import { Injectable } from '@angular/core'
import { FirestoreDiffService } from './firestore-diff.service';
import { CompanyLocation } from '../dao/company-location';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { AuthenticationService } from '../../util/authentication.service';
import { AddressService } from './address.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})
export class CompanyLocationService extends DatabaseStoreService<CompanyLocation> {

  constructor( fs: CompanyLocationFirestoreService, authenticationService: AuthenticationService, store: CompanyLocationStoreService, private addressService: AddressService) {
    super(fs, store, authenticationService,new Map([
      ["mailingAddress", {associatedDocumentId: "mailingAddressDocId", compositionStoreService: addressService}],
      ["dispatchAddress", {associatedDocumentId: "dispatchAddressDocId", compositionStoreService: addressService}]
      ]));
    }
}

@Injectable({
  providedIn: 'root'
})
export class CompanyLocationStoreService extends StateChangeStoreService<CompanyLocation> {
  protected store = "CompanyLocation-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, CompanyLocation>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
export class CompanyLocationFirestoreService extends FirestoreBackend<CompanyLocation> {

 protected basePath = "CompanyLocation";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): CompanyLocation {
return new CompanyLocation(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new CompanyLocation(), authService);
 }
}
