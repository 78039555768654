<div style="display: flex;">
  <!-- <div class="header-label header-border">Customer Jobs</div> -->

<div class="header-label header-border">Job Attachments</div>
</div>

<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort>



    <!-- Created On Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
      <td mat-cell *matCellDef="let row">{{row.createdOn | date:'short'}}</td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Url Column-->
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef>Attachment</th>
      <td mat-cell *matCellDef="let row">
        <!-- <a href={{formatPhoneNumberHref(c.primaryPhoneNumber)}}>{{formatPhoneNumber(c.primaryPhoneNumber)}}</a> -->
        <a href={{row.url}} target="_blank">
           <div onclick="hideUpload.value === true? row.name : row.url">{{row.name}}</div>
        </a>
      </td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Attachment Type Column -->
    <ng-container matColumnDef="attachmentType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Attachment Type</th>
      <td mat-cell *matCellDef="let row">{{row.attachmentType}}</td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef>Delete</th>
      <td mat-cell *matCellDef="let row"><button [hidden]=hideUpload|async mat-button class="button-red" (click)="delete(row)">Delete</button></td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
        <button mat-button class="button-blue" [hidden]=hideUpload|async (click)="fileInput.click()">
          <span>Upload</span>
          <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns" class="example-second-footer-row"></tr>
  </table>
</div>
