import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {FieldType } from '@ngx-formly/core';
import { fromEvent, Subject, Subscription, zip } from 'rxjs';
import { distinctUntilChanged, filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PhotoService } from '../../../../../../../common/src/data/services/photo.service';
import { FormlyUtilityService } from '../formly-utility.service';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';


@Component({
  selector: 'app-formly-signature-pad',
  template: `
  <div #rootContainer style="padding: 4px;">
  <canvas #imageCanvas ></canvas>
  <hr style="width: 100%;">
  <div class="signature-text">Signature</div>
  </div>
    `,
  styleUrls: ['./formly-signature-pad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormlySignaturePadComponent extends FieldType implements AfterViewInit, OnDestroy, OnInit {


  destroyingComponent$ = new Subject();
  collectSignatureDialogRef: MatDialogRef<SignaturePadComponent>;
  // default generated by cutting screenshot from below ( if you want to change to Click to sign or what not)
  // https://signaturely.com/online-signature/type/
  // displaySignature = "/assets/SignHere.png";

  image = new Image();
  private imageContext: CanvasRenderingContext2D;
  @ViewChild('imageCanvas') myCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('rootContainer') rootContainer: ElementRef;
  clickedElement: Subscription = new Subscription();

  private guid = Math.random().toString(36).substring(2, 15);

  constructor(private dialog: MatDialog, private http: HttpClient, private formlyUtilityService:  FormlyUtilityService,
    private photoService: PhotoService) {
    super();
   }

   get controlKey(): string {
    return `${this.formControl.value}-${this.key}`;}

  ngOnInit(): void {
    // If no uuid associated w/ form add it.
    if (this.formControl.value === undefined) {
      this.formControl.patchValue(this.guid);
    }
  }
   ngOnDestroy(): void {
    if (this.formControl !== undefined) {
      this.photoService.ceaseMonitoringPhotoKey.next(this.controlKey);
    }
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  ngAfterViewInit(): void {

    this.imageContext = this.myCanvas.nativeElement.getContext('2d');
    this.image.src=this.to.imageUrl;

    this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      distinctUntilChanged(),
      switchMap(() => this.photoService.photosAssignedControl(this.controlKey).pipe(
        filter(photo => photo.length > 0),
        tap(photo => {
          this.imageContext.clearRect(0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
          this.image.src = photo[0].localWebviewPath
        })
      )),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    // If imageWidthPixels not in formControl, then we are not in design view, so we load up image and do not suscribe to
    // changes in size of signature panel b/c of column resizing.
    if (this.formControl.get("imageWidthPixels") === null) {

      this.myCanvas.nativeElement.width = this.to.imageWidthPixels;
      this.myCanvas.nativeElement.height = this.to.imageHeightPixels;


      this.clickedElement = fromEvent(this.myCanvas.nativeElement, 'click').pipe(
        filter(() => !this.formControl.disabled),
        takeUntil(this.destroyingComponent$)
      ).subscribe(() => this.collectSignature());

    } else {

    zip(this.formControl.get("imageWidthPixels").valueChanges,this.formControl.get("imageHeightPixels").valueChanges).pipe(
      startWith([this.formControl.get("imageWidthPixels").value, this.formControl.get("imageHeightPixels").value]),
      filter(dimensions => ! (dimensions[0] === 0 && dimensions[1] === 0)),
      tap( dimensions => {
        this.myCanvas.nativeElement.width = dimensions[0];
        this.myCanvas.nativeElement.height = dimensions[1];
        this.imageContext.drawImage(this.image,0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    }

    this.image.onload = () => {
      this.imageContext.drawImage(this.image,0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
    }
  }

  collectSignature() : void {

    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      data: {
      key: this.controlKey
      }
    });

    this.collectSignatureDialogRef = this.dialog.open(SignaturePadComponent,editorConfig);
  }
}
