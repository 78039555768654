import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service'
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { JobEvent } from '../dao/job-event';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})
export class JobEventService extends DatabaseStoreService<JobEvent> {

  constructor( fs: JobEventFirestoreService, store: JobEventStoreService , authenticationService: AuthenticationService) {
super(fs, store, authenticationService);
  }
}

@Injectable({
  providedIn: 'root'
})
export class JobEventStoreService extends StateChangeStoreService<JobEvent> {
  protected store = "JobEvent-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, JobEvent>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class JobEventFirestoreService extends FirestoreBackend<JobEvent> {

 protected basePath = "JobEvent";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): JobEvent {
return new JobEvent(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new JobEvent(), authService);
 }
}
