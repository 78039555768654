import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';
import {  differenceInMinutes,  subMinutes, addMinutes, endOfDay, startOfDay } from 'date-fns';
import { Address } from './address';
import {  EmployeeService } from '../dao-services/employee.service';
import { Employee } from '../../../../common/src/data/dao/employee';
import { Commute, CommuteCharacterization } from '../../../../common/src/data/dao/commute';
import { Assignment } from './assignment';
import { PhysicalAddressRoutingService } from '../../../../web-app/src/app/physical-address-routing.service';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { filter, map, switchMap,tap, take, share } from 'rxjs/operators';
import { SiteVisitService } from '../dao-services/site-visit.service';
import { where } from 'firebase/firestore';
import { merge, zip } from 'rxjs';
import { AddressRouting } from './address-routing';

export enum SITE_VISIT_STATUS {
  SCHEDULED = "Scheduled",
  ENROUTE = "Enroute",
  ARRIVED = "Arrived",
  DEPARTED = "Departed",
}

export class SiteVisit extends RetrieveFirestorePropertiesBase<SiteVisit>  {

  get startDate(): Date {
    return this._startDate;
  }
  set startDate(val: Date) {
    // console.warn(`Setting start date ${this.DocId()}`);
    this._startDate = val;
    if (this._endDate !== undefined && this._startDate !== undefined) {
      this.recalculateDurationHours();
    }
  }
  get endDate(): Date {
    return this._endDate;
  }
  set endDate(val: Date) {
    this._endDate = val;
    if (this._endDate !== undefined && this._startDate !== undefined) {
    this.recalculateDurationHours();
    }
  }

  get siteVisitStatus() : SITE_VISIT_STATUS {
    let retVal = SITE_VISIT_STATUS.SCHEDULED;
    if (this.actualEndDate) {
      return SITE_VISIT_STATUS.DEPARTED;
    }
    if (this.actualStartDate) {
      return SITE_VISIT_STATUS.ARRIVED;
    }
    if (this.actualCommutes.length > 0) {
      return SITE_VISIT_STATUS.ENROUTE;
    }
    return retVal;
  }

  actualStartDate: Date;
  actualEndDate: Date;

  private _startDate: Date;
  private _endDate: Date;
  arrivalWindowStartDate: Date = null;
  arrivalWindowEndDate: Date = null;
  private _commuteTimeMinutes = 0;
  private _commuteTimeMinutesEnd = 0;
  _durationHours: number;
  prospectiveSiteVisit: boolean = false;
  prospectiveIcon: object[] = [];
  commuteDeltaFromAdding: number = undefined;
  jobDocId: string;
  explicitErrored: boolean = false;
  lockStartTime: boolean = false;
  lockPreceedingSiteVisitOrder: boolean = false;
  lockNextSiteVisitOrder: boolean = false;
  notes: string = "";

  enRoute: boolean = false;
  onSite: boolean = false;
  beenHere: boolean = false;

  actualCommutes: Commute[] = [];
  actualCommuteDocIds: string[] = [];

  prospectiveCommutes: Commute[] = [];
  prospectiveCommuteDocIds: string[] = [];

  //**For temporary use only, not serialized -> Firestore */
  siteVisitAddress: Address;
  previousSiteVisit: SiteVisit;

  commuteToSiteIncludedInWorkDay: boolean = true;
  commuteSiteToShopIncludedInWorkDay: boolean = true;
  assignments: Assignment[];

  minimizeSiteVisitMutationsOnReconciliation: boolean = false;

  get arrivalWindowStartForScheduling() : Date | null {
    return this.explicitErrored || this.lockStartTime ? this._startDate === undefined ? null : this._startDate
      : this.arrivalWindowStartDate;
  }

  get arrivalWindowEndForScheduling() : Date | null {
    return this.explicitErrored || this.lockStartTime ? this._startDate === undefined ? null : this._startDate
     : this.arrivalWindowEndDate;
  }


  recalculateCommuteTimeMinutes(preceedingSiteVisit: SiteVisit, lastVisitOfDay: boolean,
     addressRoutingService: PhysicalAddressRoutingService, settingsService: SettingsService,
     employeeService: EmployeeService, employeeDocId: string, employeeWorkStartTime: Date, siteVisitService: SiteVisitService)   {
      this.recalculateStartOfDayCommuteTime(preceedingSiteVisit, addressRoutingService, settingsService, employeeService, employeeDocId,employeeWorkStartTime, null);
      this.recalculateEndOfDayCommuteTime(lastVisitOfDay, addressRoutingService, settingsService, employeeService, employeeDocId, siteVisitService);
  }

  recalculateStartOfDayCommuteTime(preceedingSiteVisit: SiteVisit, addressRoutingService: PhysicalAddressRoutingService, settingsService: SettingsService,
    employeeService: EmployeeService, employeeDocId: string, employeeWorkStartTime: Date, siteVisitService: SiteVisitService | null) {

    const commuteToSite = this.retrieveProspectiveCommuteBeforeSiteVisit(addressRoutingService, settingsService,
      employeeService,employeeDocId, employeeWorkStartTime, preceedingSiteVisit, siteVisitService, false );
    this._commuteTimeMinutes = commuteToSite.commuteTimeMinutes;
    if (this._commuteTimeMinutes === 120) {
      // get preceeding address
      const proceedingAddressDocId = preceedingSiteVisit ? preceedingSiteVisit.siteVisitAddress.docId : employeeService.get(employeeDocId).dispatchOrginAddressDocId;
      const prospect = this.prospectiveCommutes.find(x => x.orginAddressDocId === proceedingAddressDocId || x.destinationAddressDocId === proceedingAddressDocId);
      this._commuteTimeMinutes = prospect ? prospect.commuteTimeMinutes : 120;
    }
    this.commuteToSiteIncludedInWorkDay = commuteToSite.commuteCharacterization === CommuteCharacterization.WORKDAY;
    this.previousSiteVisit = preceedingSiteVisit;
  }

  recalculateEndOfDayCommuteTime(lastVisitOfDay: boolean, addressRoutingService: PhysicalAddressRoutingService, settingsService: SettingsService,
    employeeService: EmployeeService, employeeDocId: string, siteVisitService: SiteVisitService) {
  const commuteAwayFromSiteEndOfDay = this.retrieveEndOfDayCommuteTime(lastVisitOfDay, addressRoutingService, settingsService, employeeService, employeeDocId, siteVisitService, false);
  this.commuteSiteToShopIncludedInWorkDay = commuteAwayFromSiteEndOfDay?.commuteCharacterization === CommuteCharacterization.WORKDAY || true;
  this._commuteTimeMinutesEnd = commuteAwayFromSiteEndOfDay?.commuteTimeMinutes || 0;
  if (this._commuteTimeMinutesEnd === 120) {
    // get preceeding address
    const postceedingAddressDocId = employeeService.get(employeeDocId).dispatchDestinationAddressDocId;
    const prospect = this.prospectiveCommutes.find(x => x.orginAddressDocId === postceedingAddressDocId || x.destinationAddressDocId === postceedingAddressDocId);
    this._commuteTimeMinutes = prospect ? prospect.commuteTimeMinutes : 120;
  }
  }


  retrieveEndOfDayCommuteTime(lastVisitOfDay: boolean, addressRoutingService: PhysicalAddressRoutingService, settingsService: SettingsService,
    employeeService: EmployeeService, employeeDocId: string, siteVisitService: SiteVisitService, roundToFidelity: boolean = false): Commute {

      const commuteStartTime = this.endDate === undefined ? startOfDay(new Date()) : this.endDate;
      const fidelity = roundToFidelity ? settingsService.getValue('minutesFidelityCommuteTimes') : 1;


    if (lastVisitOfDay) {
      const emp = employeeService.get(employeeDocId);
      const routingInfoForSiteVisit = addressRoutingService.orginAddressIdToAssociatedCommutes
        .get(this.siteVisitAddress.docId);
      const retVal = new Commute({commuteStartTime: commuteStartTime, commuteEndTime: addMinutes(commuteStartTime,120),
        commuteCharacterization: emp.dispatchDestinationAddressCommuteDispensation, employeeDocId: employeeDocId, estimated: true,
        orginAddressDocId: this.siteVisitAddress.docId, destinationAddressDocId: emp.dispatchDestinationAddressDocId});
      if (routingInfoForSiteVisit === undefined) {
        this.addMissingCommute(addressRoutingService, siteVisitService, emp.dispatchDestinationAddress, retVal, this.startDate, fidelity, false).pipe(
          take(1)
        ).subscribe();
        retVal.errored=true;
        return retVal;
      } else {
      if (routingInfoForSiteVisit
        .get(employeeService.get(employeeDocId).dispatchDestinationAddressDocId) !== undefined) {
          retVal.commuteEndTime = addMinutes(commuteStartTime,Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
            .get(this.siteVisitAddress.docId)
            .get(employeeService.get(employeeDocId).dispatchDestinationAddressDocId).timeEstimateSeconds / 60) / fidelity) * fidelity);
          retVal.errored = false;
        } else {
          this.addMissingCommute(addressRoutingService, siteVisitService, emp.dispatchDestinationAddress, retVal, this.startDate, fidelity, false).pipe(
            take(1)
          ).subscribe();
          retVal.errored=true;
        }
        return retVal;
      }

    } else {
      return null;
    }
  }


  public regenerateArrivalWindow(start: Date, a: any) : {arrivalWindowStartDate: Date, arrivalWindowEndDate: Date} {
    console.log(a);
    const arrivalWindowStartDate = this.arrivalWindowStartDate;
    const arrivalWindowEndDate=  this.arrivalWindowEndDate;
    console.log(arrivalWindowStartDate,arrivalWindowEndDate);
    return ({arrivalWindowStartDate, arrivalWindowEndDate});

  }

  getRoundedCommuteTimeMinutes(actualCommuteEstimateMinutes: number, settingsService: SettingsService, fidelity: number | null = null) : number {
    if (actualCommuteEstimateMinutes === undefined) {
      return undefined;
    }
    if (!fidelity) {
      fidelity = settingsService.getValue('minutesFidelityCommuteTimes');
    }
    return Math.round(actualCommuteEstimateMinutes / fidelity) * fidelity;

  }

  retrieveProspectiveCommuteBeforeSiteVisit(addressRoutingService: PhysicalAddressRoutingService,
    settingsService: SettingsService, employeeService: EmployeeService, employeeDocId: string,  employeeWorkStartTime : Date,
    preceedingSiteVisit: SiteVisit = null, siteVisitService: SiteVisitService = null, roundToFidelity: boolean = false): Commute {

      const fidelity = roundToFidelity ? settingsService.getValue('minutesFidelityCommuteTimes') : 1;
      const emp: Employee = employeeService.get(employeeDocId);
      const retVal = new Commute({commuteStartTime: subMinutes(this.startDate,120), commuteEndTime: this.startDate,
        commuteCharacterization: emp.dispatchOrginAddressCommuteDispensation, employeeDocId: employeeDocId,
        estimated: true, destinationAddressDocId: this.siteVisitAddress.docId});

      // First site visit of the day.
      if (preceedingSiteVisit === null ) {
        retVal.orginAddressDocId = emp.dispatchOrginAddressDocId;
        // if site visit start time is not set, calculate it based on whether commuting to site visit is characterized as work, or pre-work activity.
        let startingDate = this.startDate;
        if (startingDate === undefined) {
          try {
            if (emp.dispatchOrginAddressCommuteDispensation === CommuteCharacterization.WORKDAY) {
            startingDate = addMinutes(employeeWorkStartTime, Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
              .get(this.siteVisitAddress.docId)
              .get(employeeService.get(employeeDocId).dispatchOrginAddressDocId).timeEstimateSeconds / 60) / fidelity)
              * fidelity);
            } else {
              startingDate = employeeWorkStartTime;
            }
          } catch (e) {
            console.log(employeeService.get(employeeDocId).dispatchOrginAddressDocId,this.siteVisitAddress.docId, emp.name);
            throw(e);
          }
          retVal.commuteEndTime = startingDate;
        }
        try
        {
          if (addressRoutingService.orginAddressIdToAssociatedCommutes
            .get(this.siteVisitAddress.docId)
            .get(employeeService.get(employeeDocId).dispatchOrginAddressDocId) === undefined) {
              console.error(this.siteVisitAddress.formattedAddress(), this.siteVisitAddress.docId, employeeService.get(employeeDocId).dispatchOrginAddressDocId, employeeService.get(employeeDocId).name,
              employeeService.get(employeeDocId).lastUpdatedAt);
            }
          retVal.commuteStartTime = subMinutes(startingDate,Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
          .get(this.siteVisitAddress.docId)
          .get(employeeService.get(employeeDocId).dispatchOrginAddressDocId).timeEstimateSeconds / 60) / fidelity)
          * fidelity);
          retVal.errored = false;
          return retVal;
        } catch (err) {
          console.log("Commute not present in address routing service, detected @ SiteVisit.");
          retVal.errored=true;
          this.addMissingCommute(addressRoutingService, siteVisitService, emp.dispatchOrginAddress, retVal, startingDate, fidelity).pipe(
            take(1)
          ).subscribe()
          return retVal;
        }
        //Site visit is 2..n site visit of day.
      } else {
        retVal.orginAddressDocId = preceedingSiteVisit.siteVisitAddress.docId;
        retVal.commuteCharacterization = CommuteCharacterization.WORKDAY;
        let startingDate = this.startDate;
        if (startingDate === undefined) {
          startingDate= endOfDay(new Date());
          retVal.commuteEndTime = startingDate;
        }
        try {
          if (addressRoutingService.orginAddressIdToAssociatedCommutes
          .get(this.siteVisitAddress.docId)
          .get(preceedingSiteVisit.siteVisitAddress.docId) !== undefined) {
            retVal.commuteStartTime = subMinutes(startingDate,Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
              .get(this.siteVisitAddress.docId)
              .get(preceedingSiteVisit.siteVisitAddress.docId).timeEstimateSeconds / 60) / fidelity)
              * fidelity);
              retVal.errored = false;
              return retVal;
          } else {
            console.error("WE WOULD BE ADDING COMMUTE HERE IS THERE WORK TO DO?");
              this.addMissingCommute(addressRoutingService, siteVisitService, preceedingSiteVisit.siteVisitAddress, retVal, startingDate, fidelity).pipe(
                take(1)
              ).subscribe();
            if (addressRoutingService.orginAddressIdToAssociatedCommutes
              .get(this.siteVisitAddress.docId)
              .get(preceedingSiteVisit.siteVisitAddress.docId) !== undefined) {
                retVal.errored=false;
                retVal.commuteStartTime = subMinutes(startingDate,Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
                  .get(this.siteVisitAddress.docId)
                  .get(preceedingSiteVisit.siteVisitAddress.docId).timeEstimateSeconds / 60) / fidelity)
                  * fidelity);
                  return retVal;
                } else {
            retVal.errored=true;
            return retVal;
            }
          }
        } catch (err) {
          retVal.errored=true;
          return retVal;
        }
      }
  }

  private addMissingCommute(addressRoutingService: PhysicalAddressRoutingService, siteVisitService: SiteVisitService,
    addressToMapTo: Address, retVal: Commute, startingDate: Date, fidelity: any, preceeding: boolean = true)
  {
     console.warn("SHOULD NOT OCCUR");
     console.warn(`Adding commute between: ${addressToMapTo.formattedAddress()} ${addressToMapTo.DocId()} and
      ${this.siteVisitAddress.formattedAddress()}  ${this.siteVisitAddress.DocId()}`);
     console.warn("SHOULD NOT OCCUR");

    const checkDexie = addressRoutingService.retrieveOnDemandFromDixie(this.siteVisitAddress, false, siteVisitService === null).pipe(
      take(1),
      map(() => {
        if (addressRoutingService.orginAddressIdToAssociatedCommutes
          .get(this.siteVisitAddress.docId)
          .get(addressToMapTo.docId) !== undefined) {
            if (preceeding) {
            retVal.commuteStartTime = subMinutes(startingDate, Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
              .get(this.siteVisitAddress.docId)
              .get(addressToMapTo.docId).timeEstimateSeconds / 60) / fidelity)
              * fidelity);
            } else {
              retVal.commuteEndTime = addMinutes(startingDate,
                Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
                .get(this.siteVisitAddress.docId)
                .get(addressToMapTo.docId).timeEstimateSeconds / 60) / fidelity)
                * fidelity);
            }
            retVal.errored = false;
          return retVal;
        } else {
          return null;
        }
      }),
      share()
      );

      const addFromDexie = checkDexie.pipe(
        filter(x => x !== null),
        tap(x => console.log(x,` ADD FROM DEXIE`)),
      );

      const addFromFirestore = checkDexie.pipe(
        filter(x => x === null),
        switchMap(() => zip(
          addressRoutingService.addressRoutingService.queryFirestoreDeep$([where("originAddressDocId", "==", addressToMapTo.docId),
          where("destinationAddressDocId", "==", this.siteVisitAddress.docId)]),
          addressRoutingService.addressRoutingService.queryFirestoreDeep$([where("destinationAddressDocId", "==", addressToMapTo.docId),
          where("originAddressDocId", "==", this.siteVisitAddress.docId)]))),
          map(x => {
            if (x[0][0] !== undefined) {
              return x[0][0] as AddressRouting;
            } else {
              return x[1][0] as AddressRouting;
            }
          }),
          tap(x => {
          console.log(x,` ADD FROM FIRESTORE site visit addr: ${this.siteVisitAddress.formattedAddress()}
            Dest: ${addressToMapTo.formattedAddress()}`);
          }),
        tap(x => addressRoutingService.addToCommuteMatrix(x)),
      );




     const addIt = merge(addFromDexie,addFromFirestore).pipe(
      tap(x => console.log(x,` string`)),
      take(1),
      map(() => {
        if (addressRoutingService.orginAddressIdToAssociatedCommutes
          .get(this.siteVisitAddress.docId)
          .get(addressToMapTo.docId) !== undefined) {
          retVal.commuteStartTime = subMinutes(startingDate, Math.round(Math.floor(addressRoutingService.orginAddressIdToAssociatedCommutes
            .get(this.siteVisitAddress.docId)
            .get(addressToMapTo.docId).timeEstimateSeconds / 60) / fidelity)
            * fidelity);
            retVal.errored = false;
          return retVal;
        } else {
          return null;
        }
      }),
      filter(x => x !== null && siteVisitService !== null),
      map(x => x as Commute),
      map(x => {
        const existingCommuteToSiteIndex = this.prospectiveCommutes.findIndex(x => x.destinationAddressDocId === this.siteVisitAddress.docId);
        if (existingCommuteToSiteIndex > -1) {
          this.prospectiveCommutes.splice(existingCommuteToSiteIndex, 1);
        }
        this.prospectiveCommutes.push(x as Commute);
        return this;
      }),
      tap(x => console.error(x, `COMMUTE MANUALLY ADDED, SHOULD ONLY OCCUR DEALING WITH ERRORED SCHEDULES.`)),
      tap(x => console.error(x, `COMMUTE MANUALLY ADDED, SHOULD ONLY OCCUR DEALING WITH ERRORED SCHEDULES.`)),
      switchMap(x => siteVisitService.update$(x as SiteVisit)),
      take(1)
     );
     return addIt;
  }

  get commuteTimeMinutes(): number {
    return this._commuteTimeMinutes + this._commuteTimeMinutesEnd;
  }

  get commuteTimeMinutesToSite(): number {
  return Number.isNaN(this._commuteTimeMinutes) ? 120 : this._commuteTimeMinutes;
  }

  set commuteTimeMinutesToSite(val: number) {
    this._commuteTimeMinutes = val;
  }

  get commuteTimeMinutesFromSiteBackToShop(): number {
    return Number.isNaN(this._commuteTimeMinutesEnd) ? 120 : this._commuteTimeMinutesEnd;
  }

  private recalculateDurationHours(): void {
    this._durationHours = differenceInMinutes(this.endDate, this.startDate) / 60;
  }

  get expectedDurationHours(): number {
    if (this._durationHours === undefined) {
      this.recalculateDurationHours();
    }
    return this._durationHours;
  }


  get actualDurationHours(): number {
    if (this.actualStartDate && this.actualEndDate) {
      return differenceInMinutes(this.actualEndDate, this.actualStartDate, {roundingMethod: "floor"}) / 60;
    } else {
      return 0;
    }
  }

  get siteVisitDispatchStatus(): string {
    return "New";
    // Dispatch values:  ? New ? "En Route", "On Site", "Completed"
  }

  public static siteVisitTimeOrCommuteToSiteVisitChanged(a: SiteVisit, b: SiteVisit): boolean {
    return a.startDate.getTime() !== b.startDate.getTime() || a.endDate.getTime() !== b.endDate.getTime() ||
    a.arrivalWindowStartDate.getTime() !== b.arrivalWindowStartDate.getTime() || a.arrivalWindowEndDate.getTime()
     !== b.arrivalWindowEndDate.getTime() || a._commuteTimeMinutes !== b._commuteTimeMinutes ||
     a._commuteTimeMinutesEnd !== b._commuteTimeMinutesEnd ||
     (
      a.prospectiveCommutes.find(x => x.destinationAddressDocId === a.siteVisitAddress.docId)?.orginAddressDocId !==
      b.prospectiveCommutes.find(x => x.destinationAddressDocId === b.siteVisitAddress.docId)?.orginAddressDocId) ||
      (
      a.prospectiveCommutes.find(x => x.orginAddressDocId === a.siteVisitAddress.docId)?.destinationAddressDocId !==
      b.prospectiveCommutes.find(x => x.orginAddressDocId === b.siteVisitAddress.docId)?.destinationAddressDocId);
  }



  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames
    .concat("_durationHours", "prospectiveSiteVisit", "siteVisitAddress", "prospectiveIcon", "commuteDeltaFromAdding",
    "previousSiteVisit","assignments","actualCommutes","prospectiveCommutes","minimizeSiteVisitMutationsOnReconciliation");
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
  .concat("_durationHours", "prospectiveSiteVisit", "siteVisitAddress", "jobDocId",
    "_commuteTimeMinutes", "_commuteTimeMinutesEnd", "commuteDeltaFromAdding", "previousSiteVisit","assignments",
    "prospectiveCommuteDocIds","actualCommuteDocIds","minimizeSiteVisitMutationsOnReconciliation");

  retrieveFirestoreIgnoredMemberNames() : string[] { return SiteVisit._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] { return SiteVisit._firestoreIgnoreDiffTrackingMembers;}
  retrievefirestoreCompositionMemberNames(): string[] { return ["actualCommutes","prospectiveCommutes"];}
  retrievefirestoreCompositionalDiffMemberNames(): string[] { return ["actualCommutes","prospectiveCommutes"]; }
  retrieveFirestoreDenormalizedMemberNames(): string[] { return ["actualCommutes","prospectiveCommutes"];}

  public constructor(init?: Partial<SiteVisit>) {
    super();
    Object.assign(this, init);
    if (this.startDate !== undefined) {
      this.startDate = new Date(this.startDate);
    }
    if (this.endDate !== undefined) {
      this.endDate = new Date(this.endDate);
    }

    if (this.startDate !== undefined && this.arrivalWindowStartDate === null) {
      throw new Error(`SiteVisit.startDate is not undefined but arrivalWindowStartDate is null docId: ${this.DocId()}`);
    // this.generateArrivalWindow();
    }
  }

  public resetFirestoreIgnoredDetails() {
    this._durationHours = undefined;
    this.siteVisitAddress = undefined;
    this.prospectiveSiteVisit = false;
    this.prospectiveIcon = [];
    this.commuteDeltaFromAdding = undefined;
  }
}
