import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class Industry extends RetrieveFirestorePropertiesBase<Industry>  {


name: string;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return Industry._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Industry._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Industry._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Industry._firestoreCompositionMemberNames;}

public constructor(init?: Partial<Industry>) {
  super();
  Object.assign(this, init);
}

}
