import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyPhotoAdderComponent } from './formly-photo-adder.component';
import { FormlyModule } from '@ngx-formly/core';
import { IonicModule } from '@ionic/angular';
import {FormlyMaterialModule} from '@ngx-formly/material';

@NgModule({
  declarations: [FormlyPhotoAdderComponent],
  imports: [
    FormlyMaterialModule,
    CommonModule,
    IonicModule,
    FormlyModule.forChild({
      types: [
        {name: 'formlyPhotoAdder', component: FormlyPhotoAdderComponent},
      ]}),

  ],
  exports: [FormlyPhotoAdderComponent]
})
export class FormlyPhotoAdderModule { }
