import { FirestoreDiff } from '../../../../common/src/data/dao/firestore-diff';
import { RetrieveFirestoreProperties } from '../../../../common/src/data/database-backend/retrieve-firestore-properties';
import { deepDiffMapper } from '../retrieve-from-firestore-diff-mapper';


export class GenerateFirestoreDeltas {

    private trackChange(changeType: string) {
      switch (changeType) {
        case "created":
        case "updated":
        case "deleted": {
          return true;
          break;
        }
          default: {
            return false;
          }
      }
    }

    public calculateDeltas(previous: RetrieveFirestoreProperties, current: RetrieveFirestoreProperties, employeeDocId?: string):
    FirestoreDiff[] {
      const genericDiff = deepDiffMapper.map(current, previous, current.retrievefirestoreIgnoreDiffTrackingMembers());
        let diffs: FirestoreDiff[] = [];

        let aDiff: FirestoreDiff[] = null;
        // tslint:disable-next-line: forin
        for ( const k in genericDiff.data) {
          // if changes to data, and class tracks changes for given member.
          // console.log(k);
          // console.log(current);
          // console.log(previous);
          if ( genericDiff.data[k] !== null && !current.retrievefirestoreIgnoreDiffTrackingMembers().includes(k)
          &&  genericDiff.data[k] !== undefined && this.trackChange(genericDiff.data[k].type)) {
            // Compositional diff members are responsible for creating diffs.  Primitives + primitive equivilants (date, etc) are not.
            if (Array.isArray(current[k])) {
              const currentArrayProperty = current === null ? null :  current[k] as Array<any>;
              const previousArrayProperty = previous === null ? null :  previous[k] as Array<any>;
              // If an array of composition objects.
              if (current.retrievefirestoreCompositionalDiffMemberNames().includes(k)) {
                // Check over all items currently in array to see if added or updated.
                currentArrayProperty.forEach(prop => {
                  if (prop !== null ) {
                    if (previousArrayProperty.every(z => z === null) || !previousArrayProperty.map(x => (x as RetrieveFirestoreProperties).DocId()).includes
                    ((prop as RetrieveFirestoreProperties).DocId())) {
                      const addCompositionalObjectToArrayDiff = prop.createFirestoreDiff(null, prop, k, "add", true);
                      (addCompositionalObjectToArrayDiff as Array<FirestoreDiff>).forEach(x => x.associatedDocId = current.DocId());
                      diffs = diffs.concat(addCompositionalObjectToArrayDiff);
                    } else {
                    aDiff = this.calculateDeltas(previousArrayProperty.filter(x => (x as RetrieveFirestoreProperties).DocId() ===
                    (prop as RetrieveFirestoreProperties).DocId())[0], prop, employeeDocId);
                    }
                    diffs = diffs.concat(aDiff);
                }
                });
                // Check over all items previously in array to see if removed.
                previousArrayProperty.forEach(prop => {
                  if (prop !== null)
                  {
                  if (currentArrayProperty === null || currentArrayProperty.every(z => z === null) ||
                  !currentArrayProperty.map(x => (x as RetrieveFirestoreProperties).DocId()).includes
                  ((prop as RetrieveFirestoreProperties).DocId())) {
                    const addCompositionalObjectToArrayDiff = prop.createFirestoreDiff(prop, null, k, "remove", true);
                    (addCompositionalObjectToArrayDiff as Array<FirestoreDiff>).forEach(x => x.associatedDocId = current.DocId());
                    diffs = diffs.concat(addCompositionalObjectToArrayDiff);
                    console.log(diffs);
                  }
                }});
                // Array of primitives.
              } else {
                currentArrayProperty.forEach(prop => {
                  if (!previousArrayProperty.includes(prop)) {
                    diffs = diffs.concat(current.createFirestoreDiff(null, current, k, "add", true));
                  }
                  });
                previousArrayProperty.forEach(prop => {
                  if (!currentArrayProperty.includes(prop)) {
                    diffs = diffs.concat(current.createFirestoreDiff(previous, null, k, "remove", true));
                  }
                  });
                }
            } else {
              // Non-array member.
              if (current.retrievefirestoreCompositionalDiffMemberNames().includes(k)) {
                aDiff =  this.calculateDeltas(previous[k], current[k], employeeDocId);
                aDiff = aDiff.filter(
                    (diff, i, arr) => arr.findIndex(x => x.temporaryGuid === diff.temporaryGuid) === i);
                diffs = diffs.concat(aDiff);
              } else {
                aDiff = current.createFirestoreDiff(previous, current, k, genericDiff.data[k].type, false);
                if (aDiff !== null) {
                  diffs = diffs.concat(aDiff);
                }
              }
            }
          }
        }
        // Composing objects need add / removing diffs logged (removed site visit x, added site visit y)
        // But they don't want update diffs added.
        const retVal: FirestoreDiff[] = [];
        diffs.forEach( diff => {
          if (diff !== null)
          {
            if ( !retVal.some(x => x.temporaryGuid === diff.temporaryGuid) && (diff.changeType === "remove" || diff.changeType === "add")) {
              const composorDiff = new FirestoreDiff(diff);
              composorDiff.associatedDocId = current.DocId();
              composorDiff.temporaryGuid = diff.temporaryGuid;
              retVal.push(composorDiff);
            }
            if (!retVal.some(x => x.temporaryGuid === diff.temporaryGuid &&
              x.associatedDocId === diff.associatedDocId && x.activeDocId === x.activeDocId) &&
              diff.changeType !== "remove" && diff.changeType !== "add") {
            retVal.push(diff);
            }
          }
        });
        return retVal;
      }
}
