import { format } from "date-fns";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Invoice } from "./invoice";

export class InvoicePayment extends RetrieveFirestorePropertiesBase<InvoicePayment>  {

invoiceDocId: string;
invoice: Invoice;
amount: number;
memo: string;
checkNumber: string;
paymentType: string;
void: boolean = false;


get title(): string {return `${this.paymentType} collected on : ${format(this.createdAt,'LLL d')}` ; }
get description(): string {return `Memo: ${this.memo} Check #: ${this.checkNumber ? this.checkNumber : ""}` }
get internalNotes(): string {return "";}

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["invoice"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["invoiceDocId"]);
static _firestoreCompositionMemberNames = ["invoice"];
static _firestoreCompositionalDiffMemberNames: string[] = ["invoice"];

retrieveFirestoreIgnoredMemberNames() : string[] { return InvoicePayment._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return InvoicePayment._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return InvoicePayment._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return InvoicePayment._firestoreCompositionMemberNames;}

public constructor(init?: Partial<InvoicePayment>) {
super();
Object.assign(this, init);
}
}
