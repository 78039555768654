import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { InvoiceService } from './invoice.service';
import { InvoicePayment } from '../dao/invoice-payment';


@Injectable({
  providedIn: 'root'
})
export class InvoicePaymentService extends DatabaseStoreService<InvoicePayment> {

  constructor( fs: InvoicePaymentFirestoreService, authenticationService: AuthenticationService, store: InvoicePaymentStoreService, private invoiceService: InvoiceService) {
super(fs, store, authenticationService, new Map([["invoice", {associatedDocumentId: "invoiceDocId",compositionStoreService: invoiceService}]]));
  }

}

@Injectable({
  providedIn: 'root'
})
export class InvoicePaymentStoreService extends StateChangeStoreService<InvoicePayment> {
  protected store = "InvoicePayment-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, InvoicePayment>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class InvoicePaymentFirestoreService extends FirestoreBackend<InvoicePayment> {

 protected basePath = "InvoicePayment";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): InvoicePayment {
return new InvoicePayment(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new InvoicePayment(), authService);
 }
}
