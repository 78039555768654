<app-navigation></app-navigation>
<div class="container1" [formGroup]="form">
    <div class="left-side"></div>
    <div class="middle">
        <!--START SERVICE ADDRESS SECTION-->
        <div class="section">
            <app-customer-search
            [activeSearch]="activeSearch$ | async"
            (activeSearchEmitter)="activeSearch$.next(true)"
            [addressCustomersFound]="customerGlobalSearchResults$ | async"
            [form] = "addressSearchFormGroup"
            [addressToShopRouting] = "addressToShopRouting$ | async"
            [previousJobCountAtAddress] = "jobCountAtAddress$ | async"
            (search)="searchStreetNamePhoneGlobalCustomer$.next($event)"
            (selectedAddress) = "serviceAddressSelection$.next($event)"
            (selectedAddressAndCustomer)="serviceAddressWithCustomerSelection$.next($event)"
            (unitUpdated) = "serviceAddressUnit$.next($event)"
            ></app-customer-search>
        </div>
        <!--END OF SERVICE ADDRESS SECTION-->
        <!--START CUSTOMER SECTION-->
        <div class="section">
          <app-multiple-customer-container
          [customerDetailsForm] = "customerDetailsFormGroup"
          ></app-multiple-customer-container>
      </div>
        <!--END OF CUSTOMER SECTION-->

        <!--START OF JOB DETAILS SECTION-->
        <div class="section">
            <app-job-details
          [form] = "jobDetailsFormGroup"
          [activeJob] = "activeJob$ | async"
          [jobTypes] = "jobTypes$ | async"
          [jobPriorities] = "jobPriorities$ | async"
          [inputDisabled] = "disableJobDetailsInput$ | async"
          [focus]="focusJobDetails"
            ></app-job-details>
        </div>
        <!--END OF JOB DETAILS SECTION-->

        <!--START OF LINE ITEM SECTION-->
        <div class="section" style="border: none">
          <div class="section">
            <div class="header-style">Line Items</div>
              <div class="section-content">
                <div class="line-items">
                  <app-line-item-display *ngIf="(lineItems$ | async)"
                  [lineItems]="lineItems$"
                  [intendedAudience]="2"
                  (addLineItem$)="addLineItem$.next($event)"
                  (editLineItem$)="editLineItem$.next($event)"
                  (removeLineItem$)="removeLineItem$.next($event)"
                  >
                </app-line-item-display>
                </div>
                <div *ngIf="jobDetailsFormGroup.controls['jobLineItemCount'].errors && (jobDetailsFormGroup.controls['jobLineItemCount'].touched || jobDetailsFormGroup.controls['jobLineItemCount'].dirty)" style="color: #f44336;" class="alert alert-danger">
                  Job must include one or more line items to schedule.
              </div>
              <div>
            </div>
          </div>
        </div>
        <!--END OF LINE ITEM SECTION-->

        <!--START OF SCHEDULE BUTTONS SECTION-->
        <div class="section" style="border: none">
            <div class="section-content">
                <div><button mat-button class="button-gray" (click)="cancel();">Cancel</button></div>
                <div class="schedule-btn-pos">
                    <button type = "submit" mat-button class="button-blue" (click)="scheduleNow();">Schedule Now</button>
                    <button type = "submit" mat-button class="button-light-blue" style="margin-left: 10px;" (click)="scheduleLater();">Schedule Later</button>
                    <!-- <button type = "button" mat-button class="button-light-blue" style="margin-left: 10px;" (click)="testes();">test</button> -->
                </div>
            </div>
        </div>
        <!--END OF SCHEDULE BUTTONS SECTION-->
    </div> <!-- End middle.-->
    <div class="right-side"></div>
  </div>
</div>

