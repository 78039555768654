import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { PhotoService } from '../../../../../common/src/data/services/photo.service';
import { Iphoto } from '../../../../../common/src/data/dao/iphoto';
import { CameraSource } from '@capacitor/camera';

@Component({
  selector: 'app-photo-picker',
  templateUrl: './photo-picker.component.html',
  styleUrls: ['./photo-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoPickerComponent implements OnInit {

  @Input() numberPicturesMax: number = 4;
  @Input() numberPicturesMin: number = 1;
  @Input() guid: string;

  numberPictures: number = 0;
  photoErrorMessage : string = "Error adding photo";
  photosAssignedContainer$ = new BehaviorSubject<Iphoto[]>([]);

  destroyingComponent$ = new Subject();

  platform: Platform;

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  // This needs moved to a sub folder of it's formly component, and needs to be converted to be
  // lazy loaded.
  constructor(public photoService: PhotoService, public actionSheetController: ActionSheetController,
    public alertController: AlertController, public modalController: ModalController, platform: Platform) {
      this.platform = platform;

      this.photoService.photoLoadErrorMessage$.pipe(
        filter(x => x !== null),
        tap(x => {
          this.photoErrorMessage = x;
          this.presentAlert("photoLoadError");
        }),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
   }

  ngOnInit(): void {
    this.photoService.photosAssignedControl(this.guid).
    pipe(
      tap(x => this.photosAssignedContainer$.next(x)),
      tap(x => this.numberPictures = x.length),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  exit() : void {
    this.modalController.dismiss({
      'dismissed': true,
      numberPictures: this.numberPictures
    });
  }

  get pictureCountColor(): Observable<string> {
    return this.photosAssignedContainer$.pipe(
      map(x => x.length >= this.numberPicturesMin ? "success" : "danger" )
    )
  }

  get pictureCountString(): Observable<string> {
    return this.photosAssignedContainer$.pipe(
      map(x => `${x.length} of ${this.numberPicturesMax}`)
    );
  }

  async presentAlert(alertOn: "tooManyPictures" | "photoLoadError") {

    const subHeaderMessage = alertOn === "tooManyPictures" ? "Too many pictures" : "Error adding photo";
    const alertMessage = alertOn === "tooManyPictures" ? `Maximum of ${this.numberPicturesMax} pictures allowed, you must delete a picture before adding another` : this.photoErrorMessage;
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Alert',
      subHeader: subHeaderMessage,
      message: alertMessage,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  public get IsWeb(): boolean {
    return this.platform.is('desktop');
  }


  public async addPictureFromCamera() {
    return this.addPictureIfRoom(CameraSource.Camera);
  }

  public async addPictureFromGallery() {
    return this.addPictureIfRoom(CameraSource.Photos);
  }

  public async addPictureIfRoom(source: CameraSource) {
    if (this.photosAssignedContainer$.value.length < this.numberPicturesMax) {
      this.photoService.addNewToGallery(this.guid, source);
    } else {
    this.presentAlert("tooManyPictures");
   }
  }

  public async showActionSheet(photo: Iphoto, position: number) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Photos',
      buttons: [{
        text: 'Delete',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          this.photoService.deletePicture(photo, position,this.guid).subscribe();
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          // Nothing to do, action sheet is automatically closed
         }
      }]
    });
    await actionSheet.present();
  }
}
