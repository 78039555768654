import { Observable } from "rxjs";
import { Address } from "../dao/address";
import { Customer } from "../dao/customer";
import { Job } from "../dao/job";

// tslint:disable-next-line: class-name
export interface searchInput {
  search: string;
  componentSource: string;
  searchSources : searchSource[];
}

export enum searchSource {
  GooglePlaceAutoComplete = "GOOGLEPLACEAUTOCOMPLETE",
  InternalCustomerName = "INTERNALCUSTOMERNAME",
  InternalCustomerPhone = "INTERNALCUSTOMERPHONE",
  InternalCustomerEmail = "INTERNALCUSTOMEREMAIL",
  InternalCustomerCompany="INTERNALCUSTOMERCOMPANY",
  // Job searches.
  InternalServiceProviderJobNumber= "INTERNALSERVICEPROVIDERJOBNUMBER",
  InternalServiceAddress = "INTERNALSERVICEADDRESS",
  All = "ALL",
}

// tslint:disable-next-line: class-name
export interface searchResults {
  resultSource: searchSource;
  address?: Address;
  customer?: Customer;
  job?: Job;
}

// tslint:disable-next-line: class-name
export interface resultsWithSearch {
  results: searchResults[];
  searchInput: searchInput;
}

export abstract class SearchService {
  public searchResults$: Observable<resultsWithSearch>;
  abstract addSearch(searchInput: searchInput): void;
}
