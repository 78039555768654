import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import {WrapperLabelComponent} from './formly-wrapper-manually-positioned-label';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
  declarations: [WrapperLabelComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormlyModule.forChild({
      wrappers: [
        { name: 'manual-label', component: WrapperLabelComponent},
      ]
    }),
  ]
})
export class FormlyWrapperManuallyPositionedLabelModule { }
