import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyImageComponent } from './formly-image.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [FormlyImageComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        { name: 'formlyImage', component: FormlyImageComponent},
      ]}),
    HttpClientModule,
  ]
})
export class FormlyImageModule { }
