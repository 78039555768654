import { Injectable } from '@angular/core';
import { Report } from '../dao/report';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { AuthenticationService } from '../../util/authentication.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { filter,  map, Observable,  take,  } from 'rxjs';
import { limit, orderBy, startAfter, where } from 'firebase/firestore';
import superjson from 'superjson';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends DatabaseStoreService<Report> {

  constructor( fs: ReportFirestoreService, store: ReportStoreService , authenticationService: AuthenticationService) {
    super(fs, store, authenticationService);
  }

  retrieveReportResults(report: Report, startDate: Date, endDate: Date, pageSize: number, lastVisible?: any) {
    return (this.fs as ReportFirestoreService).getReportResults(report, startDate, endDate, pageSize, lastVisible);
  }

  retrieveReportResultCount(report: Report, startDate: Date, endDate: Date) {
    return (this.fs as ReportFirestoreService).retrieveReportResultCount(report, startDate, endDate);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReportStoreService extends StateChangeStoreService<Report> {
  protected store = "Report-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, Report>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class ReportFirestoreService extends FirestoreBackend<Report> {

 protected basePath = "Reports";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Report {
return new Report(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new Report(), authService);
 }

 public retrieveReportResultCount(report: Report, startDate: Date, endDate: Date) : Observable<any> {
    const guid = Math.random().toString(36).substring(0, 7);

      const { json, meta } = superjson.serialize([where(`${report.primaryDateField}`, '>=', startDate),
      where(`${report.primaryDateField}`, '<=', endDate)]);
    setTimeout(() => FirestoreBackend.worker.postMessage({
      operation: 'queryServerCount',
      signalGuid: guid,
      collection: `${this.basePathRead()}/${report.docId}/Results`,
      queryFn: {json, meta}
    }),FirestoreBackend.firestoreBackendMessageDelayMs);

    return FirestoreBackend.firestoreBackendMessage$.pipe(
        filter((y) => y.signalGuid === guid),
        map(x => x['count']),
        take(1),
      );
  }

 public getReportResults(report: Report, startDate: Date, endDate: Date, pageSize: number, lastVisible?: any) : Observable<any> {
    const path = `${this.basePathRead()}/${report.docId}/Results/`;
    const guid = Math.random().toString(36).substring(0, 7);
    let queryParams = [];
    if (lastVisible) {
      queryParams = [where(`${report.primaryDateField}`, '>=', startDate), where(`${report.primaryDateField}`, '<=', endDate),orderBy(`${report.primaryDateField}`, 'desc'),
        startAfter(lastVisible[report.primaryDateField]),limit(pageSize)];
    } else {
      queryParams = [where(`${report.primaryDateField}`, '>=', startDate),where(`${report.primaryDateField}`, '<=', endDate),orderBy(`${report.primaryDateField}`, 'desc'),
      limit(pageSize)];
    }
    const { json, meta } = superjson.serialize(queryParams);

      setTimeout(() =>
      FirestoreBackend.worker.postMessage({
        operation: 'querySnapshot',
        signalGuid: guid,
        collection: path,
        queryFn: { json, meta},
      }),FirestoreBackend.firestoreBackendMessageDelayMs);

      return FirestoreBackend.firestoreBackendMessage$.pipe(
          filter((y) => y.signalGuid === guid),
          map(x => x['docs']),
        );
  }
}
