import { Injectable } from '@angular/core';
;
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { FormFirestoreService } from './form-firestore.service';
import {FormModelFirestore} from '../dao/form-model-firestore';

@Injectable({
  providedIn: 'root'
})
export class FormModelFirestoreService extends DatabaseStoreService<FormModelFirestore> {

  constructor( fs: FormModelFirestoreFirestoreService, authenticationService: AuthenticationService, store: FormModelFirestoreStoreService, private formFirestoreService: FormFirestoreService) {
    super(fs, store, authenticationService, new Map([
      ["formFirestore", {associatedDocumentId: "formFirestoreDocId", compositionStoreService: formFirestoreService}]
    ]));
    this.compositionServices.set("previousForms", {associatedDocumentId: "previousFormModelFirestoreVersionDocIds", compositionStoreService: this});
    this.fs.compositionServices.set("previousForms", {associatedDocumentId: "previousFormModelFirestoreVersionDocIds", firestoreBackEnd: this.fs});
  }

  override update$(obj: FormModelFirestore, batch: string | null = null): Observable<FormModelFirestore> {
      if (obj.skipSave) {
        return of(obj);
      } else {
        return super.update$(obj, batch);
      }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FormModelFirestoreStoreService extends StateChangeStoreService<FormModelFirestore> {
  protected store = "form-model-firestore";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, FormModelFirestore>(), true, firestoreDiffService);
  }
}
@Injectable({
  providedIn: 'root'
  })
class FormModelFirestoreFirestoreService extends FirestoreBackend<FormModelFirestore> {

  protected basePath = "form_model_firestore";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): FormModelFirestore {
    return new FormModelFirestore(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new FormModelFirestore(), authService);
  }
}
