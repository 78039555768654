
<div style="background-color: #f8f8f8;">
<div class="header-label header-border">Communications Sent</div>
    <div>
          <table mat-table [dataSource]="dataSource | async" class="mat-elevation-z8">

            <!-- Primary Customer Contact e-mail / SMS # -->
            <ng-container matColumnDef="primaryCustomerContact">
              <th mat-header-cell *matHeaderCellDef>Customer Contact Info</th>
              <td mat-cell *matCellDef="let c"> {{customerCommunicationContactUtilized(c)}}</td>
            </ng-container>

            <!-- Primary Customer Name -->
            <ng-container matColumnDef="customerName">
              <th mat-header-cell *matHeaderCellDef>Primary Customer</th>
              <td mat-cell *matCellDef="let c"> {{c.primaryCustomer?.customerName}} </td>
            </ng-container>

            <!-- Template Name -->
            <ng-container matColumnDef="templateName">
              <th mat-header-cell *matHeaderCellDef>Template</th>
              <td mat-cell *matCellDef="let c"> {{c.customerCommunicationTemplate?.title}} </td>
            </ng-container>

            <!-- Communication Status -->
            <ng-container matColumnDef="CommunicationStatus">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let c"> {{c.customerCommunicationStatus}} </td>
            </ng-container>

            <!-- Communication Doc ID -->
            <ng-container matColumnDef="docId">
              <th mat-header-cell *matHeaderCellDef>Doc Id:</th>
              <td mat-cell *matCellDef="let c"> {{c.docId}} </td>
            </ng-container>

            <!-- Communication Sent Date -->
            <ng-container matColumnDef="sentDate">
              <th mat-header-cell *matHeaderCellDef>Sent On:</th>
              <td mat-cell *matCellDef="let c"> {{getDeliveryDate(c)}} </td>
            </ng-container>

            <!-- Communication delivery details button -->
            <ng-container matColumnDef="deliveryDetails">
              <th mat-header-cell *matHeaderCellDef>Delivery Details:</th>
              <td mat-cell *matCellDef="let c">
                <button mat-button class="button-blue" type="button" (click)=deliveryDetailsModal(c)>
                  Delivery Details
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            (click)="setActiveCommunication(row)"
            class="example-element-row"></tr>
          </table>
    </div>

    <div class="resend-communication">
      <div style="grid-column: 1/3; margin-bottom: 20px;" class="header-border header-label">Communication Details</div>

      
        <div class="label-style">Attachments Sent:</div>
        <div style="grid-column: 2/3; text-align: left;" class="label-style email-details-styling" *ngFor="let attachment of activeCustomerCommunication?.attachments">
          <a href="{{attachment.url}}">{{attachment.name}}</a>
        </div>
      


  <div class="label-style" style="grid-column: 1/2;">To:</div>
<div class="label-style email-details-styling" style="grid-column: 2/3; text-align: left;">{{commuinicationDestinationString}}</div>


  <div class="label-style" style="grid-column: 1/2;">Sent:</div>
  <div class="label-style email-details-styling" style="grid-column: 2/3; text-align: left;">{{actualDeliveryDate}}</div>


  <div class="label-style">Scheduled Delivery:</div>
  <div class="label-style email-details-styling" style="grid-column: 2/3; text-align: left;">{{scheduledDeliveryDate}}</div>


  <div class="label-style">Subject:</div>
  <div class="label-style email-details-styling" style="grid-column: 2/3; text-align: left;">{{activeCustomerCommunication?.subject}}</div>


  <div class="label-style">Body</div>
  <div style="width: 100%; grid-column: 2/3;" fxLayout="row">
    <div fxLayoutAlign="start center">
      <mat-form-field appearance="outline" style="width: 410px;">
        <textarea readonly style="min-height: 240px;" matInput>{{activeCustomerCommunication?.body}}</textarea>
        <!-- <textarea #body matInput formControlName="body" placeholder="Body" style="min-height: 300px;"
        [name]="ElementNameForId('892069')" autofill='off' autocomplete='new-password' data-lpignore='true'></textarea> -->
      </mat-form-field>
    </div>
  </div>
  <!-- <textarea readonly>{{activeCustomerCommunication?.body}}</textarea> -->

<div class="label-style" style="grid-column: 1/2;">Created At:</div>
  <div class="label-style email-details-styling" style="grid-column: 2/3; text-align: left;">{{creationDate}}</div>

<div class="label-style" style="grid-column: 1/2;">State Change Notes:</div>
  <div class="label-style email-details-styling" style="grid-column: 2/3; text-align: left;">{{activeCustomerCommunication?.stateChangeNotes}}</div>

<div class="footer">
  <div><button mat-button (click)="exit()" class="button-gray">Exit</button></div>
  <div style="display: flex; justify-content: flex-end;">
    <div><button mat-button [disabled]="cancelMessageDisabled(activeCustomerCommunication)" (click)="cancelMessage()" class="button-gray">Cancel Message</button></div>
    <div><button mat-button [disabled]="resendDisabled(activeCustomerCommunication)" (click)="resendMessage()" class="button-blue">Resend Message</button></div>
  </div>
</div>
</div>
</div>
