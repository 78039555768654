<h5>
  Select workflows you wish to allow field tech to choose from.
</h5>
<app-mulliselect-nested-tree
  (itemsAddedToSelection$)="workflowSelectionAdded$.next($event)"
  (itemsRemovedFromSelection$)="workflowSelectionRemoved$.next($event)"
  [checkListDatabase]="CheckListDatabase()"
></app-mulliselect-nested-tree>
<!-- Cancel / Submit buttons. -->
<div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%;">
  <div class="button-position">
    <button type="button" mat-button class="button-gray" (click)="close()">Cancel</button>
  </div>
  <div class="button-position">
    <button type="submit" (click)="save()" mat-button class="button-blue">Save</button>
  </div>
