<div style="display:none">{{fields[0].props.changeDetect|async}}</div>
<div [formGroup]="componentForm" style="height:auto;" class="control-attributes active-control">
  <div
  class="rectangle"
  mwlResizable
  [enableGhostResize]="true"
  (resizing)="onLiveResize($event)"
  [style.height.px]="componentForm.get('heightPixels').value"
  >
  <div
    class="resize-handle-bottom"
    mwlResizeHandle
    (mousedown)="suppressDrag($event)"
    [resizeEdges]="{ bottom: true }"
  ></div>
</div>
