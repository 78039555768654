import { Injectable } from '@angular/core'
;
import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { EmployeePermission } from '../dao/employee-permission';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})
export class EmployeePermissionService extends DatabaseStoreService<EmployeePermission> {

  constructor( fs: EmployeePermissionFirestoreService, authenticationService: AuthenticationService, store: EmployeePermissionStoreService) {
super(fs, store, authenticationService);
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmployeePermissionStoreService extends StateChangeStoreService<EmployeePermission> {
  protected store = "EmployeePermission-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, EmployeePermission>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class EmployeePermissionFirestoreService extends FirestoreBackend<EmployeePermission> {

 protected basePath = "EmployeePermission";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): EmployeePermission {
return new EmployeePermission(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new EmployeePermission(), authService);
 }
}
