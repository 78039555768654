import { Injectable } from '@angular/core';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import {  Observable } from 'rxjs';
import { map,  } from 'rxjs/operators';
import { AuthenticationService } from '../../util/authentication.service';
import { Assignment } from '../dao/assignment';
import { QueryConstraint, where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService extends DatabaseStoreService<Assignment> {

  constructor( fs: AssignmentFirestoreService, authenticationService: AuthenticationService, store: AssignmentStoreService) {
    super(fs, store, authenticationService);
  }

  public searchDateRange(minDate: Date, maxDate: Date, employeeDodId: string | null = null ): Observable<Assignment[]> {
    return (this.fs as AssignmentFirestoreService).searchDateRange(minDate, maxDate, employeeDodId);
    }
}

@Injectable({
  providedIn: 'root'
})
export class AssignmentStoreService extends StateChangeStoreService<Assignment> {
  protected store = "assignment-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, Assignment>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class AssignmentFirestoreService extends FirestoreBackend<Assignment> {

  protected basePath = "assignment";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Assignment {
    return new Assignment(obj);
  }


  constructor(protected authService: AuthenticationService) {
    super(new Assignment(), authService);
  }

  public searchDateRange(minDate: Date, maxDate: Date, employeeDodId: string | null = null): Observable<Assignment[]> {

    let commonQuery: QueryConstraint[];
    if (employeeDodId === null) {
      commonQuery = [where('siteVisitStartDate', '>=', minDate),
      where('siteVisitStartDate', '<=', maxDate )];
    } else {
      commonQuery = [where('siteVisitStartDate', '>=', minDate),
        where('siteVisitStartDate', '<=', maxDate ),
        where('employeeDocId','==',employeeDodId )];
    }
    return this.QueryFirestoreShallowValueChanges(commonQuery).pipe(
      map(x => x.filter(a => a.active).sort((a: Assignment, b: Assignment) => a.siteVisitStartDate.getTime() - b.siteVisitStartDate.getTime()))
    );
  }
}
