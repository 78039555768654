import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { JobService } from '../../../../../../common/src/data/dao-services/job.service';
import { Job } from '../../../../../../common/src/data/dao/job';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CancelJobConfirmationModalComponent } from 'web-app/src/app/jobs/cancel-job-confirmation-modal/cancel-job-confirmation-modal.component';
import { PhysicalAddressRoutingService } from '../../../physical-address-routing.service';
import { AddressRoutingService } from '../../../../../../common/src/data/dao-services/address-routing.service';
import { VerticalSchedulerService } from '../../../vertical.scheduler.service';
import { Observable, Subject, of, takeUntil, zip } from 'rxjs';
import { CustomerCommunicationManagementService } from '../../../customer-communication/customer-communication-management.service';
import { SiteVisitRescheduleModalComponent } from '../../../jobs/site-visit-reschedule-modal';
import { SiteVisitRescheduleReasons } from '../../../jobs/site-visit-reschedule-modal/site-visit-reschedule-modal.component';
import { JOB_ATTENTION_REQUIRED, JobAttentionRequired } from '../../../../../../common/src/data/dao/job-attention-required';
import { startOfDay } from 'date-fns';
import { JobDurationDeltaModificationType } from '../../../../../../common/src/data/dao/job-duration-delta';
import { FirestoreBackend } from '../../../../../../common/src/data/database-backend/retrieve-from-firestore';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnDestroy, OnInit {

  @Input() jobsNeedingAssignment: Job[];
  @Output() selectedJob = new EventEmitter();
  @Input() draggingSidebar: Observable<boolean>;

  destroyingComponent$ = new Subject();

  rescheduleSiteVisitDialogRef: MatDialogRef<SiteVisitRescheduleModalComponent>;

  constructor(private jobService: JobService, private dialog: MatDialog, public addrService: AddressRoutingService, private physicalAddressService: PhysicalAddressRoutingService,
    private schedulerService: VerticalSchedulerService, private customerCommunicationManagementService: CustomerCommunicationManagementService, private ref: ChangeDetectorRef ) {
    }
  ngOnInit(): void {
  }

    ngOnDestroy(): void {
      this.destroyingComponent$.next(null);
      this.destroyingComponent$.complete();
      }

    addNote(event: MouseEvent,job: Job) {
      event.stopPropagation();
      const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
        disableClose : false,
        width        : '500px',
        data: {
          title: "Add Scheduling Note",
        }
        });

    this.rescheduleSiteVisitDialogRef = this.dialog.open(SiteVisitRescheduleModalComponent, editorConfig);
    this.rescheduleSiteVisitDialogRef.afterClosed().pipe(
      filter(result => result !== undefined),
      // send along job attention that needs stored to database w/ job.
      map(x => {
        const resecheduleReason = x.reason as SiteVisitRescheduleReasons;
        const jobAttentionRequiredReason = resecheduleReason === SiteVisitRescheduleReasons.NEED_PART ? JOB_ATTENTION_REQUIRED.WAITING_FOR_PART : JOB_ATTENTION_REQUIRED.SCHEDULING_NOTE;
        const jobAttention = new JobAttentionRequired({jobAttentionRequiredReason,notes: x.notes, jobAttentionRequiredSubReason: resecheduleReason.toString()});
        jobAttention.additionalTimeNeedingScheduled = startOfDay(new Date());
        jobAttention.originatingLineItemDocId = null;
        jobAttention.siteVisitDocId = null;
        return jobAttention;
      }),
      switchMap(attention => this.jobService.addJobAttentionRequired(attention,JobDurationDeltaModificationType.SITEVISITRESCHEDULED, job)),
      tap(() => this.ref.markForCheck()),
      take(1)
     ).subscribe();
    }

  formatHours(hours: number): string {
    return `${Math.floor(hours)}H, ${Math.round((hours-Math.floor(hours))*60)}M`;
  }

  trackJob(index: number, job: Job) {
    return job.DocId();
  }

  forceAddressUpdate() {
    // this.addrService.testtestDo();
    console.log(this.addrService.missingMatches.length);
    console.log(this.addrService.missingMatches);
    // this.addrService.missingMatches.forEach(m => {
    //   this.physicalAddressService.addressNeedsPopulatedToSchedule$.next(m);
    // });
  }

  removeFromScheduler(event: MouseEvent,job: Job) {
    event.stopPropagation();
    job.needsAssigned = false;
    this.updateJob(job);
  }

  updateJob(job: Job) {
    this.jobService.update$(job).pipe(
      take(1)
    ).subscribe();
  }

  cancelJob(event: MouseEvent,job: Job) {
    event.stopPropagation();
    const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '700px',
        outerHeight  :  '200px',
        data         :
        {
          job: job
        }
        });
      let dialogRef = this.dialog.open(CancelJobConfirmationModalComponent, editorConfig);
      dialogRef.afterClosed().pipe(
        filter(x => x && x['yes']),
        switchMap(x => FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
          map(batch => ({batch, val: x}) )
        )),
        switchMap(x => this.jobService.update$(job, x.batch).pipe(
          map(() => x),
          take(1)
        )),
        switchMap(x => zip(of(null),...x.val['siteVisitCanceled'].map(s => this.customerCommunicationManagementService.cancelCustomerCommunicationFromSiteVisit(s, "Canceled from sidebar", x.batch))).pipe(
          map(() => x),
          take(1)
        )),
        switchMap(x => zip(of(null),...x.val['siteVisitCanceled'].map(s => this.schedulerService.removeAssignment(s, x.batch))).pipe(
          map(() => x.batch)
        )),
        switchMap(x => this.jobService.commitExternallyManagedFirestoreBatch(x)),
        take(1)
      ).subscribe();
  }

  getAttentionRequired(job: Job) {
    return job.jobAttentionRequired.filter(x=>x.active);
  }

  additionalSiteVisitBooked(job: Job) {
    return job.siteVisits.length > 0;
  }

  clickUnassignedItem(item: Job) {
    this.physicalAddressService.retrieveOnDemandFromDixie(item.serviceAddress).pipe(
      tap(() => {
        this.selectedJob.emit(item);
      }),
      take(1),
    ).subscribe();
  }
}
