import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { format } from 'date-fns';
import { BehaviorSubject, Observable,  Subject, tap, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../common/src/util/authentication.service';
import { CustomerCommunicationManagementService } from 'web-app/src/app/customer-communication/customer-communication-management.service';
import { CustomerCommunicationCategory } from '../../../../../common/src/data/dao/customer-communication-template';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import { Invoice } from '../../../../../common/src/data/dao/invoice';
import { InvoiceDisplayModalComponent } from '../invoice-display-modal/invoice-display-modal.component';
import { JournalEntry } from '../../../../../common/src/data/dao/journal-entry';
import { JournalEntryService } from '../../../../../common/src/data/dao-services/journal-entry.service';
import { InvoiceService } from '../../../../../common/src/data/dao-services/invoice.service';
import { FirestoreBackend } from '../../../../../common/src/data/database-backend/retrieve-from-firestore';

@Component({
  selector: 'app-journal-entry-display',
  templateUrl: './journal-entry-display.component.html',
  styleUrls: ['./journal-entry-display.component.scss']
})
export class JournalEntryDisplayComponent implements OnInit, OnDestroy {

  journalEntries: BehaviorSubject<JournalEntry[]>;
  invoiceDisplayModalDialogRef: MatDialogRef<InvoiceDisplayModalComponent>;
  _totalAmount: Observable<number>;
  destroyingComponent$ = new Subject();

  columnsToDisplay: string[] = ["customerName","dateCreated","paymentType","amount","memo","checkNumber",
  "employee","voided","void","viewInvoices"]

  totalAmount() : Observable<number> {
        return this._totalAmount;
    }

  voidingDetails(j : JournalEntry ) : string {
    return `Voided by: ${j.employeeVoiding.name} on ${format(j.voidedOn,'MM/dd/yyyy')}`;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<JournalEntryDisplayComponent>,private authenticationService: AuthenticationService,
  private journalEntryService: JournalEntryService, private employeeService: EmployeeService, private dialog: MatDialog,
  private customerCommunicationManagementService: CustomerCommunicationManagementService, private invoiceService: InvoiceService) {
    this.journalEntries = data.journalEntries;

    this._totalAmount = this.journalEntries.pipe(
      map(val => val.reduce((acc, curr) =>
        acc + (curr.void ? 0 : curr.amount), 0)),
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  void(j: JournalEntry) {
    if(confirm("Are you sure you want to delete journal entry? ")) {
      // roll back invoice payments associated w/ journal entry, and update form model firestore so it regenerates when new communications go out.
      j.invoicePayments.forEach(invoicePayment => {
        invoicePayment.invoice.amountPaid -= invoicePayment.amount;
        invoicePayment.void = true;
      });
      j.void = true;
      j.employeeVoiding = this.employeeService.get(this.authenticationService.activelyLoggedInEmployeeDocId);
      j.voidedOn = new Date();
      let b: string = null;
    FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
      tap(batch => b = batch),
      switchMap(() => this.journalEntryService.update$(j,b)),
        take(1),
        map(j => {
          const firestoreUpdates: Observable<any>[] = [];
          j.invoicePayments.forEach(i => firestoreUpdates.push(this.customerCommunicationManagementService.cancelCustomerCommunicationsForInvoice(i.invoiceDocId, "JournalEntryDisplayComponent.void",b)));
          return firestoreUpdates;
        }),
      switchMap(cancels => zip(...cancels)),
      switchMap(() => this.journalEntryService.commitExternallyManagedFirestoreBatch(b)),
      map(() => j.invoicePayments.map(i => i.invoice)),
      switchMap(invoices => this.invoiceService.popluateJobsToInvoices(invoices)),
      switchMap(invoices => zip(...invoices.map(ip => this.customerCommunicationManagementService.createInvoiceCustomerCommuinications(ip.job,
        CustomerCommunicationCategory.PAYMENT,ip.job.siteVisits[0], ip, "journal entry display component")))),
        take(1)
      ).subscribe(() => this.dialogRef.close());
    }
  }

  viewInvoices(j: JournalEntry) {

    const editorConfig = new MatDialogConfig();
    const invoices$: BehaviorSubject<Invoice[]> = new BehaviorSubject<Invoice[]>(j.invoicePayments.map(i => i.invoice));

    Object.assign(editorConfig, {
      disableClose : false,
      width        : '500px',
      data         :
      {
        invoices$
      }
      });

    this.invoiceDisplayModalDialogRef = this.dialog.open(InvoiceDisplayModalComponent, editorConfig);
  }
}
