import { Address } from "./address";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Industry } from "./industry";


export class CompanySettings extends RetrieveFirestorePropertiesBase<CompanySettings>  {

  // Custom objects.
mailingAddressDocId : string;
mailingAddress: Address;
dispatchAddressDocId: string;
dispatchAddress: Address;
industryDocId: string;
industry: Industry;

referralCode: string = "";
website: string = "";
companyEmailAddress: string = "";
companyPhoneNumber: string = "";
companyName: string = "";

defaultFormFirestoreSummaryDocId: string = "";
defaultFormFirestoreSummaryEstimateDocId: string = "";
defaultFormFirestoreSummaryInvoiceDocId: string = "";
currentJobCounter: number = 1;
jobNumberFormatting: string = "#<<counter>>";
companyBccEmailAddress: string = "";

triggerEstimateAndInvoiceCommunicationsOnWorkflowCompletion: boolean = true;


static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["mailingAddress","dispatchAddress","industry"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["mailingAddressDocId","dispatchAddressDocId","industryDocId"]);
static _firestoreCompositionMemberNames = ["mailingAddress","dispatchAddress","industry"];
static _firestoreCompositionalDiffMemberNames: string[] = ["mailingAddress","dispatchAddress","industry"];

retrieveFirestoreIgnoredMemberNames() : string[] { return CompanySettings._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return CompanySettings._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return CompanySettings._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return CompanySettings._firestoreCompositionMemberNames;}

  public constructor(init?: Partial<CompanySettings>) {
    super();
    Object.assign(this, init);
  }
}
