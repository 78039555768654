<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

  <!-- Node without children. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="20">
    <button mat-icon-button disabled></button>
    <div (click)="toggleNode(node)" [ngClass]="getStyleClasses(node)" style="padding: 10px; display: flex; align-items: center;">
      <mat-icon style="color: #EBD7BD; margin-right: 10px;">folder</mat-icon><div>{{node.name}}</div>
    </div>
    <!-- Description here is a tooltip. -->
    <div *ngIf="node.description">
      <mat-icon matTooltip="{{node.description}}" matTooltipPosition="right">info</mat-icon>
    </div>
  </mat-tree-node>

  <!-- Node with children. -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : treeControl.isExpandable(node) ? 'chevron_right' : null}}
      </mat-icon>
    </button>
    <div (click)="toggleNode(node)" [ngClass]="getStyleClasses(node)" style="padding: 10px;  display: flex; align-items: center;">
      <mat-icon style="color: #EBD7BD; margin-right: 10px;">folder</mat-icon><div>{{node.name}}</div>
    </div>
    <div *ngIf="node.description">
      <mat-icon matTooltip="{{node.description}}" matTooltipPosition="right">info</mat-icon>
    </div>
  </mat-tree-node>
</mat-tree>
