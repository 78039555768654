import { Component, OnInit,  Output, EventEmitter, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Customer } from '../../../../../common/src/data/dao/customer';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { merge, Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MultipleJobSummaryComponent } from 'web-app/src/app/multiple-job-summary/multiple-job-summary.component';
import { AddressRouting } from '../../../../../common/src/data/dao/address-routing';
import { Address } from '../../../../../common/src/data/dao/address';
import { randomElementName } from '../../../../../common/src/util/util';
import { AddressService } from '../../../../../common/src/data/dao-services/address.service';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { limit, where } from 'firebase/firestore';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements OnInit {

  @ViewChild("inputBox") _el: ElementRef;

  @Input() addressToShopRouting: AddressRouting | null;

  @Input() addressCustomersFound$: Observable<{address: Address, customer?: Customer}[]>;
  @Input() form: UntypedFormGroup;
  @Input() previousJobCountAtAddress: string | null;

  @Output() search = new EventEmitter();
  @Output() selectedAddress = new EventEmitter<Address>();
  @Output() selectedCustomer = new EventEmitter<Customer>();
  @Output() customerPresent = new EventEmitter<boolean>();

  @Output() unitUpdated = new EventEmitter<string>();

  searchRaw$ = new Subject<string>();

  activeSearch: boolean;
  skipAddressSearchEmissionOnSelect: boolean = false;
  displayNoResultsFound: boolean = false;
  randomElementName : string = randomElementName();

  destroyingComponent$: Subject<any> = new Subject();
  address: Address;

  PropertyHistoryModalRef: MatDialogRef<MultipleJobSummaryComponent>;


  get disablePropertyHistory() { return this.previousJobCountAtAddress === "0"; }

  formatSearchTextFromSelectedValue(selectedVal : {address: Address, customer?: Customer}) : string {
    if (selectedVal.customer !== null) {
      return `${selectedVal.customer.customerName} - ${selectedVal.address.formattedAddress()}`;
    } else {
      return selectedVal.address.formattedAddress();
    }
  }

  public ViewPropertyHistoryClick() {
    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         :
      {
        title: "Service Address History",
        jobs$: this.jobService.queryFirestoreDeep$([where('serviceAddressDocId', '==',this.address.DocId()),
          limit(25)]),
      }
      });
    this.PropertyHistoryModalRef = this.dialog.open(MultipleJobSummaryComponent, editorConfig);
  }

  searchAddressSearchKeyUp(e: KeyboardEvent) {
    this.searchRaw$.next((e.target as HTMLTextAreaElement).value);
  }


  optionSelected(e: MatAutocompleteSelectedEvent) {
    const searchValue : {address: Address, customer?: Customer} = e.option.value;
    this.form.patchValue({ serviceAddressSearch : this.formatSearchTextFromSelectedValue(searchValue), unit: searchValue.address.unit});
    this.skipAddressSearchEmissionOnSelect = true;

    // If customer is not populated, then orgin of address was other then internal.  Must query for duplicate google place id to prevent adding address multiple times.
      if (searchValue.customer === null) {
        this.addressService.queryFirestoreDeep$([where('googlePlaceId','==',searchValue.address.googlePlaceId),
          limit(1)]).pipe(
          map(x => {
            if (x.length > 0) {
              searchValue.address = x[0];
            }
            return searchValue;
          }),
          tap(x => this.selectedAddress.emit(searchValue.address)),
          take(1)).subscribe();
      } else {
        this.selectedAddress.emit(searchValue.address);
      }

    if (searchValue.customer !== null) {
      this.selectedCustomer.emit(searchValue.customer);
      this.customerPresent.emit(true);
    } else {
      this.customerPresent.emit(false);
    }
  }

  constructor(private fb: UntypedFormBuilder, private addressService: AddressService, private jobService: JobService, private dialog: MatDialog) { }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => this._el.nativeElement.focus(), 0);
  }

  ngOnInit(): void {

    this.addressCustomersFound$.pipe(
      tap(() => this.activeSearch = false),
      tap(x => this.displayNoResultsFound = x.length === 0),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    //only emit search when not caused by selection of item.
    const searchToEmit = this.searchRaw$.pipe(
      filter(() => !this.skipAddressSearchEmissionOnSelect),
      distinctUntilChanged(),
      tap(() => this.displayNoResultsFound = false),
      tap(() => this.addressToShopRouting = null),
      debounceTime(300),
      tap(x => this.activeSearch = true),
      tap(x => this.search.emit(x)));

    const searchToSkip = this.searchRaw$.pipe(
      filter(() => this.skipAddressSearchEmissionOnSelect),
      tap(() => this.skipAddressSearchEmissionOnSelect = false));

      merge(searchToEmit,searchToSkip).pipe(
        takeUntil(this.destroyingComponent$)).subscribe();


    this.form.controls["unit"].valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroyingComponent$),
    ).subscribe(unit => this.unitUpdated.emit(unit));


    this.selectedAddress.pipe(
      tap(x => this.address = x),
      tap(x => console.log(x,` string`)),
      tap(x => console.log(`this.address set to: ${x.formattedAddress()}`)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.search.pipe(
      distinctUntilChanged(),
      tap(x  => {
        if (this.address !== undefined && this.address.formattedAddress() !== x) {
          console.log(this.address.formattedAddress());
          this.address = undefined;
          console.log(`this.address set to: undefined`);
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }
}
