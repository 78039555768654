import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AngularFirestorageService } from '../angular-firestorage.service';
import { BranchingContainerComponent } from './branching-container/branching-container.component';
import { ChoiceComponent } from './choice/choice.component';
import { ColumnSplitterComponent } from './column-splitter/column-splitter.component';
import { ControlContainerComponent } from './component-models/control-container.component';
import { FormlyUtilityService } from './component-models/formly-controls/formly-utility.service';
import { ImageControlComponent } from './component-models/image-control/image-control.component';
import { LineItemsComponent } from './component-models/line-items/line-items.component';
import { RatingControlDefaultService } from './component-models/rating-control/rating-control-default.service';
import { RatingControlComponent } from './component-models/rating-control/rating-control.component';
import { SignaturePadEditorComponent } from './component-models/signature-pad-editor/signature-pad-editor.component';
import { TextboxControlComponent } from './component-models/textbox-control/textbox-control.component';
import { WhitespaceControlComponent } from './component-models/whitespace-control/whitespace-control.component';
import { DataLinkService } from './data-link-populator/data-link.service';
import { PageBreakComponent } from './page-break/page-break.component';
import { PhotoAdderComponent } from './photo-adder/photo-adder.component';
import { FormFirestoreService } from '../../../../common/src/data/dao-services/form-firestore.service';
import { FormCatagoryService } from '../../../../common/src/data/dao-services/form-catagory.service';
import { FormlyComponentUtilityService } from './component-models/formly-controls/formly-component-utility.service';
import { FormFirestoreSummaryService } from '../../../../common/src/data/dao-services/form-firestore-summary.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentFromFormlyFactoryService {

  constructor(private fb: UntypedFormBuilder, private afs: AngularFirestorageService, private http: HttpClient, private ratingDefaultService: RatingControlDefaultService,
    private dataLinkService: DataLinkService, private ngZone : NgZone, private formlyUtilityService:  FormlyUtilityService, private formlyComponentUtilityService: FormlyComponentUtilityService,
    private formFirestoreService: FormFirestoreService, private formCatagoryService: FormCatagoryService) { }

  generateComponent(field: FormlyFieldConfig) : ControlContainerComponent
  {
    let retVal : ControlContainerComponent;

    switch (field.type) {
      case "input":
      case "textarea":
      case "label":
      case "formlyTextAreaAutoSizing":
        retVal = new TextboxControlComponent(this.fb, this.dataLinkService);
        if (field.type === "label") {
          (retVal as TextboxControlComponent).convertToLabel();
        }
        break;
      case "formlyHeightSpacer":
        retVal = new WhitespaceControlComponent(this.fb);
        break;
      case "formlyImage":
        retVal = new ImageControlComponent(this.fb, this.afs, this.http);
        break;
      case "radio":
      case "select":
      case "multicheckbox":
        retVal = new ChoiceComponent(this.fb);
        break;
      case "formlyRating":
        retVal = new RatingControlComponent(this.fb,this.ratingDefaultService);
        break;
      case "formlySignaturePad":
        retVal = new SignaturePadEditorComponent(this.fb);
        break;
      case "formlyPageBreak":
      retVal = new PageBreakComponent(this.fb);
      break;
      case "formlyPhotoAdder":
        retVal = new PhotoAdderComponent(this.fb)
        break;
      case "formlySplitter":
        retVal = new ColumnSplitterComponent(this.fb, this,this.ngZone, this.formlyComponentUtilityService);
        break;
      case "formlyLineItems":
        // field.modelOptions.updateOn = 'change';
        retVal = new LineItemsComponent(this.fb);
        break;
      case "formlyBranchingContainer":
        retVal = new BranchingContainerComponent(this.fb, this.formlyUtilityService, this.formFirestoreService);
        break;
      default:
        throw new Error(`You need to define component type ${field.type} before you re-hydrate it!`);
    }

    return retVal;
  }

}
