import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { JOB_STATUS } from "./job";

export enum EXPLICIT_JOB_EVENT {
  UNKNOWN = "Unknown",
  MARKED_COMPLETED = "Marked Completed",
  READY_TO_SUBMIT_PAPERWORK = "Ready to Submit Paperwork",
  REOPENED = "Reopened",

  CANCELED = "Canceled",
  REACTIVATED = "Reactivated",
  IMPORTED = "Imported",
}

export class JobEvent extends RetrieveFirestorePropertiesBase<JobEvent>  {

  eventType: EXPLICIT_JOB_EVENT = EXPLICIT_JOB_EVENT.UNKNOWN;
  message: string = "";


  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
  static _firestoreCompositionMemberNames = [];
  static _firestoreCompositionalDiffMemberNames: string[] = [];

  retrieveFirestoreIgnoredMemberNames() : string[] { return JobEvent._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return JobEvent._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return JobEvent._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return JobEvent._firestoreCompositionMemberNames;}

  public constructor(init?: Partial<JobEvent>) {
  super();
  Object.assign(this, init);
  }

  public static GetExplicitJobEventStatus(jobEvents: JobEvent[]) : JOB_STATUS {
    if (jobEvents === undefined || jobEvents.length === 0) {
      return JOB_STATUS.UNKNOWN;
    } else {
      const lastEvent = jobEvents[jobEvents.length - 1];
      switch (lastEvent.eventType) {
        case EXPLICIT_JOB_EVENT.MARKED_COMPLETED:
        case EXPLICIT_JOB_EVENT.READY_TO_SUBMIT_PAPERWORK:
          return JOB_STATUS.COMPLETED;
        case EXPLICIT_JOB_EVENT.CANCELED:
          return JOB_STATUS.CANCELLED;
        case EXPLICIT_JOB_EVENT.REOPENED:
          return JOB_STATUS.IN_PROGRESS;
        case EXPLICIT_JOB_EVENT.IMPORTED:
          return JOB_STATUS.IMPORTED;
        default:
          return JOB_STATUS.UNKNOWN;
      }
    }
    }
}
