import { Customer } from './customer';
import { LineItem } from './line-item';
import { Address } from './address';
import { SiteVisit } from './site-visit';
import { JobDurationDelta } from './job-duration-delta';
import { GenericServiceProviderSetting } from './generic-service-provider-setting';
import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';
import { JobAttentionRequired } from './job-attention-required';
import { JobEvent } from './job-event';
import { FormModelFirestore } from './form-model-firestore';
import { JobType } from './job-type';
import { Invoice } from './invoice';
import { Attachment } from './attachment';
import { Discount } from './discount';
import { CompanyLocation } from './company-location';


export interface CustomerWithRoleOnJobAndPrimaryDesignation {
  customer: Customer, role: string, primary: boolean
}

export enum JOB_STATUS {
  UNKNOWN = "Unknown",

  UNSCHEDULED = "Unscheduled",
  SCHEDULED = "Scheduled",
  IN_PROGRESS = "In Progress",
  LINE_ITEMS_COMPLETE = "Line Items Complete",

  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  IMPORTED = "Imported",
}

export enum JOB_PAYMENT_STATUS {
  UNPAID = "Unpaid",
  PARTIAL = "Partial",
  PAID = "Paid",
  IMPORTED = "Imported",
}

export class Job extends RetrieveFirestorePropertiesBase<Job>  {

paymentStatus: JOB_PAYMENT_STATUS;

jobDocId: string = undefined;
notes: string;

// Denormalized:
//  Done:  siteVisits
//  Possible: jobDurationDelta, lineItems

// Cached no worries JobType, jobPriority, jobTags
// 2nd layer:  Customer

// Custom objects.
billingCustomerDocIds: string[] = [];
billingCustomers: Customer[] = [];
siteVisitContactCustomerDocIds: string[] = [];
siteVisitContactCustomers: Customer[] = [];

//Principal customer.
customerDocId: string;
customer: Customer;

serviceAddressDocId: string;
serviceAddress: Address;
lineItemDocIds: string[] = [];
lineItems: LineItem[] = [];
abandonedLineItems: LineItem[] = [];
abandonedLineItemDocIds: string[] = [];
importedJob: boolean = false;

siteVistDocIds: string[] = [];
siteVisits: SiteVisit[] = [];
jobDurationDeltaIds: string[] = [];
jobDurationDeltas: JobDurationDelta[] = [];

locationAssignedWork: CompanyLocation;
locationAssignedWorkDocId: string;

discountDocIds: string[] = [];
discounts: Discount[] = [];

formModelFirestoreDocId: string;
formModelFirestore: FormModelFirestore;
needsAssignedStateWhenCanceled: boolean | null = null;

serviceProviderSpecifiedJobId: string | null = null;

// Common Service Provider Settings
jobTypeDocId: string;
jobType: JobType;
jobPriorityDocId: string;
jobPriority: GenericServiceProviderSetting;
jobTagDocIds: string[] = [];
jobTags: GenericServiceProviderSetting[] = new Array<GenericServiceProviderSetting>();
startDate: Date = null;
endDate: Date = null;

explicitJobEvents: JobEvent[] = [];
explicitJobEventDocIds: string[] = [];

jobAttentionRequiredDocIds: string[] = [];
jobAttentionRequired : JobAttentionRequired[] = [];

externalAttachmentDocIds: string[] = [];
externalAttachments: Attachment[] = [];

commutePopulationEventId: string = "";

get id(): string { return this.jobDocId; }

static generateServiceProviderSpecificJobIdentifier(jobNumberFormatting: string, counter: number) : string {
  if (jobNumberFormatting.includes("<<counter>>")) {
    return jobNumberFormatting.replace("<<counter>>", counter.toString());
  } else {
    return undefined;
  }
}

retrieveCustomersWithRolesAndPrimaryDesignations() : CustomerWithRoleOnJobAndPrimaryDesignation[] {
  const siteVisitCustomerDocIds = this.siteVisitContactCustomerDocIds;
        const billingCustomerDocIds = this.billingCustomerDocIds;
        const dualCustomerDocIds = siteVisitCustomerDocIds.filter(function(n) {return billingCustomerDocIds.indexOf(n) !== -1;});
        const retVal = [{customer: this.customer, role: dualCustomerDocIds.some(d => d === this.customerDocId) ? "All" :
          billingCustomerDocIds.some(b => b === this.customerDocId) ? "Billing Contact" : "Site Visit Contact", primary: true}];
        retVal.push(...this.siteVisitContactCustomers.filter(s => dualCustomerDocIds.some(z => z === s.DocId()) && s.DocId() !== this.customerDocId)
          .map(c => ({customer: c, role: "All", primary: false})));
        retVal.push(...this.billingCustomers.filter(b => !dualCustomerDocIds.some(z => z === b.DocId()) && b.DocId() !== this.customerDocId)
          .map(c => ({customer: c, role: "Billing Contact", primary: false})))
        retVal.push(...this.siteVisitContactCustomers.filter(b => !dualCustomerDocIds.some(z => z === b.DocId())&& b.DocId() !== this.customerDocId)
        .map(c => ({customer: c, role: "Site Visit Contact", primary: false})))
        return retVal;
}

updateJobPaymentStatus(invoices: Invoice[]) {
  if (this.jobStatus === JOB_STATUS.IMPORTED ) {
    this.paymentStatus = JOB_PAYMENT_STATUS.IMPORTED;
  }
  else {
    if (invoices.length === 0) {
      this.paymentStatus = JOB_PAYMENT_STATUS.UNPAID;
    } else {
      if (invoices.find(x => x.outstandingDue > 0) && invoices.find(x => x.amountPaid > 0)) {
        this.paymentStatus = JOB_PAYMENT_STATUS.PARTIAL;
      }
      else {
        if (invoices.find(x => x.outstandingDue > 0)) {
          this.paymentStatus = JOB_PAYMENT_STATUS.UNPAID;
        } else {
          this.paymentStatus = JOB_PAYMENT_STATUS.PAID;
        }
      }
    }
  }
}

get jobStatus() : JOB_STATUS {
  let retVal = JOB_STATUS.UNSCHEDULED;
  if (this.siteVisits.length > 0) {
    retVal = JOB_STATUS.SCHEDULED;
  }
  if (this.siteVisits.find(x => x.actualStartDate !== undefined) !== undefined) {
    retVal = JOB_STATUS.IN_PROGRESS;
  }
  if (this.lineItems.find(x => !x.completed  && !x.abandoned) === undefined) {
    retVal = JOB_STATUS.LINE_ITEMS_COMPLETE;
  }
  // get status based on explicit events, this over-rides
  const explicitStatus = JobEvent.GetExplicitJobEventStatus(this.explicitJobEvents);
  if (explicitStatus !== JOB_STATUS.UNKNOWN) {
    return explicitStatus;
  }
  return retVal;
}

jobStatusUpdateDate() : Date | null {
  // get status based on explicit events, this over-rides
  const explicitStatus = JobEvent.GetExplicitJobEventStatus(this.explicitJobEvents);
  if (explicitStatus !== JOB_STATUS.UNKNOWN) {
    return this.explicitJobEvents[this.explicitJobEvents.length - 1].createdAt;
  } else {
    return null;
  }
}

get remainingToScheduleHours(): number {
  const rescheduledLineItemHours = this.lineItems.filter(x => x.lineItemNeedsOrHasBeenRescheduled).map(t => t.expectedDurationHours).reduce((acc, value) => acc + value, 0);
  let retVal =  Math.round(((rescheduledLineItemHours + this.durationExpectedHours - this.siteVisitScheduledHours) * 100)) / 100;
  retVal = retVal > 0 ? retVal : 0;
  return retVal;
}

get lineItemExpectedHours() : number {
  return this.lineItems.map(t => t.expectedDurationHours).reduce((acc, value) => acc + value, 0);
}

get firstSiteVisitDocId() : string {
  return this.siteVisits.sort((a, b) => a.startDate.getTime() > b.startDate.getTime() ? 1 : -1 )[0].DocId();
}

get lastSiteVisitDocId() : string {
  return this.siteVisits.sort((a, b) => a.startDate.getTime() > b.startDate.getTime() ? -1 : 1 )[0].DocId();
}

get durationExpectedHours(): number {
  const lineItemHours = this.lineItemExpectedHours;
  const rescheduledLineItemHours = this.lineItems.filter(x => x.lineItemNeedsOrHasBeenRescheduled).map(t => t.expectedDurationHours).reduce((acc, value) => acc + value, 0);
  const jobDurationDeltaHours = this.jobDurationDeltas.filter(f=>f.active).map(d => d.deltaHours).reduce((acc, value) => acc + value, 0);
  return Math.round((lineItemHours - rescheduledLineItemHours + jobDurationDeltaHours) * 100) / 100;
}

get siteVisitScheduledHours(): number {
  return this.siteVisits === null ? 0 : Math.round(this.siteVisits.map(x => x.expectedDurationHours)
  .reduce((acc, value) => acc + value, 0) * 100) / 100;
}

get actualTimeSpentOnJobHours() : number {
  return this.siteVisits === null ? 0 : Math.round(this.siteVisits.map(x => x.actualDurationHours)
  .reduce((acc, value) => acc + value, 0) * 100) / 100;
}

get remainingScheduledTimeOnJobHours() : number {
  return this.siteVisits === null ? 0 : Math.round(this.siteVisits.filter(x => x.startDate &&  x.startDate > new Date()).map(x => x.expectedDurationHours)
  .reduce((acc, value) => acc + value, 0) * 100) / 100;
}

// serviceProviderJobId: string;

get serviceProviderJobId() : string { return this.serviceProviderSpecifiedJobId ? ` ${this.serviceProviderSpecifiedJobId}` : ` ${this.DocId().substring(0,5)}`; }

// ****************** Deprecate these ***********************
// This derives from job type.
get jobColor(): string {return this.jobType.color; }
needsAssigned = true;
// For moment, job.Type but.... but we will see.  Ideally would be settable by SP depending on what they find useful.
name: string;
// This seems derived, wait to see what it is used for.
description = "";
// ****************** End DEPRECATION! ***********************


DocId(): string {
  return this.jobDocId;
}
SetDocId(docId: string): void {
  this.jobDocId = docId;
}

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["customer", "serviceAddress", "jobType", "jobPriority",
"jobTags", "lineItems",  "firestoreDiffs", "siteVisits", "jobDurationDeltas", "billingCustomers",
"siteVisitContactCustomers", "formModelFirestore", "abandonedLineItems","jobAttentionRequired","paymentStatus", "explicitJobEvents",
"externalAttachments", "discounts", "locationAssignedWork"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
  .concat(["customerDocId", "serviceAddressDocId", "jobTypeDocId", "jobPriorityDocId", "jobTagDocIds", "startDate", "endDate",
"lineItemDocIds", "firestoreDiffs", "firestoreDiffDocIds", "siteVistDocIds", "jobDurationDeltaIds",
"billingCustomerDocIds", "formModelFirestoreDocId","abadonedLineItemDocIds","jobAttentionRequiredDocIds","paymentStatus","explicitJobEventDocIds",
"externalAttachmentDocIds", "discountDocIds", "locationAssignedWorkDocId", "commutePopulationEventId"]);
// Think a bit on serviceAddress b/c of the notes field, and general address immutability.
static _firestoreCompositionMemberNames = ["customer", "lineItems", "serviceAddress", "jobType",
                                   "siteVisits", "jobDurationDeltas",  "jobTags", "jobPriority", "billingCustomers", "siteVisitContactCustomers",
                                   "formModelFirestore", "abandonedLineItems","jobAttentionRequired","explicitJobEvents",
                                  "externalAttachments", "discounts", "locationAssignedWork" ];

static _firestoreCompositionalDiffMemberNames: string[] = ["customer", "serviceAddress", "jobType", "jobPriority",
                                                   "jobTags", "lineItems", "siteVisits", "jobDurationDeltas",
                                                   "billingCustomers", "siteVisitContactCustomers", "formModelFirestore","abandonedLineItems",
                                                   "explicitJobEvents","jobAttentionRequired","externalAttachments", "discounts", "locationAssignedWork"];

//  static _firestoreCompositionDenormalized: string[] = ["customer","serviceAddress","siteVisits"];

static _firestoreCompositionDenormalized: string[] = ["customer", "lineItems", "serviceAddress", "jobType",
                                   "siteVisits", "jobDurationDeltas",  "jobTags", "jobPriority", "billingCustomers", "siteVisitContactCustomers",
                                   "formModelFirestore", "abandonedLineItems","jobAttentionRequired","explicitJobEvents",
                                  "externalAttachments", "discounts", "locationAssignedWork" ];

retrieveFirestoreIgnoredMemberNames() : string[] { return Job._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Job._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Job._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Job._firestoreCompositionMemberNames;}
retrieveFirestoreDenormalizedMemberNames(): string[] { return Job._firestoreCompositionDenormalized;}


public constructor(init?: Partial<Job>) {
  super();
  Object.assign(this, init);
  if (this.jobTagDocIds === null) {
    this.jobTagDocIds = [];
  }
}

generateHeaderForEventDetailsModal(siteVisitDocId: string) {
  const position = this.siteVisits.sort((a, b) => a.startDate.getTime() > b.startDate.getTime() ? 1 : -1 ).map(x=>x.DocId()).indexOf(siteVisitDocId) + 1;
  const total = this.siteVisits.length;
  return `Site Visit ${position} of ${total}`;
}
}
