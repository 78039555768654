<app-navigation></app-navigation>
<div class="container1" [formGroup]="form">
  <div class="left-side"></div>
  <div class="middle">
    <div class="main" fxLayout="column">
        <div class=cust-job-section fxLayout="row">
          <div class="tester">
              <div class="customer-section">


              <div class="section-header" style="margin-left: 20px;">
                <div style="width: 100%;">
                  <div class="align-header">
                <div style="display: flex; align-items: center;">
                  <div>Customer Details</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                <button mat-button class="button-green" (click)="CreateNewCustomer()">Add New Customer</button>
                <button mat-button class="button-blue" (click)="DisplayCustomerCommunicationModal()">View Customer Communications</button>
                </div>
                </div>
                <hr>
                </div>
              </div>


              <!-- EDITABLE "Standard Form" DATA-->

                  <!-- NAME -->
                  <div class="label-style">Name</div>
                  <div class="medium-field">
                    <mat-form-field appearance="outline" style="width:280px;">
                      <input placeholder="Name" matInput formControlName="customerName"
                      [name]="ElementNameForId('088116')" autofill='off' autocomplete='new-password' data-lpignore='true'>
                      <mat-error>Customer name is required</mat-error>
                    </mat-form-field>
                  </div>
                  <!-- END NAME -->


                  <!-- EMAIL -->
                  <div class="label-style" style="grid-column: 1/2;">Email</div>
                  <div class="medium-field">
                    <mat-form-field appearance="outline" style="width:280px;">
                      <input (focusout)="onEmailFocusOut()" placeholder="Email" matInput formControlName="customerEmail"
                      [name]="ElementNameForId('868172')" autofill='off' autocomplete='new-password' data-lpignore='true'>
                      <mat-error>Not a valid email</mat-error>
                    </mat-form-field>
                  </div>
                  <!-- END EMAIL -->

                  <!-- PRIMARY PHONE NUMBER -->
                  <div class="label-style" style="grid-column: 1/2;">Primary Phone</div>
                  <div class="phone-alignment">
                  <div class="medium-field">
                    <mat-form-field appearance="outline" style="width:280px;">
                      <input (focusout)="onPhoneNumberFocusOut()" placeholder="Phone Number" type="text" matInput mask="(000) 000-0000" formControlName="primaryPhoneNumber"
                      [name]="ElementNameForId('581831')" autofill='off' autocomplete='new-password' data-lpignore='true'>
                      <mat-error>Phone number is required.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="short-field" style="margin-left: 20px;">
                    <mat-form-field appearance="outline" style="width:75px;">
                      <input placeholder="ext." type="text" matInput  formControlName="primaryPhoneNumberExtension"
                      [name]="ElementNameForId('493618')" autofill='off' autocomplete='new-password' data-lpignore='true'>
                    </mat-form-field>
                  </div>
                  </div>
                  <!-- END PRIMARY PHONE NUMBER -->

                  <!-- SECONDARY PHONE NUMBER -->
                  <div class="label-style" style="grid-column: 1/2;">Alternative Phone</div>
                  <div class="phone-alignment">
                  <div class="medium-field">
                    <mat-form-field appearance="outline" style="width:280px;">
                      <input placeholder="Alternative Phone Number" type="text" matInput mask="(000) 000-0000" formControlName="alternativePhoneNumber"
                      [name]="ElementNameForId('774371')" autofill='off' autocomplete='new-password' data-lpignore='true'>
                    </mat-form-field>
                  </div>
                  <div class="short-field" style="margin-left: 20px">
                    <mat-form-field appearance="outline" style="width:75px;">
                      <input placeholder="ext." type="text" matInput  formControlName="alternativePhoneNumberExtension"
                      [name]="ElementNameForId('500837')" autofill='off' autocomplete='new-password' data-lpignore='true'>
                    </mat-form-field>
                  </div>
                  </div>
                  <!-- END SECONDARY PHONE NUMBER -->

                  <!-- CUSTOMER TYPE -->
                  <div class="label-style" style="grid-column: 1/2;">Customer Type</div>
                  <div class="medium-field">
                    <mat-form-field appearance="outline" class="form-align">
                          <mat-select formControlName="customerType" placeholder="Customer Type" [compareWith]="compareCommonServiceProviderSettings">
                          <mat-option *ngFor="let customerType of customerTypes$|async" [value]="customerType" >
                            {{customerType.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
                  <!-- END CUSTOMER TYPE -->

                  <!-- COMPANY -->
                  <div class="label-style" style="grid-column: 1/2;">Company</div>
                  <div class="medium-field">
                    <mat-form-field appearance="outline" style="width:280px;">
                      <input placeholder="Company Name" matInput formControlName="company"
                      [name]="ElementNameForId('355496')" autofill='off' autocomplete='new-password' data-lpignore='true'>
                    </mat-form-field>
                  </div>
                  <!-- END COMPANY -->

                  <!-- REFERRAL -->
                  <div class="label-style" style="grid-column: 1/2;">Referral</div>
                  <div class="medium-field">
                   <mat-form-field appearance="outline">
                     <mat-select placeholder="Customer Referral" formControlName="customerReferral" [compareWith]="compareReferralCampaigns">
                       <mat-option *ngFor="let referral of activeReferralCampaigns$ | async" [value]="referral">
                         {{referral.name}}
                       </mat-option>
                     </mat-select>
                     </mat-form-field>
                  </div>
                  <!-- END REFERRAL -->

                  <div class="label-style" style="grid-column: 3/4; grid-row: 2/3">Customer Balance Due</div>
                  <div style="grid-column: 4/5; grid-row: 2/3;">
                 <app-customer-balance
                      *ngIf="form && (form.get('customer').value !== null)"
                      [customer]="activeCustomer"
                    ></app-customer-balance>
                    </div>




              <!-- ADDRESS -->

              <div class="label-style" style="grid-column: 3/4; grid-row: 3/4">Billing Address</div>
              <div style="grid-column: 4/5; grid-row: 3/5;">
              <div>
              <mat-form-field appearance="outline" style="width: 330px;">
                <input matInput placeholder="Address"  formControlName="billingAddressDisplay">
                <mat-error>Billing address is required.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 75px; margin-left: 20px;">
                <input matInput placeholder="Unit" formControlName="billingAddressUnit">
              </mat-form-field>
            </div>
                <button mat-button class="button-light-blue" (click)="updateBillingAddress()">Change Billing Address</button>
              </div>

            <!-- END ADDRESS -->

                  <!-- NOTES -->
                  <div class="label-style" style="grid-column: 3/4; grid-row: 5/6;">Notes</div>
                  <div class="medium-field" style="grid-column: 4/5; grid-row: 5/7;">
                    <mat-form-field appearance="outline" style="width:425px;">
                      <textarea placeholder="Notes" matInput formControlName="notes"
                      [name]="ElementNameForId('250351')" autofill='off' autocomplete='new-password' data-lpignore='true'></textarea>
                    </mat-form-field>
                  </div>
                  <!-- END NOTES -->

                  <!-- CUSTOMER TAGS -->
                  <div class="label-style" style="grid-column: 3/4; grid-row: 7/8;">Notes</div>
                  <div style="grid-column: 4/5; grid-row: 7/9;">
                  <app-custom-tags class="form-align field-stretch"
                    [title] = "customTagComponentInputs.title"
                    [assignedTags] = "customTagComponentInputs.assignedTags"
                    [existingTags] = "customTagComponentInputs.existingTags | async"
                    [existingTagsUpdated] = "customTagComponentInputs.existingTagsUpdated"
                    [disableInput] = "customTagComponentInputs.disableInput"
                    (tagSelectedForRemoval) = "customTagComponentOutputs.tagSelectedForRemoval.next($event)"
                    (tagSelectedForAddition) = "customTagComponentOutputs.tagSelectedForAddition.next($event)"
                    (newlyCreatedTagForAdditon) = "customTagComponentOutputs.newlyCreatedTagForAdditon.next($event)">
                  </app-custom-tags>
                  </div>
                  <!-- END CUSTOMER TAGS -->

                    <!-- End of editable customer data. -->

                  </div>
              </div>
          </div>



          <!-- This is for tall monitors as taller monitors should be able to see all of the table so no tabs are needed -->
<!-- <div class="tall-screen" style="margin-top: 70px;">
  <div class="section" style="border: none"></div>
  <div class="header-label header-border">Jobs</div>
          <app-multiple-job-summary style="margin-top: 70px;"
          [jobs$]="jobsAssociatedWithCustomer$"
          [title]="'Jobs'"
        ></app-multiple-job-summary>
        <div class="header-label header-border">Estimates</div>
        <app-multiple-estimate-summary
          [estimates]= "estimates"
          [customer]="activeCustomer"
          ></app-multiple-estimate-summary>
          <div class="header-label header-border">Invoices</div>
        <app-invoice-display
      [invoices] = "invoices"
      [addToJournalEntry] = true
      ></app-invoice-display>
      <div style="display: flex; margin: 5px 58px 0px 0px; justify-content: flex-end;">
      <button mat-button class="button-light-blue" type="button" (click)="viewJournalEntries()">Journal Entries</button>
      </div>
      </div> -->
      <!-- End of tall monitor section -->

<!-- This is for short monitors as  -->
          <mat-tab-group animationDuration="0ms" class="short-screen" [selectedIndex]="1" style="margin-top: 20px;">

            <!-- filler -->
            <mat-tab disabled="true" style="width: 20px;">
              <ng-template mat-tab-label style="width: 20px;">
              </ng-template>
            </mat-tab>
            <!-- end filler -->

            <!-- Jobs Section Tab -->
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon style="color: #209334;" class="example-tab-icon">handyman</mat-icon>
                Jobs
              </ng-template>
          <app-multiple-job-summary style="margin-top: 70px;"
          [jobs$]="jobsAssociatedWithCustomer$"
          [title]="'Jobs'"
        ></app-multiple-job-summary>
            </mat-tab>
          <!--End Jobs Section Tab -->

          <!-- Estimates Section Tab -->
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon style="color: #209334;" class="example-tab-icon">text_snippet</mat-icon>
                Estimates
              </ng-template>
              <app-multiple-estimate-summary
            [estimates]= "estimates"
            [customer]="activeCustomer"
            ></app-multiple-estimate-summary>
            </mat-tab>
            <!-- End Estimates Section Tab -->

            <!-- Invoices Section Tab -->
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon style="color: #209334;" class="example-tab-icon">attach_money</mat-icon>
                Invoices
              </ng-template>
          <app-invoice-display
          [invoices] = "invoices"
          [addToJournalEntry] = true
          ></app-invoice-display>
          <div style="display: flex; margin: 5px 58px 0px 0px; justify-content: flex-end;">
          <button mat-button class="button-light-blue" type="button" (click)="viewJournalEntries()">Journal Entries</button>
          </div>
            </mat-tab>
            <!-- End Invoices Section Tab -->


            <!-- back Filler -->
            <mat-tab disabled="true" style="width: 100%;">
              <ng-template mat-tab-label style="width: 100%;">
              </ng-template>
            </mat-tab>
            <!-- end back filler -->

          </mat-tab-group>




















    </div>
</div>
<div class="right-side"></div>
</div>
