import { Injectable } from '@angular/core'
;
import { AuthenticationService } from '../../util/authentication.service'
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service'
import { Industry } from '../dao/industry';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndustryService extends DatabaseStoreService<Industry> {

  prePopulate() {
    const a = new Industry({name: "Plumbing"});
    const b = new Industry({name: "Chimney"});
    const c = new Industry({name: "Other"});
    this.createMultiple$([a,b,c]).pipe(
    ).subscribe();
  }

  constructor( fs: IndustryFirestoreService, authenticationService: AuthenticationService, store: IndustryStoreService) {
super(fs, store, authenticationService);
// this.prePopulate();
  }

}

@Injectable({
  providedIn: 'root'
})
export class IndustryStoreService extends StateChangeStoreService<Industry> {
  protected store = "Industry-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, Industry>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class IndustryFirestoreService extends FirestoreBackend<Industry> {

 protected basePath = "Industry";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Industry {
return new Industry(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new Industry(), authService);
 }
}
