import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';
import {FirestoreDiff, covertCamelCaseToSentanceCase } from './firestore-diff';
import { SkillSet } from './skill-set';

export class LineItem extends RetrieveFirestorePropertiesBase<LineItem>  {

  cost: number;
  description: string | null = null;
  expectedDurationHours: number = 0;
  expectedDurationHoursSingleQuantity: number = 0;
  lineItemPrototypeDocId: string | null;
  lineItemDocId: string;
  pricePerItem: number;
  quantity: number = 1;
  title: string;
  internalNotes: string = "";
  originatingEmployeeDocId: string | null = null;

  active: boolean = true;
  lineItemNeedsOrHasBeenRescheduled = false;
  siteVisitReschedulingLineItemDocId: string | null = null;

  addToActiveJob: boolean = false;
  originatingEstimateDocId: string | null = null;
  originatingLineItemDocId: string | null = null;

  abandoned: boolean = false;
  scheduledFromEstimate: boolean = false;
  addedFromJobType: boolean = false;

  requiredSkillsDocIds: string[] = [];
  requiredSkills: SkillSet[] = [];

  get completed(): boolean {
    return  !this.active;
  }

  get originatingOrCurrentLineItemIdForSorting(): string {
    if ( this.originatingLineItemDocId) {
      return this.originatingLineItemDocId;
    } else {
      return this.DocId();
    }
  }

  set completed(val: boolean) {
    this.active = !val;
  }

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([ "updateSource", "timeDurationString",
    "addToActiveJob", "addedFromJobType", "requiredSkills"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["lineItemPrototypeDocId", "lineItemDocId",
   "updateSource","timeDurationString","originatingEstimateDocId", "originatingLineItemDocId", "addedFromJobType", "addToActiveJob", "requiredSkillDocIds"]);

  static _firestoreCompositionMemberNames = ["requiredSkills"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["requiredSkills"];

  get timeDurationString(): string {
    return LineItem.timeDurationStringFromHours(this.expectedDurationHours);
  }

  static timeDurationStringFromHours(hours: number) : string{
    const fullHours =  Math.floor(hours);
    return `${fullHours} h ${String(Math.round((hours-fullHours) * 60 * 100)/100).padStart(2,'0')} m`;
  }

  public constructor(init?: Partial<LineItem>) {
    super();
    Object.assign(this, init);
  }

  retrieveFirestoreIgnoredMemberNames() : string[] { return LineItem._firestoreIgnoredMemberNames};
  retrievefirestoreIgnoreDiffTrackingMembers() : string[] {return LineItem._firestoreIgnoreDiffTrackingMembers;}
  retrievefirestoreCompositionMemberNames() { return LineItem._firestoreCompositionMemberNames; }
  retrievefirestoreCompositionalDiffMemberNames() { return LineItem._firestoreCompositionalDiffMemberNames; }

  DocId(): string {
    return  this.lineItemDocId;
  }
  SetDocId(docId: string): void {
    this.lineItemDocId = docId;
  }

  createFirestoreDiff(previous: LineItem, current: LineItem,
    memberName: string, diffType: string, containedInArray: boolean): FirestoreDiff[] {
      // update
    if (current !== null && previous !== null) {
      return [new FirestoreDiff({newValue: current[memberName], oldValue: previous[memberName], fieldName: memberName,
                                 dateCreated: new Date(), changeType: diffType, activeDocId: current.DocId(),
                                 associatedDocId: current.DocId(), userMessage:
                                 `${covertCamelCaseToSentanceCase(memberName)} changed to ${current[memberName]}`})];
    } else if (current !== null) {
    // add
    return [new FirestoreDiff({newValue: current.DocId(), oldValue: "", fieldName: memberName,
                               dateCreated: new Date(), changeType: diffType, activeDocId: current.DocId(),
                               associatedDocId: current.DocId(), userMessage:
                               `Added line item: ${current.title}  Price: ${current.pricePerItem}`})];
    } else {
      // delete
      return [new FirestoreDiff({newValue: "", oldValue: previous.DocId(), fieldName: memberName,
                                 dateCreated: new Date(), changeType: diffType, activeDocId: previous.DocId(),
                                 associatedDocId: previous.DocId(), userMessage:
                                 `Deleted line item: ${previous.title}  Price: ${previous.pricePerItem}`})];
    }
  }

/*
Materials
Equipment
Labor
Appliance
*/

}
