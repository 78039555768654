<!-- <button (click)="test()">TETEST</button> -->
<div style="display:none">{{fields[0].props.changeDetect|async}}</div>
<app-workflow-header
      [isMain]="false"
      [loadedFromBranch]="!serializeToDatabase"
      [activeFormFirestore]="activeFormFirestore"
      [referencedComponents]="containedControls"
      [unsavedChanges$]="unsavedChanges"
      [activeWorkflowType]="sectionWorkflowType"
      (copyWorkflowClicked)="copyWorkflow()"
      (loadWorkflowClicked)="loadSection()"
      (saveWorkflowClicked)="saveSection()"
      (removeWorkflowClicked)="removeSection()"
      (newWorkflowClicked)="newSection($event)"
      (revertToDeployedWorkflowClicked) = "revertToDeployedWorkflow()"
      (deployWorkflowClicked) = "deployWorkflow($event)"
      (workflowActivationStatusToggled)="toggleWorkflowActivationStatus()"
    ></app-workflow-header>

<div class="section-color" [formGroup]="componentForm" style="width: 100%; height:auto"
>

  <div formArrayName="columnFormGroups" >
    <div *ngFor="let activeColumnFormGroup of columnFormGroups.controls; index as i;" [formGroupName]="i">
    <!-- <h5>{{perInstance}}</h5> -->
    <div class="section-header" id={{idForContainingDiv}} [ngClass]="{'repeating':componentForm.get('repeating').value}">{{componentForm.get('itemName').value}}</div>
    <div cdkDropList style="min-height: 200px;"
      [id]="activeColumnFormGroup.get('id').value"
      [cdkDropListConnectedTo]="activeColumnFormGroup.get('cdkDropListConnectedTo').valueChanges | async"
      [cdkDropListData]="activeColumnFormGroup.get('cdkDropListData').value"
      (cdkDropListDropped)="onTalkDrop($event)"
      [cdkDropListDisabled]=disableDesignComponentInteraction
      class = "card-list mat-elevation-z1"
      [cdkDropListEnterPredicate]="forbidSectionComponentsPredicate"
      >
      <div *ngFor="let component of activeColumnFormGroup.get('cdkDropListData').value;" cdkDrag
          [cdkDragData]="component"
          (cdkDragStarted)="component.dragStarted($event)"
          (cdkDragEnded)="component.dragEnded($event)">
            <app-form-element
            id={{component.perInstance}}
            [componentToInstantiate]="component">
            ></app-form-element>
      </div>
  </div>
</div>


</div>
</div>
