export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBOQzCXRifJYn10rVgAAhBdVqKh6I8dByA",
    authDomain: "service-vanguard.firebaseapp.com",
    databaseURL: "https://service-vanguard.firebaseio.com",
    projectId: "service-vanguard",
    storageBucket: "service-vanguard.appspot.com",
    messagingSenderId: "72678829950",
    appId: "1:72678829950:web:8f7d02d5e6a6ccda89cd2f",
    measurementId: "G-DNG2JMW7B4",
  },

  appVersion: require('../../package.json').version,
  releaseTimeStamp: '06/13/2024 11:52:08 AM EDT',

  minutesFidelitySchedulingArrivalTimes: 5,
  minimumSiteVisitLengthMinutes: 15,
  minutesFidelityArrivalWindow: 30,
  minutesFidelitySchedulingLengthSiteVisit: 15,
  minutesToShiftToMoveDropToValidLocation: 15,
  minutesFidelityCommuteTimes: 5,
  homeBaseAddressDocId: "XOsh4i9Q5OOwpPQqHF6S",
  numberFillsDaySiteVisits: 2,
  numberFirstAvailiableSiteVisits: 2,
  numberLowCommuteSiteVisits: 2,
  businessName: "Charming Chimney Friends",

  hoursBeforeStartTimeToDisplay: 1,
  hoursAfterEndTimeToDisplay: 3,
  // employeeWorkMaxTimeToSchedule: new Date(2000,1,1,8),

  // firebaseBasePathRead: "/service-providers/83uNj7Hu68k2DCJ21EXm/",

  // Seth Active
  // firebaseBasePathWrite: "/service-providers/9tMnuJPrIBraRQ9qrTbR/",
  // firebaseBasePathRead: "/service-providers/9tMnuJPrIBraRQ9qrTbR/",

  // Greg Active
  firebaseBasePathRead: "/service-providers/r2rWZNMrjZ2oHtfMyYSQ/",
  firebaseBasePathWrite: "/service-providers/r2rWZNMrjZ2oHtfMyYSQ/",
  //
  // minutesToShiftToSnapSiteVisitsTogether: 20,
};
