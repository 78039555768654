import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export enum Attachment_Source {
  WORKFLOW = "Workflow",
  IMPORTED = "Imported",
}

export class Attachment extends RetrieveFirestorePropertiesBase<Attachment>  {

url: string;
name: string;
attachmentType: Attachment_Source = Attachment_Source.WORKFLOW;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return Attachment._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Attachment._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Attachment._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Attachment._firestoreCompositionMemberNames;}

public constructor(init?: Partial<Attachment>) {
super();
Object.assign(this, init);
}
}
