import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Address } from "./address";

export class EmployeeGeofence extends RetrieveFirestorePropertiesBase<EmployeeGeofence>  {

  employeeDocId: string | null = null;
  latitude: number = 0;
  longitude: number = 0;
  addressDocId: string | null = null;
  dateMonitoring: Date = null;
  siteVisitDocId: string | null = null;

  address: Address;

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["address"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["address"]);
  static _firestoreCompositionMemberNames = ["address"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["address"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return EmployeeGeofence._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return EmployeeGeofence._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return EmployeeGeofence._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return EmployeeGeofence._firestoreCompositionMemberNames;}

  public constructor(init?: Partial<EmployeeGeofence>) {
    super();
    Object.assign(this, init);
  }
}
