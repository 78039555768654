import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service';
import { ResourceDayAddressRouting } from '../dao/resource-day-address-routing';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceDayAddressRoutingService extends DatabaseStoreService<ResourceDayAddressRouting> {

  constructor( fs: resourceDayAddressRoutingFirestoreService, store: resourceDayAddressRoutingStoreService, authenticationService: AuthenticationService) {
super(fs, store, authenticationService);
  }

}

@Injectable({
  providedIn: 'root'
})
export class resourceDayAddressRoutingStoreService extends StateChangeStoreService<ResourceDayAddressRouting> {
  protected store = "resourceDayAddressRouting-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, ResourceDayAddressRouting>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class resourceDayAddressRoutingFirestoreService extends FirestoreBackend<ResourceDayAddressRouting> {

 protected basePath = "resourceDayAddressRouting";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): ResourceDayAddressRouting {
return new ResourceDayAddressRouting(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new ResourceDayAddressRouting(), authService);
 }
}
