  <div class="header-container">
    <div class="header-grid">
        <div class="filter-field" [formGroup]="form">
            <mat-form-field class="form-field-color" appearance="outline" style="width: 100%;">
                <input formControlName="search" [name]="ElementNameForId('546676')" autofill='off' autocomplete='new-password' data-lpignore='true'
                matInput placeholder="Filter">
            </mat-form-field>
        </div>
        <div class="resource-tree">
          <app-mulliselect-nested-tree style="resource-filter-style"
    (itemsRemovedFromSelection$) = "employeesRemovedFromTreeDisplay$.next($event)"
    (itemsAddedToSelection$) = "employeesAddedToTreeDisplay$.next($event)"
    [checkListDatabase] = "CheckListDatabase()"
    >
      </app-mulliselect-nested-tree>
      </div>
        <div class="on-deck-section" *ngIf="onDeckJob !== null">
              <app-on-deck
                [siteVisitsActivelyScheduling] = "onDeckJob"
                (completedSchedulingFromOnDeck$) = "completedSchedulingFromOnDeck$.next(null)"
                (schedulerLater$) = "schedulerLater$.next(null)"
                (allowedCommuteSlider$) = "allowedCommuteSlider$.next($event)"
                (prospectiveDurationHourUpdate$) = "prospectiveDurationHourUpdate$.next($event)"
                ></app-on-deck>
        </div>
        <div class="date-range">
            <app-date-range-selection
            (retreatViewWindow$)="retreatViewWindow$.next(null)"
            (advanceViewWindow$) = "advanceViewWindow$.next(null)"
            (viewWindowSetToToday$) = "viewWindowSetToToday$.next(null)"
            [viewWindowMovementEnabled] = "viewWindowMovementEnabled"
            ></app-date-range-selection>
            <div class="start-date-selection">
                <button (click)="picker.open()" mat-button matDatepickerToggleIcon style="display: flex; height: 30px;">
                <mat-icon style="margin-bottom: 5px; margin-right: 10px; color: #707070;">date_range</mat-icon>
                <span style="color: #707070;">{{ActiveViewWindow}}</span>
                </button>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
                <input class="start-date-input" matInput [matDatepicker]="picker" disabled
                (dateChange)="setDatepicker($event)">
            </div>
        </div>
        <div class="week-range">
            <app-day-week-view
              (updatedViewWindow)="updatedViewWindow$.next($event)"
              (updateNumberWorkdaysToDisplay)="updateNumberWorkdaysToDisplay$.next($event)"
              [numberWorkDaysDisplayed]="numberWorkDaysDisplayed"
              [viewWindowMovementEnabled] = "viewWindowMovementEnabled"
            ></app-day-week-view>
        </div>
        <div class="toggle">
        <button mat-flat-button class="button-gray unscheduled" matBadge="{{numberUnassigned}}" fxLayout="row" fxLayoutAlign="center" (click)="toggleSideNav$.next(null)">Unscheduled</button>
        </div>
      </div>
</div>
