import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ControlContainsLiveviewComponent } from '../component-models/control-contains-liveview.component';
import { ControlReportsOnViewInitilizationInterface } from '../containsComponents';

@Component({
  selector: 'app-page-break',
  templateUrl: './page-break.component.html',
  styleUrls: ['./page-break.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageBreakComponent extends ControlContainsLiveviewComponent implements OnInit, AfterViewInit, OnDestroy, ControlReportsOnViewInitilizationInterface {


  patchControlComponentsToFormlyFields(): void {
    this.form.get("indexInParentContainer").valueChanges.pipe(
      startWith(this.form.get("indexInParentContainer").value),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    const obs$ = this.patchControlComponentsToFormlyFieldsCommon();

    merge(...obs$).pipe(
      tap(() => {
        this.fields[0].props.changeDetect.next();
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }


  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Page Break");
    retVal.patchValue({
      icon: "horizontal_rule",
      iconColor: "#8615dc",
      controlComponentFormGroup: this.fb.group({}),
    ControlRequiresFullWidth: true,
    });
    return retVal;
  }

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig {
    const retVal = this.toFormlyFieldConfig();
    retVal.wrappers=[];
    const {changeDetect,destroyComponents$,indexInParentContainer,guid,label,...temps} = retVal.props;
    retVal.props = temps;
    return retVal;
  }
  toFormlyFieldConfig(): FormlyFieldConfig {
    const retVal = super.convertFormlyFieldConfigToLiveViewVersion(this.fields[0]);
    retVal.props.destroyComponents$ = new Subject<any>();
    retVal.props.indexInParentContainer = this.form.get("indexInParentContainer").value;
    return retVal;
  }
  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig | FormlyFieldConfig[]): void {
    super.patchCommonFieldsToForm(formlyConfig);
  }


  constructor(protected fb: UntypedFormBuilder)
  {
    super(PageBreakComponent,fb);
    this.form = this.initilizeFormGroup();
    this.fields =
      [{
        key: `${this.perInstance}-pageBreakComponent`,
        type: 'formlyPageBreak',
        props: {
          changeDetect: this.changeDetect$,
          disabled: true,
          destroyComponents$: new Subject<any>(),
          label: `${this.perInstance}-pageBreakComponent`,
          indexInParentContainer: 0,
          guid: this.guid,
        },
        wrappers: ["change-detect"],
      }];

       // Directly emit clicks as there is no parent component.
    this.unfilteredComponentClicked$.pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(this.filteredComponentClicked$);
  }

  afterViewInitilized$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.controlContainerCommonDestruction();
  }

  ngAfterViewInit(): void {
    this.form.patchValue({triggerUpdate: {}});
    this.patchControlComponentsToFormlyFields();
    of(true).pipe(take(1)).subscribe(this.afterViewInitilized$);
}

  ngOnInit(): void {
  }

}
