import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectionStrategy,  Component,  ElementRef,   NgZone,  OnDestroy,  ViewChild  } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SplitComponent } from 'angular-split';
import { combineLatest,  merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ComponentFromFormlyFactoryService } from '../component-from-formly-factory.service';
import {  ControlContainerComponent } from '../component-models/control-container.component';
import { ControlContainsControlsComponent } from '../component-models/control-contains-controls.component';
import { ControlReportsOnViewInitilizationInterface } from '../containsComponents';
import { FormlyComponentUtilityService } from '../component-models/formly-controls/formly-component-utility.service';

export enum BorderOptionType {
  Outer = 1,
  InnerAndOuter = 2,
  None = 3
}

export enum BorderColor {
  Black = 1,
  Grey = 2
}

@Component({
  selector: 'app-column-splitter',
  templateUrl: './column-splitter.component.html',
  styleUrls: ['./column-splitter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnSplitterComponent extends ControlContainsControlsComponent implements AfterViewInit, OnDestroy, ControlReportsOnViewInitilizationInterface {

  @ViewChild('row') rowElement: ElementRef;

  static fb: UntypedFormBuilder = new UntypedFormBuilder();
  static buildColumnSplitterFormGroup() : UntypedFormGroup {
    return this.fb.group({
      numberOfColumnsSpecificed: 1,
      columnFormGroups: new UntypedFormArray([]),
      connectedDropLists: [],
      addDropZoneId: "",
      removeDropZoneId: "",
      itemDrop: [],
      itemRemoved: {},
      border: BorderOptionType.None,
      borderColor: BorderColor.Black,
      menuLanchedByContainerId: "",
      menulLaunchedOverControlGuid: "",
      explicitRegenerateFormly: {},
    });
  }


  patchControlComponentsToFormlyFields(): void {
  }

  forbidTopLevelOnlyComponentsPredicate(item: CdkDrag<ControlContainerComponent>) {

    const fullWidthRequired = (item.data as ControlContainerComponent).form.get("ControlRequiresFullWidth").value;
      if (fullWidthRequired) {
        return false;
      }

    //Empty columns can have components drug into them.
    if ((((this as unknown) as CdkDropList).data[0] as ControlContainerComponent) === undefined && !item.data.form.get("composerComponent").value) {
      return true;
    } else {
    return !item.data.form.get("composerComponent").value;
    }
  }


  recalculateClassName() : string {
    let retVal = "";
    const borderOptionValue = this.componentForm.get("border").value;
    switch (borderOptionValue.value) {
      case BorderOptionType.InnerAndOuter:
      retVal += "border innerOutline";
      break;
    case (BorderOptionType.Outer):
      retVal+= "border";
      break;
    case (BorderOptionType.None):
    default:
      break;
    }

    switch (this.componentForm.get("borderColor").value) {
      case BorderColor.Black:
        retVal += " black";
        break;
      case BorderColor.Grey:
        retVal += " grey";
        break;
      default:
        break;
    }

    return retVal;
  }

  trackByColumnFormGroupId(index,formGroup){
    return (formGroup as UntypedFormGroup).get("id").value;
  }

  trackByComponentPerInstance(index,component: ControlContainerComponent){
    return component.perInstance;
   }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {

    super.patchCommonFieldsToForm(formlyConfig);
    formlyConfig.fieldGroup.reverse();
    formlyConfig.fieldGroup.forEach(subComponentGrouping => {
      const aGrouping: ControlContainerComponent[] = [];
      subComponentGrouping.fieldGroup.forEach(comp => {
      const c = this.componentFromFormlyFactory.generateComponent(comp);
        c.patchInFormlyFieldConfig(comp);
        c.parentContainer = this;
        aGrouping.push(c);
      });
      const index = this.numberInitilizedColumns;
      this.addColumnAtIndex(this.numberInitilizedColumns,true);
      (this.columnFormGroups.controls[index] as UntypedFormGroup).patchValue({"cdkDropListData" :aGrouping});
    });

    let i=0;
    const columnCount = formlyConfig.fieldGroup.length-1;
    formlyConfig.fieldGroup.forEach(subComponentGrouping => {
      (this.columnFormGroups.controls[columnCount-i] as UntypedFormGroup).patchValue({"columnSplitSizeOutput": subComponentGrouping.props["size"],
        "columnSplitSize": subComponentGrouping.props["size"]});
      i++;
    });
    this.componentForm.patchValue({borderColor: formlyConfig.props["borderColor"], border: formlyConfig.props["border"]  })
  }

  retrieveFormlyFieldConfigForColumn(column: UntypedFormGroup, jsonOnly: boolean) : FormlyFieldConfig
  {
    const retVal : FormlyFieldConfig = {};
    retVal.props = {
      size: column.get("columnSplitSizeOutput").value,
    };

    if (!jsonOnly) {
      column.get("columnSplitSizeOutput").valueChanges.pipe(
        tap(x => retVal.props["size"] = x),
        takeUntil(this.regenerateLiveFormlyFieldView$)
      ).subscribe();
    }

    retVal.fieldGroup = this.retrieveFormlyFieldConfigForContainedElements(column,jsonOnly);
    // retVal.formControl = this.fb.group([]);
    return retVal;
  }

  generateFormlyFieldConfigSanSubForms() : FormlyFieldConfig {
    return {
      type: 'formlySplitter',
      key: `${this.perInstance}-splitter`,
      // defaultValue: [{}],
      fieldGroup: [],
      props: {
        className:"",
        innerOutline: false,
        border: false,
        changeDetect: this.changeDetect$,
        destroyComponents$: new Subject<any>(),
        indexInParentContainer: 0,
        guid: this.guid,
      },
      wrappers: ["change-detect"],
    };
  }

  toFormlyFieldConfig(): FormlyFieldConfig {
    const columns = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
    const subForms: FormlyFieldConfig[]=[];
    this.regenerateLiveFormlyFieldView$.next(null);
    for (let control of columns.controls) {
      subForms.push(this.retrieveFormlyFieldConfigForColumn(control as UntypedFormGroup, false));
    }
    const retVal = this.generateFormlyFieldConfigSanSubForms();
    retVal.fieldGroup = subForms;
    retVal.props.border = this.componentForm.get("border").value;
    retVal.props.borderColor = this.componentForm.get("borderColor").value;
    retVal.props.className = this.recalculateClassName();
    retVal.props.indexInParentContainer = this.form.get("indexInParentContainer").value;
    this.form.patchValue({liveFormlyFieldConfig: retVal});
    return retVal;
  }

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig | FormlyFieldConfig[] {
    const columns = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
    const subForms: FormlyFieldConfig[]=[];
    for (let control of columns.controls) {
      subForms.push(this.retrieveFormlyFieldConfigForColumn(control as UntypedFormGroup, true));
    }
    const retVal = this.generateFormlyFieldConfigSanSubForms();
    retVal.fieldGroup = subForms;
    retVal.props.border = this.componentForm.get("border").value;
    retVal.props.borderColor = this.componentForm.get("borderColor").value;
    retVal.props.className = this.recalculateClassName();
    retVal.wrappers=[];
    const {changeDetect,destroyComponents$,
      indexInParentContainer,guid,...temps} = retVal.props;
     retVal.props = temps;
    return retVal;
  }

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  @ViewChild('asSplit') asSplit: SplitComponent;

  menuX : number = 0;
  menuY: number = 0;
  regenerateLiveFormlyFieldView$ = new Subject();

  maxNumColumns: 7;
  numberInitilizedColumns: number = 0;

  public allDropListsIds: string[];

  get columnFormGroup(): UntypedFormGroup {
    return this.componentForm;
  }

  set columnFormGroup(value: UntypedFormGroup) {
    this.form.patchValue({controlComponentFormGroup: value});
  }

  createColumnFormGroup() {
    return ColumnSplitterComponent.buildColumnSplitterFormGroup();
  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Columns");
    retVal.patchValue({
      icon: "view_column",
      iconColor: "#447109",
      controlComponentFormGroup: this.createColumnFormGroup(),
      composerComponent: true,
    });
    return retVal;
  }

  constructor(fb: UntypedFormBuilder, private componentFromFormlyFactory: ComponentFromFormlyFactoryService, private _ngZone: NgZone,
    private formlyComponentUtilityService:  FormlyComponentUtilityService) {

    super(ColumnSplitterComponent,fb);


    // If we don't observe a resize for 2 second, we will assume columns are initilized.


    this.form = this.initilizeFormGroup();

    // Column Splitter components do not emit click events, as they are not modified in sidebar.
    this.unfilteredComponentClicked$.pipe(
      filter(x => x.perInstance !== this.perInstance),
      takeUntil(this.destroyingComponent$),
      ).subscribe(this.filteredComponentClicked$);

  }

  afterViewInitilized$: Observable<boolean> =
  of(null).pipe(
    debounceTime(2000),
    map(() => true),
    take(1));

  ngOnDestroy(): void {
    ((this.componentForm.get("columnFormGroups") as UntypedFormArray).value as UntypedFormGroup[]).forEach( c => {
      this.columnFormGroup.patchValue({removeDropZoneId:  c["id"]});
    });
    this.controlContainerCommonDestruction();
  }

  ngAfterViewInit(): void {

    const id = ((this.componentForm.get("columnFormGroups") as UntypedFormArray).at(0) as UntypedFormGroup).get("id").value;
    this.componentForm.patchValue({addDropZoneId: id});

    super.NumberComponents++;
    this.patchControlComponentsToFormlyFields();

    this.asSplit.dragEnd.pipe(
      map(x => x.sizes as number[]),
      tap(() => {
        let i = 0;
        const columns = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
        columns.controls.forEach(c => (c as UntypedFormGroup).get("cdkDropListData").value.forEach(x =>  x.form.patchValue({resizing: false})));
      }),
      tap(x => this.resizeSplits(x, true,true)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.asSplit.dragStart.pipe(
      tap(() => {
        let i = 0;
        const columns = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
        columns.controls.forEach(c => (c as UntypedFormGroup).get("cdkDropListData").value.forEach(x =>  x.form.patchValue({resizing: true})));
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    this.asSplit.dragProgress$.pipe(
      map(x => x.sizes as number[]),
      takeUntil(this.destroyingComponent$)
      //If we do not run this in zone, then live view updates to width do not appear.
    ).subscribe(x => this._ngZone.run(() => this.resizeSplits(x,true)));

    super.patchValuesToContainedComponentsAsNeeded();
  }

  onTriggerContextMenu(event: MouseEvent, id: string){
    this.menu.closeMenu() // close existing menu first.
    event.preventDefault();
    this.menuX = event.clientX;
    this.menuY = event.clientY;
    this.componentForm.patchValue({menuLanchedByContainerId: id, menulLaunchedOverControlGuid : ""})
    const composedPath = event.composedPath();
    for (var subPath in composedPath) {
      if ((composedPath[subPath] as any).localName === "app-form-element") {
        this.componentForm.patchValue({menulLaunchedOverControlGuid: (composedPath[subPath] as any).id});
        break;
      }
    }
    this.menu.openMenu();
  }

  public onDividerContextMenu(event:MouseEvent) {
    event.preventDefault();
  }

  public onDragDrop(event: CdkDragDrop<ControlContainerComponent[]>): void {
    if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        const associatedColumnGroupIndex = this.findActiveColumnIndex(event.container.id);
        const f = this.form.get("liveFormlyFieldConfig").value;
        const columnFormGroup = f.fieldGroup[associatedColumnGroupIndex];
        moveItemInArray(columnFormGroup.fieldGroup, event.previousIndex, event.currentIndex)
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
    } else  {
        const associatedColumnId = this.findActiveColumnIndex(event.container.id);
        const percentWidth = (this.componentForm.get("columnFormGroups") as UntypedFormArray).at(associatedColumnId).get("columnSplitSize").value;
        (event.item.data as ControlContainerComponent).form.patchValue({percentTotalWidth: percentWidth});
        (event.item.data as ControlContainerComponent).parentContainer = this;
        event.previousContainer.data[event.previousIndex] = (event.item.data as ControlContainerComponent);
        transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
      this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
      this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.container.data);
      }
    this.componentForm.patchValue({itemDrop: event});
  }

  public OnDragMovedEvent(event:any) {
    console.log(event);
  }

  private resizeSplits(sizes: number[], patchLiveSplitSize: boolean = false, forcePatch: boolean = false) {
    const columns = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
    let i = 0;
    sizes.forEach(percent =>
      {
        if ((columns.controls[i] as UntypedFormGroup).get("columnSplitSizeOutput").value !== percent || forcePatch) {
          (columns.controls[i] as UntypedFormGroup).patchValue({columnSplitSizeOutput: percent});
          if (patchLiveSplitSize) {
            (columns.controls[i] as UntypedFormGroup).patchValue({columnSplitSize: percent});
          }
          (columns.controls[i] as UntypedFormGroup).get("cdkDropListData").value.forEach(x =>  x.form.patchValue({percentTotalWidth: percent}));
        }
        i++;
      });
  }

  public get NumberComponents() {
    let subComponentCount = 0;
    ((this.componentForm.get("columnFormGroups") as UntypedFormArray).value as UntypedFormGroup[]).forEach( column => {
      subComponentCount ++;
      const columnControls = (column["cdkDropListData"] as ControlContainerComponent[]);
      columnControls.forEach(control => subComponentCount += control.NumberComponents);
    });
    return subComponentCount;
  }

  private buildColumnFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: (Math.random().toString(36) + '00000000000000000').slice(2, 14),
      cdkDropListConnectedTo: [[]],
      cdkDropListData: [[]],
      columnSplitSize: 0,
      columnSplitSizeOutput: 0,
    });
  }

  get columnFormGroups(): UntypedFormArray {
    return this.componentForm.get('columnFormGroups') as UntypedFormArray;
  }


  addColumn(direction: string) : void {
    let insertionIndex = this.findActiveColumnIndex(this.componentForm.get("menuLanchedByContainerId").value);
    if (direction === "LEFT") {
      if (insertionIndex > 0) {
        insertionIndex--;
      }
    } else {
      insertionIndex++;
    }
    this.addColumnAtIndex(insertionIndex,true);
  }

  deleteItem() : void {
    const activeColumnIndex = this.findActiveColumnIndex(this.componentForm.get("menuLanchedByContainerId").value);
    const columnFormGroup = (this.componentForm.get("columnFormGroups") as UntypedFormArray).at(activeColumnIndex);
    const associatedDropList = (columnFormGroup.get("cdkDropListData").value as ControlContainerComponent[]);
    associatedDropList.splice(associatedDropList.findIndex(x => x.perInstance === this.componentForm.get("menulLaunchedOverControlGuid").value),1);
    this.componentForm.patchValue({itemRemoved: {}});
    this.form.patchValue({triggerUpdate: {}});
  }

  deleteColumn() : void {
    const activeColumnIndex = this.findActiveColumnIndex(this.componentForm.get("menuLanchedByContainerId").value);
    // Handle removing last column.
    const idToRemove = (this.componentForm.get("columnFormGroups") as UntypedFormArray).at(activeColumnIndex).get("id").value;
    this.componentForm.patchValue({removeDropZoneId: idToRemove});
    (this.componentForm.get("columnFormGroups") as UntypedFormArray).removeAt(activeColumnIndex);
    this.numberInitilizedColumns--;
    this.patchSplitSizes();
  }

  findActiveColumnIndex(id: string) : number {
    //find index of control which launched the menu
    for (let i = 0; i < (this.componentForm.get("columnFormGroups") as UntypedFormArray).length; i++) {
      if ((this.componentForm.get("columnFormGroups") as UntypedFormArray).at(i).get("id").value === id) {
         return i;
      }
    }
    return undefined;
  }

  patchSplitSizes() : void {
    const splitSizes = 100/this.numberInitilizedColumns;
    (this.componentForm.get('columnFormGroups') as UntypedFormArray).controls.forEach(x => {
      x.patchValue({columnSplitSize: splitSizes, columnSplitSizeOutput: splitSizes})
      x.get("cdkDropListData").value.forEach(y =>  y.form.patchValue({percentTotalWidth: splitSizes}));
     });
  }

  addColumnAtIndex(index: number, patch: boolean=false): void {
    const columnFormGroup = this.buildColumnFormGroup();
    const id = columnFormGroup.get("id").value;

    // Add subscription to connected drop lists to newly built component.
    this.componentForm.controls["connectedDropLists"].valueChanges.pipe(
      delay(1),
      startWith(this.componentForm.get("connectedDropLists").value),
      takeUntil(this.componentForm.controls["removeDropZoneId"].valueChanges.pipe(
        filter(x => x === id))),
        map(q => (q as string[])),
        map(connectedGroups => {
          if (this.parentContainer?.form.get("controlName").value === "Section") {
            return connectedGroups.filter(x => x !== this.parentContainer.componentForm.get("columnFormGroups").value[0].id);
          } else {
            return connectedGroups;
          }
        }),
        tap(connectedGroups => columnFormGroup.patchValue({cdkDropListConnectedTo: connectedGroups.filter(x => x !== id)})),
    ).subscribe();

    if (patch) {
      this.componentForm.patchValue({addDropZoneId: id});
    }
    (this.componentForm.get("columnFormGroups") as UntypedFormArray).insert(index,columnFormGroup);
    this.patchSplitSizes();
  }
}
