import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PRICING_METHODOLOGY } from '../../../../../common/src/data/dao/pricing-methodology-settings';
import { randomElementName } from '../../../../../common/src/util/util';

@Component({
  selector: 'app-pricing-methodology-settings',
  templateUrl: './pricing-methodology-settings.component.html',
  styleUrls: ['./pricing-methodology-settings.component.scss']
})
export class PricingMethodologySettingsComponent implements OnInit, OnDestroy {

  @Input() pricingMethodologySettingsForm: UntypedFormGroup;

  pricingMethodologyTypes: {name: string,val: PRICING_METHODOLOGY}[] =
    [{name: "Fixed Line Item", val: PRICING_METHODOLOGY.FIXED_LINE_ITEM},
    {name: "Hours and Materials", val: PRICING_METHODOLOGY.HOURS_AND_MATERIALS}];

  randomElementName : string = randomElementName();
  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor() { }


  ngOnInit(): void {
    this.pricingMethodologySettingsForm.get("pricingMethodologyType").valueChanges.pipe(
      tap(x => {
        if (x === PRICING_METHODOLOGY.FIXED_LINE_ITEM) {
          this.pricingMethodologySettingsForm.controls["materialMarkupRate"].disable();
          this.pricingMethodologySettingsForm.controls["defaultHourlyBillingRate"].disable();
        } else {
          this.pricingMethodologySettingsForm.controls["materialMarkupRate"].enable();
          this.pricingMethodologySettingsForm.controls["defaultHourlyBillingRate"].enable();
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

}
