import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyLineItemComponent } from './formly-line-item.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule } from '@angular/forms';
import {LineItemDisplayModule} from '../../../../line-item/line-item-display/line-item-display.module';


@NgModule({
  declarations: [FormlyLineItemComponent],
  imports: [
    LineItemDisplayModule,
    CommonModule,
    FormsModule,
    FormlyModule.forChild({
      types: [
        {name: 'formlyLineItems', component: FormlyLineItemComponent},
      ]}),
  ],
})
export class FormlyLineItemModule { }
