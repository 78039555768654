import {FormlyExtension,FormlyFieldConfig} from '@ngx-formly/core';
import { FormlyFieldConfigCache } from '@ngx-formly/core/lib/models';

export const formlyDefaultsExtension: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig) {
    if (field.type === "input") {
      return;
    }
    if (!field.modelOptions) {
      field.modelOptions = {
        updateOn: 'blur',
      };
    }
    if (field.props !== undefined && field.props.serializeToDatabase === undefined) {
      field.props.serializeToDatabase = true;
    }
    return field;
    },
    onPopulate(field: FormlyFieldConfigCache) {
      if (field.type === "input") {
        return;
      }
      if (field.formControl && field.props && field.props.manualPatchValue) {
        field.formControl.patchValue(field.defaultValue);
      }
    },
  }
