import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
selector: 'formly-wrapper-label',
template: `
<div class= "{{to.labelClass}}">
  <mat-label style="font-size: 14px">{{to.label}}</mat-label>
    <div style="width: 100%">
    <ng-container #fieldComponent></ng-container>
    </div>
</div>

`,
styleUrls: ['./formly-wrapper-manually-positioned-label.scss'],
changeDetection: ChangeDetectionStrategy.OnPush
})

export class WrapperLabelComponent extends FieldWrapper {

}
