import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Address } from '../../../../../common/src/data/dao/address';
import { AddressRouting } from '../../../../../common/src/data/dao/address-routing';
import { AddressService } from '../../../../../common/src/data/dao-services/address.service';
import { Customer } from '../../../../../common/src/data/dao/customer';
import { GoogleGeocodeService } from 'web-app/src/app/google-geocode.service';
import { PhysicalAddressRoutingService } from 'web-app/src/app/physical-address-routing.service';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { searchSource } from '../../../../../common/src/data/services/search.service';
import { CustomerSearchService } from '../../../../../common/src/data/services/customer-search.service';

@Component({
  selector: 'app-address-search-modal',
  template: `
  <div class="change-address-modal-layout">
  <div class="header-address-label">{{title}}</div>
  <app-address-search
    style="display: flex; justify-self: center; margin: 40px 0px;"
    [addressCustomersFound$]="  addressSearchResults$"
    [form] = "form"
    [addressToShopRouting] = "addressToShopRouting$ | async"
    [previousJobCountAtAddress] = "jobCountAtAddress$ | async"
    (search)="addressSearchBar$.next($event)"
    (selectedAddress) = "addressSelection$.next($event)"
    (unitUpdated) = "serviceAddressUnit$.next($event)"
  ></app-address-search>
  <div class="footer-container header-address-label" style="border-top: 2px solid gainsboro;">
  <div>
  <button mat-button class="button-gray" (click)="exitCancel()">Cancel</button>
  </div>
  <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
  <button mat-button class="button-blue" [disabled]="selectedAddress === undefined" (click)="exitOk()">Save</button>
  </div>
  </div>
  </div>
  `,
  styleUrls: ['./address-search-modal.component.scss']
})

export class AddressSearchModalComponent implements OnInit, OnDestroy {

  title : string = "";
  searchSources: searchSource[];
  provideAddressToShopRoutingInfo: boolean;

  selectedAddress: Address = undefined;
  activeSearchTerm = "";
  destroyingComponent$ = new Subject();
  form : UntypedFormGroup;

  addressSearchResults$ = new Observable<{address: Address, customer?: Customer}[]>();
  addressToShopRouting$ = new BehaviorSubject<AddressRouting | null>(null);
  jobCountAtAddress$ = new BehaviorSubject<string | null>(null);
  addressSearchBar$ = new Subject<string>();
  addressSelection$ = new Subject<Address>();
  // customerSelection$ = new Subject<Customer>();
  // customerAddedFromServiceSelection$ = new Subject<boolean>();
  serviceAddressUnit$ = new BehaviorSubject<string>("");

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AddressSearchModalComponent>,
  private customerSearchService: CustomerSearchService, private addressRoutingService: PhysicalAddressRoutingService,
  private addressService: AddressService, private googleGeocoderService: GoogleGeocodeService, private fb: UntypedFormBuilder,
  private settingsService: SettingsService) {
    if (data) {
      this.title = data.title;
      this.searchSources = data.searchSources;
      this.provideAddressToShopRoutingInfo = data.provideAddressToShopRoutingInfo;

      if (data.provideJobCountAtAddressInfo) {
        this.jobCountAtAddress$.next("0");
      }
    }
  }

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  buildSearchAddressForm() : UntypedFormGroup {
    return this.fb.group ({
      serviceAddressSearch: ["", [Validators.required]],
      unit: [""]
    });
  }

  exitOk() {
    this.dialogRef.close(this.addressService.get(this.selectedAddress.docId));
  }

  exitCancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    this.form=this.buildSearchAddressForm();

    const searchAddress = this.addressSearchBar$.pipe(
      tap(this.selectedAddress = undefined),
      tap(x => {
        this.activeSearchTerm = x;
        this.customerSearchService.addSearch({search: x, componentSource: "addressSearch", searchSources: this.searchSources});
      }));

    const resetAddressSearch = searchAddress.pipe(
      filter( x => x.length <= 2),
      map(() => ([])));

      const fromCustomerSearchService$ =  this.customerSearchService.searchResults$.pipe(
        filter(x => x.searchInput.componentSource === "addressSearch" && x.searchInput.search === this.activeSearchTerm),
        map(x => x.results));

    this.addressSearchResults$ = merge(fromCustomerSearchService$, resetAddressSearch).pipe(
      takeUntil(this.destroyingComponent$)
    );

    this.addressSelection$.pipe(
      tap(x => console.log(x,` string`)),
      map( x => {
        if (x.DocId() === undefined) {
          x.lineOne = x.formattedAddress();
        }
        return x;
      }),
      tap(x => {
        if (!this.addressRoutingService.addressesOriginationDocIdsRetrievedOrRetrieving.has(x.docId)) {
          this.googleGeocoderService.retrieveGeocoderResult(x);
        } else {
          if (this.addressRoutingService.orginAddressIdToAssociatedCommutes.has(x.docId) && this.provideAddressToShopRoutingInfo) {
            this.addressToShopRouting$.next(this.addressRoutingService.orginAddressIdToAssociatedCommutes.get(x.docId).get(this.addressRoutingService.officeSchedulingFromAddress.DocId()));
          }
        }
        this.addressRoutingService.generatedCommuteMatrixResultsToShop$.pipe(
          filter(() => this.provideAddressToShopRoutingInfo),
          filter(addrDocId => addrDocId === x.docId),
          tap(() => this.addressToShopRouting$.next(this.addressRoutingService.orginAddressIdToAssociatedCommutes.get(x.docId).get(this.addressRoutingService.officeSchedulingFromAddress.DocId()))),
          take(1)
        ).subscribe();
      }),
      map(x => {
        x.unit = this.serviceAddressUnit$.value;
        return x;
      }),
      switchMap(x => this.addressService.update$(x)),
      tap(x => this.selectedAddress = x),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.serviceAddressUnit$.pipe(
      filter(() => this.selectedAddress !== undefined),
      debounceTime(500),
      map(x => {
        this.selectedAddress.unit = x;
        return this.selectedAddress;
      }),
      switchMap(x => this.addressService.update$(x)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

}
