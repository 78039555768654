import { Injectable } from '@angular/core';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreDiffService } from './firestore-diff.service'
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
;
import { Observable,  merge, ReplaySubject, zip, of } from 'rxjs';
import { tap, share, filter, switchMap, takeUntil, map, take, mergeMap, delay, distinctUntilChanged } from 'rxjs/operators';
import { AddressService } from './address.service';
import { EmployeeAvailabilityService } from './employee-availability.service';
import { EmployeePermissionService } from './employee-permission.service';
import { EmployeeRoleService } from './employee-role.service';
import { Employee } from '../dao/employee';
import { AuthenticationService } from '../../util/authentication.service';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { where } from 'firebase/firestore';
import { CompanyLocationService } from './company-location.service';
import { SkillSetService } from './skill-set.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends DatabaseStoreService<Employee> {

  allEmployees: Employee[] = [];
  private allEmployees$ = new ReplaySubject<Employee[]>(1);
  private loadAllCalled: boolean = false;

  constructor( fs: EmployeeFirestoreService, authenticationService: AuthenticationService, store: EmployeeStoreService, private addressService: AddressService,
    private employeeAvailibilityService: EmployeeAvailabilityService, private employeePermissionService: EmployeePermissionService,
    private employeeRoleService: EmployeeRoleService, private settingsService: SettingsService, private companyLocationService: CompanyLocationService, private skillSetService: SkillSetService) {
    super(fs, store, authenticationService,
      new Map([["dispatchOrginAddress", {associatedDocumentId: "dispatchOrginAddressDocId",compositionStoreService: addressService}],
               ["dispatchDestinationAddress", {associatedDocumentId: "dispatchDestinationAddressDocId",compositionStoreService: addressService}],
               ["employeeAvailability", {associatedDocumentId: "employeeAvailabilityDocIds",compositionStoreService: employeeAvailibilityService}],
               ["employeePermissions", {associatedDocumentId: "employeePermissionDocIds", compositionStoreService: employeePermissionService}],
               ["employeeRole", {associatedDocumentId: "employeeRoleDocId", compositionStoreService: employeeRoleService}],
               ["employeeLocation", {associatedDocumentId: "employeeLocationDocId", compositionStoreService: companyLocationService}],
                ["employeeSkills", {associatedDocumentId: "employeeSkillDocIds", compositionStoreService: skillSetService}],
              ]));


    const loggedOutEmployee =  this.authenticationService.updatedUser$.pipe(
      filter(x => x === ""),
      tap(() => this.authenticationService.updateEmployeePermissions(undefined))
    );

    const logInEmployee =  this.authenticationService.updatedUser$.pipe(
      filter(x => x && x !== ""),
      distinctUntilChanged(),
      switchMap(uuid => this.queryFirestoreDeep$([where('guid','==',uuid)]).pipe(
        filter(x => x.length > 0),
        take(1)
      )),
      map(x => x[0]),
      share()
    );

    const logInActiveEmployee = logInEmployee.pipe(
      filter(x => x.active),
      tap(e => this.authenticationService.updateEmployeePermissions(e)),
    );

    const logInInactiveEmployee = logInEmployee.pipe(
      filter(x => !x.active),
      tap(() => {
        window.alert("Your employee account is not currently active, please contact your administrator.");
        this.authenticationService.SignOut();
      })
    );

    merge(loggedOutEmployee,logInActiveEmployee, logInInactiveEmployee).pipe(
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  override load$(docId: string): Observable<Employee> {
    if (docId === undefined || docId === null) {
      return of(new Employee({name: "Unknown Employee"}));
    } else {
      return super.load$(docId);
    }
  }

  override loadAll$(): Observable<Employee[]> {
    if (!this.loadAllCalled) {
      this.loadAllCalled = true;
      if (this.authenticationService.mobileSite) {
        this.privateLoadAll$().pipe(
          tap(x => console.log("DERICK DRAGS?")),
          take(1),
        ).subscribe();
      } else {
        this.privateLoadAll$().pipe(
          tap(x => console.log("BILLY DRAGS?")),
        )
        .subscribe();
      }
    }
    return this.allEmployees$;
  }

  privateLoadAll$(): Observable<Employee[]> {

    console.error("CALLING LOAD ALL EMPLOYEES");
    return this.queryFirestoreShallow$().pipe(
      filter(x => x.length > 0),
      mergeMap(x => this.employeePermissionService.queryFirestoreForInValues("docId", x.flatMap(y => y.employeePermissionDocIds)).pipe(
        filter(x => x.length > 0),
        map(() => x),
        take(1),
      )),
      mergeMap(x => this.employeeAvailibilityService.queryFirestoreForInValues("docId", x.flatMap(y => y.employeeAvailabilityDocIds)).pipe(
        filter(x => x.length > 0),
        map(() => x),
        take(1),
      )),
      mergeMap(() => super.loadAll$(false)),
      tap(x => {
        this.allEmployees = x;
        this.allEmployees$.next(x);
      }),
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmployeeStoreService extends StateChangeStoreService<Employee> {
  protected store = "employee-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, Employee>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class EmployeeFirestoreService extends FirestoreBackend<Employee> {

  protected basePath = "employees";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Employee {
    return new Employee(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new Employee(), authService);
  }
}
