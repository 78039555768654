import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomTagsService } from 'web-app/src/app/custom-tags.service';
import { CustomTagComponentInputs, CustomTagComponentOutputs } from 'web-app/src/app/custom-tags/custom-tags.component';
import { GenericServiceProviderSettingService } from '../../../../../common/src/data/dao-services/generic-service-provider-setting.service';
import { GenericServiceProviderSetting } from '../../../../../common/src/data/dao/generic-service-provider-setting';

@Component({
  selector: 'app-status-settings',
  templateUrl: './status-settings.component.html',
  styleUrls: ['./status-settings.component.scss']
})
export class StatusSettingsComponent implements OnInit, OnDestroy {

  customerTagComponentInputs : CustomTagComponentInputs;
  customerTagComponentOutputs : CustomTagComponentOutputs;
  jobTagComponentInputs : CustomTagComponentInputs;
  jobTagComponentOutputs : CustomTagComponentOutputs;

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor(private tagService: CustomTagsService, private genericServiceProviderSettingService: GenericServiceProviderSettingService) { }

  ngOnInit(): void {
    this.customerTagComponentInputs = this.tagService.buildCustomTagsInput([],false,this.destroyingComponent$,"customer_tags", "Customer Tags");
    this.customerTagComponentOutputs = this.tagService.buildCustomTagsOutput([], this.destroyingComponent$, "customer_tags",true);
    this.jobTagComponentInputs = this.tagService.buildCustomTagsInput([], false, this.destroyingComponent$, 'job_tags', 'Job Tags')
    this.jobTagComponentOutputs = this.tagService.buildCustomTagsOutput([], this.destroyingComponent$, 'job_tags',true);
  }

  deactivateTag(tag: GenericServiceProviderSetting) {
    tag.active=false;
    this.genericServiceProviderSettingService.update$(tag).pipe(take(1)).subscribe();
  }

  changeColor(tag:GenericServiceProviderSetting, color: string) {
    tag.colorAssocatedWithSetting = color;
    this.genericServiceProviderSettingService.update$(tag).pipe(take(1)).subscribe();
  }

}
