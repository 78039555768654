<div class="reschedule-grid" [formGroup]="form">
  <!-- Resscheduling Reason -->
  <mat-dialog-content class="mat-dialog-content" style="overflow-x: hidden; display: contents;"> <!--makes the modal scrollable on y axis if text in the notes field makes mat form field grow vertically below the viewport-->
  <div class="header-label" style="margin-bottom: 20px;">{{title}}</div>
  <div class="label-style">Reschedule Reason</div>
  <div class="medium-field center-form-field">
    <mat-form-field appearance="outline" class="form-align wide-VW">
          <mat-select formControlName="siteVisitRescheduleReason" placeholder="Reason Rescheduling" >
          <mat-option *ngFor="let siteVisitRescheduleReason of siteVisitRescheduleReasons" [value]="siteVisitRescheduleReason" >
            {{siteVisitRescheduleReason}}
          </mat-option>
        </mat-select>
        <mat-error>Site visit rescheduling must be catagorized</mat-error>
      </mat-form-field>
  </div>
  <!-- Rescheduling Note -->
  <div class="label-style">Notes</div>
  <div class="medium-field">
      <mat-form-field appearance="outline" class="wide-VW" rows="5">
        <textarea matInput placeholder="Notes" formControlName="notes"
        [name]="ElementNameForId('756595')" autofill='off' autocomplete='new-password' data-lpignore='true' cdkTextareaAutosize></textarea>
      </mat-form-field>
  </div>
  <!-- Time Remaining  -->
  <ng-container *ngIf="form.get('duration').value !== null">
    <div class="label-style">Remaining Time</div>
      <div style="width:280px; display: flex; margin-bottom: 20px;" class="center-form-field">
        <div class="form-align">
          <div style="padding-left: 20px;">
            <app-time-field
            [value]= "form.get('duration').value"
            [fieldLabel]= "'Time remaining to complete job'"
            [readOnly]="false"
            [granularity]="15"
            (valueChange) = "timeRemaining$.next($event)"
            [minDate]="zeroHoursAsDate"
            [maxDate]="maximumTimeOneLineItem"
            ></app-time-field>
          </div>
        </div>
      </div>
  </ng-container>
    </mat-dialog-content>

  <!--START OF SCHEDULE BUTTONS SECTION-->
  <!-- <div class="section" style="border: none"> -->
    <mat-dialog-actions style="display: contents;">
    <div class="footer-button" class="footer-cust" style="grid-column: 1/-1; padding-top: 20px;">
        <div>
          <button mat-button class="button-gray" (click)="cancel();">Cancel</button>
        </div>
        <div class="schedule-btn-pos">
            <button type = "submit" mat-button class="button-blue" (click)="save();">Save</button>
      </div>
    </div>
  </mat-dialog-actions>
<!-- </div> -->
<!--END OF SCHEDULE BUTTONS SECTION-->

</div>

