<form [formGroup]="form" class="save-workflow-grid">
  <div class="header-label">Save Workflow</div>
  <div class="label-style">Workflow Name</div>
<div style="width:280px;">
    <div class="form-align">
        <mat-form-field appearance="outline" style="width:280px;" style="margin-bottom: 20px;">
          <input placeholder="Form Name" matInput formControlName="title"
          [name]="ElementNameForId('123194')" autofill='off' autocomplete='new-password' data-lpignore='true'>
          <mat-error style="margin-top: 25px;">Workflow name is required</mat-error>
        </mat-form-field>
      </div>
    </div>

  <!-- Workflow Catagories -->
  <div class="label-style">Catagory</div>
  <app-workflow-settings-tree
  [checkListDatabase]="CheckListDatabase()"
></app-workflow-settings-tree>

<mat-form-field class="error-position" appearance="outline">
  <input matInput formControlName="selectedFormCatagory" style="width:40px;" hidden="true">
  <mat-error>A workflow catagory must be chosen.</mat-error>
</mat-form-field>
<!-- Cancel / Submit buttons. -->
<div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
  <div class="button-position">
    <button type="button" mat-button class="button-gray" (click)="close()">Cancel</button>
  </div>
  <div class="button-position">
    <button type="submit" (click)="submit()" mat-button class="button-blue">Save</button>
  </div>
</div>
</form>
