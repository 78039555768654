import { Component, OnInit,  Output, EventEmitter, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Customer } from '../../../../../common/src/data/dao/customer';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { merge, Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MultipleJobSummaryComponent } from 'web-app/src/app/multiple-job-summary/multiple-job-summary.component';
import { Address } from '../../../../../common/src/data/dao/address';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { AddressRouting } from '../../../../../common/src/data/dao/address-routing';
import { randomElementName } from '../../../../../common/src/util/util';
import { limit, where } from 'firebase/firestore';
import { searchResults } from '../../../../../common/src/data/services/search.service';


@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("inputBox") _el: ElementRef;

  @Input() activeSearch: boolean;
  @Input() addressCustomersFound: searchResults[];
  @Input() displayNoResultsFound: boolean;
  @Input() form: UntypedFormGroup;
  @Input() addressToShopRouting: AddressRouting | null;
  @Input() previousJobCountAtAddress: string | null;

  @Output() activeSearchEmitter = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() selectedAddress = new EventEmitter<Address>();
  @Output() selectedAddressAndCustomer = new EventEmitter<{address: Address, customer: Customer}>();
  @Output() unitUpdated = new EventEmitter<string>();

  searchRaw$ = new Subject<string>();

  skipAddressSearchEmissionOnSelect: boolean = false;

  destroyComponent$: Subject<any> = new Subject();
  address: Address;


  randomElementName = randomElementName();
  PropertyHistoryModalRef: MatDialogRef<MultipleJobSummaryComponent>;


  public ViewPropertyHistoryClick() {
    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         :
      {
        title: "Service Address History",
        jobs$: this.jobService.queryFirestoreDeep$([where('serviceAddressDocId', '==', this.address.DocId()),
        limit(25)]),
      }
      });
    this.PropertyHistoryModalRef = this.dialog.open(MultipleJobSummaryComponent, editorConfig);
  }



  get disablePropertyHistory() { return this.previousJobCountAtAddress === "0"; }

  formatSearchTextFromSelectedValue(selectedVal : {address: Address, customer?: Customer}) : string {
    if (selectedVal.customer !== null) {
      return `${selectedVal.customer.customerName} - ${selectedVal.address.formattedAddress()}`;
    } else {
      return selectedVal.address.formattedAddress();
    }
  }

  optionSelected(e: MatAutocompleteSelectedEvent) {
    const searchValue : {address: Address, customer?: Customer} = e.option.value;
    this.form.patchValue({ serviceAddressSearch : this.formatSearchTextFromSelectedValue(searchValue), unit: searchValue.address.unit});
    this.skipAddressSearchEmissionOnSelect = true;
    this.address = searchValue.address;
    if (searchValue.customer !== null) {

      this.selectedAddressAndCustomer.emit({address: searchValue.address, customer: searchValue.customer});
    } else {
        this.selectedAddress.emit(searchValue.address);
    }
  }

  constructor(private fb: UntypedFormBuilder, private jobService: JobService, private dialog: MatDialog) { }

  ngOnDestroy(): void {
    this.destroyComponent$.next(null);
    this.destroyComponent$.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => this._el.nativeElement.focus(), 0);
  }
  ngOnInit(): void {

    //only emit search when not caused by selection of item.
    const searchToEmit = this.searchRaw$.pipe(
      filter(() => !this.skipAddressSearchEmissionOnSelect),
      distinctUntilChanged(),
      tap(() => this.displayNoResultsFound = false),
      tap(() => this.addressToShopRouting = null),
      debounceTime(300),
      tap(x => this.search.emit(x)));

      this.searchRaw$.pipe(
        filter(x=>x.length > 2 && !this.skipAddressSearchEmissionOnSelect),
        tap(() => this.activeSearchEmitter.emit(true)),
        takeUntil(this.destroyComponent$),
      ).subscribe();

    const searchToSkip = this.searchRaw$.pipe(
      filter(() => this.skipAddressSearchEmissionOnSelect),
      tap(() => this.skipAddressSearchEmissionOnSelect = false));

      merge(searchToEmit,searchToSkip).pipe(
        takeUntil(this.destroyComponent$)).subscribe();


    this.form.controls["unit"].valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroyComponent$),
    ).subscribe(unit => this.unitUpdated.emit(unit));

    this.form.controls["addressPatchedFromCustomerSelection"].valueChanges.pipe(
      tap(x => this.address = x),
      takeUntil(this.destroyComponent$),
    ).subscribe();

    this.search.pipe(
      distinctUntilChanged(),
      tap(x  => {
        if (this.address !== undefined && this.address.formattedAddress() !== x) {
          console.log(this.address.formattedAddress());
          this.address = undefined;
          console.log(`this.address set to: undefined`);
        }
      }),
      takeUntil(this.destroyComponent$)
    ).subscribe();

  }

  excludeEnter(event:any){
    if(event.keyCode == 13) {
      event.preventDefault();
    }
  }
  addressSearchKeyUp($event: KeyboardEvent) {
    this.searchRaw$.next(($event.target as HTMLTextAreaElement).value);
  }

  displayCustomerResultsHeader() {
    return this.addressCustomersFound && this.addressCustomersFound.filter(x => x.customer).length > 0;
  }
}
