import {firebaseui, FirebaseUIModule} from 'firebaseui-angular';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

// import { getAuth, provideAuth } from '@angular/fire/auth'
// import { getStorage, provideStorage } from '@angular/fire/storage'

import { AngularFireAuthModule} from '@angular/fire/compat/auth'
import { getStorage, provideStorage } from '@angular/fire/storage'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddressDisplayViewComponent } from './address-display-view/address-display-view.component';
import { AddJobBoardComponent } from './add-job-board/add-job-board.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { AddressDropdownComponent } from './add-customer/address-dropdown/address-dropdown.component';


import { BryntumAngularSharedModule } from 'bryntum-angular-shared';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CustomerBalanceComponent } from './add-job-board/customer-balance/customer-balance.component';
import { CustomerDetailsComponent } from './add-job-board/customer-details/customer-details.component';
import { CustomerSearchComponent } from './add-job-board/customer-search/customer-search.component';

import { DateRangeSelectionComponent } from './scheduler-view/scheduler-view-header/date-range-selection/date-range-selection.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgModule } from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';


import { SchedulerViewComponent } from './scheduler-view/scheduler-view.component';
import { SiteWideSearchBarModule } from './sitewide-search-bar/public_api';

import { HeaderComponent } from './scheduler-view/scheduler-view-header/header/header.component';

import { OnDeckComponent } from './scheduler-view/scheduler-view-header/on-deck/on-deck.component';

import { SidebarComponent } from './scheduler-view/scheduler-view-sidebar/sidebar/sidebar.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select'; // added by greg for the navigation menu drop down
import {MatMenuModule} from '@angular/material/menu'; // added by greg for the navigation menu drop down
import {MatTabsModule} from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';


import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
// added by greg for so there is now a dedicated header on every page
import { NavigationModule } from './scheduler-view/Navigation/public_api';

// tslint:disable-next-line:max-line-length
import { CustomTagsComponent } from './custom-tags/custom-tags.component';

import { TestingComponent } from './jobs/testing/testing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { JobsPageComponent } from './jobs-page/jobs-page.component';
import { JobDetailsComponent } from './add-job-board/job-details/job-details.component';
import { CustomerPageComponent } from './customer-page/customer-page.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { CustomerJobSectionComponent } from './jobs-page/customer-job-section/customer-job-section.component';
import { JobsPageHeaderComponent } from './jobs-page/jobs-page-header/jobs-page-header.component';
import { MultipleSiteVisitSummaryComponent } from './jobs-page/multiple-site-visit-summary/multiple-site-visit-summary.component';
import {ObserversModule} from '@angular/cdk/observers';
import { DayWeekViewComponent } from './scheduler-view/scheduler-view-header/day-week-view/day-week-view.component';
import { EventDetailsComponent } from './scheduler-view/event-details/event-details.component';
import { SiteVisitLengthComponent } from './scheduler-view/site-visit-length/site-visit-length.component';
import { TimeFieldModule } from './time-field/public_api';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { JobChangeHistoryComponent } from './jobs-page/job-change-history/job-change-history.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MultipleCustomerContainerComponent } from './multiple-customer-container/multiple-customer-container.component';
import { SvDataDisplaySelectionComponent } from './scheduler-view/scheduler-view-header/sv-data-display-selection/sv-data-display-selection.component';
import { EmployeeComponent } from './employee.component';
import { ResolveDirtyAddressComponent } from './add-job-board/resolve-dirty-address/resolve-dirty-address.component';
import { FormBuilderComponent } from './form-builder/form-builder/form-builder.component';
import { FormDesignerComponent } from './form-builder/form-designer/form-designer.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ColumnSplitterComponent } from './form-builder/column-splitter/column-splitter.component';
import {AngularSplitModule} from 'angular-split';
import { TextboxControlComponent } from './form-builder/component-models/textbox-control/textbox-control.component';
import { WhitespaceControlComponent } from './form-builder/component-models/whitespace-control/whitespace-control.component';
import { ImageControlComponent } from './form-builder/component-models/image-control/image-control.component';
import { FormBuilderDirective } from './form-builder/form-builder.directive';
import { FormElementComponent } from './form-builder/form-element/form-element.component';
import { ResizableModule } from 'angular-resizable-element';
import { AngularDraggableModule } from 'angular2-draggable';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import { MultiselectNestedTreeModule } from './multiselect-nested-tree/public_api';

import { ChoiceComponent } from './form-builder/choice/choice.component';
import { SectionComponent } from './form-builder/section/section.component';
import { PageBreakComponent } from './form-builder/page-break/page-break.component';
import { PhotoAdderComponent } from './form-builder/photo-adder/photo-adder.component';

import { DragDropListBuilderComponent } from './form-builder/component-models/formly-controls/drag-drop-list-builder/drag-drop-list-builder.component';
import {FormlySelectModule } from '@ngx-formly/core/select';
import { SaveFormComponent } from './form-builder/storage/save-form/save-form.component';
import { LoadFormComponent } from './form-builder/storage/load-form/load-form.component';
import { SidebarComponentEditorComponent } from './form-builder/sidebar-component-editor/sidebar-component-editor.component';
import { RatingControlComponent } from './form-builder/component-models/rating-control/rating-control.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { DataLinkPopulatorComponent } from './form-builder/data-link-populator/data-link-populator.component';

import {SignaturePadEditorComponent} from './form-builder/component-models/signature-pad-editor/signature-pad-editor.component';
import { IonicModule } from '@ionic/angular';
import { PhotoPickerModule } from './testing/photo-picker/public_api';
import {LineItemsComponent} from './form-builder/component-models/line-items/line-items.component';
import { LineItemCreationIonicModule } from './line-item/line-item-creation/line-item-creation-ionic/public_api';
import {DiscountCreationIonicModule} from './discount/discount-creation-ionic/public_api';

import { CustomerDetailsModalComponent } from './jobs-page/customer-details-modal/customer-details-modal.component';
import { CustomerDisplayCardComponent } from './customer-display-card/customer-display-card.component';
import { MultipleJobSummaryComponent } from './multiple-job-summary/multiple-job-summary.component';
import { AddressSearchComponent } from './address_search/address-search/address-search.component';
import { AddressSearchModalComponent } from './address_search/address-search-modal/address-search-modal.component';

import { AbilityModule } from '@casl/angular';
import {PureAbility} from '@casl/ability'
import { AppAbility } from '../../../common/src/util/AppAbility';

import { CompanySettingsComponent } from './setting components/company-settings/company-settings.component';
import { SettingsComponent } from './setting components/settings.component';
import { SchedulerSettingsComponent } from './setting components/scheduler-settings/scheduler-settings.component';
import { CommunicationPreferenceSettingsComponent } from './setting components/communication-preference-settings/communication-preference-settings.component';
import { StatusSettingsComponent } from './setting components/status-settings/status-settings.component';
import { PricebookSettingsComponent } from './setting components/pricebook-settings/pricebook-settings.component';
import { RoleSettingsComponent } from './setting components/role-settings/role-settings.component';
import { BillingSettingsComponent } from './setting components/billing-settings/billing-settings.component';
import { CommunicationPreferenceModalComponent } from './setting components/communication-preference-settings/communication-preference-modal/communication-preference-modal.component';
import { GoogleAuthProvider, TwitterAuthProvider, EmailAuthProvider, PhoneAuthProvider } from '@angular/fire/auth';
import { ModifyEmployeeScheduleModalComponent } from './scheduler-view/modify-employee-schedule-modal/modify-employee-schedule-modal.component';
import { PriceBookComponent } from './price-book/price-book.component';
import { JobTypeComponent } from './setting components/pricebook-settings/job-type/job-type.component';
import { PricingMethodologySettingsComponent } from './setting components/pricing-methodology-settings/pricing-methodology-settings.component';
import { LineItemCreationModalModule } from './line-item/line-item-creation/line-item-creation-modal/public_api';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AddJournalEntryComponent } from './accounting/add-journal-entry/add-journal-entry.component';
import { InvoiceDisplayComponent } from './accounting/invoice-display/invoice-display.component';
import { JournalEntryDisplayComponent } from './accounting/journal-entry-display/journal-entry-display.component';
import { MultipleEstimateSummaryComponent } from './multiple-estimate-summary/multiple-estimate-summary.component';
import { LineItemDisplayModalComponent } from './line-item/line-item-display-modal/line-item-display-modal.component';
import { InvoiceDisplayModalComponent } from './accounting/invoice-display-modal/invoice-display-modal.component';
import { formlyDefaultsExtension } from './form-builder/component-models/formly-defaults-extension';
import { MatDialogSingleFieldModalModule } from './utility/mat-dialog-single-field-modal/public_api';
import { SiteVisitRescheduleModalModule } from './jobs/site-visit-reschedule-modal/public_api';
import { JobAttentionRequiredTableComponent } from './jobs/job-attention-required-table/job-attention-required-table.component';
import { DiscountCreationModalModule } from './discount/discount-creation-modal/public_api';
import { PercentageMaskDirective } from './directives/percentage-mask.directive';
import { BranchingContainerComponent } from './form-builder/branching-container/branching-container.component';
import { WorkflowSettingsComponent } from './setting components/workflow-settings/workflow-settings.component';
import { WorkflowSettingsTreeComponent } from './setting components/workflow-settings/workflow-settings-tree/workflow-settings-tree.component';
import { WorkflowSelectionModalComponent } from './form-builder/branching-container/workflow-selection-modal/workflow-selection-modal.component';
import { WorkflowHeaderComponent } from './form-builder/workflow-header/workflow-header.component';
import { AdminComponent } from './admin/admin.component';
import { ResendCustomerCommunicationModalComponent } from './customer-communication/resend-customer-communication-modal/resend-customer-communication-modal.component';
import { CustomerCommunicationDisplayModalComponent } from './customer-communication/customer-communication-display-modal/customer-communication-display-modal.component';
import { CustomerCommunicationsDeliveryDetailsModalComponent } from './customer-communication/customer-communications-delivery-details-modal/customer-communications-delivery-details-modal.component';

import { FormlyTextboxAutosizeTriggerableModule, FormlyTextboxAutosizeTriggerableComponent } from './form-builder/component-models/formly-controls/formly-textbox-autosize-triggerable/public_api';
import {FormlyPhotoAdderComponent,FormlyPhotoAdderModule} from './form-builder/component-models/formly-controls/formly-photo-adder/public_api';
import { LineItemDisplayModule } from './line-item/line-item-display';
import {AddEditPrototypeModalModule} from './line-item/line-item-creation/add-edit-prototype-modal/public_api';
import { FormlyLineItemComponent, FormlyLineItemModule } from './form-builder/component-models/formly-controls/formly-line-item/public_api';
import { FormlyRatingComponent, FormlyRatingModule } from './form-builder/component-models/formly-controls/formly-rating/public_api';
import { FormlyColumnSplitterComponent, FormlyColumnSplitterModule } from './form-builder/component-models/formly-controls/formly-column-splitter/public_api';
import {WrapperLabelComponent, FormlyWrapperManuallyPositionedLabelModule} from './form-builder/component-models/formly-wrappers/formly-wrapper-manually-positioned-label/public_api';
import {ManualSizeWrapperComponent, FormlyWrapperManualSizeModule} from './form-builder/component-models/formly-wrappers/formly-wrapper-manual-size/public_api';
import { FormlyChangeDetectComponent, FormlyWrapperChangeDetectModule } from './form-builder/component-models/formly-wrappers/formly-wrapper-change-detect/public_api';
import { FormlySectionComponent, FormlySectionModule } from './form-builder/component-models/formly-controls/formly-section/public_api';
import { FormlyBranchingContainerComponent, FormlyBranchingContainerModule } from './form-builder/component-models/formly-controls/formly-branching-container/public_api';
import { FormlyHorizontalWhitespaceComponent,FormlyHorizontalWhitespaceModule } from './form-builder/component-models/formly-controls/formly-horizontal-whitespace/public_api';
import { FormlyImageComponent, FormlyImageModule } from './form-builder/component-models/formly-controls/formly-image/public_api';
import { FormlyPageBreakComponent, FormlyPageBreakModule } from './form-builder/component-models/formly-controls/formly-page-break/public_api';
import { FormlySignaturePadComponent, FormlySignaturePadModule } from './form-builder/component-models/formly-controls/formly-signature-pad/public_api';
import { CancelJobConfirmationModalComponent } from './jobs/cancel-job-confirmation-modal/cancel-job-confirmation-modal.component';
import { JobAttachmentsModalModule } from './job-attachments/job-attachments-module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ScheduleModificationReqModalComponent } from './scheduler-view/schedule-modification-req-modal/schedule-modification-req-modal.component';
import { ReportTableViewComponent } from './report-table-view/report-table-view.component';

import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
// import {CoreModule} from '../../../common/src/error-handling/core/core.module';
import {SharedModule} from '../../../common/src/error-handling/shared/shared.module';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { SettingsTableComponent } from './setting components/settings-table/settings-table.component';
import { WorkflowDeploymentDialogComponentDialog } from './form-builder/workflow-header/workflow-deployment-dialog/workflow-deployment-dialog.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    TwitterAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: EmailAuthProvider.PROVIDER_ID
    },
    PhoneAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AddJobBoardComponent,
    AddCustomerComponent,
    AddressDisplayViewComponent,
    AddressDropdownComponent,
    AppComponent,
    CustomerBalanceComponent,
    CustomerDetailsComponent,
    CustomerSearchComponent,
    CustomTagsComponent,
    DateRangeSelectionComponent,
    HeaderComponent,
    TestingComponent,
    JobsPageComponent,
    JobDetailsComponent,
    OnDeckComponent,
    PageNotFoundComponent,
    SchedulerViewComponent,
    SidebarComponent,
    PhoneMaskDirective,
    CustomerPageComponent,
    CustomerJobSectionComponent,
    JobsPageHeaderComponent,
    MultipleSiteVisitSummaryComponent,
    DayWeekViewComponent,
    EventDetailsComponent,
    SiteVisitLengthComponent,
    JobChangeHistoryComponent,
    MultipleCustomerContainerComponent,
    ResolveDirtyAddressComponent,
    SvDataDisplaySelectionComponent,
    EmployeeComponent,
    FormBuilderComponent,
    FormDesignerComponent,
    ColumnSplitterComponent,
    TextboxControlComponent,
    WhitespaceControlComponent,
    ImageControlComponent,
    FormBuilderDirective,
    FormElementComponent,
    ChoiceComponent,
    SectionComponent,
    PageBreakComponent,
    PhotoAdderComponent,
    DragDropListBuilderComponent,
    SaveFormComponent,
    LoadFormComponent,
    SidebarComponentEditorComponent,
    RatingControlComponent,
    DataLinkPopulatorComponent,
    SignaturePadEditorComponent,
    LineItemsComponent,
    CustomerDetailsModalComponent,
    CustomerDisplayCardComponent,
    MultipleJobSummaryComponent,
    AddressSearchComponent,
    AddressSearchModalComponent,
    CompanySettingsComponent,
    SettingsComponent,
    SchedulerSettingsComponent,
    CommunicationPreferenceSettingsComponent,
    StatusSettingsComponent,
    PricebookSettingsComponent,
    RoleSettingsComponent,
    BillingSettingsComponent,
    CommunicationPreferenceModalComponent,
    ModifyEmployeeScheduleModalComponent,
    PriceBookComponent,
    JobTypeComponent,
    PricingMethodologySettingsComponent,
    AddJournalEntryComponent,
    InvoiceDisplayComponent,
    JournalEntryDisplayComponent,
    MultipleEstimateSummaryComponent,
    LineItemDisplayModalComponent,
    InvoiceDisplayModalComponent,
    JobAttentionRequiredTableComponent,
    PercentageMaskDirective,
    BranchingContainerComponent,
    WorkflowSettingsComponent,
    WorkflowSettingsTreeComponent,
    WorkflowSelectionModalComponent,
    WorkflowHeaderComponent,
    AdminComponent,
    ResendCustomerCommunicationModalComponent,
    CustomerCommunicationDisplayModalComponent,
    CustomerCommunicationsDeliveryDetailsModalComponent,
    CancelJobConfirmationModalComponent,
    ScheduleModificationReqModalComponent,
    ReportTableViewComponent,
    SettingsTableComponent,
    WorkflowDeploymentDialogComponentDialog,
  ],
  imports: [
    JobAttachmentsModalModule,
    SiteWideSearchBarModule,
    NavigationModule,
    TimeFieldModule,
    SiteVisitRescheduleModalModule,
    LineItemCreationModalModule,
    DiscountCreationModalModule,
    LineItemCreationIonicModule,
    MatDialogSingleFieldModalModule,
    PhotoPickerModule,
    DiscountCreationIonicModule,
    AddEditPrototypeModalModule,
    MultiselectNestedTreeModule,

    // These should be probably be lazy loaded when workflow page, or form designer page is routed to.
    LineItemDisplayModule,
    FormlyTextboxAutosizeTriggerableModule,
    FormlyPhotoAdderModule,
    FormlyLineItemModule,
    FormlyRatingModule,
    FormlyColumnSplitterModule,
    FormlyWrapperManuallyPositionedLabelModule,
    FormlyWrapperManualSizeModule,
    FormlyWrapperChangeDetectModule,
    FormlySectionModule,
    FormlyBranchingContainerModule,
    FormlyHorizontalWhitespaceModule,
    FormlyImageModule,
    FormlyPageBreakModule,
    FormlySignaturePadModule,

    NgxMaskModule.forRoot(),
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    BryntumAngularSharedModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatListModule,
    MatCardModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    LayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatGridListModule,
    MatTreeModule,
    MatBadgeModule,
    MatChipsModule,
    MatButtonModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTableModule,
    MatSliderModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    HttpClientModule,
    MatSelectModule, // added by greg for the navigation menu drop down
    MatMenuModule, // added by greg for the navigation menu drop down
    provideFirebaseApp(() => initializeApp(environment.firebase,"SV-WEB")),
    provideStorage(() => getStorage(undefined,'gs://service-vanguard')),
    AngularFireAuthModule,
    MatRadioModule,
    MatTabsModule,
    ObserversModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    DragDropModule,
    AngularSplitModule,
    ResizableModule,
    AngularDraggableModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'manual-size', component: ManualSizeWrapperComponent },
        { name: 'manual-label', component: WrapperLabelComponent},
        { name: 'change-detect', component: FormlyChangeDetectComponent},
      ],
      types: [
        { name: 'formlySplitter', component: FormlyColumnSplitterComponent},
        { name: 'formlyHeightSpacer', component: FormlyHorizontalWhitespaceComponent},
        { name: 'formlyImage', component: FormlyImageComponent},
        {name: 'formlyPageBreak', component: FormlyPageBreakComponent},
        {name: 'formlyPhotoAdder', component: FormlyPhotoAdderComponent},
        {name: 'formlyRating', component: FormlyRatingComponent},
        {name: 'formlySignaturePad', component: FormlySignaturePadComponent },
        {name: 'formlySection', component: FormlySectionComponent},
        {name: 'formlyLineItems', component: FormlyLineItemComponent},
        {name: 'formlyBranchingContainer', component: FormlyBranchingContainerComponent},
        {name: 'formlyTextAreaAutoSizing', component: FormlyTextboxAutosizeTriggerableComponent},
      ],
      extensions: [
        {
        name: 'default-formly-extension',
        extension: formlyDefaultsExtension
        }
      ]
    }),
    FormlyMaterialModule,
    FormlySelectModule,
    IonicModule.forRoot(),
    AbilityModule,
    CurrencyMaskModule,
    MatPaginatorModule,
    MatSortModule,

    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs',
      serverLoggingUrl: 'https://us-central1-service-vanguard.cloudfunctions.net/ml-logMobileError',
      // serverLoggingUrl: 'http://localhost:5001/service-vanguard/us-central1/ml-logMobileError',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.LOG,
      customHttpHeaders: new HttpHeaders({ 'Content-Type' : 'application/json' }),
      enableSourceMaps:true,
      serverCallsTimer : 15000,
      serverCallsBatchSize: 10,
    }),
    // CoreModule,
    SharedModule,
  ],
  exports: [
    PhoneMaskDirective,
    PercentageMaskDirective,
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: AppAbility, useValue: new AppAbility() },
    { provide: PureAbility, useExisting: AppAbility },
    CurrencyPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
