import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Address } from "./address";
import {Customer} from "./customer";

export class AddressCustomerMapping extends RetrieveFirestorePropertiesBase<AddressCustomerMapping>  {

    // Custom objects.
    billingCustomerDocIds: string[];
    billingCustomers: Customer[];

    siteVisitContactCustomerDocIds: string[];
    siteVisitContactCustomers: Customer[];

    //Principal customer.
    primaryCustomerDocId: string;
    primaryCustomer: Customer;

    serviceAddressDocId: string;
    serviceAddress: Address;

    static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames .concat("serviceAddress", "billingCustomers", "siteVisitContactCustomers", "primaryCustomer");
    static _firestoreCompositionMemberNames: string[] = ["billingCustomers", "siteVisitContactCustomers","primaryCustomer", "serviceAddress"];
    static _firestoreCompositionalDiffMemberNames: string[] = [];
    static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
    .concat(["billingCustomerDocIds","siteVisitContactCustomerDocIds","primaryCustomerDocId","serviceAddressDocId","billingCustomers", "siteVisitContactCustomers","primaryCustomer", "serviceAddress"]);

    retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return AddressCustomerMapping._firestoreIgnoreDiffTrackingMembers; }
    retrievefirestoreCompositionalDiffMemberNames() : string[] {return AddressCustomerMapping._firestoreCompositionalDiffMemberNames;}
    retrievefirestoreCompositionMemberNames() : string[] { return AddressCustomerMapping._firestoreCompositionMemberNames;}
    retrieveFirestoreIgnoredMemberNames() : string[] { return AddressCustomerMapping._firestoreIgnoredMemberNames};


    public constructor(init?: Partial<AddressCustomerMapping>) {
      super();
      Object.assign(this, init);
    }


    static buildCustomerRoleEntry(addressCustomerMapping: AddressCustomerMapping, customer : Customer) : {customer: Customer, role: string, primary: boolean} {
      const retVal = {customer, role: "", primary: false};
      if (addressCustomerMapping.billingCustomerDocIds.some(billing => billing === customer.DocId())) {
        if (addressCustomerMapping.siteVisitContactCustomerDocIds.some(contact => contact === customer.DocId())) {
          retVal.role = "All";
        } else {
          retVal.role = "Billing Contact";
        }
      } else {
        retVal.role = "Site Visit Contact";
      }
      if (addressCustomerMapping.primaryCustomerDocId === customer.DocId()) {
        retVal.primary = true;
      }
      return retVal;
    }
  }
