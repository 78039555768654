import { Customer } from "../../../../common/src/data/dao/customer";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Job } from "../../../../common/src/data/dao/job";

export enum CustomerCommunicationCategory {
  PAYMENT = 0,
  APPOINTMENT=1,
  ESTIMATE=2,
  WORK_PERFORMED=3,
}

export enum CustomerCommunicationPurpose {
  UNKNOWN=0,
  CONFIRMATION=1,
  REMINDER=2,
  INITIAL_CONTACT_RE_TOPIC=3,
}

export enum CustomerCommunicationType {
  EMAIL = "Email",
  SMS= "SMS",
  BOTH= "Both",
}

export class CustomerCommunicationTemplate extends RetrieveFirestorePropertiesBase<CustomerCommunicationTemplate>  {

retrieveContacts(j: Job) : Customer[] {
  if (this.communicationCatagory === CustomerCommunicationCategory.APPOINTMENT) {
    return j.siteVisitContactCustomers;
  } else {
    return j.billingCustomers;
  }
}

body: string;
subject: string;
title: string;
description: string;
communicationCatagory : CustomerCommunicationCategory;
customerCommuncationType : CustomerCommunicationType = CustomerCommunicationType.EMAIL;
enabled: boolean = true;
allowFollowUpMessaging: boolean = true;
sendFollowUpMessaging: boolean = false;
allowJobWorkflowAttachment: boolean = false;
includeJobWorkflowAttachment: boolean = false;
considerInline: boolean = false;
inlineEstimate: boolean = false;
inlineInvoice: boolean = false;

maximumAllowedFollowUpMessages: number = 3;

customerCommunicationPurpose: CustomerCommunicationPurpose = CustomerCommunicationPurpose.UNKNOWN;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return CustomerCommunicationTemplate._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return CustomerCommunicationTemplate._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return CustomerCommunicationTemplate._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return CustomerCommunicationTemplate._firestoreCompositionMemberNames;}

public constructor(init?: Partial<CustomerCommunicationTemplate>) {
super();
Object.assign(this, init);
}
}
