import { Injectable } from '@angular/core';
;
import { addMinutes, getDay, getHours, getMinutes, subDays } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import { BehaviorSubject, combineLatest, Observable, throwError, ReplaySubject, from } from 'rxjs';
import { take, catchError, map, takeUntil, tap, filter, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { EmployeeAvailability } from '../dao/employee-availability';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class EmployeeAvailabilityService extends DatabaseStoreService<EmployeeAvailability> {

  public futureAvailibility: BehaviorSubject<EmployeeAvailability[]> = new BehaviorSubject([]);
  public loadedAvailibility$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor( fs: EmployeeAvailabilityFirestoreService, authenticationService: AuthenticationService, store: EmployeeAvailabilityStoreService) {
    super(fs, store, authenticationService);

    const loadAvail =
    this.authenticationService.isLoggedIn$.pipe(
      filter(x => x === true),
      switchMap(() => this.loadEmployeeAvailibility()),
    );

    if (this.authenticationService.mobileSite) {
      loadAvail.pipe(
        take(1)
      ).subscribe();
    } else {
      loadAvail.subscribe();
    }
  }

  private loadEmployeeAvailibility() : Observable<EmployeeAvailability[]> {
    const loadAllAvail =  this.loadAllAfterDate$(subDays(new Date(), 30)).pipe(
      tap(availabilities => this.futureAvailibility.next(availabilities)),
      tap(() => this.loadedAvailibility$.next(true)),
      catchError(err => {
        if (err.message === "Missing or insufficient permissions." && this.authenticationService._userId !== undefined ) {
          window.alert("E-mail address is no longer associated with an active account, though it was at one time.  Either login with new e-mail address, or contact your administrator to re-associate your e-mail address with an active account.");
          from(this.authenticationService.SignOut()).pipe(
            take(1),
          ).subscribe();
        }
      console.log('Error caught in observable.', err);
      return throwError(err);
      }),
    );
    return loadAllAvail;
  }

  public getDefaultAvailabilityForDate(date: Date, employeeDocId: string) : EmployeeAvailability {
    const avail = new EmployeeAvailability({...this.futureAvailibility.value.find(avail =>  getDay(avail.date) === getDay(date) && avail.employeeDocId === employeeDocId && avail.defaultAvailability)});
    // mutate date to be the same date as the date passed in.
    avail.workStartTime = EmployeeAvailabilityService.convertTimeToSameTimeOnSpecifiedDate(avail.workStartTime, date);
    avail.workEndTime = EmployeeAvailabilityService.convertTimeToSameTimeOnSpecifiedDate(avail.workEndTime, date);
    return avail;
  }

  public getAvailibilityForDate(date: Date, employeeDocId: string) : EmployeeAvailability {
   const start = startOfDay(date);
    const oneOff = this.futureAvailibility.value.find(avail => avail.date.getTime() === start.getTime() && avail.employeeDocId === employeeDocId);
    if (oneOff) {
      return oneOff;
    } else {
      return this.getDefaultAvailabilityForDate(start, employeeDocId);
    }
  }

  public static convertTimeToSameTimeOnSpecifiedDate(time: Date, dayInQuestion?: Date) : Date {
    if (dayInQuestion === undefined) {
      dayInQuestion = new Date();
    }
    const today = startOfDay(dayInQuestion);
    return addMinutes(today,  getHours(time) * 60 + getMinutes(time));
  }

  public getEmployeeStartTimeForDate(date: Date, employeeDocId: string) : Date {
    const beginningOfDay = startOfDay(date);
    const availibility = this.getAvailibilityForDate(beginningOfDay, employeeDocId);
    return this.getEmployeeStartTimeForAvailibility(availibility, beginningOfDay);
  }

  //**Assumes that passed in date is startOfDay() */
  public getEmployeeStartTimeForAvailibility(availibility: EmployeeAvailability, date: Date) : Date {
    if (availibility && availibility.activeWorkDay) {
      return addMinutes(date,  getHours(availibility.workStartTime) * 60 + getMinutes(availibility.workStartTime));
    } else {
      return null;
    }
  }

    //**Assumes that passed in date is startOfDay() */
  public getEmployeeEndTimeForDate(date: Date, employeeDocId: string) : Date {
    const beginningOfDay = startOfDay(date);
    const availibility = this.getAvailibilityForDate(beginningOfDay, employeeDocId);
    return this.getEmployeeEndTimeForAvailibility(availibility, beginningOfDay);
  }

  public getEmployeeEndTimeForAvailibility(availibility: EmployeeAvailability, date: Date) : Date {
    if (availibility && availibility.activeWorkDay) {
      return addMinutes(date,  getHours(availibility.workEndTime) * 60 + getMinutes(availibility.workEndTime));
    } else {
      return null;
    }
  }

  public getMinutesWorkingForDate(date: Date, employeeDocId: string) : number {
    const availibility = this.getAvailibilityForDate(date, employeeDocId);
    return this.getMinutesWorkingForAvailibility(availibility);
  }

  public getMinutesWorkingForAvailibility(availibility: EmployeeAvailability) : number {
    if (availibility && availibility.activeWorkDay) {
      return  availibility.workEndTime.getMinutes() + availibility.workEndTime.getHours() * 60 - availibility.workStartTime.getMinutes() - availibility.workStartTime.getHours() * 60;
    } else {
      return 0;
    }
  }

  loadDefaultAvailability() : Observable<EmployeeAvailability[]> {
    return this.queryFirestoreDeep$([where('defaultAvailability', '==', true),where('active', '==', true)]);
  }

  loadAllAfterDate$(startDate: Date) : Observable<EmployeeAvailability[]> {
    const availibilityOverrides = this.queryFirestoreDeep$([where('date','>=',startOfDay(startDate))]).pipe(
      map(availabilities => availabilities.filter(avail => !avail.defaultAvailability))
    );

    return combineLatest([this.loadDefaultAvailability(),availibilityOverrides ])
    .pipe(
      map( ([defaultAvailabilities, availabilities]) => defaultAvailabilities.concat(availabilities)),
      )
  }
}

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeeAvailabilityStoreService extends StateChangeStoreService<EmployeeAvailability> {
    protected store = "employee-store";

    constructor(firestoreDiffService: FirestoreDiffService) {
      super(new Map<string, EmployeeAvailability>(), true, firestoreDiffService);
    }
  }

  @Injectable({
    providedIn: 'root'
    })
    class EmployeeAvailabilityFirestoreService extends FirestoreBackend<EmployeeAvailability> {

    protected basePath = "employee_availability";

    public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): EmployeeAvailability {
      return new EmployeeAvailability(obj);
    }

    constructor(protected authService: AuthenticationService) {
      super(new EmployeeAvailability(), authService);
    }
  }
