import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, tap, combineLatest, startWith } from 'rxjs';
import { Attachment, Attachment_Source } from '../../../../common/src/data/dao/attachment';

// TODO: Replace this with your own data model type
export interface JobAttachmentsItem {
  name: string;
  createdOn: Date;
  url: string;
  attachmentType: Attachment_Source;
}

/**
 * Data source for the JobAttachments view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class JobAttachmentsDataSource extends DataSource<JobAttachmentsItem> {
  data: Observable<JobAttachmentsItem[]>;
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  constructor(attachments: Observable<Attachment[]>) {
    super();
    this.data = attachments.pipe(
      map(attachments => {
      const retVal = [];
      for (const attachment of attachments) {
        retVal.push({name: attachment.name,
        createdOn: attachment.createdAt,
        url: attachment.url,
        attachmentType: attachment.attachmentType,
        docId: attachment.docId
        });
      };
      return retVal;
    }));
    this.paginator = undefined;
    this.sort = new MatSort();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<JobAttachmentsItem[]> {
    if (this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return combineLatest([this.data, this.sort.sortChange.pipe(startWith(null))])
        .pipe(
          map(x => {
          return this.getSortedData([...x[0] ]);
        }));
    } else {
      throw Error('Please set sort on the data source before connecting.');
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: JobAttachmentsItem[]): JobAttachmentsItem[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: JobAttachmentsItem[]): JobAttachmentsItem[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'createdOn' : return compare(a.createdOn.getTime(), b.createdOn.getTime(), isAsc);
        case 'attachmentType' : return compare(a.attachmentType, b.attachmentType, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
