/// <reference types="google.maps" />
import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { Observable, of, merge, combineLatest, throwError, Subject } from 'rxjs';
import { distinctUntilChanged,  filter, map, tap, share,  mergeMap, catchError, switchMap, take, debounceTime, delay } from 'rxjs/operators';
import { CustomerService } from '../dao-services/customer.service';
import { Customer } from '../dao/customer';
import { AddressService } from '../dao-services/address.service';
import { Address } from '../dao/address';

import { JobService } from '../dao-services/job.service';
import { Job } from '../dao/job';
import { limit, orderBy, where } from 'firebase/firestore';
import { SearchService, searchInput, searchResults, searchSource } from './search.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Returns shallow customer and address objects.  Between good practice, and necessary to hydrate results on selection.
 */
export class CustomerSearchService extends SearchService {

  protected search: Subject<searchInput>;
  private rawSearch$: Observable<searchInput>;

  googlePlacesAutocompleteService = new google.maps.places.AutocompleteService();
  home = new google.maps.LatLng(39.492740, -76.563490);
  serviceRadius = 5000;

  // Service Address Middle Streams
  googleRawAddressResults$ = new EventEmitter<{googlePrediction: google.maps.places.AutocompletePrediction[], search: string}>();


  constructor(private _ngZone: NgZone, private customerService: CustomerService, private addressService: AddressService, private jobService: JobService) {
    super();
    this.search = new Subject<searchInput>();
    this.rawSearch$ = this.search.asObservable();

    const globalSearchCleaned$ = this.rawSearch$.pipe(
      filter( x => x !== undefined && x.search !== undefined && x.search.length > 2),
      distinctUntilChanged((a,b) => a.search === b.search),
      tap(x => {
        // Because search is run outside NgZone, we only send along if we desire to retrieve results from google.
        if (x.searchSources.includes(searchSource.GooglePlaceAutoComplete) || x.searchSources.includes(searchSource.All))
        this.getAutocompleteAddressPredictions(x.search);
      }),
      delay(1),
      share(),
      );

    const internalServiceProviderJobNumberActive$ = globalSearchCleaned$.pipe(
      filter(x => x.searchSources.includes(searchSource.InternalServiceProviderJobNumber) || x.searchSources.includes(searchSource.All)),
      map(x => x.search),
      mergeMap(search => this.jobService.queryFirestoreDeep$([where("serviceProviderSpecifiedJobId", '==', search)]).pipe(
        map(res => res.length > 0 ? res[0] : undefined),
        map(j => {
          if (j===undefined) {
            return ({results: [], search});
          } else {
          return ({results: [{address: j.serviceAddress, customer: j.customer, job: j, resultSource: "INTERNALSERVICEPROVIDERJOBNUMBER"}], search});
          }
        }),
      )));

    const internalServiceProviderJobNumberInactive$ = globalSearchCleaned$.pipe(
      filter(x => !x.searchSources.includes(searchSource.InternalServiceProviderJobNumber) && !x.searchSources.includes(searchSource.All)),
      map(x => ({results: [], search: x.search})));

    const internalServiceProviderJobNumberSearch = merge(internalServiceProviderJobNumberActive$, internalServiceProviderJobNumberInactive$);

    const companySearchActive$ = this.buildActiveSearchObservable(globalSearchCleaned$, searchSource.InternalCustomerCompany,"company");

    const emailSearchActive$ = this.buildActiveSearchObservable(globalSearchCleaned$, searchSource.InternalCustomerEmail, "customerEmail");

    const nameSearchActive$ = this.buildActiveSearchObservable(globalSearchCleaned$, searchSource.InternalCustomerName, "customerName");

    const companySearchInactive$ = globalSearchCleaned$.pipe(
      filter(x => !x.searchSources.includes(searchSource.InternalCustomerCompany) && !x.searchSources.includes(searchSource.All)),
      map(x => ({results: [], search: x.search})));

    const companySearch$ = merge(companySearchActive$, companySearchInactive$);

    const emailSearchInactive$ = globalSearchCleaned$.pipe(
      filter(x => ! x.searchSources.includes(searchSource.InternalCustomerEmail) && !x.searchSources.includes(searchSource.All)),
      map(x => ({results: [], search: x.search})));

    const emailSearch$ = merge(emailSearchActive$, emailSearchInactive$);

    const phoneNumberActive$ = globalSearchCleaned$.pipe(
      filter(x => x.searchSources.includes(searchSource.InternalCustomerPhone) || x.searchSources.includes(searchSource.All)),
      map(x => x.search));

    const phoneNumberInActive$ = globalSearchCleaned$.pipe(
        filter(x => ! x.searchSources.includes(searchSource.InternalCustomerPhone) && !x.searchSources.includes(searchSource.All)),
        map(x => ({results: [], search: x.search})));

    const phoneNumberShort$ = phoneNumberActive$.pipe(
    filter(x => x.length < 4),
    map(x => ({results: [], search: x.search})));

  // for moment, do not search phone number even if long enough as we need to implement
  // full text soluton to do.
    const phoneNumberLong$ = phoneNumberActive$.pipe(
    filter(x => x.length >= 4),
    map(x => ({results: [], search: x.search})));

    const phoneNumber$ = merge(phoneNumberShort$, phoneNumberLong$, phoneNumberInActive$);

    const nameSearchInactive$ = globalSearchCleaned$.pipe(
      filter(x => ! x.searchSources.includes(searchSource.InternalCustomerName) && ! x.searchSources.includes(searchSource.All)),
      map(x => ({results: [], search: x.search})));

    const nameSearch$ = merge(nameSearchActive$, nameSearchInactive$);

    const googleDecoratedAddressResultsActive$ = this.googleRawAddressResults$.pipe(
      map(x => {
        const found: {address: Address, customer?: Customer, resultSource: searchSource}[] = [];
        if (x !== null && x.googlePrediction !== null) {
        x.googlePrediction = x.googlePrediction.sort( (a,b) => a["distance_meters"] as number - b["distance_meters"] as number);
        x.googlePrediction.forEach(z => {
          if (!z.types.includes("route")) {
            found.push({address: new Address({_formattedAddress: z.description, googlePlaceId: z.place_id}), customer: null, resultSource: searchSource.GooglePlaceAutoComplete});
          }
        }
          );
        }
        return ({results: found, search: x.search});
      }));

    const googleDecoratedAddressResultsInactive$ = globalSearchCleaned$.pipe(
        filter(x => ! x.searchSources.includes(searchSource.GooglePlaceAutoComplete) && ! x.searchSources.includes(searchSource.All)),
        map(x => ({results: [], search: x.search})));

    const googleDecoratedAddressResults$ = merge(googleDecoratedAddressResultsActive$, googleDecoratedAddressResultsInactive$);

    const internalAddressResultsActive$ = globalSearchCleaned$.pipe(
      filter(x => x.searchSources.includes(searchSource.InternalServiceAddress) || x.searchSources.includes(searchSource.All)),
      map(x => x.search),
      mergeMap(search => this.addressService.searchAddress(search).pipe(
      map(res => {
        return {search: search, results: res};
      }),
      share()
      )));

      const internalAddressResultsActiveWithResults = internalAddressResultsActive$.pipe(
      filter(addresses => addresses.results.length > 0),
      mergeMap(addresses => this.populateCustomersFromInternalStreetMatches(addresses.results, addresses.search).pipe(
      map(x => {
        return {results: x, search:addresses.search};
      }
      ))));

      const internalAddressResultsActiveWithNoResults = internalAddressResultsActive$.pipe(
      filter(addresses => addresses.results.length === 0),
      map(addresses => ({results: [], search: addresses.search})));



      const internalAddressResultsInactive$ = globalSearchCleaned$.pipe(
        filter(x => ! x.searchSources.includes(searchSource.InternalServiceAddress) && ! x.searchSources.includes(searchSource.All)),
        map(x => ({results: [], search: x.search})));

      const internalAddressResults$ = merge(internalAddressResultsActiveWithResults, internalAddressResultsActiveWithNoResults, internalAddressResultsInactive$);

    const allInternalResults$ = combineLatest([internalAddressResults$, nameSearch$, emailSearch$, companySearch$, internalServiceProviderJobNumberSearch]).pipe(
    filter(([internalAddress, name, email, company, jobNumber]) => (internalAddress.search === name.search) && (name.search === email.search) && (email.search === company.search)
    && (company.search === jobNumber.search)),
    map(([internalAddress, name, email, company, jobNumber]) => {
      const found = internalAddress.results.concat(name.results).concat(email.results).concat(company.results).concat(jobNumber.results);
      return ({results: found, search: internalAddress.search});
    }
  ));

    const internalAndGoogleCombinedAddressResults$ = combineLatest([googleDecoratedAddressResults$,
                                                                    allInternalResults$, this.rawSearch$]).pipe(
    filter(([goog, internalResults, rawSearch]) => (goog.search === internalResults.search) && (goog.search === rawSearch.search)),
    map(([goog, internalResults, rawInput]) => {
      const found: {address: Address, customer?: Customer, resultSource: searchSource}[] = [];
      internalResults.results.forEach(res => found.push(res));
      if (internalResults.results.length > 0 && goog.results.length > 0) {
        found.push({address: null, customer: null, resultSource: null});
      }
      goog.results.forEach( googleAddress => found.push(this.updateGoogleAddressObjectToExistingIfApplicible(googleAddress, internalResults.results)));
      return {searchInput: rawInput, results: found };
    }
  ),
  share(),
  );

  this.searchResults$ = internalAndGoogleCombinedAddressResults$;
 }


  populateCustomerAddresses(customers: Customer[]): Observable<Customer[]> {
    return this.addressService.loadMultiple$(customers.map(y => y.addressDocId)).pipe(
      map(addresses => {
        addresses.forEach(address =>
          customers.forEach( aCustomer => {
            if (aCustomer.addressDocId === address.docId) {
              aCustomer.address = address;
            }})
          );
        return customers;
        }
      )
    );
  }

  public addSearch(search: searchInput) {
    // replace enter key with '' in search term
    search.search = search.search.replace(/\n/g, '');
    this.search.next(search);
  }

  private getAutocompleteAddressPredictions(search: string) {

    const request = {
      input: search,
      location: this.home,
      origin: this.home,
      radius: this.serviceRadius,
      types: ['address'],
      componentRestrictions: {country: ['us']}
    };

    this.googlePlacesAutocompleteService.getPlacePredictions(request, data => {
      this._ngZone.run(() => {
        this.googleRawAddressResults$.next({googlePrediction: data, search});
    });
    });
  }

  populateCustomersFromInternalStreetMatches(addresses: Address[], search: string): Observable<searchResults[]> {
    if (addresses.length === 0) {
      return of([]);
    }
    // get jobs that are associated with
    console.log(addresses.map(x => x.DocId()));
    return this.jobService.queryFirestoreForInValues("serviceAddressDocId", addresses.map(x => x.DocId())).pipe(
      tap(x => console.log(x,` string`)),
      map(jobs => {
        jobs.forEach(job => {
          addresses.filter(a => a.DocId() === job.serviceAddressDocId).forEach(a => job.serviceAddress = a);
        });
        return jobs;
      }),
      switchMap(jobs => this.customerService.queryFirestoreForInValues("customerDocId", jobs.map(j => j.customerDocId)).pipe(
        tap(x => console.log(x,` string`)),
        debounceTime(200),
        map(customers => {
          customers.forEach(customer => {
            jobs.filter(j => j.customerDocId === customer.customerDocId).forEach(j => j.customer = customer)
          });
          return jobs;
        }),
        // take(1)
        )),
      map(mappedJobs =>  {
      const found: {address: Address, customer?: Customer, job: Job, resultSource: searchSource}[] = [];
      mappedJobs.forEach( job => {
        found.push({address: job.serviceAddress, customer: job.customer,  job, resultSource: searchSource.InternalServiceAddress});
      });
      return found.filter(j => j.customer !== undefined);
      }));
  }

  buildActiveSearchObservable(globalSearchCleaned$: Observable<searchInput>, s: searchSource, inclusionFieldName: string ) : Observable<{results: {
        address: Address,
        customer: Customer,
        resultSource: searchSource
    }[], search: string}> {
      return globalSearchCleaned$.pipe(
        filter(x => x.searchSources.includes(s) || x.searchSources.includes(searchSource.All)),
        map(x => x.search),
        mergeMap(search => this.customerService.queryFirestoreDeep$([where(inclusionFieldName, '>=', search),
          orderBy(inclusionFieldName),
          limit(5)]).pipe(
          map(customers => customers.filter(z => z[inclusionFieldName].toLowerCase().includes(search.toLowerCase()))),
          map(customers => ({results: customers.map(c => ({address: c.address, customer: c, resultSource: s})), search})),
        )),
        catchError(err => {
          console.log('Error caught in observable.', err);
          return throwError(err);
          })
        );
    }


   updateGoogleAddressObjectToExistingIfApplicible(googleAddress: any, internalResults: any[] )
   {
     const present = internalResults.find(q => q.address !== null && googleAddress.address !== null && googleAddress.address.googlePlaceId !== undefined &&
      q.address.googlePlaceId === googleAddress.address.googlePlaceId);
      if (present !== undefined) {
        googleAddress.address = present.address;
      }
      return googleAddress;
   }

}
