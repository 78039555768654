import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { JobAttachmentsDataSource, JobAttachmentsItem } from './job-attachments-datasource';
import { Job } from '../../../../common/src/data/dao/job';
import { JobService } from '../../../../common/src/data/dao-services/job.service';
import { Attachment, Attachment_Source } from '../../../../common/src/data/dao/attachment';
import { BehaviorSubject, Subject, filter, interval, map, switchMap, take, takeUntil, tap, zip } from 'rxjs';
import { AngularFirestorageService } from '../angular-firestorage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-job-attachments',
  templateUrl: './job-attachments.component.html',
  styleUrls: ['./job-attachments.component.scss']
})
export class JobAttachmentsComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<JobAttachmentsItem>;
  dataSource: JobAttachmentsDataSource;
  job: Job;
  CompletedUpload: Subject<string> = new Subject<string>();
  hideUpload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  attachments$: BehaviorSubject<Attachment[]> = new BehaviorSubject<Attachment[]>([]);

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['createdOn', 'url', 'attachmentType', 'Delete'];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private angularFireStorageService: AngularFirestorageService, private jobService: JobService,
  private snackBar: MatSnackBar, private ref: ChangeDetectorRef) {

    this.dataSource = new JobAttachmentsDataSource(this.attachments$);
    this.attachments$.next(data.attachments);
    this.hideUpload.next(data.hideUpload);
    this.job = data.job;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = undefined;
    this.table.dataSource = this.dataSource;
  }

  delete(row: any)
  {

    this.job.externalAttachments = this.job.externalAttachments.filter(x => x.docId !== row.docId);
    this.jobService.update$(this.job).pipe(
      tap(() => this.attachments$.next(this.job.externalAttachments)),
      tap(() => this.table.renderRows()),
      tap(() => this.ref.markForCheck()),
      take(1)
    ).subscribe();
  }


  onFileInput(event: any) {

    const file = event.target.files[0];

    this.snackBar.open(`Uploading ${file.name}`);

    zip(interval(350),this.CompletedUpload.pipe(filter(x => x === file.name))).pipe(
      tap(() => this.snackBar.dismiss()),
      take(1)
    ).subscribe();

    this.angularFireStorageService.downloadURL$.pipe(
      filter(x => x.fileName === file.name),
      map(x => {
        const attachment = new Attachment();
      attachment.url = x.downloadUrl;
      attachment.name = file.name;
      attachment.attachmentType= Attachment_Source.IMPORTED;
      this.job.externalAttachments.push(attachment);
      return this.job;
      }),
      switchMap(x => this.jobService.update$(x)),
      tap(() => this.CompletedUpload.next(file.name)),
      tap(x => this.attachments$.next(x.externalAttachments)),
      tap(() => this.table.renderRows()),
      tap(() => this.ref.markForCheck()),
      take(1),
    ).subscribe();

    this.angularFireStorageService.progress$.pipe(
      filter(x => x.fileName === file.name),
      tap(x => {
        this.snackBar.dismiss();
        this.snackBar.open(`Uploading ${file.name} ${Math.round(x.progress)}%`);
      }),
      takeUntil(this.CompletedUpload.pipe(filter(x => x === file.name)))
    ).subscribe();
    this.angularFireStorageService.uploadFilePopulateDownloadUrl(file, file.name);

  }
}
