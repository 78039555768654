import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { addHours, addMinutes, formatISO, getHours, getMinutes, startOfDay } from 'date-fns';
import { Subject } from 'rxjs/internal/Subject';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { SettingsService } from '../../settings/settings.service';
import { LineItemService } from '../../../../../common/src/data/dao-services/line-item.service';
import { LineItem } from '../../../../../common/src/data/dao/line-item';
import { AuthenticationService } from '../../../../../common/src/util/authentication.service';
import { Observable, combineLatest, switchMap } from 'rxjs';

export enum LINE_ITEM_CREATION_MODE {
  PROTOTYPE= 1,
  INSTANTIATE =2,
}

export enum PROTOTYPE_INTENTION_ON_EDIT {
  UPDATE=0,
  CREATE=1,
  UNKNOWN=2,
}

export enum EXIT_STATUS {
  CREATE=0,
  UPDATE=1,
  SELECT=2,
  UNKNOWN=3,
}

@Injectable({
  providedIn: 'root'
})
export class LineItemCreationService {

  constructor(private fb: UntypedFormBuilder, private currencyPipe: CurrencyPipe, private settingsService: SettingsService,
    private lineItemService: LineItemService, private authenticationService: AuthenticationService) { }


  buildLineItemFormGroup(destroy$: Subject<any> ) : UntypedFormGroup {

    const retVal = this.fb.group ({
      title: ["", [Validators.required, Validators.minLength(3)]],
      description: [],
      box_price_formatted: [],
      price: [0, [Validators.required, Validators.min(0)]],
      totalExpectedDurationString: this.fb.control({value: "", disabled: true}),
      durationHours: [0,[Validators.required, Validators.min(0)]],
      daysLineItemDuration: [,[]],
      expectedDurationOneLineItemString: this.fb.control({value: "", disabled: true}),
      singleQuantityDurationAsTime: ["00:00"],
      internalNotes: [""],
      quantity: [1, [Validators.required, Validators.min(1)]],
      totalPrice: this.fb.control({value: 0, disabled: true}),
      requiredSkills: [[]],
    });


    const timePortionOfDuration = retVal.get("singleQuantityDurationAsTime").valueChanges.pipe(
      startWith(retVal.get("singleQuantityDurationAsTime").value),
      filter(x => x!==null),
      map(a => a as string),
      map(d => this.getHoursFromTimeDuration(d))
    );

    const daysPortionOfDuration = retVal.get("daysLineItemDuration").valueChanges.pipe(
      startWith(0),
      map(a => a * 12)
    );

    combineLatest([timePortionOfDuration, daysPortionOfDuration]).pipe(
      tap(([timeDuration,dayDuraction]) => {
        const duration = timeDuration + dayDuraction;
        retVal.patchValue(
        {durationHours: duration,
        expectedDurationOneLineItemString: this.getTotalExpectedDurationString(1, duration),
        totalExpectedDurationString:  this.getTotalExpectedDurationString(retVal.get("quantity").value, duration)}
        )
      }),
      takeUntil(destroy$)
    ).subscribe();

    retVal.get("price").valueChanges.pipe(
      tap(x => console.log(x,` string`)),
      tap(price => retVal.patchValue({totalPrice : retVal.get("quantity").value*price})),
      takeUntil(destroy$)
    ).subscribe();

    retVal.get("quantity").valueChanges.pipe(
      tap(quantity => retVal.patchValue({totalPrice :retVal.get("price").value*quantity,
        totalExpectedDurationString: this.getTotalExpectedDurationString(quantity, retVal.get("durationHours").value)})),
      takeUntil(destroy$)
    ).subscribe();

    console.log(retVal);
    return retVal;
  }


  getHoursFromTimeDuration(isoTime: string) : number {
    if (isoTime === '00:00') {
      return 0;
    } else {
      if (isoTime.split(":").length !== 2) {
      const asDate = new Date(isoTime);
      const fullHours = getHours(asDate);
      const minutes = getMinutes(asDate);
      const retVal = fullHours + minutes / 60;
      return retVal;
      } else {
        const fullHours = Number( isoTime.split(":")[0]) ;
        const minutes =Number( isoTime.split(":")[1]);
        const retVal = fullHours + minutes / 60;
        return retVal;
      }
    }
  }

  patchLineItemToFormGroup(lineItem: LineItem, form: UntypedFormGroup) : void {
    console.log(lineItem);
      form.patchValue({title: lineItem.title, price: lineItem.pricePerItem, box_price_formatted: this.getCurrency(lineItem.pricePerItem),
        description: lineItem.description, durationHours: lineItem.expectedDurationHours/ lineItem.quantity, internalNotes: lineItem.internalNotes,
        singleQuantityDurationAsTime: this.getTimeFromHourDuration((lineItem.expectedDurationHoursSingleQuantity%12)/ lineItem.quantity),
        daysLineItemDuration: Math.floor(lineItem.expectedDurationHours/12),
        totalExpectedDurationString: this.getTotalExpectedDurationString(lineItem.quantity, lineItem.expectedDurationHours/ lineItem.quantity),
        quantity: lineItem.quantity,
        totalPrice :this.getCurrency(lineItem.pricePerItem*lineItem.quantity),
        requiredSkills: lineItem.requiredSkills === undefined || lineItem.requiredSkills === null ? [] : lineItem.requiredSkills});
  }

  getCurrency(amount: number) {
    return this.currencyPipe.transform(amount, 'USD', true, '1.2-2');
  }

  getTimeFromHourDuration(hours: number) : string {
    try {
      const retVal =  addHours(startOfDay(new Date()),Math.floor(hours));
      const d = addMinutes(retVal, Math.round((hours-Math.floor(hours)) * 60 ));
      return formatISO(d);
    } catch (error) {
      throw new Error(`Failed to convert hours to time: ${hours}`);
    }

  }

  getTotalExpectedDurationString(quantity: number, hoursPer: number) {
    let totalHours = quantity * hoursPer;
    const fullHours = Math.floor(totalHours);
    totalHours = totalHours - fullHours;
    return `${fullHours}h ${String(Math.round(totalHours * 60 * 100)/100).padStart(2,'0')}m`
  }

  patchFormGroupToLineItem(form: UntypedFormGroup, lineItem: LineItem) {
    lineItem.title = form.get("title").value;
    lineItem.pricePerItem = form.get("price").value;
    lineItem.description = form.get("description").value;
    lineItem.expectedDurationHours = form.get("durationHours").value*form.get("quantity").value;
    lineItem.expectedDurationHoursSingleQuantity = form.get("durationHours").value;
    lineItem.internalNotes = form.get("internalNotes").value;
    lineItem.quantity = form.get("quantity").value;
  }

  newLineItemFromPrototype(prototype: LineItem) : Observable<LineItem> {
    const retVal = new LineItem(prototype);
    retVal.createdAt = new Date();
    retVal.lineItemPrototypeDocId = prototype.DocId();
    retVal.originatingEmployeeDocId = this.authenticationService.activelyLoggedInEmployeeDocId;
    return this.lineItemService.retrieveDocId(retVal).pipe(
      map(() => retVal)
    );
  }

  newLineItemFromFormAndPrototypeLineItem(form: UntypedFormGroup, lineItem: LineItem) : Observable<LineItem> {
    const retVal = new LineItem(lineItem);
    retVal.title = form.get("title").value;
    retVal.pricePerItem = form.get("price").value;
    retVal.description = form.get("description").value;
    retVal.expectedDurationHours = form.get("durationHours").value*form.get("quantity").value;
    retVal.expectedDurationHoursSingleQuantity = form.get("durationHours").value;
    retVal.internalNotes = form.get("internalNotes").value;
    retVal.quantity = form.get("quantity").value;
    retVal.requiredSkills = form.get("requiredSkills").value === null ? [] : form.get("requiredSkills").value;
    retVal.createdAt = new Date();
    retVal.originatingEmployeeDocId = this.authenticationService.activelyLoggedInEmployeeDocId;

      if (lineItem.lineItemPrototypeDocId === undefined) {
        retVal.lineItemPrototypeDocId = this.settingsService.getValue("customLineItemPrototypeDocId");
      } else {
        // If line item has no protototype, it is a top level line item so it is the prototype.
        if (lineItem.lineItemPrototypeDocId === null) {
          retVal.lineItemPrototypeDocId = lineItem.DocId();
        }
      }
    return this.lineItemService.retrieveDocId(retVal).pipe(
      map(() => retVal)
    );
  }

}
