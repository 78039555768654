import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { format } from 'date-fns';
import { CustomerWithRoleOnJobAndPrimaryDesignation, Job } from '../../../../../common/src/data/dao/job';

@Component({
  selector: 'app-resend-customer-communication-modal',
  templateUrl: './resend-customer-communication-modal.component.html',
  styleUrls: ['./resend-customer-communication-modal.component.scss']
})
export class ResendCustomerCommunicationModalComponent implements OnInit {

  siteVisitDocId: string;
  job: Job;
  customersWithRolesAndPrimaryDesignation: CustomerWithRoleOnJobAndPrimaryDesignation[];
  previousArrivalWindowStart: Date;
  previousArrivalWindowEnd: Date;
  currentArrivalWindowStart: Date;
  currentArrivalWindowEnd: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ResendCustomerCommunicationModalComponent>) {
    this.job = data.job;
    this.siteVisitDocId = data.siteVisitDocId;
    this.customersWithRolesAndPrimaryDesignation = this.job.retrieveCustomersWithRolesAndPrimaryDesignations();
    this.previousArrivalWindowEnd = data.previousArrivalWindowEnd;
    this.previousArrivalWindowStart = data.previousArrivalWindowStart;
    this.currentArrivalWindowEnd = data.currentArrivalWindowEnd;
    this.currentArrivalWindowStart = data.currentArrivalWindowStart;
   }

   Resubmit() {
    this.dialogRef.close({siteVisitDocId: this.siteVisitDocId, jobDocId: this.job.DocId()});
   }

   Exit () {
     this.dialogRef.close(undefined);
   }

  arrivalWindowDateFormatStart(date: Date) {

    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    return date.toLocaleDateString('en-US', (options as Intl.DateTimeFormatOptions));
  }

  arrivalWindowDateFormatEnd(date: Date) {

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return date.toLocaleTimeString('en-US', (options as Intl.DateTimeFormatOptions));
  }


  ngOnInit(): void {
  }

}
