import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class EmployeeAvailability extends RetrieveFirestorePropertiesBase<EmployeeAvailability>  {

    // For default entries for employee (present in employee object), date is just used for day of the week.
    // For over-rides, it would be the particular day in question.
    date: Date;
    workStartTime: Date;
    workEndTime: Date;
    activeWorkDay: boolean;
    employeeDocId: string;
    defaultAvailability: boolean = true;
    note: string = "";
    active: boolean = false;

    get startEndTimesValidate() { return this.workEndTime.getTime() > this.workStartTime.getTime();}

  public constructor(init?: Partial<EmployeeAvailability>) {
    super();
    Object.assign(this, init);
  }

}
