import { Injectable } from '@angular/core'
import { FirestoreDiffService } from './firestore-diff.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { EmployeeLocation } from '../dao/employee-location';
import { AuthenticationService } from '../../util/authentication.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLocationService extends DatabaseStoreService<EmployeeLocation> {

  constructor( fs: EmployeeLocationFirestoreService, store: EmployeeLocationStoreService, authenticationService: AuthenticationService) {
    super(fs, store, authenticationService);
  }

}

@Injectable({
  providedIn: 'root'
})
export class EmployeeLocationStoreService extends StateChangeStoreService<EmployeeLocation> {
  protected store = "EmployeeLocation-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, EmployeeLocation>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class EmployeeLocationFirestoreService extends FirestoreBackend<EmployeeLocation> {

 protected basePath = "EmployeeLocation";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): EmployeeLocation {
return new EmployeeLocation(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new EmployeeLocation(), authService);
 }
}
