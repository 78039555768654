import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitewideSearchBarComponent } from './sitewide-search-bar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule

  ],
  exports: [SitewideSearchBarComponent],
  declarations: [SitewideSearchBarComponent],

})

export class SiteWideSearchBarModule { }
