import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { EmployeeRole } from "./employee-role";

export class SkillSet extends RetrieveFirestorePropertiesBase<SkillSet>  {

name: string = "";
description: string = "";
active: boolean = true;
applicibleEmployeeRoles: EmployeeRole[] = [];
applicableEmployeeRoleDocIds: string[] = [];

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["applicibleEmployeeRoles"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["applicibleEmployeeRoles"]);
static _firestoreCompositionMemberNames = ["applicibleEmployeeRoles"];
static _firestoreCompositionalDiffMemberNames: string[] = ["applicibleEmployeeRoles"];

retrieveFirestoreIgnoredMemberNames() : string[] { return SkillSet._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return SkillSet._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return SkillSet._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return SkillSet._firestoreCompositionMemberNames;}

public constructor(init?: Partial<SkillSet>) {
super();
Object.assign(this, init);
}
}
