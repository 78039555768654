import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineItemDisplayComponent } from './line-item-display.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule } from '@ionic/angular';
import { DiscountCreationIonicModule } from '../../discount/discount-creation-ionic';
import { LineItemCreationModalModule } from '../line-item-creation/line-item-creation-modal/public_api';
import { LineItemCreationIonicModule } from '../line-item-creation/line-item-creation-ionic';
import { MatDialogSingleFieldModalModule } from '../../utility/mat-dialog-single-field-modal';
import { SiteVisitRescheduleModalModule } from '../../jobs/site-visit-reschedule-modal';
import { DiscountCreationModalModule } from '../../discount/discount-creation-modal';

@NgModule({
  declarations: [LineItemDisplayComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    IonicModule,
    DiscountCreationIonicModule,
    LineItemCreationModalModule,
    LineItemCreationIonicModule,
    MatDialogSingleFieldModalModule,
    SiteVisitRescheduleModalModule,
    DiscountCreationModalModule,

  ],
  exports: [LineItemDisplayComponent],
})
export class LineItemDisplayModule { }
