<!-- <div class="header-style">Job Details</div> -->
<form>
<div class="section-content" [formGroup]="form">
<!--START OF JOB TYPE-->

<!-- Job Status -->
<div class="label-style remove-from-add-job-board">Job Status</div>
<div style="background-color: transparent;" class="remove-from-add-job-board status-styling">
  <div style="font-size: 19px; color: #084f7e; font-weight: bold;">{{activeJob.jobStatus}}</div>
</div>
<!-- End of Job Status -->

<!-- Payment Status -->
<div class="label-style remove-from-add-job-board column-position">Job Payment</div>
<div style="background-color: transparent;" class="remove-from-add-job-board status-styling">
  <div style="font-size: 19px; color: #084f7e; font-weight: bold;">{{activeJob.paymentStatus}}</div>
</div>
<!-- End of Payment Status -->


<!-- Originating Office -->
<div class="label-style">Job Location</div>
<div class="medium-field">
  <mat-form-field appearance="outline" class="form-align">
        <mat-select formControlName="locationAssignedWork" placeholder="Job Location" [compareWith]="compareWithDocIds" #jobTypeFormField>
        <mat-option *ngFor="let loc of employeeLocations.loadAll$() | async" [value]="loc" >
          {{loc.name}}
        </mat-option>
      </mat-select>
      <mat-error>Job Must be assigned a location</mat-error>
    </mat-form-field>
</div>
<!-- End Originating Office -->

<div class="label-style">Job Type</div>
  <div class="medium-field">
    <mat-form-field appearance="outline" class="form-align">
          <mat-select formControlName="jobType" placeholder="Job Type" [compareWith]="compareJobTypes" #jobTypeFormField>
          <mat-option *ngFor="let jobType of jobTypes" [value]="jobType" >
            {{jobType.name}}
          </mat-option>
        </mat-select>
        <mat-error>Job type must be selected</mat-error>
      </mat-form-field>
  </div>

<!--END OF JOB TYPE-->

<!--START OF JOB PRIORITY-->
<div class="label-style">Job Priority</div>
<div class="medium-field">
  <mat-form-field appearance="outline" class="form-align">
        <mat-select formControlName="jobPriority" placeholder="Job Priority" [compareWith]="compareCommonServiceProviderSettings" >
        <mat-option *ngFor="let jobPriority of jobPriorities" [value]="jobPriority" >
          {{jobPriority.name}}
        </mat-option>
      </mat-select>
      <mat-error>Job priority must be selected</mat-error>
    </mat-form-field>
</div>

<!--END OF JOB PRIORITY-->

<!--START OF EXPECTED DURATION (SUM OF LINE ITEM TIME)-->

  <!-- <div class="label-style">Job Duration</div>
  <div style="width:240px;" fxLayout="row">
      <mat-form-field appearance="outline" style="width:90px; padding-right: 20px">
        <input matInput placeholder="Hours" disabled [value]="getHours(form.value['jobDurationHours'])">
      </mat-form-field>
      <mat-form-field appearance="outline" style="width:90px;">
        <input matInput placeholder="Minutes" disabled [value]="getMinutes(form.value['jobDurationHours'])">
      </mat-form-field>
  </div> -->

<!--END OF DURATION-->

<!--START OF SCHEDULED AND SPENT DURATION (SUM OF SITE VISITS PAST AND FUTURE)-->
<ng-container *ngIf="form.value['jobDurationActualAndScheduledHours'] !== undefined">
  <div class="label-style">Estimated duration</div>
  <div style="width:240px;" fxLayout="row">
    <mat-form-field appearance="outline" style="width:90px; padding-right: 20px">
      <input matInput placeholder="Hours" disabled [value]="getHours(form.value['estimatedJobDurationHours'])">
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:90px;">
      <input matInput placeholder="Minutes" disabled [value]="getMinutes(form.value['estimatedJobDurationHours'])">
    </mat-form-field>
  </div>
  <div class="label-style">Time spent on site to date:</div>
  <div style="width:240px;" fxLayout="row">
    <mat-form-field appearance="outline" style="width:90px; padding-right: 20px">
      <input matInput placeholder="Hours" disabled [value]="getHours(form.value['timeSpentOnJobToDateHours'])">
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:90px;">
      <input matInput placeholder="Minutes" disabled [value]="getMinutes(form.value['timeSpentOnJobToDateHours'])">
    </mat-form-field>
  </div>
  <div class="label-style">Remaining Scheduled Time:</div>
  <div style="width:240px;" fxLayout="row">
    <mat-form-field appearance="outline" style="width:90px; padding-right: 20px">
      <input matInput placeholder="Hours" disabled [value]="getHours(form.value['remainingTimeScheduledForJobHours'])">
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:90px;">
      <input matInput placeholder="Minutes" disabled [value]="getMinutes(form.value['remainingTimeScheduledForJobHours'])">
    </mat-form-field>
  </div>
</ng-container>
<!--END OF SCHEDULED AND SPENT DURATION (SUM OF SITE VISITS PAST AND FUTURE)-->

<!--START JOB NOTES-->

  <div class="label-top job-notes-job-page-label-positioning">Job Notes</div>
  <div style="width:450px;" class="field-stretch job-notes-job-page-textarea-positioning">
    <mat-form-field appearance="outline" style="width:100%; min-height: 80px;" >
      <textarea [cdkTextareaAutosize] [name]="form.get('noAutoFill').value" autofill="off" autocomplete="new-password" matInput placeholder="Job Notes" formControlName="notes"></textarea>
    </mat-form-field>
  </div>

<!--END JOB NOTES-->

<!--START JOB TAGS-->
<div class="label-top job-tags-jobs-page-label-positioning">Job Tags</div>
<app-custom-tags class="form-align field-stretch"
[assignedTags] = "customTagComponentInputs.assignedTags"
        [existingTags] = "customTagComponentInputs.existingTags | async"
        [existingTagsUpdated] = "customTagComponentInputs.existingTagsUpdated"
        [disableInput] = "customTagComponentInputs.disableInput"
        (tagSelectedForRemoval) = "customTagComponentOutputs.tagSelectedForRemoval.next($event)"
        (tagSelectedForAddition) = "customTagComponentOutputs.tagSelectedForAddition.next($event)"
        (newlyCreatedTagForAdditon) = "customTagComponentOutputs.newlyCreatedTagForAdditon.next($event)">
</app-custom-tags>
<!--END JOB TAGS-->
</div>
</form>
