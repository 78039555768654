import { BryntumEvent } from './bryntum-event';
import { compareAsc, differenceInMilliseconds, format, addMilliseconds, addMinutes, addHours } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import { EmployeeAvailabilityService } from '../data/dao-services/employee-availability.service';

export class BryntumResource {
    id: string;
    name: string;
    role: string;

    public constructor(init?: Partial<BryntumResource>) {
      Object.assign(this, init);
    }
}

export class BryntumResourceDateSummation extends BryntumResource {

      public static verticalDate = startOfDay(new Date());
      actualResourceId: string = undefined;
      colorAssociatedWithDateFullness = 'green';
      fullnessPercent = 0;
      resourceDate: Date = undefined;
      displayDate: string = undefined;
      timeBookedOnDate: Date = BryntumResourceDateSummation.verticalDate;
      timeBookedOnDateDisplay = '0H, 00M';
      commuteBookedOnDate: Date = BryntumResourceDateSummation.verticalDate;
      commuteBookedOnDateDisplay = '0H, 00M';
      displayAttributes = '';
      siteVisitBooking = undefined;
      employeeCreationDate: Date;
      employeeWorkStartTime : Date;
      employeeWorkEndTime : Date;

      public constructor(init?: Partial<BryntumResourceDateSummation>) {
        super(init);
        Object.assign(this, init);

      }

      public static allEqualForBryntumDisplay(a: BryntumResourceDateSummation[], b: BryntumResourceDateSummation[], log: boolean = false) : boolean
  {
    if (a.length !== b.length) {
      return false;
    } else {
      let index = 0;
      for (const event of a) {
        const singleEqual = this.equalForBryntumDisplay(event, b[index], log);
        if (!singleEqual) {
          return false;
        }
        index++;
      }
    }
    return true;
  }

  static listDatesToCompareForEquality = ["resourceDate","timeBookedOnDate","employeeWorkStartTime","employeeWorkEndTime","commuteBookedOnDate"];

  public static equalForBryntumDisplay(a: object, b: object, log : boolean = false): boolean {
    const aa = new BryntumResourceDateSummation();
    const bb = new BryntumResourceDateSummation();

    for (var prop in aa) {
      if (Object.prototype.hasOwnProperty.call(aa, prop)) {
          aa[prop] = a[prop];
      }
    }
    for (var prop in bb) {
      if (Object.prototype.hasOwnProperty.call(bb, prop)) {
          bb[prop] = b[prop];
      }
    }

    // all date properties need checked seperately.
    for (const aDate in BryntumResourceDateSummation.listDatesToCompareForEquality)
      {
        if (aa[aDate] !== bb[aDate] && (aa[aDate] === undefined || bb[aDate] === undefined || aa[aDate].getTime() !== bb[aDate].getTime())) {
          if (log) {
            console.log(aDate);
            console.log(aa[aDate]);
          console.log(bb[aDate]);
          }
          return false;
        }
        delete aa[aDate];
        delete bb[aDate];
      }
      const retVal = aa.displayAttributes === bb.displayAttributes && aa.fullnessPercent === bb.fullnessPercent;
      if (retVal === false){

        // if (log) {
        // console.log(aa);
        // console.log(bb);
        // }
      }
      return retVal;

  }

      static mapResourceSummaryInformation( resource: BryntumResource, bryntumEvents: BryntumEvent[], employeeDocId: string, actualDate: Date,
         employeeAvailibilityService: EmployeeAvailabilityService):
        BryntumResourceDateSummation {

        const retVal: BryntumResourceDateSummation = new BryntumResourceDateSummation(resource);
        retVal.timeBookedOnDate = BryntumResourceDateSummation.verticalDate;
        retVal.commuteBookedOnDate = BryntumResourceDateSummation.verticalDate;

        if (bryntumEvents) {
          bryntumEvents.forEach( element => {
            retVal.timeBookedOnDate =
            addMilliseconds(retVal.timeBookedOnDate, element.actualEndDate.getTime() - element.actualStartDate.getTime());
            if (element.commuteToSiteIncludedInWorkDay) {
              retVal.timeBookedOnDate = addMinutes(retVal.timeBookedOnDate, element.commuteMinutesToSite);
              retVal.commuteBookedOnDate = addMinutes(retVal.commuteBookedOnDate, element.commuteMinutesToSite);
            }
            if (element.commuteSiteToShopIncludedInWorkDay) {
              retVal.timeBookedOnDate = addMinutes(retVal.timeBookedOnDate, element.commuteMinutesSiteToShop);
              retVal.commuteBookedOnDate = addMinutes(retVal.commuteBookedOnDate, element.commuteMinutesSiteToShop);
            }
            retVal.timeBookedOnDateDisplay = `${format(retVal.timeBookedOnDate, 'H')}H, ${format(retVal.timeBookedOnDate, 'mm')}M`;
            retVal.commuteBookedOnDateDisplay = `${format(retVal.commuteBookedOnDate, 'H')}H, ${format(retVal.commuteBookedOnDate, 'mm')}M`;
          });
        }
        retVal.fullnessPercent = this.calculatePercentOfDayFull(retVal.timeBookedOnDate, employeeDocId, actualDate, employeeAvailibilityService);
        retVal.colorAssociatedWithDateFullness = this.RetrieveColorAssociatedWithResourceTimeFullness(retVal.fullnessPercent);
        return retVal;
      }

      private static RetrieveColorAssociatedWithResourceTimeFullness(percentDayFull: number) {
        if (percentDayFull >= 1 ) {
            return 'black'; }
        if (percentDayFull >= .666 ) {
            return '#dc1616'; }
        if (percentDayFull >= .333 ) {
            return '#ffa62f'; }
        if (percentDayFull >= .001 ) {
            return '#269026'; }
        return '#4b93ca';
        }

      private static calculatePercentOfDayFull(resourceTimeScheduled: Date, employeeDocId: string, actualDate: Date, employeeAvailibilityService: EmployeeAvailabilityService): number {
        // maximum of 8 hour work day.
        const maxToScheduleForDay =  addMinutes(BryntumResourceDateSummation.verticalDate, employeeAvailibilityService.getMinutesWorkingForDate(actualDate, employeeDocId))

        // if there is more scheduled then max allowed, return 1 for complete fulness.
        if (compareAsc(resourceTimeScheduled, maxToScheduleForDay) === 1) {
          return 1;
        }
        // if there is no time scheduled, return 0 for no fulness.
        if (compareAsc(resourceTimeScheduled, this.verticalDate) === 0) {
          return 0;
        }

        return differenceInMilliseconds(this.verticalDate, resourceTimeScheduled) /
          differenceInMilliseconds(this.verticalDate, maxToScheduleForDay);
      }
}
