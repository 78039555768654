<div  class="week-picker" fxLayout="row" style="padding-right:10px;">


    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value={{viewWindow}}>
      <mat-button-toggle value="Day" [disabled]="ViewModelDisabled" (click)="emitSchedulerViewWindow('Day')">Day</mat-button-toggle>
      <mat-button-toggle value="Week" [disabled]="ViewModelDisabled" (click)="emitSchedulerViewWindow('Week')">Week</mat-button-toggle>
      <mat-button-toggle value="WorkDay" [disabled]="ViewModelDisabled" (click)="emitSchedulerViewWindow('WorkDay')">Work Days</mat-button-toggle>
    </mat-button-toggle-group>


    <mat-form-field appearance="outline" style="width: 60px; padding: 0 20px; top: 1px;">
      <input
      [name]="ElementNameForId('905383')" autofill='off' autocomplete='new-password' data-lpignore='true'
      [disabled]="ViewModelDisabled" matInput type="number" maxlength=2 min=1 max=99 value= {{numberWorkDaysDisplayed}}
      (change)="updateNumberOfWorkDaysToDisplay($event)"
      >
    </mat-form-field>

  </div>


<!--

<mat-form-field appearance="outline" style="width: 130px;">
      <input type="number" matInput [formControl]="siteVisitLengthFormControl" [errorStateMatcher]="matcher">
</mat-form-field>


<mat-form-field appearance="outline" style="width: 30px;">
  <input [disabled]="ViewModelDisabled" type = number class="work-day-text" maxlength=2 min=0 max = 99 style="resize:none;border-radius:4px;min-width:30px;" value= {{numberWorkDaysDisplayed}} (change)="updateNumberWorkdaysToDisplay.emit($event.target.value)">
  </mat-form-field>



-->
