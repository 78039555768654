import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { googleGeocodeResult } from 'web-app/src/app/google-geocode.service';
import { Address } from '../../../../../common/src/data/dao/address';
import { CustomerSearchService } from '../../../../../common/src/data/services/customer-search.service';
import { searchSource } from '../../../../../common/src/data/services/search.service';

@Component({
  selector: 'app-resolve-dirty-address',
  templateUrl: './resolve-dirty-address.component.html',
  styleUrls: ['./resolve-dirty-address.component.scss']
})
export class ResolveDirtyAddressComponent implements OnInit, OnDestroy {

  destroyCompoenent$: Subject<any> = new Subject();

  searchAddress$ = new Subject<string>();
  addressesFound$: Subject<Address[]> = new Subject<Address[]>();
  //We must maintain active search term to prevent later emissions from previously submitted searches from being processed.
  activeSearchTerm = "";
  activeSearch: boolean = false;
  displayNoResultsFound: boolean;

  customerSuppliedAddress: string;

  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ResolveDirtyAddressComponent>,
  private fb: UntypedFormBuilder, private dialog: MatDialog, private customerSearchService: CustomerSearchService) {
    this.form = this.createForm(data.googleGeocodeResult, data.address);
  }

  ngOnDestroy(): void {
    this.destroyCompoenent$.next(null);
    this.destroyCompoenent$.complete();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.valid) {
      const address: Address = this.form.value["serviceAddress"];
      address.unit = this.form.value["serviceAddressUnit"];
      this.dialogRef.close(address);
    } else {
      this.form.markAllAsTouched();
    }
  }

  serviceAddressSearchKeyUp($event: KeyboardEvent) {
    this.searchAddress$.next(($event.target as HTMLInputElement).value);
  }

  createForm(result: googleGeocodeResult, customerSuppliedAddress: string) : UntypedFormGroup {
    const randomElementName = (Math.random().toString(36) + '00000000000000000').slice(2, 14);

    return this.fb.group ({
      noAutoFill: [{value: randomElementName, disabled: true}],
      serviceAddressDisplay:["", [Validators.required,]],
      serviceAddress :["", [Validators.required]],
      serviceAddressUnit:["",],
      googleGeocoderError: result,
      customerSuppliedAddress: [{value: customerSuppliedAddress, disabled: true}]
    });
  }

  billingAddressOptionSelected(e: MatAutocompleteSelectedEvent) {
    this.form.patchValue({ serviceAddressDisplay : e.option.value.formattedAddress(), serviceAddress: e.option.value, serviceAddressUnit: "", googleGeocoderError: null});
  }

  ngOnInit(): void {

    this.searchAddress$.pipe(
      distinctUntilChanged(),
      tap(() => this.displayNoResultsFound = false),
      debounceTime(300),
      filter(x => x.length > 2),
      tap(x => {
        if (this.form.controls["serviceAddress"].value === "" || this.form.controls["serviceAddress"].value.formattedAddress() !== x) {
        this.activeSearch=true;
        this.activeSearchTerm = x;
        this.customerSearchService.addSearch({search: x, componentSource: "resolveDirtyAddressSearch", searchSources: [searchSource.GooglePlaceAutoComplete]});
        }
      }),
      takeUntil(this.destroyCompoenent$)
      ).subscribe();

      this.searchAddress$.pipe(
        filter( x => x.length <= 2),
        tap(() =>  {
          this.activeSearchTerm = "";
          this.activeSearch=false;
        }),
        map(() => ([])),
        takeUntil(this.destroyCompoenent$)
      ).subscribe(this.addressesFound$);


      this.customerSearchService.searchResults$.pipe(
        filter(x => x.searchInput.componentSource === "resolveDirtyAddressSearch" && x.searchInput.search === this.activeSearchTerm),
        tap(x => {
          console.log(x);
          this.activeSearch=false;
          if (x.results.length === 0) {
            this.displayNoResultsFound = true;
          }
        }),
        // Since looking to add address to existing customer, remove results where customer is populated, and map to just address.
        map(x => x.results.filter(result => result.customer === null && result.address !== null).map(z => z.address)),
        tap(q => {
          // If there are no results, more then 1 result, or a result that doesn't match already selected value, patch in
          // blank value for billing address.
          if (q.length !== 1 || this.form.controls["serviceAddress"].value === "" || q[0].formattedAddress() !== this.form.controls["serviceAddress"].value.formattedAddress()) {
            this.form.patchValue({ billingAddress: ""});
          }
        }),
        takeUntil(this.destroyCompoenent$),
        tap(z => console.log(z))
      ).subscribe(this.addressesFound$);
  }

  public displayAddressSelected(value) {
    return  (value !== undefined && value !== null && value !== "") ? value.formattedAddress() : '';
  }

}

