import { Injectable } from '@angular/core';
;
import { JobDurationDelta } from '../dao/job-duration-delta';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { AuthenticationService } from '../../util/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class JobDurationDeltaService extends DatabaseStoreService<JobDurationDelta> {

  constructor( fs: JobDurationDeltaFirestoreService, authenticationService: AuthenticationService, store: JobDurationDeltaStoreService) {
    super(fs, store, authenticationService);
  }
}


@Injectable({
  providedIn: 'root'
})
export class JobDurationDeltaStoreService extends StateChangeStoreService<JobDurationDelta> {
  protected store = "job-duration-delta-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, JobDurationDelta>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class JobDurationDeltaFirestoreService extends FirestoreBackend<JobDurationDelta> {

  protected basePath = "job_duration_delta";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): JobDurationDelta {
    return new JobDurationDelta(obj);
  }

  constructor(protected authService: AuthenticationService ) {
    super(new JobDurationDelta(), authService);
  }
}
