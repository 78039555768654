import { firestore } from "firebase-admin";
import { Customer } from "./customer";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Attachment } from "./attachment";
import { CustomerCommunicationScheduling } from "./customer-communication-scheduling";
import { CustomerCommunicationCategory, CustomerCommunicationTemplate, CustomerCommunicationType } from "./customer-communication-template";


export interface smsDeliveryInfo {
  deliveredAt: firestore.Timestamp;
  errorCode: string;
  errorMessage: string;
  state: string;
  sentTo: string;
  updatedAt: firestore.Timestamp;
}

export interface emailDeliveryInfo {
  attempts: string;
  deliveredAt: firestore.Timestamp;
  acceptedAddresses: string[];
  pendingAddresses: string[];
  rejectedAddresses: string[];
  response: string;
  state: string;
  updatedAt: firestore.Timestamp;
}

export enum CustomerCommuncationStatus {
  SCHEDULED = "SCHEDULED",
  CANCELED = "CANCELED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  SENT= "SENT",
  ERROR = "ERROR",
  UNKNOWN = "UNKNOWN",
}

//Members of class are intentionally redundant w/ composite members b/c root firestore document is also used
//by server side code.
export class CustomerCommunication extends RetrieveFirestorePropertiesBase<CustomerCommunication>  {

body: string;
subject: string;
customerCommunicationType: CustomerCommunicationType;
to: string[] = [];
replyTo: string;
bcc: string = "";
source: string = "";

uniquePerCommunicationRequestAcrossChannels: string | null = null;

attachments: Attachment[] = [];
attachmentDocIds: string[] = [];

customerCommunicationTemplateDocId: string;
customerCommunicationTemplate: CustomerCommunicationTemplate;

customerCommuncationSchedulingDocId: string;
customerCommunicationScheduling: CustomerCommunicationScheduling;

sendFollowUpMessaging: boolean = false;
messageSequenceNumber: number = 1;
numberOfAdditionalMessagesToSend: number = 0;
delayDaysToSendFollowUpMessage: number = 7;
followUpMessageTimeSend: Date = null;
customerCommunicationStatus: CustomerCommuncationStatus = CustomerCommuncationStatus.SCHEDULED;
customerCommunicationCatagory: CustomerCommunicationCategory;
scheduledDeliveryDate: Date;
actualDeliveryDate: Date = null;

workflowDocId: string = null;
jobDocId: string = null;
siteVisitDocId: string = null;
customerDocId: string = null;
invoiceDocId: string = null;
estimateDocId: string = null;
primaryCustomer: Customer;

workflowUpdatedDate: Date;

generatePdfFromWorkflow: boolean = false;
pdfGeneratedFromWorkflow: boolean = false;
workflowVersionNumber: number = 1;

deliveryInfo: smsDeliveryInfo | emailDeliveryInfo = null;
url: string = null;
stateChangeNotes: string = "";

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["primaryCustomer","attachments","customerCommunicationTemplate","customerCommunicationScheduling"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["attachmentDocIds", "customerCommunicationTemplateDocId",
  "customerCommunicationSchedulingDocId","customerDocId","customerCommunicationTemplate","customerCommunicationScheduling"]);
static _firestoreCompositionMemberNames = ["primaryCustomer","customerCommunicationTemplate","customerCommunicationScheduling","attachments"];
static _firestoreCompositionalDiffMemberNames: string[] = ["primaryCustomer","attachments"];

retrieveFirestoreIgnoredMemberNames() : string[] { return CustomerCommunication._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return CustomerCommunication._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return CustomerCommunication._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return CustomerCommunication._firestoreCompositionMemberNames;}

public constructor(init?: Partial<CustomerCommunication>) {
super();
Object.assign(this, init);
}

static CommunicationHasBeenSentToCustomer(status: CustomerCommuncationStatus) {
  return status === CustomerCommuncationStatus.PENDING || status === CustomerCommuncationStatus.PROCESSING ||
  status === CustomerCommuncationStatus.SUCCESS || status === CustomerCommuncationStatus.ERROR || status === CustomerCommuncationStatus.SENT;
}

}
