<ion-app>
<ion-header>
  <ion-toolbar>
    <ion-title>
      Flue One Pictures
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Flue One Pictures</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-item>
    <ion-label>Pictures:</ion-label>
    <ion-badge color={{pictureCountColor|async}} slot="end">{{pictureCountString|async}}</ion-badge>
  </ion-item>
  <ion-grid>
    <ion-row>
    <ion-col  size="6" *ngFor="let photo of this.photosAssignedContainer$ | async; index as position">
      <ion-img [src]="photo.localWebviewPath" (click)="showActionSheet(photo,  position)"></ion-img>
    </ion-col>
    </ion-row>
  </ion-grid>
  <ion-fab vertical="bottom" horizontal="start" slot="fixed">
    <ion-fab-button (click)="addPictureFromCamera()">
      <ion-icon name="camera"></ion-icon>
      <ion-title>Cam</ion-title>
    </ion-fab-button>
  </ion-fab>
    <ion-fab *ngIf="!IsWeb" vertical="bottom" horizontal="center" slot="fixed">
      <ion-fab-button (click)="addPictureFromGallery()">
        <ion-icon name="image-outline"></ion-icon>
        <ion-title>Gallery</ion-title>
      </ion-fab-button>
    </ion-fab>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="exit()">
      <ion-icon name="arrow-back"></ion-icon>
      <ion-title>Done</ion-title>
    </ion-fab-button>
  </ion-fab>
</ion-content>
</ion-app>
