import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { addHours, addMinutes, startOfDay, subHours } from 'date-fns';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { LineItem } from '../../../../../common/src/data/dao/line-item';
import { randomElementName } from '../../../../../common/src/util/util';

export enum SiteVisitRescheduleReasons {
  NEED_PART = "Need Part",
  RAN_OUT_OF_TIME = "Ran Out of Time",
  WEATHER = "Weather",
  OTHER = "Other",
}


@Component({
  selector: 'app-site-visit-reschedule-modal',
  templateUrl: './site-visit-reschedule-modal.component.html',
  styleUrls: ['./site-visit-reschedule-modal.component.scss']
})

export class SiteVisitRescheduleModalComponent implements OnInit, OnDestroy {

  randomElementName : string = randomElementName();
  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  zeroHoursAsDate = startOfDay(new Date());
  maximumTimeOneLineItem= addMinutes(startOfDay(new Date()),23*60+55);

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  siteVisitRescheduleReasons = [SiteVisitRescheduleReasons.NEED_PART, SiteVisitRescheduleReasons.RAN_OUT_OF_TIME, SiteVisitRescheduleReasons.WEATHER, SiteVisitRescheduleReasons.OTHER];
  form: UntypedFormGroup;
  lineItem: LineItem;
  timeRemaining$: Subject<Date> = new Subject<Date>();
  title:string;
  durationHours: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<SiteVisitRescheduleModalComponent>,) {
    this.lineItem = data.lineItem;
    this.title = data.title;
    if (data.durationHours) {
      this.durationHours = data.durationHours;
    }
  }

  buildFormGroup() {
    this.form = this.fb.group({
      siteVisitRescheduleReason: ["", [Validators.required]],
      notes: [""],
      duration: [this.getDefaultDuration()],
  })}

  getDefaultDuration()  : Date {
    if (this.durationHours) {
      return this.getTimeFromHourDuration(this.durationHours);
    } else {
      if (this.lineItem && this.lineItem.expectedDurationHours !== undefined) {
        const hourPortion = Math.ceil(this.lineItem.expectedDurationHours);
        return this.getTimeFromHourDuration(hourPortion);
      } else {
        return null;
      }
    }
  }

  getTimeFromHourDuration(hours: number) : Date{
    const hoursToAdd = Math.floor(hours);
    let retVal =  addHours(startOfDay(new Date()), hoursToAdd);
    const minutesPortion = (hours - hoursToAdd) * 60;
    retVal = addMinutes(retVal, minutesPortion);
    return retVal;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close({reason: this.form.get("siteVisitRescheduleReason").value, notes: this.form.get("notes").value, duration: this.form.get("duration").value});
    } else {
      this.form.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.buildFormGroup();

    this.timeRemaining$.pipe(
      map(x => {
        let hours = 0;
        hours = x.getHours();
        x = subHours(x,hours);
        hours = Math.round(x.getMinutes()*100/60)/100 + hours;
        return hours;
      }),
      tap(hours => this.form.patchValue({duration:
        this.getTimeFromHourDuration(hours)})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

}
