import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { LineItemControlContext, LineItemControlType } from '../../form-builder/component-models/line-items/line-item-enums';
import { Discount } from '../../../../../common/src/data/dao/discount';
import { LineItem } from '../../../../../common/src/data/dao/line-item';
import { Audience, LineItemRemovalWithReason } from '../line-item-display/line-item-display.component';
import { InvoicePayment } from '../../../../../common/src/data/dao/invoice-payment';
import { Estimate } from '../../../../../common/src/data/dao/estimate';

@Component({
  selector: 'app-line-item-display-modal',
  templateUrl: './line-item-display-modal.component.html',
  styleUrls: ['./line-item-display-modal.component.scss']
})
export class LineItemDisplayModalComponent implements OnInit {

  lineItemControlContext: LineItemControlContext = LineItemControlContext.Website;

  title: string;
  lineItems$ : BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
  discounts$ : BehaviorSubject<Discount[]> = new BehaviorSubject<Discount[]>([]);
  invoicePayments$: BehaviorSubject<InvoicePayment[]> = new BehaviorSubject<InvoicePayment[]>([]);
  lineItemControlType: LineItemControlType;
  addLineItem$: Subject<LineItem>;
  removeLineItem$: Subject<LineItemRemovalWithReason> = new Subject<LineItemRemovalWithReason>();
  editLineItem$: Subject<{old: LineItem, new: LineItem}[]>;
  addDiscount$ : Subject<Discount>;
  removeDiscount$: Subject<Discount>;
  editDiscount$: Subject<{old: Discount, new: Discount}>;
  intendedAudience: Audience;
  completedLineItemOperation$ : ReplaySubject<LineItem> = new ReplaySubject<LineItem>(1);
  estimate: Estimate | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<LineItemDisplayModalComponent>) {
    if (data) {
      this.title = data.title;
      this.lineItemControlType = data.lineItemControlType;
      this.lineItems$ = data.lineItems$;
      this.addLineItem$ = data.addLineItem$;
      this.removeLineItem$ = data.removeLineItem$;
      this.editLineItem$ = data.editLineItem$;
      this.intendedAudience = data.intendedAudience;
      this.discounts$ = data.discounts$;
      this.addDiscount$ = data.addDiscount$;
      this.removeDiscount$ = data.removeDiscount$;
      this.editDiscount$ = data.editDiscount$;
      this.completedLineItemOperation$ = data.completedLineItemOperation$;
      this.invoicePayments$ = data.invoicePayments$ ? data.invoicePayments$ : new BehaviorSubject<InvoicePayment[]>([]);
      this.estimate = data.estimate ? data.estimate : null;
    }
  }

  ngOnInit(): void {
  }



}
