import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '../../../../common/src/data/dao/customer';
import { PhoneMaskDirective } from '../directives/phone-mask.directive';

@Component({
  selector: 'app-customer-display-card',
  template: `
  <div class="customer-card">
    <div class="cust-name" fxLayout="row" fxLayoutAlign=" center">
      <div *ngIf="customerWithRoleAndPrimary.customer">
      <a routerLink="/app-customer-page/{{customerWithRoleAndPrimary.customer.DocId()}}">
        {{customerWithRoleAndPrimary.customer.customerName}}
      </a>
      </div>
    </div>
    <div class="contact-info" fxLayout="row" fxLayoutAlign=" center">
        <mat-icon>phone</mat-icon>
        <div *ngIf="customerWithRoleAndPrimary.customer">
        <div class="phone-text" appPhoneMask>{{formatPhoneNumber(customerWithRoleAndPrimary.customer.primaryPhoneNumber)}}{{formatExtension(customerWithRoleAndPrimary.customer.primaryPhoneNumberExtension)}}</div>
        </div>
    </div>
    <div class="contact-info" fxLayout="row" fxLayoutAlign=" center">
        <mat-icon class="email-icon">email</mat-icon>
        <div *ngIf="customerWithRoleAndPrimary.customer">
        <div class="email-text">
          <a href={{getMailToLink(customerWithRoleAndPrimary.customer.customerEmail)}} target="_blank">
            {{customerWithRoleAndPrimary.customer.customerEmail}}
          </a>
        </div>
        </div>
    </div>
    <div *ngIf="isSiteContact(customerWithRoleAndPrimary)" class="contact-info" fxLayout="row" fxLayoutAlign=" center">
        <mat-icon class="site-contact-icon">home</mat-icon>
        <div class="email-text">Site Contact</div>
    </div>
    <div *ngIf="isBilling(customerWithRoleAndPrimary)" class="contact-info" fxLayout="row" fxLayoutAlign=" center">
        <mat-icon class="billing-contact-icon">attach_money</mat-icon>
        <div class="email-text">Billing Contact</div>
    </div>
    <div *ngIf="customerWithRoleAndPrimary.primary" class="contact-info" fxLayout="row" fxLayoutAlign=" center">
      <mat-icon class="primary-customer-icon">star</mat-icon>
      <div class="email-text">Primary</div>
    </div>
  </div>
  `,
  styleUrls: ['./customer-display-card.component.scss']
})

export class CustomerDisplayCardComponent implements OnInit {

  @Input() customerWithRoleAndPrimary: {customer: Customer, role: string, primary: boolean}

  formatPhoneNumber(i: String) {
    return PhoneMaskDirective.modifyInputToRetrievePhoneNumber(i.replace(/\D/g,''));
  }

  getMailToLink(emailAddr) : string {
    return `mailto:${emailAddr}`;
  }

  isBilling(c: {customer: Customer, role: string, primary: boolean}) {
    return c.role === "All" || c.role === "Billing Contact";
  }

  isSiteContact(c: {customer: Customer, role: string, primary: boolean}) {
    return c.role === "All" || c.role === "Site Visit Contact";
  }

  formatExtension(extension: string) : string {
    if (extension !== "") {
      return ` x${extension}`;
    } else {
      return "";
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
