import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';

export enum JobDurationDeltaModificationType {
  // Needed, but not from scheduler.
  SHIFTEDWHENCREATED=0,
  SHIFTEDFROMJOBDETAILPAGE=1,

  // Current
  SITEVISITDELETED=2,
  SITEVISITCREATED=3,
  SITEVISITDURATIONREDUCEDBYDRAG=4,
  SITEVISITDURACTIONINCREASEDBYDRAG=5,
  ADDITIONALSITEVISITPUTONDECK=6,

  // Field Tech
  FIELDTECHTIMEADDITION=7,
  FIELDTECHTIMESUBTRACTION=8,

  // Scheduler
  SITEVISITRESCHEDULED=9,

}

export class JobDurationDelta extends RetrieveFirestorePropertiesBase<JobDurationDelta>  {

  employeeAssigningDocId: string;
  siteVisitDocId?: string | null = null;
  originatingLineItemDocId?: string | null = null;
  deltaHours: number;
  modificationType: JobDurationDeltaModificationType;
  dateModificationOccurred: Date;
  active: boolean = true;

  public constructor(init?: Partial<JobDurationDelta>) {
    super();
    Object.assign(this, init);
  }
}
