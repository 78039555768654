import { Injectable } from '@angular/core';
import {EmployeeService} from '../../../common/src/data/dao-services/employee.service';
import {arrivalWindow, JobService} from '../../../common/src/data/dao-services/job.service';
import {getHours, format, compareAsc, addDays, getDate, addHours, addMinutes,
  getMinutes, startOfDay, subMinutes, differenceInHours, differenceInMinutes} from 'date-fns';
import { BryntumEvent, BryntumAssignment } from '../../../common/src/bryntum/bryntum-event';
import {BryntumResourceDateSummation} from '../../../common/src/bryntum/bryntum-resource';
import {  Observable, of, combineLatest, BehaviorSubject, Subject, merge, race, interval,  zip, pipe, throwError, ReplaySubject, forkJoin} from 'rxjs';
import { Job } from '../../../common/src/data/dao/job';
import {Assignment} from '../../../common/src/data/dao/assignment';
import { AssignmentService } from '../../../common/src/data/dao-services/assignment.service';
import { tap, map,  switchMap, share, distinctUntilChanged,   take, filter, repeat, concatMap, catchError, takeUntil, mergeMap, exhaustMap, finalize, debounce} from 'rxjs/operators';
import { SiteVisit } from '../../../common/src/data/dao/site-visit';
import { SiteVisitService } from '../../../common/src/data/dao-services/site-visit.service';
import  { SchedulingGuidanceService } from './scheduling/scheduling-guidance.service';
import { JobDurationDelta, JobDurationDeltaModificationType } from '../../../common/src/data/dao/job-duration-delta';
import { groupBy } from '../../../common/src/util/util';
import { PhysicalAddressRoutingService } from './physical-address-routing.service';
import { SiteVisitSchedulingService } from './scheduling/site-visit-scheduling.service';
import { ResourceAvailibility, RESOURCE_AVAILIBILITY_CALCULATION_METHOD } from './scheduling/resource-availibility';
import { ResourceAvailibilityService } from './scheduling/resource-availibility.service';
import { SettingsService } from './settings/settings.service';
import { EmployeeAvailabilityService } from '../../../common/src/data/dao-services/employee-availability.service';
import { AuthenticationService } from '../../../common/src/util/authentication.service';
import { CustomerCommunicationManagementService } from './customer-communication/customer-communication-management.service';
import { Employee } from '../../../common/src/data/dao/employee';
import { AddressRoutingService } from '../../../common/src/data/dao-services/address-routing.service';
import {Commute} from '../../../common/src/data/dao/commute';
import { AddressRoutingLocal } from './db';
import {ResourceDayAddressRouting} from '../../../common/src/data/dao/resource-day-address-routing';
import {ResourceDayAddressRoutingService} from '../../../common/src/data/dao-services/resource-day-address-routing.service';
import {ResourceDayService} from '../../../common/src/data/dao-services/resource-day.service';
import {ResourceDay} from '../../../common/src/data/dao/resource-day';
import { Address } from '../../../common/src/data/dao/address';
import { RetrieveFirestoreProperties } from '../../../common/src/data/database-backend/retrieve-firestore-properties';
import {EmployeeGeofence} from '../../../common/src/data/dao/employee-geofence';
import {EmployeeGeofenceService} from '../../../common/src/data/dao-services/employee-geofence.service';
import { AddressService } from '../../../common/src/data/dao-services/address.service';
import { FirestoreBackend } from '../../../common/src/data/database-backend/retrieve-from-firestore';
import { LoggingService } from '../../../common/src/data/logging/logging.service';

function sortSiteVisitsEventsByStartTime(events: SiteVisit[]) {
  return events.sort((a, b) => a.startDate.getTime() < b.startDate.getTime() ? -1 : a.startDate.getTime() >
  b.startDate.getTime() ? 1 : a.docId < b.docId ? -1 : 1);
}

function sortBryntumEventsByStartTime(events: BryntumEvent[]) {
  return events.sort((a, b) => a.startDate.getTime() < b.startDate.getTime() ? -1 : a.startDate.getTime() >
  b.startDate.getTime() ? 1 : a.siteVisitDocId < b.siteVisitDocId ? -1 : 1);
}

class DataNeededForAdditionalSiteVisitModifications {
  job: Job;
  siteVisit: SiteVisit;
  assignedEmployeeDocId: string;
  wb: string;
  newSiteVisitNumber: number;
}

export class ByrntumData {
  assignments: BryntumAssignment[];
  events: BryntumEvent[];
  resources: BryntumResourceDateSummation[];
  availibilityInfo: ResourceAvailibility[];
  changeInHoursScheduledAgainstOnDeckJob : number = 0;

  public constructor(init?: Partial<ByrntumData>)  {
    Object.assign(this, init);
  }

  public static equalSeedBryntumDisplay(prev:ByrntumData, curr:ByrntumData) : boolean {
    const log: boolean = false;
    const eventsEqual = BryntumEvent.allEqualForBryntumDisplay(prev.events.sort((o,n) => o.siteVisitDocId > n.siteVisitDocId ? -1 : 1),
      curr.events.sort((o,n) => o.siteVisitDocId > n.siteVisitDocId ? -1 : 1),log);
    const assignmentsEqual = BryntumAssignment.allEqualForBryntumDisplay(prev.assignments, curr.assignments);
    const resourceInformationEqual = BryntumResourceDateSummation.allEqualForBryntumDisplay(prev.resources, curr.resources,log);
    console.warn(`events: ${eventsEqual}  ass: ${assignmentsEqual}  res: ${resourceInformationEqual} CTS`);
    const retVal = eventsEqual && assignmentsEqual && resourceInformationEqual;
    return retVal;
  }

}

interface FirestoreDataNeededForViewInput {
  startDate: Date,
  endDate: Date,
  employeeIdSubsetToReturn: string[] | null,
  takeUntilGuid: string,
  takeUntilObs$: Observable<any> | null,
}

@Injectable({
  providedIn: 'root'
})

export class VerticalSchedulerService {

  schedulerDestroyed$ = new Subject<null>();
  commuteSliderStringRepresentation: string;
  commuteSliderValue : number;
  commuteSliderUpdate: boolean;

  monitoredDateHigh: Date;
  monitoredDateLow: Date;

  jobs: Job[];
  assignments: Assignment[];
  siteVisits: SiteVisit[];
  _resourceAvailibility: ResourceAvailibility[];
  _resourceAvailibilityDistinctCommute: ResourceAvailibility[] = [];

  activeVerticalSchedulerGuid$ = new ReplaySubject<string>(1);

  assignmentModification$ :BehaviorSubject<boolean> = new BehaviorSubject(false);

  get resourceAvailibility() {
    return this._resourceAvailibility;
  }

  set resourceAvailibility(value) {
    this._resourceAvailibility = value;
    if (value === undefined || value.length === 0 ) {
      this._resourceAvailibilityDistinctCommute = value;
    } else {
    this._resourceAvailibilityDistinctCommute = this.resourceAvailibility.filter(x => x.available)
      .sort((a, b) => a.commuteMinutesDelta < b.commuteMinutesDelta ? -1 : b.commuteMinutesDelta < a.commuteMinutesDelta ? 1 : 0)
      .reduce((unique: ResourceAvailibility[], o) => {
        if (!unique.some(obj => obj.commuteMinutesDelta === o.commuteMinutesDelta)) {
          unique.push(o);
        }
        return unique;
      }, []);
    }
  }

  prospectiveSiteVisits: SiteVisit[] = [];
  prospectiveAssignments: Assignment[] = [];

  bryntumAssignments: BryntumAssignment[];
  eventsVertical: BryntumEvent[];
  resourcesVertical: BryntumResourceDateSummation[];

  assignmentUpdateReadSinceLastEmission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  eventStartTimeUpdateReadSinceLastEmission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  waitingForAssignmentAndEventUpdates$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  fullAssignmentAndEventUpdateProcessed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  updateProcessedWithoutChange$ = new Subject<null>();
  processingChangeToSchedule$ = new ReplaySubject<boolean>(1);
  bryntumChangeAssignment: Subject<any> = new Subject();
  bryntumChangeEvent: Subject<any> = new Subject();
  bryntumRescheduleSiteVisit$: Subject<BryntumEvent> = new Subject();
  bryntumDeleteSiteVisit$: Subject<BryntumEvent> = new Subject();
  bryntumAddSiteVisit$: Subject<{jobDocId: string, employeeDocId: string,  bryntumStartTime: Date, bryntumEndTime: Date,
                                 actualDate: Date, siteVisitDocId: string, minimizeSiteVisitMutationsOnReconciliation: boolean,
                                 explicitlyErrored: boolean}> = new Subject();
  jobsNeedingAssignment$ = new ReplaySubject<Job[]>(1);
  changeInHoursScheduledAgainstOnDeckJob = 0;
  hoursDeltaAgainstOnDeckJob: number;
  viewWindowMovementEnabled$ = new ReplaySubject<boolean>(1);
  siteVisitArrivalWindowUpdated$= new Subject<{siteVisitDocId: string, job: Job, orignalWindow: arrivalWindow, newWindow: arrivalWindow }>();

  manuallyUpdateStartTimeSiteVisit$: Subject<{siteVisit: SiteVisit, bryntumEventRawStartTime: Date}> = new Subject();

  consideringBatchedChange = false;
  loadedEmployeeOriginAndDestinationAddresses$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  siteVisitPreModification : {siteVisit: SiteVisit, assignment: Assignment};
  triggerRerenderingManually$ = new ReplaySubject<null>(1);

  removeCommuteTimeWhenUpdating = true;
  freshDataRetrievedForScheduleView$: Subject<null> = new Subject();
  schedulerViewActive$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  delaySchedulerUpdates$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private replaceViewWindowCache$ = new Subject();

  constructor(private jobService: JobService, private employeeService: EmployeeService, private assignmentService: AssignmentService,
    private siteVisitService: SiteVisitService, private schedulingGuidanceService: SchedulingGuidanceService,
    private physicalAddressRoutingService: PhysicalAddressRoutingService, public siteVisitSchedulingService: SiteVisitSchedulingService,
    private resourceAvailibilityService: ResourceAvailibilityService, private settingsService: SettingsService,
    private employeeAvailabilityService: EmployeeAvailabilityService, private authenticationService: AuthenticationService,
    private customerCommunicationCreationService: CustomerCommunicationManagementService, private addrRoutingService: AddressRoutingService,
    private resourceDayAddressRoutingService: ResourceDayAddressRoutingService, private resourceDayService: ResourceDayService,
    private employeeGeofenceService: EmployeeGeofenceService, private addressService: AddressService, private loggingService: LoggingService )
    {

      combineLatest([this.waitingForAssignmentAndEventUpdates$, this.eventStartTimeUpdateReadSinceLastEmission$, this.assignmentUpdateReadSinceLastEmission$]).pipe(
        // tap(([waitingForAssignmentAndEventUpdates, eventStartTimeUpdateRead, assignmentUpdateRead]) => console.log(`w  ${waitingForAssignmentAndEventUpdates} e  ${eventStartTimeUpdateRead} a   ${assignmentUpdateRead}`)),
        map(([waitingForAssignmentAndEventUpdates, eventStartTimeUpdateRead, assignmentUpdateRead]) => waitingForAssignmentAndEventUpdates && eventStartTimeUpdateRead && assignmentUpdateRead),
        filter(x => x === true),
        tap(() => {
          this.eventStartTimeUpdateReadSinceLastEmission$.next(false);
          this.assignmentUpdateReadSinceLastEmission$.next(false);
          this.fullAssignmentAndEventUpdateProcessed$.next(true)
        }),
        ).subscribe();

      this.processingChangeToSchedule$.next(false);

      // this may need modified to properly handle these addresses updating
      this.physicalAddressRoutingService.addressesToAlwaysConsiderLoaded$.pipe(
        map(() => {
          const startEndObs = [of(null)];
          return startEndObs;
        }),
        mergeMap(startEndObs => combineLatest( [...startEndObs])),
          tap(() => console.error('Mapped addresses for orgin / destinations of techs.')),
          tap(() => this.loadedEmployeeOriginAndDestinationAddresses$.next(true)),
          catchError(err => {
          console.log('Error caught in observable.', err);
          return throwError(err);
          }),
          take(1)
      ).subscribe();

      this.schedulerDestroyed$.pipe(
        tap(() => {
          // this.monitoredDateHigh = undefined;
          // this.monitoredDateLow = undefined;
        })
      ).subscribe();

      const changeAssignmentPipe = this.bryntumChangeAssignment.pipe(
        tap(x => console.warn(x,`BRYNTUM CHANGE ASSIGNMENT`)),
        distinctUntilChanged((o: any, n: any) => o.changes === undefined || n.changes === undefined ||
        (o.changes.resourceId.value === n.changes.resourceId.value &&
        o.changes.resourceId.oldValue === n.changes.resourceId.oldValue && o.record.eventId === n.record.eventId)),
        map(x => {
          const siteVisitDocId = (x.record.eventId as string).replace('prospect', '');
          return {bryntumChangeAssignmentData: x, siteVisitDocId};
        }),
        tap(() => this.processingChangeToSchedule$.next(true)),
        share());

      const existingSiteVisitAssignmentModificationPipe = changeAssignmentPipe.pipe(

        filter(schedulerAssignmentMapping => this.siteVisits.some(x => x.docId === schedulerAssignmentMapping.siteVisitDocId)),
        map(x => x.bryntumChangeAssignmentData),
        );

      const prospectiveSiteVisitAssignmentModificationPipe = changeAssignmentPipe.pipe(
        filter(schedulerAssignmentMapping => !this.siteVisits.some(x => x.docId === schedulerAssignmentMapping.siteVisitDocId)),
        map(x => x.bryntumChangeAssignmentData),
        );

      const changeProspectiveAssignmentOnly = prospectiveSiteVisitAssignmentModificationPipe.pipe(
        filter(() => !this.consideringBatchedChange),
        map(x => this.updateJobWithAssignmentModification(x, null)),
        switchMap(x => this.siteVisitService.retrieveFirestoreBatchString().pipe((
          map(wb => {
            return {batch: wb, val:x};
          })
        ))),
        switchMap(x => this.AssignSiteVisitToEmployee(x.val.currentSiteVisit.jobDocId, x.val.newAssignment.employeeDocId,
          x.val.currentSiteVisit, x.val.currentSiteVisit.startDate, x.batch, x.val.oldAssignment, x.val.newSiteVisitNumber )));

      const changeExistingAssignmentOnly = existingSiteVisitAssignmentModificationPipe.pipe(
        filter(() => !this.consideringBatchedChange),
        switchMap(x => this.modifyAssignment(x)));

      const changeEventPipe =  this.bryntumChangeEvent.pipe(
        filter(x => (Object.keys(x.changes).filter(z => !["cls", "readOnly", "commuteEndDate", "returnCommuteStartDate","removeCommuteTimeWhenUpdating"]
          .some(y => y === z))).length > 0),
          distinctUntilChanged(),
          tap(x => console.warn(x,`BRYNTUM CHANGE EVENT`)),
          tap(eventArgs => this.removeCommuteTimeWhenUpdating = eventArgs.record.removeCommuteTimeWhenUpdating),
          tap(() => this.processingChangeToSchedule$.next(true)),
          tap(() => this.consideringBatchedChange = true),
          share()
          );

      const existingSiteVisitModification = changeEventPipe.pipe(
        filter(schedulerEvent => this.eventsVertical.some(x => x.id === schedulerEvent.record.id)),
      );

      const prospectiveSiteVisitModification = changeEventPipe.pipe(
        filter(schedulerEvent => !this.eventsVertical.some(x => x.id === schedulerEvent.record.id)),
        share(),
      );

      function collateEventChangesWithAssignmentChangesIfApplicible(changeEvent: any) {
        return pipe(
        take(1),
        map(changeAssignment => [changeEvent, changeAssignment]),
        share()
        );
      }

      const buildProspectiveEventAssignmentChanges = prospectiveSiteVisitModification.pipe(
        concatMap(changeEvent =>
        race(interval(50).pipe(map(x => x as any), take(1)), prospectiveSiteVisitAssignmentModificationPipe)
        .pipe(
          tap(() => this.consideringBatchedChange = false),
          collateEventChangesWithAssignmentChangesIfApplicible(changeEvent)
        )));

      const buildExistingEventAssignmentChanges = existingSiteVisitModification.pipe(
        concatMap(changeEvent =>
        race(interval(50).pipe(map(x => x as any), take(1)), existingSiteVisitAssignmentModificationPipe)
        .pipe(
          tap(() => this.consideringBatchedChange = false),
          collateEventChangesWithAssignmentChangesIfApplicible(changeEvent)
        )));

      const changeProspectiveEventDetailsOnly = buildProspectiveEventAssignmentChanges.pipe(
          filter(([, changeAssignment]) => changeAssignment === 0),
          map(bryntumEvent => {
            const associatedUnmutatedSiteVisit = this.prospectiveSiteVisits.find(q => q.docId === bryntumEvent[0].record.data.siteVisitDocId);
            const updates = this.updateEvent(bryntumEvent[0]);
            const associatedAssignment = this.prospectiveAssignments.find(q => q.siteVisitDocId === associatedUnmutatedSiteVisit.docId);
            return {updates, associatedAssignment, newSiteVisitNumber: bryntumEvent[0].siteVisitNumber};
          }),
          switchMap(x => this.siteVisitService.retrieveFirestoreBatchString().pipe((
            map(wb => {
              return {batch: wb, val:x};
            })
          ))),
          switchMap(x => this.AssignSiteVisitToEmployee(x.val.updates.job.jobDocId, x.val.associatedAssignment.employeeDocId,
            x.val.updates.siteVisit, x.val.updates.siteVisit.startDate,x.batch, null, x.val.newSiteVisitNumber  )));


      const changeExistingEventDetailsOnly = buildExistingEventAssignmentChanges.pipe(
          filter(([, changeAssignment]) => changeAssignment === 0),
          tap(x => console.error("existing event details only.")),

          tap(x => {
            if (x !== null) {
              if (x[0].record.readOnly !== undefined && !x[0].record.readOnly) {
                const record = x[0].record;
                let currentSiteVisit: SiteVisit = null;
                const associcatedVerticalEvent = this.eventsVertical.find(x => x.id === record.id);
                currentSiteVisit = this.siteVisits.find(x => x.docId === associcatedVerticalEvent.siteVisitDocId);
                this.siteVisitPreModification = {siteVisit: new SiteVisit(this.siteVisits.find(y=>y.docId === currentSiteVisit.docId)),
                assignment: new Assignment(this.assignments.find(y=>y.siteVisitDocId === currentSiteVisit.docId))};
                this.siteVisitPreModification.siteVisit.startDate = new Date(this.siteVisitPreModification.siteVisit.startDate);
                this.siteVisitPreModification.siteVisit.endDate = new Date(this.siteVisitPreModification.siteVisit.endDate);
            }
          }
        }),
          map(bryntumEvent => {
            return {updatedEvent : this.updateEvent(bryntumEvent[0]), bryntumEvent};
          }),
          switchMap(update => this.assignmentService.retrieveFirestoreBatchString().pipe((
            map(wb => {
              return {batch: wb, val:update};
            })
          ))),
          map(x => {
            const update = x.val;
            if (update.updatedEvent !== null) {
              update.updatedEvent.siteVisit.minimizeSiteVisitMutationsOnReconciliation = update.bryntumEvent[0].record.dragging;
              const assignedEmployeeDocId = this.assignments.find(x => x.siteVisitDocId === update.updatedEvent.siteVisit.docId).employeeDocId;
              return {job: update.updatedEvent.job, siteVisit: update.updatedEvent.siteVisit, assignedEmployeeDocId, newSiteVisitNumber: x.val.bryntumEvent[0].siteVisitNumber, wb: x.batch} as DataNeededForAdditionalSiteVisitModifications;
            } else {
              return null;
            }
          }),
          );

      const manualChangeExistingEventDetailsOnly = this.manuallyUpdateStartTimeSiteVisit$.pipe(
        switchMap(update => this.assignmentService.retrieveFirestoreBatchString().pipe((
          map(wb => {
            return {batch: wb, val:update};
          })
        ))),
        map(x => {
            const updatedEvent = x.val;
                updatedEvent.siteVisit.startDate = this.AddTimePortionFromVerticalDateToDatePortionFromResourceDate(updatedEvent.bryntumEventRawStartTime, updatedEvent.siteVisit.startDate);
                updatedEvent.siteVisit.endDate = subMinutes(updatedEvent.siteVisit.endDate,updatedEvent.siteVisit.commuteTimeMinutes);
                const existingEvent = this.siteVisits.find(x => x.docId === updatedEvent.siteVisit.docId);
                this.hoursDeltaAgainstOnDeckJob = updatedEvent.siteVisit.expectedDurationHours - existingEvent.expectedDurationHours;
                const associatedJob = this.jobs.find(x => x.siteVisits.some(z => z.docId === updatedEvent.siteVisit.docId));
                const assignedEmployeeDocId = this.assignments.find(x => x.siteVisitDocId === updatedEvent.siteVisit.docId).employeeDocId;
                return {job: associatedJob, siteVisit: updatedEvent.siteVisit, assignedEmployeeDocId: assignedEmployeeDocId, newSiteVisitNumber: null, wb: x.batch} as DataNeededForAdditionalSiteVisitModifications;
            }));

      function applyEventChangesWhenAssignmentAndEventChangesPresent(verticalSchedulerService: VerticalSchedulerService) {
        return pipe(
        filter(([, changeAssignment]) => changeAssignment !== 0),
        tap(() => verticalSchedulerService.waitingForAssignmentAndEventUpdates$.next(true)),
        map(([changeEvent, changeAssignment]) => {
          const job = verticalSchedulerService.updateEvent(changeEvent);
          return {changeAssignment, job};
        }));
      }

      const changeExistingEventAndAssignment = buildExistingEventAssignmentChanges.pipe(
        applyEventChangesWhenAssignmentAndEventChangesPresent(this),
        tap(x => console.log(x,` string`)),
        tap(x => {
          if (x.job !== null) {
          this.siteVisitPreModification = {siteVisit: new SiteVisit(this.siteVisits.find(y=>y.docId === x.job.siteVisit.docId)),
          assignment: new Assignment(this.assignments.find(y=>y.siteVisitDocId === x.job.siteVisit.docId))};
          console.error(this.siteVisitPreModification.assignment);
          this.siteVisitPreModification.siteVisit.startDate = new Date(this.siteVisitPreModification.siteVisit.startDate);
          this.siteVisitPreModification.siteVisit.endDate = new Date(this.siteVisitPreModification.siteVisit.endDate);
          }
        }),
        tap(x => console.error('RAN THROUGH CHANGE EXISTING EVENT AND ASSIGNMENT')),
          switchMap(val => this.modifyAssignment(val.changeAssignment,val.job?.job, val.job?.siteVisit )));

      const changeProspectiveEventAndAssignment = buildProspectiveEventAssignmentChanges.pipe(
        applyEventChangesWhenAssignmentAndEventChangesPresent(this),
            map(x => this.updateJobWithAssignmentModification(x.changeAssignment, x.job.job, x.job.siteVisit)),
            switchMap(x => this.siteVisitService.retrieveFirestoreBatchString().pipe((
              map(wb => {
                return {batch: wb, val:x};
              })
            ))),
          switchMap(x => this.AssignSiteVisitToEmployee(x.val.job.jobDocId, x.val.newAssignment.employeeDocId,
            x.val.currentSiteVisit, x.val.currentSiteVisit.startDate, x.batch,x.val.oldAssignment, x.val.newSiteVisitNumber )));

      const removeAssignment = this.bryntumRescheduleSiteVisit$.pipe(
        tap(x => console.warn(x,`BRYNTUM RESCHEDULE SITE VISITS`)),
        tap(() => {
          this.processingChangeToSchedule$.next(true);
          this.assignmentModification$.next(true);
        }),
        switchMap(x => this.removeAssignmentAndSiteVisitFromJob(x, true).pipe(
          map(wb => {
            return {wb: wb, siteVisit: x.siteVisitDocId};
          }))
        )
      );

      const deleteAssignment = this.bryntumDeleteSiteVisit$.pipe(
        tap(x => console.warn(x,`BRYNTUM DELETE SITE VISITS`)),
        tap(() => this.processingChangeToSchedule$.next(true)),
        switchMap(x => this.removeAssignmentAndSiteVisitFromJob(x, false).pipe(
          switchMap(wb => this.addTimeDiffToJobAndUpdateToJobService(x.jobDocId, differenceInHours(x.actualStartDate, x.actualEndDate)  +
          differenceInMinutes(x.actualStartDate, x.actualEndDate ) / 60, wb, JobDurationDeltaModificationType.SITEVISITDELETED, x.siteVisitDocId)
          .pipe(
            map(wb => {
              return {wb: wb, siteVisit: x.siteVisitDocId};
            }))
          )
        )));

      const addSiteVisit = this.bryntumAddSiteVisit$.pipe(
        tap(x => console.warn(x,`BRYNTUM ADD SITE VISIT`)),
        tap(() => this.processingChangeToSchedule$.next(true)),
        map(bryntumAddSiteVisit => {
          const prospectiveSiteVisit = this.prospectiveSiteVisits.find(x=> x.docId === bryntumAddSiteVisit.siteVisitDocId);
          if (prospectiveSiteVisit !== undefined) {
            const timeRanges = this.getSiteVisitStartEnd(bryntumAddSiteVisit.bryntumStartTime,
              bryntumAddSiteVisit.bryntumEndTime, bryntumAddSiteVisit.actualDate);
            prospectiveSiteVisit.startDate = timeRanges.siteVisitStart;
            prospectiveSiteVisit.endDate = timeRanges.siteVisitEnd;
            prospectiveSiteVisit._durationHours = undefined;
            prospectiveSiteVisit.explicitErrored = bryntumAddSiteVisit.explicitlyErrored;
            prospectiveSiteVisit.minimizeSiteVisitMutationsOnReconciliation = bryntumAddSiteVisit.minimizeSiteVisitMutationsOnReconciliation;
            return {siteVisit: prospectiveSiteVisit, bryntumAddSiteVisit};
          } else {
          const newSiteVisit = this.createSiteVisitFromBryntumTimes(bryntumAddSiteVisit.jobDocId, bryntumAddSiteVisit.bryntumStartTime,
          bryntumAddSiteVisit.bryntumEndTime, bryntumAddSiteVisit.actualDate, bryntumAddSiteVisit.employeeDocId);
          newSiteVisit.minimizeSiteVisitMutationsOnReconciliation = bryntumAddSiteVisit.minimizeSiteVisitMutationsOnReconciliation;
          newSiteVisit.explicitErrored = bryntumAddSiteVisit.explicitlyErrored;
          return {siteVisit: newSiteVisit, bryntumAddSiteVisit};
          }
        }),
        switchMap(x => this.siteVisitService.retrieveFirestoreBatchString().pipe((
          map(wb => {
            return {batch: wb, val:x};
          })
        ))),
        // this may be wrong, need to test.
        switchMap(data => this.AssignSiteVisitToEmployee(data.val.siteVisit.jobDocId, data.val.bryntumAddSiteVisit.employeeDocId,
          data.val.siteVisit, data.val.bryntumAddSiteVisit.actualDate, data.batch, null, null)),
        take(1));

      const eventRemoval = merge(removeAssignment, deleteAssignment).pipe(
        switchMap(x => this.customerCommunicationCreationService.cancelCustomerCommunicationFromSiteVisit(x.siteVisit, "canceled from assigment removal/ deletion", x.wb).pipe(
          map(() => x.wb)
        ))
      );

      const eventProcessingResultingInAddingSiteVisit = merge(addSiteVisit, changeProspectiveEventAndAssignment,
        changeProspectiveEventDetailsOnly, changeProspectiveAssignmentOnly);

      const existingEventAssignmentUpdates = merge(changeExistingEventDetailsOnly.pipe(tap(()  => console.log('1 - this.jobService.update$')),),
        changeExistingEventAndAssignment.pipe(tap(()  => console.log('2 - jobService.update$, assignmentServiceUpdate'))),
        changeExistingAssignmentOnly.pipe(tap(()  => console.log('3 - jobService.update$, assignmentServiceChange'))),
        manualChangeExistingEventDetailsOnly.pipe(tap(()  => console.log('4 - jobService.update$')))).pipe(share());

      const updatesWhichMayRequireAdditonalSiteVisitMovement = existingEventAssignmentUpdates.pipe(
          filter(x => x !== null),
          concatMap(x => this.updateResourceDayRouting(x.assignedEmployeeDocId, x.siteVisit.startDate, x.siteVisit.siteVisitAddress,
            this.siteVisitPreModification === undefined ? null : this.siteVisitPreModification.siteVisit , x.wb, x.siteVisit)
          .pipe(
            map(() => x)
            )),
          concatMap(x => this.reconcileSiteVisits(x.siteVisit, x.assignedEmployeeDocId, x.wb, x.newSiteVisitNumber).pipe(
            map(siteVisitsNeedingUpdate => {
              if (siteVisitsNeedingUpdate.newSiteVisit !== undefined) {
                  x.siteVisit = siteVisitsNeedingUpdate.newSiteVisit;
                }
                if (x.job.siteVisits.findIndex(y => y.docId === x.siteVisit.docId) > -1) {
                  x.job.siteVisits.splice(x.job.siteVisits.findIndex(y => y.docId === x.siteVisit.docId), 1, x.siteVisit);
                }
                return x;
            }))),
            concatMap(x => this.jobService.update$(x.job, x.wb).pipe(
                map(z=> x.wb)
              )),
            catchError(err => {
            console.log('Error caught in observable.', err);
            return throwError(err);
            })
      );

      const updatesWhichWereCosmeticOnly = existingEventAssignmentUpdates.pipe(
        filter(x => x === null),
        switchMap(() => this.assignmentService.retrieveFirestoreBatchString()),
        tap(x => console.log("CTS COSMETIC")),
        tap(() => this.triggerRerenderingManually$.next(null))
      );

      const allEventUpdates = merge(eventRemoval.pipe(tap(() => console.log("EVENT REMOVAL"))), eventProcessingResultingInAddingSiteVisit.pipe(tap(() => console.log("EPRI"))),
        updatesWhichMayRequireAdditonalSiteVisitMovement.pipe(tap(() => console.log("UWMR"))),updatesWhichWereCosmeticOnly.pipe(tap(() => console.log("COSMOS"))) )
        .pipe(
          share());

      const eventUpdatesWhereOriginalSiteVisitCausesCommuteRecalculationNeeds = allEventUpdates.pipe(
        tap(x => console.warn(this.siteVisitPreModification)),
        filter(x => this.siteVisitPreModification !== undefined),
        map(wb => {
          return {pre: this.siteVisitPreModification, wb: wb}
        }),
        filter(pre => this.assignments.find(x => x.siteVisitDocId === pre.pre.siteVisit.docId) !== undefined),
        switchMap(pre => this.reconcileSiteVisitsOnDay(this.assignments.find(x => x.siteVisitDocId === pre.pre.siteVisit.docId).employeeDocId,
           pre.pre.siteVisit, pre.pre.assignment.employeeDocId, pre.wb) ),
      );

      const noCommuteRecalculationNeeed = allEventUpdates.pipe(
        filter(() => this.siteVisitPreModification === undefined || this.assignments.find(x => x.siteVisitDocId === this.siteVisitPreModification.siteVisit.docId) === undefined),
        tap(() => console.error("No commute recalc needed?")),
      );

      const theFullness = race(eventUpdatesWhereOriginalSiteVisitCausesCommuteRecalculationNeeds,noCommuteRecalculationNeeed).pipe(
        tap(() => this.siteVisitPreModification = undefined),
        take(1),
        repeat(),
        share());

      const changesMade = theFullness.pipe(
        filter(wb => wb !== null),
        tap(x => console.log(x,` string`)),
        tap( () => {
          if (this.hoursDeltaAgainstOnDeckJob !== 0)
          {
            this.changeInHoursScheduledAgainstOnDeckJob = this.hoursDeltaAgainstOnDeckJob;
            this.hoursDeltaAgainstOnDeckJob = 0;
          }
        }
        ),
        tap(x => console.log("COMMITTING now.")),
        mergeMap(wb => this.assignmentService.commitExternallyManagedFirestoreBatch(wb)),
      );

      // In some cases, updates are not meaningful (for example, they were triggered by a readonly event).
      const noChangesMade = theFullness.pipe(
        filter(wb => wb === null),
        tap(x => console.log(x,` string`)),
        tap(() => this.updateProcessedWithoutChange$.next(null)),
        repeat(),
        );

      race(changesMade, noChangesMade).pipe(
        tap(x => console.error("UNMASKING NOW")),
        tap(() => this.processingChangeToSchedule$.next(false)),
        take(1),
        repeat()
      ).subscribe();
 }

  retrieveCopyOfBryntumDataFromSchedulingService(): ByrntumData {
  const retVal = new ByrntumData();
  retVal.assignments = this.bryntumAssignments.map(x => new BryntumAssignment(x));
  retVal.events = this.eventsVertical.map(x => {

    const newEvent = new BryntumEvent(this.siteVisitSchedulingService.regenerateArrivalWindowForSiteVisitDoc,x);
    newEvent.actualEndDate = new Date(newEvent.actualEndDate);
    newEvent.actualStartDate = new Date(newEvent.actualStartDate);
    newEvent.arrivalWindowEndDate = new Date(newEvent.arrivalWindowEndDate);
    newEvent.arrivalWindowStartDate = new Date(newEvent.arrivalWindowStartDate);
    newEvent.endDate = new Date(newEvent.endDate);
    newEvent.startDate = new Date(newEvent.startDate);
    newEvent.commuteEndDate = new Date(newEvent.commuteEndDate);
    newEvent.returnCommuteStartDate = new Date(newEvent.returnCommuteStartDate);
    newEvent.arrivalWindowString = newEvent.formatArrivalWindowString();
    return newEvent;
  }
    );
  retVal.resources = this.resourcesVertical.map(x => new BryntumResourceDateSummation(x));
  return retVal;
}

  retrieveAvailibilityInfoFromSchedulingService(): ResourceAvailibility[] {
  return  this.resourceAvailibility === undefined ? [] : this.resourceAvailibility.map(x => {
    const newAvailible = new ResourceAvailibility(x);
    newAvailible.actualDate = new Date(newAvailible.actualDate);
    newAvailible.endDate = new Date(newAvailible.endDate);
    newAvailible.startDate = new Date(newAvailible.startDate);
    return newAvailible;
  });
}

  addTimeDiffToJob(currentJob: Job, deltaHours: number, modificationType: JobDurationDeltaModificationType,
    siteVisitDocId: string = null): Job {
      const timeDelta = new JobDurationDelta({employeeAssigningDocId: this.authenticationService.activelyLoggedInEmployeeDocId, siteVisitDocId, deltaHours,
                                              modificationType, dateModificationOccurred: new Date() });
      currentJob.jobDurationDeltas.push(timeDelta);
      return currentJob;
    }

  private addTimeDiffToJobAndUpdateToJobService(jobDocId: string, deltaHours: number,
    wb: string, modificationType: JobDurationDeltaModificationType,
    siteVisitDocId: string = null ): Observable<string> {
    let currentJob = this.jobs.find(x => x.jobDocId === jobDocId);
    currentJob = this.addTimeDiffToJob(currentJob, deltaHours, modificationType, siteVisitDocId);
    return this.jobService.update$(currentJob, wb).pipe(
      map(() => wb));
  }

  /**
   * We maintain a pre-cache of 1 view window in each direction.
   * @param preceedingWindowStartDate
   * @param viewStartDate
   * @param viewEndDate
   * @param postceedingWindowEndDate
   * @param employeeIdSubsetToReturn
   */
  precacheFromViewAsNeeded(preceedingWindowStartDate: Date, viewStartDate: Date, viewEndDate: Date, postceedingWindowEndDate: Date, employeeIdSubsetToReturn ) {
    if (this.monitoredDateLow === undefined) {
      this.monitoredDateLow = viewStartDate;
      this.monitoredDateHigh = viewEndDate;
    }
    let enabledViewWindowMovement = false;

    const cachingObs$ = [];

    if (preceedingWindowStartDate.getTime() !== this.monitoredDateLow.getTime() || postceedingWindowEndDate.getTime() !== this.monitoredDateHigh.getTime()) {
      console.error(preceedingWindowStartDate, viewStartDate, viewEndDate, postceedingWindowEndDate);
      this.replaceViewWindowCache$.next(null);

      const earlyCacheObs = this.retrieveAllFirestoreDataNeededForViewWindow({startDate: preceedingWindowStartDate, endDate: viewStartDate, employeeIdSubsetToReturn, takeUntilGuid: "",
      takeUntilObs$: this.replaceViewWindowCache$});

      cachingObs$.push(earlyCacheObs);
      console.log(`Early Cache:  ${preceedingWindowStartDate}  -  ${viewStartDate}`);
      this.monitoredDateLow = preceedingWindowStartDate;
      const lateCacheObs = this.retrieveAllFirestoreDataNeededForViewWindow({startDate: viewEndDate, endDate: postceedingWindowEndDate, employeeIdSubsetToReturn, takeUntilGuid: "",
        takeUntilObs$: this.replaceViewWindowCache$});

      cachingObs$.push(lateCacheObs);
      this.monitoredDateHigh = postceedingWindowEndDate;
        zip(...cachingObs$).pipe(
          concatMap((value, index) => index === 0
          ? of(value).pipe(
            tap(() => console.log(`Monitoring: ${this.monitoredDateLow} -  ${this.monitoredDateHigh}`)),
            tap(() => {
              if (!enabledViewWindowMovement) {
              this.viewWindowMovementEnabled$.next(true)
            }})
        )
        : of(value)
      ),
      takeUntil(merge(this.schedulerDestroyed$, this.replaceViewWindowCache$)),
      finalize(() => console.warn(`Finalize on ${preceedingWindowStartDate}  - ${postceedingWindowEndDate}`))
      ).subscribe();
    }
  }


  addInActualJobValuesWhenPopulated(jobDocIds: string[], addedInActualValues: Subject<boolean>) : void {
    this.jobService.loadMultiple$(jobDocIds).pipe(
      tap(x => console.log(x,` string`)),
      tap(() => addedInActualValues.next(true)),
      // take(1)
    ).subscribe();
  }


  retrieveActiveAssignments(startDate: Date, endDate: Date, employeeIdSubsetToReturn: string[] | null, takeUntil$: Observable<any>) : Observable<Assignment[]> {
    return this.assignmentService.searchDateRange(startDate, endDate, employeeIdSubsetToReturn === null ? null : employeeIdSubsetToReturn[0]).pipe(
      map(x => x.filter(q => q.active)),
      debounce(() => this.schedulerViewActive$.pipe(filter(x => x === true))),
      tap(x => console.log(`CT CTS ${startDate} ${endDate}`)),
      switchMap(assignments => this.siteVisitService.queryFirestoreForInValues('docId',assignments.map(a => a.siteVisitDocId),false).pipe(
      debounce(() => this.schedulerViewActive$.pipe(filter(x => x === true))),
      distinctUntilChanged((prev,curr) => {
        const prevSet = new Set(prev.map(a => a.docId));
        const curSet = new Set(curr.map(a => a.docId));
        if (prevSet.size !== curSet.size) {
          return false;
        }
        for (var p of prevSet) {
          if (!curSet.has(p)) {
            return false;
          }
        }
        return true;
      }),
      map(siteVisits => {
        siteVisits.forEach(sv => {
          const assignment = assignments.find(a => a.siteVisitDocId  === sv.docId);
          if (assignment) {
            assignment.jobDocId = sv.jobDocId;
          }
        })
        return assignments;
      }),
    )),
    takeUntil(takeUntil$),
    );
  }

  generateBryntumDataMobile(startDate: Date, endDate: Date, employeeIdSubsetToReturn: string[] | null, takeUntilGuid: string,
    siteVisitIdSubsetToReturn?: string[], mutateDates: boolean = false, precacheDates: boolean = true) : Observable<ByrntumData>
  {
    this.loggingService.addLog(`Begin generating mobile data. ${format(new Date(), 'H:mm:ss:SSS')}`);
    let employeeUpdateInitiatedChangeSet = false;
    const monitoringJobs$ : Subject<boolean> = new Subject<boolean>();
    this.viewWindowMovementEnabled$.next(false);
    const resouceDayPopulated = this.physicalAddressRoutingService.addressRoutingService.populateAddressRoutingFromDexieForResourceDays(startDate, endDate).pipe(
      tap(x => this.loggingService.addLog(`${x} commutes from resource days added. ${format(new Date(), 'H:mm:ss:SSS')}`)),
    );

    const activeAssignment$ = new Subject<Assignment[]>();
    this.retrieveActiveAssignments(startDate, endDate, employeeIdSubsetToReturn, this.activeVerticalSchedulerGuid$.pipe(filter(x => x !== takeUntilGuid))).subscribe(activeAssignment$);

    // first get assignements.
    const getAssignments = activeAssignment$.pipe(
      switchMap(assignments => combineLatest(
        [ this.jobService.retrieveJobsToMonitorForScheduler(startDate, endDate,false, this.schedulerViewActive$, assignments.map(a => a.jobDocId), this.assignmentModification$).pipe(
          tap(x => precacheDates ? console.log("job monitor") : console.log(x) )),
        of(assignments).pipe(tap(() => precacheDates ? console.log("assignment searchDateRange") : null )),
        employeeIdSubsetToReturn === null ? this.employeeService.loadAll$().pipe(filter(x=>x!==null),map(y => y.filter(z => z.active && z.scheduleFieldCallsFor)) ) : of(employeeIdSubsetToReturn.map(e =>
          this.employeeService.get(e))),
        resouceDayPopulated,
        this.employeeAvailabilityService.futureAvailibility.pipe(
          tap(() =>employeeUpdateInitiatedChangeSet = true),
        ),
       ]
      )
        ));

    const getJobsOnDate =
      getAssignments
      .pipe(
        debounce(() => this.schedulerViewActive$.pipe(filter(x => x === true))),
        // tap(x => console.warn(x,` string`)),
        debounce(() => this.loadedEmployeeOriginAndDestinationAddresses$),
          tap( ([jobs ]) =>  {
            this.addrRoutingService.cacheProspectiveCommutes(jobs.flatMap(j => j.siteVisits).flatMap(s => s.prospectiveCommutes));
          }),
        );


    return getJobsOnDate.pipe(
      map( ([jobs,assignments,allEmployees]) => {
        const activeSiteVisits = jobs.flatMap(x => x.siteVisits.map(s => {
          s.siteVisitAddress = x.serviceAddress;
          return s;
        }));
        const activeSiteVisitDocIds = jobs.flatMap(x => x.siteVisits.map(s => s.DocId()));
        const siteVisitsPerEmployee = allEmployees.reduce((acc, emp) => {
          const assignedSiteVisits = assignments.filter(x => x.employeeDocId === emp.docId);
          acc.push(assignedSiteVisits.filter(x => activeSiteVisitDocIds.includes(x.siteVisitDocId)));
          return acc;
        }, []);
        const populated = siteVisitsPerEmployee.filter(x => x.length > 0);
        populated.forEach(x => x.sort((a, b) => a.siteVisitStartDate.getTime() - b.siteVisitStartDate.getTime()));
        console.log(populated);
        const retVal :{assignment: Assignment, siteVisit: SiteVisit, employee: Employee}[][] = [];
        populated.forEach(day => {
          retVal.push(day.map(x => {
            return {
              assignment: x,
              siteVisit: activeSiteVisits.find(y => y.DocId() === x.siteVisitDocId),
              employee: allEmployees.find(y => y.docId === x.employeeDocId)
            }
          }));
        });
        return {jobs,assignments};
      }),
      tap(q => {
        if (precacheDates) {
          // this.precacheMobileAsNeeded(startDate, endDate, employeeIdSubsetToReturn);
          this.addInActualJobValuesWhenPopulated(q.jobs.map(x => x.DocId()), monitoringJobs$);
        }
      }),
      map(q  => {
      const jobs = q.jobs ;
      let siteVisits = jobs.flatMap(x => x.siteVisits.map(z => {
        z.resetFirestoreIgnoredDetails();
        return z;
      }));
      const assignments = q.assignments.filter(x => siteVisits.map(x => x.docId).includes(x.siteVisitDocId));
      siteVisits = siteVisits.filter(x => assignments.map(a => a.siteVisitDocId).includes(x.docId));
      return this.generateBryntumVertical(startDate, endDate, employeeIdSubsetToReturn, jobs, siteVisits, assignments, siteVisitIdSubsetToReturn, mutateDates);
      }),
      tap(() => console.log(employeeUpdateInitiatedChangeSet)),
      // distinctUntilChanged((prev,curr) =>  ByrntumData.equalSeedBryntumDisplay(prev,curr) && !employeeUpdateInitiatedChangeSet),
      distinctUntilChanged((prev,curr) =>  ByrntumData.equalSeedBryntumDisplay(prev.byrntumData,curr.byrntumData)),
      tap(() => employeeUpdateInitiatedChangeSet = false),
      tap(x => this.populateServiceArraysFromBryntumData(x)),
      map(q => {
          const retVal = this.retrieveCopyOfBryntumDataFromSchedulingService();
          if (this.changeInHoursScheduledAgainstOnDeckJob !== 0) {
            retVal.changeInHoursScheduledAgainstOnDeckJob = this.changeInHoursScheduledAgainstOnDeckJob;
            this.changeInHoursScheduledAgainstOnDeckJob = 0;
          }
          return retVal;
        }
      ),
      tap(() => this.loggingService.addLog(`Completed generating mobile data. ${format(new Date(), 'H:mm:ss:SSS')}`)),
      catchError(err => {
        this.loggingService.addLog(`Error generating mobile bryntum data ${err.message} ${format(new Date(), 'H:mm:ss:SSS')}`, "",err);
        return throwError(err);
    }),
    ) as Observable<ByrntumData>;
  }

      retrieveAllFirestoreDataNeededForViewWindow(input : FirestoreDataNeededForViewInput) : Observable<{ jobs: Job[]; assignments: Assignment[]; resourceDayUpdateInitiatedChangeSet: boolean;
                                               employeeUpdateInitiatedChangeSet: boolean; employeeSubset: string[];}> {


        let resourceDayUpdateInitiatedChangeSet = false;
        let employeeUpdateInitiatedChangeSet = false;

        const resouceDayPopulated = this.physicalAddressRoutingService.addressRoutingService.populateAddressRoutingFromDexieForResourceDays(input.startDate, input.endDate).pipe(
          tap(x => console.log(`${x} commutes from resource days added. start: ${input.startDate} end: ${input.endDate} `)),
        );

        let loadEmployees$ = this.employeeService.loadAll$().pipe(
          filter(x=>x!==null),
          tap(() => console.error("employee service CTS")),
          map(emp => {
           let employeeSubset: string[] = [];
           if (input.employeeIdSubsetToReturn === null) {
             employeeSubset = emp.filter(z => z.active && z.scheduleFieldCallsFor).map(x => x.docId);
           } else {
             employeeSubset = input.employeeIdSubsetToReturn as string[];
           }
           employeeUpdateInitiatedChangeSet = true;
           return employeeSubset;
          }));

          let futureAvailability$ = this.employeeAvailabilityService.futureAvailibility.pipe(
            (tap(() => {
              console.error("availibility service!  CTS");
              employeeUpdateInitiatedChangeSet = true;
             }))
           );


        const activeAssignment$ = new Subject<Assignment[]>();
        this.retrieveActiveAssignments(input.startDate, input.endDate, input.employeeIdSubsetToReturn, input.takeUntilObs$ !== null ? input.takeUntilObs$ :
          this.activeVerticalSchedulerGuid$.pipe(filter(x => x !== input.takeUntilGuid))).pipe(
            distinctUntilChanged((prev,curr) => {
              // an assignment has been updated if there is a record in curr that has a different siteVisitDocId then corresponding record in prev, or if
              // the two have different counts.
              try {
                const assignmentUpdated  = curr.length !== prev.length || curr.some(x => prev.find(y => y.siteVisitDocId === x.siteVisitDocId)?.employeeDocId !== x.employeeDocId);

                if (assignmentUpdated) {
                  this.assignmentUpdateReadSinceLastEmission$.next(true);
                }
              } catch (err) {
                console.error(err);
              }
              return false;
            }),
          ).subscribe(activeAssignment$);



       // first get assignements.
        const retreivedAllCombinedData = combineLatest
        ([
          // dependendant on activeAssignments
          activeAssignment$.pipe(
          tap(x => console.log("BONE ASSIGNMENT CTS")),
          tap(() => this.assignmentModification$.next(false)),
        switchMap(assignments => combineLatest(
        [this.jobService.retrieveJobsToMonitorForScheduler(input.startDate, input.endDate, true, this.schedulerViewActive$, assignments.map(a => a.jobDocId), this.assignmentModification$)
          .pipe(
          filter(() => this.assignmentModification$.value === false),
          map(val => {
            const siteVisitsToCheck = val.flatMap(x => x.siteVisits).map(x => ({siteVisit: x, startTime: new Date(x.startDate), endTime: new Date(x.endDate)}));
            return {val, siteVisitsToCheck}
          }),
          distinctUntilChanged((prev,curr) => {
            if (curr.val.length !== prev.val.length || curr.siteVisitsToCheck.length !== prev.siteVisitsToCheck.length) {
              console.log(`BONE job Count Prev:  ${prev.val.length} job Count Curr: ${curr.val.length} siteVisitCheck Prev: ${prev.siteVisitsToCheck.length}
                siteVisitCheck Curr: ${curr.siteVisitsToCheck.length} `   )
              return false;
            }
            const currentSiteVisits = curr.siteVisitsToCheck;
            const previousSiteVisits = prev.siteVisitsToCheck;
            // a start time for site visit has been updated.
            try {
              const meaningfulSiteVisitUpdate  = currentSiteVisits.some(x => {
                const matched = previousSiteVisits.find(y => y.siteVisit.DocId()  === x.siteVisit.DocId());
                return matched?.startTime.getTime() !== x.startTime.getTime() ||
                matched?.endTime.getTime() !== x.endTime.getTime() || matched.siteVisit.lockStartTime !== x.siteVisit.lockStartTime ||
                matched.siteVisit.lockNextSiteVisitOrder !== x.siteVisit.lockNextSiteVisitOrder || matched.siteVisit.lockPreceedingSiteVisitOrder
                !== x.siteVisit.lockPreceedingSiteVisitOrder;
              });
              if (meaningfulSiteVisitUpdate) {
                this.eventStartTimeUpdateReadSinceLastEmission$.next(true);
                return false;
              }
            }
            catch (err) {
              console.log(err);
            }
            return true;
          }),
          map(x => x.val),
          tap(x => console.error(`BONE ${input.startDate}  -  ${input.endDate} job monitor CTS`)),
        ),
         of(assignments).pipe(tap(() => console.error("assignment searchDateRange CTS"))),
        ]))),
        //independent of activeAssignments
        loadEmployees$,
         futureAvailability$,
         merge(
            this.triggerRerenderingManually$.pipe(
              tap(() => employeeUpdateInitiatedChangeSet = true),
              tap(() => console.error("triggerRerenderingManually$!  CTS"))),
            of(null)
            ),
            resouceDayPopulated.pipe(tap(() => console.error("resource day service! CTS"))),
            ])
            .pipe(
        filter(() => (this.waitingForAssignmentAndEventUpdates$.value === false || this.fullAssignmentAndEventUpdateProcessed$.value === true)),
          debounce(() => this.loadedEmployeeOriginAndDestinationAddresses$),
          // when resource day availibility updates, we need to process updates even though changes are in process ( though these will get filtered out by the schedule viewer)
          debounce(() => this.processingChangeToSchedule$.pipe(filter(x => x === false || employeeUpdateInitiatedChangeSet))),
          filter(() => (this.waitingForAssignmentAndEventUpdates$.value === false || this.fullAssignmentAndEventUpdateProcessed$.value === true)),
          map(x => [x[0][0],x[0][1],x[1]]),
          tap( x =>  {
            const jobs = (x[0] as Job[]);
            this.addrRoutingService.cacheProspectiveCommutes(jobs.flatMap(j => j.siteVisits).flatMap(s => s.prospectiveCommutes));
            this.jobsNeedingAssignment$.next(jobs.filter(z => z.jobDocId !== undefined && z.needsAssigned));
          }),
         ) as Observable<[Job[], Assignment[], string[]]>;

         return retreivedAllCombinedData.pipe(
          map(([jobs, assignments, employeeSubset]) => {
            return {jobs, assignments, resourceDayUpdateInitiatedChangeSet, employeeUpdateInitiatedChangeSet, employeeSubset};
          }),
          tap(() => {
            resourceDayUpdateInitiatedChangeSet = false;
            employeeUpdateInitiatedChangeSet = false;
            this.eventStartTimeUpdateReadSinceLastEmission$.next(false);
          this.assignmentUpdateReadSinceLastEmission$.next(false);
            this.waitingForAssignmentAndEventUpdates$.next(false);
            this.fullAssignmentAndEventUpdateProcessed$.next(false);
          }),
         );
      }

  generateBryntumVerticalData(startDate: Date, endDate: Date, employeeIdSubsetToReturn: string[] | null, takeUntilGuid: string,
    preceedingViewStartDate: Date, suceedingViewEndDate: Date, cacheData: boolean, siteVisitIdSubsetToReturn?: string[], mutateDates: boolean = true ): Observable<ByrntumData> {
      console.error(`request BRYNTUMS CTS!  ${startDate}  -  ${endDate}   cache: ${cacheData}`);

      let precacheRun: boolean = false;
      const retreivedAllCombinedData  = this.retrieveAllFirestoreDataNeededForViewWindow({startDate, endDate, employeeIdSubsetToReturn, takeUntilGuid, takeUntilObs$: null});

      const updatesFromDatabase =  retreivedAllCombinedData.pipe(
        filter(() => (!this.waitingForAssignmentAndEventUpdates$.value)),
        debounce(() => this.schedulerViewActive$.pipe(filter(x => x === true))),
        debounce(() => merge(of(this.delaySchedulerUpdates$.value),this.delaySchedulerUpdates$).pipe(filter(x => x === false))),
        filter(() => (!this.waitingForAssignmentAndEventUpdates$.value)),
        tap(x => console.log(x,` string`)),
        // remove any assignments or site visits that are not matched.  This occurs when user A adds a new event to shedule, and user b recieves
        // update of only one of the two collections.  It resolves after second collection hits, so we can safely ignore the parially hydrated events here.
        map(x => {
          const allSiteVisitsFromJobs = x.jobs.flatMap(j => j.siteVisits.filter(q => q.startDate.getTime() >= startDate.getTime() && q.startDate.getTime() <= endDate.getTime()));
          const hangingAssignments = x.assignments.filter(a => a.active && !allSiteVisitsFromJobs.map(x => x.docId).includes(a.siteVisitDocId)).filter(h => h.lastUpdatedAt);
          const hangingSiteVisits = allSiteVisitsFromJobs.filter(s => !x.assignments.map(a => a.siteVisitDocId).includes(s.docId)).filter(h => h.lastUpdatedAt);
          x.assignments = x.assignments.filter(a => !hangingAssignments.map(x => x.docId).includes(a.docId));
          x.jobs = x.jobs.filter(j => j.needsAssigned || j.siteVisits.filter(s => !hangingSiteVisits.map(x => x.docId).includes(s.docId)).length > 0);
          return x;
        }),
        map(data => {
          data.jobs = data.jobs.filter(x => x.importedJob === false);
          return data;
        }),
        tap(q => {
          if (!precacheRun) {
            precacheRun = true;
            setTimeout(() => this.precacheFromViewAsNeeded(preceedingViewStartDate, startDate, endDate, suceedingViewEndDate, employeeIdSubsetToReturn), 100);
          }}),
        map(q  => {
          const jobs = q.jobs ;
          let siteVisits = jobs.flatMap(x => x.siteVisits.map(z => {
            z.resetFirestoreIgnoredDetails();
            return z;
          }));
          console.log(q.assignments, q.jobs);
          const assignments = q.assignments.filter(x => siteVisits.map(x => x.docId).includes(x.siteVisitDocId));
          siteVisits = siteVisits.filter(x => assignments.map(a => a.siteVisitDocId).includes(x.docId));
          return {val: this.generateBryntumVertical(startDate, subMinutes(endDate,1), q.employeeSubset, jobs, siteVisits,
            assignments, siteVisitIdSubsetToReturn, mutateDates), src: q};
          }),
          // if there are site visits w/ errored commutes updated in past minute, we will delay emissions for 30 seconds to allow for the commute to be updated )
          filter(x => {
            const siteVisitsWithErroredCommutes = x.val.siteVisits.filter(q => q.commuteTimeMinutesFromSiteBackToShop === 120 || q.commuteTimeMinutesToSite === 120);
            const mostRecentUpdatedSiteVisitWithErroredCommute = siteVisitsWithErroredCommutes.sort((a,b) => b.lastUpdatedAt.getTime() - a.lastUpdatedAt.getTime())[0];
            if (mostRecentUpdatedSiteVisitWithErroredCommute !== undefined) {
              console.error(mostRecentUpdatedSiteVisitWithErroredCommute);
              console.error(new Date().getTime() - mostRecentUpdatedSiteVisitWithErroredCommute.lastUpdatedAt.getTime());
            }
            return siteVisitsWithErroredCommutes.length === 0 || new Date().getTime() - mostRecentUpdatedSiteVisitWithErroredCommute.lastUpdatedAt.getTime() > 30000 ||
            startOfDay(mostRecentUpdatedSiteVisitWithErroredCommute.startDate).getTime() < startOfDay(new Date()).getTime();
          }),
          tap(x => this.populateServiceArraysFromBryntumData(x.val)),
          distinctUntilChanged((prev,curr) => {
            console.log(prev.val.byrntumData.events.length, curr.val.byrntumData.events.length);
            const bryntumEqual = ByrntumData.equalSeedBryntumDisplay(prev.val.byrntumData,curr.val.byrntumData);
            console.log(`curr.src.employeeUpdateInitiatedChangeSet: ${curr.src.employeeUpdateInitiatedChangeSet}    curr.src.resourceDayUpdateInitiatedChangeSet: ${curr.src.resourceDayUpdateInitiatedChangeSet}`);
            return bryntumEqual && !curr.src.employeeUpdateInitiatedChangeSet && !curr.src.resourceDayUpdateInitiatedChangeSet
          }),
          map(() => {
              const retVal = this.retrieveCopyOfBryntumDataFromSchedulingService();
              if (this.changeInHoursScheduledAgainstOnDeckJob !== 0) {
                retVal.changeInHoursScheduledAgainstOnDeckJob = this.changeInHoursScheduledAgainstOnDeckJob;
                this.changeInHoursScheduledAgainstOnDeckJob = 0;
              }
              return retVal;
            }
          ),
          tap(() => this.freshDataRetrievedForScheduleView$.next(null)),
       ) as Observable<ByrntumData>;


      return combineLatest([updatesFromDatabase,this.activeVerticalSchedulerGuid$]).pipe(
        map(x => x[0]),
        takeUntil(this.activeVerticalSchedulerGuid$.pipe(filter(x => x !== takeUntilGuid))),
        finalize(() => console.error("BREADANDCIRCUSBREADANDCIRCUSBREADANDCIRCUSBREADANDCIRCUSBREADANDCIRCUSBREADANDCIRCUSBREADANDCIRCUSBREADANDCIRCUS"))
      );
    }

  generateVerticalBryntumEventFromJobSiteVisit(job: Job, siteVisit: SiteVisit, mutateDate: boolean = true): BryntumEvent {

   const retVal = BryntumEvent.generateBryntumEventFromJobSiteVisitPrimaryCustomer(job, siteVisit, job.customer, job.serviceAddress,
      this.siteVisitSchedulingService.regenerateArrivalWindowForSiteVisitDoc, this.settingsService );
   retVal.endDate = mutateDate ? this.AddTimePortionFromActualToVerticalDate(siteVisit.endDate) : siteVisit.endDate;
   if (!job.importedJob) {
    retVal.endDate = addMinutes(retVal.endDate, siteVisit.commuteTimeMinutesFromSiteBackToShop);
    retVal.returnCommuteStartDate = subMinutes(retVal.endDate, siteVisit.getRoundedCommuteTimeMinutes(siteVisit.commuteTimeMinutesFromSiteBackToShop, this.settingsService));
   }
   retVal.startDate = mutateDate ? this.AddTimePortionFromActualToVerticalDate(siteVisit.startDate) : siteVisit.startDate;
   if (!job.importedJob) {
    retVal.startDate = subMinutes(retVal.startDate, siteVisit.getRoundedCommuteTimeMinutes(siteVisit.commuteTimeMinutesToSite, this.settingsService));
   }
   retVal.commuteEndDate = addMinutes(retVal.startDate, siteVisit.getRoundedCommuteTimeMinutes(retVal.commuteMinutesToSite, this.settingsService));
   return retVal;
 }

  private generateBryntumVertical(startDate: Date, endDate: Date, employeeIdSubsetToReturn: string[], jobs: Job[], siteVisits: SiteVisit[], assignments: Assignment[],
siteVisitIdSubsetToReturn: string[], mutateDates: boolean) : {byrntumData : ByrntumData, assignments: Assignment[], siteVisits: SiteVisit[], jobs: Job[]}  {
  const newAssignmentsVertical: BryntumAssignment[] = [];
  let newResourcesVertical: BryntumResourceDateSummation[] = [];
  const newEventsVertical: BryntumEvent[] = [];

  let currentDate = new Date(startDate.getTime());
  // for each date in range
  let employeesOfInterest: Set<string> ;
  if (employeeIdSubsetToReturn !== null && employeeIdSubsetToReturn[0] !== undefined) {
    employeesOfInterest = new Set(assignments.map(x => x.employeeDocId).concat(...employeeIdSubsetToReturn));
  } else {
    employeesOfInterest = new Set(assignments.map(x => x.employeeDocId));
  }

  do {
   ([...employeesOfInterest].map(x => this.employeeService.get(x))).forEach(employee => {
    const verticalResource = new BryntumResourceDateSummation(employee);
    verticalResource.resourceDate = currentDate;
    verticalResource.displayDate = format(currentDate, 'E d');
    verticalResource.actualResourceId = employee.docId;
    verticalResource.id = currentDate.getTime() + employee.docId;
    verticalResource.employeeCreationDate = employee.createdAt;
    const employeeAvailability = this.employeeAvailabilityService.getAvailibilityForDate(currentDate, employee.DocId());
    verticalResource.employeeWorkStartTime = EmployeeAvailabilityService.convertTimeToSameTimeOnSpecifiedDate(this.employeeAvailabilityService.getEmployeeStartTimeForAvailibility(employeeAvailability, currentDate));
    verticalResource.employeeWorkEndTime = EmployeeAvailabilityService.convertTimeToSameTimeOnSpecifiedDate(this.employeeAvailabilityService.getEmployeeEndTimeForAvailibility(employeeAvailability, currentDate));
    if (employeeIdSubsetToReturn === null || employeeIdSubsetToReturn.includes(employee.docId)) {
      newResourcesVertical.push(verticalResource);
    }
  });

  // add site visits occurring on this date.
  const siteVisitsOnDay = sortSiteVisitsEventsByStartTime(siteVisits.filter(x => getDate(new Date(x.startDate)) === getDate(currentDate)));
  siteVisitsOnDay.forEach(siteVisit => {
    const associatedJob = jobs.find(x => x.siteVisits.map(a => a.docId).some(z => z === siteVisit.docId));
    if (associatedJob !== undefined) {
    siteVisit.jobDocId = associatedJob.jobDocId;
    siteVisit.siteVisitAddress = associatedJob.serviceAddress;
    }
  });
  const previousVisitPerAssignment = {};
  assignments.forEach(assign => previousVisitPerAssignment[assign.employeeDocId] = null);
  siteVisitsOnDay.forEach(siteVisit => {
    const currentAssignment = assignments.find(x => x.siteVisitDocId === siteVisit.docId);
    siteVisit.recalculateEndOfDayCommuteTime(true, this.physicalAddressRoutingService, this.settingsService, this.employeeService, currentAssignment.employeeDocId,
      this.siteVisitService);

    const previousSiteVisitOnDayForEmployee = previousVisitPerAssignment[currentAssignment.employeeDocId];
    if (previousSiteVisitOnDayForEmployee !== null) {
        previousSiteVisitOnDayForEmployee.recalculateEndOfDayCommuteTime(false, this.physicalAddressRoutingService,this.settingsService,
          this.employeeService, currentAssignment.employeeDocId );
    }
    previousVisitPerAssignment[currentAssignment.employeeDocId] = siteVisit;
  });
  assignments.forEach(assign => previousVisitPerAssignment[assign.employeeDocId] = null);

  siteVisitsOnDay.forEach(siteVisit => {
    const associatedJob = jobs.find(x => x.siteVisits.map(a => a.docId).some(z => z === siteVisit.docId));
    if (associatedJob !== undefined) {
      siteVisit.jobDocId = associatedJob.jobDocId;
      siteVisit.siteVisitAddress = associatedJob.serviceAddress;
      const currentAssignment = assignments.find(x => x.siteVisitDocId === siteVisit.docId);
      const previousSiteVisitOnDayForEmployee = previousVisitPerAssignment[currentAssignment.employeeDocId];
      const startTimeForEmployee = this.employeeAvailabilityService.getEmployeeStartTimeForDate(currentDate, currentAssignment.employeeDocId);
      // *****************   THIS SHOULDN'T BE NEEDED, WE CURRENTLY ARE NOT UPDATING THIS WHEN WE COMMIT THE SITE VISIT WHICH SEEMS LIKE IT SHOULD BE
      // THE RIGHT TIME TO DO IT.  LOOK INTO . ****************** //
      siteVisit.recalculateStartOfDayCommuteTime(previousSiteVisitOnDayForEmployee, this.physicalAddressRoutingService, this.settingsService, this.employeeService,
        currentAssignment.employeeDocId, startTimeForEmployee, this.siteVisitService);
      previousVisitPerAssignment[currentAssignment.employeeDocId] = siteVisit;
      const eventVertical = this.generateVerticalBryntumEventFromJobSiteVisit(associatedJob, siteVisit, mutateDates);
      if (siteVisitIdSubsetToReturn === undefined || siteVisitIdSubsetToReturn.includes(siteVisit.docId)) {
        newEventsVertical.push(eventVertical);
      }
      // add assigments for this event.
      assignments.filter(x => x.siteVisitDocId === siteVisit.docId).forEach(assignmentElement => {
        if (newEventsVertical.findIndex( x => x.siteVisitDocId === assignmentElement.siteVisitDocId) >= 0 &&
        (employeeIdSubsetToReturn === null || employeeIdSubsetToReturn.includes(assignmentElement.employeeDocId))) {
          const resourceId = newResourcesVertical.find(x => x.actualResourceId === assignmentElement.employeeDocId &&
            getDate(x.resourceDate) === getDate(currentDate))?.id;
          if (!newAssignmentsVertical.some(x => x.resourceId === resourceId && x.eventId === assignmentElement.siteVisitDocId)) {
            newAssignmentsVertical.push(new BryntumAssignment({resourceId, eventId: assignmentElement.siteVisitDocId}));
          }
        }
      });
    }
  });
  currentDate = addDays(currentDate, 1);
  } while (compareAsc(currentDate, endDate) <= 0);

  // resource object needs populated with fullness information derived from assigned events.
  newResourcesVertical = newResourcesVertical.map( vertResourceToUpdate =>
    this.updateVerticalResourceSummationInfo(vertResourceToUpdate, newAssignmentsVertical, newEventsVertical));
  newResourcesVertical.sort( (a,b) =>  {
    return  (a.resourceDate < b.resourceDate ? -1 : a.resourceDate > b.resourceDate ? 1 :
      a.employeeCreationDate.getTime() > b.employeeCreationDate.getTime() ? 1 : a.employeeCreationDate.getTime() < b.employeeCreationDate.getTime() ? -1 : 0);
    });
  newResourcesVertical = this.AddDayEndDecorationWhenNeeded(newResourcesVertical);

    // For each resource day, calculate if site visits are in errored state.
  const siteVisitsPerResourceDay = groupBy(newAssignmentsVertical, x => x.resourceId);
  siteVisitsPerResourceDay.forEach( (value: BryntumAssignment[], key) => {
  const bryntumEventsForResourceDay = newEventsVertical.filter(x => value.map(z => z.eventId).some(z => z === x.id));
  this.checkForErrors(bryntumEventsForResourceDay);
  // if there is an errored event, and active GUID was last to update an object check if schedule validates.  If so, move site visits to first availiable.
  const lastGuidToUpdateObject = jobs.map(x => x as RetrieveFirestoreProperties).concat(assignments.map(x => x as RetrieveFirestoreProperties)).sort((a,b) => {
    return a.lastUpdatedAt > b.lastUpdatedAt ? -1 : a.lastUpdatedAt < b.lastUpdatedAt ? 1 : 0})[0].lastUpdatedByGuid;

  if (lastGuidToUpdateObject === this.authenticationService.guid && bryntumEventsForResourceDay.find(x => x.errored)) {
    const expandedResourceInfo = newResourcesVertical.find(q => q.id === value[0].resourceId);
    let invalidSchedule = false;
    ({ invalidSchedule } = this.schedulingGuidanceService.CheckIfValidSchedulePossible(siteVisits.filter(x => bryntumEventsForResourceDay.find(n => n.siteVisitDocId === x.DocId())),
    expandedResourceInfo.actualResourceId, expandedResourceInfo.resourceDate, expandedResourceInfo.employeeWorkStartTime, expandedResourceInfo.employeeWorkEndTime, invalidSchedule, false, true));
    if (!invalidSchedule) {
      console.warn("WE WILL FIX SCHEDULE BY SETTING TO EARLIEST IN WINDOW.");
      this.schedulingGuidanceService.setSiteVisitsToEarliestPossible(siteVisits.filter(x => bryntumEventsForResourceDay.find(n => n.siteVisitDocId === x.DocId())),
        expandedResourceInfo.actualResourceId, expandedResourceInfo.resourceDate, false);
    } else {
      console.warn("INVALID SCHEDULE DECTECTED");
    }
  }
  });
  const retVal = new ByrntumData({assignments: newAssignmentsVertical, events: newEventsVertical, resources: newResourcesVertical} );
  return {byrntumData: retVal, assignments, siteVisits, jobs};
}

populateServiceArraysFromBryntumData(val: {byrntumData : ByrntumData, assignments: Assignment[], siteVisits: SiteVisit[], jobs: Job[]})
{
  this.bryntumAssignments = val.byrntumData.assignments.slice();
  this.resourcesVertical = val.byrntumData.resources.slice();
  this.eventsVertical = val.byrntumData.events.slice();
  this.assignments = val.assignments.slice();
  this.siteVisits = val.siteVisits.slice();
  this.jobs = val.jobs.slice();
}

  private checkForErrors(events: BryntumEvent[]) {
    events = sortBryntumEventsByStartTime(events);
    let preceedingEventCompletionTime: Date;
    let i = 0;
    events.forEach(event => {
      if (preceedingEventCompletionTime !== undefined && preceedingEventCompletionTime.getTime() > event.startDate.getTime()) {
        event.setErrored();
      } else {
        if (events[i + 1] !== undefined && event.endDate.getTime() > events[i + 1].startDate.getTime()) {
          event.setErrored();
        }
      }
      preceedingEventCompletionTime = preceedingEventCompletionTime === undefined ?  event.endDate :
      addMinutes(preceedingEventCompletionTime, event.commuteMinutesToSite + event.durationMinutes).getTime() >
        event.endDate.getTime() ? addMinutes(preceedingEventCompletionTime, event.commuteMinutesToSite + event.durationMinutes) :
         event.endDate;
      i++;
    });
  }

  private AddDayEndDecorationWhenNeeded(bryntumResourceDateSummations: BryntumResourceDateSummation[]): BryntumResourceDateSummation[] {
  // tslint:disable-next-line:forin
  for (const i in bryntumResourceDateSummations) {
    const nextElementIndex = (Number(i) + 1).toString();
    if (Number(i) < bryntumResourceDateSummations.length - 1 &&
      bryntumResourceDateSummations[i].actualResourceId !== bryntumResourceDateSummations[nextElementIndex].actualResourceId &&
      bryntumResourceDateSummations[i].displayDate !== bryntumResourceDateSummations[nextElementIndex].displayDate) {
    bryntumResourceDateSummations[i].displayAttributes = 'day-end';
      }
  }
  return bryntumResourceDateSummations;
}

  AddTimePortionFromActualToVerticalDate(actualEventDate: Date): Date {
  let retDate = addHours( BryntumResourceDateSummation.verticalDate, getHours(actualEventDate));
  retDate = addMinutes(retDate, getMinutes(actualEventDate));
  return retDate;
}

  private AddTimePortionFromVerticalDateToDatePortionFromResourceDate(verticalDateRepresentation: Date, resourceDate: Date): Date {
  let retDate = addHours(startOfDay(resourceDate), getHours(verticalDateRepresentation));
  retDate = addMinutes(retDate, getMinutes(verticalDateRepresentation));
  return retDate;
}

  private getSiteVisitStartEnd(bryntumStartTime: Date, bryntumEndTime: Date, actualDate: Date): {siteVisitStart, siteVisitEnd} {
    const siteVisitStart = this.AddTimePortionFromVerticalDateToDatePortionFromResourceDate(bryntumStartTime, actualDate);
    const siteVisitEnd = this.AddTimePortionFromVerticalDateToDatePortionFromResourceDate(bryntumEndTime, actualDate);
    return {siteVisitStart, siteVisitEnd};
  }
  public createSiteVisitFromBryntumTimes(jobDocId: string,  bryntumStartTime: Date, bryntumEndTime: Date,
  actualDate: Date, employeeDodId: string ) : SiteVisit {
    const timeBounds = this.getSiteVisitStartEnd(bryntumStartTime, bryntumEndTime, actualDate);
    const retVal = new SiteVisit({startDate: timeBounds.siteVisitStart,endDate: timeBounds.siteVisitEnd, jobDocId, arrivalWindowStartDate : timeBounds.siteVisitStart});
    const assignment = new Assignment({employeeDocId: employeeDodId, siteVisitDocId: retVal.docId,
      siteVisitStartDate: timeBounds.siteVisitStart,employeeAssigningDocId: this.authenticationService.activelyLoggedInEmployeeDocId, jobDocId: jobDocId});
    this.siteVisitSchedulingService.generateArrivalWindow(retVal,assignment);
    return retVal;
  }

  public getCommuteDeltaMaxAssociatedWithSliderPercent(percentSlid: number) : number {
    if (percentSlid !== undefined && this.resourceAvailibility !== undefined && this.resourceAvailibility.length !== 0 && this._resourceAvailibilityDistinctCommute.length > 0 ) {
      percentSlid = 100 - percentSlid;
      if (percentSlid === 100) {
        return 1440;
      } else {
      if (this._resourceAvailibilityDistinctCommute.length > 1) {
      let index = Math.floor((this._resourceAvailibilityDistinctCommute.length) * percentSlid / 100);
      index = index === this._resourceAvailibilityDistinctCommute.length ? index - 1 : index;
      return this._resourceAvailibilityDistinctCommute[index].commuteMinutesDelta;
      } else {
        return this._resourceAvailibilityDistinctCommute[0].commuteMinutesDelta;
      }
      }
    } else {
      return 1440;
    }
  }

  // If a site visit is moved to a different day, this method will update the site visits on original day to properly reflect commute and
  // arrival times.
  private reconcileSiteVisitsOnDay(currentSiteVisitEmployeeDocId: string, siteVisitPreModification: SiteVisit, employeePreModification: string, batch: string) : Observable<string> {

    const siteVisitDayBeingConsidered = startOfDay(siteVisitPreModification.startDate);
    const availibility = this.employeeAvailabilityService.getAvailibilityForDate(siteVisitDayBeingConsidered, currentSiteVisitEmployeeDocId);
    if (this.siteVisitPreModification.assignment.employeeDocId === currentSiteVisitEmployeeDocId) {
      this.assignmentUpdateReadSinceLastEmission$.next(true);
    }

    const newSiteVisit = this.retrieveSiteVisitsForGivenEmployeeDay(currentSiteVisitEmployeeDocId, siteVisitDayBeingConsidered).find(x => x.DocId() === siteVisitPreModification.DocId());
    // we will not attempt to reconcile site visits that are explicitly errored.
    if (newSiteVisit && newSiteVisit.explicitErrored) {
      return of(batch);
    }

    // If same day, and same employee, then no need to reconcile.
    if (newSiteVisit && startOfDay(newSiteVisit.startDate).getTime() === startOfDay(siteVisitPreModification.startDate).getTime() && currentSiteVisitEmployeeDocId === employeePreModification) {
      console.warn("Same resource day, no need to reconcile.");
      return of(batch);
    }

    console.warn("RECONCILE ON DAY");

    const siteVisits = this.retrieveSiteVisitsForGivenEmployeeDay(currentSiteVisitEmployeeDocId, siteVisitDayBeingConsidered).filter(x => x.DocId() !== siteVisitPreModification.DocId());
    const needRetrievedFromDexie = this.orderSiteVisitsAndRetrieveInformationFromDexieNeededForCommute(siteVisits);

    return forkJoin(needRetrievedFromDexie).pipe(
      map(() => {
        const siteVisitsNeedingUpdate: SiteVisit[] = [];
        let svIndex: number = 0;
        siteVisits.forEach(sv => {
      let commute: Commute;
      if (svIndex > 0) {
        // need to ensure that either the address for origin or destination has been mapped, else map destination.
        commute = sv.retrieveProspectiveCommuteBeforeSiteVisit(this.physicalAddressRoutingService, this.settingsService, this.employeeService,
          currentSiteVisitEmployeeDocId, this.employeeAvailabilityService.getEmployeeStartTimeForAvailibility(availibility,siteVisitDayBeingConsidered),siteVisits[svIndex - 1] );
        }
        else {
          commute = sv.retrieveProspectiveCommuteBeforeSiteVisit(this.physicalAddressRoutingService, this.settingsService, this.employeeService,
            currentSiteVisitEmployeeDocId, this.employeeAvailabilityService.getEmployeeStartTimeForAvailibility(availibility,siteVisitDayBeingConsidered) );
        }
        const existingCommuteToSite = sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commute.destinationAddressDocId);
        if (existingCommuteToSite && existingCommuteToSite.orginAddressDocId !== commute.orginAddressDocId) {
          sv.prospectiveCommutes.splice(sv.prospectiveCommutes.indexOf(sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commute.destinationAddressDocId)), 1, commute);
              siteVisitsNeedingUpdate.push(sv);
        } else if (!existingCommuteToSite) {
          sv.prospectiveCommutes.push(commute);
          siteVisitsNeedingUpdate.push(sv);
        }
        // Ensure that commute from site back to tech's dispatch destination is included if last site visit of day, and is spliced off if not last site visit of day.
        // If last site visit of day.
        if (svIndex === siteVisits.length - 1) {
          const commuteBackToDispatch = sv.retrieveEndOfDayCommuteTime(true, this.physicalAddressRoutingService, this.settingsService, this.employeeService,
            currentSiteVisitEmployeeDocId, this.siteVisitService);
          const existingCommuteToDispatch = sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commuteBackToDispatch.destinationAddressDocId);
          if (existingCommuteToDispatch && existingCommuteToDispatch.destinationAddressDocId !== commuteBackToDispatch.destinationAddressDocId) {
            sv.prospectiveCommutes.splice(sv.prospectiveCommutes.indexOf(sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commuteBackToDispatch.destinationAddressDocId)), 1, commuteBackToDispatch);
            siteVisitsNeedingUpdate.push(sv);
          } else if (!existingCommuteToDispatch) {
            sv.prospectiveCommutes.push(commuteBackToDispatch);
            siteVisitsNeedingUpdate.push(sv);
          }
        } else {
          const existingCommuteToDispatch = sv.prospectiveCommutes.find(x => x.orginAddressDocId === sv.siteVisitAddress.docId);
          if (existingCommuteToDispatch) {
            sv.prospectiveCommutes.splice(sv.prospectiveCommutes.indexOf(sv.prospectiveCommutes.find(x => x.orginAddressDocId === sv.siteVisitAddress.docId)), 1);
            siteVisitsNeedingUpdate.push(sv);
          }
        }
        svIndex++;
      });
      console.log(siteVisitsNeedingUpdate.map(z => z.prospectiveCommutes.map(m => m.commuteStartTime )));
      return siteVisitsNeedingUpdate;
    }),
    map(x => {
      if (x.length > 0) {
        return x.map(mod => {
          const j = this.jobs.filter(q => q.siteVisits.filter(w => w.DocId() === mod.DocId()).length > 0);

          if (j.length === 0) {
            console.log(this.jobs.flatMap(j => j.siteVistDocIds));
            console.log(this.jobs.map(j => j.DocId()));
            console.log(mod);
            return of(null);
          } else {
            j[0].siteVisits.splice(
              j[0].siteVisits.indexOf(j[0].siteVisits.find(q => q.DocId() === mod.DocId())), 1, mod);
            return this.jobService.update$(j[0], batch).pipe(take(1));
          }
        });
      } else {
        return [of(null)];
      }
    }),
    switchMap(z => forkJoin(z)),
    map(() => batch)
    );
  }


  /**
   * Adds commute entries between the newly added or updated site visit, and other site visits on same resource day if needed.
   * @param employeeDocId
   * @param day
   * @param siteVisitAddress
   * @param siteVisitPreModification
   * @param batch
   * @param siteVisitBeingAddedToResourceDay
   * @returns
   */
  private updateResourceDayRouting(employeeDocId: string, day: Date, siteVisitAddress: Address, siteVisitPreModification: SiteVisit | null, batch: string | null, siteVisitBeingAddedToResourceDay: SiteVisit) : Observable<string> {

    return of("hotdogs");
    let existingResourceDay : ResourceDay | null = null;
    const resourceDay = startOfDay(day);

    const rawResourceDay = this.physicalAddressRoutingService.addressRoutingService.retrieveResourceDayFromDixie(resourceDay, employeeDocId).pipe(
      share()
    );

    const resourceDayExists = rawResourceDay.pipe(
    filter(x => x.length > 0),
    map(x => x[0]),
    );

    const resourceDayDaysNotExist = rawResourceDay.pipe(
    filter(x => x.length === 0),
    map(() =>  new ResourceDay({ employeeDocId: employeeDocId, resourceDay: resourceDay })),
    switchMap(x => this.resourceDayService.create$(x))
    );

    const retrieveExistingResourceDay = merge(resourceDayExists, resourceDayDaysNotExist).pipe(
    map(x => {
      x.lastUpdatedAt = new Date();
      return x;
    }),
    tap(x => existingResourceDay = x),
    );

    const siteVisitsAssignedResourceDay = this.retrieveSiteVisitsForGivenEmployeeDay(employeeDocId, resourceDay)
    .filter(x => x.DocId() !== siteVisitPreModification?.DocId() && x.siteVisitAddress.docId !== siteVisitPreModification?.siteVisitAddress.docId);
    const siteVisitAddressesAssignedResourceDay = siteVisitsAssignedResourceDay.map(x => x.siteVisitAddress.docId);
    const employee = this.employeeService.get(employeeDocId);
    siteVisitAddressesAssignedResourceDay.push(employee.dispatchDestinationAddress.DocId());
    if (!siteVisitAddressesAssignedResourceDay.includes(employee.dispatchOrginAddress.DocId())) {
      siteVisitAddressesAssignedResourceDay.push(employee.dispatchOrginAddress.DocId());
    }
    if (!siteVisitAddressesAssignedResourceDay.includes(this.settingsService.companySettings.dispatchAddressDocId)) {
      siteVisitAddressesAssignedResourceDay.push(this.settingsService.companySettings.dispatchAddressDocId);
    }
    siteVisitAddressesAssignedResourceDay.push(siteVisitAddress.DocId());

    const retVal = ( retrieveExistingResourceDay.pipe(
      concatMap(() => this.physicalAddressRoutingService.retrieveOnDemandFromDixie(siteVisitAddress,true,false)),
      // Limit mapped commute routing to those which are between the site visit address added to resource day, other addresses which occur on resource day, and employee's dispatch orgin / destination addresses.
      take(1),
      map(() =>  [...this.physicalAddressRoutingService.orginAddressIdToAssociatedCommutes
        .get(siteVisitAddress.docId).values()]),
        // both orgin and destination addresses must be in site visit addresses assigned to resource day, else we are not interested in caching value here.
      map(routes => routes.filter(r => siteVisitAddressesAssignedResourceDay.includes(r.destinationAddressDocId) && siteVisitAddressesAssignedResourceDay.includes(r.originAddressDocId))),
      map(routing => {
        const retVal = [];
        routing.forEach(r => {
          const {orginAddress,destinationAddress,docId: _, ...rest} = r;
          if (rest.distanceMeters === undefined) {
            rest.distanceMeters=0;
          }
          retVal.push(new ResourceDayAddressRouting({ employeeDocId: employeeDocId, resourceDay: resourceDay, ...rest }));
        });
        return retVal;
      }),
      // retrieve resource address routing entries that already exist for given day.
      map(routing => {
        const routingToAdd = routing.filter(r => !this.physicalAddressRoutingService.addressRoutingService.cachedResourceDayAddressRouting.find(x => x.resourceDay.getTime() === r.resourceDay.getTime() &&
        x.employeeDocId === r.employeeDocId && x.originAddressDocId === r.originAddressDocId && x.destinationAddressDocId === r.destinationAddressDocId));
        const existingGeofenceAddresses = new Set(this.physicalAddressRoutingService.addressRoutingService.cachedResourceDayAddressRouting.filter(x => x.resourceDay.getTime() === resourceDay.getTime() &&
        x.employeeDocId === employeeDocId).flatMap(x => [x.originAddressDocId,x.destinationAddressDocId]));
        return {add: routingToAdd, exists: existingGeofenceAddresses};
      }),
      concatMap(x => this.resourceDayAddressRoutingService.createMultiple$(x.add, batch).pipe(
        map(added => {
          return {added, exists: x.exists};
        })
      )),
      tap(x => this.resourceDayService.atomicallyIncrementNumberEntries(existingResourceDay, x.added.length, batch)),
      // for each distinct orgin / destination added, ensure that geofence entry exists for employee.
      map(routing => {
        const distinctOrginDestination = routing.added.map(x => x.originAddressDocId).concat(routing.added.map(x => x.destinationAddressDocId)).filter((value, index, self) => self.indexOf(value) === index);
        const geofenceEntriesToAdd = distinctOrginDestination.filter(
            x => !routing.exists.has(x))
          .map(x => {
            const retVal = new EmployeeGeofence({addressDocId: x, employeeDocId: employeeDocId, dateMonitoring: resourceDay});
            if (siteVisitBeingAddedToResourceDay.siteVisitAddress.DocId() === x) {
              retVal.siteVisitDocId = siteVisitBeingAddedToResourceDay.DocId();
            }
            return retVal;
          });
        return geofenceEntriesToAdd;
      }),
      // get lat and long for each address being added to geofence.
      concatMap(geofenceEntriesToAdd => this.addressService.queryFirestoreForInValues("docId",geofenceEntriesToAdd.map(x => x.addressDocId)).pipe(
        map(addresses => {
          const retVal = [];
          addresses.forEach(address => {
            const associatedGeofenceEntry = geofenceEntriesToAdd.find(x => x.addressDocId === address.docId);
            associatedGeofenceEntry.latitude = address.geoCoordinates.Latitude;
            associatedGeofenceEntry.longitude = address.geoCoordinates.Longitude;
            retVal.push(associatedGeofenceEntry);
          });
          return retVal;
        }))),
      // add geofence entries to firestore.
      concatMap(geofenceEntriesToAdd => this.employeeGeofenceService.createMultiple$(geofenceEntriesToAdd, batch)),
      concatMap( () => this.resourceDayService.mergeUpdate(existingResourceDay, ["dateModified"], batch, true) ),
      tap(x => console.log(x,` string`)),
      take(1),
      map(() => batch),
      catchError(err => {
      console.error('Error caught in observable.', err);
      return throwError(err);
      })
    ) as Observable<string>);
    return retVal;
  }

  /**
   * Orders passed in site visits by starting time, stubbed out code to retrieve any missing commute matrix entries from Dexie.
   * @param siteVisits
   * @returns of([])
   */
  private orderSiteVisitsAndRetrieveInformationFromDexieNeededForCommute(siteVisits: SiteVisit[]) : Observable<AddressRoutingLocal[]>[] {
    const addressesAwaitingObservationFromDexie: string[] = [];
    siteVisits = sortSiteVisitsEventsByStartTime(siteVisits);
    const needRetrievedFromDexie: Observable<AddressRoutingLocal[]>[] = [of([])];
    return needRetrievedFromDexie;
    // I am leaving this in code base for moment, as it *may* prove useful somewhere down the road, though we currently are just ordering the site visits and returning here. <SRR>

    // let svIndex: number = 0;
    // siteVisits.forEach(sv => {
    //   if (svIndex > 0) {
    //     // need to ensure that either the address for origin or destination has been mapped, else map destination.
    //     if (!this.addressRoutingService.addressRoutingService.catchedOriginAddressDocIds.includes(sv.siteVisitAddress.docId) &&
    //       !this.addressRoutingService.addressRoutingService.catchedOriginAddressDocIds.includes(siteVisits[svIndex - 1].siteVisitAddress.docId) &&
    //       !addressesAwaitingObservationFromDexie.includes(sv.siteVisitAddress.docId) &&
    //       !addressesAwaitingObservationFromDexie.includes(siteVisits[svIndex - 1].siteVisitAddress.docId)) {
    //         console.warn(sv.siteVisitAddress.formattedAddress(), siteVisits[svIndex - 1].siteVisitAddress.formattedAddress())
    //       needRetrievedFromDexie.push(this.addressRoutingService.addressRoutingService.retrieveOnDemandFromDixie(sv.siteVisitAddress));
    //       addressesAwaitingObservationFromDexie.push(sv.siteVisitAddress.docId);
    //     }
    //   }
    //   svIndex++;
    // });
    // return needRetrievedFromDexie;
  }

  ensureFullMappingPresentToDragSiteVisitSameDay(siteVisit : SiteVisit) : Observable<AddressRoutingLocal[]>[] {
    const dayInQuestion = startOfDay(siteVisit.startDate);
    const employeeDocId = this.assignments.find(x => x.siteVisitDocId === siteVisit.docId).employeeDocId;
    const siteVisitsPreReconcile = this.retrieveSiteVisitsForGivenEmployeeDay(employeeDocId, dayInQuestion);
    return siteVisitsPreReconcile.map(sv => this.physicalAddressRoutingService.retrieveOnDemandFromDixie(sv.siteVisitAddress));
  }

  private reconcileSiteVisits( newSiteVisit: SiteVisit, employeeSiteVisitAssignedToDocId: string, batch: string | null, newSiteVisitNumber: number | null):
  Observable<{batch: string, newSiteVisit: SiteVisit}> {

    console.warn("reconcile site visits called.");
    console.log({...newSiteVisit});
    const dayInQuestion = startOfDay(newSiteVisit.startDate);
    const employeeAvailability = this.employeeAvailabilityService.getAvailibilityForDate(dayInQuestion, employeeSiteVisitAssignedToDocId);
    const siteVisitsPreReconcile = this.retrieveSiteVisitsForGivenEmployeeDay(employeeSiteVisitAssignedToDocId, dayInQuestion).map(x=>x);
    let newSiteVisitInitialLockStartTime : boolean = undefined;
    let newSiteVisitInitialArrivalWindowStartDate = new Date(newSiteVisit.arrivalWindowStartDate.getTime());
    let newSiteVisitInitialArrivalWindowEndDate = new Date(newSiteVisit.arrivalWindowEndDate.getTime());
    siteVisitsPreReconcile.forEach(x => {
      x.startDate = new Date(x.startDate);
      x.endDate = new Date(x.endDate);

    })
    if (siteVisitsPreReconcile.findIndex(q => q.docId === newSiteVisit.docId) === -1) {
      const newSv = new SiteVisit(newSiteVisit);
      newSv.startDate = new Date(newSv.startDate.getTime());
      newSv.endDate = new Date(newSv.endDate.getTime());
      siteVisitsPreReconcile.push(newSv);
    } else {
      const newSv = siteVisitsPreReconcile.find(q => q.docId === newSiteVisit.docId);
      newSv.startDate = new Date(newSv.startDate.getTime());
      if (newSv.explicitErrored) {
        return of({batch,newSiteVisit});
      } else {
        newSiteVisitInitialLockStartTime = newSv.lockStartTime;
        newSv.lockStartTime = true;
      }
    }

    let siteVisitsToReconcile = this.retrieveSiteVisitsForGivenEmployeeDay(employeeSiteVisitAssignedToDocId, dayInQuestion)
      .filter(x => x.docId !== newSiteVisit.docId).map(x => new SiteVisit(x));
      // We need to ensure that prospective commute array is newed up, otherwise we can not properly determine if commutes have been updated.
      siteVisitsToReconcile.forEach(s => {
        const prospects = s.prospectiveCommutes;
        s.prospectiveCommutes = [];
        prospects.forEach(p => {
          s.prospectiveCommutes.push(p);
        });
      });
    siteVisitsToReconcile.push(newSiteVisit);
    return forkJoin(this.orderSiteVisitsAndRetrieveInformationFromDexieNeededForCommute(siteVisitsToReconcile)).pipe(
      switchMap( () => {
        const prospectiveValidSchedule = this.schedulingGuidanceService.retrieveProspectiveSiteVisitAssignments(newSiteVisit,
          siteVisitsToReconcile.filter(x => x.docId !== newSiteVisit.docId),
          this.assignments.filter(x => x.employeeDocId === employeeSiteVisitAssignedToDocId), [dayInQuestion],
          [employeeSiteVisitAssignedToDocId],newSiteVisit.minimizeSiteVisitMutationsOnReconciliation, true );
          // ** START SCHEDULED VALIDATED
        if (prospectiveValidSchedule !== null && prospectiveValidSchedule.schedulingGuidance[0] !== undefined) {
          const allProspectiveSiteVisits = prospectiveValidSchedule.schedulingGuidance[0].siteVisits;
          if (newSiteVisitInitialLockStartTime !== undefined) {
            const newSv = allProspectiveSiteVisits.find(q => q.docId === newSiteVisit.docId);
            newSv.lockStartTime = newSiteVisitInitialLockStartTime;
            newSv.arrivalWindowStartDate = newSiteVisitInitialArrivalWindowStartDate;
            newSv.arrivalWindowEndDate = newSiteVisitInitialArrivalWindowEndDate;
            const preReqSV = siteVisitsPreReconcile.find(q => q.docId === newSiteVisit.docId);
            preReqSV.lockStartTime = newSiteVisitInitialLockStartTime;
          }
          console.log(allProspectiveSiteVisits);
          const siteVisitsNeedingUpdate = allProspectiveSiteVisits.filter(x =>
            {
            const prospect =  siteVisitsPreReconcile.find(q => (q.docId === x.docId) || (q.docId === newSiteVisit.docId && x.prospectiveSiteVisit));
            const retVal =  prospect.prospectiveCommutes.length === 0 ||
            SiteVisit.siteVisitTimeOrCommuteToSiteVisitChanged(x, prospect)
            return retVal;
            });
          const needRetrievedFromDexie = this.orderSiteVisitsAndRetrieveInformationFromDexieNeededForCommute(allProspectiveSiteVisits);
          return forkJoin(needRetrievedFromDexie).pipe(
            map(() => {
              siteVisitsNeedingUpdate.forEach(sv => {
                const svIndex = allProspectiveSiteVisits.findIndex(f => f.siteVisitAddress.docId === sv.siteVisitAddress.docId);
                let commute: Commute;
                if (svIndex > 0) {
                  // need to ensure that either the address for origin or destination has been mapped, else map destination.

                  commute = sv.retrieveProspectiveCommuteBeforeSiteVisit(this.physicalAddressRoutingService, this.settingsService, this.employeeService,
                    employeeSiteVisitAssignedToDocId, this.employeeAvailabilityService.getEmployeeStartTimeForAvailibility(employeeAvailability,dayInQuestion),
                    allProspectiveSiteVisits[svIndex - 1] );
                  }
                  else if (svIndex === 0) {
                    commute = sv.retrieveProspectiveCommuteBeforeSiteVisit(this.physicalAddressRoutingService, this.settingsService, this.employeeService,
                      employeeSiteVisitAssignedToDocId, this.employeeAvailabilityService.getEmployeeStartTimeForAvailibility(employeeAvailability,dayInQuestion) );
                  }

                  if (sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commute.destinationAddressDocId)) {
                    sv.prospectiveCommutes.splice(
                        sv.prospectiveCommutes.indexOf(sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commute.destinationAddressDocId)), 1, commute);
                  } else {
                    sv.prospectiveCommutes.push(commute);
                  }

                  // Ensure that commute from site back to tech's dispatch destination is included if last site visit of day, and is spliced off if not last site visit of day.
                  // If last site visit of day.
                  if (svIndex === allProspectiveSiteVisits.length - 1) {
                    const commuteBackToDispatch = sv.retrieveEndOfDayCommuteTime(true, this.physicalAddressRoutingService, this.settingsService, this.employeeService,
                      employeeSiteVisitAssignedToDocId, this.siteVisitService);
                    const existingCommuteToDispatch = sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commuteBackToDispatch.destinationAddressDocId);
                    if (existingCommuteToDispatch && existingCommuteToDispatch.destinationAddressDocId !== commuteBackToDispatch.destinationAddressDocId) {
                      sv.prospectiveCommutes.splice(sv.prospectiveCommutes.indexOf(sv.prospectiveCommutes.find(x => x.destinationAddressDocId === commuteBackToDispatch.destinationAddressDocId)), 1, commuteBackToDispatch);
                    } else if (!existingCommuteToDispatch) {
                      sv.prospectiveCommutes.push(commuteBackToDispatch);
                    }
                  } else {
                    const existingCommuteToDispatch = sv.prospectiveCommutes.find(x => x.orginAddressDocId === sv.siteVisitAddress.docId);
                    if (existingCommuteToDispatch) {
                      sv.prospectiveCommutes.splice(sv.prospectiveCommutes.indexOf(sv.prospectiveCommutes.find(x => x.orginAddressDocId === sv.siteVisitAddress.docId)), 1);
                    }
                  }

                });
                console.log(siteVisitsNeedingUpdate.map(z => z.prospectiveCommutes.map(m => m.commuteStartTime )));
                return siteVisitsNeedingUpdate;
            }),
            tap(x => console.warn(x,` string`)),
            map(updatedSiteVisits => {
              const updatedNewSiteVisit = updatedSiteVisits.find(q => q.DocId() === newSiteVisit.DocId());
              if (updatedNewSiteVisit) {
                updatedSiteVisits = updatedSiteVisits.filter(q => q.DocId() !== updatedNewSiteVisit.DocId());
              }
              return {updatedSiteVisits, updatedNewSiteVisit}
            }),
            concatMap(x => forkJoin(x.updatedSiteVisits.map(
              mod => {
                const j = this.jobs.filter(q => q.siteVisits.filter(w => w.DocId() === mod.DocId()).length > 0);
                if (j.length === 0) {
                  console.log(this.jobs.flatMap(j => j.siteVistDocIds));
                  console.log(this.jobs.map(j => j.DocId()));
                  console.log(mod);
                  return of(null);
                } else {
                  j[0].siteVisits.splice(
                    j[0].siteVisits.indexOf(j[0].siteVisits.find(q => q.DocId() === mod.DocId())), 1, mod);
                  return this.jobService.update$(j[0], batch).pipe(take(1));
                }
              }).concat(of(null))).pipe(
              map(() => {
                return {batch, newSiteVisit: x.updatedNewSiteVisit};
              })
            ))
          )
      } else {
        console.warn('schedule not valid');
        // If schedule doesn't validate, we consider moving start time to (close) valid start time so that it does.
        const siteVisitWithoutSetStart = new SiteVisit(newSiteVisit);
        siteVisitWithoutSetStart.startDate = undefined;
        siteVisitWithoutSetStart.lockStartTime = newSiteVisitInitialLockStartTime;
        const preReqSV = siteVisitsPreReconcile.find(q => q.docId === newSiteVisit.docId);
            preReqSV.lockStartTime = newSiteVisitInitialLockStartTime;
        const prospectiveValidScheduleForDay = this.schedulingGuidanceService.retrieveProspectiveSiteVisitAssignments(siteVisitWithoutSetStart,
          siteVisitsToReconcile.filter(x => x.docId !== newSiteVisit.docId && x.prospectiveSiteVisit === false),
          this.assignments.filter(x => x.employeeDocId === employeeSiteVisitAssignedToDocId), [dayInQuestion],
          [employeeSiteVisitAssignedToDocId], true, true );
        if (prospectiveValidScheduleForDay !== null) {
          const updatedStartDate = this.resourceAvailibilityService.findClosestAvailiable(newSiteVisit.startDate,
          prospectiveValidScheduleForDay.resourceAvailibility.find(q => q.CalcuationModel=== RESOURCE_AVAILIBILITY_CALCULATION_METHOD.DEFAULT).ResourceAvailibility);
          if (updatedStartDate.getTime() !== newSiteVisit.startDate.getTime()) {
          const minutesDiff = differenceInMinutes(updatedStartDate, newSiteVisit.startDate);
          newSiteVisit.startDate = new Date(updatedStartDate);
          newSiteVisit.endDate = addMinutes(newSiteVisit.endDate, minutesDiff);
          return this.reconcileSiteVisits(newSiteVisit, employeeSiteVisitAssignedToDocId, batch, newSiteVisitNumber);
          }
        }

        // When schedule doesn't validate, we must consider whether arrival windows for placed site visit
        // need mutated seperate from the question of building a valid schedule.
        if (newSiteVisit.startDate.getTime() < newSiteVisit.arrivalWindowStartForScheduling.getTime() ||
            newSiteVisit.startDate.getTime() > newSiteVisit.arrivalWindowEndForScheduling.getTime()) {
              const arrivalSettings = this.schedulingGuidanceService.setArrivalWindows(newSiteVisit, dayInQuestion, this.assignments.filter(x => x.siteVisitDocId === newSiteVisit.docId)[0]);
              newSiteVisit.arrivalWindowStartDate = arrivalSettings.arrivalWindowStart;
              newSiteVisit.arrivalWindowEndDate = arrivalSettings.arrivalWindowEnd;
              return of({batch,newSiteVisit});
        } else {
          return of({batch,newSiteVisit: undefined});
           }
        }
      }));
  }


  public AssignSiteVisitToEmployee(jobDocId: string, employeeDocId: string, newSiteVisit: SiteVisit, actualDate: Date, wb: string, oldAssignment: Assignment = null, siteVisitNumber: number | null ): Observable<string> {

    if (actualDate.getHours() !== 0 || actualDate.getMinutes() !== 0) {
      actualDate = subMinutes(actualDate, actualDate.getMinutes() + actualDate.getHours() * 60);
    }
    const jobToUpdate = this.jobs.find(x => x.jobDocId === jobDocId);
    newSiteVisit.siteVisitAddress = jobToUpdate.serviceAddress;

    this.hoursDeltaAgainstOnDeckJob = newSiteVisit.expectedDurationHours;
    if (jobToUpdate.remainingToScheduleHours - newSiteVisit.expectedDurationHours < .016666) {
      jobToUpdate.needsAssigned = false;
    }

    let getSiteVisitDocIdIfNeeded$: Observable<any> = of(void(0));
    let updateOldAssignmentReq$ : Observable<any> = of(void(0));

    if (newSiteVisit.docId === undefined) {
      getSiteVisitDocIdIfNeeded$ = getSiteVisitDocIdIfNeeded$.pipe(
        switchMap(() => this.siteVisitService.retrieveDocId(newSiteVisit))
      );
    }

    if (oldAssignment !== null) {
      updateOldAssignmentReq$ = updateOldAssignmentReq$.pipe(
        switchMap(() => this.assignmentService.update$(oldAssignment,wb)
      ));
    }

    const siteVisitAdded = getSiteVisitDocIdIfNeeded$.pipe(
      switchMap(() => this.assignmentService.create$(
      new Assignment({employeeDocId, siteVisitDocId: newSiteVisit.docId,
                      siteVisitStartDate: newSiteVisit.startDate,
                      employeeAssigningDocId: this.authenticationService.activelyLoggedInEmployeeDocId,
                    jobDocId: newSiteVisit.jobDocId}), wb)),
        tap(assignment => {
          newSiteVisit.assignments = [assignment];
          this.assignmentModification$.next(true);
      }),
        map(() => this.jobService.addSiteVisitToJob(newSiteVisit, jobToUpdate)),
        map(job => {
          if (job.siteVisitScheduledHours  > job.durationExpectedHours) {
            return this.addTimeDiffToJob(job, (job.siteVisitScheduledHours  - job.durationExpectedHours),
            JobDurationDeltaModificationType.SITEVISITCREATED, job.siteVisits.slice(job.siteVisits.length - 1)[0].docId);
          } else {
            return job;
          }
        })
        );

    console.warn("FROM ASSIGN SITE VISIT TO EMPLOYEE", newSiteVisit.prospectiveSiteVisit, newSiteVisit.docId);
    return updateOldAssignmentReq$.pipe(
    switchMap(() => this.reconcileSiteVisits(newSiteVisit, employeeDocId, wb, siteVisitNumber )),
      map(siteVisitsNeedingUpdate => {
        if (siteVisitsNeedingUpdate.newSiteVisit !== undefined) {
          newSiteVisit = siteVisitsNeedingUpdate.newSiteVisit;
          if (jobToUpdate.siteVisits.findIndex(y => y.docId === newSiteVisit.docId) > -1) {
            jobToUpdate.siteVisits.splice(jobToUpdate.siteVisits.findIndex(y => y.docId === newSiteVisit.docId), 1, newSiteVisit);
          }
        }
      }),
      concatMap(() => siteVisitAdded),
      concatMap(job => this.jobService.update$(job, wb)),
      concatMap(() => this.updateResourceDayRouting(employeeDocId, actualDate, newSiteVisit.siteVisitAddress,null, wb, newSiteVisit)),
      map(() => wb),
    );
}

  public retrieveSiteVisitsForGivenEmployeeDay(employeeDocId: string, day: Date) {
    const existingSiteVisitsForDay = this.siteVisits.filter(x => x.startDate?.toDateString() === day.toDateString());
    const existingAssignmentsForEmployeeDay = this.assignments
            .filter(x => existingSiteVisitsForDay.some(y => y.docId === x.siteVisitDocId) && x.employeeDocId === employeeDocId);
    return existingSiteVisitsForDay.filter(x => existingAssignmentsForEmployeeDay
            .some(y => y.siteVisitDocId === x.docId));
  }


  public updateEvent(schedulerEvent: any): {job: Job, siteVisit: SiteVisit} {
    if (!schedulerEvent.record.readOnly !== undefined && !schedulerEvent.record.readOnly) {
      const record = schedulerEvent.record;
      const currentJob = this.jobs.find(x => x.jobDocId === record.jobDocId);
      let currentSiteVisit: SiteVisit = null;
      let modifiedEventExcludingTime = false;
      let modifiedTime = false;
      const associcatedVerticalEvent = this.eventsVertical.find(x => x.id === schedulerEvent.record.id);
      let initialSiteVisitDurationHours: number;
      // When user drags suggested event length to resize, or moves suggested event to another location it
      // is we must add it b/f we attempt to update it.
      if (associcatedVerticalEvent === undefined) {
          modifiedEventExcludingTime = true;
          const startOfActualDay =  startOfDay(record.actualStartDate);
          const eventStartDate = this.removeCommuteTimeWhenUpdating ?
            addMinutes(record.startDate, record.commuteMinutesToSite) : record.startDate;
          currentSiteVisit = this.prospectiveSiteVisits.find(x => x.docId === schedulerEvent.record.id.replace("prospect",""));
          if (currentSiteVisit === undefined) {
            currentSiteVisit = this.prospectiveSiteVisits[0];
          }
          const timeBounds = this.getSiteVisitStartEnd(eventStartDate, record.endDate, startOfActualDay);
          currentSiteVisit.startDate = timeBounds.siteVisitStart;
          currentSiteVisit.endDate = timeBounds.siteVisitEnd;
          currentSiteVisit.explicitErrored = record.explicitErrored;

          // When site visit time is changed, this (sometimes) affects the arrival windows, b/c start time changes as well as duration.
          // So we need to regenerate them here.
          const vertResourceNew = this.resourcesVertical.find(x => x.id === schedulerEvent.record.assignments[0].data.resourceId);
          const newAssignment = new Assignment({siteVisitDocId: currentSiteVisit.docId, employeeDocId: vertResourceNew.actualResourceId,
            employeeAssigningDocId: this.authenticationService.activelyLoggedInEmployeeDocId, siteVisitStartDate: currentSiteVisit.startDate, jobDocId: currentSiteVisit.jobDocId});
          // this.siteVisitSchedulingService.generateArrivalWindow(currentSiteVisit, this.assignments.filter(x => x.siteVisitDocId === currentSiteVisit.docId)[0]);
          this.siteVisitSchedulingService.generateArrivalWindow(currentSiteVisit, newAssignment);
          currentSiteVisit._durationHours = undefined;
          currentSiteVisit.minimizeSiteVisitMutationsOnReconciliation = schedulerEvent.record.dragging === undefined ? false : schedulerEvent.record.dragging;
          return {job: currentJob, siteVisit: currentSiteVisit};
      } else {
        currentSiteVisit = this.siteVisits.find(x => x.docId === associcatedVerticalEvent.siteVisitDocId);
        currentSiteVisit.explicitErrored = record.explicitErrored;
        initialSiteVisitDurationHours = currentSiteVisit.expectedDurationHours;
      }

      if (schedulerEvent.action === 'update') {
    const result = Object.keys(schedulerEvent.changes).map( key => {
      return [key, schedulerEvent.changes[key]];
    });
    const unmutatedEventStartDate = new Date(associcatedVerticalEvent.actualStartDate);
    const unmutatedEventEndDate = new Date(associcatedVerticalEvent.actualEndDate);
    result.forEach(element => {
      // On Vertical view, changing times involves : 1) changing time portion ( removing any date differences )
      // 2) changing date portion ( in assignment swap handler)
      switch (element[0]) {
          // On start or end date, must recalculate other (start or end), and also full recalculate actualStart/ actualEnd.  Otherwise
          // we run into problems where we attempt to read undefined value for non-set portion.
          case 'startDate':
          case 'endDate':
              modifiedTime = true;
              //20230613
              const updatedDate = new Date(element[1].value);
              if (element[0] === 'startDate') {
                associcatedVerticalEvent.actualStartDate = this.AddTimePortionFromVerticalDateToDatePortionFromResourceDate(updatedDate, associcatedVerticalEvent.actualStartDate);
                  if (this.removeCommuteTimeWhenUpdating) {
                  associcatedVerticalEvent.actualStartDate = addMinutes(associcatedVerticalEvent.actualStartDate, associcatedVerticalEvent.commuteMinutesToSite);
                }
              } else {
                associcatedVerticalEvent.actualEndDate = this.AddTimePortionFromVerticalDateToDatePortionFromResourceDate(updatedDate, associcatedVerticalEvent.actualEndDate);
                  if (this.removeCommuteTimeWhenUpdating) {
                  associcatedVerticalEvent.actualEndDate = subMinutes(associcatedVerticalEvent.actualEndDate, associcatedVerticalEvent.commuteMinutesSiteToShop);
                }
              }
              associcatedVerticalEvent[element[0]] = updatedDate;
              break;
              case 'job':
              case 'actualStartDate':
              case 'actualEndDate':
                break;
          default:
              modifiedEventExcludingTime = true;
              associcatedVerticalEvent[element[0]] = element[1].value;
              break;
        }
      });

      if (modifiedTime && !this.removeCommuteTimeWhenUpdating) {
        associcatedVerticalEvent.startDate = subMinutes(associcatedVerticalEvent.startDate, associcatedVerticalEvent.commuteMinutesToSite);
    }

    // Because of how we mutate events to remove commute time (sometimes) we must check that modification actually occurred.
    modifiedTime = modifiedTime && (unmutatedEventStartDate.getTime() !== associcatedVerticalEvent.actualStartDate.getTime() || unmutatedEventEndDate.getTime() !== associcatedVerticalEvent.actualEndDate.getTime());
    if (modifiedEventExcludingTime || modifiedTime) {
        const retVal = {job: this.applyBryntumEventUpdatesToJob(associcatedVerticalEvent, "event",
          currentSiteVisit ), siteVisit: currentSiteVisit};
        if (initialSiteVisitDurationHours !== currentSiteVisit.expectedDurationHours) {
          this.hoursDeltaAgainstOnDeckJob = currentSiteVisit.expectedDurationHours - initialSiteVisitDurationHours;
          if (this.prospectiveSiteVisits.length === 0) {
            this.addTimeDiffToJob(retVal.job, currentSiteVisit.expectedDurationHours - initialSiteVisitDurationHours,
              currentSiteVisit.expectedDurationHours - initialSiteVisitDurationHours > 0 ?
                JobDurationDeltaModificationType.SITEVISITDURACTIONINCREASEDBYDRAG :
                JobDurationDeltaModificationType.SITEVISITDURATIONREDUCEDBYDRAG, currentSiteVisit.docId);
          }
        }
        retVal.siteVisit.minimizeSiteVisitMutationsOnReconciliation = schedulerEvent.record.dragging === undefined ? false : schedulerEvent.record.dragging;
        return retVal;
    } else {
      console.error("RETURNING NULL HERE LIKELY THIS SHOULD NOT HAPPEN");
      return null;
    }
      // end update
    } else if (schedulerEvent.action === 'remove') {
      throw Error("Removed event, not sure how we got here.");
    } // end remove
  } else {
  return null;
  }
} // end of UpdateEvent.

  private applyBryntumEventUpdatesToJob(associcatedVerticalEvent: BryntumEvent, updateType: string,
    currentSiteVisit: SiteVisit = null): Job {
    let currentJob = this.jobs.find(x => x.jobDocId === associcatedVerticalEvent.jobDocId);
    if (currentSiteVisit === null) {
      currentSiteVisit = this.siteVisits.find(x => x.docId === associcatedVerticalEvent.siteVisitDocId);
    }
    // If an existing site visit was updated.
    if (currentSiteVisit !== undefined) {
      if (currentSiteVisit.startDate.valueOf() !== associcatedVerticalEvent.actualStartDate.valueOf() ||
        currentSiteVisit.endDate.valueOf() !== associcatedVerticalEvent.actualEndDate.valueOf() ) {
        // On assignment updates, modify date portion.
        if (updateType === "assignment") {
          const unModifiedStartDate = new Date(currentSiteVisit.startDate);
          const unModifiedEndDate = new Date(currentSiteVisit.endDate);
          currentSiteVisit.startDate =  subMinutes(associcatedVerticalEvent.actualStartDate,
            associcatedVerticalEvent.actualStartDate.getHours() * 60 + associcatedVerticalEvent.actualStartDate.getMinutes());
          currentSiteVisit.startDate = addMinutes(currentSiteVisit.startDate,
            unModifiedStartDate.getHours() * 60 + unModifiedStartDate.getMinutes());
          currentSiteVisit.endDate =  subMinutes(associcatedVerticalEvent.actualEndDate,
            associcatedVerticalEvent.actualEndDate.getHours() * 60 + associcatedVerticalEvent.actualEndDate.getMinutes());
          currentSiteVisit.endDate = addMinutes(currentSiteVisit.endDate,
            unModifiedEndDate.getHours() * 60 + unModifiedEndDate.getMinutes());
        } else {
        // On event updates, modify time portion.
          if (updateType === "event") {
            currentSiteVisit.startDate = subMinutes(currentSiteVisit.startDate,
              currentSiteVisit.startDate.getHours() * 60 + currentSiteVisit.startDate.getMinutes());
            currentSiteVisit.startDate = addMinutes(currentSiteVisit.startDate,
              associcatedVerticalEvent.actualStartDate.getHours() * 60 + associcatedVerticalEvent.actualStartDate.getMinutes());
            currentSiteVisit.endDate = subMinutes(currentSiteVisit.endDate,
              currentSiteVisit.endDate.getHours() * 60 + currentSiteVisit.endDate.getMinutes());
            currentSiteVisit.endDate = addMinutes(currentSiteVisit.endDate,
                associcatedVerticalEvent.actualEndDate.getHours() * 60 + associcatedVerticalEvent.actualEndDate.getMinutes());
          }
        }
        currentSiteVisit.prospectiveCommutes.forEach(c => {
          const commuteTime = c.commuteTimeMinutes;
          if (c.destinationAddressDocId === currentSiteVisit.siteVisitAddress.DocId()) {
            c.commuteEndTime = new Date(currentSiteVisit.startDate);
            c.commuteStartTime = subMinutes(c.commuteEndTime,commuteTime);
          } else if (c.orginAddressDocId === currentSiteVisit.siteVisitAddress.DocId()) {
            c.commuteStartTime = new Date(currentSiteVisit.endDate);
            c.commuteEndTime = addMinutes(c.commuteStartTime,commuteTime);
          }
        });
        // If modifications leave current arrival time outside bounds, we need to regenerate the arrival windows.
        if (startOfDay(currentSiteVisit.startDate).getTime() !== startOfDay(currentSiteVisit.arrivalWindowStartDate).getTime() ||
         currentSiteVisit.startDate.getHours() * 60 + currentSiteVisit.startDate.getMinutes() >
          currentSiteVisit.arrivalWindowEndDate.getMinutes() + currentSiteVisit.arrivalWindowEndDate.getHours() * 60 ||
          currentSiteVisit.startDate.getHours() * 60 + currentSiteVisit.startDate.getMinutes() <
          currentSiteVisit.arrivalWindowStartDate.getMinutes() + currentSiteVisit.arrivalWindowStartDate.getHours() * 60) {
            this.siteVisitSchedulingService.generateArrivalWindow(currentSiteVisit, this.assignments.filter(x => x.siteVisitDocId === currentSiteVisit.docId)[0]);
          }
        // If the site visit has already been added, we want to update the job.  Otherwise we will update it when we add site visit.
        if (currentJob.siteVisits.some(x => x.docId === currentSiteVisit.docId)) {
          const arrivalWindowUpdates = this.jobService.applySiteVisitModificationsToJobReturnTrueIfArrivalWindowChanged(currentSiteVisit, currentJob);
          if (arrivalWindowUpdates !== null) {
            this.siteVisitArrivalWindowUpdated$.next({job:currentJob, siteVisitDocId: currentSiteVisit.docId, orignalWindow: arrivalWindowUpdates.orignalWindow,
              newWindow: arrivalWindowUpdates.newWindow});
          }
        }
      }
      return currentJob;
    } else {
      console.log("Attempted to create from apply Bryntum Event Updates!");
      return null;
    }
  }

  regenerateSubsetOfBryntumResourceDateSummationsFromPassedIn(bryntumResourceIdsToRegenerate: string[], assignments: BryntumAssignment[],
  events: BryntumEvent[], source: BryntumResourceDateSummation[]): BryntumResourceDateSummation[] {
  return source.map(vertResource => {
    if (bryntumResourceIdsToRegenerate.includes(vertResource.id)) {
      return this.updateVerticalResourceSummationInfo(vertResource, assignments, events);
    } else {
      return vertResource;
    }});
}


  private updateVerticalResourceSummationInfo(bryntumResourceDateSummation: BryntumResourceDateSummation,
    assignmentsVertical: BryntumAssignment[], eventsVertical: BryntumEvent[]): BryntumResourceDateSummation {
  const associatedEventIds = assignmentsVertical.filter( y => y.resourceId === bryntumResourceDateSummation.id).map(y => y.eventId);
  const associatedEvents = eventsVertical.filter(element => associatedEventIds.includes(element.id));
  return BryntumResourceDateSummation.mapResourceSummaryInformation(bryntumResourceDateSummation, associatedEvents,
    bryntumResourceDateSummation.actualResourceId, bryntumResourceDateSummation.resourceDate, this.employeeAvailabilityService);
}

  public isWorkDay(date :Date) : boolean {
    return this.settingsService.getValue('workWeek') === undefined ? false :
      this.settingsService.getValue('workWeek').map(x => x.activeWorkDay && x.date.getDay()).includes(date.getDay());
  }

  public updateResourceVertical() {
  let updateIt = [];
  this.resourcesVertical.forEach(resource =>
    updateIt.push(this.updateVerticalResourceSummationInfo(resource, this.bryntumAssignments, this.eventsVertical)));
  updateIt = this.AddDayEndDecorationWhenNeeded(updateIt);
  this.resourcesVertical = updateIt;
}

public removeAssignment(siteVisitDocId: string, b?: string | null): Observable<Assignment> {
  const assignmentIndex = this.assignments.findIndex(x => x.siteVisitDocId === siteVisitDocId);
  if (assignmentIndex > -1) {
  const oldAssignment = this.assignments.splice(assignmentIndex, 1)[0];
  oldAssignment.active = false;
  return this.assignmentService.update$(oldAssignment, b)
    .pipe(
    take(1)
    );
  } else {
    return of(null);
  }
}


  private removeAssignmentAndSiteVisitFromJob(vertEvent: BryntumEvent, timeNeedsRescheduled: boolean): Observable<string> {
    const oldAssignment = this.assignments.splice(this.assignments.findIndex(x => x.siteVisitDocId === vertEvent.siteVisitDocId), 1)[0];

    this.siteVisitPreModification = {siteVisit: new SiteVisit(this.siteVisits.find(y=>y.docId === oldAssignment.siteVisitDocId)),
      assignment: new Assignment(oldAssignment)};
      this.siteVisitPreModification.siteVisit.startDate = new Date(this.siteVisitPreModification.siteVisit.startDate);
      this.siteVisitPreModification.siteVisit.endDate = new Date(this.siteVisitPreModification.siteVisit.endDate);

    oldAssignment.active = false;
    let job = this.jobs.find(x => x.jobDocId === vertEvent.jobDocId);
    job = this.jobService.removeSiteVisitFromJob(
      job.siteVisits.find(x => x.docId === oldAssignment.siteVisitDocId ), job, timeNeedsRescheduled);
    return this.assignmentService.retrieveFirestoreBatchString().pipe(
      switchMap(wb => this.jobService.update$(job, wb).pipe(
      exhaustMap(() => this.assignmentService.update$(oldAssignment,wb)),
      take(1),
      map(() => wb)
    )));
  }
  private updateJobWithAssignmentModification(schedulerAssignmentMapping: any, currentJob: Job = null, currentSiteVisit: SiteVisit = null):
{job: Job, oldAssignment: Assignment, newAssignment: Assignment, currentSiteVisit: SiteVisit, newSiteVisitNumber: number } {
    console.log(currentSiteVisit);
    let regenerateSiteVisitArrivalWindow = false;
    if (schedulerAssignmentMapping.action === 'remove') {
      console.log("Attempting to remove event.");
      throw Error("Should not attempt to remove events through Bryntum tooling.");
    } else if (schedulerAssignmentMapping.action === 'add') {
      console.log("Attempting to add event.");
      throw Error("Should not attempt to add events through Bryntum tooling.");
      // Adds are handled from result of seperate add dialog.
    } else if (schedulerAssignmentMapping.action === 'update') {
      if (schedulerAssignmentMapping.changes.resourceId !== undefined ) {
        const vertResourceOld = this.resourcesVertical.find(x => x.id === schedulerAssignmentMapping.changes.resourceId.oldValue);
        const vertResourceNew = this.resourcesVertical.find(x => x.id === schedulerAssignmentMapping.changes.resourceId.value);

        let vertEvent = this.eventsVertical.find(x => x.id === schedulerAssignmentMapping.record.eventId);

        // Occurs when both event and assignment modification events are fired on prospective site visit.
        if (vertEvent === undefined && currentJob !== null) {
          vertEvent = this.generateVerticalBryntumEventFromJobSiteVisit(currentJob, currentSiteVisit);
          regenerateSiteVisitArrivalWindow = true;
        } else if (vertEvent === undefined) {
        // Occurs when only an assignement modification event fires on prospective site visit.
        // get actual date from resource id.

        const associatedProspectiveSiteVisit = this.prospectiveSiteVisits[0];
        const timeBounds = this.getSiteVisitStartEnd(associatedProspectiveSiteVisit.startDate, associatedProspectiveSiteVisit.endDate,
          vertResourceNew.resourceDate);
        associatedProspectiveSiteVisit.startDate = timeBounds.siteVisitStart;
        associatedProspectiveSiteVisit.endDate = timeBounds.siteVisitEnd;
        associatedProspectiveSiteVisit._durationHours=undefined;
        currentSiteVisit = associatedProspectiveSiteVisit;
        const partialAssignment = new Assignment({employeeDocId: vertResourceNew.actualResourceId, jobDocId: currentSiteVisit.jobDocId});
        return {job: currentJob, oldAssignment: null, newAssignment: partialAssignment, currentSiteVisit, newSiteVisitNumber: schedulerAssignmentMapping.siteVisitNumber};
        } else {
          currentSiteVisit = this.siteVisits.find(x => x.docId === vertEvent.siteVisitDocId);
        }

        const oldAssignmentIndex = this.assignments.findIndex(x => x.siteVisitDocId === vertEvent.siteVisitDocId);
        const oldAssignment = oldAssignmentIndex === -1 ? undefined : this.assignments.splice(oldAssignmentIndex, 1)[0];
        if (oldAssignment) {
          oldAssignment.active = false;
        }

      // The event start / end dates need updated to reflect the change in the resource object.
        const delta = new Date(vertResourceNew.resourceDate).getTime() - new Date(vertResourceOld.resourceDate).getTime();
        vertEvent.actualStartDate = new Date(vertEvent.actualStartDate.getTime() + delta);
        vertEvent.actualEndDate = new Date(vertEvent.actualEndDate.getTime() + delta);
        const indexOfExistingAssignment = this.bryntumAssignments.findIndex(x => x.eventId === vertEvent.id);
        if (indexOfExistingAssignment !== -1) {
          this.bryntumAssignments.splice(indexOfExistingAssignment, 1,
            {resourceId : vertResourceNew.id, eventId: vertEvent.id });
        }

        const newAssignment = new Assignment({siteVisitDocId: vertEvent.siteVisitDocId, employeeDocId: vertResourceNew.actualResourceId,
                                              employeeAssigningDocId: this.authenticationService.activelyLoggedInEmployeeDocId,
                                              siteVisitStartDate: vertEvent.actualStartDate, jobDocId: vertEvent.jobDocId});
        this.assignments.push(newAssignment);

        const job = this.applyBryntumEventUpdatesToJob(vertEvent, "assignment", currentSiteVisit);
        if (regenerateSiteVisitArrivalWindow) {
          this.siteVisitSchedulingService.generateArrivalWindow(currentSiteVisit, newAssignment);
        }
        return {job, oldAssignment, newAssignment, currentSiteVisit, newSiteVisitNumber: schedulerAssignmentMapping.siteVisitNumber};
    } else {
      return null;
    }
  } else {
    return null;
  }
}

  public modifyAssignment(schedulerAssignmentMapping: any, currentJob: Job = null, currentSiteVisit: SiteVisit = null, batch: string | null = null ):
    Observable<DataNeededForAdditionalSiteVisitModifications>
    {
        let retVal = new Observable<any>();
        const updates =  this.updateJobWithAssignmentModification(schedulerAssignmentMapping, currentJob, currentSiteVisit);
        updates.currentSiteVisit.minimizeSiteVisitMutationsOnReconciliation = schedulerAssignmentMapping.record.event.dragging === undefined ? false : schedulerAssignmentMapping.record.event.dragging;
        console.log(updates);

        const responsibleForCommit = batch === null;
        let batchResolved = of(batch);
        if (responsibleForCommit) {
          batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
        }

      if (updates !== null) {
        updates.oldAssignment.active = false;
        return batchResolved.pipe(switchMap(wb => this.assignmentService.update$(updates.oldAssignment, wb).pipe(
          map(() => wb)
        )),
          map(wb => {
            return {job: updates.job, siteVisit: updates.currentSiteVisit, assignedEmployeeDocId: updates.newAssignment.employeeDocId, newSiteVisitNumber: schedulerAssignmentMapping.siteVisitNumber, wb};
          }),
          switchMap(retVal => this.assignmentService.create$(updates.newAssignment, retVal.wb).pipe(
            map(() => retVal)
          )),
          take(1)
        );
      } else {
        return batchResolved.pipe(map(wb => {
          return {job: null, siteVisit: updates.currentSiteVisit, assignedEmployeeDocId: updates.newAssignment.employeeDocId, newSiteVisitNumber: schedulerAssignmentMapping.siteVisitNumber, wb}
        }));
      }
  } // end ModifyAssignment
} // end of SchedulerService
