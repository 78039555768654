import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { Discount } from '../../../../common/src/data/dao/discount';

@Injectable({
  providedIn: 'root'
})
export class DiscountService extends DatabaseStoreService<Discount> {

  constructor( fs: DiscountFirestoreService, store: DiscountStoreService, authService: AuthenticationService) {
super(fs, store,authService);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DiscountStoreService extends StateChangeStoreService<Discount> {
  protected store = "Discount-store";

constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, Discount>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class DiscountFirestoreService extends FirestoreBackend<Discount> {

 protected basePath = "Discount";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Discount {
return new Discount(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new Discount(), authService);
 }
}
