import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { FormFirestoreSummary } from "./form-firestore-summary";

export class FormFirestore extends RetrieveFirestorePropertiesBase<FormFirestore>  {

  title: string;
  form: string;
  formSummary: FormFirestoreSummary;
  formSummaryDocId: string;

  immutable: boolean = true;


  static _replaceTemplateOptions: RegExp = new RegExp(/templateOptions/,"g");

  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["formSummaryDocId"]);
  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["formSummary"]);
  static _firestoreCompositionMemberNames = ["formSummary"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["formSummary"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return FormFirestore._firestoreIgnoredMemberNames};
  retrievefirestoreIgnoreDiffTrackingMembers() : string[] {return FormFirestore._firestoreIgnoreDiffTrackingMembers;}
  retrievefirestoreCompositionMemberNames() { return FormFirestore._firestoreCompositionMemberNames; }
  retrievefirestoreCompositionalDiffMemberNames() { return FormFirestore._firestoreCompositionalDiffMemberNames; }
  retrieveFirestoreDenormalizedMemberNames(): string[] {return FormFirestore._firestoreCompositionMemberNames; }

  public constructor(init?: Partial<FormFirestore>) {
    super();
    Object.assign(this, init);
    if (this.form) {
      this.form = this.form.replace(FormFirestore._replaceTemplateOptions,"props");
    }
  }
}
