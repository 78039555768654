<div style="display: flex;">
  <!-- <div class="header-label header-border">Customer Jobs</div> -->

<div class="header-label header-border">{{title}} </div>
</div>
<div class="mat-table-container">
  <table mat-table [dataSource]="sortedJob$ | async" class="mat-elevation-z8">
    <ng-container matColumnDef="jobDocId">
      <th mat-header-cell *matHeaderCellDef>Job #</th>
      <td mat-cell *matCellDef="let job">
        <a routerLink="/job-page/{{job.jobDocId}}">
          {{job.serviceProviderJobId}}
        </a>
        </td>
        <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef>Start Date</th>
      <td mat-cell *matCellDef="let job">{{job.startDate | date:'medium'}} </td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef>End Date</th>
      <td mat-cell *matCellDef="let job">{{job.endDate | date:'medium'}} </td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef>Customer</th>
      <td mat-cell *matCellDef="let job">
        <a routerLink="/app-customer-page/{{job.customer?.DocId()}}">
          {{job.customer?.customerName}}
        </a>
      </td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="expectedHours">
      <th mat-header-cell *matHeaderCellDef>Expected Duration</th>
      <td mat-cell *matCellDef="let job">{{durationDisplayString(job.durationExpectedHours)}} </td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="serviceAddress">
      <th mat-header-cell *matHeaderCellDef>Address </th>
      <td mat-cell *matCellDef="let job">{{job.serviceAddress?.formattedAddress()}} </td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="jobStatus">
      <th mat-header-cell *matHeaderCellDef>Job Status</th>
      <td mat-cell *matCellDef="let job">{{job.jobStatus}}</td>
      <td style="background-color: #e8e8e8;" mdat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="jobType">
      <th mat-header-cell *matHeaderCellDef>Job Type</th>
      <td mat-cell *matCellDef="let job">{{job.jobType?.name}} </td>
      <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></tr>
    <tr mat-row *matRowDef="let job; columns: columnsToDisplay;" class="example-element-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay" class="example-second-footer-row"></tr>
  </table>
</div>
