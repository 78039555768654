import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest,  of,  Subject, switchMap, zip } from 'rxjs';
import { filter, map,  take, takeUntil, mergeMap, tap } from 'rxjs/operators';
import { AddressSearchModalComponent } from '../../address_search/address-search-modal/address-search-modal.component';
import { CompanySettingsService } from '../../../../../common/src/data/dao-services/company-settings.service';
import { randomElementName } from '../../../../../common/src/util/util';
import { IndustryService } from '../../../../../common/src/data/dao-services/industry.service';
import { CompanySettings } from '../../../../../common/src/data/dao/company-settings';
import { Address } from '../../../../../common/src/data/dao/address';
import { PhysicalAddressRoutingService } from 'web-app/src/app/physical-address-routing.service';
import { Job } from '../../../../../common/src/data/dao/job';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import { searchSource } from '../../../../../common/src/data/services/search.service';
import {CompanyLocation} from '../../../../../common/src/data/dao/company-location';
import {CompanyLocationService} from '../../../../../common/src/data/dao-services/company-location.service';
import { SettingsTableColumnDefinition } from '../settings-table/settings-table.component';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySettingsComponent implements OnInit, OnDestroy {

  randomElementName : string = randomElementName();
  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  offices$ = new BehaviorSubject<any[]>([]);
  locationColumns : SettingsTableColumnDefinition[] = [{name: 'name', friendlyName: 'Name', type: "string"}, {name: 'dispatchAddressDisplay', friendlyName: 'Dispatch Address', type: "string"},
    {name: 'mailingAddressDisplay', friendlyName: 'Mailing Address', type: "string"}, {name: 'default', friendlyName: 'Default', type: "boolean"}];

  get currentJobCounter() : number { return this.companySetting.currentJobCounter; }

  constructor(private companySettingService: CompanySettingsService, private fb: UntypedFormBuilder, public industryService: IndustryService, private dialog: MatDialog,
    private snackBar: MatSnackBar, private physicalAddressRoutingService: PhysicalAddressRoutingService, private employeeService: EmployeeService,
    private companyLocationService: CompanyLocationService) {


    }

  form: UntypedFormGroup;
  companyLocationForm: UntypedFormGroup;
  updateAddressModalRef: MatDialogRef<AddressSearchModalComponent>;
  standardFieldsToPatch = ["referralCode","website","companyEmailAddress","companyPhoneNumber","companyName", "industry", "currentJobCounter", "jobNumberFormatting", "companyBccEmailAddress"];
  private companySetting: CompanySettings;
  private companyLocations: CompanyLocation[];

  ngOnInit(): void {

    this.form = this.initilizeForm();
    this.companyLocationForm = this.initilizeCompanyLocationForm();
    this.companyLocationService.loadAll$().pipe(
      takeUntil(this.destroyingComponent$)
    ).subscribe(x => {
      this.companyLocations = x;
      this.offices$.next(x.map(y => {
        return {dispatchAddressDisplay:y.dispatchAddress.formattedAddress(), mailingAddressDisplay:y.mailingAddress.formattedAddress(),  ...y}
      }));
    });

    this.companySettingService.loadAll$().pipe(
      filter(x => x.length > 0),
      tap(x => this.companySetting = x[0]),
      tap(x => this.patchCompanySettings(this.companySetting)),
      tap(() => {
        this.form.get('currentJobCounter').setValidators([Validators.required, Validators.min(this.companySetting.currentJobCounter) ]);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  selectLocation(location: CompanyLocation) {
      this.companyLocationForm.patchValue({dispatchAddress: location.dispatchAddress});
      this.companyLocationForm.patchValue({dispatchAddressDisplay: location.dispatchAddress.formattedAddress()});
      this.companyLocationForm.patchValue({mailingAddress: location.mailingAddress});
      this.companyLocationForm.patchValue({mailingAddressDisplay: location.mailingAddress.formattedAddress()});
      this.companyLocationForm.patchValue({name: location.name});
      this.companyLocationForm.patchValue({docId: location.docId});
      this.companyLocationForm.patchValue({default: location.default});
    }

  patchLocationFormToLocation(location: CompanyLocation) {
    location.dispatchAddress = this.companyLocationForm.get("dispatchAddress").value;
    location.mailingAddress = this.companyLocationForm.get("mailingAddress").value;
    location.name = this.companyLocationForm.get("name").value;
    location.default = this.companyLocationForm.get("default").value === null ? false : this.companyLocationForm.get("default").value;
  }

  saveLocation(event: any){
    if (this.companyLocationForm.valid) {
      this.snackBar.open("Saving Updates",undefined);
      const location = this.companyLocations.find(x => x.docId === this.companyLocationForm.get("docId").value) === undefined ?
        new CompanyLocation() : this.companyLocations.find(x => x.docId === this.companyLocationForm.get("docId").value);
      this.patchLocationFormToLocation(location);
    const toUpdate = [of(null)];
    // If dispatch address is updated, we must update dispatch origin / destination addresses for any employees who
      // are assigned the original company's dispatch address.
      if (this.companySetting?.dispatchAddress?.DocId() === undefined || this.companySetting.dispatchAddress.DocId() !== this.companyLocationForm.get("dispatchAddress").value.DocId()) {
        this.employeeService.loadAll$().pipe(
          take(1),
          map(x => {
            let update = false;
            x.forEach(y => {
              if (y.dispatchDestinationAddress && y.dispatchDestinationAddress.DocId() === this.companySetting.dispatchAddress.DocId()) {
                y.dispatchDestinationAddress = this.companyLocationForm.get("dispatchAddress").value;
                update = true;
              }
              if (y.dispatchOrginAddress && y.dispatchOrginAddress.DocId() === this.companySetting.dispatchAddress.DocId()) {
                y.dispatchOrginAddress = this.companyLocationForm.get("dispatchAddress").value;
                update = true;
              }
              if (update) {
                toUpdate.push(this.employeeService.update$(y).pipe(map(() => null)));
              }
            });
          }),
          mergeMap(x => zip(...toUpdate)),
          switchMap(x => this.companyLocationService.update$(location)),
          tap(() => this.snackBar.dismiss()),
          take(1)
        ).subscribe();
      } else {
        this.companyLocationService.update$(location).pipe(
          tap(() => this.snackBar.dismiss()),
          take(1),
          ).subscribe();
      }
    }
  }

  UpdateAddress(addressFieldName: string) : void {

    const editorConfig = new MatDialogConfig();
        Object.assign(editorConfig, {
          disableClose : false,
          autoFocus    : true,
          width        : '700px',
          outerHeight  :  '200px',
          data         :
          {
            title: `Update ${addressFieldName}`,
            searchSources: [searchSource.GooglePlaceAutoComplete],
            provideAddressToShopRoutingInfo: false,
            provideJobCountAtAddressInfo: false
          }
          });
        this.updateAddressModalRef = this.dialog.open(AddressSearchModalComponent, editorConfig);
        this.updateAddressModalRef.afterClosed().pipe(
          filter(x => x !== undefined),
          tap(address => {
            if (addressFieldName === "Dispatch Address") {
              this.companyLocationForm.patchValue({dispatchAddress: address});
              this.companyLocationForm.patchValue({dispatchAddressDisplay: address.formattedAddress()});
            } else if (addressFieldName === "Mailing Address") {
              this.companyLocationForm.patchValue({mailingAddress: address});
              this.companyLocationForm.patchValue({mailingAddressDisplay: address.formattedAddress()});
            }
          }),
          take(1)
        ).subscribe();
    }

  initilizeCompanyLocationForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      name: [""],
      dispatchAddress: [undefined,[Validators.required]],
      dispatchAddressDisplay: [],
      mailingAddress: [undefined, [Validators.required]],
      mailingAddressDisplay: [],
      docId: [],
      default: []
    })

    retVal.get("mailingAddress").valueChanges.pipe(
      map(x => x === null ? "" : (x as Address).formattedAddress()),
      tap(x => this.form.patchValue({mailingAddressDisplay: x})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    retVal.get("dispatchAddress").valueChanges.pipe(
      map(x => x === null ? "" : (x as Address).formattedAddress()),
      tap(x => this.form.patchValue({dispatchAddressDisplay: x})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    return retVal;
  }

  initilizeForm() : UntypedFormGroup{
    const retVal = this.fb.group({
      referralCode: "",
      website: "",
      companyEmailAddress: [undefined,[Validators.required]],
      companyBccEmailAddress: [undefined],
      companyPhoneNumber: [undefined,[Validators.required]],
      companyName: [undefined,[Validators.required]],
      industry: [undefined, [Validators.required]],
      currentJobCounter: [null, [Validators.required, Validators.min(1) ]],
      jobNumberFormatting: ['#<<counter>>',[jobFormattingValidator()]],
      nextJobNumber: [],
      nextJobNumberDisplay: []
    });

    combineLatest([retVal.get("currentJobCounter").valueChanges,retVal.get("jobNumberFormatting").valueChanges]).pipe(
      filter(x => x[0]!== null && x[1] !== null),
      map(x =>
        {
        const currentValue = Job.generateServiceProviderSpecificJobIdentifier(x[1],x[0]);
        this.form.patchValue({nextJobNumber:currentValue});
        return currentValue === undefined ? "Invalid" : currentValue;
        }),
        tap(x => this.form.patchValue({nextJobNumberDisplay: x})),
        takeUntil(this.destroyingComponent$)
    ).subscribe();

    return retVal;
  }

  patchFormGroupToCompanySettings() {
      this.standardFieldsToPatch.forEach(field =>  this.companySetting[field] = this.form.get(field).value);
    }


  Cancel() {
    this.form.reset();
    this.patchCompanySettings(this.companySetting);
  }

  Save() {
    if (this.form.valid) {
      this.snackBar.open("Saving Updates",undefined);
        this.patchFormGroupToCompanySettings();
        this.companySettingService.update$(this.companySetting).pipe(
          take(1)
        ).subscribe(() => this.snackBar.dismiss());
      }
    }

  addLocation() {
    this.companyLocationForm = this.initilizeCompanyLocationForm();
  }

  patchCompanySettings(settings: CompanySettings) {
    this.standardFieldsToPatch.forEach(field => {
      this.form.get(field).patchValue(settings[field]);
    });
  }

  compareWithDocIds(o1:any, o2:any) {
    if (o1===null || o2===null) { return false; }
    if (o1.docId === o2.docId) {
      return true;
    } else {
      return false;
    }
  }
}

  // Job formatting must include counter.
  function jobFormattingValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? control.value.includes("<<counter>>") ? null : {invalidJobFormatting: true} : null;
    };
  }
