import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Job } from '../../../../common/src/data/dao/job';
import { LineItem } from '../../../../common/src/data/dao/line-item';

@Component({
  selector: 'app-multiple-job-summary',
  templateUrl: './multiple-job-summary.component.html',
  styleUrls: ['./multiple-job-summary.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleJobSummaryComponent implements OnInit {

  @Input() jobs$: Observable<Job[]>;
  @Input() title: string;

  sortedJob$: Observable<Job[]>;

  // @ViewChild(MatTable) table: MatTable<any>;

  columnsToDisplay = ['jobDocId','startDate','endDate','customer','expectedHours','jobStatus','jobType'];
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data, private router: Router, @Optional() private dialogRef: MatDialogRef<MultipleJobSummaryComponent>) {

    if (data) {
      if (data.jobs$) {
        this.jobs$=data.jobs$;
      }
      if (data.title) {
        this.title = data.title;
      }
    }
  }

  durationDisplayString(hours: number) {
    return LineItem.timeDurationStringFromHours(hours);
  }
  ngOnInit(): void {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.dialogRef )  {
          this.dialogRef.close();
        }
    })

    // jobs are sorted by imorted at top (by start date), and then by creation date for the
        // jobs that were created in our system.
        this.sortedJob$ = this.jobs$.pipe(
          map(j => {
            j.sort((a,b) => a.importedJob && !b.importedJob ? 1 : b.importedJob && !a.importedJob ? -1 :
            a.importedJob && b.importedJob && a.startDate && b.startDate ? b.startDate.getTime() - a.startDate.getTime() :
            !a.importedJob && !b.importedJob ? b.createdAt.getTime() - a.createdAt.getTime() : 0);
            return j;
          })
        );
  }

}
