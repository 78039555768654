import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';

@Component({
  selector: 'app-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.scss']
})
export class BillingSettingsComponent implements OnInit {

  constructor(public employeeService: EmployeeService) { }

  get activeUserCount() {
    return this.employeeService.allEmployees.filter(employee => employee.active).length;
  }

  ngOnInit(): void {
  }

}
