import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { addHours, startOfDay } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'web-app/src/app/settings/settings.service';


@Component({
  selector: 'app-site-visit-length',
  templateUrl: './site-visit-length.component.html',
  styleUrls: ['./site-visit-length.component.scss']
})
export class SiteVisitLengthComponent implements OnInit {

  initialValue = addHours(startOfDay(new Date()),1);
  fidelitySiteVisitLength: number;
  zeroHoursAsDate = startOfDay(new Date());
  lengthToAdd$ = new BehaviorSubject<Date>(this.initialValue);

  constructor(private dialogRef: MatDialogRef<SiteVisitLengthComponent>, private settingsService: SettingsService) {
    this.fidelitySiteVisitLength = this.settingsService.getValue("minutesFidelitySchedulingLengthSiteVisit");
  }

  cancelClose() {
    this.dialogRef.close({ hoursToAdd: null, action: "cancel" });
  }

  addSiteVisitClose() {
    this.dialogRef.close({ hoursToAdd: this.lengthToAdd$.value, action: "addSiteVisit"});
  }

  ngOnInit(): void {
  }

}
