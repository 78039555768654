import { Component, OnInit } from '@angular/core';
import { DexieCacheService } from '../../../../common/src/data/dao-services/dexie-cache.service';

@Component({
  selector: 'app-price-book',
  templateUrl: './price-book.component.html',
  styleUrls: ['./price-book.component.scss']
})
export class PriceBookComponent implements OnInit {

  constructor(private dexieCacheService: DexieCacheService) { }

  ngOnInit(): void {
  }

}
