import { Job } from '../data/dao/job';
import { deepDiffMapper } from '../../../web-app/src/app/retrieve-from-firestore-diff-mapper';
import { format } from 'date-fns';
import { SiteVisit } from '../data/dao/site-visit';
import { Customer } from '../data/dao/customer';
import { Address } from '../data/dao/address';
import { SettingsService } from 'web-app/src/app/settings/settings.service.js';
import { LiteDomClassList } from '../util/util';

export enum BryntumReadonlyEventType {
  CommuteAndArrivalWindow,
}

export class BryntumEvent  {
  // Bryntum Uses.
  startDate: Date;
  endDate: Date;
  eventType = "";
  iconCls: object[];
  name = "";
  id =  "";
  desc = "";
  eventColor = "";
  draggable: boolean;
  resizable: boolean;
  cls = "";
  commuteEndDate: Date;
  returnCommuteStartDate: Date;
  errored: boolean = false;
  commuteToSiteIncludedInWorkDay: boolean;
  commuteSiteToShopIncludedInWorkDay: boolean;
  phoneNumber: string;
  extension: string;
  addressString: string;
  addressDocId: string;

  removeCommuteTimeWhenUpdating = true;

  // We use for actual events.
  actualStartDate: Date = undefined;
  actualEndDate: Date = undefined;
  jobDocId: string = undefined;
  siteVisitDocId: string = undefined;
  commuteMinutesToSite: number = undefined;
  commuteMinutesSiteToShop: number = undefined;
  _arrivalWindowStartDate: Date = undefined;
  _arrivalWindowEndDate: Date = undefined;

  arrivalWindowString: string = undefined;
  emissionGuid: string;

  explicitlyErrored: boolean = false;
  lockedEventStartTime: boolean = false;
  lockedEventOrderPrevious: boolean = false;
  lockedEventOrderNext: boolean = false;
  techLocatedCommuteStart: boolean = false;
  techLocatedAtSiteVisit: boolean = false;
  techBeenHere: boolean = false;
  // tech location at commute end is not *currenty* in use, as we have no formal way to know if tech is en route back to the shop,
  // or just finished their last scheduled site visit, and are waiting for another one.
  techLocatedCommuteEnd: boolean = false;

  //We use in scheduling mode.
  commuteDeltaFromAdding: string = undefined;
  _prospectiveEvent: boolean = false;
  get prospectiveEvent() { return this._prospectiveEvent; }
  set prospectiveEvent(val: boolean) {
    this._prospectiveEvent = val;
    const taskClassList = new LiteDomClassList(this.cls.split(" "));
    if (val === true) {
      taskClassList.add("b-prospective-event");
    } else {
      taskClassList.remove("b-prospective-event");
    }
    this.cls = taskClassList.value;
  }
  durationMinutes: number = undefined;
  regenerateArrivalWindow: (string,date,assignment) => {arrivalWindowStartDate: Date, arrivalWindowEndDate: Date};

  get arrivalWindowStartDate(): Date {
    return this._arrivalWindowStartDate;
  }
  set arrivalWindowStartDate(val: Date) {
    this._arrivalWindowStartDate = val;
    this.arrivalWindowString = this.formatArrivalWindowString();
  }

  get arrivalWindowEndDate(): Date {
    return this._arrivalWindowEndDate;
  }
  set arrivalWindowEndDate(val: Date) {
    this._arrivalWindowEndDate = val;
    this.arrivalWindowString = this.formatArrivalWindowString();
  }


  public constructor(regenArrivalWindow: (string,date,assignment) => {arrivalWindowStartDate: Date, arrivalWindowEndDate: Date}, init?: Partial<BryntumEvent>) {
    this.regenerateArrivalWindow = regenArrivalWindow;
    Object.assign(this, init);
  }

  public static allEqualForBryntumDisplay(prev: BryntumEvent[], curr: BryntumEvent[], log:boolean = false) : boolean
  {
    if (prev.length !== curr.length) {
      return false;
    } else {
      let index = 0;
      for (const event of prev) {
        const singleEqual = this.equalForBryntumDisplay(event, curr[index], log);
        if (!singleEqual) {
          return false;
        }
        index++;
      }
    }
    return true;
  }

  public static equalForBryntumDisplay(prev: object, curr: object, log: boolean = false): boolean {
    const aa = Object.assign({}, prev as BryntumEvent);
    const bb = Object.assign({}, curr as BryntumEvent);
    // all date properties need checked seperately.
    for (const aDate of BryntumEvent.listDatesToCompareForEquality)
      {
        if (aa[aDate] !== bb[aDate] && (aa[aDate] === undefined || bb[aDate] === undefined || aa[aDate].getTime() !== bb[aDate].getTime())) {
          if (log) {
            console.log(prev);
            console.log(curr);
          }
          return false;
        }
        delete aa[aDate];
        delete bb[aDate];
      }

    for (const del of BryntumEvent.doNotConsiderForEqualityComparison) {
      delete aa[del];
      delete bb[del];
    }

    const retVal = JSON.stringify(aa) === JSON.stringify(bb);
    if (retVal === false && log) {
      console.log(prev);
      console.log(curr);
      console.log(aa);
      console.log(bb);
    }
    return retVal;
  }

  static listDatesToCompareForEquality = ["startDate","endDate","commuteEndDate","returnCommuteStartDate",
  "actualStartDate","actualEndDate","_arrivalWindowStartDate","_arrivalWindowEndDate"];
  static doNotConsiderForEqualityComparison = ["emissionGuid"];

  public static equal(a: object, b: object): boolean
  {
    const aAsEvent = a as BryntumEvent;
    const bAsEvent = b as BryntumEvent;
    return (deepDiffMapper.map(aAsEvent,bAsEvent).type  === "unchanged");
  }

  public static generateBryntumEventFromJobSiteVisitPrimaryCustomer(job: Job, siteVisit: SiteVisit, primaryCustomer: Customer,
    address: Address,
    regenArrivalWindow: (string, date, assignment) => {arrivalWindowStartDate: Date, arrivalWindowEndDate: Date}, settingsService: SettingsService): BryntumEvent {
    const retVal: BryntumEvent = new BryntumEvent(regenArrivalWindow);
    retVal.name = `${job.customer.customerName}`;
    retVal.desc = job.description !== undefined ? job.description : "HawtDawg in hall";
    retVal.eventType = job.jobType.name;
    retVal.eventColor = job.jobColor;
    retVal.actualStartDate = siteVisit.startDate;
    retVal.actualEndDate = siteVisit.endDate;
    retVal.jobDocId = job.jobDocId;
    retVal.siteVisitDocId = siteVisit.docId;
    retVal.prospectiveEvent = siteVisit.prospectiveSiteVisit;
    retVal.id = siteVisit.prospectiveSiteVisit ? `prospect${siteVisit.docId}` : siteVisit.docId;
    // retVal.commuteDeltaFromAdding = siteVisit.getRoundedCommuteTimeMinutes(siteVisit.commuteDeltaFromAdding,settingsService);
    retVal.commuteDeltaFromAdding = siteVisit.commuteDeltaFromAdding === undefined ? undefined : siteVisit.commuteDeltaFromAdding < 5 ? "< 5"
    : siteVisit.commuteDeltaFromAdding.toString();
    retVal.commuteMinutesToSite = siteVisit.getRoundedCommuteTimeMinutes(siteVisit.commuteTimeMinutesToSite,settingsService) ;
    retVal.commuteMinutesSiteToShop = siteVisit.getRoundedCommuteTimeMinutes(siteVisit.commuteTimeMinutesFromSiteBackToShop,settingsService);
    retVal.commuteToSiteIncludedInWorkDay = siteVisit.commuteToSiteIncludedInWorkDay;
    retVal.commuteSiteToShopIncludedInWorkDay = siteVisit.commuteSiteToShopIncludedInWorkDay;
    retVal.arrivalWindowStartDate = siteVisit.arrivalWindowStartDate;
    retVal.arrivalWindowEndDate = siteVisit.arrivalWindowEndDate;
    retVal.arrivalWindowString = retVal.formatArrivalWindowString();
    retVal.draggable = true;
    retVal.resizable = true;
    retVal.iconCls = siteVisit.prospectiveIcon;
    retVal.durationMinutes = siteVisit.expectedDurationHours * 60;
    retVal.phoneNumber = primaryCustomer.primaryPhoneNumber;
    retVal.extension = primaryCustomer.primaryPhoneNumberExtension;
    retVal.addressString = address.formattedAddress();
    retVal.addressDocId = address.DocId();
    retVal.explicitlyErrored = siteVisit.explicitErrored;
    retVal.lockedEventStartTime = siteVisit.lockStartTime;
    retVal.lockedEventOrderPrevious = siteVisit.lockPreceedingSiteVisitOrder;
    retVal.lockedEventOrderNext = siteVisit.lockNextSiteVisitOrder;
    retVal.techLocatedAtSiteVisit = siteVisit.onSite;
    retVal.techLocatedCommuteStart = siteVisit.enRoute;
    retVal.techBeenHere = siteVisit.beenHere;

    // retVal.emissionGuid = siteVisit.emisssionGuid + job.emisssionGuid;

    // for imported data, there are no commute times.
    if (job.importedJob) {
      retVal.commuteMinutesToSite = 0;
      retVal.commuteMinutesSiteToShop=0;
    }

    // calculate cls
    const taskClassList = new LiteDomClassList(retVal.cls.split(" "));
    if (retVal.lockedEventStartTime) {
      taskClassList.add("b-lock-start");
    }
    if (retVal.lockedEventOrderPrevious || retVal.lockedEventOrderNext) {
      taskClassList.add("b-lock-order");
    }
    retVal.cls = taskClassList.value;
    return retVal;
  }

  public formatArrivalWindowString(): string {
    if (this._arrivalWindowEndDate !== undefined && this._arrivalWindowStartDate !== undefined) {
      return `${format(this._arrivalWindowStartDate, 'h:mm')}-${format(this._arrivalWindowEndDate, 'h:mm')}`;
    } else {
      return "Unknown";
    }
  }

  public setErrored() {
    this.errored = true;
  }

}



export class BryntumAssignment {
  resourceId: string;
  eventId: string;

  public static allEqualForBryntumDisplay(a: BryntumAssignment[], b: BryntumAssignment[]) : boolean
  {
    if (a.length !== b.length) {
      return false;
    } else {
      let index = 0;
      for (const val of a) {
        const singleEqual = this.equalForBryntumDisplay(val, b[index]);
        if (!singleEqual) {
          return false;
        }
        index++;
      }
    }
    return true;
  }

  public static equalForBryntumDisplay(a: object, b: object): boolean {
    const aa = Object.assign({}, a as BryntumAssignment);
    const bb = Object.assign({}, b as BryntumAssignment);
    return JSON.stringify(aa) === JSON.stringify(bb);
  }

  public constructor(init?: Partial<BryntumAssignment>) {
    Object.assign(this, init);
  }
}
