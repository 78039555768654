import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, merge, Observable, Subject, zip } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../common/src/util/authentication.service';
import { CustomerCommunicationManagementService } from 'web-app/src/app/customer-communication/customer-communication-management.service';
import { CustomerCommunicationCategory } from '../../../../../common/src/data/dao/customer-communication-template';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import { randomElementName } from '../../../../../common/src/util/util';
import { Invoice } from '../../../../../common/src/data/dao/invoice';
import { InvoicePayment } from '../../../../../common/src/data/dao/invoice-payment';
import { InvoiceService } from '../../../../../common/src/data/dao-services/invoice.service';
import { JournalEntry, JOURNAL_CREDIT_TYPE, JOURNAL_DEBIT_TYPE, JOURNAL_ENTRY_TYPE } from '../../../../../common/src/data/dao/journal-entry';
import { JournalEntryService } from '../../../../../common/src/data/dao-services/journal-entry.service';
import { FirestoreBackend } from '../../../../../common/src/data/database-backend/retrieve-from-firestore';

@Component({
  selector: 'app-add-journal-entry',
  templateUrl: './add-journal-entry.component.html',
  styleUrls: ['./add-journal-entry.component.scss']
})
export class AddJournalEntryComponent implements OnInit, OnDestroy, AfterViewInit {

  form: UntypedFormGroup;
  journalEntryTypes: JOURNAL_ENTRY_TYPE[] = [JOURNAL_ENTRY_TYPE.CREDIT, JOURNAL_ENTRY_TYPE.DEBIT];
  journalEntryCreditTypes: JOURNAL_CREDIT_TYPE[] = [JOURNAL_CREDIT_TYPE.CASH, JOURNAL_CREDIT_TYPE.CHECK,
    JOURNAL_CREDIT_TYPE.CREDIT_CARD,  JOURNAL_CREDIT_TYPE.OTHER,
    JOURNAL_CREDIT_TYPE.CREDIT];
  invoices: BehaviorSubject<Invoice[]> = new BehaviorSubject<Invoice[]>([]);
  updateAmountAllocated: Subject<any> = new Subject<any>();


  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AddJournalEntryComponent>,
    private fb: UntypedFormBuilder, private authenticationService: AuthenticationService, private employeeService: EmployeeService,
    private journalEntryService: JournalEntryService, private snackBar: MatSnackBar,
    private customerCommunicationManagementService: CustomerCommunicationManagementService, private invoiceService: InvoiceService ) {
      this.form = this.createForm();

      const enableCheck = this.form.get("journalEntryCreditType").valueChanges.pipe(
        filter(x => x === JOURNAL_CREDIT_TYPE.CHECK),
        tap(x => this.form.controls["checkNumber"].enable())
      );

      const disableCheck = this.form.get("journalEntryCreditType").valueChanges.pipe(
        filter(x => x !== JOURNAL_CREDIT_TYPE.CHECK),
        tap(x => this.form.controls["checkNumber"].reset()),
        tap(x => this.form.controls["checkNumber"].disable()));

        merge(enableCheck, disableCheck).pipe(
          takeUntil(this.destroyingComponent$)
        ).subscribe();

    if (!data) {
      window.alert("You must select invoices to apply payments to.");
      this.dialogRef.close();
    } else {
      this.patchInvoices(data.invoices.value);
    }

}
  ngAfterViewInit(): void {
    this.updateAmountAllocated.pipe(
      tap(() => this.form.patchValue({"employeeDocId": this.authenticationService.activelyLoggedInEmployeeDocId})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  ngOnInit(): void {
  }

  patchInvoices(invoices: Invoice[]) : void {
    this.invoices.next(invoices);
    if (invoices.length > 0) {
      this.form.patchValue({billingCustomerName: invoices[0].billingCustomer.customerName});
    }
    const invoiceFormArray = this.form.get("invoices") as UntypedFormArray;
    invoices.forEach(i => {
      (invoiceFormArray).push(
        this.fb.group({
          invoice: i,
        }));
    });
  }

  createForm() : UntypedFormGroup {

    return this.fb.group ({
      billingCustomerName: [{value: "", disabled:true}],
      memo:[""],
      amount:[[Validators.min(0), Validators.required], { updateOn: "blur"}],
      journalEntryCreditType:['',[Validators.required]],
      checkNumber: [{value: "", disabled:true}],
      employeeDocId: [this.authenticationService.activelyLoggedInEmployeeDocId],
      invoices: new UntypedFormArray([]),
    }, {validators: this.paymentsAssignedEqualPaymentsReceived});
  }

  cancel() : void {
    this.dialogRef.close();
  }

  journalEntryFromFormAndInvoicePayments(invoicePayements: InvoicePayment[]) : JournalEntry {
    const retVal: JournalEntry = new JournalEntry();
    const form = this.form;
    retVal.memo = form.get("memo").value;
    retVal.billingCustomer = this.invoices.value[0].billingCustomer;
    retVal.amount = form.get("amount").value;
    retVal.journalEntryType = JOURNAL_ENTRY_TYPE.CREDIT;
    retVal.journalEntryCreditType = form.get("journalEntryCreditType").value;
    retVal.checkNumber = form.get("checkNumber").value;
    retVal.employee = this.employeeService.get(form.get("employeeDocId").value);
    invoicePayements.forEach(i => {
      i.memo = retVal.memo;
      i.checkNumber = retVal.checkNumber;
      i.paymentType = retVal.journalEntryCreditType;
    });
    retVal.invoicePayments = invoicePayements;
    return retVal;
  }

  submit() : void
  {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.snackBar.open("Saving",undefined);
      const invoicePaymentsToApply = [];
      this.invoices.value.forEach(i => {
        if (i.AmountAllocatedToJournalEntry > 0) {
          i.amountPaid += i.AmountAllocatedToJournalEntry;
          invoicePaymentsToApply.push(new InvoicePayment({invoice: i, amount: i.AmountAllocatedToJournalEntry}));
        }
      });
      const journalEntry = this.journalEntryFromFormAndInvoicePayments(invoicePaymentsToApply);
      let b: string = null;
      // For each invoice we applied a payment against, we cancel outstanding communications and regenerate as the total amount paid has been updated.
      FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
        tap(batch => b = batch),
        switchMap(() => this.journalEntryService.create$(journalEntry, b)),
        map(j => {
          const firestoreUpdates: Observable<any>[] = [];
          j.invoicePayments.forEach(i => firestoreUpdates.push(this.customerCommunicationManagementService.cancelCustomerCommunicationsForInvoice(i.invoiceDocId,
            "AddJournalEntryComponent.submit",b)));
          return firestoreUpdates;
        }),
      switchMap(cancels => zip(...cancels)),
      take(1),
      // must populate jobs to invoice here.
      map(() => this.invoices.value.filter(x => x.AmountAllocatedToJournalEntry > 0)),
      switchMap(invoices => this.invoiceService.popluateJobsToInvoices(invoices)),
      // regenerate invoice communications for each invoice
      switchMap(invoices => zip(...invoices.map(i => this.customerCommunicationManagementService.createInvoiceCustomerCommuinications(i.job,
        CustomerCommunicationCategory.PAYMENT,i.job.siteVisits[0], i, "add journal entry component", b)))),
        switchMap(() => this.invoiceService.commitExternallyManagedFirestoreBatch(b)),
        take(1),
      ).subscribe(() => {
        this.snackBar.dismiss();
        this.dialogRef.close();
      });
    }
  }

  logIt() : void {
    this.form.updateValueAndValidity();
    console.log(this.form);
  }


paymentsAssignedEqualPaymentsReceived: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const amount = control.get("amount").value;
  const invoices = control.get("invoices") as UntypedFormArray;
  const totalPayments = invoices.controls.reduce((acc, curr) => {
    return acc + curr.value.invoice["AmountAllocatedToJournalEntry"];
  }, 0);
  const retVal = amount && totalPayments && amount !== totalPayments ? { paymentsAssignedUn: true } : null;
  return retVal;

};


}
