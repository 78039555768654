import { Injectable } from '@angular/core'
;
import { AuthenticationService } from '../../util/authentication.service'
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { TaxRateSettings } from '../dao/tax-rate-settings';

@Injectable({
  providedIn: 'root'
})
export class TaxRateSettingsService extends DatabaseStoreService<TaxRateSettings> {

  populateInitial() {
    const a = new TaxRateSettings();
    a.name= "Maryland";
    a.percent= .06;
    const b = new TaxRateSettings();
    a.percent = .053;
    a.name = "Virginia"
    this.createMultiple$([a,b]).subscribe();
  }

  constructor( fs: TaxRateSettingsFirestoreService, authenticationService: AuthenticationService, store: TaxRateSettingsStoreService) {
super(fs, store, authenticationService);
  }

}

@Injectable({
  providedIn: 'root'
})
export class TaxRateSettingsStoreService extends StateChangeStoreService<TaxRateSettings> {
  protected store = "TaxRateSettings-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, TaxRateSettings>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class TaxRateSettingsFirestoreService extends FirestoreBackend<TaxRateSettings> {

 protected basePath = "TaxRateSettings";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): TaxRateSettings {
return new TaxRateSettings(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new TaxRateSettings(), authService);
 }
}
