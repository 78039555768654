import { Component, OnInit,  Inject, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { Customer } from '../../../../../common/src/data/dao/customer';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { of, Observable, Subject, merge, zip, BehaviorSubject, combineLatest } from 'rxjs';
import { SiteVisitLengthComponent } from '../site-visit-length/site-visit-length.component';
import { take, switchMap,  map, takeUntil, debounceTime, tap, share, filter } from 'rxjs/operators';
import { LineItem } from '../../../../../common/src/data/dao/line-item';
import { SiteVisitService } from '../../../../../common/src/data/dao-services/site-visit.service';
import { SiteVisit } from '../../../../../common/src/data/dao/site-visit';
import { GenericServiceProviderSetting } from '../../../../../common/src/data/dao/generic-service-provider-setting';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { addHours, addMinutes, getHours, getMinutes, startOfDay, subMinutes } from 'date-fns';
import { AddressRouting} from '../../../../../common/src/data/dao/address-routing';
import { AddressRoutingService } from '../../../../../common/src/data/dao-services/address-routing.service';
import { PhysicalAddressRoutingService } from 'web-app/src/app/physical-address-routing.service';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import { NavigationStart, Router, RouterEvent, Event } from '@angular/router';
import { Job } from '../../../../../common/src/data/dao/job';
import { MultipleJobSummaryComponent } from 'web-app/src/app/multiple-job-summary/multiple-job-summary.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CancelJobConfirmationModalComponent } from 'web-app/src/app/jobs/cancel-job-confirmation-modal/cancel-job-confirmation-modal.component';
import { AddressService } from '../../../../../common/src/data/dao-services/address.service';
import {ResourceDayAddressRoutingService} from '../../../../../common/src/data/dao-services/resource-day-address-routing.service';
import {ResourceDayService} from '../../../../../common/src/data/dao-services/resource-day.service';
import { SiteVisitRescheduleModalComponent } from '../../jobs/site-visit-reschedule-modal';
import { SiteVisitRescheduleReasons } from '../../jobs/site-visit-reschedule-modal/site-visit-reschedule-modal.component';
import { JobAttentionRequired, JOB_ATTENTION_REQUIRED } from '../../../../../common/src/data/dao/job-attention-required';
import { JobDurationDeltaModificationType } from '../../../../../common/src/data/dao/job-duration-delta';
import { LineItemRemovalWithReason } from '../../line-item/line-item-display/line-item-display.component';
import { limit, where } from 'firebase/firestore';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDetailsComponent implements OnInit, OnDestroy {

  jobCountAtAddress$: BehaviorSubject<string> = new BehaviorSubject<string>("0");
  siteVisitDetailsFormGroup: UntypedFormGroup;
  customer: Customer;
  customerTags: GenericServiceProviderSetting[];
  jobTags: GenericServiceProviderSetting[];
  job: Job;
  lineItems: BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
  disableInput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  title: string;
  siteVisitDocId: string;
  outstandingTimeOnJob: boolean;
  updateArrivalWindowStart$: Subject<Date> = new Subject<Date>();
  updateArrivalWindowEnd$: Subject<Date> = new Subject<Date>();
  updateCommuteTime$: Subject<Date> = new Subject<Date>();
  destructionNotifier$ = new Subject();
  siteVisit: SiteVisit;
  fidelitySiteVisitArrivalWindows!: number;
  fidelityCommuteTime!: number;
  zeroHoursAsDate = startOfDay(new Date());
  maxCommuteAsDate= addHours(startOfDay(new Date()),4);
  employeeDocId: string;
  refreschScheduleOnExit: boolean = false;

  updatedArrivalWindow: boolean = false;
  originalArrivalWindowStart: Date = undefined;
  originalArrivalWindowEnd: Date = undefined;

  //Line item modifications
  addLineItem$ = new Subject<LineItem>();
  removeLineItem$ = new Subject<LineItemRemovalWithReason>();
  editLineItem$ = new  Subject<{old: LineItem, new: LineItem}[]>();

  rescheduleSiteVisitDialogRef: MatDialogRef<SiteVisitRescheduleModalComponent>;

  get noOutstandingTimeOnJob()  { return !this.outstandingTimeOnJob;}

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<EventDetailsComponent>,
    private dialog: MatDialog, private siteVisitService: SiteVisitService, private jobService: JobService,
    private addressRoutingService: AddressRoutingService, private physicalAddressRoutingService: PhysicalAddressRoutingService,
    private settingsService: SettingsService, private employeeService: EmployeeService, private router: Router, private snackBar: MatSnackBar,
    private addressService: AddressService, private resourceDayAddressRoutingService: ResourceDayAddressRoutingService, private resourceDayService: ResourceDayService) {

    this.fidelitySiteVisitArrivalWindows! = this.settingsService.getValue('minutesFidelityArrivalWindow');
    this.fidelityCommuteTime = this.settingsService.getValue('minutesFidelityCommuteTimes') > 5 ? this.settingsService.getValue('minutesFidelityCommuteTimes') : 5;

    if (data) {
      console.log(data);
      this.siteVisitDetailsFormGroup = data.siteVisitDetailsFormGroup;
      this.customer = data.customer;
      this.customerTags = data.customerTags;
      this.jobTags = data.jobTags;
      this.title = data.title;
      this.siteVisitDocId = data.siteVisitDocId;
      this.outstandingTimeOnJob = data.outstandingTimeOnJob;
      this.siteVisit = data.siteVisit;
      this.originalArrivalWindowStart = new Date(this.siteVisit.arrivalWindowStartDate);
      this.originalArrivalWindowEnd = new Date(this.siteVisit.arrivalWindowEndDate);
      this.employeeDocId = data.employeeDocId;
      this.job = data.job;
      this.lineItems.next(data.job.lineItems.concat(data.job.abandonedLineItems));
    }

    dialogRef.beforeClosed().pipe(
      take(1),
      ).subscribe(val => {
      let dialogRes: any = {
        refreschScheduleOnExit : this.refreschScheduleOnExit,
        updatedArrivalWindow : this.updatedArrivalWindow,
        originalArrivalWindowStart : this.originalArrivalWindowStart,
        originalArrivalWindowEnd : this.originalArrivalWindowEnd,
      };
      if (val) {
        dialogRes = {...val,...dialogRes};
      }
      dialogRef.close(dialogRes);
    });
  }

  fileNameDialogRef: MatDialogRef<SiteVisitLengthComponent>;
  PropertyHistoryModalRef: MatDialogRef<MultipleJobSummaryComponent>;

  ngOnDestroy() {
    this.destructionNotifier$.next(null);
    this.destructionNotifier$.complete();
  }

  getTimeWindowFiveMinuteFidelity(date: Date) : Date{
    const hours = getHours(date);
    const minutes = getMinutes(date);
    let retVal: Date;
    retVal = addMinutes(startOfDay(date),hours*60 + (minutes === 0 ? 0 : Math.round(minutes / 5) * 5));
    return retVal;
  }

  getTimeWindowDateRespectingFidelity(arrivalDate: Date, arrivalDateEvent: string) {
    const hours = getHours(arrivalDate);
    const minutes = getMinutes(arrivalDate);
    let retVal: Date;
    if (arrivalDateEvent === "arrive") {
      retVal = addMinutes(startOfDay(arrivalDate),hours*60 + (minutes === 0 ? 0 : Math.round(minutes / this.fidelitySiteVisitArrivalWindows )) * this.fidelitySiteVisitArrivalWindows);
    } else if (arrivalDateEvent === "depart") {
      if ((minutes / this.fidelityCommuteTime + 1) * this.fidelityCommuteTime > 60) {
        retVal =  addHours(startOfDay(arrivalDate),hours+1);
      } else {
        retVal =  addMinutes(startOfDay(arrivalDate),hours*60 + (minutes === 0 ? 0 : Math.round(minutes / this.fidelitySiteVisitArrivalWindows )) * this.fidelitySiteVisitArrivalWindows);
      }
    }
    return retVal;
  }

  formatHours(hours: number): string {
    return `${Math.floor(hours)}H, ${Math.floor((hours-Math.floor(hours))*60)}M`;
  }

  messageUserJobLengthChanged(previousLineItemExpectedHours: number, newLineItemExpectedHours: number) {
    if (previousLineItemExpectedHours !== newLineItemExpectedHours) {
      this.snackBar.open(`Expected hours from line items changed from ${this.formatHours(previousLineItemExpectedHours)} to ${this.formatHours(newLineItemExpectedHours)}, consider changing site visit length.`,
        'Ok',{duration: 5000});
    }
  }

  ngOnInit(): void {

    const addLineItems$ = this.addLineItem$.pipe(
    tap(lineItem => {
      const oldJobDurationHours = this.job.lineItemExpectedHours;
      this.job.lineItems.push(lineItem);
      this.messageUserJobLengthChanged(oldJobDurationHours, this.job.lineItemExpectedHours);
    }));

    const removeLineItmes$ = this.removeLineItem$.pipe(
      tap(lineItemWithRemovalReason => {
        const oldJobDurationHours = this.job.lineItemExpectedHours;
        const indexOfLineItem = this.job.lineItems.indexOf(lineItemWithRemovalReason.lineItem);
          this.job.lineItems.splice(indexOfLineItem,1);
          this.messageUserJobLengthChanged(oldJobDurationHours, this.job.lineItemExpectedHours);
      }));

    const editLineItems$ = this.editLineItem$.pipe(
        map(x => x[0]),
        tap(x => {
          const oldJobDurationHours = this.job.lineItemExpectedHours;
          const indexOfLineItem = this.job.lineItems.findIndex(z => z.DocId() === x.old.DocId());
          this.job.lineItems.splice(indexOfLineItem,1,x.new);
          this.messageUserJobLengthChanged(oldJobDurationHours, this.job.lineItemExpectedHours);
        }));

    merge(addLineItems$, removeLineItmes$, editLineItems$).pipe(
      tap(() => this.lineItems.next(this.job.lineItems)),
      switchMap(() => this.jobService.update$(this.job)),
    takeUntil(this.destructionNotifier$)
    ).subscribe();

    this.jobService.queryFirestoreShallow$([where('serviceAddressDocId', '==', this.siteVisit.siteVisitAddress.docId),
      limit(25)]).pipe(
      map(q => (q as Job[]).filter(j => j.DocId() !== this.siteVisit.jobDocId)),
      map(q => q.length >= 25 ? ">25" : `${q.length}`),
      takeUntil(this.destructionNotifier$)
    ).subscribe(x => this.jobCountAtAddress$.next(x));

    this.router.events.pipe(
      filter((event: Event | RouterEvent) : event is RouterEvent => event instanceof NavigationStart),
       filter(() => !!this.dialogRef),
       takeUntil(this.destructionNotifier$)
     ).subscribe(() => this.dialogRef.close());

    const arrivalWindowStartUpdate$ = this.updateArrivalWindowStart$.pipe(
      tap(() => this.updatedArrivalWindow = true),
      map(x => {
        this.siteVisit.arrivalWindowStartDate = x;
        return ({val: this.siteVisit, wb:null});
      }));

    const arrivalWindowEndUpdate$ = this.updateArrivalWindowEnd$.pipe(
      tap(() => this.updatedArrivalWindow = true),
      map(x => {
        this.siteVisit.arrivalWindowEndDate = x;
        return ({val: this.siteVisit, wb:null});
      })
    );

    const commuteTimeUpdated$ = this.updateCommuteTime$.pipe(
      map(x => {
        const timeMinutes = x.getHours() * 60 + x.getMinutes();
        this.siteVisit.commuteTimeMinutesToSite = timeMinutes;
        const orginAddressDocId = this.siteVisit.previousSiteVisit ? this.siteVisit.previousSiteVisit.siteVisitAddress.DocId() :
        this.employeeService.get(this.employeeDocId).dispatchOrginAddressDocId;
        const destinationAddressDocId = this.siteVisit.siteVisitAddress.DocId();
        // update prospective commute in site visit object.
        this.siteVisit.prospectiveCommutes.filter(y => y.destinationAddressDocId === destinationAddressDocId && y.orginAddressDocId === orginAddressDocId).forEach(
          y => y.commuteStartTime = subMinutes(this.siteVisit.startDate, timeMinutes));
        // update site visit address (so new commute time is populated down to resource day properly)
        this.siteVisit.siteVisitAddress.commuteMatrixGenerationTime = new Date();
        if (this.siteVisit.previousSiteVisit === undefined) {
          return {commuteMinutes: timeMinutes, orginAddressDocId: destinationAddressDocId, destinationAddressDocId: orginAddressDocId};
        } else {
          return {commuteMinutes: timeMinutes, orginAddressDocId: orginAddressDocId, destinationAddressDocId: destinationAddressDocId};
        }
      }),
      switchMap( x=> this.addressService.retrieveFirestoreBatchString().pipe(
        map(wb => {
          return {val: x, wb: wb}
        })
      )),
      // address routing entries to update.
      switchMap( x => combineLatest([
        this.addressRoutingService.queryFirestoreDeep$([where("originAddressDocId", "==", x.val.orginAddressDocId),
          where("destinationAddressDocId","==", x.val.destinationAddressDocId)]),
      this.addressRoutingService.queryFirestoreDeep$([where("originAddressDocId", "==", x.val.destinationAddressDocId),
        where("destinationAddressDocId","==", x.val.orginAddressDocId)])
    ]).pipe(
      map(q => ({commuteTime: x.val.commuteMinutes, orginAddress: x.val.orginAddressDocId, destinationAddress: x.val.destinationAddressDocId,
        mappings: q[0].concat(q[1]), wb: x.wb})),
        take(1),
    )),
    switchMap(x => combineLatest([
    // also need to update matching resource day entries.
    this.resourceDayAddressRoutingService.queryFirestoreDeep$([where("originAddressDocId", "==", x.destinationAddress),
      where("destinationAddressDocId","==", x.orginAddress),
      where("resourceDay", ">=", startOfDay(this.siteVisit.startDate))]),
    this.resourceDayAddressRoutingService.queryFirestoreDeep$([where("originAddressDocId", "==", x.orginAddress),
      where("destinationAddressDocId","==", x.destinationAddress),
      where("resourceDay", ">=", startOfDay(this.siteVisit.startDate))])
    ]).pipe(
      map(q => ({commuteTime: x.commuteTime, orginAddress: x.orginAddress, destinationAddress: x.destinationAddress, mappings: x.mappings, resouceDayMappings: q[0].concat(q[1]), wb: x.wb})),
        take(1),
    )),
    switchMap(p => {
    const resourceDayMappings = p.resouceDayMappings.filter(x => x.resourceDay !== undefined).map(x => ({resourceDay: x.resourceDay, employeeDocId: x.employeeDocId}));
    const distinctResourceDayMappings = resourceDayMappings.filter((thing, i, arr) => {
      return arr.indexOf(arr.find(t => t.resourceDay === thing.resourceDay && t.employeeDocId === thing.employeeDocId)) === i;
    });
    return zip(...distinctResourceDayMappings.map(x => this.resourceDayService.queryFirestoreDeep$([where("resourceDay", "==", x.resourceDay),
      where("employeeDocId", "==", x.employeeDocId)]))).pipe(
        map(q => ({commuteTime: p.commuteTime, orginAddress: p.orginAddress, destinationAddress: p.destinationAddress, mappings: p.mappings, resouceDayMappings: p.resouceDayMappings,
            wb: p.wb, resourceDayDocs: q.flatMap(x => x)})),
        take(1),
      )}),
        share()
        );

    const commuteTimePreviouslyPopulated = commuteTimeUpdated$.pipe(
      filter(p => p.mappings.length > 0),
      map(p => {
        const retVal: Observable<string>[] = [];
        const addressRetVal: AddressRouting[] = [];
        p.mappings.forEach(m => {
          m.timeEstimateSeconds = p.commuteTime*60;
          this.physicalAddressRoutingService.addToCommuteMatrix(m);
          addressRetVal.push(m);
        });
        addressRetVal.forEach(x => retVal.push(this.addressRoutingService.update$(x, p.wb).pipe(
          map(b => p.wb)
        )
        ));
        //update resource day address routing entries.
        p.resouceDayMappings.forEach(m => {
          m.timeEstimateSeconds = p.commuteTime*60;
          retVal.push(this.resourceDayAddressRoutingService.update$(m, p.wb).pipe(
            map(b => p.wb)
          ));
        });
        // update associated resource days.
        p.resourceDayDocs.forEach(m => {
          m.lastUpdatedAt = new Date();
          retVal.push(this.resourceDayService.mergeUpdate(m, ['lastUpdatedAt'],p.wb).pipe
          (map(b => p.wb)));
      });
      // update address proper.
      this.siteVisit.siteVisitAddress.commuteMatrixGenerationTime = new Date();
      retVal.push(this.addressService.mergeUpdate(this.siteVisit.siteVisitAddress, ['commuteMatrixGenerationTime'], p.wb).pipe(
        map(b => p.wb)
      ));
        return retVal;
      }),
      switchMap(x => zip(...x).pipe(map(q => q[0]))),
      );

    const newlyPopulatedCommuteTime = commuteTimeUpdated$.pipe(
      filter(p => p.mappings.length === 0),
      map(x => ({val: new AddressRouting({originAddressDocId: x.orginAddress, destinationAddressDocId: x.destinationAddress,
        timeEstimateSeconds: x.commuteTime*60, addressRoutingSource: "SERVICE_PROVIDER",
        reversedFromRequestedRoute: false }), wb: x.wb})),
      tap(x => this.physicalAddressRoutingService.addToCommuteMatrix(x.val)),
      switchMap(x => this.addressRoutingService.create$(x.val,x.wb).pipe(
        map(b => x.wb))
      ));

    const updatedCommute = merge(commuteTimePreviouslyPopulated,newlyPopulatedCommuteTime).pipe(
      tap(() => this.refreschScheduleOnExit = true),
      map(x => ({val: this.siteVisit, wb: x}))
    );

    this.siteVisitDetailsFormGroup.get("siteVisitNotes").valueChanges.pipe(
      debounceTime(100),
      map(x => {
        this.siteVisit.notes = x;
        return this.siteVisit;
      }),
      switchMap(x => this.siteVisitService.update$(x)),
      takeUntil(this.destructionNotifier$)
    ).subscribe();

    const updateJobNotes$ = this.siteVisitDetailsFormGroup.get("jobNotes").valueChanges.pipe(
      debounceTime(100),
      map(x => {
        this.job.notes = x;
        return this.job;
      })
    );

    // Site Visit updates
    merge(arrivalWindowEndUpdate$, arrivalWindowStartUpdate$, updatedCommute).pipe(
      tap(x => console.log(x)),
      switchMap(x => this.siteVisitService.update$(x.val, x.wb).pipe(
        map(b => x.wb)
      )),
      switchMap(x => this.addressService.commitExternallyManagedFirestoreBatch(x)),
      takeUntil(this.destructionNotifier$)
    ).subscribe();

    // Job Updates
    updateJobNotes$.pipe(
      switchMap(x => this.jobService.update$(x,null)),
      takeUntil(this.destructionNotifier$)
    ).subscribe();

  }

  public ViewPropertyHistoryClick() {
    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         :
      {
        title: "Service Address History",
        jobs$: this.jobService.queryFirestoreDeep$([where('serviceAddressDocId', '==', this.siteVisit.siteVisitAddress.DocId()),
          limit(25)]),
      }
      });
    this.PropertyHistoryModalRef = this.dialog.open(MultipleJobSummaryComponent, editorConfig);
  }

  modifyClose() {
    this.dialogRef.close({ siteVisitDocId: this.siteVisitDocId, action: "modify" });
  }

  deleteClose() {
    if (this.job.siteVisits.length === 1 && !(this.job.needsAssigned && this.job.remainingToScheduleHours > 0)) {
      const editorConfig = new MatDialogConfig();
        Object.assign(editorConfig, {
          disableClose : false,
          autoFocus    : true,
          width        : '700px',
          outerHeight  :  '200px',
          data         :
          {
            job: this.job
          }
          });
        let dialogRef = this.dialog.open(CancelJobConfirmationModalComponent, editorConfig);
        dialogRef.afterClosed().subscribe(result => {
          // Note: If the user clicks outside the dialog or presses the escape key, there'll be no result
          if (result && result.yes) {
            this.dialogRef.close({ siteVisitDocId: this.siteVisitDocId, jobDocId: this.job.DocId(), action: "cancel_job"});
          }
      });
    } else {
      this.dialogRef.close({ siteVisitDocId: this.siteVisitDocId, action: "delete"});
    }
  }

  get formattedCustomerPhoneNumber() {
    // format customer phone number
    if (this.siteVisitDetailsFormGroup.get('customerPhone').value === null) {
      return "";
    } else {
      return this.siteVisitDetailsFormGroup.get('customerPhone').value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }

  rescheduleClose() {

    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      disableClose : false,
      width        : '500px',
      data: {
        title: "Reschedule Site Visit",
      }
      });

    this.rescheduleSiteVisitDialogRef = this.dialog.open(SiteVisitRescheduleModalComponent, editorConfig);


     const success = this.rescheduleSiteVisitDialogRef.afterClosed().pipe(
      filter(result => result !== undefined),
      // send along job attention that needs stored to database w/ job.
      map(x => {
        const resecheduleReason = x.reason as SiteVisitRescheduleReasons;
        const jobAttentionRequiredReason = resecheduleReason === SiteVisitRescheduleReasons.NEED_PART ? JOB_ATTENTION_REQUIRED.WAITING_FOR_PART : JOB_ATTENTION_REQUIRED.FIELD_TECH_RESCHEDULED;
        const jobAttention = new JobAttentionRequired({jobAttentionRequiredReason,notes: x.notes, jobAttentionRequiredSubReason: resecheduleReason.toString()});
        // When you reschedule a site visit, it's duration is removed from the time scheduled to spend on job so you will (properly) reschedule site visits current duration
        // w/o the job attention adding any additional time.
        const siteVisitDurationAsDate = startOfDay(new Date());
        jobAttention.additionalTimeNeedingScheduled = siteVisitDurationAsDate;
        jobAttention.originatingLineItemDocId = null;
        jobAttention.siteVisitDocId = this.siteVisit.DocId();
        return jobAttention;
      }),
      switchMap(attention => this.jobService.addJobAttentionRequired(attention,JobDurationDeltaModificationType.SITEVISITRESCHEDULED, this.job)),
      tap(() => this.dialogRef.close({ siteVisitDocId: this.siteVisitDocId, action: "reschedule"}))
     );

     const cancel = this.rescheduleSiteVisitDialogRef.afterClosed().pipe(
      filter(result => result === undefined),
      tap(() => window.alert("You must specify a reason for rescheduling."))
     );

     merge(success,cancel).pipe(
      take(1)
      ).subscribe();
  }

  simulateSiteVisit() {
    this.siteVisit.actualStartDate = addMinutes(this.siteVisit.startDate,20);
    this.siteVisit.actualEndDate = addMinutes(this.siteVisit.endDate,20);
    this.siteVisitService.update$(this.siteVisit).pipe(
      take(1)
    ).subscribe();
  }

  addSiteVisit() {

    const suggestedHoursToSchedule = 1;
    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      data         : {
        suggestedHoursToSchedule,
        action: "addSiteVisit"
      }
      });
    this.fileNameDialogRef = this.dialog.open(SiteVisitLengthComponent);
  // Event dialog closure:
    this.fileNameDialogRef.afterClosed().pipe(take(1))
    .subscribe(x => this.specifySiteVisitHoursDialogClosure(x));
  }

  siteVisitFromUnassigned() {
    console.log(this.siteVisitDetailsFormGroup.value);
    const suggestedHoursToSchedule = this.siteVisitDetailsFormGroup.value["hoursRequired"] -
      this.siteVisitDetailsFormGroup.value["hoursScheduled"];
    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      data         : {
        suggestedHoursToSchedule,
        action: "siteVisitFromUnassigned"
      }
      });
    this.fileNameDialogRef = this.dialog.open(SiteVisitLengthComponent);
  // Event dialog closure:
    this.fileNameDialogRef.afterClosed().pipe(take(1))
    .subscribe(x => this.specifySiteVisitHoursDialogClosure(x));
  }

  specifySiteVisitHoursDialogClosure(result: any) {
    if (result.action === "addSiteVisit") {
      const hoursFromDateToAdd = result.hoursToAdd.getHours() + result.hoursToAdd.getMinutes() / 60;
      this.dialogRef.close({ siteVisitDocId: this.siteVisitDocId, numberHours: hoursFromDateToAdd, action: "addSiteVisit"});
    }
    if (result.action === "siteVisitFromUnassigned") {
      this.dialogRef.close({ siteVisitDocId: this.siteVisitDocId, numberHours: result.hoursToAdd, action: "siteVisitFromUnassigned"});
    }
  }

}
