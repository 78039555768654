import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {

  constructor(
    public authService: AuthenticationService,
    public router: Router,
    // private settingsService: SettingsService
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedIn$.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['sign-in'],{queryParams:{'redirectURL':state.url}});
        }
      }),
    );
  }
}
