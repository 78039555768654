import { Injectable } from '@angular/core'

import { FirestoreDiffService } from './firestore-diff.service';
import { EmployeeGeofence } from '../dao/employee-geofence';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { AuthenticationService } from '../../util/authentication.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { AddressService } from './address.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGeofenceService extends DatabaseStoreService<EmployeeGeofence> {

  constructor( fs: EmployeeGeofenceFirestoreService, store: EmployeeGeofenceStoreService, authenticationService: AuthenticationService,
    private addressService: AddressService) {
super(fs, store, authenticationService, new Map([
  ["address", {associatedDocumentId: "addressDocId", compositionStoreService: addressService}]]));
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmployeeGeofenceStoreService extends StateChangeStoreService<EmployeeGeofence> {
  protected store = "EmployeeGeofence-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, EmployeeGeofence>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class EmployeeGeofenceFirestoreService extends FirestoreBackend<EmployeeGeofence> {

 protected basePath = "EmployeeGeofence";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): EmployeeGeofence {
return new EmployeeGeofence(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new EmployeeGeofence(), authService);
 }
}
