import { AfterViewInit, ChangeDetectionStrategy, Component,  OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {  merge,  Observable,  of, Subject, switchMap } from 'rxjs';
import {  delay, filter,  map,  pairwise,  startWith,  take,  takeUntil, tap } from 'rxjs/operators';
import { ChoiceComponent, ChoiceControlType } from '../choice/choice.component';
import { ControlContainerComponent } from '../component-models/control-container.component';
import { FormlyUtilityService } from '../component-models/formly-controls/formly-utility.service';
import { ControlReportsOnViewInitilizationInterface } from '../containsComponents';
import { FormFirestoreService } from '../../../../../common/src/data/dao-services/form-firestore.service';
import { SectionComponent } from '../section/section.component';


@Component({
selector: 'app-branching-container',
templateUrl: './branching-container.component.html',
styleUrls: ['./branching-container.component.scss'],
changeDetection: ChangeDetectionStrategy.OnPush
})
export class BranchingContainerComponent extends ControlContainerComponent implements OnInit, AfterViewInit, ControlReportsOnViewInitilizationInterface {

  static fb: UntypedFormBuilder = new UntypedFormBuilder();

  patchToChooser: any = undefined;
  chooserFormlyFieldConfigArray : FormlyFieldConfig[] = [];

  previouslyAddedFormFirestoreSummaryDocId: string;
  sectionIdsToDocIds: Map<string,string> = new Map<string,string>();
  populatedFormFirestoreSummaryDocId: string;

  afterViewInitilized$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('choice') chooser: ChoiceComponent;

  get changeDetect() : Observable<any> { return (this.fields[0] as FormlyFieldConfig).props.changeDetect as Observable<any>; }

  get chooserKey() : string { return (this.chooser.liveFormlyFieldConfig.key as string).replace("0.", ''); }

  constructor(fb: UntypedFormBuilder, private formlyUtilityService: FormlyUtilityService, private formFirestoreService: FormFirestoreService)  {
    super(BranchingContainerComponent,fb);
    this.form = this.initilizeFormGroup();
    this.fields = [this.toFormlyFieldConfig()];

    this.unfilteredComponentClicked$.pipe(
    takeUntil(this.destroyingComponent$)
    ).subscribe(this.filteredComponentClicked$);

  }

patchControlComponentsToFormlyFields(): void {
  const obs$ = this.patchControlComponentsToFormlyFieldsCommon([(this.fields as FormlyFieldConfig)]);
}

patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {
  super.patchCommonFieldsToForm(formlyConfig);
  formlyConfig.fieldGroup = (formlyConfig.fieldArray as FormlyFieldConfig).fieldGroup;
  formlyConfig.fieldArray=undefined;
  this.patchToChooser = formlyConfig.fieldGroup[0];

  this.componentForm.get("label").patchValue(formlyConfig.fieldGroup[0].props.label);
}

toFormlyFieldConfigJsonOnly(): FormlyFieldConfig  {
  const ret = this.toFormlyFieldConfig();
  const {fieldGroup,...retVal} = ret;
  retVal.fieldArray = {
    fieldGroup: [((this.componentForm.get("chooserControl").value as ControlContainerComponent).toFormlyFieldConfigJsonOnly() as FormlyFieldConfig)]
  };
  retVal.wrappers=[];
  const {changeDetect,destroyComponents$,guid,...temps} = retVal.props;
  retVal.props = temps;
  return retVal;
}

toFormlyFieldConfig(): FormlyFieldConfig {
  const retVal =
   {
    type: 'formlyBranchingContainer',
    defaultValue: [{}],
    key: `${this.perInstance}-branching-container`,
    fieldGroup: this.chooserFormlyFieldConfigArray,

    props: {
    label:"",
    className:"",
    changeDetect: this.changeDetect$,
    destroyComponents$: new Subject<any>(),
    guid: this.guid,
    },
    wrappers: ["change-detect"],
    };

    return retVal;
}

static buildBranchingFormGroup() : UntypedFormGroup {
return this.fb.group({
chooserFormGroup: null,
chooserControl: [],
label: ["Branching Label"],
});
}

initilizeFormGroup(): UntypedFormGroup {
const retVal = this.createDefaultControlContainerFormGroup("Branching");
retVal.patchValue({
icon: "account_tree",
iconColor: "#868686",
controlComponentFormGroup: BranchingContainerComponent.buildBranchingFormGroup(),
ControlRequiresFullWidth: true,
});
return retVal;
}


getParentDropZoneId() : string {
return this.parentContainer ?  (this.parentContainer as SectionComponent).dropZoneIdAssigned : "mainDragDropZone";
}

addFormFirestoreSection(formFirestoreSummaryDocId: string) {
  this.formlyUtilityService.addFormFirestoreToFormDesigner$.next({firestoreSummaryDocId: formFirestoreSummaryDocId, originSourceGuid: this.chooser.guid,
      parentContainerGuid: this.guid, parentDropZoneId: this.getParentDropZoneId()});
  setTimeout(() => this.changeDetect$.next(null), 100);
}

getBranchingSingleValueChangesObservable() {
  return this.chooser.formlyForm.valueChanges.pipe(
    delay(1),
    map(x => x[0][this.chooserKey]),
    filter(x => x !== undefined && x.toString() !== "[object Object]"),
    filter(() => this.chooser.componentForm.get("choiceControlType").value !== ChoiceControlType.CheckBoxes),
    tap(x => {
      if (this.previouslyAddedFormFirestoreSummaryDocId) {
        this.formlyUtilityService.removeSectionFromDesignView$.next({formFirestoreSummaryDocId: this.previouslyAddedFormFirestoreSummaryDocId,
          originSourceGuid: this.chooser.guid, parentContainerGuid: this.guid, parentDropZoneId: this.getParentDropZoneId()});
      }
      this.previouslyAddedFormFirestoreSummaryDocId = x;
    }),
    tap(x => this.addFormFirestoreSection(x)),
  );
}


getBranchingMultiselectValueChangesObservable() {
  return merge(of(null),this.chooser.formlyForm.valueChanges).pipe(
    delay(1),
    filter(() => this.chooser.componentForm.get("choiceControlType").value === ChoiceControlType.CheckBoxes),
    pairwise(),
    // after below transformation, emitted value is docId : true || false || not present (also false)
    map(x => {
      const key = this.chooserKey;
      return {old: x[0] === null ? null : x[0][0][key], new: x[1][0][key]};
    }),
    tap(x => {
      for ( const val in x.new)  {
        if (x.new[val] === true && (x.old === undefined || x.old === null ||  !x.old[val] )) {
          this.addFormFirestoreSection(val);
        } else {
          if(x.new[val] === false && (x.old && x.old[val] )) {
            this.formlyUtilityService.removeSectionFromDesignView$.next({formFirestoreSummaryDocId: val,
              originSourceGuid: this.chooser.guid, parentContainerGuid: this.guid, parentDropZoneId: this.getParentDropZoneId()})
          }
        }
      };
    }),
  );
}



ngAfterViewInit(): void {

  if (this.chooser !== undefined) {
    this.chooser.componentForm.get("choiceControlType").valueChanges.pipe(
      tap(() => {
        (this.chooser.fields[0].props.options as any[]).forEach(x => {
          this.formlyUtilityService.removeSectionFromDesignView$.next({formFirestoreSummaryDocId: x.value,
            originSourceGuid: this.chooser.guid, parentContainerGuid: this.guid, parentDropZoneId: this.getParentDropZoneId()})
        })
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }


  this.formlyUtilityService.sectionKeyAddedToFormDesigner$.pipe(
    filter(x => x.source === (this.chooser.guid+this.guid)),
    tap(x => this.sectionIdsToDocIds.set(x.sectionKey, x.firestoreDocId)),
    takeUntil(this.destroyingComponent$)
  ).subscribe();

  if (this.patchToChooser === undefined) {
    const chooserFormGroup = this.chooser.form.get("controlComponentFormGroup").value as UntypedFormGroup;
    (chooserFormGroup.get("options") as UntypedFormArray).clear();
    chooserFormGroup.patchValue({allowAddFromDragDrop: false});
  }

this.patchControlComponentsToFormlyFields();
this.form.patchValue({triggerUpdate: {}});
if (this.chooser.fields[0].formControl !== undefined) {
  merge(this.getBranchingSingleValueChangesObservable(),this.getBranchingMultiselectValueChangesObservable()).pipe(
    takeUntil(this.destroyingComponent$)
  ).subscribe();
}
this.componentForm.patchValue({chooserFormGroup: this.chooser.form.get("controlComponentFormGroup"), chooserControl: this.chooser});

this.componentForm.get("label").valueChanges.pipe(
  startWith(this.componentForm.get("label").value),
  tap(label => this.chooser.form.get("controlComponentFormGroup").value.patchValue({label: label})),
  takeUntil(this.destroyingComponent$)
).subscribe();

this.chooser.fields.forEach(field => {
field.props.disabled=false;});

this.chooserFormlyFieldConfigArray.push(this.chooser.toFormlyFieldConfig());
this.chooser.form.get("controlComponentFormGroup").value.get("choiceControlType").valueChanges.pipe(
  tap(() => {
    this.chooserFormlyFieldConfigArray.splice(0,1,this.chooser.toFormlyFieldConfig());
    this.form.patchValue({triggerUpdate: {}});
  }),
  takeUntil(this.destroyingComponent$)
).subscribe();
this.changeDetect$.next(null);

of(true).pipe(take(1)).subscribe(this.afterViewInitilized$);
}

test() {
  console.log(this.chooser);
  console.log(this.form);
  console.log(this.chooser.formlyForm);
}

ngOnInit(): void {
this.form.get("focused").valueChanges.pipe(
tap(x => this.changeDetect$.next(null)),
takeUntil(this.destroyingComponent$)
).subscribe();

}

}
