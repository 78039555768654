import { AfterViewInit, ChangeDetectionStrategy,   ChangeDetectorRef,   Component, ComponentFactoryResolver,  ElementRef,  HostListener,  Injector, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DragDropZone } from '../../../shared/Track.model'
import { CdkDragDrop,  moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BehaviorSubject, combineLatest, debounce, interval, merge, Observable,of,Subject, zip } from 'rxjs';
import { ControlContainer, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ImageControlComponent } from '../component-models/image-control/image-control.component';
import { TextboxControlComponent } from '../component-models/textbox-control/textbox-control.component';
import { WhitespaceControlComponent } from '../component-models/whitespace-control/whitespace-control.component';
import { ControlContainerComponent, FormlyView } from '../component-models/control-container.component';
import { ColumnSplitterComponent } from '../column-splitter/column-splitter.component';
import {  debounceTime, delay, distinctUntilChanged, filter, map, mergeMap,  pairwise,  switchMap,  take, takeUntil, tap } from 'rxjs/operators';
import { FormBuilderDirective } from '../form-builder.directive';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ComponentFromFormlyFactoryService } from '../component-from-formly-factory.service';
import { ChoiceComponent } from '../choice/choice.component';
import { PageBreakComponent } from '../page-break/page-break.component';
import { SectionComponent } from '../section/section.component';
import { PhotoAdderComponent } from '../photo-adder/photo-adder.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SaveFormComponent } from '../storage/save-form/save-form.component';
import { LoadFormComponent } from '../storage/load-form/load-form.component';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { FormElementComponent } from '../form-element/form-element.component';
import { RatingControlComponent } from '../component-models/rating-control/rating-control.component';
import { SignaturePadEditorComponent } from '../component-models/signature-pad-editor/signature-pad-editor.component';
import {ContainsControlComponentsInterface, instanceOfControlReportsOnViewInitilizationInterface} from '../../form-builder/containsComponents';
import { Audience, FormlyUtilityService } from '../component-models/formly-controls/formly-utility.service';
import { LineItemsComponent } from '../component-models/line-items/line-items.component';
import { BranchingContainerComponent } from '../branching-container/branching-container.component';
import { FormCatagoryService } from '../../../../../common/src/data/dao-services/form-catagory.service';
import { FormFirestoreService } from '../../../../../common/src/data/dao-services/form-firestore.service';
import { FormFirestoreSummary } from '../../../../../common/src/data/dao/form-firestore-summary';
import { FormFirestoreSummaryService } from '../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { CustomerService } from '../../../../../common/src/data/dao-services/customer.service';
import { SiteVisitService } from '../../../../../common/src/data/dao-services/site-visit.service';
import { FormModelFirestore } from '../../../../../common/src/data/dao/form-model-firestore';
import { ControlReportsOnViewInitilizationInterface } from '../containsComponents';
import { WORKFLOW_STAGE } from '../component-models/formly-controls/utilities/fetch-updated-workflow.service';
import { LocalSettingsService } from 'web-app/src/app/settings/local-settings.service';
import { InvoiceService } from '../../../../../common/src/data/dao-services/invoice.service';
import { EstimateService } from '../../../../../common/src/data/dao-services/estimate.service';
import { FormlyLineItemService } from '../component-models/formly-controls/formly-line-item/formly-line-item.service';
import { FormlyComponentUtilityService } from '../component-models/formly-controls/formly-component-utility.service';
import { limit, where } from 'firebase/firestore';
import { ControlContainsControlsComponent } from '../component-models/control-contains-controls.component';

export enum WorkflowType {
  Top = "topLevel",
  Section = "section",
}

@Component({
  selector: 'app-form-designer',
  templateUrl: './form-designer.component.html',
  styleUrls: ['./form-designer.component.scss', './_partial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormDesignerComponent implements OnInit, OnDestroy, AfterViewInit, ContainsControlComponentsInterface {


  activeWorkflowType = undefined;
  subComponentBeingViewed : ControlContainsControlsComponent = undefined;

  static activeView: FormlyView = FormlyView.Technician;

  sideBarDropZone: DragDropZone;
  mainDropZone: DragDropZone;

  testingFormly: FormlyFieldConfig[];
  changeDetect$: Subject<any> = new Subject();

  @ViewChild(FormBuilderDirective, {static: true}) formBuilderControlHost: FormBuilderDirective;

  unsavedChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);
  turnAutosaveBackOn: Subject<boolean> = new Subject();

  dropListIdsForMain: string[] = ["sidebarDragDropZone"];
  dropListIdsForSidebar: string[] = ["mainDragDropZone"];

  form: UntypedFormGroup;
  newColumnFormGroup: UntypedFormGroup;

  _automaticallySaveForm: boolean;

  get automaticallySaveForm(): boolean {
    return this._automaticallySaveForm;
  }

  set automaticallySaveForm(value: boolean) {
    this._automaticallySaveForm = value;
    this.localSettingsService.saveToLocalStorage("WorkflowDesigner", "autoSaveForms", this._automaticallySaveForm);
    if (value) {
      this.turnAutosaveBackOn.next(true);
    }
  }


  componentSideViewForm: UntypedFormGroup;
  componentSideViewParentForm: UntypedFormGroup;

  triggerSideViewUpdate = new Subject();
  loadedInitialTechView: boolean = false;

  focusedComponentModel: ControlContainerComponent;
  focusedComponentFormGroup: UntypedFormGroup = undefined;

  destroyComponent$: Subject<any> = new Subject();
  itemDroppedInComposingControl$ = new Subject<CdkDragDrop<ControlContainerComponent[]>>();
  addColumnDropListId$ = new Subject<string>();
  removeColumnDropListId$ = new Subject<string>();
  componentClicked$= new Subject<ControlContainerComponent>();
  newFormForSidebar$ = new Subject<any>();
  saveFormDialogRef: MatDialogRef<SaveFormComponent>;
  loadFormDialogRef: MatDialogRef<LoadFormComponent>;
  componentInjector: Injector;
  numberControlsRendered: number = 0;

  formlyForm = new UntypedFormGroup({});
  formlyModel = {};
  formlyOptions: FormlyFormOptions = {};
  formlyFields: BehaviorSubject<FormlyFieldConfig[]> = new BehaviorSubject<FormlyFieldConfig[]>([]);

  formlyViewInitializing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  sectionUpdated$: Subject<any> = new Subject();

  activeFormFirestore: FormFirestore;


  constructor(private fb: UntypedFormBuilder, private componentFactoryResolver: ComponentFactoryResolver, private componentFromFormlyFactory: ComponentFromFormlyFactoryService,
    private dialog: MatDialog,  private resolver: ComponentFactoryResolver, private _ngZone: NgZone, private ref: ChangeDetectorRef, public formlyUtilityService:  FormlyUtilityService,
    private formCatagoryService: FormCatagoryService, private formFirestoreService: FormFirestoreService, private formFirestoreSummaryService: FormFirestoreSummaryService,
    private route: ActivatedRoute, private router: Router, private settingsService: SettingsService, private jobService: JobService, private customerService: CustomerService,
    private siteVisitService: SiteVisitService, private localSettingsService: LocalSettingsService, private invoiceService: InvoiceService, private estimateService: EstimateService,
    private formlylineItemService: FormlyLineItemService, private formlyComponentUtilityService: FormlyComponentUtilityService) {

      this.automaticallySaveForm = localSettingsService.loadFromLocalStorage("WorkflowDesigner", "autoSaveForms", true);

      zip([this.jobService.load$(this.settingsService.getValue('demoJobDocId')), this.siteVisitService.load$(this.settingsService.getValue('demoSiteVisitDocId')),
      this.customerService.load$(this.settingsService.getValue('demoCustomerDocId')), this.invoiceService.load$(this.settingsService.getValue('demoInvoiceDocId')),
      this.estimateService.load$(this.settingsService.getValue('demoEstimateDocId')) ]).pipe(
        tap(x => console.log(x,` string`)),
        tap(([job, , , invoice, estimate  ]) => {
          this.formlylineItemService.job = job;
          this.formlylineItemService.explicitEstimateDocId = estimate.DocId();
          this.formlylineItemService.explicitInvoiceDocId = invoice.DocId();
          }),
        take(1)
      ).subscribe();

      this.initilizeComponentInjector();
      // this.formCatagoryService.load$("Udyc8F8cU8Tjx49tgqNe").pipe(take(1)).subscribe();
      this.formCatagoryService.loadAll$().pipe(take(1)).subscribe();
    }

  // ******************** Members required for live view of formly form.   ******************* //
  submit() {
    if (this.form.valid) {
      alert(JSON.stringify(this.formlyModel));
    }
  }

  patchChangesToDropZonesAndItemsFromContainedControlsToFormDesigner(containedComponentFormGroup: UntypedFormGroup) {

    console.log("PatchChangesToDropZonesAndItemsFromContainedControlsToFormDesigner");
    if (containedComponentFormGroup.controls["sectionUpdated"] !== undefined) {

      containedComponentFormGroup.controls["sectionUpdated"].valueChanges.pipe(
        debounce(() => this.formlyUtilityService.sidebarDebounceActive$.pipe(filter(x => x === false))),
        tap(x => console.log("SECTION WAS UPDATED")),
        tap(x => this.sectionUpdated$.next(x)),
        takeUntil(this.destroyComponent$)
      ).subscribe();
    }

    containedComponentFormGroup.controls["addDropZoneId"].valueChanges.pipe(
      tap(x => this.addColumnDropListId$.next(x)),
      delay(1),
      tap(() => this.formlyFields.next(this.retrieveCurrentFormlyFieldRepresentation(false))),
    ).subscribe();

    containedComponentFormGroup.controls["removeDropZoneId"].valueChanges.pipe(
      tap(x => this.removeColumnDropListId$.next(x)),
      tap(x => {
        if ( (containedComponentFormGroup.get("columnFormGroups").value as UntypedFormArray).length === 1) {
          const spliceIndex = this.mainDropZone.draggableComponents.findIndex(c =>  (c.form.get("controlName").value === "Columns" ||  c.form.get("controlName").value === "Section")
          && c.componentForm.get("menuLanchedByContainerId").value == x)
          // Index is only found when deleting a top level component, but a splice on -1 === a splice on last element, and we only want to remove
          // Component if it was deleted, not if a composed component within it was deleted...
            if (spliceIndex > -1) {
              this.mainDropZone.draggableComponents.splice(spliceIndex,1);
            }
        }
      }),
      debounceTime(10),
      delay(1),
      tap(x => this.formlyFields.next(this.retrieveCurrentFormlyFieldRepresentation(false)))
    ).subscribe();

    containedComponentFormGroup.controls["explicitRegenerateFormly"].valueChanges.pipe(
      tap(x => this.formlyFields.next(this.retrieveCurrentFormlyFieldRepresentation(true)))
    ).subscribe();

    containedComponentFormGroup.controls["itemRemoved"].valueChanges.pipe(
      tap(x => console.log("item removed")),
      tap(x => this.formlyFields.next(this.retrieveCurrentFormlyFieldRepresentation(true)))
    ).subscribe();

    containedComponentFormGroup.controls["itemDrop"].valueChanges.pipe(
      filter(x => x!==null),
      tap(x => this.itemDroppedInComposingControl$.next(x)),
      tap(() => FormElementComponent.emitComponentClicked=true),
      delay(1),
      map(() => this.retrieveCurrentFormlyFieldRepresentation(true)),
      tap(x => this.formlyFields.next(x))
    ).subscribe();
  }

  buildBranchingContainerFormGroup() : UntypedFormGroup {
    const retVal = BranchingContainerComponent.buildBranchingFormGroup();
    return this.patchUpdatesToContainedControlFormGroups(retVal);
  }

  buildSectionFormGroup() : UntypedFormGroup {
    const retVal = SectionComponent.buildSectionFormGroup();
    return this.patchUpdatesToContainedControlFormGroups(retVal);
  }

  buildColumnSplitterFormGroup() : UntypedFormGroup {
    const retVal = ColumnSplitterComponent.buildColumnSplitterFormGroup();
    return this.patchUpdatesToContainedControlFormGroups(retVal);
  }

  patchUpdatesToContainedControlFormGroups(f: UntypedFormGroup) {
    f.patchValue({connectedDropLists: this.dropListIdsForMain.slice()});
    this.patchChangesToDropZonesAndItemsFromContainedControlsToFormDesigner(f);
    return f;
  }

  trackByComponentPerInstance(index,component: ControlContainerComponent){
    return component.perInstance;
   }

    updateDropListsAndPatchToContainedControls() {
      this.removeColumnDropListId$.subscribe(x =>
        {
          if (this.dropListIdsForMain.indexOf(x) > -1) {
            this.dropListIdsForMain.splice(this.dropListIdsForMain.indexOf(x),1);
            this.dropListIdsForSidebar.splice(this.dropListIdsForSidebar.indexOf(x),1);
            (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).controls.forEach(column => column.patchValue({connectedDropLists: this.dropListIdsForMain}));
          }
        });

        this.addColumnDropListId$.pipe(
          delay(1)
          ).subscribe(x => {
          const asSet = new Set(this.dropListIdsForMain);
          if (!asSet.has(x)) {
            // Splice to beginning of array b/c drops in first match found.
            this.dropListIdsForMain.splice(0,0,x);
            this.dropListIdsForSidebar.splice(0,0,x);
            (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).controls.forEach(column => {
              column.patchValue({connectedDropLists: this.dropListIdsForMain})
            });
          }
        });
    }

    detectUnsavedChanges() {

      const updatedSubComponent = interval(1000).pipe(
        filter(() => this.unsavedChanges.value === false),
        filter(() => this.formlyUtilityService.sidebarDebounceActive$.value === false),
        map(() => {
          const f = this.mainDropZone.draggableComponents.filter(x => x.form.get("controlName").value !== "Section" && x.serializeToDatabase).map(x => x.toFormlyFieldConfigJsonOnly());
          f.forEach(form => this.formlyUtilityService.stripKeysAllTheWayDown(form));
          return JSON.stringify(f);
        }),
        pairwise(),
        filter(x => x[0] !== x[1]),
        );

        const addedSection = interval(1000).pipe(
          filter(() => this.unsavedChanges.value === false),
          filter(() => this.formlyUtilityService.sidebarDebounceActive$.value === false),
          map(() =>  this.mainDropZone.draggableComponents.filter(x => x.form.get("controlName").value === "Section" && x.serializeToDatabase &&
            x.form.value?.controlComponentFormGroup?.value?.formFirestoreSummaryDocId !== null).length ),
          pairwise(),
          filter(x => x[0] !== x[1]),
          );

        merge(updatedSubComponent, addedSection,
          ...this.mainDropZone.draggableComponents.filter(x => x.form.get("controlName").value === "Section")
            .map(y => (y as SectionComponent).updatedSectionProperties$.pipe(debounceTime(500)))).pipe(
          tap(x => console.log("UPDATED SUB COMPONENT OR ADDED SECTION")),
          tap(x => {
              this.unsavedChanges.next(true);
          }),
          takeUntil(merge(this.destroyComponent$, this.formlyViewInitializing$.pipe(filter(x => x === true)))),
        ).subscribe();
    }

    checkForSaveValidity() : boolean {
      // We must iterate through all controls and check if there are any formly-sections which have not yet been saved.
      // If there are, we must prompt the user to save the form before proceeding.
      const toCheck = this.mainDropZone.draggableComponents.filter(x => x.serializeToDatabase).map(x => x.toFormlyFieldConfig() as FormlyFieldConfig);
      for (const formlySection of toCheck) {
        const sectionRetVal = this.checkForSectionValidity(formlySection);
        if (!sectionRetVal) {
          return false;
        }
      }
      return true;
    }

    checkForSectionValidity(f : FormlyFieldConfig) : boolean {
      if (f.type === "formlySection" && !f.props.formFirestoreSummaryDocId) {
        return false;
      } else {
        if (f.fieldGroup) {
          for (const sub of f.fieldGroup) {
            if (!this.checkForSectionValidity(sub)) {
              return false;
            }
          }
      }
        return true;
      }
    }

    retrieveFormToSave() : string {
      if (this.activeWorkflowType === WorkflowType.Section) {
        (this.subComponentBeingViewed as SectionComponent).columnFormGroup.patchValue({columnFormGroups: [{cdkDropListData: this.mainDropZone.draggableComponents}]});
        (this.subComponentBeingViewed as SectionComponent).fields[0].props["formFirestoreDocId"] = "<<formFirestoreDocId>>";
        (this.subComponentBeingViewed as SectionComponent).fields[0].props["formFirestoreSummaryDocId"] = "<<formFirestoreSummaryDocId>>";
        return JSON.stringify(this.subComponentBeingViewed.toFormlyFieldConfigJsonOnly());
      } else {
        return JSON.stringify(this.mainDropZone.draggableComponents.filter(x => x.serializeToDatabase).map(x => x.toFormlyFieldConfigJsonOnly()));
      }
    }

    autoSaveForm() {
      const savedVersion = this.retrieveFormToSave();
      const newFormFireStore = new FormFirestore();
      newFormFireStore.formSummary = this.activeFormFirestore.formSummary;
      newFormFireStore.title = this.activeFormFirestore.title;
      newFormFireStore.form = savedVersion;
      this.formFirestoreService.retrieveDocId(newFormFireStore).pipe(
        map(() => {
          newFormFireStore.formSummary.currentDesignFirestoreDocId = newFormFireStore.DocId();
          if (this.activeWorkflowType === WorkflowType.Section) {
            newFormFireStore.form = newFormFireStore.form.replace("<<formFirestoreDocId>>", newFormFireStore.DocId());
            newFormFireStore.form = newFormFireStore.form.replace("<<formFirestoreSummaryDocId>>", newFormFireStore.formSummary.DocId());
          }
          return newFormFireStore;
        }),
        switchMap(newFormFireStore => this.formFirestoreService.create$(newFormFireStore)),
        tap(() => this.unsavedChanges.next(false)),
        switchMap(x => this.formFirestoreService.load$(x.DocId())),
        filter(x => x.formSummary !== null),
        tap(() => console.log("Loading to tech view b/c auto saved.")),
        tap(x => this.activeFormFirestore = x),
        tap(() => this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(this.activeFormFirestore)),
        take(1),
        ).subscribe();
    }

    saveToFirestore(toSave: FormFirestore) {
      // Ensure that form is in a savable state.
      if (!this.checkForSaveValidity()) {
        window.alert("You first must save sub sections.");
        return;
      }

      let formFirestoreSummaryDocId = this.activeFormFirestore?.formSummary.DocId();
      let  formFirestoreSummaryDocId$ : Observable<string> = undefined;

      if ( !formFirestoreSummaryDocId) {
        formFirestoreSummaryDocId$ = this.formFirestoreService.retrieveFirestoreRawDocId();
      } else {
        formFirestoreSummaryDocId$ = of(formFirestoreSummaryDocId);
      }
      zip(formFirestoreSummaryDocId$,this.formFirestoreService.retrieveFirestoreRawDocId()).pipe(
        tap(x => formFirestoreSummaryDocId = x[0]),
        tap(x => {
          const formFirestoreDocId = x[1];
          const editorConfig = new MatDialogConfig();
          let savedVersion = this.retrieveFormToSave();
          if (this.activeWorkflowType === WorkflowType.Section) {
            savedVersion = savedVersion.replace("<<formFirestoreDocId>>", formFirestoreDocId);
            savedVersion = savedVersion.replace("<<formFirestoreSummaryDocId>>", formFirestoreSummaryDocId);
          }
          Object.assign(editorConfig, {
            data: {
            form: savedVersion,
            formType: this.activeWorkflowType === WorkflowType.Section ? "section" : "topLevel",
            formFirestore: toSave,
            formFirestoreDocId: formFirestoreDocId,
            formFirestoreSummaryDocId: formFirestoreSummaryDocId,
            }
          });

          this.saveFormDialogRef = this.dialog.open(SaveFormComponent, editorConfig);

          this.saveFormDialogRef.afterClosed().pipe(
            filter(x => x !== undefined),
            switchMap(x => this.formFirestoreService.load$(x.DocId())),
            filter(x => x.formSummary !== null),
            tap(x => this.activeFormFirestore = x),
            tap(() => this.unsavedChanges.next(false)),
            tap(x => {
              if (!this.loadedInitialTechView && x.form !== '[]') {
                this.loadedInitialTechView = true;
                this.loadInitialTechView();
              } else if (this.loadedInitialTechView){
                this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(this.activeFormFirestore);
              }
            }),
            take(1),
          ).subscribe();
        }),
        take(1)
      ).subscribe();
    }

    ngOnInit(): void {

      this.formlyUtilityService.workFlowStage = WORKFLOW_STAGE.DESIGN;
      this.formlyUtilityService.saveFormModelFirestoreUpdates = false;

      merge(this.sectionUpdated$.pipe(filter(() => this.subComponentBeingViewed !== undefined)),this.unsavedChanges, this.turnAutosaveBackOn).pipe(
        filter(x => x === true),
        filter(() => this.automaticallySaveForm),
        filter(() => this.checkForSaveValidity()),
        // Initial save must be manual, even in auto save mode as there is no name or catagory assigned.
        filter(() => this.activeFormFirestore !== undefined),
        tap(() => this.autoSaveForm()),
        delay(50),
        tap(() => {
          if (!this.loadedInitialTechView) {
            this.loadedInitialTechView = true;
            this.loadInitialTechView();
          }
        }),
        takeUntil(this.destroyComponent$),
      ).subscribe();

      this.sectionUpdated$.pipe(
        filter(() => this.subComponentBeingViewed === undefined),
        tap(() => console.log("Loaded to tech view b/c section updated.")),
        tap(() => this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(this.activeFormFirestore)),
        takeUntil(this.destroyComponent$)
      ).subscribe();



      this.formlyUtilityService.designMode = true;
      this.form = this.fb.group({
        containedComponentFormGroups : new UntypedFormArray([]),
      });

      this.componentClicked$.subscribe(x => {
        this.loadComponentDetailsSidebar(x);
      });

      // When item is dropped in composing control, add back to side bar if item originated there.
      this.itemDroppedInComposingControl$.subscribe(event => {
        if (event.previousContainer?.id === "sidebarDragDropZone") {
        this.AddElementBackToSidebar(event.previousIndex,event.item.data);
        }
      });

      this.updateDropListsAndPatchToContainedControls();

      this.sideBarDropZone = this.initilizeSiderBarDragDropZone();
      this.mainDropZone = this.initilizeMainDragDropZone();

      this.formlyViewInitializing$.pipe(
        filter(x => x === false),
        tap(() => this.detectUnsavedChanges()),
        takeUntil(this.destroyComponent$),
      ).subscribe();

      this.formlyUtilityService.addFormFirestoreToFormDesigner$.pipe(
        mergeMap(x => this.formFirestoreSummaryService.load$(x.firestoreSummaryDocId).pipe(
          map(summary => {
            return {x, formFirestoreDocId: summary.currentDesignFirestoreDocId}
          }),
          take(1),
        )),
        mergeMap(toLoad => this.formFirestoreService.load$(toLoad.formFirestoreDocId).pipe(
          map(formFirestore => {
            return {formFirestore, formFirestoreSummaryDocId: toLoad.x.firestoreSummaryDocId, originSourceGuid: toLoad.x.originSourceGuid, parentContainerGuid: toLoad.x.parentContainerGuid, parentDropZoneId: toLoad.x.parentDropZoneId};
          }),
          take(1)
        ))).subscribe( x => this.addFirestore(x));

      this.formlyUtilityService.removeSectionFromDesignView$.pipe(
        tap(x => {
          const parsed = this.checkIfElementContainsGuid((this.form.controls["containedComponentFormGroups"] as UntypedFormArray),
          x.originSourceGuid,x.parentContainerGuid,x.parentDropZoneId,null, x.formFirestoreSummaryDocId, "Remove");
          // top level won't be found
          if (!parsed) {
            const sectionToRemoveIndex = this.mainDropZone.draggableComponents.findIndex(q => q.componentForm?.value?.formFirestoreSummaryDocId === x.formFirestoreSummaryDocId);
          if (sectionToRemoveIndex > -1) {
          const sectionToRemove = (this.mainDropZone.draggableComponents[sectionToRemoveIndex] as SectionComponent);
            sectionToRemove.removeSection();
          } else {
            console.warn("Section not found to remove.");
          }
        }
        }),
        takeUntil(this.destroyComponent$)
      ).subscribe();
    }

  ngAfterViewInit(): void {
  }


    ngOnDestroy(): void {
      this.formlyUtilityService.designMode = false;
      this.formlyUtilityService.workFlowStage = WORKFLOW_STAGE.DEPLOYED;
      this.destroyComponent$.next(null);
      this.destroyComponent$.complete();
    }

  loadInitialTechView() {
    this.jobService.load$(this.settingsService.getValue('demoJobDocId')).pipe(
      map(job => {
        job.lineItems = [];
        job.abandonedLineItems = [];
        job.formModelFirestore = new FormModelFirestore({formFirestore: this.activeFormFirestore, formFirestoreDocId: this.activeFormFirestore.DocId() });
        return job;
      }),
      take(1),
      switchMap(j => this.jobService.update$(j)),
      switchMap(j => this.invoiceService.queryFirestoreDeep$([where('jobDocId', '==', j.DocId()),
        limit(1)]).pipe(
        map(invoice => {
          return {job: j, invoice: invoice.length > 0 ? invoice[0] : null}
        }),
      )),
      switchMap(j => this.estimateService.queryFirestoreDeep$([where('jobDocId', '==', j.job.DocId()),
        limit(1)]).pipe(
        map(estimate => {
          return {job: j.job, invoice: j.invoice, estimate: estimate.length > 0 ? estimate[0] : null}
        }),
      )),
      tap(j => {
        this.router.navigate([{outlets: {techView:
          ['load-workflow', j.job.formModelFirestore.DocId(), 'job', this.settingsService.getValue('demoJobDocId'),
          'sv',this.settingsService.getValue('demoSiteVisitDocId'),'cust',this.settingsService.getValue('demoCustomerDocId'),
          'inv',j.invoice? j.invoice.DocId() : null,'est',j.estimate? j.estimate.DocId() : null ]
        }}],{relativeTo: this.route, skipLocationChange: true});
      }
      ),
      take(1)
    ).subscribe();
  }

  formlyFormValid() {
    if (!this.formlyForm.valid && !this.formlyForm.disabled) {
      console.log("this",this.formlyForm);
    }
    return this.formlyForm.valid;
  }

  deployWorkflow(refresh: boolean) {
    const f = this.activeFormFirestore.formSummary;
    f.currentDeployedFirestoreDocId = f.currentDesignFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      tap(() => {
        if (refresh) {
          this.loadForm(this.activeFormFirestore);
        }
      }),
      take(1)
    ).subscribe();
  }

  revertToDeployedWorkflow() {
    const f = this.activeFormFirestore.formSummary;
    f.currentDesignFirestoreDocId = f.currentDeployedFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      switchMap(x => this.formFirestoreService.load$(f.currentDesignFirestoreDocId)),
      filter(x => x.formSummary !== null),
      tap(() => this.formlyViewInitializing$.next(true)),
      tap(x => this.activeFormFirestore = x),
      map(x => JSON.parse(x.form)),
      tap(() => this.unsavedChanges.next(false)),
      tap(x => this.populateFromJson(x)),
      tap(x => this.changeDetect$.next(null)),
      take(1)
      ).subscribe();
  }

  newWorkflow(workflowType: WorkflowType) {
    if (workflowType === "section") {
      this.subComponentBeingViewed = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
        this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService);
        (this.subComponentBeingViewed as SectionComponent).columnFormGroup = this.buildSectionFormGroup();
    } else {
      this.subComponentBeingViewed = undefined;
    }
    this.activeWorkflowType = workflowType;
    this.activeFormFirestore = undefined;
    this.populateFromJson([]);
    this.unsavedChanges.next(false);
    this.changeDetect$.next(null);
    this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(null);
  }

  loadForm(formFirestore: FormFirestore) {
      this.formlyViewInitializing$.next(true);
      this.activeWorkflowType = formFirestore.formSummary.formType;
      this.activeFormFirestore = formFirestore;
      console.log(formFirestore);
      const formlyConfig = JSON.parse(formFirestore.form);
      if (this.activeWorkflowType === "section") {
        this.subComponentBeingViewed = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
          this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService);
          (this.subComponentBeingViewed as SectionComponent).columnFormGroup = this.buildSectionFormGroup();
          (this.subComponentBeingViewed as SectionComponent).patchInSectionConfigurationComponents(formlyConfig);
      } else {
        this.subComponentBeingViewed = undefined;
      }
      this.unsavedChanges.next(false);
      this.populateFromJson(formlyConfig);
      if (!this.loadedInitialTechView && formlyConfig.length > 0 || formlyConfig.fieldArray?.fieldGroup) {
        this.loadedInitialTechView = true;
        this.loadInitialTechView();
      } else if (!this.loadInitialTechView) {
        this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.next(this.activeFormFirestore);
      }
      this.changeDetect$.next(null);
  }

  loadFromFirestore() {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      data: {
      formCatagory: null,
      formType: "all"
      }
    });

    this.loadFormDialogRef = this.dialog.open(LoadFormComponent, editorConfig);

    // Event dialog closure:
    this.loadFormDialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(x => this.loadForm(x)),
      take(1),
      ).subscribe();
  }

  ShowCustomerView() {
    this.formlyUtilityService.activeAudience = Audience.Customer;
    this.formlyUtilityService.triggerReload$.next(null);
  }

  ShowTechView() {
    this.formlyUtilityService.activeAudience = Audience.Internal;
    this.formlyUtilityService.triggerReload$.next(null);
  }

  saveWorkflow(workflowType: WorkflowType | null) {
    if (workflowType === WorkflowType.Section && this.subComponentBeingViewed === undefined) {
      this.activeWorkflowType = WorkflowType.Section;
      this.subComponentBeingViewed = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
        this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService);
        (this.subComponentBeingViewed as SectionComponent).columnFormGroup = this.buildSectionFormGroup();
    }
    this.saveToFirestore(this.activeFormFirestore);
  }

  loadWorkflow() {
    this.loadFromFirestore();
  }

  copyWorkflow() {
    const copy = new FormFirestore(this.activeFormFirestore);
    const summaryCopy = new FormFirestoreSummary(copy.formSummary);
    copy.formSummary = summaryCopy;
    copy.formSummaryDocId = undefined;
    combineLatest([this.formFirestoreService.retrieveDocId(copy),this.formFirestoreSummaryService.retrieveDocId(summaryCopy)]).pipe(
      tap(() => this.saveToFirestore(copy)),
      take(1)
    ).subscribe();
  }

  toggleWorkflowActivationStatus() {
    this.activeFormFirestore.formSummary.active = !this.activeFormFirestore.formSummary.active;
    this.formFirestoreSummaryService.update$(this.activeFormFirestore.formSummary).pipe(
      take(1)
    ).subscribe();
  }

  reinitilizeDesignForm() {

    this.mainDropZone.draggableComponents = [];
    (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).clear();
    this.dropListIdsForMain = ["sidebarDragDropZone"];
    this.dropListIdsForSidebar = ["mainDragDropZone"];
  }

  populateFromJson(fields: FormlyFieldConfig[] ) {
    FormElementComponent.emitComponentClicked=false;
    const inilizationReportedBack : ControlReportsOnViewInitilizationInterface[] = [];
    console.log(fields);
    this.reinitilizeDesignForm();
    let fieldsToParse : FormlyFieldConfig[] = fields;
    // For sections, load sub components in when viewing in main design container.
    if (this.activeWorkflowType === WorkflowType.Section && (fields as any).fieldArray) {
      fieldsToParse = (fields as any).fieldArray.fieldGroup;
    }
    fieldsToParse.filter(x => x.key !== "_id" ).forEach(field => {
      var c : ColumnSplitterComponent | SectionComponent | PageBreakComponent | PhotoAdderComponent | BranchingContainerComponent;
        switch (field.type) {
          case "formlySplitter":
            c = new ColumnSplitterComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.formlyComponentUtilityService);
            c.columnFormGroup = this.buildColumnSplitterFormGroup();
            c.columnFormGroup.patchValue({borderColor: field.props["borderColor"], border: field.props["border"]  });
            break;
          case "formlySection":
            c = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
              this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService);
            c.columnFormGroup = this.buildSectionFormGroup();
            break;
          case "formlyBranchingContainer":
            c = new BranchingContainerComponent(this.fb, this.formlyUtilityService, this.formFirestoreService);
            break;
          case "formlyPageBreak":
            c = new PageBreakComponent(this.fb);
            break;
          case "formlyPhotoAdder":
            c = new PhotoAdderComponent(this.fb);
            break;
          default:
            console.error("Attempted to parese a field with an unsupported type.");
          break;
        }
        c.patchInFormlyFieldConfig(field);
        (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).push(c.componentForm);
          if (instanceOfControlReportsOnViewInitilizationInterface(c)) {
            inilizationReportedBack.push(c as ControlReportsOnViewInitilizationInterface);
          }
          this.mainDropZone.draggableComponents.push(c as ControlContainerComponent);
    });

    if (inilizationReportedBack.length === 0) {
      this.formlyViewInitializing$.next(false);
    } else {
      zip(...inilizationReportedBack.map(x => x.afterViewInitilized$), of(null)).pipe(
        tap(() => this.formlyViewInitializing$.next(false)),
        take(1)
      ).subscribe();
    }
  }

  initilizeComponentInjector() {
    this.componentInjector = Injector.create({providers: [
      {provide : WhitespaceControlComponent},
      {provide: TextboxControlComponent},
      {provide: ImageControlComponent},
      {provide: ColumnSplitterComponent},
      {provide: ChoiceComponent},
      {provide: PageBreakComponent},
      {provide: SectionComponent},
      {provide: PhotoAdderComponent},
      {provide: RatingControlComponent},
      {provide: SignaturePadEditorComponent},
      {provide: LineItemsComponent},
      {provide: BranchingContainerComponent},
    ]});
  }

  AddElementBackToSidebar(index: number, componentToClone: ControlContainerComponent) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentToClone.component);
    const adder = componentFactory.create(this.componentInjector).instance;
    if ((adder as ControlContainerComponent).form.get("controlName").value === "Columns") {
      (adder as ColumnSplitterComponent).columnFormGroup = this.buildColumnSplitterFormGroup();
      (adder as ColumnSplitterComponent).addColumnAtIndex(0);
    } else if ((adder as BranchingContainerComponent).form.get("controlName").value === "Branching") {
      // (adder as BranchingContainerComponent).columnFormGroup = this.buildSectionFormGroup() ;
      console.log(adder);
    }else if ((adder as SectionComponent).form.get("controlName").value === "Section") {
      (adder as SectionComponent).columnFormGroup = this.buildSectionFormGroup() ;
      console.log(adder);
    }
    if (componentToClone.form.get("controlName").value === "Label") {
      (adder as TextboxControlComponent).convertToLabel();
    }
    this.sideBarDropZone.draggableComponents.splice(index,0,adder);
  }

  retrieveCurrentFormlyFieldRepresentation(explictRegenerate : boolean) : FormlyFieldConfig[] {
    const currentCount = this.mainDropZone.draggableComponents.map(x => x.NumberComponents).reduce((acc,value) => acc + value,0);
    if (currentCount !== this.numberControlsRendered) {
      console.log("Number controls:",currentCount);
      (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).controls.forEach(column => column.patchValue({connectedDropLists: this.dropListIdsForMain}));
    }
    if (explictRegenerate || currentCount !== this.numberControlsRendered) {
      let val: FormlyFieldConfig[];
      val = ((this.mainDropZone.draggableComponents.map(x => x.toFormlyFieldConfig())).reduce((retVal: FormlyFieldConfig[], o) => {
        if (Array.isArray(o)) {
          o.forEach(aVal => retVal.push(aVal))
        } else {
          retVal.push(o);
        }
        return retVal;
      }, []) as FormlyFieldConfig[]);
      this.numberControlsRendered = currentCount;
      return val;
    } else {
      return this.formlyFields.value;
    }
}


  onTalkDropSidebar(event: CdkDragDrop<ControlContainerComponent[]>) {
    if (event.previousContainer !== event.container)  {
      event.previousContainer.data.splice(event.previousIndex,1);
          this.formlyFields.next(this.retrieveCurrentFormlyFieldRepresentation(true));
    }
  }

  mutateComponentWhenDroppedInContainer(c: ControlContainerComponent): ControlContainerComponent {
    if (c.form.get("composerComponent").value || c.form.get("ControlRequiresFullWidth").value) {
      return c;
    } else {
      const columnSplitterComponent = new ColumnSplitterComponent(this.fb,this.componentFromFormlyFactory,this._ngZone, this.formlyComponentUtilityService);
      columnSplitterComponent.columnFormGroup = this.buildColumnSplitterFormGroup();
      columnSplitterComponent.addColumnAtIndex(0,true);
      const dropListToPushTo = (((columnSplitterComponent.componentForm.get("columnFormGroups") as UntypedFormArray).at(0) as UntypedFormGroup).get("cdkDropListData").value as Array<ControlContainerComponent>);
      c.parentContainer = columnSplitterComponent;
      dropListToPushTo.push(c);
      return columnSplitterComponent;
    }
  }


  checkIfElementContainsGuid(c: any, componentGuid: string, parentGuid: string, parentDropZoneId : string,
    sectionToSplice: ControlContainerComponent | null, formFirestoreSummaryDocIdToRemove: string | null, addRemove: "Add" | "Remove") : boolean {


    let retVal = false;

    const commonRecursion = (field:any) => {
      if (this.checkIfElementContainsGuid(field,componentGuid,parentGuid,parentDropZoneId,sectionToSplice, formFirestoreSummaryDocIdToRemove, addRemove)) {
        retVal= true;
      }
      return retVal;
    }

    if (!c) {
      return false;
    }

    if (c.value?.id === parentDropZoneId) {
      if (addRemove === "Add") {
        const spliceIndex = c.value.cdkDropListData.findIndex(x => x.guid === parentGuid)+1;
        if (spliceIndex > -1) {
          c.value.cdkDropListData.splice(spliceIndex,0,sectionToSplice);
          return true;
        }
      } else {
        const sectionToRemoveIndex = c.value.cdkDropListData.findIndex(x => x.componentForm?.value?.formFirestoreSummaryDocId === formFirestoreSummaryDocIdToRemove);
          if (sectionToRemoveIndex > -1) {
          const sectionToRemove = (c.value.cdkDropListData[sectionToRemoveIndex] as SectionComponent);
            sectionToRemove.removeSection();
            c.value.cdkDropListData.splice(sectionToRemoveIndex,1);
          } else {
            console.error("Unable to find section to remove index.");
          }
      }
    }

    if (c.value?.cdkDropListData) {
      if (Array.isArray(c.value?.cdkDropListData)) {
        for (var x in c.value.cdkDropListData) {
          if (commonRecursion(c.value.cdkDropListData[x])){
            break;
          }
        }
      }
    }

    if (c.controls) {
      if (Array.isArray(c.controls)) {
        for (var x in c.controls) {
          if (commonRecursion(c.controls[x])) {
            break;
          }
        }
      } else if (c.controls.columnFormGroups) {
        commonRecursion(c.controls.columnFormGroups);
      }
    }

    if (c.columnFormGroups?.controls) {
      for (var x in c.columnFormGroups.controls) {
        if (commonRecursion(c.columnFormGroups.controls[x])) {
          break;
        }
      }
    }
    return retVal;
  }

  addSectionFromParsed(contents: FormlyFieldConfig[], f: FormFirestore, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string) {

    const retSection = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog, this.formlyUtilityService,
      this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService);
    retSection.columnFormGroup = this.buildSectionFormGroup();
    retSection.activeFormFirestore = f;
    const field = this.formlyUtilityService.getSection(contents,true,f.formSummary.title,f.formSummary.DocId());
    retSection.patchInFormlyFieldConfig(field);
    retSection.form.patchValue({serializeToDatabase : false});

    const AddToParentDropZoneIfNotMain = this.checkIfElementContainsGuid((this.form.controls["containedComponentFormGroups"] as UntypedFormArray),
          originSourceGuid,parentContainerGuid,parentDropZoneId,(retSection as unknown) as ControlContainerComponent, null, "Add");

    if (!AddToParentDropZoneIfNotMain) {
      const draggableIndex = this.mainDropZone.draggableComponents.findIndex(x => x.guid === parentContainerGuid) + 1;
      this.mainDropZone.draggableComponents.splice(draggableIndex,0,(retSection as unknown) as ControlContainerComponent);
    }
    this.formlyUtilityService.sectionKeyAddedToFormDesigner$.next(
      {sectionKey: retSection.fields[0].key as string, firestoreDocId: f.DocId(), source: originSourceGuid+parentContainerGuid});
    const dropZoneId = (retSection as SectionComponent).form.get("controlComponentFormGroup").value.get("addDropZoneId").value;
    setTimeout(() => {
      this.patchUpdatesToContainedControlFormGroups((retSection as SectionComponent).form.get("controlComponentFormGroup").value);
    },500);
  }

addFirestore(val: {formFirestore: FormFirestore, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string}) : void {
      const parsed = JSON.parse(val.formFirestore.form) as FormlyFieldConfig[];
      this.addSectionFromParsed(parsed, val.formFirestore, val.originSourceGuid, val.parentContainerGuid, val.parentDropZoneId);
}

  onTalkDropMain(event: CdkDragDrop<ControlContainerComponent[]>) {
    FormElementComponent.emitComponentClicked=true;
    // moved within same container.
    if (event.previousContainer === event.container) {
      if (event.previousIndex !== event.currentIndex)  {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
          this.formlyFields.next(this.retrieveCurrentFormlyFieldRepresentation(true));
      }
    // Moved from a different container.
    } else {
        let controlWithMutationIfNeeded: ControlContainerComponent;
        // if previous container was sidebar, mutate element b/f transferring it.
        if (event.previousContainer.id === "sidebarDragDropZone") {
          controlWithMutationIfNeeded = this.mutateComponentWhenDroppedInContainer(event.previousContainer.data[event.previousIndex]);
          if (controlWithMutationIfNeeded.component.name  === "SectionComponent") {
            (controlWithMutationIfNeeded as SectionComponent).formlyViewInitializing$.next(false);
          }
          event.previousContainer.data[event.previousIndex] = controlWithMutationIfNeeded;
        } else {
          controlWithMutationIfNeeded = event.previousContainer.data[event.previousIndex];
        }
        transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
        // Originating from sidebar
        if (event.previousContainer.id === "sidebarDragDropZone") {
          this.AddElementBackToSidebar(event.previousIndex,event.item.data);
          controlWithMutationIfNeeded.form.get("triggerUpdate").valueChanges.pipe(
            tap(() => console.log("manual update of live view triggered!")),
            tap(() => this.formlyFields.next(this.retrieveCurrentFormlyFieldRepresentation(true))),
            takeUntil(controlWithMutationIfNeeded.destroyingComponent$)
          ).subscribe();
        }
        (this.form.controls["containedComponentFormGroups"] as UntypedFormArray).push(controlWithMutationIfNeeded.componentForm);
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.container.data);
      }
  }

  getIconStyling(c:  ControlContainerComponent)  {
    return {
      'padding-right' : '10px',
      'color': `${c.form.get("iconColor").value}`
    };
  }

  private loadComponentDetailsSidebar(c: ControlContainerComponent) {
    if (this.focusedComponentModel) {
      this.focusedComponentModel.form.patchValue({focused: false});
    }

    if (c.fields[0].type !== "formlySection") {
      c.form.patchValue({focused: true});
    }

    this.focusedComponentModel = c;

    this.componentSideViewForm = c.form;
    this.componentSideViewParentForm = (c.parentContainer === undefined || c.parentContainer === null) ? c.form : c.parentContainer.form;
    this.formlyUtilityService.sidebarDebounceActive$.next(false);
    this.newFormForSidebar$.next(null);

    // on change, activate debounce.  After debouncing, deactivate it.  This is to reduce auto-saving when user is actively updating control
    // particularly text box controls.....
    const debounceObs = [c.form.get("controlComponentFormGroup").value.valueChanges];

    if (c.form.get("controlComponentFormGroup").value.get("data") !== undefined && c.form.get("controlComponentFormGroup").value.get("data") !== null) {
      debounceObs.push(c.form.get("controlComponentFormGroup").value.get("data").valueChanges);
    }
    if (c.form.get("controlComponentFormGroup").value.get("columns") !== undefined && c.form.get("controlComponentFormGroup").value.get("columns") !== null) {
      debounceObs.push(c.form.get("controlComponentFormGroup").value.get("columns").valueChanges);
    }

    merge(...debounceObs)
    .pipe(
      tap(() => this.formlyUtilityService.sidebarDebounceActive$.next(true)),
      takeUntil(this.newFormForSidebar$)
    ).subscribe();

    merge(...debounceObs).pipe(
      debounceTime(2000),
      tap(() => this.formlyUtilityService.sidebarDebounceActive$.next(false)),
      takeUntil(this.newFormForSidebar$)
    ).subscribe();

  }

  private initilizeSiderBarDragDropZone() : DragDropZone {
    const columnSplitter = this.resolver.resolveComponentFactory(ColumnSplitterComponent).create(this.componentInjector).instance;
    columnSplitter.columnFormGroup = this.buildColumnSplitterFormGroup();
    columnSplitter.addColumnAtIndex(0);
    const textBox = this.resolver.resolveComponentFactory(TextboxControlComponent).create(this.componentInjector).instance;
    const label = this.resolver.resolveComponentFactory(TextboxControlComponent).create(this.componentInjector).instance;
    label.convertToLabel();
    const whiteSpace = this.resolver.resolveComponentFactory(WhitespaceControlComponent).create(this.componentInjector).instance;
    const imageControl = this.resolver.resolveComponentFactory(ImageControlComponent).create(this.componentInjector).instance;
    const choice = this.resolver.resolveComponentFactory(ChoiceComponent).create(this.componentInjector).instance;
    const rating = this.resolver.resolveComponentFactory(RatingControlComponent).create(this.componentInjector).instance;
    const pageBreak = this.resolver.resolveComponentFactory(PageBreakComponent).create(this.componentInjector).instance;
    const section = this.resolver.resolveComponentFactory(SectionComponent).create(this.componentInjector).instance;
    section.columnFormGroup = this.buildSectionFormGroup();
    const photoAdder = this.resolver.resolveComponentFactory(PhotoAdderComponent).create(this.componentInjector).instance;
    const signaturePad = this.resolver.resolveComponentFactory(SignaturePadEditorComponent).create(this.componentInjector).instance;
    const lineItems = this.resolver.resolveComponentFactory(LineItemsComponent).create(this.componentInjector).instance;
    const branching = this.resolver.resolveComponentFactory(BranchingContainerComponent).create(this.componentInjector).instance;

    return  {
        "name" :  "Form Components",
        "id" : "sidebarDragDropZone",
        "draggableComponents" : [
          textBox,
          whiteSpace,
          imageControl,
          choice,
          rating,
          pageBreak,
          section,
          photoAdder,
          columnSplitter,
          label,
          signaturePad,
          lineItems,
          branching,
        ]
      };
  }

  private initilizeMainDragDropZone(): DragDropZone {
    return {
        "name": "Design View",
        "id": "mainDragDropZone",
        "draggableComponents": [],
      };
  }
}
