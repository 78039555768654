import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class TaxRateSettings extends RetrieveFirestorePropertiesBase<TaxRateSettings>  {

name: string;
percent: number;
applyToMaterials: boolean = false;
applyToLabor: boolean = false;
default: boolean = false;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return TaxRateSettings._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return TaxRateSettings._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return TaxRateSettings._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return TaxRateSettings._firestoreCompositionMemberNames;}

public constructor(init?: Partial<TaxRateSettings>) {
  super();
  Object.assign(this, init);
}
}
