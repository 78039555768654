<mat-dialog-content>
  <div>
    <p>Please select whether you wish to update the existing line item (which is applied to all job types and in price book), or create a new seperate line item.</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="footer-button">
  <button class="button-white" style="grid-column: 2/3;" mat-button mat-dialog-close (click)="editClose()">Edit</button>
  <button class="button-blue" mat-button mat-dialog-close (click)="createNewClose()">Create New</button>
  </div>
</mat-dialog-actions>
