<div *ngIf="deliveryDetails !== null else notPopulatedYetBlock">
  <div *ngIf="deliveryDetailType === 'SMS' else emailBlock">
    <div class="sms-communications-modal-layout">
      <div class="header-label" style="grid-column: 1/3; width: 100%; margin-bottom: 20px;">SMS Communication Status</div>
    
      <div class="label-style">Delivered At:</div>
      <div>
        <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsSms.deliveredAt === null ? '' :
        dateFromTimeStamp(deliveryDetailsSms.deliveredAt) | date:'medium'}}" />
      </mat-form-field>
    </div>
    
      <div class="label-style">Error Code:</div>
      <div>
        <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsSms.errorCode === null ? '' : deliveryDetailsSms.errorCode }}" />
    </mat-form-field>
    </div>
    
    <div class="label-style">Error Message:</div>
    <div>
      <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsSms.errorMessage === null ? '' : deliveryDetailsSms.errorMessage}}" />
      </mat-form-field>
    </div>
    
      <div class="label-style">State:</div>
      <div>
        <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsSms.state === null ? '' : deliveryDetailsSms.state}}" />
    </mat-form-field>
    </div>

    <div class="label-style">Sent To:</div>
      <div>
        <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsSms.sentTo === null ? '' : deliveryDetailsSms.sentTo}}" />
    </mat-form-field>
    </div>
    
        <div class="label-style">Updated At:</div>
      <div>
        <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsSms.updatedAt === null ? '' :
        dateFromTimeStamp(deliveryDetailsSms.updatedAt) | date:'medium'}}" />
      </mat-form-field>
      </div>

      <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
        <button mat-button class="button-gray" style="visibility: hidden;">Cancel</button>
        <button mat-button class="button-blue" (click)="exit()">OK</button>
        </div>
  </div>
  </div>


  <ng-template #emailBlock>
    <div class="email-communications-modal-layout">
      <div class="header-label" style="grid-column: 1/3; width: 100%; margin-bottom: 20px;">Email Communication Status</div>
    
      <div class="label-style">Delivered At:</div>
      <div>
      <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsEmail.deliveredAt === null ? '' :
        dateFromTimeStamp(deliveryDetailsSms.deliveredAt) | date:'medium'}}" />
      </mat-form-field>
      </div>
    
    
      <div class="label-style">Attempts:</div>
      <div>
      <mat-form-field appearance="outline" style="width: 100px;">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsEmail.attempts === null ? '' : deliveryDetailsEmail.attempts }}" />
      </mat-form-field>
      </div>
    
      <div class="label-style">Response:</div>
      <div>
      <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsEmail.response === null ? '' : deliveryDetailsEmail.response}}" />
      </mat-form-field>
    </div>
    
      <div class="label-style">State:</div>
      <div>
      <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsEmail.state === null ? '' : deliveryDetailsEmail.state}}" />
      </mat-form-field>
    </div>
    
      <div class="label-style">Accepted Addresses:</div>
      <div>
      <mat-list-item *ngFor="let address of deliveryDetailsEmail.acceptedAddresses" style="display: contents;">
          <mat-form-field appearance="outline">
        <input matInput [disabled]="true" type="text" value="{{address}}" />
      </mat-form-field>
        </mat-list-item>
      </div>
  
    
    
      <div class="label-style">Pending Addresses:</div>
      <div>
      <mat-list-item *ngFor="let address of deliveryDetailsEmail.pendingAddresses" style="display: contents;">
          <mat-form-field appearance="outline">
          <input matInput [disabled]="true" type="text" value="{{address}}" />
        </mat-form-field>
      </mat-list-item>
    </div>
    
    
      <div class="label-style">Rejected Addresses:</div>
      <div>
      <mat-list-item *ngFor="let address of deliveryDetailsEmail.rejectedAddresses" style="display: contents;">
          <mat-form-field appearance="outline">
          <input matInput [disabled]="true" type="text" value="{{address}}" />
        </mat-form-field>
      </mat-list-item>
    </div>
  
    
      <div class="label-style">Updated At:</div>
      <div>
      <mat-form-field appearance="outline">
      <input matInput [disabled]="true" type="text" value="{{deliveryDetailsEmail.updatedAt === null ? '' :
        dateFromTimeStamp(deliveryDetailsEmail.updatedAt) | date:'medium'}}" />
      </mat-form-field>
      </div>


      <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
        <button mat-button class="button-gray" style="visibility: hidden;">Cancel</button>
        <button mat-button class="button-blue" (click)="exit()">OK</button>
        </div>
    
    </div>
  </ng-template>
</div>

<ng-template #notPopulatedYetBlock>
  <h5>Delivery details have not yet been populated, please check back in a few minutes.</h5>
</ng-template>
