<mat-dialog-content>
  <div class="add-site-visit-cont">
    <div class="sv-new-header">
      <span class="modal-heaer-add-new-site-visit">Add New Site Visit</span>
      <hr>
    </div>
    <div class="label-style label-field">Visit Duration</div>
    <app-time-field class="time-field"
        [value]= "initialValue"
        [fieldLabel]= "'Site Visit Length'"
        [readOnly]="false"
        [granularity]="fidelitySiteVisitLength"
        (valueChange) = "lengthToAdd$.next($event)"
        [minDate]="zeroHoursAsDate"
    ></app-time-field>

  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div class="footer-button">
  <button class="button-white" style="grid-column: 2/3;" mat-button mat-dialog-close (click)="cancelClose()">Cancel</button>
  <button class="button-blue" mat-button mat-dialog-close (click)="addSiteVisitClose()">Add Site Visit</button>
  <!--<button mat-button class="button-green">New Site Visit</button>-->
  </div>
</mat-dialog-actions>

