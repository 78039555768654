import { defaultFirestoreDiffMapper, FirestoreDiff } from '@dao/firestore-diff';


export interface RetrieveFirestoreProperties {

  retrieveFirestoreIgnoredMemberNames(): string[];
  retrievefirestoreIgnoreDiffTrackingMembers(): string[];
  retrievefirestoreCompositionMemberNames(): string[];
  retrievefirestoreCompositionalDiffMemberNames(): string[];
  retrieveFirestoreDenormalizedMemberNames(): string[];

  uncommitedDocId: boolean;
  createdAt: Date;
  updateRequired: boolean;
  updatePrincipalRequired: boolean;
  updatedThisCycle: boolean;
  createdObject: boolean;
  emisssionGuid: string;
  lastUpdatedAt: Date;
  lastUpdatedByEmployeeDocId: string;
  lastUpdatedByGuid: string;

  immutable: boolean;
  lazyLoaded: boolean;
  appVersionUpdate: string;
  appVersionCreate: string;
  bucketId: string;
  // displayContextUpdate: string;
  // displayContextCreate: string;

  DocId(): string | null;
  SetDocId(docId: string): void;
  createFirestoreDiff(previous: object | null, current: object | null, memberName: string,
    diffType: string, containedInArray: boolean): FirestoreDiff[];
}

export abstract class RetrieveFirestorePropertiesBase<T> implements RetrieveFirestoreProperties {
  static _firestoreIgnoredMemberNames : string[] = ["uncommitedDocId","freshObservableRequred", "updateRequired", "updatedThisCycle",
      "updatePrincipalRequired", "createdObject", "emisssionGuid", "lazyLoaded"];
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.slice().concat("createdAt","lastUpdatedAt","lastUpdatedByEmployeeDocId",
  "uncommitedDocId","freshObservableRequred", "emisssionGuid", "lazyLoaded", "lastUpdatedByGuid", "appVersionUpdate", "bucketId");
  static _firestoreCompositionMemberNames: string[] = [];
  static _firestoreCompositionalDiffMemberNames: string[] = [];
  static _firestoreCompositionDenormalized: string[] = [];

  docId: string = undefined;
  uncommitedDocId: boolean = false;
  createdAt: Date | null  = null;
  updateRequired: boolean = false;
  updatePrincipalRequired: boolean = false;
  updatedThisCycle: boolean = false;
  createdObject: boolean = false;
  emisssionGuid: string = "";
  lastUpdatedAt: Date | null = null;
  lastUpdatedByEmployeeDocId: string = "";
  lastUpdatedByGuid: string = "";
  immutable: boolean = false;
  lazyLoaded: boolean = false;
  appVersionUpdate: string = "";
  appVersionCreate: string = "";
  bucketId: string = "";

  retrievefirestoreIgnoreDiffTrackingMembers(): string[] {
    return RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers;
  }
  retrievefirestoreCompositionMemberNames(): string[] {
    return RetrieveFirestorePropertiesBase._firestoreCompositionMemberNames;
  }
  retrievefirestoreCompositionalDiffMemberNames(): string[] {
    return RetrieveFirestorePropertiesBase._firestoreCompositionalDiffMemberNames;
  }

  retrieveFirestoreIgnoredMemberNames() : string[] {
    return RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames;
  }

  retrieveFirestoreDenormalizedMemberNames() : string [] {
    return RetrieveFirestorePropertiesBase._firestoreCompositionDenormalized;
  }


  DocId(): string | null {
    return this.docId;
  }

  SetDocId(docId: string): void {
    this.docId = docId;
  }

  createFirestoreDiff(previous: RetrieveFirestoreProperties, current: RetrieveFirestoreProperties, memberName: string,
    diffType: string, containedInArray: boolean): FirestoreDiff[] {
      return defaultFirestoreDiffMapper(previous, current, memberName, diffType, containedInArray);
    }
}
