
<div class="modify-employees-schedule">
  <div class="header-label" style="grid-column: 1/3; width: 100%; padding-bottom: 10px;">Scheduling Conflict</div>
  <mat-dialog-content style="display: contents;">
    
    <div class="label-style" style="background-color: #FBFBFB; text-align: left; padding-left: 10px; width: 600px;">{{warningReason}}</div>
    <div class="label-style" style="background-color: #FBFBFB; text-align: left; padding-left: 10px; width: 600px">
      {{warningExplanation}}
    
  </div>
  </mat-dialog-content>
  <mat-dialog-actions style="display: contents;">
    <div class="footer-button" class="footer-cust" style="grid-column: 1/-1; padding-top: 20px; display: flex; justify-content: space-between;">
      <div style="display: flex;">
      <div>
    <button class="button-gray" mat-button mat-dialog-close (click)="cancelClose()">Cancel</button>
    </div>
    <div style="margin-left: 40px;">
      <button [disabled]="true" class="button-red" mat-button mat-dialog-close (click)="skipWarningClose()">Do not show again</button>
    </div>
    </div>
    <div>
      <button class="button-blue" style="grid-column: 2/3;" mat-button mat-dialog-close (click)="continueClose()">Continue</button>
      </div>
    </div>
  </mat-dialog-actions>
  </div>
