import { Component, OnInit, Input,OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, merge, Observable } from 'rxjs';
import { Job } from '../../../../../common/src/data/dao/job';
import {  tap,  takeUntil, delay, } from 'rxjs/operators';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { GenericServiceProviderSettingService } from '../../../../../common/src/data/dao-services/generic-service-provider-setting.service';
import { GenericServiceProviderSetting } from '../../../../../common/src/data/dao/generic-service-provider-setting';
import { UntypedFormGroup } from '@angular/forms';
import { CustomTagComponentInputs, CustomTagComponentOutputs } from 'web-app/src/app/custom-tags/custom-tags.component';
import { CustomTagsService } from 'web-app/src/app/custom-tags.service';
import { JobType } from '../../../../../common/src/data/dao/job-type';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { CompanyLocationService } from '../../../../../common/src/data/dao-services/company-location.service';


@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private genericServiceProviderSettingService: GenericServiceProviderSettingService, private jobService: JobService,
    private tagService: CustomTagsService, private router: Router, private companyLocationService: CompanyLocationService) { }


  ngAfterViewInit(): void {
    this.focus.pipe(
      delay(0),
      tap(() => console.log(this.jobTypeViewChild)),
      tap(() => this.jobTypeViewChild.focus()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  @Input() jobTypes: JobType[];
  @Input() jobPriorities: GenericServiceProviderSetting[];
  @Input() activeJob: Job;
  @Input() inputDisabled: boolean;
  @Input() form: UntypedFormGroup;
  @Input() focus: Observable<any> = new Observable<any>();

  tags: GenericServiceProviderSetting[] = [];

  customTagComponentInputs : CustomTagComponentInputs;
  customTagComponentOutputs : CustomTagComponentOutputs;
  destroyingComponent$: Subject<any> = new Subject();


  @ViewChild ("jobTypeFormField") jobTypeViewChild : MatSelect;

  get employeeLocations() { return this.companyLocationService;}

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  ngOnInit(): void {

    this.tags = this.form.get("jobTags").value;
    this.customTagComponentInputs = this.tagService.buildCustomTagsInput(this.tags, false, this.destroyingComponent$, 'job_tags', 'Job Tags')
    this.customTagComponentOutputs = this.tagService.buildCustomTagsOutput(this.tags, this.destroyingComponent$, 'job_tags');


    merge(this.customTagComponentOutputs.newlyCreatedTagForAdditon, this.customTagComponentOutputs.tagSelectedForAddition, this.customTagComponentOutputs.tagSelectedForRemoval).pipe(
      tap(() => this.form.patchValue({jobTags: this.tags.slice()})),
      takeUntil(this.destroyingComponent$),
    ).subscribe();
  }

  getHours(val: string) {
    if (val === "") {
      return " h";
    } else {
      return `${Math.floor(Number(val))} h`;
    }
  }

  compareWithDocIds(o1:any, o2:any) {
    if (o1===null || o2===null || o1 === undefined || o2 === undefined) { return false; }
    if (o1.docId === o2.docId) {
      return true;
    } else {
      return false;
    }
  }


  getMinutes(val: string) {
    if (val === "") {
      return " m";
    } else {
      const fractionalHours = Number(val)  - Math.floor(Number(val));
      return `${Math.round(fractionalHours * 60 )} m`;
    }
  }

  compareCommonServiceProviderSettings(o1: any, o2: any): boolean {
    return o1!== undefined && o2 !== undefined && o1 !== null && o2 !== null &&  o1.docId === o2.docId;
  }

  compareJobTypes(o1: any, o2: any): boolean {
    return o1!== undefined && o2 !== undefined && o1 !== null && o2 !== null &&  o1.jobTypeDocId === o2.jobTypeDocId;
  }

}
