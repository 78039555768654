import { Injectable } from '@angular/core';
import { LineItem } from '../../../../common/src/data/dao/line-item';
;
import {  AddressService } from './address.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { AuthenticationService } from '../../util/authentication.service';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, delay, map, mergeMap, take, tap } from 'rxjs/operators';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { where } from 'firebase/firestore';
import { SkillSetService } from './skill-set.service';


@Injectable({
  providedIn: 'root'
})

export class LineItemService extends DatabaseStoreService<LineItem> {
  constructor( fs: LineItemFirestoreService, authenticationService: AuthenticationService, store: LineItemStoreService, private addressService: AddressService,
     private skillSetService: SkillSetService) {
    super(fs, store, authenticationService, new Map([
      ["address", {associatedDocumentId: "addressDocId",
                   compositionStoreService: addressService}],
      ["requiredSkills", {associatedDocumentId: "requiredSkillsDocIds", compositionStoreService:
                            skillSetService}],
                          ]));
  }

  updatePrototypes() : void {
    console.log("BARRY");

    this.queryFirestoreDeep$([where("lineItemPrototypeDocId", "==", null)]).pipe(
      delay(1200),
      tap(x => console.log(x,` string`)),
      map(lineItems => {
        const toUpdate : Observable<LineItem>[] = [];
        lineItems.forEach(lineItem => {
          lineItem.active = true;
          lineItem.lineItemNeedsOrHasBeenRescheduled = false;
          lineItem.addToActiveJob = false;
          lineItem.originatingEstimateDocId = null;
          lineItem.originatingLineItemDocId = null;
          lineItem.abandoned = false;
          lineItem.scheduledFromEstimate = false;
          lineItem.lastUpdatedByEmployeeDocId = "f3cFHDXB45yrSQClus1M";
          toUpdate.push(this.update$(lineItem));
        });
        return toUpdate;
        }),
        mergeMap(x => zip(...x)),
        tap(x => console.log(x,` string`)),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
        take(1),
    ).subscribe();
  }

  lineItemNameDistinctValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.queryFirestoreDeep$([where("lineItemPrototypeDocId", "==", null)]).pipe(
        map(lineItems => lineItems.map(l=> l.title.toLowerCase())),
        map(lineItems => lineItems.includes(control.value.toLowerCase()) ? {'lineItemNameDistinct': true} : null),
        take(1),
        catchError(() => of(null).pipe(tap(x => console.log(x,` string`)),))
      );
    }
  }

  createConcreateLineItemFromPrototype(input: LineItem): LineItem {
    if (input.lineItemPrototypeDocId !== null) {
      // tslint:disable-next-line: max-line-length
      throw Error(`You can not create a concreate line item from non-prototypical line item doc id: ${input.lineItemPrototypeDocId}`);
    }
    const clone = new LineItem(input) as LineItem;
    clone.lineItemPrototypeDocId = clone.lineItemDocId;
    clone.lineItemDocId = null;
    return clone;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LineItemStoreService extends StateChangeStoreService<LineItem> {
  protected store = "line-item-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, LineItem>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class LineItemFirestoreService extends FirestoreBackend<LineItem> {

  protected basePath = "line_items";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): LineItem {
    return new LineItem(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new LineItem(), authService);
  }
}
