import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Observable, Subject, tap } from 'rxjs';

export class SettingsTableColumnDefinition{
  name: string;
  friendlyName: string;
  type: "string" | "date" | "boolean";
}

@Component({
  selector: 'app-settings-table',
  templateUrl: './settings-table.component.html',
  styleUrls: ['./settings-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsTableComponent implements OnInit, AfterViewInit{


  @Input() dataSource: Observable<any[]>;
  @Input() columns: SettingsTableColumnDefinition[] = [];
  @Input() detailTemplate: TemplateRef<any>;
  @Input() settingTableTitle: string;
  @Output() rowSelected$: Subject<any> = new Subject<any>();
  @Output() saveRow$: Subject<null> = new Subject<null>();
  @Output() deleteRow$ : Subject<null> = new Subject<null>();
  @Output() addRow$ : Subject<null> = new Subject<null>();

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;
  get table() : MatTable<any> { return this.tables?.first; }

  columnsToDisplayHumanReadable = new Map();
  allStandard: SettingsTableColumnDefinition[];
  headerRow: string[];

  standardDateColumns: SettingsTableColumnDefinition[] = [{name: 'createdAt', friendlyName: 'Created', type: "date"}]

  get columnNames() { return this.allStandard.map(c => c.name);}

  get trimmedTitle() { return this.settingTableTitle.slice(0, -1)}
  constructor() {
  }
  ngAfterViewInit(): void {
    this.dataSource.pipe(
      tap(() => this.table.renderRows()),
      tap(x => console.log(x,` string`)),
    ).subscribe();
  }

  getType(settingName :string) {
    return this.allStandard.find(x => x.name === settingName).type;
  }

  ngOnInit(): void {

    this.allStandard = this.columns.concat(this.standardDateColumns);
    this.headerRow = this.allStandard.map(c => c.name);
    this.allStandard.forEach(c => {
      this.columnsToDisplayHumanReadable.set(c.name, c.friendlyName);
    });
    console.log(this.columnsToDisplayHumanReadable);
  }

  selectRow(row: any) {
    this.rowSelected$.next(row);
  }

  AddNew() {
    this.addRow$.next(null);
  }

  Save() {
    this.saveRow$.next(null);
  }

  Delete() {
    this.deleteRow$.next(null);
  }

}
