import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from, merge, of } from 'rxjs';
import { filter,share,switchMap, take, tap } from 'rxjs/operators';
import SignaturePad from 'signature_pad';
import { PhotoService } from '../../../../../../../../common/src/data/services/photo.service';



@Component({
  selector: 'app-signature-pad',
  template: `
  <div class="sig-title">Sign Here</div>
  <div class="signature-container" #canvasContainer>
  <canvas class="signature-pad-canvas" #canvas style="touch-action: none;"></canvas>
  </div>
  <div class="sig-button-spacing">
  <button class="button-blue signature-button" (click)="save()">Save</button>
    <button class="button-white signature-button" mat-button (click)="clear()">Reset</button>
  </div>
    `,
  styleUrls: ['./signature-pad.component.scss']
})

export class SignaturePadComponent implements OnInit, AfterViewInit {

  signaturePad: any;
  controlKeyToRegisterSignaturePhotoTo: string;

  @ViewChild('canvas', { static: true }) signaturePadElement;
  @ViewChild('canvasContainer', { static: true }) signaturePadContainer;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SignaturePadComponent>, private photoService: PhotoService) {
    this.controlKeyToRegisterSignaturePhotoTo = data.key;
  }

  ngAfterViewInit(): void {
    this.signaturePadElement.nativeElement.width =  this.signaturePadContainer.nativeElement.clientWidth;
    this.signaturePadElement.nativeElement.height = this.signaturePadContainer.nativeElement.clientHeight;
  }

  ngOnInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  clear() {
    this.signaturePad.clear();
   }

   save() {
    const encoded = this.signaturePad.toDataURL() as string;
    let populated: boolean = false;

    const previousSig = this.photoService.photosAssignedControl(this.controlKeyToRegisterSignaturePhotoTo).pipe(
      take(1),
      share()
    );

    const popSig = previousSig.pipe(
      filter(x=>x.length>0),
      tap(() => populated = true),
      switchMap(x => this.photoService.deletePicture(x[0],0,this.controlKeyToRegisterSignaturePhotoTo)),
    );

    const notPop = previousSig.pipe(
      filter(x=>x.length==0 && !populated),
    );

    merge(popSig,notPop).pipe(
      switchMap(() => from(this.photoService.savebase64EncodedImage(encoded.split(',')[1],
        this.controlKeyToRegisterSignaturePhotoTo,"data:image/png;base64,"))),
      switchMap(photo => this.photoService.addNewPhotoToGallery(photo,this.controlKeyToRegisterSignaturePhotoTo)),
      take(1)
      ).subscribe(() => this.dialogRef.close(true));
   }
}
