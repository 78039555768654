import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import {  UntypedFormBuilder } from '@angular/forms';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyUtilityService } from '../formly-utility.service';

@Component({
  selector: 'app-formly-section',
  template: `
  <div style="display:none">{{to.changeDetect|async}}</div>
    <!-- Tech View -->
    <div style="margin-bottom: 10px;" *ngIf="to.activeView === 'techView'">
    <!-- <h4>{{key}}</h4>
    <h4>{{id}}</h4> -->
    <!-- <button (click)="log()">LogIt</button> -->
    <!-- Title of section and X to delete displayed in tech view. -->
    <!-- <div *ngIf="!to.repeating && to.generatedFromBranch" class="design-view-button-container"> -->
    <div *ngIf="to.generatedFromBranch" class="design-view-button-container">
    <div class="button-container">
        <button mat-button (click)="removeFormly()" [disabled]="formControl.disabled">
        <mat-icon class="button-no-print">close</mat-icon>
      </button>
    </div>
    <div class="design-view-style">{{to.title}}</div>
    </div>
    <!-- Begining of actual section. -->
      <div class="outer-section">
        <div *ngIf="field.fieldGroup.length > 0; else elseBlock">
          <div *ngFor="let field of field.fieldGroup; index as i trackBy:trackSection">
          <!-- <h5>{{field.key}}</h5> -->
              <div class="inner-section">
                <div class="section-header" [ngClass]="{'noRepeat':!to.repeating}">{{to.itemName}} - {{i+1}}</div>
                <formly-field class="row" [field]="field"></formly-field>
                <div style="margin-top: 10px;" class="button-no-print" [ngClass]="{'noRepeat':!to.repeating}">
                  <button [ngClass]="{'noRepeat':!to.repeating}" class="button-no-print button-red" mat-button [hidden]="!to.minimumNumberInstances === to.maximumNumberInstances" [disabled]="formControl.disabled" type="button" (click)="remove(i)">Delete {{to.itemName}}</button>
                  <button [ngClass]="{'noRepeat':!to.repeating}" class="button-no-print button-green" style="margin-left: 15px;" mat-button [hidden]="!to.minimumNumberInstances === to.maximumNumberInstances" [disabled]="formControl.disabled" (click)="add(i)">Add {{to.itemName}} </button>
                </div>
              </div>
          </div>
        </div>
        <ng-template #elseBlock>
        <div style="margin-top: 10px;" class="button-no-print" [ngClass]="{'noRepeat':!to.repeating}">
                  <button [ngClass]="{'noRepeat':!to.repeating}" class="button-no-print button-green" style="margin-left: 15px;" mat-button [hidden]="!to.minimumNumberInstances === to.maximumNumberInstances" [disabled]="formControl.disabled" (click)="add(0)">Add {{to.itemName}} </button>
                </div>
        </ng-template>
      </div>
    </div>

    <!-- Print View -->
    <div style="margin-bottom: 10px;" *ngIf="to.activeView === 'printView'">
      <div class="outer-section">
        <div *ngFor="let field of field.fieldGroup; index as i">
            <div class="inner-section">
              <div class="section-header" [ngClass]="{'noRepeat':!to.repeating}">{{to.itemName}} - {{i+1}}</div>
              <formly-field class="row" [field]="field"></formly-field>
            </div>
        </div>
    </div>
  </div>
  `,
  styleUrls: ['./formly-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormlySectionComponent extends FieldArrayType implements AfterViewInit {

  log() {
    console.log(this.form.value);
    console.log(this.field);
    console.log(this.model);
    console.log(this);
  }

  constructor(private formlyUtilityService:  FormlyUtilityService, private fb: UntypedFormBuilder) {
    super();
  }

  trackSection(index: number, item: FormlyFieldConfig) {
    return item.id;
  }

  ngAfterViewInit(): void {
    if (this.field.fieldGroup.length > 0) {
      const last = this.field.fieldGroup[0].fieldGroup.length - 1;
      if (this.field.fieldGroup[0].fieldGroup[last].formControl.value === null) {
        this.field.fieldGroup[0].fieldGroup[last].formControl.patchValue(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
      }
    }
  }

  override onPopulate(field: FieldArrayTypeConfig): void {
      super.onPopulate(field);
  }

  get parentId() : string {
    return this.field.parent ? this.field.parent.model._id : null;
  }

  removeFormly() {
    this.formlyUtilityService.removeSectionFromWorkflow$.next({sectionKey: (this.key as string), branchKey: this.to.sourceKey === undefined ? null : this.to.sourceKey ,
       parentId: this.parentId, originatedFromSection: true});
   }

  remove(i: number) {
    if (this.field.fieldGroup.length -1 >= this.to.minimumNumberInstances) {
      super.remove(i);
    } else {
      window.alert(`Unable to remove, ${this.to.minimumNumberInstances} ${this.to.itemName} required.`)
    }
  }

  add(i: number) {
    if (this.field.fieldGroup.length +1 <= this.to.maximumNumberInstances) {
      const initialModel = {_id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)};
      super.add(this.field.fieldGroup.length +1,initialModel);
      if (i !== this.field.fieldGroup.length-2) {
        const addedFieldGroup = this.field.fieldGroup.pop();
        this.field.fieldGroup.splice(i+1, 0, addedFieldGroup);
        for (let j = i+1; j < this.field.fieldGroup.length; j++) {
          this.field.fieldGroup[j].key = j.toString();
        }
        const addedModel = this.model.pop();
        this.model.splice(i+1, 0, addedModel);
      }
    } else {
      window.alert(`Unable to add, no more then ${this.to.maximumNumberInstances} ${this.to.itemName} allowed.`)
    }
  }
}
