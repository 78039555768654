import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { format, startOfDay } from 'date-fns';
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { delay, delayWhen, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Address } from '../../../../common/src/data/dao/address';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';
import { Customer } from '../../../../common/src/data/dao/customer';
import { CustomerCommunicationManagementService } from '../customer-communication/customer-communication-management.service';
import { CustomerCommunicationCategory } from '../../../../common/src/data/dao/customer-communication-template';
import { Discount } from '../../../../common/src/data/dao/discount';
import { EmployeeService } from '../../../../common/src/data/dao-services/employee.service';
import { Estimate } from '../../../../common/src/data/dao/estimate';
import { EstimateService } from '../../../../common/src/data/dao-services/estimate.service';
import { FormlyLineItemService } from '../form-builder/component-models/formly-controls/formly-line-item/formly-line-item.service';
import { FormModelFirestore } from '../../../../common/src/data/dao/form-model-firestore';
import { FormModelFirestoreService } from '../../../../common/src/data/dao-services/form-model-firestore.service';
import { JobService } from '../../../../common/src/data/dao-services/job.service';
import { Job } from '../../../../common/src/data/dao/job';
import { LineItem } from '../../../../common/src/data/dao/line-item';
import { LineItemDisplayModalComponent } from '../line-item/line-item-display-modal/line-item-display-modal.component';
import { Audience, LineItemRemovalReason, LineItemRemovalWithReason } from '../line-item/line-item-display/line-item-display.component';
import { LineItemService } from '../../../../common/src/data/dao-services/line-item.service';
import { LineItemControlType } from '../form-builder/component-models/line-items/line-item-enums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirestoreBackend } from '../../../../common/src/data/database-backend/retrieve-from-firestore';

@Component({
  selector: 'app-multiple-estimate-summary',
  templateUrl: './multiple-estimate-summary.component.html',
  styleUrls: ['./multiple-estimate-summary.component.scss']
})
export class MultipleEstimateSummaryComponent implements OnInit, OnDestroy {

  @Input() estimates: BehaviorSubject<Estimate[]>;
  @Input() customer: BehaviorSubject<Customer>;
  @Input() serviceAddress: Address;
  @Input() job: Job;

  sortedEstimates: Observable<Estimate[]>;

  destroyingComponent$ = new Subject();

  discountsToSchedule: Discount[] = [];

  columnsToDisplay: string[] = ["customer","serviceAddress","dateCreated","dateUpdated","employee","active","totalEstimateAmount",
"jobDocId","lastCommunication","openEstimate"];
footerSecondRowColumns: string[] = ["addEstimate"];

  lineItemDisplayModalDialogRef: MatDialogRef<LineItemDisplayModalComponent>;

  constructor(private dialog: MatDialog, private jobService: JobService, private router: Router, private estimateService: EstimateService,
    private lineItemService: LineItemService, private formlyLineItemService: FormlyLineItemService, private employeeService: EmployeeService,
    private authService: AuthenticationService, private customerCommunicationManagementService: CustomerCommunicationManagementService,
    private formModelFirestoreService: FormModelFirestoreService, private snackBar: MatSnackBar) { }

    ngOnDestroy(): void {
      this.destroyingComponent$.next(null);
      this.destroyingComponent$.complete();
      this.formlyLineItemService.initiatingJobScheduleFromEstimate = false;
      }

  ngOnInit(): void {
    this.formlyLineItemService.initiatingJobScheduleFromEstimate = true;
    this.sortedEstimates = this.estimates.pipe(
      map(es => es.sort((a,b) => a.createdAt.getTime() - b.createdAt.getTime())),
    );
  }

  routeToAddJobsPage(j: Job) {
    this.jobService.pushToLocalCache(j);
    this.router.navigate(['/add-job-board', {jobDocId: j.DocId()}]);
  }


  estimateSentDateString(estimate: Estimate) {
    if (estimate.mostRecentlySentCommunicationDate === null) {
      return "";
    } else {
      if (startOfDay(estimate.mostRecentlySentCommunicationDate).getTime() === startOfDay(new Date()).getTime()) {
        return format(estimate.mostRecentlySentCommunicationDate,'h:mm a');
      } else {
        return format(estimate.mostRecentlySentCommunicationDate,'LLL d');
      }
    }
  }

  sendEstimateToCustomer(estimate: Estimate) {
    const estimateDocIdsToGet$ : Observable<void>[] = [of(void(0))];
    if (estimate.formModelFirestore === null && this.estimateService.estimateFormFirestore !== undefined) {
      estimate.formModelFirestore = new FormModelFirestore({formFirestore: this.estimateService.estimateFormFirestore ,
        formFirestoreDocId: this.estimateService.estimateFormFirestore.DocId() });
        estimateDocIdsToGet$.push(this.formModelFirestoreService.retrieveDocId(estimate.formModelFirestore).pipe(
          tap(() => estimate.formModelFirestoreDocId = estimate.formModelFirestore.DocId())
        )
        );
    }

    this.snackBar.open("Sending Estimate",undefined);
    // We cancel all outstanding communications with given estimateDocId, as all outstanding communications are replaced by our fresh new friend.
    let batch: string = null;
    combineLatest(estimateDocIdsToGet$).pipe(
    switchMap(() => FirestoreBackend.retrieveFirestoreWriteBatchIdentifier()),
      tap(b => batch = b),
      switchMap(() => this.customerCommunicationManagementService.cancelCustomerCommunicationsForEstimate(estimate.DocId(),"MultipleEstimateSummaryComponent.sendEstimateToCustomer",batch)),
      take(1),
      switchMap(() => this.estimateService.update$(estimate,batch)),
      switchMap(() => this.customerCommunicationManagementService.createEstimateCustomerCommunications(estimate.job, CustomerCommunicationCategory.ESTIMATE,
        estimate.job?.siteVisits[0],  estimate, null, "Multiple Estimate Summary",batch)),
        tap(x => console.warn(x,`Scheduled Estimate Communication!`)),
        switchMap(() => this.estimateService.commitExternallyManagedFirestoreBatch(batch)),
        take(1)
        ).subscribe(() => this.snackBar.dismiss());
  }

  scheduleLineItems(lineItems: LineItem[], e: Estimate) {
    console.log(e);
    const j = new Job({lineItems: lineItems, notes: `Generated from estimate: ${e.DocId()}`,customer: e.customer, serviceAddress: e.serviceAddress});
    j.discounts = this.discountsToSchedule;
    this.jobService.retrieveDocId(j).pipe(
      tap(() => {
      if (e.job) {
        e.job.billingCustomers.forEach(c => j.billingCustomers.push(c));
        e.job.siteVisitContactCustomers.forEach(c => j.siteVisitContactCustomers.push(c));
      } else {
        j.billingCustomers.push(e.customer);
        j.siteVisitContactCustomers.push(e.customer);
      }
      this.routeToAddJobsPage(j);
    }),
    take(1)
    ).subscribe();
  }

  copyEstimate(sourceEstimate: Estimate) {
      this.estimateService.createCopy(sourceEstimate).pipe(
        switchMap(estimate => this.estimateService.create$(estimate)),
        tap(e => this.openEstimate(e)),
        take(1)
        ).subscribe();
  }

  openEstimate(estimate: Estimate) {
    const editorConfig = new MatDialogConfig();

    const lineItems$ : BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
    const discounts$ : BehaviorSubject<Discount[]> = new BehaviorSubject<Discount[]>([]);
    const addLineItem$: Subject<LineItem> = new Subject<LineItem>();
    const removeLineItem$: Subject<LineItemRemovalWithReason> = new Subject<LineItemRemovalWithReason>();
    const editLineItem$: Subject<{old: LineItem, new: LineItem}[]> = new Subject<{old: LineItem, new: LineItem}[]>();

    const closeEstimate$ : Subject<any> = new Subject<any>();

    const estimateUpdateInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    const completedLineItemOperation$ : ReplaySubject<LineItem> = new ReplaySubject<LineItem>(1);

    let preSource: Observable<any>;

    if (!estimate && !this.customer.value) {
      alert("You can not create a new estimate without specifying the customer estimate is for.  Please create customer first, or copy an existing estimate which already has a customer assigned.");
      return;
    }

    const editLineItems = editLineItem$.pipe(
      map(y => {
        y.forEach(x => {
          const indexOfLineItem = estimate.lineItems.findIndex(z => z.DocId() === x.old.DocId());
          estimate.lineItems.splice(indexOfLineItem,1,x.new);
        });
        return {val: estimate, operation: 'edit line item'}
      }),
    );

    const removeEstimatesLineItems = removeLineItem$.pipe(
      filter(x => x.reason === LineItemRemovalReason.Unknown),
      map(x => {
        const indexOfLineItem = estimate.lineItems.indexOf(x.lineItem);
        estimate.lineItems.splice(indexOfLineItem,1);
        return {val: estimate, operation: 'remove line item'};
      }));

    const addToEstimatesLineItems = addLineItem$.pipe(
      map(x => {
        estimate.lineItems.push(x);
        return {val: estimate, operation: 'add line item'}
      }));

      const editDiscounts$ = new Subject<{old: Discount, new: Discount}>().pipe(
        map(x => {
          const indexOfDiscount = estimate.discounts.findIndex(z => z.DocId() === x.old.DocId());
          estimate.discounts.splice(indexOfDiscount,1,x.new);
          return {val: estimate, operation: 'edit discount'}
        }),
      );

      const removeDiscounts$ = new Subject<Discount>().pipe(
        map(x => {
          const indexOfDiscount = estimate.discounts.indexOf(x);
          estimate.discounts.splice(indexOfDiscount,1);
          return {val: estimate, operation: 'remove discount'}
        }
      ));

      const addDiscounts$ = new Subject<Discount>().pipe(
        map(x => {
          estimate.discounts.push(x);
          return {val: estimate, operation: 'add discount'}
        }
      ));


    // When SP clicks create new, estimate is null.
    if (!estimate) {
      const newEstimate = new Estimate({customer: this.customer.value, lineItems: [], employee: this.employeeService.get(this.authService.activelyLoggedInEmployeeDocId),
        serviceAddress: this.serviceAddress, discounts: []});
      if (this.job) {
        newEstimate.job = this.job;
      }
      preSource = this.estimateService.create$(newEstimate);
    } else {
      preSource = of(estimate).pipe(take(1));
    }

    preSource.pipe(
      switchMap(est => this.estimateService.load$(est.DocId()).pipe(
      take(1),
      tap(e => estimate = e),
      tap(e => lineItems$.next(e.lineItems)),
      tap(e => discounts$.next(e.discounts)),
      tap(estimate => {
        Object.assign(editorConfig, {
          disableClose : false,
          width        : '500px',
          data         :
          {
            title: "Estimate",
            lineItems$ : lineItems$,
            discounts$ : discounts$,
            lineItemControlType: LineItemControlType.EstimateCreator,
            addLineItem$ : addLineItem$,
            removeLineItem$ : removeLineItem$,
            editLineItem$ : editLineItem$,
            addDiscount$ : addDiscounts$,
            removeDiscount$ : removeDiscounts$,
            editDiscount$ : editDiscounts$,
            intendedAudience: Audience.Internal,
            completedLineItemOperation$ : completedLineItemOperation$,
            estimate: estimate,
          }
          });

        this.lineItemDisplayModalDialogRef = this.dialog.open(LineItemDisplayModalComponent, editorConfig);

        this.lineItemDisplayModalDialogRef.afterClosed().pipe(
          tap( () => closeEstimate$.next(null)),
          take(1)
        ).subscribe();
      }),
      takeUntil(closeEstimate$)
    ))).subscribe();

    this.formlyLineItemService.addLineItemsToJob$.pipe(
      // delay here is to ensure that addDiscountToInvoice has chance to
      delay(300),
      tap(x => this.scheduleLineItems(x,estimate)),
      tap(() => this.dialog.closeAll()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  this.formlyLineItemService.addDiscountsToInvoice$.pipe(
    delayWhen(() => estimateUpdateInProgress.pipe(filter(x => x === false))),
    tap(x => this.discountsToSchedule.push(...x)),
    takeUntil(this.destroyingComponent$)
  ).subscribe();


      merge(editLineItems,removeEstimatesLineItems,addToEstimatesLineItems,editDiscounts$,addDiscounts$,removeDiscounts$).pipe(
        // debounce is b/c edits are iterated over when the edit is "schedule these line items"
        delay(300),
        tap(() => estimateUpdateInProgress.next(true)),
        mergeMap(e => this.estimateService.update$(e.val).pipe(
          map(() => e),
          tap(l => {
            if (l.operation === "add line item" || l.operation === "edit line item") {
              console.log(l.val);
              (l.val.lineItems).forEach( lineItem => completedLineItemOperation$.next(lineItem));
            }
          }),
          tap(() => estimateUpdateInProgress.next(false)),
          take(1)
        )),
        takeUntil(closeEstimate$)
      ).subscribe();


  }

}
