import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { endOfDay } from 'date-fns';
import format from 'date-fns/format';
import { TimeService } from './time.service';

@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TimeFieldComponent implements OnInit {

  // bindable properties
  @Input() value: Date;
  @Input() fieldLabel: string;
  @Input() readOnly: boolean;
  @Input() granularity: number;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() timeValueList: string[];

  // value change event emitter
  @Output() valueChange: EventEmitter<Date> = new EventEmitter();

  // time selector is bound here
  get timeValue() {
    if (this.value.toLocaleTimeString() === endOfDay(this.value).toLocaleTimeString()) {
      return "24:00";
    } else {
      return format(this.value, 'HH:mm')
    }
  }

  ngOnInit() {
      if (this.timeValueList === undefined) {
        this.timeValueList = [...this.timeService.GetTimeValueList(this.granularity, this.minDate, this.maxDate)];
      }
  } // eo function ngOnInit

  constructor(private timeService: TimeService) {}

  onTimeChange(event: any) {
    const hoursMinutes = event.value.split(':');
    const value = this.value;
    if (hoursMinutes[0] === '24') {
      value.setHours(23, 59, 59, 999);
    } else {
      value.setHours(hoursMinutes[0]);
      value.setMinutes(hoursMinutes[1]);
    }
    this.valueChange.emit(value);
} // eo function onTimeChange

}
