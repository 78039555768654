import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { delay, startWith, takeUntil, tap } from 'rxjs/operators';
import { CommonControlContainerFormGroup, ControlContainerComponent } from '../control-container.component';
import {ResizeEvent} from 'angular-resizable-element';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { merge, Observable, Subject } from 'rxjs';


// Could look at converting to this resizer (used already in image control component ) - https://xieziyu.github.io/angular2-draggable/#/resizable/events

@Component({
  selector: 'app-whitespace-control',
  templateUrl: './whitespace-control.component.html',
  styleUrls: ['./whitespace-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhitespaceControlComponent extends ControlContainerComponent implements AfterViewInit, OnDestroy  {

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig | FormlyFieldConfig[] {
    const internal = this.toFormlyFieldConfig();
    const retVal: FormlyFieldConfig = {};
    retVal.key = internal.key;
    retVal.type = internal.type;
    retVal.props = {...internal.props};
    retVal.wrappers = [];
    retVal.className = internal.className;


    //Json only representation strips out formControl, and changeDetection subject from props.
    const {formControl, ...newObj} = retVal;
    const {changeDetect,...temps} = retVal.props;
    newObj.props = temps;
    return newObj;
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {
    super.patchCommonFieldsToForm(formlyConfig);
    this.componentForm.patchValue({heightPixels: formlyConfig.props["heightPixels"]});
  }

  toFormlyFieldConfig(): FormlyFieldConfig {
    return (this.fields[0] as FormlyFieldConfig);
  }

  initilizeFormlyFieldConfig(): void {
    this.fields =  [{
      type: 'formlyHeightSpacer',
      key: `${this.perInstance}-whitespace`,
      props: {
        heightPixels: this.componentForm.get("heightPixels").value,
        changeDetect: new Subject<any>(),
        appearance: "none",
      },
      className: "white-space-spec",
      wrappers: ["change-detect"],
    }
  ];
  }

  patchControlComponentsToFormlyFields(): void {

    const obs$ : Observable<any>[] = this.patchControlComponentsToFormlyFieldsCommon();

    obs$.push(this.componentForm.get("heightPixels").valueChanges.pipe(
      startWith(this.componentForm.get("heightPixels").value),
      tap(x => (this.fields as FormlyFieldConfig)[0].props["heightPixels"] = x)));

      obs$.push(
        this.form.get("indexInParentContainer").valueChanges.pipe(
          startWith(this.form.get("indexInParentContainer").value),
          tap(x => (this.fields as FormlyFieldConfig)[0].props.indexInParentContainer = x)
        )
      );

    merge(...obs$).pipe(
      tap(() => (this.fields as FormlyFieldConfig)[0].props.changeDetect.next()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("White Space");
    retVal.patchValue({
      icon: "expand",
      iconColor: "#dc1515",
      controlComponentFormGroup: this.createWhitespaceFormGroup()
    });
    return retVal;
  }

  onLiveResize(event: ResizeEvent): void {
    let height = event.rectangle.bottom - event.rectangle.top - 2;
    this.componentForm.patchValue({heightPixels: height});
  }

  constructor( fb: UntypedFormBuilder)
  {
    super(WhitespaceControlComponent,fb);
    this.form = this.initilizeFormGroup();
    this.initilizeFormlyFieldConfig();
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  ngAfterViewInit(): void {
    this.NumberComponents++;
    this.patchControlComponentsToFormlyFields();
  }

  suppressDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  createWhitespaceFormGroup() {
    return this.fb.group({
      "heightPixels" : 40,
    })
  }

}
