import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { JobAttentionRequired } from '../dao/job-attention-required';

@Injectable({
  providedIn: 'root'
})
export class JobAttentionRequiredService extends DatabaseStoreService<JobAttentionRequired> {

  constructor( fs: JobAttentionRequiredFirestoreService, store: JobAttentionRequiredStoreService, auth: AuthenticationService) {
    super(fs, store,auth);
  }
}

@Injectable({
  providedIn: 'root'
})
export class JobAttentionRequiredStoreService extends StateChangeStoreService<JobAttentionRequired> {
  protected store = "JobAttentionRequired-store";

constructor(firestoreDiffService: FirestoreDiffService) {
  super(new Map<string, JobAttentionRequired>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class JobAttentionRequiredFirestoreService extends FirestoreBackend<JobAttentionRequired> {

 protected basePath = "JobAttentionRequired";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): JobAttentionRequired {
return new JobAttentionRequired(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new JobAttentionRequired(), authService);
 }
}
