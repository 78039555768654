
<div class="sv-data-display">
    <div class="sv-data-display-header">
        <span>Scheduler Data Display</span>
    </div>
    
       <mat-checkbox class="selection-data">City</mat-checkbox>
       <mat-checkbox class="selection-data">Address</mat-checkbox>
       <mat-checkbox class="selection-data">State</mat-checkbox>
       <mat-checkbox class="selection-data">Zip Code</mat-checkbox>
       <mat-checkbox class="selection-data">Time Window</mat-checkbox>
       <mat-checkbox class="selection-data">Customer Name</mat-checkbox>
       <mat-checkbox class="selection-data">Parts</mat-checkbox>
       <mat-checkbox class="selection-data">Materials</mat-checkbox>
       <mat-checkbox class="selection-data">Equipment</mat-checkbox>
       <mat-checkbox class="selection-data">Skillset</mat-checkbox>

       <mat-slide-toggle class="business-hours-toggle">Show Business Hours</mat-slide-toggle>
       
    


</div>