import { addHours, endOfDay, startOfDay } from "date-fns";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { CustomerCommunicationTemplate } from "./customer-communication-template";

export enum InitialCommunicationTimingType {
  IMEDIATELY = "Immediately",
  DELAYHOURS = "Delay Hours",
  DELAYDAYS = "Delay Days",
  PRECEEDHOURS = "Preceed Hours",
}

export class CustomerCommunicationScheduling extends RetrieveFirestorePropertiesBase<CustomerCommunicationScheduling>  {


customerCommunicationTemplate: CustomerCommunicationTemplate;
customerCommunicationTemplateDocId : string;

initialCommunicationTimingType : InitialCommunicationTimingType = InitialCommunicationTimingType.IMEDIATELY;
delayHoursFromTriggeringToInitiallySending : Date = startOfDay(new Date());
//This is the number of hours to send communication preceeding triggering event ( start of arrival window for example )
delayHoursFromTriggeringBeforeInitiallySending : Date = endOfDay(new Date());

timeOfDayToSendMessage: Date = addHours(startOfDay(new Date()), 12);
delayDaysToSendInitialMessage : number = 0;
delayDaysToSendFollowUpMessage : number = 7;
numberOfAdditionalMessagesToSend: number = 2;
scheduleFollowUpMessages: boolean = false;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["customerCommunicationTemplate"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["customerCommunicationTemplateDocId","customerCommunicationTemplate"]);
static _firestoreCompositionMemberNames = ["customerCommunicationTemplate"];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return CustomerCommunicationScheduling._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return CustomerCommunicationScheduling._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return CustomerCommunicationScheduling._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return CustomerCommunicationScheduling._firestoreCompositionMemberNames;}

public constructor(init?: Partial<CustomerCommunicationScheduling>) {
super();
Object.assign(this, init);
}
}
