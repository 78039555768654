import { Injectable } from '@angular/core';
import { Observable, } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { Address } from '../dao/address';
import { AuthenticationService } from '../../util/authentication.service';
import { limit, orderBy, where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends DatabaseStoreService<Address> {

  constructor(fs: AddressFirestoreService, authenticationService: AuthenticationService, store: AddressStoreService ) {
    super(fs, store, authenticationService);
  }

/**
 * Returns observable of addresses which match passed in search.
 * Currently just using >= on fireStore, combined w/ forcing inclusion of search in lineOne of address.
 * @param search Search string we wish to retrieve addresses for.
 */
  searchAddress(search: string): Observable<Address[]> {
    return (this.fs as AddressFirestoreService).searchAddress(search);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AddressStoreService extends StateChangeStoreService<Address> {
  protected store = "address-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, Address>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class AddressFirestoreService extends FirestoreBackend<Address> {
  protected basePath = "addresses";

  constructor(protected authService: AuthenticationService) {
    super(new Address(), authService);
  }

  public searchAddress(search: string): Observable<Address[]> {
    return this.QueryFirestoreShallowValueChanges([where('lineOne', '>=', search),orderBy('lineOne'),
    limit(25)]).pipe(
    map(x => x.filter(z => z.lineOne.includes(search))),
    takeUntil(FirestoreBackend.destroyingComponent$)
  );
  }

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Address {
    return new Address(obj);
  }
}
