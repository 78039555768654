<form>
  <div class="discount-grid" [formGroup]="form">
    <div class="header-label" style="margin-bottom: 20px;">Add Discount</div>
    <!--DISCOUNT CATAGORY-->
    <div class="label-style" style="margin-bottom: 40px;">Discount Catagory</div>
    <div class="medium-field">
      <mat-form-field appearance="outline" class="form-align">
            <mat-select formControlName="discountCatagory" placeholder="Discount Catagory">
            <mat-option *ngFor="let discountCatagory of discountCatagories" [value]="discountCatagory" >
              {{discountCatagory}}
            </mat-option>
          </mat-select>
          <mat-error>Discount Catagory must be selected.</mat-error>
        </mat-form-field>
    </div>
    <!-- DISCOUNT TYPE -->
    <div class="label-style" style="margin-bottom: 40px;">Discount Type</div>
    <div class="medium-field">
      <mat-form-field appearance="outline" class="form-align">
            <mat-select formControlName="discountType" placeholder="Discount Type">
            <mat-option *ngFor="let d of discountTypes" [value]="d" >
              {{d}}
            </mat-option>
          </mat-select>
          <mat-error>Discount Type must be selected.</mat-error>
        </mat-form-field>
    </div>
    <!-- FIXED DISCOUNT -->
    <div class="label-style">Fixed Discount Amount</div>
    <div class="short-field">
      <mat-form-field appearance="outline" class="form-align" style="width: 100px;">
            <input matInput placeholder="$0.00" currencyMask [options]="{align: 'left'}" inputmode="numeric" formControlName="discountFixedAmount"
            [errorStateMatcher]="matcher"
            [name]="ElementNameForId('633733')" autofill='off' autocomplete='new-password' data-lpignore='true'>
            <mat-error *ngIf="form.errors?.['discountFixedAmount']">Discount amount must be specified.</mat-error>
        </mat-form-field>
    </div>
    <!-- PERCENT DISCOUNT -->
    <div class="label-style">Percent Discount</div>
    <div class="short-field">
      <mat-form-field appearance="outline" class="form-align" style="width: 100px;">
            <input matInput placeholder="0.00%" appPercentageMask type="text" formControlName="discountPercentageDisplay"
            [errorStateMatcher]="matcher"
            [name]="ElementNameForId('433733')" autofill='off' autocomplete='new-password' data-lpignore='true'>
            <mat-error *ngIf="form.errors?.['discountPercentage']">Discount percent must be specified.</mat-error>
      </mat-form-field>
    </div>
    <!-- TITLE -->
    <div class="label-style" style="margin-bottom: 40px;">Title</div>
    <div class="medium-field">
      <mat-form-field appearance="outline" class="form-align">
            <input matInput placeholder="Title" formControlName="title"
            [name]="ElementNameForId('993803')" autofill='off' autocomplete='new-password' data-lpignore='true'>
            <mat-error>Title is required, and must be longer then 3 characters.</mat-error>
      </mat-form-field>
    </div>
    <!-- DESCRIPTION -->
    <div class="label-style">Description</div>
    <div class="medium-field">
      <mat-form-field appearance="outline" class="form-align" style="width: 380px;">
            <textarea matInput placeholder="Description" formControlName="description"
            [name]="ElementNameForId('651051')" autofill='off' autocomplete='new-password' data-lpignore='true'></textarea>
      </mat-form-field>
    </div>
    <!-- INTERNAL NOTES-->
    <div class="label-style">Internal Notes</div>
    <div class="medium-field">
      <mat-form-field appearance="outline" class="form-align" style="width: 380px;">
            <textarea matInput placeholder="Internal Notes" formControlName="internalNotes"
            [name]="ElementNameForId('703034')" autofill='off' autocomplete='new-password' data-lpignore='true'></textarea>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
    <button mat-button class="button-gray" type="button" (click)="cancel()">Cancel</button>
    <button mat-button class="button-blue" type="submit" (click) = "submit()">Save</button>
  </div>
</form>
