import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyRatingComponent } from './formly-rating.component';
import { FormlyModule } from '@ngx-formly/core';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [FormlyRatingComponent],

  imports: [
    CommonModule,
    MatTableModule,
    MatRadioModule,
    FormlyModule.forChild({
      types: [
        {name: 'formlyRating', component: FormlyRatingComponent},
      ]}),
  ]
})
export class FormlyRatingModule { }
