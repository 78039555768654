<h2>Arrival window has been changed for site visit, please indicate how you would like to proceed.</h2>
<div *ngFor="let c of customersWithRolesAndPrimaryDesignation">
  <app-customer-display-card
    [customerWithRoleAndPrimary] = c
  ></app-customer-display-card>
</div>
<h4>Arrival Window Previously Set To:</h4>
<h5>{{arrivalWindowDateFormatStart(previousArrivalWindowStart)}} - {{arrivalWindowDateFormatEnd(previousArrivalWindowEnd)}}</h5>
<h4>Arrival Window Updated To:</h4>
<h5>{{arrivalWindowDateFormatStart(currentArrivalWindowStart)}} - {{arrivalWindowDateFormatEnd(currentArrivalWindowEnd)}}</h5>
<button (click)="Resubmit()">Resend Appointment Confirmation</button>
<button (click)="Exit()">Exit</button>
