import { Component, OnInit, ElementRef,  OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject,  merge,  Observable,  Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap,  take,  takeUntil, tap } from 'rxjs/operators';
import { Invoice } from '../../../../common/src/data/dao/invoice';
import { InvoiceService } from '../../../../common/src/data/dao-services/invoice.service';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';
import { Customer } from '../../../../common/src/data/dao/customer';
import { Estimate } from '../../../../common/src/data/dao/estimate';
import { EstimateService } from '../../../../common/src/data/dao-services/estimate.service';
import { JobService } from '../../../../common/src/data/dao-services/job.service';
import { CustomerWithRoleOnJobAndPrimaryDesignation, Job } from '../../../../common/src/data/dao/job';
import { SiteVisit } from '../../../../common/src/data/dao/site-visit';
import { LineItem } from '../../../../common/src/data/dao/line-item';
import { LineItemRemovalWithReason } from '../line-item/line-item-display/line-item-display.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { startOfDay } from 'date-fns';
import { where } from 'firebase/firestore';

@Component({
  selector: 'app-jobs-page',
  templateUrl: './jobs-page.component.html',
  styleUrls: ['./jobs-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsPageComponent implements OnInit, OnDestroy {

  destroyingComponent$ = new Subject();
  activeJob: BehaviorSubject<Job> = new BehaviorSubject<Job>(null);
  customers: BehaviorSubject<{customer: Customer, role: string, primary: boolean}[]> = new BehaviorSubject<CustomerWithRoleOnJobAndPrimaryDesignation[]>([]);
  addedCustomer: Subject<Customer> = new Subject<Customer>();
  invoices: BehaviorSubject<Invoice[]> = new BehaviorSubject<Invoice[]>([]);
  siteVisit$ = new BehaviorSubject<SiteVisit[]>([]);
  estimates: BehaviorSubject<Estimate[]> = new BehaviorSubject<Estimate[]>([]);
  lineItems$: BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
  primaryCustomer$: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);

  addLineItem$ = new Subject<LineItem>();
  removeLineItem$ = new Subject<LineItemRemovalWithReason>();
  editLineItem$ = new  Subject<{old: LineItem, new: LineItem}[]>();


  constructor(private elementRef: ElementRef, private route: ActivatedRoute, private jobService: JobService,
    private invoiceService: InvoiceService, private authenticationService: AuthenticationService, private estimateService: EstimateService,
    private snackBar: MatSnackBar) {

    this.customers.pipe(
      map(x => x.filter(q => q.primary)),
      tap(x => this.primaryCustomer$.next(x.length > 0 ? x[0].customer : null)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  get firstSiteVisit() { return this.siteVisit$.value.length > 0 ? this.siteVisit$.value[0] : undefined; }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
    }

  ngAfterViewInit(){
    this.addedCustomer.pipe(
      tap(x => console.log(x,` string`)),
      tap(c => {
        this.activeJob.value.billingCustomers.push(c);
        this.activeJob.value.siteVisitContactCustomers.push(c);
      }),
      tap(x => console.log(this.activeJob.value)),
      switchMap(() => this.jobService.update$(this.activeJob.value)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
 }

  ngOnInit(): void {


    const addLineItems = this.addLineItem$.pipe(
      map( x => {
        const oldJobDurationHours = this.activeJob.value.lineItemExpectedHours;
        this.activeJob.value.lineItems.push(x);
        this.messageUserJobLengthChanged(oldJobDurationHours, this.activeJob.value.lineItemExpectedHours);
        return this.activeJob.value;
      }),
      );

    const removeLineItems = this.removeLineItem$.pipe(
      map(x => {
        const oldJobDurationHours = this.activeJob.value.lineItemExpectedHours;
        const indexOfLineItem = this.activeJob.value.lineItems.findIndex(q => q.DocId() === x.lineItem.DocId());
        this.activeJob.value.lineItems.splice(indexOfLineItem,1);
        this.messageUserJobLengthChanged(oldJobDurationHours, this.activeJob.value.lineItemExpectedHours);
        return this.activeJob.value;
      }));

    const editLineItems = this.editLineItem$.pipe(
      map(z => z[0]),
      map(x => {
        const oldJobDurationHours = this.activeJob.value.lineItemExpectedHours;
        const indexOfLineItem = this.activeJob.value.lineItems.findIndex(z => z.DocId() === x.old.DocId());
        this.activeJob.value.lineItems.splice(indexOfLineItem,1,x.new);
        this.messageUserJobLengthChanged(oldJobDurationHours, this.activeJob.value.lineItemExpectedHours);
        return this.activeJob.value;
      }));

      merge(addLineItems,removeLineItems,editLineItems).pipe(
        tap(() => this.activeJob.next(this.activeJob.value)),
        switchMap(() => this.jobService.update$(this.activeJob.value)),
        takeUntil(this.destroyingComponent$)).subscribe();

    this.activeJob.pipe(
      filter(x => x !== null),
      map(x => x.lineItems.concat(x.abandonedLineItems)),
      tap(x => this.lineItems$.next(x)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    const routedLoadOfSiteVisit = this.route.paramMap.pipe(
      map(params => params.get("jobDocId")),
      switchMap(docId => this.jobService.load$(docId)),
      tap(job => this.activeJob.next(job)),
      takeUntil(this.destroyingComponent$)
      ).subscribe();

    this.activeJob.pipe(
        filter(x => x !== null),
        map(j => j.customer),
        switchMap(x => this.estimateService.queryFirestoreDeep$([where("customerDocId", "==", x.DocId())])),
        map(x => x.filter(q => q.lineItems.length > 0)),
        takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.estimates.next(x));

    this.activeJob.pipe(
      filter(a => a!==null),
      map(x => x.retrieveCustomersWithRolesAndPrimaryDesignations()),
      tap(c => this.customers.next(c)),
      takeUntil(this.destroyingComponent$))
        .subscribe();

        this.activeJob.pipe(
        filter(x => x!==null),
        map(x => x.DocId()),
        distinctUntilChanged(),
        switchMap(jobDocId => this.invoiceService.queryFirestoreDeep$([where("jobDocId", "==", jobDocId)])),
        tap(x => this.activeJob.value.updateJobPaymentStatus(x)),
        map(x => x.filter(q => q.lineItems.length > 0)),
        tap(x => this.invoices.next(x)),
        takeUntil(this.destroyingComponent$)
        ).subscribe();

    this.activeJob.pipe(
    filter(a => a!==null),
    map(x => x.siteVisits),
    map(x => {
      x.sort((a,b) =>  a.actualStartDate !== undefined && b.actualStartDate === undefined ? 1 :
      a.actualStartDate === undefined && b.actualStartDate !== undefined ? -1 :
      a.actualStartDate === undefined && b.actualStartDate === undefined ? 0 :
      a.actualStartDate.getTime() - b.actualStartDate.getTime());
      return x;
    }),
    tap(x => this.activeJob.value.updateJobPaymentStatus(this.invoices.value)),
    takeUntil(this.destroyingComponent$)
    ).subscribe(x => this.siteVisit$.next(x));
  }

  formatHours(hours: number): string {
    return `${Math.floor(hours)}H, ${Math.floor((hours-Math.floor(hours))*60)}M`;
  }

  messageUserJobLengthChanged(previousLineItemExpectedHours: number, newLineItemExpectedHours: number) {
    if (previousLineItemExpectedHours !== newLineItemExpectedHours &&
      this.activeJob.value.siteVisits.filter(x => startOfDay(new Date()).getTime() < x.startDate.getTime()).length > 0) {
      this.snackBar.open(`Expected hours from line items changed from ${this.formatHours(previousLineItemExpectedHours)} to ${this.formatHours(newLineItemExpectedHours)}, ` +
      `consider ${previousLineItemExpectedHours < newLineItemExpectedHours ? 'increasing future site visit length or adding and additional visit as needed.' :
      'decreasing or eliminating future site visit.'}`,'Ok',{duration: 5000});
    }
  }

}
