import { Injectable } from "@angular/core";
import { debounceTime, filter, Observable, of, Subject, take } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { FormFirestoreSummaryService } from "../../../../../../../common/src/data/dao-services/form-firestore-summary.service";
import { FormFirestoreService } from "../../../../../../../common/src/data/dao-services/form-firestore.service";
import { FormFirestore } from "../../../../../../../common/src/data/dao/form-firestore";
import { FormModelFirestore } from "../../../../../../../common/src/data/dao/form-model-firestore";
import { FormModelFirestoreService } from "../../../../../../../common/src/data/dao-services/form-model-firestore.service";
import { FormlyFieldConfig } from "@ngx-formly/core";

export enum WORKFLOW_STAGE {
  DESIGN = "Design",
  DEPLOYED = "Deployed",
}

@Injectable({
  providedIn: "root",
})
export class FetchUpdatedWorkflowService {
  formModelFirestoreMapping: Map<string, FormModelFirestore> = new Map<string,FormModelFirestore>();
  formModelFirestoreToInstatiatedFormsMap: Map<string, Map<string, FormlyFieldConfig>> = new Map<string, Map<string, FormlyFieldConfig>>();
  updatedFormModelFirestore$: Subject<any> = new Subject<any>();
  workFlowStage: WORKFLOW_STAGE = WORKFLOW_STAGE.DEPLOYED;

  formModelFirestore(formModelFirestoreDocId: string): FormModelFirestore {
    return this.formModelFirestoreMapping.get(formModelFirestoreDocId);
  }

  constructor(private formFirestoreService: FormFirestoreService,private formFirestoreSummaryService: FormFirestoreSummaryService,
    private formModelFirestoreService: FormModelFirestoreService
  ) {}

  public resetMappings() {
    this.formModelFirestoreMapping.clear();
    this.formModelFirestoreToInstatiatedFormsMap.clear();
  }

  public getActiveFormSummaryDocIdFromFormFirestoreDocId(formFirestoreDocId: string,formModelFirestoreDocId: string): string {
    return this.formModelFirestore(formModelFirestoreDocId).formFirestoreInstantiatedDocIdToSummaryDocIdMap.get(formFirestoreDocId);
  }

  public getActiveFormFirestoreDocIdFromSummary(formFirestoreSummaryDocId: string,formModelFirestoreDocId: string): Observable<string> {
    if (
      this.formModelFirestore(formModelFirestoreDocId)?.formFirestoreSummaryDocIdToInstantiatedDocIdMap?.has(formFirestoreSummaryDocId)
    ) {
      return of(
        this.formModelFirestore(formModelFirestoreDocId).formFirestoreSummaryDocIdToInstantiatedDocIdMap.get(formFirestoreSummaryDocId)
      );
    } else {
      let retVal: Observable<any> = of(null);
      if (this.formModelFirestore(formModelFirestoreDocId) === undefined) {
        return this.formModelFirestoreService.load$(formModelFirestoreDocId).pipe(
            tap((x) =>
              this.formModelFirestoreMapping.set(formModelFirestoreDocId, x)
            ),
            take(1),
            switchMap(() =>this.getActiveFormFirestoreDocIdFromSummary(formFirestoreSummaryDocId,formModelFirestoreDocId))
          );
      } else {
        return retVal.pipe(
          switchMap(() => this.formFirestoreSummaryService.load$(formFirestoreSummaryDocId)),
          debounceTime(100),
          map((summary) =>
            this.workFlowStage === WORKFLOW_STAGE.DEPLOYED
              ? summary.currentDeployedFirestoreDocId
              : summary.currentDesignFirestoreDocId
          ),
          switchMap(x => this.formFirestoreService.load$(x)),
          filter(x => x !== null),
          map((x) => x.DocId()),
          tap((x) => {
            this.formModelFirestore(formModelFirestoreDocId).setformFirestoreSummaryDocIdToInstantiatedDocIdMap(formFirestoreSummaryDocId,x);
          }),
          take(1)
        );
      }
    }
  }

  formFirestoreSummaryDocIdNeedsCheckedForUpdate(formFirestoreSummaryDocId: string,formModelFirestoreDocId: string): boolean {
    if (this.formModelFirestore(formModelFirestoreDocId) === undefined) {
      return true;
    }
    const formModelFirestoreMap = this.formModelFirestore(formModelFirestoreDocId).formFirestoreSummaryDocIdToInstantiatedDocIdMap;
    return !formModelFirestoreMap.has(formFirestoreSummaryDocId);
  }

  formFirestoreDocIdNeedsCheckedForUpdate(formFirestoreDocId: string,formModelFirestoreDocId: string): boolean {
    // if the formModelFirestore is in mapping but the formFirestoreSummaryDocIdToInstantiatedDocIdMap does not have the formFirestoreDocId, needs checked for update.
    if (this.formModelFirestore(formModelFirestoreDocId) === undefined) {
      return true;
    }
    const formModelFirestoreMap = this.formModelFirestore(formModelFirestoreDocId).formFirestoreInstantiatedDocIdToSummaryDocIdMap;
    return !formModelFirestoreMap.has(formFirestoreDocId);
  }

  retrieveRefreshedFormFirestoreDocId(formFirestoreDocId: string,formModelFirestoreDocId: string): Observable<string> {
    // if the formModelFirestore is not in mapping yet, we need to populate it before we check if it should be updated.
    if (!this.formModelFirestoreMapping.has(formModelFirestoreDocId)) {
      return this.formModelFirestoreService.load$(formModelFirestoreDocId).pipe(
        tap((x) =>
          this.formModelFirestoreMapping.set(formModelFirestoreDocId, x)
        ),
        take(1),
        switchMap(() =>
          this.retrieveRefreshedFormFirestoreDocId(formFirestoreDocId,formModelFirestoreDocId
          )
        )
      );
    } else {
      return this.formFirestoreService.load$(formFirestoreDocId).pipe(
        filter((x) => x !== null),
        switchMap((x) =>
          this.retrieveRefreshedFormFirestore(x, formModelFirestoreDocId)
        ),
        map((x) => x.DocId())
      );
    }
  }

  patchInUpdatedDocId(updatedFirestoreDocId: string,formFirestoreSummaryDocId: string,formModelFirestoreDocId: string) {
    this.formModelFirestore(formModelFirestoreDocId).setformFirestoreSummaryDocIdToInstantiatedDocIdMap(formFirestoreSummaryDocId,
      updatedFirestoreDocId);
    this.updatedFormModelFirestore$.next(null);
  }

  retrieveRefreshedFormFirestore(formFirestore: FormFirestore,formModelFirestoreDocId: string): Observable<FormFirestore> {
    let docId = null;
    if (
      this.formModelFirestore(formModelFirestoreDocId)?.formFirestoreSummaryDocIdToInstantiatedDocIdMap.has(formFirestore.formSummary.DocId())
    ) {
      docId = this.formModelFirestore(formModelFirestoreDocId).formFirestoreSummaryDocIdToInstantiatedDocIdMap.get(
        formFirestore.formSummary.DocId()
      );
    }
    if (docId === null) {
      docId = this.workFlowStage === WORKFLOW_STAGE.DEPLOYED? formFirestore.formSummary.currentDeployedFirestoreDocId : formFirestore.formSummary.currentDesignFirestoreDocId;
      this.formModelFirestore(formModelFirestoreDocId).setformFirestoreSummaryDocIdToInstantiatedDocIdMap(formFirestore.formSummary.DocId(),docId);
    }
    return this.formFirestoreService.load$(docId);
  }
}
