
<div [formGroup]="form" class="content-customer">

<div class="add-site-visit-cont">

      <div class="sv-new-header">Resolve Inaccurate Address</div>
      <div style="width: 100%; grid-column: 1/5;">
        <header class="header-error" *ngIf="form.get('googleGeocoderError').value === 'ZERO_RESULTS'">Unable to find address.  Please select a known address</header>
  <header class="header-error" *ngIf="form.get('googleGeocoderError').value === 'MULTIPLE_RESULTS'">Multiple results found.  Please select a known address</header>
      </div>
      <div style="width: 100%; grid-column: 1/5;"><hr></div>
     <!-- <div style="width: 100%; grid-column: 1/5;">
        <header class="header-error" *ngIf="form.get('googleGeocoderError').value === 'ZERO_RESULTS'">Unable to find address.  Please select a known address</header>
  <header class="header-error" *ngIf="form.get('googleGeocoderError').value === 'MULTIPLE_RESULTS'">Multiple results found.  Please select a known address</header>
      </div>-->

<!-- Start  Address -->
<div class="label-style">Service Address</div>
<div style="width: 100%; grid-column: 2/5;">
<mat-form-field appearance="outline" style="width: 330px">
  <input type="text" matInput placeholder="Service Address" autocomplete="new-password" autofill="off" [name]="form.get('noAutoFill').value" data-lpignore="true"
  [matAutocomplete]="billingAutoComplete" formControlName="serviceAddressDisplay" (keyup)="serviceAddressSearchKeyUp($event)" >
    <mat-autocomplete panelWidth=600px #billingAutoComplete="matAutocomplete" (optionSelected)="billingAddressOptionSelected($event)">
      <mat-option *ngFor ="let place of addressesFound$ | async;" [value]="place" >
          <i class="material-icons">place</i> {{displayAddressSelected(place)}}
      </mat-option>
    </mat-autocomplete>
  <mat-error>Service address is required.</mat-error>
</mat-form-field>
<mat-form-field appearance="outline" style="width:70px; margin-left: 10px;">
    <input type = "text" matInput placeholder="Unit" autocomplete="new-password" autofill="off" formControlName="serviceAddressUnit" [name]="form.get('noAutoFill').value">
  </mat-form-field>
</div>
<div class="label-style">Dirty Address</div>
<div style="width:330px; grid-column: 2/5;">
  <mat-form-field appearance="outline" style="width:100%;" >
    <textarea matInput formControlName="customerSuppliedAddress"></textarea>
  </mat-form-field>
</div>

<div *ngIf="activeSearch" fxLayout="row" fxLayoutAlign=" center"><mat-spinner [diameter]="25"></mat-spinner></div>
<div *ngIf="displayNoResultsFound" fxLayout="row" fxLayoutAlign=" center" style="font-size: 16px; color: red; margin-left: 5px;">No Results Found<i class="material-icons">error</i></div>


<div style="width: 100%; grid-column: 1/5; margin-top: 30px;"><hr></div>
  <button style="width: 80px;" mat-button class="button-gray footer-button-cancel" (click)="cancel()">Cancel</button>
  <button style="width: 80px; justify-self: flex-end;" mat-button class="button-blue footer-button-save" (click) = "submit()" type="submit">Save</button>

</div>
</div>


