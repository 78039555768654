import { Component, HostListener, inject } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { from, interval } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FirestoreBackend } from '../../../common/src/data/database-backend/retrieve-from-firestore';
import { AuthenticationService } from '../../../common/src/util/authentication.service';
import { SettingsService } from './settings/settings.service';
import { environment } from '../environments/environment';
import { LocalSettingsService } from './settings/local-settings.service';
import {LoggingBackendService} from '../../../common/src/data/logging/logging-backend.service';
import { DataLinkService } from './form-builder/data-link-populator/data-link.service';

const {Device} = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private authenticationService: AuthenticationService;

  constructor( private router: Router, private settingsService: SettingsService,
    private localSettingsService: LocalSettingsService, private loggingBackendService: LoggingBackendService, private dataLinkService: DataLinkService) {

    this.localSettingsService.appVersion = environment.appVersion;
    this.localSettingsService.releaseTimeStamp = environment.releaseTimeStamp;
    this.localSettingsService.app = "WEB";
    this.localSettingsService.environment = environment;
    this.authenticationService = inject(AuthenticationService);

    this.loggingBackendService.startLogging();
    FirestoreBackend.debounceAll$.next(false);

    interval(5000).pipe(
      // tap(() => console.log(`tot: ${FirestoreBackend.numberObservers} col:${FirestoreBackend.numberCollectionObservers}`))
    ).subscribe();

    if (this.authenticationService.logExtendedSnapshotInformation || this.authenticationService.logExtendednapshotInformationDocumentIds) {
      interval(15000).pipe(
        tap(() => {
          FirestoreBackend.activeFillz.forEach(
            (value, key) => {
              console.log(`fillz: ${key} ${value}`);
            }
          );
        }),
      ).subscribe();
    }

  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    FirestoreBackend.destroyingComponent$.next(null);
    FirestoreBackend.destroyingComponent$.complete();
  }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    FirestoreBackend.destroyingComponent$.next(null);
    FirestoreBackend.destroyingComponent$.complete();
  }


  title = 'service-vanguard';

}
