import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPageBreakComponent } from './formly-page-break.component';


@NgModule({
  declarations: [FormlyPageBreakComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {name: 'formlyPageBreak', component: FormlyPageBreakComponent}
      ]})
    ]
  })
export class FormlyPageBreakModule { }
