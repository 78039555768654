import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { startOfDay } from 'date-fns';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { Job } from '../../../../../common/src/data/dao/job';
import { EXPLICIT_JOB_EVENT, JobEvent } from '../../../../../common/src/data/dao/job-event';
import { SiteVisit } from '../../../../../common/src/data/dao/site-visit';
import { delay, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-cancel-job-confirmation-modal',
  template: `
  <div class="modify-employees-schedule-grid" style="max-width: 500px; grid-template-columns: 1fr">
  <div class="header-label" style="grid-column: 1/2; width: 100%; padding-bottom: 10px;">Cancel Job</div>
    <!-- <h2 matDialogTitle>Are you sure you wish to cancel job?</h2> -->
    <mat-dialog-content>
    <div>
      <ng-container *ngIf="siteVisitToCancelCount() > 0">
        <div class="label-style" style="text-align: left;">Are you sure you wish to cancel this job? {{siteVisitToCancelMessage()}}</div>
      </ng-container>
    </div>
    <div style="grid-template-columns: 1fr; display:grid; margin-top: 30px; margin-bottom: 10px;">
      <div class="label-style" style="text-align: left;">Reason for cancellation (Optional)</div>
      <mat-form-field appearance="outline" style="width: 100%; min-height: 80px;">
      <textarea cdkTextareaAutosize matInput [(ngModel)]="message" placeholder="Reason for cancellation">
      </textarea>
      </mat-form-field>
    </div>
    </mat-dialog-content>
    <mat-dialog-actions class="site-visit-cont spacing" style="display: contents;">
    <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
    <div class="button-position">  
      <button mat-button class="button-gray" (click)="cancel()">Do Not Cancel</button>
    </div>
    <div class="button-position">
        <button mat-button class="button-blue" (click)="confirm()">Cancel Job</button>
    </div>
        </div>
    </mat-dialog-actions>
    </div>
  `,
  styleUrls: ['./cancel-job-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CancelJobConfirmationModalComponent implements OnInit {

  job: Job;
  message: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private jobService: JobService,
  private dialogRef: MatDialogRef<CancelJobConfirmationModalComponent>) {
    this.job = data.job;
  }

  ngOnInit(): void {
  }

  siteVisitToCancelCount() : number {
    return this.siteVisitsToCancel().length;
  }

  siteVisitsToCancel() : SiteVisit[] {
    return this.job.siteVisits.filter(x => x.startDate && x.startDate.getTime() > startOfDay(new Date()).getTime() && x.actualStartDate === undefined);
  }

  formatHours(hours: number): string {
    return `${Math.floor(hours)}H, ${Math.round(((hours-Math.floor(hours))*60))}M`;
  }

  siteVisitsToCancelDuration() : string {
    return this.formatHours(Math.round(this.siteVisitsToCancel().map(x => x.expectedDurationHours)
.reduce((acc, value) => acc + value, 0) * 100) / 100);
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const cancel = new JobEvent({eventType: EXPLICIT_JOB_EVENT.CANCELED, message: this.message});
    this.job.explicitJobEvents.push(cancel);
    // If there are any site visits which will be canceled, the job will need assigned if it is re-opened.
    this.job.needsAssignedStateWhenCanceled = this.job.needsAssigned || this.siteVisitToCancelCount() > 0;
    this.job.needsAssigned=false;
    const siteVisitDocIdsToCancel = this.siteVisitsToCancel().map(x => x.DocId());
    siteVisitDocIdsToCancel.forEach( s => this.job.siteVisits.splice(this.job.siteVisits.findIndex(x => x.DocId() === s), 1));
    this.dialogRef.close({yes:true, siteVisitCanceled: siteVisitDocIdsToCancel });
  }

  siteVisitToCancelMessage() : string {
    return `This will cancel ${this.siteVisitToCancelCount()} site visit(s) totalling ${this.siteVisitsToCancelDuration()}`;
  }

}
