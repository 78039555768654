import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, filter, of, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Invoice } from '../../../../../common/src/data/dao/invoice';
import { InvoiceService } from '../../../../../common/src/data/dao-services/invoice.service';
import { Customer } from '../../../../../common/src/data/dao/customer';
import { Job } from '../../../../../common/src/data/dao/job';
import { where } from 'firebase/firestore';

@Component({
  selector: 'app-customer-balance',
  templateUrl: './customer-balance.component.html',
  styleUrls: ['./customer-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerBalanceComponent implements OnInit, OnDestroy {

  balanceDue: string;
  balancePastDue: string;
  totalBilled: string;

  formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
 });

  invoices: Observable<Invoice[]>;

  @Input() customer: Observable<Customer> | Customer;
  @Input() job: Observable<Job> | Job;

  customer$ : Observable<Customer>;
  job$ : Observable<Job>;

  destroyingComponent$ = new Subject();
  loadedData$ = new BehaviorSubject<boolean>(false);

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor(private invoiceService: InvoiceService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {

    if (this.customer instanceof Customer) {
      this.customer$ = of(this.customer);
    } else {
      this.customer$ = this.customer;
    }
    if (this.job instanceof Job) {
      this.job$ = of(this.job);
    } else {
      this.job$ = this.job;
    }

    if (this.customer || this.job) {
      if (this.customer) {
        this.invoices = this.customer$.pipe(
          filter(c => c !== null),
          switchMap(c => this.invoiceService.queryFirestoreDeep$([where('billingCustomerDocId', '==', c.DocId())]))
        );
      } else {
        this.invoices = this.job$.pipe(
          filter(c => c !== null),
          switchMap(j => this.invoiceService.queryFirestoreDeep$([where('jobDocId', '==', j.DocId())]))
        );
      }
      this.invoices.pipe(
        // emit of outstandingDue, pastDue, or totalAmount changes
        distinctUntilChanged((a,b) => a.reduce((acc, cur)=> acc + cur.outstandingDue, 0) === b.reduce((acc, cur)=> acc + cur.outstandingDue, 0) &&
        a.reduce((acc, cur)=> acc + cur.pastDue, 0) === b.reduce((acc, cur)=> acc + cur.pastDue, 0) &&
        a.reduce((acc, cur)=> acc + cur.totalAmount, 0) === b.reduce((acc, cur)=> acc + cur.totalAmount, 0)),
        takeUntil(this.destroyingComponent$),
      ).subscribe(x=> {
        this.balanceDue = this.formatter.format(Math.round(x.reduce((acc, cur)=> acc + cur.outstandingDue, 0) * 100) / 100);
        this.balancePastDue = this.formatter.format(Math.round(x.reduce((acc, cur)=> acc + cur.pastDue, 0) * 100) / 100);
        this.totalBilled = this.formatter.format(Math.round(x.reduce((acc, cur)=> acc + cur.totalAmount, 0) * 100) / 100);
        this.ref.markForCheck();
      });
    }
  }



}
