import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import {Location} from '@angular/common';
import { Observable, BehaviorSubject, merge,  Subject, zip, throwError, of, combineLatest } from 'rxjs';
import { Customer } from '../../../../common/src/data/dao/customer';
import {  filter, tap, map, share, switchMap, takeUntil, take, catchError, skip, startWith, distinctUntilChanged } from 'rxjs/operators';
import { Job } from '../../../../common/src/data/dao/job';
import { JobService } from '../../../../common/src/data/dao-services/job.service';
import { LineItem } from '../../../../common/src/data/dao/line-item';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { googleGeocodeResult, GoogleGeocodeService } from '../google-geocode.service';
import { PhysicalAddressRoutingService } from '../physical-address-routing.service';
import { GenericServiceProviderSetting } from '../../../../common/src/data/dao/generic-service-provider-setting';
import { AddressCustomerMappingService } from '../../../../common/src/data/dao-services/address-customer-mapping.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ResolveDirtyAddressComponent } from './resolve-dirty-address/resolve-dirty-address.component';
import { SettingsService } from '../settings/settings.service';
import { FormFirestoreService } from '../../../../common/src/data/dao-services/form-firestore.service';
import { LineItemRemovalWithReason } from '../line-item/line-item-display/line-item-display.component';
import { AddressRouting } from '../../../../common/src/data/dao/address-routing';
import { Address } from '../../../../common/src/data/dao/address';
import { JobType } from '../../../../common/src/data/dao/job-type';
import { AddressCustomerMapping } from '../../../../common/src/data/dao/address-customer-mapping';
import { FormFirestore } from '../../../../common/src/data/dao/form-firestore';
import { CustomerService } from '../../../../common/src/data/dao-services/customer.service';
import { JobTypeService } from '../../../../common/src/data/dao-services/job-type.service';
import { LineItemService } from '../../../../common/src/data/dao-services/line-item.service';
import { AddressService } from '../../../../common/src/data/dao-services/address.service';
import { GenericServiceProviderSettingService } from '../../../../common/src/data/dao-services/generic-service-provider-setting.service';
import { FormFirestoreSummaryService } from '../../../../common/src/data/dao-services/form-firestore-summary.service';
import { FormModelFirestore } from '../../../../common/src/data/dao/form-model-firestore';
import { LineItemCreationService } from '../line-item/line-item-creation/line-item-creation.service';
import { limit, where } from 'firebase/firestore';
import { searchResults, searchSource } from '../../../../common/src/data/services/search.service';
import { CustomerSearchService } from '../../../../common/src/data/services/customer-search.service';
import { CompanyLocationService } from '../../../../common/src/data/dao-services/company-location.service';
import { CompanyLocation } from '../../../../common/src/data/dao/company-location';
import { FirestoreBackend } from '../../../../common/src/data/database-backend/retrieve-from-firestore';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';


@Component({
  selector: 'app-add-job-board',
  templateUrl: './add-job-board.component.html',
  styleUrls: ['./add-job-board.component.scss']
})
export class AddJobBoardComponent implements OnInit, OnDestroy, AfterViewInit {

  // Global search and selection.
  addressToShopRouting$ = new BehaviorSubject<AddressRouting | null>(null);
  jobCountAtAddress$ = new BehaviorSubject<string>("0");
  customerGlobalSearchResults$ = new Observable<searchResults[]>();
  activeSearch$ = new Subject<boolean>();
  searchStreetNamePhoneGlobalCustomer$ = new Subject<string>();
  searchServiceAddress$ = new Subject<string>();
  manuallySetCustomerGlobalSearch$ = new Subject<searchResults[]>();
  addressPreviouslyVerifiedAsClean$ = new Subject<Address>();

  serviceAddressUnit$ = new BehaviorSubject<string>("");

  serviceAddressSelection$ = new Subject<Address>();
  serviceAddressWithCustomerSelection$ = new Subject<{address: Address, customer: Customer}>();
  customerSelectionWithBillingAddressDefault$ = new Subject<{address: Address, customer: Customer}>();
  customerLastSelectedFromAddressSearch: boolean = true;

  // Customer details search and selection.
  addCustomerTag$ = new Subject<GenericServiceProviderSetting>();
  removeCustomerTag$ = new Subject<GenericServiceProviderSetting>();

  // Job Details section.
  jobPriorities$: Observable<GenericServiceProviderSetting[]>;
  jobTypes$: Observable<JobType[]>;

  // Line items section.
  lineItems$: BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
  addLineItem$ = new Subject<LineItem>();
  removeLineItem$ = new Subject<LineItemRemovalWithReason>();
  editLineItem$ = new  Subject<{old: LineItem, new: LineItem}[]>();

  disableJobDetailsInput$ = new Observable<boolean>();

  randomElementName = (Math.random().toString(36) + '00000000000000000').slice(2, 14);
  form: UntypedFormGroup;
  activeSearchTerm = "";
  destroyingComponent$ = new Subject();
  activeGeocoderSearchAddress: Address;
  dirtyAddressResolverDialogRef: MatDialogRef<ResolveDirtyAddressComponent>;
  jobPassedIn: boolean = false;
  focusJobDetails: Subject<any> = new Subject();

  activeJob$: BehaviorSubject<Job> = new BehaviorSubject(new Job());
  addressCustomerMapping: AddressCustomerMapping;

  addressAndCustomerMappingFromRoutedJobParam$ : Subject<AddressCustomerMapping> = new Subject<AddressCustomerMapping>();
  companyLocations: CompanyLocation[] = [];
  defaultFormFirestore: FormFirestore;

  constructor(private fb: UntypedFormBuilder, private router: Router,
    private route: ActivatedRoute,private googleGeocoderService: GoogleGeocodeService,
    private location: Location, private dialog: MatDialog,
    private customerSearchService: CustomerSearchService, private customerService: CustomerService,
    private jobService: JobService, private lineItemService: LineItemService, private jobTypeService: JobTypeService,
    private addressService: AddressService,
    private genericServiceProviderSettingService: GenericServiceProviderSettingService,
    private addressRoutingService: PhysicalAddressRoutingService,
    private addressCustomerMappingService : AddressCustomerMappingService,
    private settingsService: SettingsService, private formFirestoreService: FormFirestoreService,
    private formFirestoreSummaryService: FormFirestoreSummaryService, private authenticationService: AuthenticationService,
    private lineItemCreationService: LineItemCreationService, private companyLocationService: CompanyLocationService) {

      this.form = this.fb.group({
        "customerFormGroup" : this.buildCustomerFormGroup(),
        "jobDetailsFormGroup" : this.buildJobDetailsFormGroup(),
        "addressSearchFormGroup" : this.buildSearchAddressForm(),
      });

      this.companyLocationService.loadAll$().pipe(
        tap(x => {
          if (this.settingsService.getValue("autoFillDefaultJobLocation") === true) {
            this.jobDetailsFormGroup.patchValue({locationAssignedWork: x.find(x=> x.default)})
          }
    }),
        take(1)
      ).subscribe();

      this.lineItems$.pipe(
        startWith([]),
        map(l => l.length),
        distinctUntilChanged(),
        skip(1),
        takeUntil(this.destroyingComponent$)
      ).subscribe(l => {
        this.jobDetailsFormGroup.patchValue({jobLineItemCount: l});
        this.jobDetailsFormGroup.controls['jobLineItemCount'].markAsDirty();
      });

     }

     ngAfterViewInit(): void {
    if (this.jobPassedIn) {
      this.focusJobDetails.next(null);
    }

    this.settingsService.settingsLoaded$.pipe(
      switchMap(() => this.formFirestoreSummaryService.load$(this.settingsService.getValue("defaultFormFirestoreSummaryDocId"))),
      filter(x => x != null),
      switchMap(summary => this.formFirestoreService.load$(summary.currentDeployedFirestoreDocId)),
      tap(formFirestore => this.defaultFormFirestore = formFirestore),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  ngOnDestroy(): void {
      this.destroyingComponent$.next(null);
      this.destroyingComponent$.complete();
  }

  buildSearchAddressForm() : UntypedFormGroup {
    return this.fb.group ({
      noAutoFill: [{value: this.randomElementName, disabled: true}],
      serviceAddressSearch: ["", [Validators.required]],
      unit: [""],
      addressPatchedFromCustomerSelection: [],
    });
  }

  buildCustomerFormGroup() : UntypedFormGroup {

    return this.fb.group ({
      noAutoFill: [{value: this.randomElementName, disabled: true}],
      customers: new UntypedFormArray([]),
      customerPopulated: "",
      primaryCustomerDocId: "",
      serviceAddressToDisplay: [{value: "", disabled: true}],
      serviceAddressToValidateOn: ["", [Validators.required]],
      serviceAddressUnit: [{value: "", disabled: true}],
      serviceAddress: "",
      customersPassedFromAddressSelection: {},
      primaryCustomer: "",
      customerAddedFromServiceSelection: false,
      resetActiveCustomerDocIds: "",
      billingAddressAssociatedWithAddedCustomer: "",
    });
  }

  buildJobDetailsFormGroup() : FormGroup {
    const retVal = this.fb.group( {
      noAutoFill: [{value: this.randomElementName, disabled: true}],
      jobType: ["", [Validators.required]],
      jobPriority: ["", [Validators.required]],
      jobDurationHours: [""],
      notes: [""],
      jobTags: [[]],
      jobLineItemCount: [0, [Validators.required, Validators.min(1)]],
      locationAssignedWork: ["", [Validators.required]],
    });

    retVal.get("locationAssignedWork").valueChanges.pipe(
      map(x => x as CompanyLocation),
      tap(x => this.addressRoutingService.officeSchedulingFromAddress = x.dispatchAddress),
      tap(x => {
        const serviceAddress = this.customerDetailsFormGroup.value["serviceAddress"];
        if (serviceAddress !== undefined && serviceAddress !== null && serviceAddress !== "") {
            this.addressToShopRouting$.next(this.addressRoutingService.orginAddressIdToAssociatedCommutes.get(serviceAddress.docId).get(x.dispatchAddressDocId))
        }
      }),
    ).subscribe();

    return retVal;
  }

  get customerDetailsFormGroup() { return this.form.controls["customerFormGroup"] as UntypedFormGroup};
  get jobDetailsFormGroup() { return this.form.controls["jobDetailsFormGroup"] as UntypedFormGroup};
  get addressSearchFormGroup() { return this.form.controls["addressSearchFormGroup"] as UntypedFormGroup};

  patchJobToCustomerDetailsFormGroup(job: Job) {
    const customerAddressMapping = new AddressCustomerMapping({billingCustomers: job.billingCustomers, billingCustomerDocIds: job.billingCustomers.map(x=>x.DocId()),
      siteVisitContactCustomers: job.siteVisitContactCustomers, siteVisitContactCustomerDocIds: job.siteVisitContactCustomers.map(x=>x.DocId()),
      primaryCustomer: job.customer, primaryCustomerDocId: job.customer.DocId(), serviceAddress: job.serviceAddress, serviceAddressDocId: job.serviceAddress.DocId() });
    this.addressAndCustomerMappingFromRoutedJobParam$.next(customerAddressMapping);
    this.addressSearchFormGroup.patchValue({serviceAddressSearch: job.serviceAddress.formattedAddress(), unit: job.serviceAddress.unit});
    this.addressPreviouslyVerifiedAsClean$.next(job.serviceAddress);
    this.addressRoutingService.addressNeedsPopulatedToSchedule$.next(job.serviceAddress);
    this.addressRoutingService.addressPopulatedFromGeocoderResults$.next(job.serviceAddress);
    this.addressRoutingService.generatedCommuteMatrixResultsToShop$.pipe(
      filter(addrDocId => addrDocId === job.serviceAddress.docId),
      tap(() => this.addressToShopRouting$.next(this.addressRoutingService.orginAddressIdToAssociatedCommutes.get(job.serviceAddress.docId).get(this.addressRoutingService.officeSchedulingFromAddress.DocId()))),
      take(1)
    ).subscribe();
    this.jobPassedIn=true;
  }

  testes() {
    console.log(this.activeJob$.value);
  }

  ngOnInit(): void {

    this.serviceAddressUnit$.pipe(
      tap(x => this.customerDetailsFormGroup.patchValue({serviceAddressUnit: x})),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    this.customerDetailsFormGroup.controls["billingAddressAssociatedWithAddedCustomer"].valueChanges.pipe(
      tap(() => console.log(this.customerDetailsFormGroup)),
      // If service address has not yet been populated, default it to the provided billing address.
      filter(() => this.customerDetailsFormGroup.value["serviceAddress"] === ""),
      tap(address => {
        this.addressSearchFormGroup.patchValue({ addressPatchedFromCustomerSelection: address,  serviceAddressSearch :
          this.formatSearchTextFromSelectedValue({address, customer: this.customerService.get(this.customerDetailsFormGroup.value["primaryCustomerDocId"])} ), unit: address.unit});
        this.serviceAddressSelection$.next(address);
        this.customerLastSelectedFromAddressSearch = false;
        this.customerSelectionWithBillingAddressDefault$.next(
          {address: address, customer: this.customerDetailsFormGroup.value["primaryCustomer"]});
        this.manuallySetCustomerGlobalSearch$.next([]);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    this.serviceAddressWithCustomerSelection$.pipe(
      tap(() => this.activeSearch$.next(false)),
      map(x => x.customer),
      switchMap(x => this.customerService.load$(x.DocId()).pipe(
        filter(x => x.address !== undefined),
        take(1),
      )),
      tap( () => {
        const previousCustomerSelectionInitiatedFromAddress = this.customerDetailsFormGroup.get("customerAddedFromServiceSelection").value;
        if (previousCustomerSelectionInitiatedFromAddress) {
          (this.customerDetailsFormGroup.controls["customers"] as UntypedFormArray).clear();
          this.customerDetailsFormGroup.patchValue({resetActiveCustomerDocIds: true});
        }
        this.customerLastSelectedFromAddressSearch = true;
      }),
      tap(x => this.customerDetailsFormGroup.patchValue({customerAddedFromServiceSelection: x})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    //googleGeocodeResultSuccess
    const addressVerifiedAsCleanFromGoogle$ =  this.googleGeocoderService.geocodeSearchResultSummary$.pipe(
      filter(x => x.orginAddress === this.activeGeocoderSearchAddress),
      filter(x => x.googleGeocodeResult === googleGeocodeResult.Success),
      map(x => x.orginAddress),
    );

    const addressVerifiedAsClean$ = merge(addressVerifiedAsCleanFromGoogle$, this.addressPreviouslyVerifiedAsClean$).pipe(
      map(address => {
        console.log(address);
        const job = this.activeJob$.value;
        job.serviceAddress = address;
        job.serviceAddressDocId = address.docId;
        this.customerDetailsFormGroup.patchValue({serviceAddressToDisplay: address.formattedAddress(), serviceAddressToValidateOn: address, serviceAddress: address, serviceAddressUnit: address.unit});
        return job;
      })
    );

    //googleGeocodeResultFailure
    this.googleGeocoderService.geocodeSearchResultSummary$.pipe(
      filter(x => x.orginAddress === this.activeGeocoderSearchAddress),
      filter(x => x.googleGeocodeResult !== googleGeocodeResult.Success),
      tap(results => this.badAddress(results.orginAddress.formattedAddress(), results.googleGeocodeResult)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    this.jobTypes$ = this.jobTypeService.loadAll$().pipe(
      map(x=> x.filter(q=>q.active)),
      takeUntil(this.destroyingComponent$)
    );

    this.jobPriorities$ = this.genericServiceProviderSettingService.allJobPriorities;

    // CUSTOMER GLOBAL SEARCH (Name, address, e-mail ) BEGIN
    const customerGlobalSearch = this.searchStreetNamePhoneGlobalCustomer$.pipe(
        tap(x => {
          this.activeSearchTerm = x;
          this.customerSearchService.addSearch({search: x, componentSource: "customerGlobalSearch", searchSources: [searchSource.GooglePlaceAutoComplete, searchSource.InternalServiceAddress]});
        }));

    const resetCustomerGlobalSearch = customerGlobalSearch.pipe(
        filter( x => x.length <= 2),
        tap(() => this.activeSearch$.next(false)),
        tap(() => this.jobCountAtAddress$.next("0")),
        tap(() => this.customerDetailsFormGroup.patchValue({serviceAddress: "", serviceAddressToValidateOn: "", serviceAddressToDisplay: "", serviceAddressUnit: ""})),
        map(() => ([])));

    const fromCustomerSearchService$ =  this.customerSearchService.searchResults$.pipe(
        filter(x => x.searchInput.componentSource === "customerGlobalSearch" && x.searchInput.search === this.activeSearchTerm),
        // finesse results.  We want to add one (internal) address result w/o customer for each sevice address, and remove associated google result if formatted address matches.
        map( results => {
          const retVal : searchResults[] = [];
          const internalSearchResults = results.results.filter(x => x.resultSource === searchSource.InternalServiceAddress);
          const deduplicateInternalSearchResults = [];
          const internalAddressOnlyResults = [];
          internalSearchResults.forEach(res => {
            if (internalAddressOnlyResults.find(x => x.address.DocId() === res.address.DocId()) === undefined) {
              internalAddressOnlyResults.push({address: res.address, resultSource: searchSource.InternalServiceAddress, customer: null});
            }
            if (deduplicateInternalSearchResults.find(x => x.address.DocId() === res.address.DocId() && x.customer.DocId() === res.customer.DocId()) === undefined) {
              deduplicateInternalSearchResults.push(res);
            }
          });
          deduplicateInternalSearchResults.forEach(i => retVal.push(i));
          if (internalSearchResults.length > 0) {
            retVal.push({address: null, resultSource: null, customer: null});
          }
          internalAddressOnlyResults.forEach(i => retVal.push(i));
          results.results.forEach(res => {
            if (res.address !== null && retVal.findIndex(q => q.address !== null && q.address.formattedAddress() === res.address.formattedAddress()) === -1) {
              retVal.push(res);
            }
          });
          return retVal;
        }),
        );


    this.customerGlobalSearchResults$ = merge(fromCustomerSearchService$, resetCustomerGlobalSearch, this.manuallySetCustomerGlobalSearch$).pipe(
      takeUntil(this.destroyingComponent$)
    );

    this.customerGlobalSearchResults$.pipe(
      tap(() => this.activeSearch$.next(false)),
      takeUntil(this.destroyingComponent$)).subscribe();

      // CUSTOMER GLOBAL SEARCH  END

    // Region Customer Details Search


    this.serviceAddressSelection$.pipe(
      tap(() => this.activeSearch$.next(false)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    const addressPortionOfCustomerResults$ = merge(this.serviceAddressSelection$,
      this.serviceAddressWithCustomerSelection$.pipe(
        map(x => x.address))
      ).pipe(
      filter(x => x !== null),
      tap(address => this.activeGeocoderSearchAddress=address),
      map(address => {
        address.generatedCommuteMatrix = false;
        return address;
      }),
      share()
    );


      addressPortionOfCustomerResults$.pipe(
        map( activeAddress => {
            if (activeAddress.DocId() === undefined) {
              activeAddress.lineOne = activeAddress.formattedAddress();
            }
            return activeAddress;
          }),
        tap(activeAddress => {
          if (!this.addressRoutingService.addressesOriginationDocIdsRetrievedOrRetrieving.has(activeAddress.docId)) {
            console.log("GEOCODIN");
            this.googleGeocoderService.retrieveGeocoderResult(activeAddress);
          } else {
            this.addressPreviouslyVerifiedAsClean$.next(activeAddress);
            if (this.addressRoutingService.orginAddressIdToAssociatedCommutes.has(activeAddress.docId)) {
              this.addressToShopRouting$.next(this.addressRoutingService.orginAddressIdToAssociatedCommutes.get(activeAddress.docId).get(this.addressRoutingService.officeSchedulingFromAddress.DocId()));
            }
            console.log("GEN COMMUTE MANUALLY");
            // If we do not need to geocode address, we must add commute matrix generation request ( which is otherwise called from result of geocoder ))
            this.addressRoutingService.addressNeedsPopulatedToSchedule$.next(activeAddress);
          }
          this.addressRoutingService.generatedCommuteMatrixResultsToShop$.pipe(
            filter(addrDocId => addrDocId === activeAddress.docId),
            tap(() => activeAddress.generatedCommuteMatrix = true),
            tap(() => this.addressToShopRouting$.next(this.addressRoutingService.orginAddressIdToAssociatedCommutes.get(activeAddress.docId).get(this.addressRoutingService.officeSchedulingFromAddress.DocId()))),
            take(1)
          ).subscribe();
        }),
        takeUntil(this.destroyingComponent$)
        ).subscribe();

        // populate count of jobs previously performed at address.
        addressPortionOfCustomerResults$.pipe(
          filter(a => a.DocId() !== undefined),
          switchMap(a => this.jobService.queryFirestoreShallow$([where('serviceAddressDocId', '==', a.DocId()),
            limit(25)])),
          map(q => q.length >= 25 ? ">25" : `${q.length}`),
          takeUntil(this.destroyingComponent$)
        ).subscribe(this.jobCountAtAddress$);

// Patch in the customers associated with given address (those last used for job at said address)
const addresscustMapping = merge(this.serviceAddressWithCustomerSelection$,this.customerSelectionWithBillingAddressDefault$).pipe(
switchMap(addressAndCustomer => this.addressCustomerMappingService.queryFirestoreDeep$([where("serviceAddressDocId", "==", addressAndCustomer.address.DocId()),
  where("primaryCustomerDocId", "==", addressAndCustomer.customer.DocId())]).pipe(
  map(jobCustomerMappingArray => jobCustomerMappingArray.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf())),
  map(x => x.length > 0 ? x[0] : new AddressCustomerMapping({billingCustomers : [addressAndCustomer.customer],
    billingCustomerDocIds : [addressAndCustomer.customer.DocId()],
    siteVisitContactCustomers: [addressAndCustomer.customer],
    siteVisitContactCustomerDocIds: [addressAndCustomer.customer.DocId()],
  primaryCustomer: addressAndCustomer.customer,
  primaryCustomerDocId: addressAndCustomer.customer.DocId(),
  serviceAddress: addressAndCustomer.address,
  serviceAddressDocId: addressAndCustomer.address.DocId(),
}) ),
  )),
  );

const addresssCustomerMappingExists = addresscustMapping.pipe(
  take(1)
);


      const addressCustomerMappingFromAddressAndCustomer =
    merge(this.addressAndCustomerMappingFromRoutedJobParam$,addresssCustomerMappingExists).pipe(
      filter(x => x !== undefined),
      map(jobCustomerMapping => {
        const customerDocIdsAdded = new Set<string>();
        const distinctCustomers = [];
        customerDocIdsAdded.add(jobCustomerMapping.primaryCustomer.DocId());
        distinctCustomers.push(jobCustomerMapping.primaryCustomer);
        const customersToGoThrough = jobCustomerMapping.billingCustomers.concat(jobCustomerMapping.siteVisitContactCustomers);
        customersToGoThrough.forEach(cust => {
          if (!customerDocIdsAdded.has(cust.DocId())) {
            customerDocIdsAdded.add(cust.DocId());
            distinctCustomers.push(cust);
          }
        })
        return {jobCustomerMapping, distinctCustomers};
      }),
      switchMap(val => this.customerService.loadMultiple$(val.distinctCustomers.map(d => d.DocId())).pipe(
        map(loadedCustomers => {
          const retVal =  {...val};
          retVal.distinctCustomers = loadedCustomers;
          return retVal;
          })
      )),
      // only distinct if different customer, or if customer has different address
      distinctUntilChanged((x, y) => {
        if (x.distinctCustomers.map(c => c.DocId()).sort().join(',') !== y.distinctCustomers.map(c => c.DocId()).sort().join(',') || x.jobCustomerMapping.serviceAddressDocId !== y.jobCustomerMapping.serviceAddressDocId) {
          return false;
        } else {
          return true;
        }
      }),
      tap(val => {
        this.customerDetailsFormGroup.patchValue({customers: val.distinctCustomers, customersPassedFromAddressSelection: val.distinctCustomers.map(c => AddressCustomerMapping.buildCustomerRoleEntry(val.jobCustomerMapping, c))});
        this.addressCustomerMapping = val.jobCustomerMapping;
      }),
      takeUntil(this.destroyingComponent$)
      ).subscribe();

      // When SP selects address w/o customer, if customer was last added from address and customer search,
      // we assume they desire to clear out this customer selection.
      this.serviceAddressSelection$.pipe(
        filter(() => this.customerLastSelectedFromAddressSearch),
        tap(() =>
        {
          this.customerDetailsFormGroup.patchValue({customers: [], customersPassedFromAddressSelection: []});
          this.addressCustomerMapping = undefined;
        }),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
        takeUntil(this.destroyingComponent$)
      ).subscribe();



    // CUSTOMER DETAILS BEGIN

    const addCustomerTagExecuted$ = this.addCustomerTag$.pipe(
        map(tag => {
          this.activeJob$.value.customer.customerTags.push(tag);
          return this.activeJob$.value;
        }));

    const removeCustomerTagExecuted$ = this.removeCustomerTag$.pipe(
        map(tag => {
          this.activeJob$.value.customer.customerTags = this.activeJob$.value.customer.customerTags.filter(x => x.docId !== tag.docId);
          return this.activeJob$.value;
        }));

    // CUSTOMER DETAILS END

    // JOB DETAILS BEGIN
    const disableJobInput$ = this.activeJob$.pipe(
      filter(x => x === null || x.customer === null),
      map(() => true));

    const enableJobInput$ = this.activeJob$.pipe(
      filter(x => x !== null && x.customerDocId !== null),
      map(()  => false));

    this.disableJobDetailsInput$ = merge(disableJobInput$, enableJobInput$);

    const updateJobTypeExecuted$ = this.jobDetailsFormGroup.controls["jobType"].valueChanges.pipe(
          tap(x => {
            this.jobDetailsFormGroup.patchValue({jobPriority: x.jobPriority})
          }),
          map(type => {
            const lineItemsToAdd$ : Observable<LineItem>[] = [of(null)];
            const activeJobWithUpdatedJobType = this.activeJob$.value;
            // we only replace the jobs line items with job type selection options if SP has not manually added or edited any line items.
            if (!activeJobWithUpdatedJobType.lineItems.some(x => !x.addedFromJobType)) {
              type.lineItems.map(x => {
                lineItemsToAdd$.push(this.lineItemCreationService.newLineItemFromPrototype(x).pipe(
                  map(retVal => {
                    retVal.addedFromJobType = true;
                    return retVal;
                  })
                ));
              });
            };
              return {type,lineItemsToAdd$};
          }),
            switchMap(x => combineLatest(x.lineItemsToAdd$).pipe(
              map(lineItems => {
                return {type: x.type, lineItemsToAdd: lineItems.filter(x => x !== null)}
              })
            )),
            map(x => {
              const type = x.type;
              const activeJobWithUpdatedJobType = this.activeJob$.value;
              activeJobWithUpdatedJobType.jobType = type;
              activeJobWithUpdatedJobType.jobPriority = type.jobPriority;
              if (x.lineItemsToAdd !== undefined && x.lineItemsToAdd.length > 0) {
                activeJobWithUpdatedJobType.lineItems = x.lineItemsToAdd;
              }
              return activeJobWithUpdatedJobType;
            }));

      // JOB DETAILS END

    const jobDetailUpdates$ = updateJobTypeExecuted$.pipe(tap(()=> console.log("job detail updates")));
    const customerDetailUpdates$ = merge(addCustomerTagExecuted$, removeCustomerTagExecuted$).pipe(tap(()=> console.log("customer detail updates")));;
    const addJobBoardUpdates$ = addressVerifiedAsClean$.pipe(tap(()=> console.log("add job board updates")));;

    // All updates to jobs.
    merge(jobDetailUpdates$, customerDetailUpdates$, addJobBoardUpdates$).pipe(
      takeUntil(this.destroyingComponent$)
        ).subscribe(this.activeJob$);

    // LINE ITEMS BEGIN
    this.activeJob$.pipe(
        filter(x => x !== null),
        map(x => x.lineItems),
        tap(lineItems => {
          let durationHours = 0;
          if (lineItems !== undefined && lineItems.length > 0) {
            durationHours = lineItems.map(t => t.expectedDurationHours).reduce((acc, value) => acc + value, 0);
          }
          this.jobDetailsFormGroup.patchValue({jobDurationHours : durationHours});
        }),
        tap(x => this.lineItems$.next(x)),
        takeUntil(this.destroyingComponent$)
      ).subscribe();

      const addLineItems = this.addLineItem$.pipe(
        map( x => {
          x.addedFromJobType = false;
          this.activeJob$.value.lineItems.push(x);
          return this.activeJob$.value;
        }));


      const removeLineItems = this.removeLineItem$.pipe(
        map(x => {
          const indexOfLineItem = this.activeJob$.value.lineItems.findIndex(q => q.DocId() === x.lineItem.DocId());
          this.activeJob$.value.lineItems.splice(indexOfLineItem,1);
          return this.activeJob$.value;
        }));

      const editLineItems = this.editLineItem$.pipe(
        map(edits => {
          edits.forEach(x => {
            const indexOfLineItem = this.activeJob$.value.lineItems.findIndex(z => z.DocId() === x.old.DocId());
            x.new.addedFromJobType = false;
            this.activeJob$.value.lineItems.splice(indexOfLineItem,1,x.new);
            return this.activeJob$.value;
          });
        }));

      merge(addLineItems,removeLineItems,editLineItems).pipe(
        tap(() => this.activeJob$.next(this.activeJob$.value)),
        takeUntil(this.destroyingComponent$)).subscribe();

    // LINE ITEMS END


    if (this.route.snapshot.paramMap.get("jobDocId")) {
      this.activeJob$.next(this.jobService.getFromLocalCache(this.route.snapshot.paramMap.get("jobDocId")));
      this.patchJobToCustomerDetailsFormGroup(this.activeJob$.value);
    }
  }


  scheduleNow() {
    if (this.form.valid) {
      this.saveJob().pipe(
        take(1),
      ).subscribe(x => this.router.navigate(['/app-scheduler-view', {onDeckJob: x.jobDocId}]));
    } else {
      this.form.markAllAsTouched();
      console.log(this.form);
    }
  }

  scheduleLater() {
    if (this.form.valid) {
      this.saveJob().pipe(
        take(1),
      ).subscribe(x => this.router.navigate(['/app-scheduler-view']));
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  updateCustomerRolesOnJob()  {
    // Populate customer tags to each customer.
    const customersArray = (this.customerDetailsFormGroup.get("customers") as UntypedFormArray).controls;
    customersArray.forEach(customerFormGroup => {
      const customer = (customerFormGroup.get("customer").value as Customer);
      customer.customerTags = customerFormGroup.get("customerTags").value;
      customer.customerName = customerFormGroup.get("customerName").value;
      customer.primaryPhoneNumber = customerFormGroup.get("customerPrimaryPhoneNumber").value;
      customer.customerEmail = customerFormGroup.get("customerEmail").value;
      if (customerFormGroup.get("customerRole").value === "All") {
        if (!this.activeJob$.value.billingCustomers.map(b => b.DocId()).includes(customer.DocId())) {
          this.activeJob$.value.billingCustomers.push(customer);
        }
        if (!this.activeJob$.value.siteVisitContactCustomers.map(b => b.DocId()).includes(customer.DocId())) {
          this.activeJob$.value.siteVisitContactCustomers.push(customer);
        }
      } else {
        if (customerFormGroup.get("customerRole").value === "Billing Contact" && !this.activeJob$.value.billingCustomers.map(b => b.DocId()).includes(customer.DocId())) {
            this.activeJob$.value.billingCustomers.push(customer);
        }
        if (customerFormGroup.get("customerRole").value === "Site Visit Contact" && !this.activeJob$.value.siteVisitContactCustomers.map(b => b.DocId()).includes(customer.DocId())) {
            this.activeJob$.value.siteVisitContactCustomers.push(customer);
        }
      }
      if (customer.DocId() === this.customerDetailsFormGroup.get("primaryCustomerDocId").value) {
        this.customerDetailsFormGroup.patchValue({primaryCustomer: customer});
      }
    });
  }

  saveJob(): Observable<Job> {

    const job = this.activeJob$.value;
    // If there are line items with null prototype line items doc ids, resolve.
    const nonPrototypeLineItems: LineItem[] = job.lineItems.filter(x => x.lineItemPrototypeDocId !== null);
    job.lineItems.filter(x => x.lineItemPrototypeDocId === null).forEach
    (lineItem => nonPrototypeLineItems.push(this.lineItemService.createConcreateLineItemFromPrototype(lineItem)));
    job.lineItems = nonPrototypeLineItems;

    job.notes = this.jobDetailsFormGroup.value["notes"];
    job.jobType = this.jobDetailsFormGroup.value["jobType"];
    job.jobPriority = this.jobDetailsFormGroup.value["jobPriority"];
    job.jobTags = this.jobDetailsFormGroup.value["jobTags"];
    job.locationAssignedWork = this.jobDetailsFormGroup.value["locationAssignedWork"];

    job.formModelFirestore = new FormModelFirestore(
      {formFirestore: this.defaultFormFirestore,
        formFirestoreDocId: this.defaultFormFirestore.DocId()});

    job.formModelFirestore.formFirestoreSummaryDocIdToInstantiated = {};

    let b: string = null;
    return FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
      tap(batch => b = batch),
      switchMap(() => this.addressService.load$(job.serviceAddress.DocId())),
      tap(x => {
        x.unit = job.serviceAddress.unit;
        job.serviceAddress = x;
        console.log(x.generatedCommuteMatrix);
      }),
      take(1),
      tap(() => {
        this.updateCustomerRolesOnJob();
        job.customer = this.customerDetailsFormGroup.get("primaryCustomer").value;
      }),
      tap(() => {
        const updatedServiceAddress = job.serviceAddress;
        job.billingCustomers.filter(b => b.address.DocId() === updatedServiceAddress.DocId()).forEach(b => b.address = updatedServiceAddress);
        job.siteVisitContactCustomers.filter(b => b.address.DocId() === updatedServiceAddress.DocId()).forEach(b => b.address = updatedServiceAddress);
        if (job.customer.address.DocId() === updatedServiceAddress.DocId()) {
          job.customer.address = updatedServiceAddress;
        }
      }),
      map(() => {
        if (this.addressCustomerMapping === undefined) {
          this.addressCustomerMapping = new AddressCustomerMapping();
        }
        this.addressCustomerMapping.billingCustomers = job.billingCustomers;
        this.addressCustomerMapping.siteVisitContactCustomers = job.siteVisitContactCustomers;
        this.addressCustomerMapping.primaryCustomer = job.customer;
        this.addressCustomerMapping.serviceAddress = job.serviceAddress;
        return this.addressCustomerMapping;
      }),
      switchMap(x => this.addressCustomerMappingService.update$(x,b).pipe(take(1))),
      switchMap(x => this.jobService.update$(job,b).pipe(take(1))),
      switchMap(j => this.jobService.commitExternallyManagedFirestoreBatch(b).pipe(
        map(() => j)
      ))) as Observable<Job>;
  }

  cancel() {
    this.location.back();
  }


  badAddress(inputAddress: string, results: googleGeocodeResult ) {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         :
      {
        address: inputAddress,
        googleGeocodeResult: results
      }
      });

    this.dirtyAddressResolverDialogRef = this.dialog.open(ResolveDirtyAddressComponent, editorConfig);

    // Event dialog closure:
    this.dirtyAddressResolverDialogRef.afterClosed().pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.resolveDirtyAddressEventDialogClosure(x));
  }

  resolveDirtyAddressEventDialogClosure(address: Address) {
    if (address !== undefined) {
      this.addressSearchFormGroup.patchValue({ addressPatchedFromCustomerSelection: address, serviceAddressSearch :
        this.formatSearchTextFromSelectedValue({address, customer: this.customerDetailsFormGroup.value["customerAddedFromServiceSelection"] } ), unit: address.unit});
      this.serviceAddressSelection$.next(address);
      this.customerSelectionWithBillingAddressDefault$.next(
        {address: address, customer: this.customerDetailsFormGroup.value["customerAddedFromServiceSelection"]});
      this.manuallySetCustomerGlobalSearch$.next([]);
      this.activeJob$.value.customer.address=address;
    } else {
      this.addressSearchFormGroup.patchValue({ serviceAddressSearch : "", unit: ""});
    }
  }

  formatSearchTextFromSelectedValue(selectedVal : {address: Address, customer?: Customer}) : string {
    if (selectedVal.customer !== null) {
      return `${selectedVal.customer.customerName} - ${selectedVal.address.formattedAddress()}`;
    } else {
      return selectedVal.address.formattedAddress();
    }
  }

  cleanPhoneNumber(input): string{
    let newVal = input.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return newVal;
  }

}
