import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { LineItemRemovalReason, LineItemRemovalWithReason } from '../../../../line-item/line-item-display/line-item-display.component';
import { Discount } from '../../../../../../../common/src/data/dao/discount';
import { Estimate } from '../../../../../../../common/src/data/dao/estimate';
import { LineItem } from '../../../../../../../common/src/data/dao/line-item';
import { Job } from '../../../../../../../common/src/data/dao/job';
import { Invoice } from '../../../../../../../common/src/data/dao/invoice';
import { concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { JobService } from '../../../../../../../common/src/data/dao-services/job.service';
import { EstimateService } from '../../../../../../../common/src/data/dao-services/estimate.service';
import { InvoiceService } from '../../../../../../../common/src/data/dao-services/invoice.service';
import { FormlyUtilityService } from '../formly-utility.service';
import { RetrieveFirestoreProperties } from '../../../../../../../common/src/data/database-backend/retrieve-firestore-properties';
import { LineItemService } from '../../../../../../../common/src/data/dao-services/line-item.service';
import { InvoicePayment } from '../../../../../../../common/src/data/dao/invoice-payment';
import { JournalEntryService } from '../../../../../../../common/src/data/dao-services/journal-entry.service';
import { AuthenticationService } from '../../../../../../../common/src/util/authentication.service';
import { EmployeeService } from '../../../../../../../common/src/data/dao-services/employee.service';

@Injectable({
  providedIn: 'root'
})
export class FormlyLineItemService {

  addLineItemsToJob$: Subject<LineItem[]> = new Subject<LineItem[]>();
  addLineItemsToInvoice: Subject<LineItem[]> = new Subject<LineItem[]>();
  addLineItemsToEstimate$: Subject<LineItem[]> = new Subject<LineItem[]>();

  addDiscountsToInvoice$: Subject<Discount[]> = new Subject<Discount[]>();
  addDiscountsToEstimate$: Subject<Discount[]> = new Subject<Discount[]>();
  addDiscountsToJob$: Subject<Discount[]> = new Subject<Discount[]>();

  removeLineItemsFromInvoice$: Subject<LineItemRemovalWithReason[]> = new Subject<LineItemRemovalWithReason[]>();
  removeLineItemsFromEstimate$: Subject<LineItemRemovalWithReason[]> = new Subject<LineItemRemovalWithReason[]>();
  removeLineItemsFromJob$: Subject<LineItemRemovalWithReason[]> = new Subject<LineItemRemovalWithReason[]>();

  removeDiscountsFromInvoice$: Subject<Discount[]> = new Subject<Discount[]>();
  removeDiscountsFromEstimate$: Subject<Discount[]> = new Subject<Discount[]>();
  removeDiscountsFromJob$: Subject<Discount[]> = new Subject<Discount[]>();

  voidInvoicePaymentsFromInvoice$ : Subject<InvoicePayment[]> = new Subject<InvoicePayment[]>();

  editLineItemsToJob$ : Subject<{old: LineItem, new: LineItem}[]> = new Subject<{old: LineItem, new: LineItem}[]>();
  editLineItemsToInvoice$: Subject<{old: LineItem, new: LineItem}> = new Subject<{old: LineItem, new: LineItem}>();
  editLineItemsToEstimate$: Subject<{old: LineItem, new: LineItem}[]> = new Subject<{old: LineItem, new: LineItem}[]>();

  editDiscountToInvoice$: Subject<{old: Discount, new: Discount}> = new Subject<{old: Discount, new: Discount}>();
  editDiscountToEstimate$: Subject<{old: Discount, new: Discount}> = new Subject<{old: Discount, new: Discount}>();
  editDiscountToJob$: Subject<{old: Discount, new: Discount}> = new Subject<{old: Discount, new: Discount}>();

  restoreAbandonedLineItem$: Subject<{lineItem: LineItem, siteVisitDocId: string}> = new Subject<{lineItem: LineItem, siteVisitDocId: string}>();
  restoreRescheduledLineItem$: Subject<{lineItem: LineItem, siteVisitDocId: string}> = new Subject<{lineItem: LineItem, siteVisitDocId: string}>();
  editDiscountReferencesThroughoutForms$: Subject<{old: Discount, new: Discount}> = new Subject<{old: Discount, new: Discount}>();

  private _explicitInvoiceDocId: string | null = null;
  private _explicitEstimateDocId: string | null = null;
  estimateDocIdSet$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  invoiceDocIdSet$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  get explicitInvoiceDocId (): string | null {
    return this._explicitInvoiceDocId;
  }

  set explicitInvoiceDocId (docId: string) {
    if (docId !== null) {
      this._explicitInvoiceDocId = docId;
      this.invoiceDocIdSet$.next(true);
    }
  }

  get explicitEstimateDocId (): string | null {
    return this._explicitEstimateDocId;
  }

  set explicitEstimateDocId (docId: string) {
    if (docId !== null) {
      this._explicitEstimateDocId = docId;
      this.estimateDocIdSet$.next(true);
    }
  }

  estimate: Estimate;
  job: Job;
  invoice: Invoice;

  initiatingJobScheduleFromEstimate: boolean = false;

  resetValues() : void {
    this.estimate = undefined;
    this.invoice = undefined;
    this.job = undefined;
    this.estimateDocIdSet$.next(false);
    this.invoiceDocIdSet$.next(false);
    this._explicitEstimateDocId = null;
    this._explicitInvoiceDocId = null;
  }

  constructor(private jobService: JobService, private estimateService: EstimateService, private invoiceService: InvoiceService,
              private formlyUtilityService: FormlyUtilityService, private lineItemService: LineItemService,
              private journalEntryService: JournalEntryService, private authenticationService: AuthenticationService,
              private employeeService: EmployeeService ) {

    //add line items
    this.initilizeAddLineItemsToJob();
    this.initlizeAddLineItemsToEstimate();
    this.initilizeAddLineItemsToInvoice();
    //add discounts
    this.initilizeAddDiscountToJob();
    this.initilizeAddDiscountToEstimate();
    this.initilizeAddDiscountToInvoice();
    // remove line items
    this.initializeRemoveLineItemsFromInvoice();
    this.initializeRemoveLineItemsFromEstimate();
    this.initilizeRemoveLineItemsFromJob();
    // remove discounts
    this.initializeRemoveDiscountsFromJob();
    this.initializeRemoveDiscountsFromInvoice();
    this.initializeRemoveDiscountsFromEstimate();
    // void invoice payments
    this.initilizeVoidInvoicePayments();
    //edit discounts
    this.editDiscountsToJob();
    this.editDiscountsToEstimate();
    this.editDiscountsToInvoice();
    //edit line items
    this.editLineItemsToJob();
    this.editLineItemsToEstimate();
    this.editLineItemsToInvoice();

  }

private editLineItemsToJob() {
  this.editLineItemsToJob$.pipe(
    tap(lineItems =>
      lineItems.forEach(x => {
      //If item freshly needs rescheduled, then we update Job to relfect that it has scheduling tasks.
      if (!x.old.lineItemNeedsOrHasBeenRescheduled && x.new.lineItemNeedsOrHasBeenRescheduled) {
        this.job.needsAssigned = true;
      }
      const indexOfLineItem = this.job.lineItems.findIndex(z => z.DocId() === x.old.DocId());
      if (indexOfLineItem > -1) {
        this.job.lineItems.splice(indexOfLineItem,1,x.new);
      } else {
        const indexOfCompletedLineItem = this.job.abandonedLineItems.findIndex(z => z.DocId() === x.old.DocId());
        if (indexOfCompletedLineItem > -1) {
          this.job.abandonedLineItems.splice(indexOfCompletedLineItem,1,x.new);
        }
      }
      if (this.invoice !== undefined) {
        this.editLineItemsToInvoice$.next(x);
      }
    })
    ),
    concatMap(lineItems => this.jobService.update$(this.job).pipe(map(() => lineItems))),
    tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l.new))),
  ).subscribe();
}

private editLineItemsToEstimate() {
  this.editLineItemsToEstimate$.pipe(
    tap(lineItems => {
      lineItems.forEach(lineItem => {
      const index = this.estimate.lineItems.findIndex(l => l.DocId() === lineItem.old.DocId());
      if (index > -1) {
        this.estimate.lineItems[index] = lineItem.new;
      }
    })
    }),
    concatMap(l => this.estimateService.update$(this.estimate).pipe(map(() => l))),
    tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l.new))),
  ).subscribe();
}

private editLineItemsToInvoice() {
  this.editLineItemsToInvoice$.pipe(
    tap(lineItem => {
      const index = this.invoice.lineItems.findIndex(l => l.DocId() === lineItem.old.DocId());
      if (index > -1) {
        this.invoice.lineItems[index] = lineItem.new;
      }
    }
    ),
    concatMap(lineItem => this.invoiceService.update$(this.invoice).pipe(map(() => lineItem))),
    tap(lineItem => this.formlyUtilityService.completedLineItemOperation$.next(lineItem.new)),
  ).subscribe();
}

  private editDiscountsToJob() {
    this.editDiscountToJob$.pipe(
      tap(discount => {
          const index = this.job.discounts.findIndex(d => d.DocId() === discount.old.DocId());
          if (index > -1) {
            this.job.discounts[index] = discount.new;
          }
      }),
      concatMap(() => this.jobService.update$(this.job)),
    ).subscribe();
  }

  private editDiscountsToEstimate() {
    this.editDiscountToEstimate$.pipe(
      tap(discount => {
          const index = this.estimate.discounts.findIndex(d => d.DocId() === discount.old.DocId());
          if (index > -1) {
            this.estimate.discounts[index] = discount.new;
          }
      }),
      concatMap(() => this.estimateService.update$(this.estimate)),
    ).subscribe();
  }

  private editDiscountsToInvoice() {
    this.editDiscountToInvoice$.pipe(
      tap(discount => {
          const index = this.invoice.discounts.findIndex(d => d.DocId() === discount.old.DocId());
          if (index > -1) {
            this.invoice.discounts[index] = discount.new;
          }
      }),
      concatMap(() => this.invoiceService.update$(this.invoice)),
    ).subscribe();
  }

  private initializeRemoveDiscountsFromJob() {
    this.removeDiscountsFromJob$.pipe(
      tap(discounts => {
        discounts.forEach(discount => {
          const index = this.job.discounts.findIndex(d => d.DocId() === discount.DocId());
          if (index > -1) {
            this.job.discounts.splice(index, 1);
          }
        });
      }),
      concatMap(() => this.jobService.update$(this.job)),
    ).subscribe();
  }

  private initilizeVoidInvoicePayments() {
    this.voidInvoicePaymentsFromInvoice$.pipe(
      tap(invoicePayments => invoicePayments.forEach(payment => {
        this.invoice.amountPaid -= payment.amount;
        payment.void = true;
      })),
      // retrieve associated journal entry.
      concatMap(payments => this.journalEntryService.queryFirestoreForInValues("invoicePaymentDocIds", [payments[0].DocId()], true ).pipe(
        take(1)
      )),
      concatMap(x => this.journalEntryService.load$(x[0].DocId()).pipe(take(1))),
      map(journalEntry => {
        journalEntry.void = true;
        journalEntry.employeeVoiding = this.employeeService.get(this.authenticationService.activelyLoggedInEmployeeDocId);
        journalEntry.voidedOn = new Date();
        return  journalEntry;
      }),
      switchMap(journalEntry => this.invoiceService.retrieveFirestoreBatchString().pipe(
        map(batch => ({journalEntry: journalEntry, batch: batch}))
      )),
      concatMap(j => this.journalEntryService.update$(j.journalEntry,j.batch).pipe(
        map(() => j.batch),
        take(1),
      )),
      concatMap(batch => this.invoiceService.update$(this.invoice, batch).pipe(
        map(() => batch),
        take(1)
      )),
      switchMap(b => this.journalEntryService.commitExternallyManagedFirestoreBatch(b)),
    ).subscribe();
  }

  private initializeRemoveDiscountsFromInvoice() {
    this.removeDiscountsFromInvoice$.pipe(
      tap(discounts => {
        discounts.forEach(discount => {
          const index = this.invoice.discounts.findIndex(d => d.DocId() === discount.DocId());
          if (index > -1) {
            this.invoice.discounts.splice(index, 1);
          }
        });
      }),
      concatMap(() => this.invoiceService.update$(this.invoice)),
    ).subscribe();
  }

  private initializeRemoveDiscountsFromEstimate() {
    this.removeDiscountsFromEstimate$.pipe(
      tap(discounts => {
        discounts.forEach(discount => {
          const index = this.estimate.discounts.findIndex(d => d.DocId() === discount.DocId());
          if (index > -1) {
            this.estimate.discounts.splice(index, 1);
          }
        });
      }),
      concatMap(() => this.estimateService.update$(this.estimate)),
    ).subscribe();
  }

  private initializeRemoveLineItemsFromInvoice() {
    this.removeLineItemsFromInvoice$.pipe(
      map(x => x.map(l => l.lineItem)),
      tap(lineItems => {
        lineItems.forEach(lineItem => {
          const index = this.invoice.lineItems.findIndex(l => l.DocId() === lineItem.DocId());
          if (index > -1) {
            this.invoice.lineItems.splice(index, 1);
          }
        });
      }),
      concatMap(lineItems => this.invoiceService.update$(this.invoice).pipe(map(() => lineItems))),
      tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l))),
    ).subscribe();
  }

  private initializeRemoveLineItemsFromEstimate() {
    this.removeLineItemsFromEstimate$.pipe(
      map(x => x.map(l => l.lineItem)),
      tap(lineItems => {
        lineItems.forEach(lineItem => {
          const index = this.estimate.lineItems.findIndex(l => l.DocId() === lineItem.DocId());
          if (index > -1) {
            this.estimate.lineItems.splice(index, 1);
          }
        });
      }
      ),
      concatMap(lineItems => this.estimateService.update$(this.estimate).pipe(map(() => lineItems))),
      tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l))),
    ).subscribe();
  }

  private initilizeRemoveLineItemsFromJob() {
    this.removeLineItemsFromJob$.pipe(
      map(lineItems => {
        const lineItemsNeedingDocId$ : Observable<void>[] = [of(void(0))];
        lineItems.forEach(lineItem => {
          if (lineItem.reason !== LineItemRemovalReason.Reschedule) {
            const index = this.job.lineItems.findIndex(l => l.DocId() === lineItem.lineItem.DocId());
            if (index > -1) {
              const removedItem = this.job.lineItems.splice(index, 1);
              if (lineItem.reason === LineItemRemovalReason.WillNotComplete) {
                const mutateAbandonedToAllowRollback = new LineItem(removedItem[0]);
                mutateAbandonedToAllowRollback.abandoned = true;
                mutateAbandonedToAllowRollback.completed = false;
                if (mutateAbandonedToAllowRollback.originatingLineItemDocId === null) {
                  mutateAbandonedToAllowRollback.originatingLineItemDocId = removedItem[0].lineItemDocId;
                }
                lineItemsNeedingDocId$.push(this.lineItemService.retrieveDocId(mutateAbandonedToAllowRollback));
                this.job.abandonedLineItems.push(mutateAbandonedToAllowRollback);
              }
            }
          }
        });
        return {x: lineItems, lineItemsNeedingDocId$};
      }),
      switchMap(x => combineLatest(x.lineItemsNeedingDocId$).pipe(map(() => x.x))),
      concatMap(lineItems => this.jobService.update$(this.job).pipe(map(() => lineItems))),
      tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l.lineItem))),
    ).subscribe();
  }

  private initilizeAddDiscountToInvoice() {
    this.addDiscountsToInvoice$.pipe(
      filter(() => !this.initiatingJobScheduleFromEstimate),
      map(discounts => this.filterOutExistingElements(discounts, this.invoice.discounts) as Discount[]),
      filter(discounts => discounts.length > 0),
      tap(discounts => this.invoice.discounts.push(...discounts)),
      concatMap(() => this.invoiceService.update$(this.invoice))
    ).subscribe();
  }

  private initilizeAddDiscountToEstimate() {
    this.addDiscountsToEstimate$.pipe(
      map(discounts => this.filterOutExistingElements(discounts, this.estimate.discounts) as Discount[]),
      filter(discounts => discounts.length > 0),
      tap(discounts => this.estimate.discounts.push(...discounts)),
      concatMap(() => this.estimateService.update$(this.estimate))
    ).subscribe();
  }

  private initilizeAddDiscountToJob() {
    this.addDiscountsToJob$.pipe(
      map(discounts => this.filterOutExistingElements(discounts, this.job.discounts) as Discount[]),
      filter(discounts => discounts.length > 0),
      tap(discounts => this.job.discounts.push(...discounts)),
      concatMap(() => this.jobService.update$(this.job))
    ).subscribe();
  }

  private initilizeAddLineItemsToInvoice() {
    this.addLineItemsToInvoice.pipe(
      tap(lineItems => this.invoice.lineItems.push(...(this.filterOutExistingElements(lineItems, this.invoice.lineItems) as LineItem[]))),
      concatMap(lineItems => this.invoiceService.update$(this.invoice).pipe(map(() => lineItems))),
      tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l)))
    ).subscribe();
  }

  private initlizeAddLineItemsToEstimate() {
    this.addLineItemsToEstimate$.pipe(
      map(lineItems => this.setLineItemsToActiveEstimate(lineItems)),
      tap(lineItems => {
        const b = (this.filterOutExistingElements(lineItems, this.estimate.lineItems) as LineItem[]);
        this.estimate.lineItems.push(...b);
      }),
      concatMap(lineItems => this.estimateService.update$(this.estimate).pipe(map(() => lineItems))),
      tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l)))
    ).subscribe();
  }

  private initilizeAddLineItemsToJob() {
    this.addLineItemsToJob$.pipe(
      filter(() => !this.initiatingJobScheduleFromEstimate),
      map(lineItems => this.setLineItemsToActiveEstimate(lineItems)),
      tap(lineItems => this.job.lineItems.push(...(this.filterOutExistingElements(lineItems, this.job.lineItems) as LineItem[]))),
      concatMap(lineItems => this.jobService.update$(this.job).pipe(map(() => lineItems))),
      tap(lineItems => lineItems.forEach(l => this.formlyUtilityService.completedLineItemOperation$.next(l)))
    ).subscribe();
  }

  //Filter out composed objects which already exist in given array
  filterOutExistingElements(proposedAdditions: RetrieveFirestoreProperties[], existingLineItems: RetrieveFirestoreProperties[]) : RetrieveFirestoreProperties[] {
    return proposedAdditions.filter(l => existingLineItems.findIndex(y => y.DocId() === l.DocId()) === -1);
  }

  setLineItemsToActiveEstimate(lineItemsToAdd: LineItem[]) : LineItem[] {
    lineItemsToAdd.forEach(l => l.originatingEstimateDocId = this.estimate.DocId());
    return lineItemsToAdd;
  }

}
