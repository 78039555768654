import { Injectable } from '@angular/core';
;
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { GenericServiceProviderSetting } from '../dao/generic-service-provider-setting';
import { AuthenticationService } from '../../util/authentication.service';
import { BehaviorSubject, combineLatest, zip } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { FirestoreDiffService } from './firestore-diff.service';
import { where } from 'firebase/firestore';


@Injectable({
  providedIn: 'root'
})

export class GenericServiceProviderSettingService extends DatabaseStoreService<GenericServiceProviderSetting> {

  allJobPriorities = new BehaviorSubject<GenericServiceProviderSetting[]>([]);
  allCustomerTypes = new BehaviorSubject<GenericServiceProviderSetting[]>([]);

  constructor(fs: GenericServiceProviderSettingFirestoreService, authenticationService: AuthenticationService, store: GenericServiceProviderSettingStoreService) {
    super(fs, store, authenticationService);

    const loadJobPriorities = this.queryFirestoreShallow$([where('parentCollectionName', '==', 'job_priorities')]).pipe(
      tap(j => this.allJobPriorities.next(j))
    );

    const loadCustomerTypes = this.queryFirestoreShallow$([where('parentCollectionName', '==', 'customer_types')]).pipe(
      tap(j => this.allCustomerTypes.next(j))
    );

    if (authenticationService.mobileSite) {
      zip(loadJobPriorities, loadCustomerTypes).pipe(
        take(1)
      ).subscribe();
    } else {
      combineLatest(loadJobPriorities, loadCustomerTypes).pipe(
      ).subscribe();
    }
  }

  prePopulate() {
    this.loadAll$().pipe(
      map(x => {
        x.forEach(y => y.uncommitedDocId = true);
        return x;
      }),
      switchMap(j => this.createMultiple$(j)),
      tap(x => {
      console.log("Populated generic service settings");
      }),
      take(1)
    ).subscribe();
  }
}

@Injectable({
  providedIn: 'root'
})
export class GenericServiceProviderSettingStoreService extends StateChangeStoreService<GenericServiceProviderSetting> {
  protected store = "generic-service-provider-setting-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, GenericServiceProviderSetting>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class GenericServiceProviderSettingFirestoreService extends FirestoreBackend<GenericServiceProviderSetting> {

  protected basePath = "generic-service-provider-settings";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): GenericServiceProviderSetting {
    return new GenericServiceProviderSetting(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new GenericServiceProviderSetting(), authService);
  }
}
