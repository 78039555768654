import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SettingsService } from '../../settings/settings.service';
import { FormCatagoryService } from '../../../../../common/src/data/dao-services/form-catagory.service';
import { FormCatagory } from '../../../../../common/src/data/dao/form-catagory';
import { FirestoreBackend } from '../../../../../common/src/data/database-backend/retrieve-from-firestore';


export class FormCatagoryTreeStructure {
  name: string;
  key: string;
  parentCatagoryKey: string;
  formCatagory: FormCatagory;
  description: string = "";

  constructor(init?: Partial<FormCatagoryTreeStructure>) {
    Object.assign(this, init);
    // Some of structure is derived from Form Catagory.
    if (this.formCatagory !== undefined) {
      this.name = this.formCatagory.name;
      this.key = this.formCatagory.DocId();
      this.parentCatagoryKey = this.formCatagory.parentDocId;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class FormCatagoryTreeService {

  displayInactiveForms: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  topLevelTreeStructureNodes: FormCatagoryTreeStructure[] = [];
  allNodes: BehaviorSubject<FormCatagoryTreeStructure[]> = new BehaviorSubject<FormCatagoryTreeStructure[]>([]);
  selectedNodes: BehaviorSubject<FormCatagoryTreeStructure[]> = new BehaviorSubject<FormCatagoryTreeStructure[]>([]);
  rootNode: BehaviorSubject<FormCatagoryTreeStructure> = new BehaviorSubject<FormCatagoryTreeStructure>(undefined);


  updateSelectedNodes(selectedFormCatagories: FormCatagory[]) {
    this.selectedNodes.next(this.allNodes.value.filter(x => selectedFormCatagories.some(z => z.DocId() === x.key)));
  }

  constructor(private formCatagoryService: FormCatagoryService, private settingsService: SettingsService) {

    const loadAll = this.formCatagoryService.loadAll$();
    combineLatest([loadAll, this.displayInactiveForms]).pipe(
      map(([formCatagories, displayInactiveForms]) => {
        if (displayInactiveForms) {
          return formCatagories;
        } else {
          return formCatagories.filter(x => x.active);
        }
      }
      ),
      map(x => this.topLevelTreeStructureNodes.concat((x as FormCatagory[]).map(z => {
        const retVal = new FormCatagoryTreeStructure({formCatagory: z});
        return retVal;
      }))),
      takeUntil(FirestoreBackend.destroyingComponent$),
      ).subscribe(this.allNodes);

      this.formCatagoryService.load$(this.settingsService.getValue('defaultWorkCatagoryDocId')).pipe(
        map(x => new FormCatagoryTreeStructure({formCatagory: x})),
        tap(x => this.rootNode.next(x)),
        takeUntil(FirestoreBackend.destroyingComponent$)).subscribe();
  }
}
