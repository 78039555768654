import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Address } from "./address";

export class CompanyLocation extends RetrieveFirestorePropertiesBase<CompanyLocation>  {

  name: string = "";
  mailingAddressDocId : string;
  mailingAddress: Address;
  dispatchAddressDocId: string;
  dispatchAddress: Address;
  active: boolean = true;
  default: boolean = false;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["dispatchAddress","mailingAddress"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["dispatchAddressDocId","mailingAddressDocId"]);
static _firestoreCompositionMemberNames = ["dispatchAddress","mailingAddress"];
static _firestoreCompositionalDiffMemberNames: string[] = ["dispatchAddress","mailingAddress"];

retrieveFirestoreIgnoredMemberNames() : string[] { return CompanyLocation._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return CompanyLocation._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return CompanyLocation._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return CompanyLocation._firestoreCompositionMemberNames;}

public constructor(init?: Partial<CompanyLocation>) {
super();
Object.assign(this, init);
}
}
