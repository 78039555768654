
 <!--inline-header is a reminder for greg to come back to this. It will be changed later-->
 <div class="date-picker" fxLayout="row">
    <button mat-button [disabled]="ViewModelDisabled" (click)="viewWindowSetToToday$.next(null)">Today</button>
    <button mat-button [disabled]="ViewModelDisabled" (click)="retreatViewWindow$.next(null)">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-button [disabled]="ViewModelDisabled" (click)="advanceViewWindow$.next(null)">
      <mat-icon class="white-icon">chevron_right</mat-icon>
    </button>
  </div>




 <!--inline-header is a reminder for greg to come back to this. It will be changed later-->
 <!-- <div class="date-picker" fxLayout="row">
  <button [disabled]="ViewModelDisabled" mat-raised-button class="md-button md-small" (click)="viewWindowSetToToday$.next(null)">Today</button>
  <button [disabled]="ViewModelDisabled" mat-raised-button class="md-button md-small" (click)="retreatViewWindow$.next(null)">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button [disabled]="ViewModelDisabled" mat-raised-button class="md-button md-small" disableRipple=true (click)="advanceViewWindow$.next(null)">
    <mat-icon class="white-icon">chevron_right</mat-icon>
  </button>
</div> -->
