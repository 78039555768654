import { Injectable } from '@angular/core';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { LineItemService } from './line-item.service';
import { GenericServiceProviderSettingService } from './generic-service-provider-setting.service';
import { AuthenticationService } from '../../util/authentication.service';
import { JobType } from '../dao/job-type';
import { PricingMethodologySettingsService } from './pricing-methodology-settings.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})

export class JobTypeService extends DatabaseStoreService<JobType> {

  get jobTypeColors() { return ['red', 'green', 'blue', 'yellow', 'orange', 'purple', 'pink']; }

  constructor( fs: JobTypeFirestoreService, authenticationService: AuthenticationService, store: JobTypeStoreService, private lineItemService: LineItemService,
    private genericServiceProviderSettingFirestoreService: GenericServiceProviderSettingService,
    private pricingMethodologySettingsService: PricingMethodologySettingsService) {
    super(fs, store, authenticationService, new Map([
      ["lineItems", {associatedDocumentId: "lineItemsDocIds", compositionStoreService: lineItemService}],
      ["jobPriority", {associatedDocumentId: "jobPriorityDocId", compositionStoreService:
      genericServiceProviderSettingFirestoreService}],
    ["pricingMethodology", {associatedDocumentId: "pricingMethodologySettingsDocId", compositionStoreService: pricingMethodologySettingsService}]
    ]));
  }
}

@Injectable({
  providedIn: 'root'
})
export class JobTypeStoreService extends StateChangeStoreService<JobType> {
  protected store = "job-type-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, JobType>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class JobTypeFirestoreService extends FirestoreBackend<JobType> {

  protected basePath = "job_type";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): JobType {
    return new JobType(obj);
  }

  constructor(protected authService: AuthenticationService ) {
    super(new JobType(), authService);
  }
}
