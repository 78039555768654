import {  ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder,  UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable,  Subject } from 'rxjs';
import { delay, distinctUntilChanged,  filter,  map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { FormFirestoreSummary } from '../../../../../common/src/data/dao/form-firestore-summary';
import { WorkflowSelectionModalComponent } from '../branching-container/workflow-selection-modal/workflow-selection-modal.component';
import { ChoiceControlType } from '../choice/choice.component';
import { BorderColor, BorderOptionType } from '../column-splitter/column-splitter.component';
import { underlineControlOptions } from '../component-models/control-container.component';
import { LineItemControlType } from '../component-models/line-items/line-item-enums';
import { RatingControlDefaultService } from '../component-models/rating-control/rating-control-default.service';
import { DataLinkPopulatorComponent } from '../data-link-populator/data-link-populator.component';
import { DataLink, DataLinkService } from '../data-link-populator/data-link.service';
import { Audience } from '../component-models/formly-controls/formly-utility.service';

interface LabelLocation {
  value: number;
  label: string;
}

interface underlineOptions {
  value: underlineControlOptions;
  label: string;
}

interface AudienceInterface {
  value: Audience;
  label: string;
}

interface ChooserOptions {
  value: ChoiceControlType;
  label: string;
}

interface LineItemControlTypeOptions {
  value: LineItemControlType;
  label: string;
}

interface StandardRatingOptions {
  value: UntypedFormArray,
  label: string
}

interface BorderOptions {
  value: BorderOptionType;
  label: string;
  icon: any;
}

interface BorderColorOptions {
  value: BorderColor;
  label: string;
}

@Component({
  selector: 'app-sidebar-component-editor',
  templateUrl: './sidebar-component-editor.component.html',
  styleUrls: ['./sidebar-component-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponentEditorComponent implements OnInit, OnDestroy {

  @ViewChild('defaultText') bodyRef: ElementRef;

  changeDetect$: Subject<any> = new Subject();

  @Input () form: UntypedFormGroup;
  @Input () parentForm: UntypedFormGroup;
  @Input () newControl: Observable<any>;
  guid: string = undefined;
  ratingGuid: string = undefined;
  questionsGuid: string = undefined;
  dataLinkDialogRef: MatDialogRef<DataLinkPopulatorComponent>;
  workflowSelectionModalDialogRef: MatDialogRef<WorkflowSelectionModalComponent>;
  destroyingComponent$ = new Subject();
  suscribeToNewDataLink$ = new Subject<string>();

  avaliableChoicesFormGroup : UntypedFormGroup;
  ratingScaleFormGroup: UntypedFormGroup;
  ratingQuestionsFormGroup: UntypedFormGroup;

  labelLocationOptions: LabelLocation[] = [
    { value: 1, label: "Left"},
    { value: 0, label: "Above"},
  ];

  underlineOptions : underlineOptions[] = [
    {value: underlineControlOptions.None, label: "None"},
    {value: underlineControlOptions.Black, label: "Black"},
    {value: underlineControlOptions.Grey, label: "Grey"},
  ];

  audienceOptions: AudienceInterface[] = [
    { value: Audience.All, label: 'All' },
    { value: Audience.Internal, label: 'Tech' },
    { value: Audience.Customer, label: 'Customer' },
  ];

  borderOptions: BorderOptions[] = [
    {value: BorderOptionType.InnerAndOuter, label : 'Inner and Outer', icon : 'border_inner'},
    {value: BorderOptionType.Outer, label: 'Outer', icon : 'border_outer'},
    {value: BorderOptionType.None, label: 'None',  icon : 'border_clear'},
  ];

  scaleOptions!: StandardRatingOptions[];

  compareFn(f1: BorderOptions, f2: BorderOptions): boolean {
    return f1 && f2? f1.value === f2.value : f1 === f2;
  }

  borderColors: BorderColorOptions[] = [
    {value: BorderColor.Black, label: 'Black'},
    {value: BorderColor.Grey, label: 'Grey'},
  ];

  chooserOptions: ChooserOptions[] = [
    { value: 1, label: 'Drop Down' },
    { value: 2, label: 'Radio Buttons' },
    { value: 3, label: 'Checkboxes' },
  ];

  lineItemDisplayTypeOptions: LineItemControlTypeOptions[] = [
    {value: 1, label: 'Estimate Creator'},
    {value: 2, label: 'Line Items to Complete'},
    {value: 3, label: 'Invoice Creator'}
  ];

  constructor(private fb: UntypedFormBuilder, private dialog: MatDialog, private ratingDefaultsService: RatingControlDefaultService,
    private dataLinkService: DataLinkService) {
      this.scaleOptions = this.ratingDefaultsService.Defaults();
  }

  get minimumPhotoCount() {return this.form.get("controlComponentFormGroup").value.get("minimumPhotoCount");}
  get maximumPhotoCount() {return this.form.get("controlComponentFormGroup").value.get("maximumPhotoCount");}
  get minimumNumberInstances() {return this.form.get("controlComponentFormGroup").value.get("minimumNumberInstances");}
  get maximumNumberInstances() {return this.form.get("controlComponentFormGroup").value.get("maximumNumberInstances");}

  ngOnInit(): void {
    this.newControl.pipe(
      startWith(null),
      delay(1),
      map(() => this.form.get("readOnlyDisabled").value),
      distinctUntilChanged(),
      tap(disabled => disabled ? this.form.get("readonly").disable() : this.form.get("readonly").enable()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

   firstChange: boolean = false;

  logFormState(): void {
    console.log(this.form);
    console.log(this.parentForm);
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
}

  provideLabel(controlName: string) : boolean {
    switch (controlName) {
      case "Text Box":
      case "Choice":
      case "Photo Adder":
      case "Rating Scale":
      case "Line Items":
      case "Branching":
      return true;
      break;
      default:
        return false;
    }
  }

  labelLocationSpecified(controlName: string) : boolean {
    switch (controlName) {
      case "Text Box":
      case "Choice":
        return true;
        break;
      case "Photo Adder":
        return false;
        break;
      default:
        return false;
    }
  }


  deleteDataLink()
  {
    this.suscribeToNewDataLink$.next(this.form.get("guid").value);
    const formToPatch = this.form.get("controlComponentFormGroup").value;
    formToPatch.controls['value'].enable();
    formToPatch.patchValue({value: "", dataLink: {}});
  }

  loadDataLinkModal() {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      formCatagory: "all"
    });

    this.dataLinkDialogRef = this.dialog.open(DataLinkPopulatorComponent, editorConfig);

    // Event dialog closure:
    this.dataLinkDialogRef.afterClosed().pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.eventDialogClosure(x));

  }

  loadWorkflowSelectionModal() {

    const editorConfig = new MatDialogConfig();

    const options = this.form.get('controlComponentFormGroup').value.get('chooserFormGroup').value.value.get('options') as UntypedFormArray;
    const activeDocIds = [];
    options.controls.forEach(c => {
      activeDocIds.push(c.get("value").value);
    });
    Object.assign(editorConfig, {
      data: {
      activeDocIds: activeDocIds
      }
    });

    this.workflowSelectionModalDialogRef = this.dialog.open(WorkflowSelectionModalComponent, editorConfig);

    // Event dialog closure:
    this.workflowSelectionModalDialogRef.afterClosed().pipe(
      filter(x => x),
      map(x => x as FormFirestoreSummary[]),
      tap(x => {
        const options = this.form.get('controlComponentFormGroup').value.get('chooserFormGroup').value.value.get('options') as UntypedFormArray;
        options.clear();
        x.forEach(formFirestoreSummary => {
        const newVal = this.fb.group({
          label: this.fb.control(formFirestoreSummary.title),
          value: formFirestoreSummary.DocId(),
          focused: false
        });
        options.push(newVal);
      });
      this.changeDetect$.next(null);
      }),
      take(1)
      ).subscribe();
  }

  eventDialogClosure(dataLinks: DataLink[]) {
    if (dataLinks !== undefined) {
      const insertString = DataLinkService.mapDataLinksToInsertString(dataLinks);
        const loc = this.bodyRef.nativeElement.selectionStart;
        const formGroup = this.form.get("controlComponentFormGroup").value;
        const prev = formGroup.value.value as string;
        if (prev !== "") {
          formGroup.patchValue({value: prev.substr(0,loc) + insertString + prev.substr(loc), dataLinks: dataLinks });
        } else {
          formGroup.patchValue({value: insertString, dataLinks: dataLinks});
        }
    }
  }

  initilizeAvailibleChoicesForm(choiceFormGroup: UntypedFormGroup) : UntypedFormGroup {
    if (this.guid !== this.form.get("guid").value) {
      this.avaliableChoicesFormGroup = this.fb.group({
        title: ["Choices"],
        draggableComponents: (choiceFormGroup.get('options') as UntypedFormArray),
        allowAddFromDragDrop: choiceFormGroup.get('allowAddFromDragDrop').value,
      }, {updateOn: "blur"});
      this.guid = this.form.get("guid").value;
    }
    return this.avaliableChoicesFormGroup;
  }

  initilizeRatingScaleForm() : UntypedFormGroup {
    if (this.ratingGuid !== this.form.get("guid").value) {
      this.ratingScaleFormGroup = this.fb.group({
        title: ["Rating Scale"],
        draggableComponents: (this.form.get('controlComponentFormGroup').value.get('columns') as UntypedFormArray),
        allowAddFromDragDrop: this.form.get('controlComponentFormGroup').value.get('allowAddFromDragDrop').value,
      });
      this.ratingGuid = this.form.get("guid").value;

      this.form.get("ratingPrepopulatedOption")?.valueChanges.pipe(
        tap(x => {
          const cols = (this.ratingScaleFormGroup.get("draggableComponents") as UntypedFormArray);
          cols.clear();
          x.controls.forEach(val => cols.push(val));
          }),
        takeUntil (this.destroyingComponent$)
      ).subscribe();
    }
    return this.ratingScaleFormGroup;
  }

  initilizeRatingQuestionsForm() : UntypedFormGroup {
    if (this.questionsGuid !== this.form.get("guid").value) {
      this.ratingQuestionsFormGroup = this.fb.group({
        title: ["Questions"],
        draggableComponents: (this.form.get('controlComponentFormGroup').value.get('data') as UntypedFormArray),
        allowAddFromDragDrop: this.form.get('controlComponentFormGroup').value.get('allowAddFromDragDrop').value,
      });
      this.questionsGuid = this.form.get("guid").value;
    }
    return this.ratingQuestionsFormGroup;
  }

}
