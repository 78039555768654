import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { subject } from '@casl/ability';
import { addDays, addHours, addMinutes, differenceInHours, differenceInMinutes,  getHours,  getMinutes,  isWeekend, startOfDay, subDays, subHours } from 'date-fns';
import {combineLatest, delayWhen, merge,  of,  Subject, zip } from 'rxjs';
import {  distinctUntilChanged, filter, map, share, skip, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddressSearchModalComponent } from './address_search/address-search-modal/address-search-modal.component';
import { AppAbility } from '../../../common/src/util/AppAbility';
import { AuthenticationService } from '../../../common/src/util/authentication.service';
import { Employee } from '../../../common/src/data/dao/employee';
import { EmployeeAvailability } from '../../../common/src/data/dao/employee-availability';
import { EmployeePermission, EmployeePermissionDateRange } from '../../../common/src/data/dao/employee-permission';
import { EmployeePermissionBuilder } from './employee-permission-builder';
import { EmployeePermissionTreeService, EmployeePermissionTreeStructure } from './employee-permission-tree.service';
import { EmployeeRole } from '../../../common/src/data/dao/employee-role';
import { LineItem } from '../../../common/src/data/dao/line-item';
import { ChecklistDatabase, ItemCatagoryNode } from './multiselect-nested-tree/multiselect-nested-tree.component';
import { SettingsService } from './settings/settings.service';
import { randomElementName } from '../../../common/src/util/util';
import { EmployeeRoleService } from '../../../common/src/data/dao-services/employee-role.service';
import { EmployeeService } from '../../../common/src/data/dao-services/employee.service';
import { AddressService } from '../../../common/src/data/dao-services/address.service';
import { Address } from '../../../common/src/data/dao/address';
import { PhysicalAddressRoutingService } from './physical-address-routing.service';
import { EmployeeAvailabilityService } from '../../../common/src/data/dao-services/employee-availability.service';
import { searchSource } from '../../../common/src/data/services/search.service';
import { CompanyLocationService } from '../../../common/src/data/dao-services/company-location.service';
import { CompanyLocation } from '../../../common/src/data/dao/company-location';
import { SkillSet } from '../../../common/src/data/dao/skill-set';
import { SkillSetService } from '../../../common/src/data/dao-services/skill-set.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeComponent implements OnInit, OnDestroy {

  weekWorthOfDays: EmployeeAvailability[] = [];
  minDateToDisplay : Date;
  maxDateToDisplay: Date;
  form: UntypedFormGroup;

  randomElementName : string = randomElementName();
  permissionAdded$ = new Subject<EmployeePermissionTreeStructure[]>();
  permissionRemoved$ = new Subject<EmployeePermissionTreeStructure[]>();

  searchAddress$ = new EventEmitter();
  addressesFound$: Subject<Address[]> = new Subject<Address[]>();
  activeSearch: boolean = false;
  displayNoResultsFound: boolean;
  patchingEmployee: boolean = false;
  createUserAccountOnSave: boolean = false;
  sendVerificationEmailOnSave: boolean = false;
  updateUserActiveOnSave: boolean = false;
  updateScheduleFieldCallsOnSave: boolean = false;

  _checkListDatabase :ChecklistDatabase<EmployeePermissionTreeStructure> = undefined;

  destroyingComponent$ = new Subject();
  changedEmployee$ = new Subject();

  updateAddressModalRef: MatDialogRef<AddressSearchModalComponent>;

  initialOriginAddress: Address = undefined;
  initialDestinationAddress: Address = undefined;

  companyLocations: CompanyLocation[] = [];

  activeEmployee : Employee = new Employee();
  _skillsChecklistDatabase: ChecklistDatabase<SkillSet> = undefined;
  selectedEmployeeSkills: Subject<SkillSet[]> = new Subject<SkillSet[]>();

  constructor(private fb: UntypedFormBuilder, private employeeService: EmployeeService, private addressService: AddressService,
    private dialog: MatDialog, private employeePermissionTreeService:EmployeePermissionTreeService, private employeePermissionBuilder: EmployeePermissionBuilder,
    private employeeRoleService: EmployeeRoleService, private abilityService: AppAbility, private authService: AuthenticationService, private snackBar: MatSnackBar,
    public settingsService: SettingsService, private physicalAddressRoutingService: PhysicalAddressRoutingService,
    private employeeAvailibilityService: EmployeeAvailabilityService, private companyLocationService: CompanyLocationService,
    private skillSetService: SkillSetService)  {
      this.companyLocationService.loadAll$().pipe(
        take(1)
      ).subscribe(x => this.companyLocations = x as CompanyLocation[]);
    }

  get employeesToDisplay() { return <UntypedFormArray>this.form.get('employees')}
  get employeeFormGroup() { return this.form.get("employeeFormGroup") as UntypedFormGroup;}

  get activeFormControl() { return this.employeeFormGroup.get("active")}

  generateRandomElementName(id: any) {
    return this.randomElementName.concat(id);
  }

  standardFieldsToPatch = ["name","phoneNumber","employeeRole","employeeSkills","dispatchOrginAddress","dispatchDestinationAddress","active",
  "scheduleFieldCallsFor", "email", "dispatchOrginAddressCommuteDispensation", "dispatchDestinationAddressCommuteDispensation", "employeeLocation"]

  findMatchingEmployeePermission(p: EmployeePermission) : ItemCatagoryNode<EmployeePermissionTreeStructure> {
    if (p.permissionType === "static") {
      return this._checkListDatabase.findNode(new EmployeePermissionTreeStructure({employeePermission: p}));
    }
    if (p.displayType === "days") {
      const asDateRange = p as EmployeePermissionDateRange;
       return this._checkListDatabase.findNode(new EmployeePermissionTreeStructure({employeePermission: p}),
      {sourceMatchGen: (x=> (x.employeePermission as EmployeePermissionDateRange).prototypePermissionDocId),
        existingMatchGen:  (x=> x.employeePermission === undefined ? null :
          (x.employeePermission as EmployeePermissionDateRange).prototypePermissionDocId === undefined ? null
          : (x.employeePermission as EmployeePermissionDateRange).prototypePermissionDocId)} );
    }
  }


  skillAddedToEmployee(skills: SkillSet[]) {
    if (!this.patchingEmployee) {
      this.employeeFormGroup.get("employeeSkills").value.push(...skills);
    }
  }

  skillRemovedFromEmployee(skills: SkillSet[]) {
    if (!this.patchingEmployee) {
      skills.forEach(skill => {
        this.employeeFormGroup.get("employeeSkills").value.splice(this.employeeFormGroup.get("employeeSkills").value.findIndex(x => x.docId === skill.docId),1);
      })
    }
  }

  EmployeeSkillCheckListDatabase() : ChecklistDatabase<SkillSet> {
    if (this._skillsChecklistDatabase === undefined) {
      this._skillsChecklistDatabase =   new ChecklistDatabase<SkillSet>("Employee Skills",
      this.skillSetService.loadAll$().pipe(
        map(z => z.filter(x=>x.active))),
        this.selectedEmployeeSkills,
      (x=>undefined),(x => x.name),(x=>x.name)
      );
    }
    return this._skillsChecklistDatabase;
  }

  setupAssignedEmployeeSkills() : void {
    this._skillsChecklistDatabase.addMatchingNodes(this.activeEmployee.employeeSkills);
  }

  patchEmployee(employee: Employee) {
    this.patchingEmployee = true;
    this.activeEmployee = employee;
    this.setupAssignedEmployeeSkills();

    this.createUserAccountOnSave = employee.name === undefined;
    this.changedEmployee$.next(null);
    this.employeePermissionTreeService.resetAllForms();
    this._checkListDatabase.selectedNodes.next([]);
    const selectedNodes : ItemCatagoryNode<EmployeePermissionTreeStructure>[] = [];
    this.standardFieldsToPatch.forEach(field => {
      this.employeeFormGroup.get(field).patchValue(employee[field]);
    });
    this.initialOriginAddress = employee.dispatchOrginAddress;
    this.initialDestinationAddress = employee.dispatchDestinationAddress;

    this.employeeFormGroup.patchValue({employeeLocation: employee.employeeLocation});
    this.employeeFormGroup.patchValue({employeePermissions: employee.employeePermissions.map(q=>q)});
    this.employeeFormGroup.patchValue({employeeAvailibility: employee.employeeAvailability.length === 0 ? this.defaultWorkWeek() : employee.employeeAvailability});
    (this.employeeFormGroup.get("employeePermissions").value as EmployeePermission[]).forEach(p => {
      const matchingPermission = this.findMatchingEmployeePermission(p);
      if (matchingPermission !== undefined) {
        if (p.displayType === "days") {
          this.employeePermissionTreeService.patchPermissionToForm(p,matchingPermission.item.form);
          this.reflectNumberDaysFromFormToPermission(matchingPermission.item.form,p as EmployeePermissionDateRange);
          this.reflectNumberDaysCatagoryFromFormToPermission(matchingPermission.item.form,p as EmployeePermissionDateRange);
        }
        selectedNodes.push(matchingPermission);
      }
    });

    // employee availability in form need employee doc id set to current employee.
    (this.employeeFormGroup.get("employeeAvailibility").value as EmployeeAvailability[]).forEach(a => {
      a.employeeDocId = employee.docId;
    });

    this.sendVerificationEmailOnSave=false;
    this.employeeFormGroup.get("email").valueChanges.pipe(
      distinctUntilChanged(),
      skip(1),
      tap(() => this.sendVerificationEmailOnSave = true),
      takeUntil(merge(this.destroyingComponent$,this.changedEmployee$))
    ).subscribe();

    this.employeeFormGroup.get("active").valueChanges.pipe(
       skip(1),
      tap(() => this.updateUserActiveOnSave = true),
      takeUntil(merge(this.destroyingComponent$,this.changedEmployee$))
    ).subscribe();

    this.employeeFormGroup.get("scheduleFieldCallsFor").valueChanges.pipe(
      skip(1),
     tap(() => this.updateScheduleFieldCallsOnSave = true),
     takeUntil(merge(this.destroyingComponent$,this.changedEmployee$))
   ).subscribe();

    // remove any permissions which were not found.

    this._checkListDatabase.selectedNodes.next(selectedNodes.filter(x=>x!==undefined));
    this.patchingEmployee = false;
  }

  patchEmployeeFormGroupToEmployee() {
    const employee = this.form.get("employee").value;
    if (employee !== null) {
      this.standardFieldsToPatch.forEach(field =>  employee[field] = this.employeeFormGroup.get(field).value);
      (employee as Employee).employeePermissions = this.employeeFormGroup.get("employeePermissions").value;
      (employee as Employee).employeeAvailability = this.employeeFormGroup.get("employeeAvailibility").value;
      (employee as Employee).employeeAvailability.forEach(a => a.employeeDocId = employee.docId);
    }
  }

  get employeeRoles() { return this.employeeRoleService;}
  get employeeLocations() { return this.companyLocationService;}

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }


  setCheckDay(day: EmployeeAvailability) {
    day.activeWorkDay = !day.activeWorkDay
  }

  reflectNumberDaysFromFormToPermission(form: UntypedFormGroup, permission: EmployeePermissionDateRange) {
    form.get("numberDays").valueChanges.pipe(
      startWith(form.get("numberDays").value),
      tap(numDays => permission.numberDaysBack = numDays),
      tap(numDays => permission.numberDaysForward = numDays),
      takeUntil(merge(this.destroyingComponent$,this.changedEmployee$))
    ).subscribe();
  }

  reflectNumberDaysCatagoryFromFormToPermission(form: UntypedFormGroup, permission: EmployeePermissionDateRange) {
    form.get("dateSelectionType").valueChanges.pipe(
      startWith(form.get("dateSelectionType").value),
      tap(selectionType => {
        let numberDays = undefined;
        if (selectionType === "null") {
          numberDays = 365;
        } else if (selectionType === "0") {
          numberDays = 0;
        }
        if (numberDays !== undefined) {
          permission.numberDaysBack = numberDays;
          permission.numberDaysForward = numberDays;
        }
      }),
      takeUntil(merge(this.destroyingComponent$,this.changedEmployee$))
    ).subscribe();
  }

  setupEmployeePermissions() : void {
    const removePermission = this.permissionRemoved$.pipe(
      filter(() => !this.patchingEmployee),
      tap(removedPermissions => {
        const permissions = (this.form.get("employeeFormGroup").get("employeePermissions").value as EmployeePermission[]);
        removedPermissions.filter(x => x.employeePermission !== undefined).forEach(permission =>
          {
            if (permissions.findIndex(p => p.docId === permission.employeePermission.docId) !== -1) {
              permissions.splice(permissions.findIndex(p => p.docId === permission.employeePermission.docId), 1);
            } else {
              permissions.splice(permissions.map(x=>x as EmployeePermissionDateRange).findIndex(p => p.prototypePermissionDocId ===
                (permission.employeePermission as EmployeePermissionDateRange).prototypePermissionDocId),1);
            }
          }
          );
      }),
    );

    const addPermission = this.permissionAdded$.pipe(
      filter(() => !this.patchingEmployee),
      tap(addedPermissions => {
        const permissions = (this.form.get("employeeFormGroup").get("employeePermissions").value as EmployeePermission[]);
        addedPermissions.filter(x => x.employeePermission !== undefined).forEach(permission => {
          if (permission.employeePermission.permissionType === "static") {
            permissions.push(permission.employeePermission)
          } else {
            if (permission.employeePermission.permissionType === "dateRange") {
              const shallowClone = new EmployeePermissionDateRange({...permission.employeePermission});
              shallowClone.docId = undefined;
              shallowClone.rootPermission=false;
              this.reflectNumberDaysFromFormToPermission(permission.form, shallowClone);
              this.reflectNumberDaysCatagoryFromFormToPermission(permission.form,shallowClone);
            permissions.push(shallowClone);
            }
          }
        });
      }),
    );

    merge(removePermission,addPermission).pipe(
      tap( () => {
        if ( (this.form.get("employeeFormGroup").get("employeePermissions").value as EmployeePermission[])?.length !==
          (this.form.get("employeeFormGroup").get("employeeRole").value as EmployeeRole)?.employeePermissions?.length ) {
          this.form.get("employeeFormGroup").patchValue({employeeRole: this.employeeRoleService.get(EmployeeRole.CustomRoleDocId)});
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  ngOnInit(): void {

    this.form = this.fb.group({"employeeFormGroup" : this.createEmployeeFormGroup(),
      employees: this.fb.array([]),
      employee: [],
      showAllEmployees: false,
    });

    this.employeeFormGroup.get("dispatchOrginAddress").valueChanges.pipe(
      map(x => x === undefined || x === null   ? "" : (x as Address).formattedAddress()),
      tap(x => this.employeeFormGroup.patchValue({dispatchOrginAddressDisplay: x})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.employeeFormGroup.get("dispatchDestinationAddress").valueChanges.pipe(
      map(x => x === undefined || x === null ? "" : (x as Address).formattedAddress()),
      tap(x => this.employeeFormGroup.patchValue({dispatchDestinationAddressDisplay: x})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    this.settingsService.settingsLoaded$.pipe(
      tap(() => console.log(this.settingsService.getValue("dispatchAddress"))),
      filter(() => this.settingsService.getValue("dispatchAddress") !== null),
      switchMap(() => this.addressService.load$(this.settingsService.getValue("dispatchAddress").DocId())),
      take(1)
    ).subscribe();

    combineLatest([this.employeeService.loadAll$(), this.form.get("showAllEmployees").valueChanges.pipe(startWith(false))]).pipe(
      map(([employees,showAll]) => {
        if (showAll) {
          return employees;
        } else {
          return (employees as Employee[]).filter(e => e.active);
        }
      }),
      tap(employees => {
        const formEmployees = <UntypedFormArray>this.form.get("employees");
        formEmployees.clear();
        employees.forEach(e =>
          formEmployees.push(this.fb.control(e))
        );
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe( x => this.form.patchValue({employees: x}));


    // Once user clicks new employee, or selects employee manage employee permissions.
    this.form.get("employee").valueChanges.pipe(
      filter(e=>e!==null),
      tap(() => this.setupEmployeePermissions()),
      take(1)
    ).subscribe();

    this.form.get("employee").valueChanges.pipe(
      filter(e => e !== null),
      tap(e => this.patchEmployee(e)),
      tap(e => this.employeeFormGroup.enable()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.settingsService.settingsLoaded$.pipe(
      tap(() => {
        this.weekWorthOfDays = this.defaultWorkWeek();
    this.minDateToDisplay = subHours(this.settingsService.getValue("businessStartHour"), this.settingsService.getValue("hoursBeforeBusinessStartTimeToDisplay"));
    this.maxDateToDisplay = addHours(this.settingsService.getValue("businessEndHour"), this.settingsService.getValue("hoursAfterBusinessEndTimeToDisplay"));
      }),
      take(1)
    ).subscribe();


    this.form.get("employeeFormGroup").get("employeeRole").valueChanges.pipe(
      distinctUntilChanged(),
      filter(role => role !== null),
      map(role => (role as EmployeeRole)),
      filter(x => x.DocId() !== EmployeeRole.CustomRoleDocId),
      tap(role => this.employeeFormGroup.patchValue({scheduleFieldCallsFor: role.scheduleFieldCallsFor})),
      map(role => role.employeePermissions),
      tap(permissions => this.employeePermissionTreeService.updateSelectedNodes(permissions)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.form.get("employeeFormGroup").get("employeeLocation").valueChanges.pipe(
      distinctUntilChanged(),
      filter(location => location !== null && location !== undefined),
      tap(x => console.log(x,` string`)),
      map(location => (location as CompanyLocation).dispatchAddress),
      switchMap(location => this.companyLocationService.loadAll$().pipe(
        map(allLocations => {
          return {activeLocation: location, allOffices: allLocations as CompanyLocation[]};
        })),
      ),
      tap(location => {
        if ((location.allOffices as CompanyLocation[]).findIndex(x => x.dispatchAddress.DocId()
          === (this.form.get("employeeFormGroup").get("dispatchOrginAddress").value as Address).DocId() ) !== -1) {
          this.employeeFormGroup.patchValue({dispatchOrginAddress: location.activeLocation})
        }
        if ((location.allOffices as CompanyLocation[]).findIndex(x => x.dispatchAddress.DocId()
          === (this.form.get("employeeFormGroup").get("dispatchDestinationAddress").value as Address).DocId() ) !== -1) {
          this.employeeFormGroup.patchValue({dispatchDestinationAddress: location.activeLocation})
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }

  compareWithDocIds(o1:any, o2:any) {
    if (o1===null || o2===null || o1 === undefined || o2 === undefined) { return false; }
    if (o1.docId === o2.docId) {
      return true;
    } else {
      return false;
    }
  }

  NewEmployee() : void {

    const e = new Employee({dispatchOrginAddress : this.addressService.get(this.settingsService.getValue("dispatchAddress").DocId()),
      dispatchDestinationAddress: this.addressService.get(this.settingsService.getValue("dispatchAddress").DocId()), employeeLocation : this.companyLocations.find(x => x.default) });
    this.initialOriginAddress = e.dispatchOrginAddress;
    this.initialDestinationAddress = e.dispatchDestinationAddress;
    this.employeeService.retrieveDocId(e).pipe(
      tap(() => this.form.patchValue({employee: e}),
      take(1)
    )).subscribe();
  }

  defaultWorkWeek() : EmployeeAvailability[] {

    let activeDate = new Date(2021,2,7);
    const retVal : EmployeeAvailability[] = [];
    for (let i=0; i<7; i++) {
      retVal.push(new EmployeeAvailability({date: activeDate, activeWorkDay: !isWeekend(activeDate),
        workStartTime:  addMinutes(startOfDay(new Date()), getHours(this.settingsService.getValue("businessStartHour")) * 60 + getMinutes(this.settingsService.getValue("businessStartHour"))),
        workEndTime: addMinutes(startOfDay(new Date()), getHours(this.settingsService.getValue("businessEndHour")) * 60 + getMinutes(this.settingsService.getValue("businessEndHour"))),
      }));
      activeDate = addDays(activeDate,1);
    }
    return retVal;
  }

  GetTotalHoursScheduled(av: EmployeeAvailability[]) : string
  {
    let totalHours: number = 0;
    av.forEach(a => {
      if (a.activeWorkDay) {
        const h = differenceInHours(a.workEndTime, a.workStartTime);
        const m = differenceInMinutes(subHours(a.workEndTime,h),a.workStartTime)/60;
        totalHours += h + m;
      }
    });
    return `Available Hours for Employee Per Week: ${LineItem.timeDurationStringFromHours(totalHours)}`;
  }

  GetHoursScheduled(a : EmployeeAvailability) : string
  {
    const h = differenceInHours(a.workEndTime, a.workStartTime);
    const m = differenceInMinutes(subHours(a.workEndTime,h),a.workStartTime)/60;
    return LineItem.timeDurationStringFromHours(h+m);
  }

  workWeekToRender() : EmployeeAvailability[] {
    if (this.form.get("employee").value === null) {
      return this.weekWorthOfDays;
    } else {
      return this.employeeFormGroup.get("employeeAvailibility").value;
    }
  }

  Cancel() {

    const currentEmployee = this.employeeService.getCloneOfCachedValue(this.form.get("employee").value.DocId());
    this.employeeFormGroup.reset();
    this.form.patchValue({employee: currentEmployee});
  }

  employeeAvailibilityValidates() : boolean {
    let valid = true;
  (this.employeeFormGroup.get("employeeAvailibility").value as EmployeeAvailability[]).forEach(a => valid = valid && a.startEndTimesValidate);
  return valid;
  }

  Save() {

    if (this.form.valid && this.form.get("employee").value !== null && this.employeeAvailibilityValidates()) {
      this.snackBar.open("Saving Updates",undefined);
      const initialActiveEmployeeAvailibility = this.form.get('employee').value?.employeeAvailibility;
      this.patchEmployeeFormGroupToEmployee();
      const e = this.form.get("employee").value as Employee;
      // current employee avail. needs set to active.
      e.employeeAvailability.forEach(a => a.active = true);

      if (this.createUserAccountOnSave || this.sendVerificationEmailOnSave) {
        console.log(this.createUserAccountOnSave,this.sendVerificationEmailOnSave);

        const createUserAccount = this.authService.createOrUpdateAuthAccount(e.email, e.name, e.guid).pipe(
          map(x => (x as {result:string, uid:string})),
          share()
        );

        const CreateUserAccount = createUserAccount.pipe(
          filter(x => x.result === "Sucess"),
          map(x => {
            e.guid = x.uid;
            return e;
          }),
          switchMap(x => this.employeeService.update$(x)),
          tap(() => {
            this.createUserAccountOnSave = false;
            this.sendVerificationEmailOnSave = false;
          })
        );

        const failCreateUserAccount = createUserAccount.pipe(
          filter(x => x.result !== "Sucess"),
          tap(x => window.alert("Failed to create user account " + x.result)),
        );


        merge(CreateUserAccount,failCreateUserAccount).pipe(
          tap(() => this.snackBar.dismiss()),
          take(1)
        ).subscribe();

      } else {
        if (this.updateUserActiveOnSave) {
          this.authService.updateUserActive(e.guid,e.active).pipe(
            switchMap(x => this.employeeService.update$(e)),
          take(1)
        ).subscribe(() => this.snackBar.dismiss());
        } else {
          console.log(e);
          this.employeeService.update$(e).pipe(
            take(1)
          ).subscribe(() => this.snackBar.dismiss());
        }
        this.createUserAccountOnSave = false;
        this.sendVerificationEmailOnSave = false;
      }
      if (initialActiveEmployeeAvailibility && initialActiveEmployeeAvailibility.length > 0) {
        const updatedAvail = initialActiveEmployeeAvailibility.filter(i => e.employeeAvailabilityDocIds.indexOf(i.docId) < 0);
        if (updatedAvail.length > 0) {
          const obs = updatedAvail.map(u => {
            u.active=false;
            return this.employeeAvailibilityService.update$(u);
          });
          zip(...obs).pipe(
            take(1)
          ).subscribe();
        }
      }

      if (this.initialOriginAddress.DocId() !== e.dispatchOrginAddress.DocId() || this.initialDestinationAddress.DocId() !== e.dispatchDestinationAddress.DocId()) {
        setTimeout( () =>
        window.alert("Dispatch address has been updated for employee.  Please note it may take a moment to update commute times to / from new address on schedule.")
        , 150);
        if (this.initialOriginAddress.DocId() !== e.dispatchOrginAddress.DocId()) {
          this.physicalAddressRoutingService.updateAddressServerSide(this.initialOriginAddress.DocId(), e.dispatchOrginAddress.DocId()).pipe(
            take(1)
          ).subscribe();
        }
        if (this.initialDestinationAddress.DocId() !== this.initialOriginAddress.DocId() && this.initialDestinationAddress.DocId() !== e.dispatchDestinationAddress.DocId()) {
          this.physicalAddressRoutingService.updateAddressServerSide(this.initialDestinationAddress.DocId(), e.dispatchDestinationAddress.DocId()).pipe(
            take(1)
          ).subscribe();
        }
        this.initialDestinationAddress = e.dispatchDestinationAddress;
        this.initialOriginAddress = e.dispatchOrginAddress;
      }

      this.updateUserActiveOnSave = false;
    }
  }

  Test() {


    const e = this.form.get("employee").value as Employee;
    this.employeePermissionBuilder.addAbilitiesFromPermissions(this.form.get("employeeFormGroup").get("employeePermissions").value);
    const siteVisit = subject.bind(null,'SiteVisit');
    const oldDay = subDays(new Date(), 2);
    const rule = this.abilityService.relevantRuleFor('read',siteVisit({_startDate: oldDay}));
    console.log(rule);
    const r = this.abilityService.relevantRuleFor('create','Job');
    console.log(r);


  }
  public displayAddressSelected(value) {
    return  (value !== undefined && value !== null && value !== "") ? value.formattedAddress() : '';
  }

  createEmployeeFormGroup() : UntypedFormGroup {
    const retVal = this.fb.group ({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phoneNumber: [""],
      employeeRole: ["", [Validators.required]],
      employeeLocation: [null, [Validators.required]],
      employeeSkills: [[]],
      employeeAvailibility: [[]],
      employeePermissions: [[]],
      dispatchOrginAddress: [null],
      dispatchOrginAddressDisplay: [],
      dispatchDestinationAddress: [null],
      dispatchDestinationAddressDisplay: [],
      startEndAddressDisplay: "",
      startEndAddressUnit : "",
      active: true,
      scheduleFieldCallsFor: true,
      dispatchOrginAddressCommuteDispensation: [],
      dispatchDestinationAddressCommuteDispensation: [],
    });
    retVal.disable()
    return retVal;
  }

  compareActive(f1: any, f2: any): boolean {
    if (f1===null || f2 === null) {
      return false;
    }
    return (f1 === "true" && f2 === true) || (f1 === "false" && f2 === false);
  }

  UpdateAddress(addressFieldName: string) : void {

  const editorConfig = new MatDialogConfig();
      Object.assign(editorConfig, {
        disableClose : false,
        autoFocus    : true,
        width        : '700px',
        outerHeight  :  '200px',
        data         :
        {
          title: `Update ${addressFieldName}`,
          searchSources: [searchSource.GooglePlaceAutoComplete],
          provideAddressToShopRoutingInfo: false,
          provideJobCountAtAddressInfo: false
        }
        });
      this.updateAddressModalRef = this.dialog.open(AddressSearchModalComponent, editorConfig);
      this.updateAddressModalRef.afterClosed().pipe(
        filter(x => x !== undefined),
        tap(address => {
          this.physicalAddressRoutingService.addressNeedsPopulatedToSchedule$.next(address);
          if (addressFieldName === "Starting Address") {
            this.employeeFormGroup.patchValue({dispatchOrginAddress: address});
          } else if (addressFieldName === "Ending Address") {
            this.employeeFormGroup.patchValue({dispatchDestinationAddress: address});
          }
        }),
        take(1)
      ).subscribe();
  }

  CheckListDatabase() : ChecklistDatabase<EmployeePermissionTreeStructure> {
    if (this._checkListDatabase === undefined) {
      this._checkListDatabase = new ChecklistDatabase<EmployeePermissionTreeStructure>("Employee Privileges",
      this.employeePermissionTreeService.allNodes,this.employeePermissionTreeService.selectedNodes, (x=>x.parentCatagoryKey),(x => x.name),(x=>x.key), (x=>x.description)
      );
    }
    return this._checkListDatabase;
  }

}
