import {AfterViewInit, ChangeDetectionStrategy,ChangeDetectorRef,Component,OnDestroy,OnInit,QueryList,ViewChild,ViewChildren,} from "@angular/core";
import { BehaviorSubject, from, merge, Observable, ReplaySubject, Subject } from "rxjs";
import { ReportService } from "common/src/data/dao-services/report.service";
import {delay, filter,map,switchMap,take,takeUntil,tap,} from "rxjs/operators";
import { GenericServiceProviderSettingService } from "../../../../common/src/data/dao-services/generic-service-provider-setting.service";
import { GenericServiceProviderSetting } from "../../../../common/src/data/dao/generic-service-provider-setting";
import { MatTable } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import { subDays } from "date-fns";
import { MatPaginator } from "@angular/material/paginator";
import { Report } from "../../../../common/src/data/dao/report";
import { AuthenticationService } from "../../../../common/src/util/authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-report-table-view",
  templateUrl: "./report-table-view.component.html",
  styleUrls: ["./report-table-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTableViewComponent implements OnInit, AfterViewInit, OnDestroy {
  data: Subject<any> = new Subject<any>();
  displayedColumns: Subject<string[]> = new Subject<string[]>();
  schema: any;
  populatedGenericServiceProviderSettings$: ReplaySubject<GenericServiceProviderSetting[]> = new ReplaySubject<GenericServiceProviderSetting[]>(1);
  genericSettingDocIds: Set<string> = new Set<string>();
  genericSettingDocIdsToLoad$: Subject<string[]> = new Subject<string[]>();
  tagFieldsActiveReport: string[] = [];
  activeReport: Report = undefined;
  activeReport$: ReplaySubject<Report> = new ReplaySubject<Report>(1);
  switchedReport$: Subject<null> = new Subject<null>();
  resultsCounter: number = 0;
  retrievingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeReports$: Subject<Report[]> = new Subject<Report[]>();

  lastVisibleForPage: Map<number,any> = new Map<number,any>();
  updatedPageSize: number = undefined;

  startDate = new FormControl(subDays(new Date(),365));
  endDate = new FormControl(new Date());
  selectedReport = new FormControl<Report>(undefined);

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  get table(): MatTable<any> { return this.tables?.first;}

  destroyingComponent$ = new Subject();

  constructor(private reportService: ReportService,public genericServiceProviderSettingsService: GenericServiceProviderSettingService,private ref: ChangeDetectorRef,
    private auth: AuthenticationService, private http: HttpClient) {}


  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
    }

    retrieveColumnName(fieldName: string) : string {
      return fieldName.replace("HREF","");
    }

    docComparer(a:Report, b:Report) {
      return a && b && a.docId && b.docId && a.docId === b.docId;
    }

  ngAfterViewInit(): void {

    this.reportService.loadAll$().pipe(
      delay(1),
      tap(x => this.activeReports$.next(x)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

      //when the start or end date changes, we must re-load the dataset.
      merge(this.startDate.valueChanges, this.endDate.valueChanges).pipe(
        filter(() => this.activeReport !== undefined),
        tap(x => this.resetPagination()),
        switchMap(() => this.reportService.retrieveReportResultCount(this.activeReport, this.startDate.value, this.endDate.value)),
        tap(x => this.resultsCounter = x.data().count),
        tap(() => this.refreshData(this.activeReport)),
        takeUntil(this.destroyingComponent$)
      ).subscribe();

      this.selectedReport.valueChanges.pipe(
        tap(() => this.switchedReport$.next(null)),
        tap(x => this.loadReport(x.docId)),
        takeUntil(this.destroyingComponent$)
      ).subscribe();


      this.paginator.page
      .pipe(
        tap(x => {
          if(this.updatedPageSize && x.pageSize !== this.updatedPageSize) {
            this.resetPagination();
          }
          this.updatedPageSize = x.pageSize;
        }),
        tap(x => {
          if (this.activeReport) {
          this.refreshData(this.activeReport)
          }
        }),
      ).subscribe();
  }

  resetPagination() {
    this.paginator.firstPage();
    this.lastVisibleForPage.clear();
  }

  generateUpdatedDataForReport(reportDocId: string) {
    const url = "https://r-genbasicfirestorereport-buwvrbjm3q-uc.a.run.app";
    // const url = "http://localhost:5001/service-vanguard/us-central1/r-genbasicfirestorereport"
    from(this.auth.afAuth.currentUser).pipe(
      switchMap(currentUser =>
        from(currentUser.getIdToken()).pipe(
          map(tkn => ({token: tkn, user: currentUser }))
        )),
      map(userData => {
        return { query: {
          initiatingUuid: userData.user.uid,
          bucketId: this.auth.bucketId,
          queryDocId: reportDocId,
        },
        headers: new HttpHeaders()
          .set('Authorization', userData.token)
          .set('Content-Type', 'application/json')
        };
      }),
      tap(x => console.log(`Posting Report Generation`)),
      switchMap(x => this.http.post(`${url}?initiatingUuid=${x.query.initiatingUuid}&bucketId=${x.query.bucketId}&queryDocId=${x.query.queryDocId}`,
      {headers: x.headers})),
      take(1)
    ).subscribe();
  }

  RegenerateReport() {
    this.generateUpdatedDataForReport(this.activeReport.docId);
  }

  loadReport(reportDocId: string) {
    this.data.next([]);
    this.resetPagination();
    this.reportService.load$(reportDocId).pipe(
      tap((x) => {
        this.activeReport = x;
        const s = JSON.parse(x.schema);
        this.schema = s;
        this.displayedColumns.next(s.fields.map((x) => x.name).filter(x => x !== "primaryKey" && x.includes("IGNORE") === false));
        this.tagFieldsActiveReport = s.fields.map((x) => x.name)
          .filter(x => x.includes("Tags"));
      }),
      switchMap(r => this.reportService.retrieveReportResultCount(r, this.startDate.value, this.endDate.value).pipe(
        tap(x => this.resultsCounter = x),
        map(x => r)
      )),
      tap(r => this.refreshData(r)),
      tap(x => this.activeReport$.next(x)),
      takeUntil(merge(this.destroyingComponent$, this.switchedReport$))
    ).subscribe();
  }

  refreshData(report: Report) {
    this.reportService.retrieveReportResults(report, this.startDate.value, this.endDate.value, this.paginator.pageSize,
          this.lastVisibleForPage.get(this.paginator.pageIndex-1)).pipe(
      tap(x => this.lastVisibleForPage.set(this.paginator.pageIndex,x[x.length - 1])),
      map((x) => x.map((y) => this.PopulateDatesInObjectAsAppropriate(y))),
      tap((x) => x.forEach((y) => this.PopulateTagsInObjectAsAppropriate(y))),
      switchMap(x => this.genericServiceProviderSettingsService.loadMultiple$([...this.genericSettingDocIds]).pipe(
        tap(y => this.populatedGenericServiceProviderSettings$.next(y)),
        map(y => x)
      )),
      tap(x => this.data.next(x)),
      tap(() => this.table.renderRows())
    ).subscribe();
  }

  ngOnInit(): void {
  }

  retrieveTag(tagId: string): Observable<GenericServiceProviderSetting> {
    return this.populatedGenericServiceProviderSettings$.pipe(
      map((x) => x.filter((y) => tagId === y.docId)[0]),
    );
  }

  getLink(hrefType: string) {
    return JSON.parse(hrefType).href;
  }
  getUserText(hrefType: string) {
    return JSON.parse(hrefType).text;
  }

  retrieveDisplayType(columnName: string): string {
    const field = this.schema.fields.find((x) => x.name === columnName);
    if (columnName.includes("Tags")) {
      return "tags";
    }
    if ( columnName.indexOf("HREF") > -1) {
      return "href";
    }
    if (field.type === "TIMESTAMP") {
      return "date";
    } else {
      return "string";
    }
  }

  protected PopulateTagsInObjectAsAppropriate(o: object): void {
    this.tagFieldsActiveReport.forEach(tag => {
      if (Array.isArray(o[tag])) {
        o[tag]=o[tag].filter(x => x !== "");
        (o[tag] as Array<string>).forEach((x) =>
          this.genericSettingDocIds.add(x)
        );
      } else {
        if (o[tag] === "") {
          o[tag]=null;
        }
        this.genericSettingDocIds.add(o[tag]);
      }
    });
  }

  protected PopulateDatesInObjectAsAppropriate(o: object): object {
    for (const prop in o) {
      if (o[prop] && o[prop].toDate) {
        o[prop] = o[prop].toDate();
      }
    }
    return o;
  }
}
