// import { AbilityBuilder } from '@casl/ability';
// import { AppAbility } from '../../../../web-app/src/app/AppAbility';
import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';
import { randomElementName } from '../../util/util';


export class EmployeePermission extends RetrieveFirestorePropertiesBase<EmployeePermission>  {

    name: string;
    parentDocId: string;
    active = true;
    rootPermission=false;
    description: string;
    displayType: string;
    index: number;
    permissionType: string = "static";

    abilityString: string = "";

    public constructor(init?: Partial<EmployeePermission>) {
      super();
      Object.assign(this, init);
    }



    // static buildEmployeeAbilities() : void {
    //   const builder = new AbilityBuilder(AppAbility);
    //   builder.can('create','Estimate');
    //   builder.cannot('delete','EmployeeSchedule');
    // }


    retrieveFirestoreIgnoredMemberNames() : string[] { return super.retrieveFirestoreIgnoredMemberNames();}
    retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return super.retrievefirestoreIgnoreDiffTrackingMembers();}
    retrievefirestoreCompositionalDiffMemberNames() : string[] {return super.retrievefirestoreCompositionalDiffMemberNames();}
    retrievefirestoreCompositionMemberNames() : string[] { return super.retrievefirestoreCompositionMemberNames();}

  }

  export class EmployeePermissionDateRange extends EmployeePermission {
    permissionType: string = "dateRange";
    numberDaysBack: number;
    numberDaysForward: number;
    action: string;
    subject: string;
    prototypePermissionDocId: string = randomElementName();

    public constructor(init?: Partial<EmployeePermissionDateRange>) {
      super();
      Object.assign(this, init);
    }
  }
