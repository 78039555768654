import { Injectable } from '@angular/core';
;
import {  zip } from 'rxjs';
import { delay, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { FormFirestore } from '../dao/form-firestore';
import { FormFirestoreSummaryService } from '../dao-services/form-firestore-summary.service';
import { where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FormFirestoreService extends DatabaseStoreService<FormFirestore> {

  deactivateAll() {
    this.formFirestoreSummaryService.queryFirestoreDeep$([where("active","==",true)]).pipe(
      delay(5000),
      // skip(1),
      take(1),
      tap(x => console.log(x,` string`)),
      map(x => {
        const retVal = [];
        for (const item of x) {
          item.active = false;
          retVal.push(item);
        }
        return retVal.map(q => this.formFirestoreSummaryService.update$(q));
      }),
      switchMap(z => zip(...z))).pipe(
        tap(() => console.warn("deactivateAll"))
      ).subscribe();
  }
  constructor( fs: FormFirestoreFirestoreService, authenticationService: AuthenticationService, store: FormFirestoreStoreService, private formFirestoreSummaryService: FormFirestoreSummaryService) {
    super(fs, store, authenticationService, new Map([
      ["formSummary", {associatedDocumentId: "formSummaryDocId", compositionStoreService: formFirestoreSummaryService}]
    ]));
  }
}


@Injectable({
  providedIn: 'root'
})
export class FormFirestoreStoreService extends StateChangeStoreService<FormFirestore> {
  protected store = "form-firestore";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, FormFirestore>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class FormFirestoreFirestoreService extends FirestoreBackend<FormFirestore> {

  protected basePath = "form_firestore";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): FormFirestore {
    return new FormFirestore(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new FormFirestore(), authService);
  }
}
