<ng-container *ngIf="deviceInfo$ | async">
<div class="line-item-container">
  <table mat-table [dataSource]="sortedLineItems$ | async"  multiTemplateDataRows class="mat-elevation-z8" style="background-color: rgba(0,0,0,0);">

<!-- Collapsed Content View. -->
<ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplayStandardText">
  <th mat-header-cell class="w-75" *matHeaderCellDef> {{columnsToDisplayHumanReadable.get(column)}} </th>
  <td class="line-item-border-top w-75" mat-cell *matCellDef="let lineItem;" [ngClass]="classesForRows(lineItem)">{{lineItem[column]}}</td>
  <td style="background-color: #e8e8e8;" class="w-75" mat-footer-cell *matFooterCellDef>
    <ng-container [ngSwitch]="column">
      <div *ngSwitchCase="'title'" >Total</div>
      <div *ngSwitchCase="'timeDurationString'">{{totalDuration$ | async}}</div>
      <div *ngSwitchDefault></div>
    </ng-container>
  </td>
</ng-container>

<ng-container matColumnDef="docId">
  <th mat-header-cell *matHeaderCellDef class="w-75">D </th>
  <td class="line-item-border-top bullocks" class="w-75" mat-cell *matCellDef="let lineItem" >{{lineItem.DocId() }} </td>
  <td style="background-color: #e8e8e8;" class="w-75" mat-footer-cell *matFooterCellDef> </td>
</ng-container>

<!-- Currency piped to display properly.-->
<ng-container matColumnDef="price">
  <th mat-header-cell *matHeaderCellDef class="w-75">Price </th>
  <td class="line-item-border-top w-75" mat-cell *matCellDef="let lineItem" [ngClass]="classesForRows(lineItem)">{{getTotalPriceLineItem(lineItem) | currency}} </td>
  <td style="background-color: #e8e8e8;" class="w-75" mat-footer-cell *matFooterCellDef> {{totalPrice$ | async | currency}} </td>
</ng-container>


<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
<ng-container matColumnDef="expandedDetail">
  <td mat-cell class="w-75" *matCellDef="let lineItem" [attr.colspan]="columnsToDisplay.length" style="border-bottom: 0px solid lightgray;" [ngClass]="classesForRows(lineItem)">
    <div class="example-element-detail" fxLayout="column" [@detailExpand]="expandedElementContainsLineItem(lineItem) ? 'expanded' : 'collapsed'">
      <div class="drop-down-layout">
        <div style="display: flex; grid-column: 1/3; grid-row: 3/4;" class="donotprint">
        <button style="margin-right: 10px;" mat-button class="button-green" disabled={{editLineItemDisabled(lineItem)}} type="button" (click)=editLineItemClicked(lineItem)>Edit</button>
        </div>

        <div style="grid-column: 3/5; grid-row: 3/4;; display: flex; justify-content: right;" class="donotprint"> <!--THIS IS THE REMOVE BUTTON-->
          <ng-container
          [ngTemplateOutlet]='lineItemControlType === 2 && lineItemControlContext ===1 ? removeFromActiveWorkToCompleteTemplate : defaultRemovalButtonTemplate'
          [ngTemplateOutletContext]="{lineItem:lineItem}"
          ></ng-container>
        </div>

        <!-- <div style="display: flex; width: 100%; flex-direction: column;"> -->
      <ng-container *ngIf="lineItem.paymentType; else defaultDetailsBlock">
        <div style="grid-column: 1/2; grid-row: 1/2;" class="description-internal-label">Payment Details</div>
          <div style="grid-column: 2/5; grid-row: 1/2;">
          <div class="description">{{lineItem.description}}</div>
        </div>
        <div style="grid-column: 1/2; grid-row: 2/3;" class="description-internal-label donotprint">Internal Notes:</div>
        <div *ngIf="intendedAudience === 2" class="internalAudience" class="donotprint">
          <div class="description" style="grid-column: 2/5; grid-row: 2/3;">{{lineItem.internalNotes}}</div>
        </div>
      </ng-container>

      <ng-template #defaultDetailsBlock>
        <div style="grid-column: 1/2; grid-row: 1/2;" class="description-internal-label">Description</div>
          <div style="grid-column: 2/5; grid-row: 1/2;">
          <div class="description">{{lineItem.description}}</div>
        </div>
        <div style="grid-column: 1/2; grid-row: 2/3;" class="description-internal-label donotprint">Internal Notes:</div>
        <div *ngIf="intendedAudience === 2" class="internalAudience" class="donotprint" style="grid-column: 2/5; grid-row: 2/3;">
          <div class="description">{{lineItem.internalNotes}}</div>
        </div>
      </ng-template>



    </div>
      <div *ngIf="lineItem.discountType">
        {{lineItem.discountType}}: {{lineItem.discountType === 'Percentage' ? lineItem.discountPercentage : lineItem.discountFixedAmount}}
      </div>
    </div>
  </td>
</ng-container>

<ng-template #defaultRemovalButtonTemplate let-lineItem="lineItem">
  <button mat-button class="button-red" style="grid-column: 3/5; grid-row: 3/4;" type="button" disabled={{removeLineItemDisabled(lineItem)}} (click)=removeLineItemUnknown(lineItem) [ngStyle]="hideRemoveWhenUnneeded(lineItem)">Remove</button>
</ng-template>

<!-- Line items to complete remove button behavior is different for field tech usage. -->
<ng-template #removeFromActiveWorkToCompleteTemplate let-lineItem="lineItem">
  <button mat-button [disabled]="disabled" [matMenuTriggerFor]="lineItemToCompleteExtraActionMenu">
    <mat-icon>menu</mat-icon>
    <span>More Actions</span>
  </button>

<mat-menu #lineItemToCompleteExtraActionMenu="matMenu" >
  <button mat-menu-item (click)="invoiceWithoutCompletion(lineItem)" disabled={{addToInvoiceDisabled(lineItem)}}>
    <mat-icon style="color: green;">spoke</mat-icon>
    <span>Add to Invoice</span>
  </button>
  <button mat-menu-item (click)=removeFromInvoice(lineItem) disabled={{removeFromInvoiceDisabled(lineItem)}}>
    <mat-icon style="color: green;">volcano</mat-icon>
    <span>Remove from Invoice</span>
  </button>
  <button *ngIf="displayRestoreMenu(lineItem); else removeMenu" mat-menu-item disabled={{restoreLineItemDisabled(lineItem)}}  (click)="restoreInactiveLineItems(lineItem)">
    <mat-icon style="color: green;">real_estate_agent</mat-icon>
    <span>Restore</span>
  </button>

  <ng-template #removeMenu>
    <button mat-menu-item disabled={{removeLineItemWillNotCompleteDisabled(lineItem)}} (click)="removeLineItemWillNotComplete(lineItem)">
      <mat-icon style="color: #428BCA;">vaccines</mat-icon>
      <span>Will Not Complete</span>
    </button>
      <button mat-menu-item  disabled={{removeLineItemRescheduleDisabled(lineItem)}} (click)="removeLineItemReschedule(lineItem)">
        <mat-icon style="color: #428BCA;">settings_input_composite</mat-icon>
      <span>Reschedule</span>
      </button>
  </ng-template>
</mat-menu>
</ng-template>

<!-- Completed Work -->
<ng-container matColumnDef="addToInvoice">
  <th mat-header-cell class="w-75" *matHeaderCellDef>
    Completed
  </th>
  <td class="line-item-border-top w-75" mat-cell *matCellDef="let lineItem"
  selection-model="completedSelection" [ngClass]="classesForRows(lineItem)">
    <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? completedSelection.toggle(lineItem.DocId()) : null"
                  [checked]="completedSelection.isSelected(lineItem.DocId())"
                  disabled={{addToInvoiceDisabled(lineItem)}}
                  >
                  Completed</mat-checkbox>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell class="donotprint w-75" *matFooterCellDef>
    <div style="display: flex; justify-content: flex-end;">
      <button mat-button class="button-blue do-not-print-this" (click)="invoiceSelected()" disabled={{disabled}} type="button">Invoice Selected</button>
      </div>
  </td>
</ng-container>

<!-- Add work SP intends to complete -->
<ng-container matColumnDef="addToWorkToComplete">
  <th mat-header-cell class="w-75" *matHeaderCellDef class="donotprint">
    Add To Job
  </th>
  <td class="line-item-border-top w-75" mat-cell *matCellDef="let lineItem" selection-model="completedSelection" [ngClass]="classesForRows(lineItem)" class="donotprint line-item-border-top">
    <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? completedSelection.toggle(lineItem.DocId()) : null"
                  [checked]="completedSelection.isSelected(lineItem.DocId())"
                  disabled={{addToActiveJobDisabled(lineItem)}}>
                  {{workToCompleteFromEstimateLabel()}}</mat-checkbox>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef class="donotprint w-75">
    <div style="display: flex; justify-content: flex-end;">
    <button mat-button class="button-blue do-not-print-this" (click)="activateSelectedItems()" disabled={{addToActiveJobDisabled(null)}} type="button">{{workToCompleteFromEstimateButtonLabel()}}</button>
    </div>
  </td>
</ng-container>

<!-- Add line item -->
<ng-container matColumnDef="addLineItem">
  <td mat-footer-cell *matFooterCellDef colspan="4" class="donotprint w-75">
    <!-- Invoices to display do not allow line items to be added ( but discounts can be ) -->
    <div style="display: flex; justify-content: end;">
      <div class="add-line-item-button" class="donotprint">
        <button mat-button class="button-blue do-not-print-this" type="button" (click)=lineItemCreation(null) disabled={{addLineItemDisabled(null)}}>Add Line Item</button>
      </div>
    <!-- Line items to complete have no idea of discounts. -->
      <div class="add-line-item-button do-not-print-this" *ngIf="lineItemControlType!==2" style="margin-left: 65px;" class="donotprint">
        <button mat-button class="button-red do-not-print-this" type="button" (click)=discountCreation(null) disabled={{addDiscountDisabled(null)}} [ngStyle]="hideAddDiscountWhenUnneeded()">Add Discount</button>
      </div>
    </div>
  </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="columnsToDisplay" style="height: 40px;"></tr>
<tr mat-row *matRowDef="let lineItem; columns: columnsToDisplay;"
    class="example-lineItem-row"
    [class.example-expanded-row]="expandedElementContainsLineItem(lineItem)"
    (click)="toggleExpandedElementView(lineItem)" style="height: 40px;">
</tr>
<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
<!-- <tr mat-row *matRowDef="let row; columns: []" style="height: 0px;"></tr> -->
<tr mat-footer-row *matFooterRowDef="columnsToDisplay" style="height: 40px; border-left: none; border-right: none;"></tr>
<tr mat-footer-row *matFooterRowDef="footerSecondRowColumns" class="example-second-footer-row donotprint" style="border-top: none; border-left: none; border-right: none;"></tr>
</table>
</div>
</ng-container>
