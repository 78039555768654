import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, mergeMap, share, skip, startWith, switchMap,  take, takeUntil, tap } from 'rxjs/operators';
import { JournalEntry } from '../../../../common/src/data/dao/journal-entry';
import { JournalEntryDisplayComponent } from '../accounting/journal-entry-display/journal-entry-display.component';
import { AddCustomerComponent } from '../add-customer/add-customer.component';
import { AddressSearchModalComponent } from '../address_search/address-search-modal/address-search-modal.component';
import { CustomTagsService } from '../custom-tags.service';
import { CustomTagComponentInputs, CustomTagComponentOutputs } from '../custom-tags/custom-tags.component';
import { Customer } from '../../../../common/src/data/dao/customer';
import { CustomerCommunicationDisplayModalComponent } from '../customer-communication/customer-communication-display-modal/customer-communication-display-modal.component';
import { Estimate } from '../../../../common/src/data/dao/estimate';
import { EstimateService } from '../../../../common/src/data/dao-services/estimate.service';
import { GenericServiceProviderSetting } from '../../../../common/src/data/dao/generic-service-provider-setting';
import { JobService } from '../../../../common/src/data/dao-services/job.service';
import { Job } from '../../../../common/src/data/dao/job';
import { ReferralCampaignService } from '../../../../common/src/data/dao-services/referral-campaign.service';
import { randomElementName } from '../../../../common/src/util/util';
import { ReferralCampaign } from '../../../../common/src/data/dao/referral-campaign';
import { Invoice } from '../../../../common/src/data/dao/invoice';
import { CustomerService } from '../../../../common/src/data/dao-services/customer.service';
import {CustomerCommunicationService} from '../../../../common/src/data/dao-services/customer-communication.service';
import { GenericServiceProviderSettingService } from '../../../../common/src/data/dao-services/generic-service-provider-setting.service';
import {InvoiceService} from '../../../../common/src/data/dao-services/invoice.service';
import {JournalEntryService} from '../../../../common/src/data/dao-services/journal-entry.service';
import { limit, orderBy, where } from 'firebase/firestore';
import { CustomerSearchService } from '../../../../common/src/data/services/customer-search.service';
import { searchSource } from '../../../../common/src/data/services/search.service';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  styleUrls: ['./customer-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerPageComponent implements OnInit, OnDestroy {

  jobsAssociatedWithCustomer$: Observable<Job[]>;
  customerTypes$: BehaviorSubject<GenericServiceProviderSetting[]> = new BehaviorSubject<GenericServiceProviderSetting[]>([]);

  activeReferralCampaigns$!: Observable<ReferralCampaign[]>;

  emailsCheckedForDuplication = new Set<string>();
  phoneNumbersCheckedForDuplication = new Set<string>();
  activeDuplicationSearchTerm = "";

  tags: GenericServiceProviderSetting[] = [];
  customTagComponentInputs : CustomTagComponentInputs;
  customTagComponentOutputs : CustomTagComponentOutputs;
  invoices: BehaviorSubject<Invoice[]> = new BehaviorSubject<Invoice[]>([]);
  estimates: BehaviorSubject<Estimate[]> = new BehaviorSubject<Estimate[]>([]);

  viewJournalEntriesDialogRef: MatDialogRef<JournalEntryDisplayComponent>;
  updateAddressModalRef: MatDialogRef<AddressSearchModalComponent>;
  createNewCustomerDialogRef: MatDialogRef<AddCustomerComponent>;
  viewCustomerCommuncationsModalRef: MatDialogRef<CustomerCommunicationDisplayModalComponent>;
  destroyingComponent$ = new Subject();

  activeCustomer: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);

  form: UntypedFormGroup;

  constructor(private route: ActivatedRoute, private customerService: CustomerService, private tagService: CustomTagsService,
    private jobService: JobService, private invoiceService: InvoiceService, private estimateService: EstimateService,
    private journalEntryService: JournalEntryService, private dialog: MatDialog, private fb: UntypedFormBuilder,
    private genericServiceProviderSettingService: GenericServiceProviderSettingService,
    private referralCampaignService: ReferralCampaignService, private customerSearchService: CustomerSearchService,
    private customerCommuncationService: CustomerCommunicationService,
    private router: Router, private authService: AuthenticationService) {
      this.buildForm();
      this.genericServiceProviderSettingService.allCustomerTypes.pipe(
        map(x => x.filter(y => y.active)),
        takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.customerTypes$.next(x));

      this.activeReferralCampaigns$= this.referralCampaignService.allActive;
    }


  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  onEmailFocusOut() {
    //check for duplication first time valid email entered.
    const email = this.form.value["customerEmail"];
    if (this.form.controls["customerEmail"].status === "VALID" && !this.emailsCheckedForDuplication.has(email)) {
      this.customerSearchService.addSearch({search: email, componentSource: "customerUniqueIdentifierDuplicationCheck", searchSources: [searchSource.InternalCustomerEmail]});
      this.emailsCheckedForDuplication.add(email);
      this.activeDuplicationSearchTerm = email;
    }
  }

  onPhoneNumberFocusOut() {
     //check for duplication first time valid phone number entered.
    const phoneNumber = this.form.value["primaryPhoneNumber"];
    if (this.form.controls["primaryPhoneNumber"].status === "VALID" && !this.phoneNumbersCheckedForDuplication.has(phoneNumber)) {
      this.customerSearchService.addSearch({search: phoneNumber, componentSource: "customerUniqueIdentifierDuplicationCheck", searchSources: [searchSource.InternalCustomerPhone]});
      this.phoneNumbersCheckedForDuplication.add(phoneNumber);
      this.activeDuplicationSearchTerm = phoneNumber;
    }
  }

  compareCommonServiceProviderSettings(o1: any, o2: any): boolean {
    return o1!== undefined && o2 !== undefined && o1 !== null && o2 !== null &&  o1.docId === o2.docId;
  }

  compareReferralCampaigns(o1: any, o2: any): boolean {
    return o1!== undefined && o2 !== undefined && o1 !== null && o2 !== null &&  o1.documentId === o2.documentId;
  }

  getCustomerWithRoleInformation() : {customer: Customer, role: string, primary: boolean} {
    return {customer: this.form.get('customer').value, role:"",primary:false};
  }

  buildForm() : void {
    this.form = this.fb.group({
      customer: [],
      billingAddress: [],
      billingAddressDisplay: ["",[Validators.required]],
      billingAddressUnit:["",{updateOn: 'blur'}],
      customerType: ["", {updateOn: 'blur'}],
      company: ["", {updateOn: 'blur'}],
      notes: ["", {updateOn: 'blur'}],
      customerReferral: ["", {updateOn: 'blur'}],
      customerTags: [],
      customerName: ["", {validators: [Validators.required],updateOn: 'blur'}],
      customerEmail: ["", {validators: [Validators.email], updateOn: 'blur'}],
      primaryPhoneNumber: ["", {validators: [Validators.required], updateOn: 'blur'}],
      primaryPhoneNumberExtension: ["", {updateOn: 'blur'}],
      alternativePhoneNumber: ["", {updateOn: 'blur'}],
      alternativePhoneNumberExtension: ["", {updateOn: 'blur'}],
    });

    this.form.get("billingAddress").disable();
    this.form.get("billingAddressDisplay").disable();
    this.form.get("billingAddressUnit").disable();
  }

  patchUpdatesFromForm() : void {
    this.form.valueChanges.pipe(
      skip(1),
      filter(() => this.form.valid),
      map(val => {
        const customerToUpdate = val["customer"] as Customer;
        customerToUpdate.alternativePhoneNumber = val["alternativePhoneNumber"];
        customerToUpdate.alternativePhoneNumberExtension = val["alternativePhoneNumberExtension"];
        customerToUpdate.primaryPhoneNumber = val["primaryPhoneNumber"];
        customerToUpdate.primaryPhoneNumberExtension = val["primaryPhoneNumberExtension"];
        customerToUpdate.customerEmail = val["customerEmail"];
        customerToUpdate.customerName = val["customerName"];
        customerToUpdate.customerNotes = val["notes"];
        customerToUpdate.company = val["company"];
        customerToUpdate.customerType = val["customerType"];
        customerToUpdate.customerReferralCampaign = val["customerReferral"];
        customerToUpdate.customerTags = val["customerTags"];
        customerToUpdate.address = this.form.get('billingAddress').value;
        return customerToUpdate;
      }),
      tap(x => console.log(x,` string`)),
      switchMap(customer => this.customerService.update$(customer)),
      takeUntil(this.destroyingComponent$)
      ).subscribe()
  }

  patchToForm(c: Customer) : void {
    this.emailsCheckedForDuplication.add(c.customerEmail);
    this.phoneNumbersCheckedForDuplication.add(c.primaryPhoneNumber);
    this.form.patchValue({
      customer: c,
      billingAddress: c.address,
      billingAddressDisplay: c.address.formattedAddress(),
      billingAddressUnit: c.address.unit,
      customerType: c.customerType,
      company: c.company,
      notes: c.customerNotes,
      customerReferral: c.customerReferralCampaign,
      customerTags: c.customerTags,
      customerName: c.customerName,
      customerEmail: c.customerEmail,
      primaryPhoneNumber: c.primaryPhoneNumber,
      primaryPhoneNumberExtension: c.primaryPhoneNumberExtension,
      alternativePhoneNumber: c.alternativePhoneNumber,
      alternativePhoneNumberExtension: c.alternativePhoneNumberExtension,
    });
    console.log(this.form.value);
  }

  DisplayCustomerCommunicationModal() : void {

    const editorConfig = new MatDialogConfig();

    const endCustomerCommuncationObservable: Subject<any> = new Subject();

    const communications = this.customerCommuncationService.queryFirestoreDeep$([where('customerDocId', '==',
    this.form.value["customer"].DocId()),
    orderBy('createdAt','desc'),
    limit(100)]).pipe(
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      }),
      takeUntil(endCustomerCommuncationObservable)
    );

    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         :
      {
        dataSource: communications,
      }
      });

    this.viewCustomerCommuncationsModalRef = this.dialog.open(CustomerCommunicationDisplayModalComponent, editorConfig);

    this.viewCustomerCommuncationsModalRef.afterClosed().pipe(
      tap(() => endCustomerCommuncationObservable.next(null)),
      take(1)
    ).subscribe();
  }


  CreateNewCustomer(): void {

    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         :
      {
        searchExistingCustomers: true,
      }
      });

    this.createNewCustomerDialogRef = this.dialog.open(AddCustomerComponent, editorConfig);

    // If SP creates new customer, navigate to the newly created customer's page.
    const closedEditCustomerModal = this.createNewCustomerDialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      mergeMap(x => this.customerService.update$(x)),
      tap(x => this.router.navigate(['/app-customer-page', {customerDocId: x.DocId()}])),
      take(1)
    ).subscribe();
  }


  ngOnInit(): void {

    this.patchUpdatesFromForm();

    const customerObs : ReplaySubject<Customer> = new ReplaySubject(1);
    let firstEmissionPatched = false;
    this.route.paramMap.pipe(
      map(params => params.get("customerDocId")),
      distinctUntilChanged(),
      switchMap(docId => this.customerService.load$(docId)),
      filter(x => x !== null),
      tap(c => {
        this.activeCustomer.next(c);
        if (!firstEmissionPatched || c.lastUpdatedByGuid !== this.authService.guid ) {
          firstEmissionPatched = true;
          this.patchToForm(c);
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe(cust => customerObs.next(cust));

    // Patch in customer tags.
      const patchCustomerTags$ = customerObs.pipe(
      tap(c => {
        this.tags.splice(0);
        (c.customerTags as GenericServiceProviderSetting[]).forEach(a => this.tags.push(a));
      }));

      // Load estimates associated w/ customer.
      const loadCustomerEstimates$ = customerObs.pipe(
      switchMap(x => this.estimateService.queryFirestoreDeep$([where("customerDocId", "==", x.DocId())])),
      map(e => e.filter(e => e.lineItems.length > 0)),
      tap(x => this.estimates.next(x)));

      merge(patchCustomerTags$,loadCustomerEstimates$).pipe(
        takeUntil(this.destroyingComponent$)
      ).subscribe();

    // Load jobs associated w/ customer.
      const billingJobs = merge(customerObs.pipe(
        switchMap(c => this.jobService.queryFirestoreDeep$([where('billingCustomerDocIds',"array-contains",c.DocId())]))),
        // route param map is merged in to cause reset of jobs when customer is changed by routing w/ back / forward arrows.
        this.route.paramMap.pipe(map(() => [])));

      const contactJobs = merge(customerObs.pipe(
        switchMap(c => this.jobService.queryFirestoreDeep$([where('siteVisitContactCustomerDocIds',"array-contains",c.DocId())]))),
        this.route.paramMap.pipe(map(() => [])));

    this.jobsAssociatedWithCustomer$ = combineLatest([billingJobs,contactJobs]).pipe(
          map( ([a,b]) => {
            const retVal = a;
              b.forEach(j => {
              if(!retVal.find(x => x.DocId() === j.DocId())) {
                retVal.push(j);
              }});
            return retVal;
          }),
          map(x => x.sort((a,b) => a.startDate < b.startDate ? 1 : -1)),
        share(),
      takeUntil(this.destroyingComponent$));

    this.customTagComponentInputs = this.tagService.buildCustomTagsInput(this.tags, false, this.destroyingComponent$, 'customer_tags', 'Customer Tags')
    this.customTagComponentOutputs = this.tagService.buildCustomTagsOutput(this.tags, this.destroyingComponent$, 'customer_tags');

    merge(this.customTagComponentOutputs.newlyCreatedTagForAdditon, this.customTagComponentOutputs.tagSelectedForAddition, this.customTagComponentOutputs.tagSelectedForRemoval).pipe(
      tap(() => {
        this.form.patchValue({customerTags: [...this.tags]});
      }),
      takeUntil(this.destroyingComponent$),
    ).subscribe();


      this.jobsAssociatedWithCustomer$.pipe(
        filter(x => x.length > 0),
        map(x => x.map(z => z.DocId())),
        distinctUntilChanged((prev,curr) => {
          const prevSet = new Set(prev);
          const curSet = new Set(curr);
          if (prevSet.size !== curSet.size) {
            return false;
          }
          for (var p of prevSet) {
            if (!curSet.has(p)) {
              return false;
            }
          }
          return true;
        }),
        switchMap(jobDocIds => this.invoiceService.queryFirestoreForInValues("jobDocId", jobDocIds)),
        map(invoices => invoices.map(i => i.DocId())),
        switchMap(invoices => this.invoiceService.loadMultiple$(invoices)),
        tap(x => this.invoices.next(x)),
        takeUntil(this.destroyingComponent$)
        ).subscribe();


        this.customerSearchService.searchResults$.pipe(
          filter(x => x.searchInput.componentSource === "customerUniqueIdentifierDuplicationCheck" && x.searchInput.search === this.activeDuplicationSearchTerm),
          filter(x => x.results.length > 0 && x.results[0].customer.DocId() !== this.form.get('customer').value.DocId()),
          tap(x => window.alert(`Duplicate customer detected . ${x.searchInput.search} Customer details are loading, modify at will!`)),
          tap(x => this.router.navigate(['/app-customer-page', {customerDocId: x.results[0].customer.customerDocId}])),
          take(1),
          ).subscribe();
  }


  updateBillingAddress() : void {

    const editorConfig = new MatDialogConfig();
        Object.assign(editorConfig, {
          disableClose : false,
          autoFocus    : true,
          width        : '700px',
          outerHeight  :  '200px',
          data         :
          {
            title: `Update Billing Address`,
            searchSources: [searchSource.GooglePlaceAutoComplete],
            provideAddressToShopRoutingInfo: true,
            provideJobCountAtAddressInfo: true
          }
          });
        this.updateAddressModalRef = this.dialog.open(AddressSearchModalComponent, editorConfig);
        this.updateAddressModalRef.afterClosed().pipe(
          filter(x => x !== undefined),
          tap(address => this.form.patchValue({billingAddress: address, billingAddressDisplay: address.formattedAddress(), billingAddressUnit: address.unit})),
          // Because these are disabled form controls (to prevent users from changing directly rather then through modal), we also need to trigger update of form value changes so they are
          // propagated to firestore.
          tap(() => this.form.patchValue({customer: this.form.get('customer').value})),
          take(1)
        ).subscribe();
  }

  viewJournalEntries(): void {
    const customerDocId = this.form.get("customer")?.value.DocId();
    if (customerDocId === undefined) {
      window.alert("There are no journal entries without customers.");
      return;
    }
    const editorConfig = new MatDialogConfig();
    const journalEntries = new BehaviorSubject<JournalEntry[]>([]);
    this.journalEntryService.queryFirestoreDeep$([where("billingCustomerDocId", "==", customerDocId)]).pipe(
    ).subscribe(x => journalEntries.next(x));

    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      data         :
      {
        journalEntries:  journalEntries
      }
      });
      this.viewJournalEntriesDialogRef = this.dialog.open(JournalEntryDisplayComponent, editorConfig);
  }
}
