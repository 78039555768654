import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import {ManualSizeWrapperComponent} from './formly-wrapper-manual-size';

@NgModule({
  declarations: [ManualSizeWrapperComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      wrappers: [
        { name: 'manual-size', component: ManualSizeWrapperComponent },
      ]
    }),
  ]
})
export class FormlyWrapperManualSizeModule { }
