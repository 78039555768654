import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { LoggingService } from "../logging/logging.service";
import { format } from "date-fns";

@Injectable({
  providedIn: 'root'
})

/**
 * Returns shallow customer and address objects.  Between good practice, and necessary to hydrate results on selection.
 */
export class IonicPlatformService {
  failedToInitilizeFirestoreInTime$: ReplaySubject<null> = new ReplaySubject<null>();
  appSentToBackground$: Subject<null> = new Subject<null>();
  appSentToForeground$: Subject<null> = new Subject<null>();

enabledNetworkAbandonGuid : string = "";
enableNetworkGuid : string = "";

outstandingSubscriptions: number = 0;

appPushedToBackground() {
  this.loggingService.addLog(`ionic-platform.ts ---- App Push to background ${format(new Date(), 'H:mm:ss:SSS')}  ${this.outstandingSubscriptions}` , "ionic-platform", {at: new Date()});
  this.appSentToBackground$.next(null);
}


appPushedToForeground() {
  this.loggingService.addLog(`ionic-platform.ts ---- App Push to foreground ${format(new Date(), 'H:mm:ss:SSS')}  ${this.outstandingSubscriptions}` , "ionic-platform", {at: new Date()});
  this.appSentToForeground$.next(null);
}

PauseIt() {
  this.appPushedToBackground();
}

ResumeIt() {
  this.appPushedToForeground();
}

constructor(private loggingService: LoggingService) {
}

}
