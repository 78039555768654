import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class Assignment extends RetrieveFirestorePropertiesBase<Assignment>  {

    employeeDocId: string;
    siteVisitDocId: string;
    jobDocId: string = "";
    siteVisitStartDate: Date;
    employeeAssigningDocId: string;
    active: boolean = true;
    siteVisitAddressDocId: string;

    public constructor(init?: Partial<Assignment>) {
      super();
      Object.assign(this, init);
    }
  }
