<!-- <div>{{perInstance}}</div> -->
<!-- <div [formGroup]="componentForm" style="width: 750px; height:100%;"> -->
  <div [formGroup]="componentForm" style="width: 100%; height:100%;" #row>
  <div [matMenuTriggerFor]="contextMenu" [style.position]="'absolute'" [style.left.px]="menuX" [style.top.px]="menuY" ></div>
  <as-split class="column-style" direction="horizontal" [gutterSize]="5" formArrayName="columnFormGroups" #asSplit>
    <as-split-area [order]="i" *ngFor="let columnFormGroup of columnFormGroups.controls; index as i; trackBy:trackByColumnFormGroupId" [formGroupName]="i"
    [size]="columnFormGroup.get('columnSplitSize').value">
      <div cdkDropList
      [id]="columnFormGroup.get('id').value" [cdkDropListConnectedTo]="columnFormGroup.get('cdkDropListConnectedTo').valueChanges | async"
      [cdkDropListData]="columnFormGroup.get('cdkDropListData').value" (cdkDropListDropped)="onDragDrop($event)" class = "card-list mat-elevation-z1"
      [cdkDropListEnterPredicate]="forbidTopLevelOnlyComponentsPredicate"
      [cdkDropListDisabled]=disableDesignComponentInteraction
      (contextmenu)="onTriggerContextMenu($event,columnFormGroup.get('id').value)"
      (click)="menu.closeMenu()"
      >
      <!-- <div>{{columnFormGroup.get('id').value}} -- {{columnFormGroup.get('cdkDropListConnectedTo').value}}</div> -->
        <div *ngFor="let component of columnFormGroup.get('cdkDropListData').value; trackBy:trackByComponentPerInstance" cdkDrag
        [cdkDragData]="component"
        (cdkDragStarted)="component.dragStarted($event)"
        (cdkDragEnded)="component.dragEnded($event)"
        >
          <app-form-element
          id={{component.perInstance}}
          [componentToInstantiate]="component">
        </app-form-element>
        </div>
      </div>
    </as-split-area>
  </as-split>

  <mat-menu #contextMenu="matMenu" >
    <button mat-menu-item (click)="addColumn('LEFT')">
      <mat-icon style="color: green;">west</mat-icon>
      <span>Add Column Left</span>
    </button>
    <button mat-menu-item (click)="addColumn('RIGHT')">
      <mat-icon style="color: green;">east</mat-icon>
      <span>Add Column Right</span>
    </button>
      <button mat-menu-item (click)="deleteItem()">
        <mat-icon style="color: #ce1b1b;">delete</mat-icon>
        <span>Delete Item</span>
      </button>
      <button mat-menu-item (click)="deleteColumn()">
        <mat-icon style="color: #ce1b1b;">delete_sweep</mat-icon>
        <span>Delete Column</span>
      </button>
  </mat-menu>
</div>
<!-- Attempt to dynamically cahnge cdkDropListConnectedTo to include the main form when exiting container using:  (cdkDragMoved)="OnDragMovedEvent($event)" -->
