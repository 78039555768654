import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import {  merge, Subject } from 'rxjs';
import { tap, take, mergeMap, filter, share } from 'rxjs/operators';
import { Iphoto } from '../../../../../../../common/src/data/dao/iphoto';
import { ImageCacheService } from '../../../../../../../service-vanguard-mobile/src/app/cache/image-cache.service';
import { FormlyUtilityService } from '../formly-utility.service';

@Component({
  selector: 'app-formly-image',
  styleUrls: ['./formly-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="image-position">
    <canvas #imageCanvas [hidden]="to.resizing" class="image-dimensions"></canvas>
    <div class="rectangle" [hidden]="!to.resizing"></div>
  </div>
    `,
})

export class FormlyImageComponent extends FieldArrayType implements AfterViewInit, OnDestroy{

  @ViewChild('imageCanvas') myCanvas: ElementRef<HTMLCanvasElement>;

  constructor(private http: HttpClient, private imageCacheService: ImageCacheService, private ref: ChangeDetectorRef, private formlyUtilityService: FormlyUtilityService) {
    super();
  }

  private imageContext: CanvasRenderingContext2D;
  image = new Image();
  destroyingComponent$ = new Subject();


  addToDexie(img: string) : void {
    const toAdd = new Iphoto({active: true, docId: this.to.imageUrl.fileName as string, localWebviewPath: img, img: img,
      controlKeyAssociatedWith: this.key as string, storedLocally: false});
    this.imageCacheService.addPhoto(toAdd).pipe(
      tap(() => this.ref.markForCheck()),
      take(1)
    ).subscribe();
  }

  ngAfterViewInit(): void {

    this.imageContext = this.myCanvas.nativeElement.getContext('2d');

    if (this.formlyUtilityService.customerFacing) {

        const reader = new FileReader();
          reader.addEventListener('load', e => {
            this.image.src = reader.result as string;
          });

        this.http.get(this.to.imageUrl.downloadUrl, { responseType: 'blob'}).pipe(
          tap(x => reader.readAsDataURL(x))).subscribe();
    } else {

      // For image control, we skip loading image from URL when working on building form b/c it causes flicker.
      if ( this.formControl.get("imageSrc") === null  && this.to.imageUrl.downloadUrl !== undefined) {

          // Check Dexie cache for image.  If not there, add it, else update it as accessed.
          const imageFromDexie = this.imageCacheService.getPhoto(this.to.imageUrl.fileName).pipe(
            share());

          const presentDexie = imageFromDexie.pipe(
            filter(photo => photo !== null),
            tap(photo => this.image.src = photo.img),
            tap(x => console.log("Fixed image present dexie")),
            mergeMap(photo => this.imageCacheService.registerPhotoAccessed(this.to.imageUrl.fileName))
          );

          const notPresentDexie = imageFromDexie.pipe(
            filter(photo => photo === null),
            tap(() => {
              const reader = new FileReader();
              reader.addEventListener('load', e => {
                this.image.src = reader.result as string;
                this.addToDexie(this.image.src);
              });
              this.http.get(this.to.imageUrl.downloadUrl, { responseType: 'blob'}).pipe(
                tap(x => reader.readAsDataURL(x))).subscribe();
            })
          );

          merge(presentDexie, notPresentDexie).pipe(
            take(1)
          ).subscribe();
      }
    }

    this.image.onload = () => {
      this.myCanvas.nativeElement.height = this.to.imageHeightPixels;
      this.myCanvas.nativeElement.width = this.to.imageWidthPixels;
      this.imageContext.drawImage(this.image,0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
      this.ref.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }
}
