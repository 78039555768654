import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountCreationIonicComponent } from './discount-creation-ionic.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [DiscountCreationIonicComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    IonicModule,
  ],
  exports: [DiscountCreationIonicComponent],
})
export class DiscountCreationIonicModule { }
