import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export enum PRICING_METHODOLOGY {
  FIXED_LINE_ITEM= 1,
  HOURS_AND_MATERIALS =2,
}

export class PricingMethodologySettings extends RetrieveFirestorePropertiesBase<PricingMethodologySettings>  {


materialMarkupRate: number = .5;
defaultHourlyBillingRate: number = 40;
pricingMethodologyType: PRICING_METHODOLOGY = PRICING_METHODOLOGY.FIXED_LINE_ITEM;
default: boolean = false;
active: boolean = true;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["jobTypeDocId"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return PricingMethodologySettings
._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return PricingMethodologySettings
._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return PricingMethodologySettings
._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return PricingMethodologySettings
._firestoreCompositionMemberNames;}

public constructor(init?: Partial<PricingMethodologySettings>) {
super();
Object.assign(this, init);
}
}
