import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import { AuthenticationService } from '../../../../../common/src/util/authentication.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(public authService: AuthenticationService, public employeeService: EmployeeService) { }

  get currentEmployeeName() : string { return this.authService.activelyLoggedInEmployeeDocId == null ? "" : this.employeeService.get(this.authService.activelyLoggedInEmployeeDocId)?.name }

  ngOnInit() {
  }

}
