import { Injectable } from '@angular/core';
import { addHours, addMinutes, subHours, subMinutes } from 'date-fns';
import { EmployeeAvailabilityService } from '../../../../common/src/data/dao-services/employee-availability.service';
import { SettingsService } from '../settings/settings.service';
import startOfDay from 'date-fns/startOfDay';
import { SiteVisitService } from '../../../../common/src/data/dao-services/site-visit.service';
import { AssignmentService } from '../../../../common/src/data/dao-services/assignment.service';
import {Assignment} from '../../../../common/src/data/dao/assignment';
import { SiteVisit } from '../../../../common/src/data/dao/site-visit';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitSchedulingService {

  constructor(private settingsService: SettingsService, private siteVisitService: SiteVisitService, private assignmentService: AssignmentService,
    private employeeAvailibilityService: EmployeeAvailabilityService) {
    }

   public regenerateArrivalWindowForSiteVisitDoc = (doc: string, start: Date, assignment: Assignment) => {

    return this.privateRegenerate(this.siteVisitService,doc,start, assignment);
    }

  privateRegenerate(myHandler: any, doc: string, start: Date, assignment: Assignment) : {arrivalWindowStartDate: Date, arrivalWindowEndDate: Date}
  {
    const s = myHandler.get(doc);
    const retVal = this.regenerateArrivalWindow(start,s,assignment);
    return retVal;
  }

  public generateArrivalWindow(siteVisit: SiteVisit, assignment: Assignment): void {

    const regenerated = this.regenerateArrivalWindow(siteVisit.startDate, siteVisit, assignment);
    siteVisit.arrivalWindowStartDate = regenerated.arrivalWindowStartDate;
    siteVisit.arrivalWindowEndDate = regenerated.arrivalWindowEndDate;
    siteVisit.assignments = [assignment];
  }

  private halfOfArrivalWindow() : Date {
    const arrivalWindow = this.settingsService.getValue("defaultArrivalWindowLength");
    var deltaMilliseconds = arrivalWindow.getTime() - startOfDay(arrivalWindow).getTime();
    deltaMilliseconds = deltaMilliseconds / 2;
    return new Date(startOfDay(arrivalWindow).getTime() + deltaMilliseconds);
  }

  public regenerateArrivalWindow(start: Date, siteVisit: SiteVisit, assignment: Assignment) : {arrivalWindowStartDate: Date, arrivalWindowEndDate: Date} {

    const minutesAtArrivalWindowFidelity = Math.round(start.getMinutes() / this.settingsService.getValue("minutesFidelityArrivalWindow")) *
    this.settingsService.getValue("minutesFidelityArrivalWindow");
    start = addMinutes(subMinutes(start,start.getMinutes()), minutesAtArrivalWindowFidelity);
    let arrivalWindowStartDate: Date;
    let arrivalWindowEndDate: Date;
    const assignedEmployeeId = assignment.employeeDocId;
    const employeeStartHourForDate = this.employeeAvailibilityService.getAvailibilityForDate(start,assignedEmployeeId).workStartTime;
    const employeeEndHourForDate = this.employeeAvailibilityService.getAvailibilityForDate(start,assignedEmployeeId).workEndTime;
    if (start.getMinutes() + start.getHours() * 60 < employeeStartHourForDate.getHours() * 60 + employeeStartHourForDate.getMinutes()) {
      arrivalWindowStartDate = new Date(start);
    } else {
    arrivalWindowStartDate = subHours(start, 2).getHours() * 60 + start.getMinutes() < employeeStartHourForDate.getHours() * 60 + employeeStartHourForDate.getMinutes() ?
      addMinutes(subMinutes(start, start.getHours() * 60 + start.getMinutes()),
      employeeStartHourForDate.getHours() * 60 + employeeStartHourForDate.getMinutes()) :
      subMinutes(subHours(start, this.halfOfArrivalWindow().getHours()),this.halfOfArrivalWindow().getMinutes());
    }
    arrivalWindowEndDate = addMinutes(addHours(arrivalWindowStartDate, this.settingsService.getValue("defaultArrivalWindowLength").getHours()),
      this.settingsService.getValue("defaultArrivalWindowLength").getMinutes());

    if (arrivalWindowEndDate.getHours() * 60 + arrivalWindowEndDate.getMinutes() >   employeeEndHourForDate.getHours() * 60 + employeeEndHourForDate.getMinutes()) {
      const minutesEarlier = arrivalWindowEndDate.getHours() * 60 + arrivalWindowEndDate.getMinutes() - (employeeEndHourForDate.getHours() * 60 + employeeEndHourForDate.getMinutes());
      arrivalWindowEndDate = subMinutes(arrivalWindowEndDate, minutesEarlier);
      arrivalWindowStartDate = subMinutes(arrivalWindowStartDate, minutesEarlier);
    }
    if (start.getMinutes() + start.getHours() * 60 > employeeEndHourForDate.getHours() * 60 + employeeEndHourForDate.getMinutes()) {
      arrivalWindowStartDate = subMinutes(subHours(start, this.halfOfArrivalWindow().getHours()),this.halfOfArrivalWindow().getMinutes());
      arrivalWindowEndDate = new Date(start);
    }
    return ({arrivalWindowStartDate, arrivalWindowEndDate});
  }


}
