<div class="line-item-container" >
  <table mat-table [dataSource]="journalEntries | async"
  multiTemplateDataRows class="mat-elevation-z8" style="background-color: rgba(0,0,0,0);">

  <!-- Payment Type-->
<ng-container matColumnDef="paymentType">
  <th mat-header-cell *matHeaderCellDef>Payment Type</th>
  <td mat-cell *matCellDef="let j">{{j.journalEntryCreditType}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Memo-->
<ng-container matColumnDef="memo">
  <th mat-header-cell *matHeaderCellDef>Memo</th>
  <td mat-cell *matCellDef="let j">{{j.memo}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Customer Name -->
<ng-container matColumnDef="customerName">
  <th mat-header-cell *matHeaderCellDef>Billing Customer</th>
  <td mat-cell *matCellDef="let j">{{j.billingCustomer.customerName}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>Total Paid:</td>
</ng-container>

<!-- Amount-->
<ng-container matColumnDef="amount">
  <th mat-header-cell *matHeaderCellDef>Amount</th>
  <td mat-cell *matCellDef="let j">{{j.amount | currency}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef> {{totalAmount() | async | currency}} </td>
</ng-container>

<!-- Check Number -->
<ng-container matColumnDef="checkNumber">
  <th mat-header-cell *matHeaderCellDef>Check #</th>
  <td mat-cell *matCellDef="let j">{{j.checkNumber}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Date Created -->
<ng-container matColumnDef="dateCreated">
  <th mat-header-cell *matHeaderCellDef>Created On</th>
  <td mat-cell *matCellDef="let j">{{j.createdAt | date:'shortDate'}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Employee -->
<ng-container matColumnDef="employee">
  <th mat-header-cell *matHeaderCellDef>Employee</th>
  <td mat-cell *matCellDef="let j">{{j.employee?.name}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- View Invoices -->
<ng-container matColumnDef="viewInvoices">
  <th mat-header-cell *matHeaderCellDef>View Invoices</th>
  <td mat-cell *matCellDef="let j">
      <button mat-button class="button-green" (click)="viewInvoices(j)">View Invoices</button>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Voided -->
<ng-container matColumnDef="voided">
  <th mat-header-cell *matHeaderCellDef>Voided</th>
  <td mat-cell *matCellDef="let j">
    {{j.void}}
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Void Payment -->
<ng-container matColumnDef="void">
  <th mat-header-cell *matHeaderCellDef>Void</th>
  <td mat-cell *matCellDef="let j">
    <div *ngIf="j.void; else elseBlock" class="account-balance">
      {{voidingDetails(j)}}
    </div>
    <ng-template #elseBlock>
      <button mat-button class="button-red" (click)="void(j)">Void Payment</button>
    </ng-template>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
<tr mat-row *matRowDef="let element; columns: columnsToDisplay;"class="example-element-row">
</tr>
<tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
  </table>
</div>
