<div [formGroup]="form" style="grid-column: 1/3;">
  <div cdkDropList class = "card-list mat-elevation-z1 main" [cdkDropListData]="draggableComponents.controls"
  (cdkDropListDropped)="onTalkDrop($event)">
    <div class="component-section">{{form.get('title').value}}</div>
    <div class="drag-boundry">
      <div *ngFor="let component of draggableComponents.controls; index as i" cdkDragBoundary=".drag-boundry" cdkDrag [cdkDragData]="component" class = "main-form-content">
        <div fxFlexLayout="row">
        <div fxFlex class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24" style="margin-top: 8px;">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <div [formGroup]="draggableComponentAsUntypedFormGroup(component)" style="width: 100%;">
          <!--<mat-form-field appearance="outline">-->
        <input fxFlex matInput type="text" formControlName="label" style="margin-left: 5px;" class="choice-text"
          [ngClass]="{'focused': focused(draggableComponentAsUntypedFormGroup(component))}" (focus)="focusGained(i)" (blur)="formArrayElementLostFocus.next(i)"
          [name]="ElementNameForId(i)" autofill='off' autocomplete='new-password' data-lpignore='true'
          >
        <!--</mat-form-field>-->
        
          <button disabled="{{!form.get('allowAddFromDragDrop').value}}" type="button" mat-button (click)="addElement(i)" class="choice-button icon-button-dimensions">
            <mat-icon style="color: green; font-size: 24px;">add</mat-icon>
          </button>
          <button type="button" mat-button (click)="deleteElement(i)" disabled={{disableDelteElementButton}} class="choice-button icon-button-dimensions">
          <mat-icon style="color: #ce1b1b; font-size: 24px;">delete</mat-icon>
        </button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
