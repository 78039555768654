<div class="job-tag-label">{{title}}</div>
<form style="width: 100%;">
<mat-form-field class="custom-tags-list">
<mat-chip-grid #chipList>
  <mat-chip-row [style.background]="tag.colorAssocatedWithSetting" style="color: white;" *ngFor = "let tag of assignedTags"
  (removed)="tagSelectedForRemoval.emit(tag)">
    {{tag.name}}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip-row>
<input
data-lpignore="true"
placeholder= {{placeHolderText}}
#tagInput
[formControl]="tagCtrl"
[matAutocomplete]="auto"
[matChipInputFor]="chipList"
[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
(matChipInputTokenEnd)="add($event)"/>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayCustomTagName">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{tag.name}}
    </mat-option>
  </mat-autocomplete>
</mat-chip-grid>
</mat-form-field>
</form>
