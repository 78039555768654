import { Address } from "./address";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Employee } from "./employee";

export enum CommuteCharacterization {
  WORKDAY = "Working",
  COMMUTETIME = "Commuting",
}

export class Commute extends RetrieveFirestorePropertiesBase<Commute>  {

commuteStartTime: Date;
commuteEndTime: Date;
commuteCharacterization: CommuteCharacterization = CommuteCharacterization.WORKDAY;
estimated: boolean = true;
errored: boolean = false;
employeeDocId: string;
orginAddressDocId: string;
destinationAddressDocId: string;

get commuteTimeMinutes() : number {
  return this.commuteEndTime.getHours() * 60 - this.commuteStartTime.getHours() * 60 + this.commuteEndTime.getMinutes() - this.commuteStartTime.getMinutes(); }


static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames;
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers;
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return Commute._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Commute._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Commute._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Commute._firestoreCompositionMemberNames;}

public constructor(init?: Partial<Commute>) {
super();
Object.assign(this, init);
}
}
