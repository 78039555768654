<div>
  <div class="header-style">{{title}}</div>
      <app-line-item-display *ngIf="(lineItems$ | async)"
      [completedLineItemOperation$]= "completedLineItemOperation$"
      [discounts]="discounts$"
      [invoicePayments]="invoicePayments$"
      [intendedAudience]="intendedAudience"
      [lineItems]="lineItems$"
      [lineItemControlContext]="1"
      [lineItemControlType]="lineItemControlType"
      [estimate]="estimate"

      (addDiscount$)="addDiscount$.next($event)"
      (addLineItem$)="addLineItem$.next($event)"
      (editDiscount$)="editDiscount$.next($event)"
      (editLineItem$)="editLineItem$.next($event)"
      (removeDiscount$)="removeDiscount$.next($event)"
      (removeLineItem$)="removeLineItem$.next($event)"

      ></app-line-item-display>
</div>
