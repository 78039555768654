<app-navigation></app-navigation>
<div class="center-content">
  <div></div>
  <div>
  <div class="header-label header-border">Reports</div>
  <div>
    <div>Date Range </div>
  <mat-form-field appearance="outline">
    <!-- <mat-label>Enter a date range</mat-label> -->
    <mat-datepicker-toggle matIconSuffix [for]="picker" class="align-date-picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-date-range-input [rangePicker]="picker">
      <input [formControl]="startDate" matStartDate placeholder="Start date">
      <input [formControl]="endDate" matEndDate placeholder="End date" style="width: 110px;">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
  </mat-form-field>
  </div>

  <div class="label-style">Report</div>
    <div class="medium-field">
      <mat-form-field appearance="outline" class="form-align">
            <mat-select [formControl]="selectedReport" placeholder="Report" [compareWith]="docComparer">
            <mat-option *ngFor="let report of activeReports$ | async" [value]="report" >
              {{report.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>

    <ng-container *ngIf="activeReport$|async">
    <ng-container *ngIf="{lr: (activeReport$|async)?.lastRun} as lastRun">
      <mat-label>Report last generated: {{ lastRun.lr ?  (lastRun.lr|date:'medium') : "Report not yet generated"}}</mat-label>
    </ng-container>

    <button mat-button (click)="RegenerateReport()">
        Regenerate Report
    </button>
    </ng-container>


<table mat-table [dataSource]="data | async" style="background-color: #fbfbfb;">
    <div *ngFor="let col of displayedColumns | async;">

    <!-- DATES -->
    <ng-container *ngIf="retrieveDisplayType(col) === 'date'" [matColumnDef]=col>
    <th class="headerCell" mat-header-cell *matHeaderCellDef>{{retrieveColumnName(col)}}</th>
    <td mat-cell *matCellDef="let row"> {{row[col] ? (row[col]| date:'medium') : ""}} </td>
  </ng-container>

    <!-- HREF LINKS -->
    <ng-container *ngIf="retrieveDisplayType(col) === 'href'" [matColumnDef]=col>
      <th class="headerCell" mat-header-cell *matHeaderCellDef>{{retrieveColumnName(col)}}</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row[col]">
          <a routerLink="{{getLink(row[col])}}">
            {{getUserText(row[col])}}
          </a>
        </ng-container>
      </td>
    </ng-container>

    <!-- TAGS -->
    <ng-container *ngIf="retrieveDisplayType(col) === 'tags'" [matColumnDef]=col>
      <th class="headerCell" mat-header-cell *matHeaderCellDef>{{retrieveColumnName(col)}}</th>
      <td mat-cell *matCellDef="let row">
        <mat-chip-listbox>
          <ng-container *ngFor = "let tag of row[col]">
            <mat-chip-option disabled [style.background]="(retrieveTag(tag)|async)?.colorAssocatedWithSetting" style="color: white;"
              [selectable]="false"
              [removable]="false"
              >
              {{(retrieveTag(tag)|async)?.name}}
            </mat-chip-option>
          </ng-container>
        </mat-chip-listbox>
      </td>
    </ng-container>

    <!-- STRINGS -->
    <ng-container *ngIf="retrieveDisplayType(col) === 'string'" [matColumnDef]=col>
      <th class="headerCell" mat-header-cell *matHeaderCellDef>{{retrieveColumnName(col)}}</th>
      <td mat-cell *matCellDef="let row"> {{row[col]}} </td>
    </ng-container>
  </div>
  <tr mat-header-row *matHeaderRowDef="displayedColumns | async sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns | async;"></tr>
</table>
<mat-paginator [disabled]="retrievingData|async" [length]="resultsCounter" [pageSize]="50" [pageSizeOptions]="[25,50,100,250,500]" style="font-size: 20px; background-color: #fbfbfb;"></mat-paginator>
</div>
<div></div>
</div>
