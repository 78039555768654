import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {  FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-horizontal-whitespace',
  styleUrls: ['./formly-horizontal-whitespace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div [style.height.px]="to.heightPixels">
  </div>
    `,
  })

export class FormlyHorizontalWhitespaceComponent extends FieldType {}
