import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit,  Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { map, tap, delay } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { GenericServiceProviderSetting } from '../../../../common/src/data/dao/generic-service-provider-setting';

export class CustomTagComponentInputs {
  title: string;
  assignedTags: GenericServiceProviderSetting[];
  existingTags: Observable<GenericServiceProviderSetting[]>;
  existingTagsUpdated: Observable<boolean>;
  disableInput: BehaviorSubject<boolean> = new BehaviorSubject(false);
}

export class CustomTagComponentOutputs {
  tagSelectedForAddition = new EventEmitter<GenericServiceProviderSetting>();
  tagSelectedForRemoval = new EventEmitter<GenericServiceProviderSetting>();
  newlyCreatedTagForAdditon = new EventEmitter<string | GenericServiceProviderSetting>();
}

@Component({
  selector: 'app-custom-tags',
  templateUrl: './custom-tags.component.html',
  styleUrls: ['./custom-tags.component.scss']
})

export class CustomTagsComponent implements OnInit {

  @Input()  title: string;
  @Input()  assignedTags: GenericServiceProviderSetting[];
  @Input()  existingTags: GenericServiceProviderSetting[];
  @Input()  existingTagsUpdated: Observable<boolean>;
  @Input() disableInput: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() createTags: boolean = false;

  @Output() tagSelectedForAddition = new EventEmitter<GenericServiceProviderSetting>();
  @Output() tagSelectedForRemoval = new EventEmitter<GenericServiceProviderSetting>();
  @Output() newlyCreatedTagForAdditon = new EventEmitter<string>();

  filteredTags: Observable<GenericServiceProviderSetting[]>;
  tagCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  _numberTags = 0;
  placeHolderText = "";

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if((value || '').trim() && !this.assignedTags.map(x => x.name).includes(value)) {
      this.newlyCreatedTagForAdditon.emit(value.trim());
    }

    if (input) {
      input.value = "";
    }

    this.tagCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.assignedTags === null || !this.assignedTags.map(x => x.name).includes(event.option.value.name)) {
       this.tagSelectedForAddition.emit(event.option.value);
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  ngOnInit(): void {

    // tag search emission returns filtered results of search term.
    const tagSearch$ = this.tagCtrl.valueChanges.pipe(
      map((tagSearch: string | null | GenericServiceProviderSetting) =>
        (tagSearch instanceof GenericServiceProviderSetting) ? this._filter(tagSearch.name) :
        tagSearch  ? this._filter(tagSearch) : this._filter('')));


    // delay (1) to give change cycle chance to popupdate update -> this.existingTags.
    if (this.existingTagsUpdated !== undefined) {
      const addedToSelectableTags$ = this.existingTagsUpdated.pipe(
        delay(1),
        tap(x => this.tagCtrl.setValue(null)
        ));

      this.filteredTags = combineLatest(tagSearch$, addedToSelectableTags$, (a, b) => a);
    }

    const disableInput$ = this.disableInput.pipe(
      tap(x => {
        if (x === false) {
          this.tagCtrl.enable();
          if (this.createTags) {
            this.placeHolderText = "Create tag...";
          } else {
            this.placeHolderText = "Select tag...";
          }

        } else {
          this.tagCtrl.disable();
          this.placeHolderText = "";
        }})).subscribe();


  }

  private _filter(value: string): GenericServiceProviderSetting[] {
    const filterValue = value.toLowerCase();
    // sort when tags are added or removed.
    if (this.existingTags !== null && this._numberTags !== this.existingTags.length) {
      this._numberTags = this.existingTags.length;
    }
    return filterValue !== '' ?
      this.existingTags.filter(customTag => customTag.name.toLowerCase().indexOf(filterValue) === 0 || customTag.name.toLowerCase().indexOf(` ${filterValue}`) !== -1) : this.existingTags;
  }

  public displayCustomTagName(value: GenericServiceProviderSetting) {
    return value !== null ? value.name : "Getting Data!";
  }

}
