import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';
import { FormFirestore } from './form-firestore';

export class FormModelFirestore extends RetrieveFirestorePropertiesBase<FormModelFirestore> {
  title: string = '';
  model: string;
  formFirestore: FormFirestore;
  formFirestoreDocId: string;

  previousFormModelFirestoreVersionDocIds: string[] = [];
  previousForms: FormModelFirestore[] = [];

  employeeLoggedInWheLastUpdated: string = '';
  previousLastUpdatedAt: Date = null;

  formFirestoreInstantiatedDocIdToSummaryDocIdMap: Map<string, string> = new Map<string, string>();
  formFirestoreSummaryDocIdToInstantiatedDocIdMap: Map<string, string> = new Map<string, string>();
  formFirestoreSummaryDocIdToInstantiated: any = null;

  public setformFirestoreSummaryDocIdToInstantiatedDocIdMap( summaryDocId: string, formFirestoreDocId: string): void {
    if (this.formFirestoreSummaryDocIdToInstantiated === null) {
      this.formFirestoreSummaryDocIdToInstantiated = {};
    }
    this.formFirestoreSummaryDocIdToInstantiatedDocIdMap.set(summaryDocId,formFirestoreDocId);
    this.formFirestoreInstantiatedDocIdToSummaryDocIdMap.set(formFirestoreDocId,summaryDocId);
    this.formFirestoreSummaryDocIdToInstantiated[summaryDocId] = formFirestoreDocId;
  }

  resetMappings() : void {
    this.formFirestoreSummaryDocIdToInstantiatedDocIdMap.clear();
    this.formFirestoreInstantiatedDocIdToSummaryDocIdMap.clear();
    this.formFirestoreSummaryDocIdToInstantiated = {};
  }

  skipSave: boolean = false;

  instantiatedLatestForm: boolean | null = null;
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([
      'formFirestoreDocId', 'previousFormModelFirestoreVersionDocIds','previousLastUpdatedAt','skipSave','previousForms','formFirestoreSummaryDocIdToInstantiatedDocIdMap',
      'formFirestoreInstantiatedDocIdToSummaryDocIdMap',]);
  static _firestoreCompositionMemberNames = ['formFirestore'];
  static _firestoreIgnoredMemberNames =
    RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames
      .concat(...FormModelFirestore._firestoreCompositionMemberNames)
      .concat('skipSave','previousForms','formFirestoreSummaryDocIdToInstantiatedDocIdMap','formFirestoreInstantiatedDocIdToSummaryDocIdMap');

  static _firestoreCompositionalDiffMemberNames: string[] = ['formFirestore'];

  retrieveFirestoreIgnoredMemberNames(): string[] {
    return FormModelFirestore._firestoreIgnoredMemberNames;
  }
  retrievefirestoreIgnoreDiffTrackingMembers(): string[] {
    return FormModelFirestore._firestoreIgnoreDiffTrackingMembers;
  }
  retrievefirestoreCompositionMemberNames() {
    return FormModelFirestore._firestoreCompositionMemberNames;
  }
  retrievefirestoreCompositionalDiffMemberNames() {
    return FormModelFirestore._firestoreCompositionalDiffMemberNames;
  }
  retrieveFirestoreDenormalizedMemberNames(): string[] {
    return FormModelFirestore._firestoreCompositionMemberNames;
  }

  public constructor(init?: Partial<FormModelFirestore>) {
    super();
    Object.assign(this, init);
    if (this.formFirestoreSummaryDocIdToInstantiated !== null) {
      for (const key in this.formFirestoreSummaryDocIdToInstantiated) {
        this.formFirestoreSummaryDocIdToInstantiatedDocIdMap.set(key, this.formFirestoreSummaryDocIdToInstantiated[key]);
        this.formFirestoreInstantiatedDocIdToSummaryDocIdMap.set(this.formFirestoreSummaryDocIdToInstantiated[key], key);
       }
    }
  }
}
