import { Injectable } from '@angular/core'
;
import { tap } from 'rxjs/operators';
import { AddressService } from './address.service';
import { AuthenticationService } from '../../util/authentication.service'
import { CompanySettings } from '../dao/company-settings';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { IndustryService } from './industry.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService extends DatabaseStoreService<CompanySettings> {

  incrementJobCounter(batch : string | null, obj: CompanySettings) : Observable<string> {
    return (this.fs as CompanySettingsFirestoreService).IncrementCounter(obj,batch);
  }

  populateInitial() {
    const seed: CompanySettings = new CompanySettings();
    this.create$(seed).pipe(
      tap(x => console.log(x,` string`)),
    )
    .subscribe();
  }

  constructor( fs: CompanySettingsFirestoreService, authenticationService: AuthenticationService, store: CompanySettingsStoreService, private addressService: AddressService,
     private industryService: IndustryService) {
super(fs, store, authenticationService,new Map([
  ["mailingAddress", {associatedDocumentId: "mailingAddressDocId", compositionStoreService: addressService}],
  ["dispatchAddress", {associatedDocumentId: "dispatchAddressDocId", compositionStoreService: addressService}],
  ["industry", {associatedDocumentId: "industryDocId", compositionStoreService: industryService}],
]));
  }

}

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsStoreService extends StateChangeStoreService<CompanySettings> {
  protected store = "CompanySettings-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, CompanySettings>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
  })
  class CompanySettingsFirestoreService extends FirestoreBackend<CompanySettings> {

 protected basePath = "CompanySettings";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): CompanySettings {
return new CompanySettings(obj);
 }

 public IncrementCounter(settings: CompanySettings, batch : string | null) : Observable<string> {
    return this.atomicallyIncrementFieldCreateIfAbsent(settings,"currentJobCounter",1,batch);
 }

constructor(protected authService: AuthenticationService) {
super(new CompanySettings(), authService);
 }
}
