import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBranchingContainerComponent } from './formly-branching-container.component';


@NgModule({
  declarations: [FormlyBranchingContainerComponent],
  imports: [
    CommonModule,
      FormlyModule.forChild({
        types: [
          {name: 'formlyBranchingContainer', component: FormlyBranchingContainerComponent},
        ]}),
  ]
})
export class FormlyBranchingContainerModule { }
