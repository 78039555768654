import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectNestedTreeComponent } from './multiselect-nested-tree.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';




@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [MultiselectNestedTreeComponent],
  exports: [MultiselectNestedTreeComponent],
})
export class MultiselectNestedTreeModule { }

/*
providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useValue: MAT_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY,
    }
  ]
  */
