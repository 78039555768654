import { Injectable } from '@angular/core';
import { addMinutes, differenceInMinutes, getHours, getMinutes,  subMinutes } from 'date-fns';
import { EmployeeAvailabilityService } from '../../../../common/src/data/dao-services/employee-availability.service';
import { SiteVisit } from '../../../../common/src/data/dao/site-visit';
import { SettingsService } from '../settings/settings.service';
import { ResourceAvailibility, RESOURCE_AVAILIBILITY_CALCULATION_METHOD } from './resource-availibility';

@Injectable({
  providedIn: 'root'
})
export class ResourceAvailibilityService {

  constructor(private settingsService: SettingsService, private employeeAvailibilityService: EmployeeAvailabilityService) { }

  findBestFromGrouping(group: ResourceAvailibility[], timeToConsider: Date): ResourceAvailibility {
    const lowestCommuteMinutesDelta = group.sort( (a, b) => a.commuteMinutesDelta - b.commuteMinutesDelta)[0].commuteMinutesDelta;
    group = group.filter(x => x.commuteMinutesDelta === lowestCommuteMinutesDelta);
    const lowestNumberSiteVisitsMutated = group.sort( (a, b) =>
      a.numberSiteVisitsMutated - b.numberSiteVisitsMutated)[0].numberSiteVisitsMutated;
    group = group.filter(x => x.numberSiteVisitsMutated === lowestNumberSiteVisitsMutated);
    return group.sort((a,b) => a.numberMinutesMutated(timeToConsider) - b.numberMinutesMutated(timeToConsider))[0];
  }

  addTimeToDate(sourceDate: Date, sourceTime: Date): Date {
    return addMinutes(sourceDate, getHours(sourceTime) * 60 + getMinutes(sourceTime));
  }

  addTimeWhenEmployeesNotWorking(employeeDocId: string, date: Date, startOfDisplayedTime: Date, endOfDisplayedTime: Date, bryntumResourceId: string): ResourceAvailibility[] {
    const retVal: ResourceAvailibility[] = [];
    const employeeAvailibility = this.employeeAvailibilityService.getAvailibilityForDate(date,employeeDocId);
    if (employeeAvailibility && employeeAvailibility.activeWorkDay) {
    // start time until employee begins work. (not working)
    retVal.push(new ResourceAvailibility({employeeDocId, actualDate: date, startDate: startOfDisplayedTime, endDate: this.addTimeToDate(date,employeeAvailibility.workStartTime),
      available: false, scheduledToWorkDuringSegment: false, resourceId: bryntumResourceId, name: employeeAvailibility.note, defaultEmployeeAvailability: employeeAvailibility.defaultAvailability}));
    // after employee done working -> end of displayed time.
    retVal.push(new ResourceAvailibility({employeeDocId, actualDate: date, startDate: this.addTimeToDate(date,employeeAvailibility.workEndTime), endDate: endOfDisplayedTime,
      available: false, name: employeeAvailibility.note, defaultEmployeeAvailability: employeeAvailibility.defaultAvailability, scheduledToWorkDuringSegment: false, resourceId: bryntumResourceId}));
    } else if (employeeAvailibility) {
      // employee is not working on this day.
      retVal.push(new ResourceAvailibility({employeeDocId, actualDate: date, startDate: startOfDisplayedTime, endDate: endOfDisplayedTime,
        available: false, name: employeeAvailibility.note, defaultEmployeeAvailability: employeeAvailibility.defaultAvailability, scheduledToWorkDuringSegment: false, resourceId: bryntumResourceId}));
        retVal.push(new ResourceAvailibility({employeeDocId, actualDate: date, startDate: startOfDisplayedTime, endDate: endOfDisplayedTime,
          available: false,name: employeeAvailibility.note, defaultEmployeeAvailability: employeeAvailibility.defaultAvailability, scheduledToWorkDuringSegment: false, resourceId: bryntumResourceId}));
    }
    return retVal;
  }

  addTimesWhenEmployeesWorkingWhileNotScheduling(segmentsNotWorking: ResourceAvailibility[]): ResourceAvailibility[] {
    const retVal: ResourceAvailibility[] = [];
    //split non-work segments into groupings of two continuous segments.
    const segs = segmentsNotWorking.reduce((acc,curr,i) => {
      if (!(i%2)) {
        acc.push(segmentsNotWorking.slice(i,i+2));
      }
      return acc;
    }, []);
    segs.forEach(seg => {
      retVal.push(new ResourceAvailibility({employeeDocId: seg[0].employeeDocId, actualDate: seg[0].actualDate, startDate: seg[0].endDate,
        endDate: seg[1].startDate, available: true, resourceId: seg[0].resourceId, scheduledToWorkDuringSegment: true, timeRangeColor: "white"}));
    });

    return retVal;
  }





  fillOutDaysAvailibility(employeeDocId: string, actualDate: Date, startTime: Date, endTime: Date,
    openTimes: ResourceAvailibility[], resourceId: string ): ResourceAvailibility[] {
      const employeeWorkSchedule = this.employeeAvailibilityService.getAvailibilityForDate(actualDate, employeeDocId );
    const retVal: ResourceAvailibility[] = this.addTimeWhenEmployeesNotWorking(employeeDocId, actualDate, startTime, endTime, resourceId);
    if (openTimes.length === 0) {
      try {
        retVal.push(new ResourceAvailibility({employeeDocId, defaultEmployeeAvailability: employeeWorkSchedule.defaultAvailability, actualDate,
          startDate: retVal[0].endDate, endDate: retVal[1].startDate, available: false, resourceId, scheduledToWorkDuringSegment: employeeWorkSchedule.activeWorkDay}))
        return retVal;
      } catch (e) {
        console.log(e);
        console.log(actualDate, employeeDocId);
        throw e;
      }
    }

    // reduce to non-overlapping segments.
    openTimes = openTimes.sort((a, b) => a.startDate.getTime()
    < b.startDate.getTime() ? -1 : b.startDate.getTime() < a.startDate.getTime() ? 1 : 0 );
    // preceed w/ unavailiable segment(s) if not starting at startime.  Both b/c not working, and b/c commute time to start on site.
    if (getHours(openTimes[0].startDate)*60 + getMinutes(openTimes[0].startDate) > getHours(startTime)*60 + getMinutes(startTime)) {
      // time employee is spending en route to site ( working, in van )
      retVal.push(new ResourceAvailibility({employeeDocId, actualDate, startDate: employeeWorkSchedule.workStartTime,
        defaultEmployeeAvailability: employeeWorkSchedule.defaultAvailability, endDate: openTimes[0].startDate,
                                            available: false, scheduledToWorkDuringSegment: true, resourceId  }));
    }

    let activeSegment: ResourceAvailibility = null;
    let finalSegment: ResourceAvailibility = null;
    openTimes.forEach(nextSegment => {
      nextSegment.resourceId = resourceId;
      finalSegment = nextSegment;
        // next segment is identical to active segment.
        if ((activeSegment && nextSegment.startDate.getTime() === activeSegment.startDate.getTime() && nextSegment.endDate.getTime() === activeSegment.endDate.getTime())
        || nextSegment.startDate.getTime() === nextSegment.endDate.getTime()) {
          if (activeSegment !== null && activeSegment.siteVisitNumber !== nextSegment.siteVisitNumber) {
            console.error(activeSegment, nextSegment);
          }
        } else {
          if (activeSegment === null) {
            activeSegment = nextSegment;
          } else {
              // next overlaps active, and extends beyond it.
              if (nextSegment.startDate.getTime() < activeSegment.endDate.getTime()
              && nextSegment.endDate.getTime() >= activeSegment.endDate.getTime() && nextSegment.siteVisitNumber === activeSegment.siteVisitNumber) {
                activeSegment.endDate = new Date(nextSegment.endDate);
              } else {
                const bestSegmentAtEdge = this.findBestFromGrouping([nextSegment, activeSegment], nextSegment.startDate);
                if (nextSegment.siteVisitNumber !== activeSegment.siteVisitNumber &&
                      bestSegmentAtEdge.siteVisitNumber === nextSegment.siteVisitNumber) {
                      nextSegment.startDate = subMinutes(nextSegment.startDate, 1);
                      activeSegment.endDate = subMinutes(activeSegment.endDate, 1);
                    }
                // If next is a fully contained subset of active, do nothing.  Otherwise a gap exists between active and next.
                // Add in gap, and active and then set active to next.
                if (nextSegment.startDate.getTime() > activeSegment.endDate.getTime()) {
                  // If gap is fidelity SP is using or less then we push a green segment here b/c the red drop zone is
                  // below the fidelity we use for scheduler.
                  if (differenceInMinutes(nextSegment.startDate, activeSegment.endDate ) <=  this.settingsService.getValue("minutesFidelitySchedulingArrivalTimes")) {
                    const visualHelperSegment = new ResourceAvailibility(bestSegmentAtEdge);
                    visualHelperSegment.startDate = new Date(activeSegment.endDate);
                    visualHelperSegment.endDate = new Date(nextSegment.startDate);
                    retVal.push(visualHelperSegment);
                  } else {
                    retVal.push(new ResourceAvailibility({employeeDocId, actualDate,  startDate: new Date(activeSegment.endDate),
                                                          endDate: new Date(nextSegment.startDate), available: false, resourceId}));
                  }
                }
                retVal.push(new ResourceAvailibility(activeSegment));
                activeSegment = nextSegment;
              }
            }
        }
      }
    );
    if (finalSegment !== null) {
      retVal.push(finalSegment);
    }

    // postceed w/ unavailible segment for portion that prevents field tech from ending work @ their normal time.
    if (activeSegment !== null) {
      retVal.push(new ResourceAvailibility({employeeDocId, actualDate, startDate: activeSegment.endDate,
        defaultEmployeeAvailability: employeeWorkSchedule.defaultAvailability, endDate: employeeWorkSchedule.workEndTime,
                                            available: false, resourceId, scheduledToWorkDuringSegment: true}));
    }

    // Remove any segments that have no duration.
    return retVal.filter(x => x.startDate.getTime() !== x.endDate.getTime());
  }

  eliminateOverlapOfWorseResourceTimeSlots(availibility: ResourceAvailibility[], existingSiteVisitsForResourceDay: SiteVisit[]): { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility[]}[] {
    const retVal: { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility[]}[] = [
      { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD.DEFAULT, ResourceAvailibility: []},
      { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD.MINIMZE_SITE_VISIT_MUTATIONS, ResourceAvailibility: []}
    ];
    if (availibility.length === 0) {
      return retVal;
    }

    const startDateConsideration = availibility.map(x=>x.startDate).sort((a,b) => a.getTime() - b.getTime())[0];
    const endDateConsideration = availibility.map(x=>x.endDate).sort((a,b) => b.getTime() - a.getTime())[0];
    availibility.sort((a, b) => a.startDate < b.startDate ? -1 : b.startDate < a.startDate ? 1
      : a.endDate < b.endDate ? -1 : b.endDate < a.endDate ? 1 : 0 );
    let activelyConsideredFiveMinuteWindow = startDateConsideration;
    let activeResourceAvailibilitySegment: { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility}[];
    do {
      // Only consider those in range.
      let considerForTime = availibility.filter(x => x.startDate.getTime() <= activelyConsideredFiveMinuteWindow.getTime() &&
        x.endDate.getTime() >= activelyConsideredFiveMinuteWindow.getTime());
      if (considerForTime.length === 0) {
        if (activeResourceAvailibilitySegment !== undefined) {
          activeResourceAvailibilitySegment.forEach(segment => segment.ResourceAvailibility.endDate = new Date(activelyConsideredFiveMinuteWindow));
          retVal[0].ResourceAvailibility.push(activeResourceAvailibilitySegment[0].ResourceAvailibility);
          retVal[1].ResourceAvailibility.push(activeResourceAvailibilitySegment[1].ResourceAvailibility);
          activeResourceAvailibilitySegment = undefined;
        }
      } else {
          const lowCommuteFavoredResult = this.generateResourceGuidanceFavoringLowCommuteTimes(considerForTime, activelyConsideredFiveMinuteWindow);
          const lowSiteVisitMutationFavoredResult = this.generateResourceGuidanceFavoringLowSiteVisitMutations(considerForTime, activelyConsideredFiveMinuteWindow, existingSiteVisitsForResourceDay);
          if (activeResourceAvailibilitySegment === undefined) {
            activeResourceAvailibilitySegment = [
              { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD.DEFAULT, ResourceAvailibility: lowCommuteFavoredResult},
              { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD.MINIMZE_SITE_VISIT_MUTATIONS, ResourceAvailibility: lowSiteVisitMutationFavoredResult}
            ];
          } else {
              const newSegments : { CalcuationModel: RESOURCE_AVAILIBILITY_CALCULATION_METHOD, ResourceAvailibility: ResourceAvailibility}[] = [];
              activeResourceAvailibilitySegment.forEach(segment => {
                const matchedSegmentGeneration = segment.CalcuationModel === RESOURCE_AVAILIBILITY_CALCULATION_METHOD.DEFAULT ?
                  lowCommuteFavoredResult : lowSiteVisitMutationFavoredResult;
              if (segment.ResourceAvailibility.siteVisitNumber !== matchedSegmentGeneration.siteVisitNumber ||
                    segment.ResourceAvailibility.endDate.getTime() !== matchedSegmentGeneration.endDate.getTime() ||
                    segment.ResourceAvailibility.commuteMinutesDelta !== matchedSegmentGeneration.commuteMinutesDelta) {
                segment.ResourceAvailibility.endDate = new Date(activelyConsideredFiveMinuteWindow);
                retVal.find(x => x.CalcuationModel === segment.CalcuationModel).ResourceAvailibility.push(segment.ResourceAvailibility);
                const newResourceAvailibility = new ResourceAvailibility(matchedSegmentGeneration);
                newResourceAvailibility.updateStartTime(activelyConsideredFiveMinuteWindow);
                newResourceAvailibility.startDate = new Date(activelyConsideredFiveMinuteWindow);
                newSegments.push({CalcuationModel: segment.CalcuationModel, ResourceAvailibility: newResourceAvailibility});
              }
            });
            newSegments.forEach(segment => {
              activeResourceAvailibilitySegment[activeResourceAvailibilitySegment.findIndex(x => x.CalcuationModel === segment.CalcuationModel)] = segment
            });
          }
        }
        activelyConsideredFiveMinuteWindow = addMinutes(activelyConsideredFiveMinuteWindow, 1);
    } while (activelyConsideredFiveMinuteWindow.getTime() <= endDateConsideration.getTime());
    retVal[0].ResourceAvailibility.push(activeResourceAvailibilitySegment[0].ResourceAvailibility);
    retVal[1].ResourceAvailibility.push(activeResourceAvailibilitySegment[1].ResourceAvailibility);
    return retVal;
  }


private retrieveNumberOfSiteVisitOrderMutations(avail: ResourceAvailibility, activelyConsideredFiveMinuteWindow: Date, existingSiteVisitsForResourceDay: SiteVisit[]) {
  //Mutation defintion:
  // if the start time of a postceeding site visit is before the actively considered window, that is a mutation of site visit order.
  // if the start time of a preceeding site visit is after the actively considered window, that is a mutation of site visit order.
  const postceedingMutationCount = existingSiteVisitsForResourceDay.filter(x => avail.postceedingSiteVisits.findIndex(p => p.DocId() === x.DocId()) > -1)
    .filter(x => x.startDate.getTime() < activelyConsideredFiveMinuteWindow.getTime()).length;
  const preceedingMutationCount = existingSiteVisitsForResourceDay.filter(x => avail.preceedingSiteVisits.findIndex(p => p.DocId() === x.DocId()) > -1)
    .filter(x => x.startDate.getTime() > activelyConsideredFiveMinuteWindow.getTime()).length;
  return postceedingMutationCount + preceedingMutationCount;
}

public generateResourceGuidanceFavoringLowSiteVisitMutations(considerForTime: ResourceAvailibility[], activelyConsideredFiveMinuteWindow: Date, existingSiteVisitsForResourceDay: SiteVisit[]) {

    // const lowestNumberSiteVisitsMutated = considerForTime.sort((a, b) => a.numberSiteVisitsMutated - b.numberSiteVisitsMutated)[0].numberSiteVisitsMutated;
    const leastMutatedRunableSchedule = considerForTime.sort((a, b) => this.retrieveNumberOfSiteVisitOrderMutations(a,activelyConsideredFiveMinuteWindow, existingSiteVisitsForResourceDay) -
      this.retrieveNumberOfSiteVisitOrderMutations(b,activelyConsideredFiveMinuteWindow, existingSiteVisitsForResourceDay))[0];
    const lowestMutationCount = this.retrieveNumberOfSiteVisitOrderMutations(leastMutatedRunableSchedule,activelyConsideredFiveMinuteWindow, existingSiteVisitsForResourceDay);
    let filteredResults = considerForTime.filter(x => this.retrieveNumberOfSiteVisitOrderMutations(x,activelyConsideredFiveMinuteWindow, existingSiteVisitsForResourceDay) === lowestMutationCount);
    const lowestCommuteMinutesDelta = Math.round(filteredResults.sort((a, b) => a.commuteMinutesDelta - b.commuteMinutesDelta)[0].commuteMinutesDelta);
    filteredResults = filteredResults.filter(x => Math.round(x.commuteMinutesDelta) === lowestCommuteMinutesDelta);
    return this.copyResourceAvailibility(filteredResults.sort((a, b) => a.numberMinutesMutated(activelyConsideredFiveMinuteWindow) - b.numberMinutesMutated(activelyConsideredFiveMinuteWindow))[0]);
  }

  private generateResourceGuidanceFavoringLowCommuteTimes(considerForTime: ResourceAvailibility[], activelyConsideredFiveMinuteWindow: Date) {

    const lowestCommuteMinutesDelta = Math.round(considerForTime.sort((a, b) => a.commuteMinutesDelta - b.commuteMinutesDelta)[0].commuteMinutesDelta);
    let filteredResults = considerForTime.filter(x => Math.round(x.commuteMinutesDelta) === lowestCommuteMinutesDelta);
    const lowestNumberSiteVisitsMutated = filteredResults.sort((a, b) => a.numberSiteVisitsMutated - b.numberSiteVisitsMutated)[0].numberSiteVisitsMutated;
    filteredResults = filteredResults.filter(x => x.numberSiteVisitsMutated === lowestNumberSiteVisitsMutated);
    return this.copyResourceAvailibility(filteredResults.sort((a, b) => a.numberMinutesMutated(activelyConsideredFiveMinuteWindow) - b.numberMinutesMutated(activelyConsideredFiveMinuteWindow))[0]);
  }

  private copyResourceAvailibility(resourceAvailibility: ResourceAvailibility) {
    const retVal = new ResourceAvailibility(resourceAvailibility);
    retVal.startDate = new Date(resourceAvailibility.startDate);
    retVal.endDate = new Date(resourceAvailibility.endDate);
    retVal.actualDate = new Date(resourceAvailibility.actualDate);
    return retVal;
  }

  trimActiveFromBetters(betters: ResourceAvailibility[], active: ResourceAvailibility ): ResourceAvailibility[] {
    const trimmed = [];
    if (betters.length === 0) {
      return [active];
    } else {
      // Current slot only contributes to overall resource availibility if "better" scheduling solution doesn't cover it's time frame.
      for (const better of betters) {
        // Carve time off active slot ( if better ends after beginning of active and starts b/f beginning. )
        if (better.endDate.getTime() > active.startDate.getTime() && better.startDate.getTime() <= active.startDate.getTime()) {
          active.startDate = new Date(better.endDate);
        }
        // if better starts b/f end active and ends after active.
        if (better.startDate.getTime() < active.endDate.getTime() && better.endDate.getTime() >= active.endDate.getTime()) {
          active.endDate = new Date(better.startDate);
        }

        // if better is an enclosed subset of active, carve active into two and append both.
        if (better.startDate.getTime() >= active.startDate.getTime() && better.endDate.getTime() < active.endDate.getTime()) {
          const firstActiveSegment = new ResourceAvailibility(active);
          firstActiveSegment.endDate = new Date(better.startDate);
          const secondActiveSegment = new ResourceAvailibility(active);
          secondActiveSegment.startDate = new Date(better.endDate);
          return this.trimActiveFromBetters(betters, firstActiveSegment).concat(this.trimActiveFromBetters(betters, secondActiveSegment));
        }
        // if active is a fully enclosed subset of better(s)
        if (active.startDate.getTime() >= active.endDate.getTime()) {
          return [];
        }
      }
      return [active];
    }
  }

  findClosestAvailiable(startDate: Date, availibilityForResourceDay: ResourceAvailibility[]): Date {
    const open = availibilityForResourceDay.filter(x => x && x.available);
    // if startDate is within an open, then return start date.
    if (open.some(x => x.startDate.getTime() <= startDate.getTime() && x.endDate.getTime() > startDate.getTime())) {
      return startDate;
    }

    // else return slot up to specifed maximum after explicit slot.
    const tenMinutesLater = addMinutes(startDate, this.settingsService.getValue("minutesToShiftToMoveDropToValidLocation"));
    if (open.some(x => x.startDate.getTime() <= tenMinutesLater.getTime() && x.endDate.getTime() >= startDate.getTime())) {
      return new Date(open.find(x => x.startDate.getTime() <= tenMinutesLater.getTime() &&
        x.endDate.getTime() >= startDate.getTime()).startDate);
    }

    // else return slot up to specifed maximum before explicit slot.
    const tenMinutesBefore = subMinutes(startDate, this.settingsService.getValue("minutesToShiftToMoveDropToValidLocation"));
    if (open.some(x => tenMinutesBefore.getTime() <= x.endDate.getTime() && x.startDate.getTime() <= startDate.getTime())) {
      return new Date(open.find(x => tenMinutesBefore.getTime() <= x.endDate.getTime() &&
        x.startDate.getTime() <= startDate.getTime()).startDate);
    }

    // else return start date.
    return startDate;
  }

}
