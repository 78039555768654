<!-- <button (click)="testIt()">Test It</button> -->
<div #fullPage class="page-layout">
  <app-navigation></app-navigation>
  <app-header
  [viewWindowMovementEnabled] = "viewWindowMovementEnabled$ | async"
  [onDeckJob] = "onDeckJobRespectMinVisitLength$ | async"
  (employeesRemovedFromTreeDisplay$) = "employeesRemovedFromTreeDisplay$.next($event)"
  (employeesAddedToTreeDisplay$) = "employeesAddedToTreeDisplay$.next($event)"
  (updatedViewWindow$) = "updatedViewWindow$.next($event)"
  (updateNumberWorkdaysToDisplay$) = "updateNumberWorkdaysToDisplay$.next($event)"
  (retreatViewWindow$) = "changeViewWindowStart$.next(-1)"
  (advanceViewWindow$) = "changeViewWindowStart$.next(1)"
  (viewWindowSetToToday$) = "setViewWindowToToday$.next(null)"
  [viewWindowStartDate] = "currentStartDate"
  [viewWindowEndDate] = "currentEndDate"
  (viewWindowUpdatedViaCalendar$) = "viewWindowUpdatedViaCalendar$.next($event)"
  (completedSchedulingFromOnDeck$) = "completedSchedulingFromOnDeck$.next(null)"
  (schedulerLater$) = "schedulerLater$.next(null)"
  [numberWorkDaysDisplayed] = "numberWorkDaysToDisplay"
  (filterSearch$) = "filterSearch$.next($event)"
  (allowedCommuteSlider$) = "filterResourceCommuteDeltaTime$.next($event)"
  (prospectiveDurationHourUpdate$) = "changeLengthOfSiteVisitBeingScheduled$.next($event)"
  (toggleSideNav$) = "toggleSideNav$.next(null)"
  [numberUnassigned] = "(jobsNeedingAssignment$ | async).length"
  ></app-header>
  <mat-sidenav-container #sidenavContainer fxFill>
      <mat-sidenav  style="height: 100%;" #sidenav opened mode="side" position="end"
      [ngStyle]="getOnDeckWidthStyling()" [opened]="sideNavOpen">
      <div fxLayout='row' style="height: 100%;">
        <button class="unsc-drag-bar" draggable="true" (drag)="dragUnassignedWorkSplitter($event)" (dragstart)="dragStartUnassignedWorkSplitter($event)" (dragend)="dragEndUnassignedWorkSplitter($event)"><mat-icon class="helme" style="position: relative; left: -7px;">code</mat-icon></button>
      <app-sidebar style="width: 100%; padding: 0 10px 0 30px;"
      [jobsNeedingAssignment] = "jobsNeedingAssignment$ | async"
      (selectedJob) = "unassignedWorkClickHandler$.next($event)"
      [draggingSidebar] = "draggingWorkSplitter$"
      ></app-sidebar>
    </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div fxFill *ngIf="loadedConfig$ | async">
      <bry-scheduler fxFill
      #scheduler1
      [rowHeight]         = "schedulerConfig.rowHeight"
      [minHeight]         = "schedulerConfig.minHeight"
      [startDate]         = "schedulerConfig.startDate"
      [endDate]           = "schedulerConfig.endDate"
      [crudManager]       = "schedulerConfig.crudManager"
      [eventColor]        = "schedulerConfig.eventColor"
      [eventStyle]        = "schedulerConfig.eventStyle"
      [eventRenderer]     = "schedulerConfig.eventRenderer"
      [eventBodyTemplate] = "schedulerConfig.eventBodyTemplate"
      [timeRanges]        = "schedulerConfig.timeRanges"
      [timeResolution]    = "schedulerConfig.timeResolution"
      [mode]              = "schedulerConfig.mode"
      [resourceColumns] = "schedulerConfig.resourceColumns"
      [scheduleTooltipFeature] = "schedulerConfig.scheduleTooltipFeature"
      [timeRangesFeature] = "schedulerConfig.timeRangesFeature"
      [eventTooltipFeature] = "schedulerConfig.eventTooltipFeature"
      [eventDragCreateFeature] = "schedulerConfig.eventDragCreateFeature"
      [eventDragFeature] = "schedulerConfig.eventDragFeature"
      [eventResizeFeature] = "schedulerConfig.eventResizeFeature"
      [viewPreset] = "schedulerConfig.viewPreset"
      [multiEventSelect] = "true"
      [useInitialAnimation] = "schedulerConfig.useInitialAnimation"
      [resourceTimeRangesFeature] = "true"
      [resourceMargin] = "schedulerConfig.resourceMargin"
      [headerContextMenuFeature]="false"
      [contextMenuFeature]="false"
      [scheduleContextMenuFeature]="schedulerConfig.schedulerContextMenuFeature"
      [eventContextMenuFeature]="schedulerConfig.eventContextMenuFeature"
      [events] = "events"
      [resources] = "resources"
      [assignments] = "assignments"
      [resourceTimeRanges] = "resourceAvailibility"
      [tickWidth]="70"
    ></bry-scheduler>
    </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
