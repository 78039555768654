import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service';
import { CustomerService } from './customer.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { EmployeeService } from './employee.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { InvoicePaymentService } from './invoice-payment.service';
import { JournalEntry } from '../dao/journal-entry';

@Injectable({
  providedIn: 'root'
})
export class JournalEntryService extends DatabaseStoreService<JournalEntry> {

  constructor( fs: JournalEntryFirestoreService, authenticationService: AuthenticationService, store: JournalEntryStoreService, private customerService: CustomerService,
    private employeeService: EmployeeService, private invoicePaymentService: InvoicePaymentService) {
super(fs, store, authenticationService,
  new Map([["billingCustomer", {associatedDocumentId: "billingCustomerDocId",compositionStoreService: customerService}],
  ["employee", {associatedDocumentId: "employeeDocId",compositionStoreService: employeeService}],
  ["employeeVoiding", {associatedDocumentId: "employeeVoidingDocId",compositionStoreService: employeeService}],
  ["invoicePayments", {associatedDocumentId: "invoicePaymentDocIds",compositionStoreService: invoicePaymentService}]]));
  }

}

@Injectable({
  providedIn: 'root'
})
export class JournalEntryStoreService extends StateChangeStoreService<JournalEntry> {
  protected store = "JournalEntry-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, JournalEntry>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class JournalEntryFirestoreService extends FirestoreBackend<JournalEntry> {

 protected basePath = "JournalEntry";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): JournalEntry {
return new JournalEntry(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new JournalEntry(), authService);
 }
}
