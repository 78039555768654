import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firestore } from 'firebase-admin';
import { CustomerCommunication, emailDeliveryInfo, smsDeliveryInfo } from '../../../../../common/src/data/dao/customer-communication';
import { CustomerCommunicationType } from '../../../../../common/src/data/dao/customer-communication-template';


@Component({
  selector: 'app-customer-communications-delivery-details-modal',
  templateUrl: './customer-communications-delivery-details-modal.component.html',
  styleUrls: ['./customer-communications-delivery-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerCommunicationsDeliveryDetailsModalComponent implements OnInit {

  get deliveryDetailsSms() : smsDeliveryInfo { return this.deliveryDetails as smsDeliveryInfo; }
  get deliveryDetailsEmail() : emailDeliveryInfo { return this.deliveryDetails as emailDeliveryInfo; }
  deliveryDetails: smsDeliveryInfo | emailDeliveryInfo;
  deliveryDetailType: CustomerCommunicationType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CustomerCommunicationsDeliveryDetailsModalComponent>)  {
    const customerCommunication = data as CustomerCommunication;
    this.deliveryDetails = customerCommunication.deliveryInfo;
    this.deliveryDetailType = customerCommunication.customerCommunicationType;
  }

  dateFromTimeStamp(t: firestore.Timestamp) : Date {
    return t.toDate();
  }

  ngOnInit(): void {
  }

  exit(){
    this.dialogRef.close();
  }

}
