import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import { JobAttentionRequired } from '../../../../../common/src/data/dao/job-attention-required';

@Component({
  selector: 'app-job-attention-required-table',
  templateUrl: './job-attention-required-table.component.html',
  styleUrls: ['./job-attention-required-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobAttentionRequiredTableComponent implements OnInit,AfterViewInit {

@Input() alerts: JobAttentionRequired[];

cols =  ["jobAttentionRequiredReason","jobAttentionRequiredSubReason","notes","employee","dateCreated"];

  constructor(public employeeService: EmployeeService) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

}
