<div class="settings-page-height">
<app-navigation></app-navigation>
<mat-sidenav-container autosize>
  <!-- Side Navigation -->
  <mat-sidenav opened="true" mode="side" #sidenav class="example-sidenav">
    <mat-nav-list>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-scheduler-settings']}}]"> Scheduler</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-company-settings']}}]"> Company</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-communication-preference-settings']}}]">Customers</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-status-settings']}}]">Tags ?and? Statuses</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-jobtype-settings']}}]">Job Types</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-pricebook-settings']}}]">Price Book</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-role-settings']}}]">Roles and Skills</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-billing-settings']}}]">Billing</a>
        <a mat-list-item routerLinkActive="active" [routerLink]="[{outlets: {'settings' : ['app-workflow-settings']}}]">Workflow</a>
    </mat-nav-list>
  </mat-sidenav>
  <!-- Main Content -->
  <mat-sidenav-content class="example-sidenav-content">
    <router-outlet name="settings"></router-outlet>
    <mat-label>
      Version: {{versionInfo}} - {{versionTime | date:'medium'}}
    </mat-label>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>
