import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { Attachment } from '../dao/attachment';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends DatabaseStoreService<Attachment> {

  constructor( fs: AttachmentFirestoreService, store: AttachmentStoreService, authenticationService: AuthenticationService) {
super(fs, store, authenticationService);
  }

}

@Injectable({
  providedIn: 'root'
})
export class AttachmentStoreService extends StateChangeStoreService<Attachment> {
  protected store = "Attachment-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, Attachment>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class AttachmentFirestoreService extends FirestoreBackend<Attachment> {

 protected basePath = "Attachment";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Attachment {
return new Attachment(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new Attachment(), authService);
 }
}
