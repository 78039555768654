<div class="container-cus">
  <div class="left-side"></div>
  <div class="section-cus">
    <div class="content-line-item">

    <!-- BEGIN SECTION INCLUDED ONLY IF USER CLICKED ADD NEW LINE ITEM -->
    <ng-container *ngIf="!lineItemPassedIntoControl">
      <div  class="header-label" style="grid-column: 1/3; width: 100%; border: none;">Add Line Item</div>

    <!-- START OF ITEM SEARCH AUTO COMPLETE -->
    <div class="label-style-cust header-bg-color">Line Items Search</div>
    <div style="width: 100%; display: flex;" class="header-bg-color">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:280px;">
        <input type="text"
               placeholder="Search"
               matInput
               [formControl]= "lineItemAutoCompleteControl"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayLineItemName">
          <mat-option *ngFor="let lineItem of filteredTopLevelLineItems | async" [value]="lineItem">
            <!-- styling specific to add New goes here. -->
            <div *ngIf="lineItem.DocId() === undefined else standardBlock" style="color:red;">
              {{lineItem.title}}
            </div>
            <!-- styling for the standard prototypical line item goes here. -->
            <ng-template #standardBlock>
              <div>
                {{lineItem.title}}
              </div>
            </ng-template>
          </mat-option>
        </mat-autocomplete>
        </mat-form-field>
        </div>
        <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Search and select from current line items.'
    >info</mat-icon>
        </div>
    <!-- END OF ITEM SEARCH AUTO COMPLETE -->
    </ng-container>
    <!-- END SECTION INCLUDED ONLY IF USER CLICKED ADD NEW LINE ITEM -->

    <!-- BEGIN SECTION INCLUDED ONLY IF USER CLICKED EDIT LINE ITEM -->
    <ng-container *ngIf="lineItemPassedIntoControl">
      <div  class="header-label" style="grid-column: 1/3; width: 100%; margin-bottom: 20px;">Edit Line Item</div>
    </ng-container>
    <!-- END SECTION INCLUDED ONLY IF USER CLICKED EDIT LINE ITEM -->


  <form class="line-item-creation" [formGroup]="form" style="display: contents;">
    <!-- Begin Title -->
    <div class="label-style-cust">Line Item Title</div>
    <div style="width:280px; display: flex;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:280px;">
        <input placeholder="Title" matInput formControlName="title" #titleInput
        [name]="ElementNameForId('585515')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        <mat-error *ngIf="form.controls['title'].errors?.required">Title is required</mat-error>
        <mat-error *ngIf="form.controls['title'].errors?.minlength">Title must be at least 3 characters long.</mat-error>
        <mat-error *ngIf="form.controls['title'].errors?.lineItemNameDistinct">Title must be distinct.</mat-error>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Name for this line item. This will either populate with the line item name when a line items is selected from the search or you can name a new line item and save it.'
>info</mat-icon>
    </div>
    <!-- End Title -->
    <!-- Begin Description -->
    <div class="label-style-cust">Description</div>
    <div style="width:280px; display: flex; margin-bottom: 20px;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:380px; line-height: 1.4;">
        <textarea cdkTextareaAutosize placeholder="Description" matInput formControlName="description"
        [name]="ElementNameForId('575313')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </textarea>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Description of the Line Item'
>info</mat-icon>
    </div>
    <!-- End Description -->

    <!-- Required Skills -->
    <app-mulliselect-nested-tree style="grid-column: 1/3; margin-bottom: 30px; margin-left: 30px;"
    [checkListDatabase]="LineItemSkillCheckListDatabase()"
    (itemsAddedToSelection$)="skillAddedToLineItem($event)"
    (itemsRemovedFromSelection$)="skillRemovedFromLineItem($event)"
    ></app-mulliselect-nested-tree>
    <!-- End Required Skills -->
    <!-- Days -->
      <div class="label-style-cust">Estimated Days (12h)</div>
      <div style="width:280px; display: flex; margin-bottom: 20px;">
        <div class="form-align">
          <mat-form-field appearance="outline" style="width:280px;">
            <input placeholder="Days" matInput formControlName="daysLineItemDuration"
            [name]="ElementNameForId('582615')" autofill='off' autocomplete='new-password' data-lpignore='true' inputmode="numeric"
              type="number" min="0" step="1" oninput="validity.valid||(value='');">
          </mat-form-field>
        </div>
      </div>

    <!-- Begin Estimated Time Duration One Unit-->
      <div class="label-style-cust">Estimated Hours and Minutes</div>
    <div style="width:280px; display: flex;">
      <div class="form-align">
        <div>
          <app-time-field style="width: 130px;"
          [value]= "timeForSingleQuantityAsDate"
          [fieldLabel]= "'Time for single quantity'"
          [readOnly]="false"
          [granularity]="5"
          (valueChange) = "timeToCompleteSingleLineItem$.next($event)"
          [minDate]="zeroHoursAsDate"
          [maxDate]="maximumTimeOneLineItem"
          ></app-time-field>
        </div>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Estimated total time this single line item is expected to take'
>info</mat-icon>
    </div>
    <!-- End Estimated Time Duration One Unit-->
    <!-- Duration one line item -->
    <div class="label-style-cust">Duration:</div>
    <div style="width:280px; display: flex;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:130px;">
        <input placeholder="Total Duration" matInput  formControlName="expectedDurationOneLineItemString"
        [name]="ElementNameForId('694145')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='"Quantity" multiplied by "Estimated Time Duration"'
>info</mat-icon>
    </div>
    <!-- End Duration one line item -->
    <!-- Begin Unit Price-->
    <div class="label-style-cust">Unit Price</div>
    <div style="width:280px; display: flex;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:130px;">
        <input currencyMask [options]="{align: 'left'}" placeholder="Unit Price" matInput formControlName="price" inputmode="numeric"
        [name]="ElementNameForId('5753134')" autofill='off' autocomplete='new-password' data-lpignore='true' >
        <mat-error *ngIf="form.controls['price'].errors?.required">Price is required</mat-error>
        <mat-error *ngIf="form.controls['price'].errors?.min">Price can not be below $0.00</mat-error>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Default price to assign to this line item'
>info</mat-icon>
    </div>
    <!-- End Unit Price-->
    <!-- Begin Quantity-->
    <div class="label-style-cust">Quantity</div>
    <div style="width:280px; display: flex;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:130px;">
        <input placeholder="Quantity" matInput formControlName="quantity" inputmode="numeric"
        [name]="ElementNameForId('683944')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        <mat-error *ngIf="form.controls['quantity'].errors?.required">Quantity is required</mat-error>
        <mat-error *ngIf="form.controls['quantity'].errors?.min">Quantity must be 1 or more.</mat-error>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Denotes the total instances this project will be completed for this line item. For instance, if the line item description was "Clean fireplace chimney" and the quantity was 20 then the completion of this line item would occur when 20 chimney cleanings have been completed'
>info</mat-icon>
    </div>
    <!-- End Quantity-->
    <!-- Total Duration -->
    <div class="label-style-cust">Total Duration:</div>
    <div style="width:280px; display: flex;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:130px;">
        <input placeholder="Total Duration" matInput  formControlName="totalExpectedDurationString"
        [name]="ElementNameForId('694045')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='"Quantity" multiplied by "Estimated Time Duration"'
>info</mat-icon>
    </div>
    <!-- End Total Duration -->
    <!-- Total Price -->
    <div class="label-style-cust">Total Price:</div>
    <div style="width:280px; display: flex;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:130px;">
        <input placeholder="Total Price" currencyMask [options]="{align: 'left'}" matInput  formControlName="totalPrice"
        [name]="ElementNameForId('792336')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='"Quantity" multiplied by "Unit Price"'
>info</mat-icon>
    </div>
    <!-- End Total Price -->
    <!-- Begin internal notes. -->
    <div class="label-style-cust">Internal Notes</div>
    <div style="width:280px; display: flex; margin-bottom: 20px;">
      <div class="form-align">
        <mat-form-field appearance="outline" style="width:380px; line-height: 1.4;">
        <textarea cdkTextareaAutosize placeholder="Internal Notes" matInput formControlName="internalNotes"
        [name]="ElementNameForId('215313')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </textarea>
        </mat-form-field>
      </div>
      <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Any internal notes to make about this line item. These notes will never be seen by the customer.'
>info</mat-icon>
    </div>
    <!-- End internal notes. -->
    <!-- Cancel and submit buttons. -->
    <div fxLayout="row" class="footer-cust" style="display: flex; justify-content: space-between; grid-column: 1/3; padding-top: 20px;">
      <button mat-button class="button-gray" (click)="Exit()">Cancel</button>
      <button mat-button class="button-blue" (click)="Save()" type="submit">Save</button>
    </div>
  </form>

</div>
  </div>
  <div class="right-side"></div>
</div>
