import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../dao-services/job.service';
import { Job } from '../dao/job';
import { searchResults, SearchService, searchSource } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {


  constructor(private jobService: JobService) { }

  componentSourceToSearchTerm: Map<string,string> = new Map<string,string>();
  searchSources: searchSource[] = [searchSource.InternalServiceProviderJobNumber, searchSource.InternalServiceAddress,searchSource.InternalCustomerName,
    searchSource.InternalCustomerPhone,searchSource.InternalCustomerEmail,searchSource.InternalCustomerCompany];

  addSearch(searchService: SearchService, componentSource: string, searchTerm: string) {
    // replace enter key with '' in search term
    searchTerm = searchTerm.replace(/\n/g, '');
      this.componentSourceToSearchTerm.set(componentSource,searchTerm);
      searchService.addSearch({search: searchTerm, componentSource: componentSource, searchSources: this.searchSources});
  }

  observeResults(searchService: SearchService, componentSource: string, requireJobPresentInResult: boolean) :  Observable<{res: searchResults, job: Job}[]> {

    // This is needed b/c we want to share the results of customerSearchService.searchResults$ but if
    // we just use share() then it makes source observable hot which causes it to not finalize because of the
    // associated subjects that are subscribed in the queryFirestoreDeep() .......
    const deduplicatedSearchResultsToShare = new Subject<searchResults[]>();

    const searchResultsDeDuplicated = searchService.searchResults$.pipe(
    filter(x => x.searchInput.componentSource ===  componentSource && x.searchInput.search === this.componentSourceToSearchTerm.get(componentSource)),
    map(x => {
      const retVal:searchResults[] = [];
      const customerDocsAdded = [];
      x.results.forEach(res => {
        if (res.job) {
          retVal.push(res);
        } else {
          if (!res.customer || !customerDocsAdded.includes(res.customer.DocId())) {
            retVal.push(res);
            if (res.customer) {
              customerDocsAdded.push(res.customer.DocId());
            }
          } else
          {
            if (!res.customer) {
              console.error(res);
            }
          }
        }
      })
      return retVal;
    }),
    delay(1),
    tap(x => deduplicatedSearchResultsToShare.next(x)),
    );

  const jobsAsBillingCustomers = deduplicatedSearchResultsToShare.pipe(
    map(x => x.filter(y => !y.job)),
    switchMap(x => this.jobService.queryFirestoreForInValues('billingCustomerDocIds',x.map(y => y.customer.DocId()),true )));

  const jobsAsContactCustomers = deduplicatedSearchResultsToShare.pipe(
    map(x => x.filter(y => !y.job)),
    switchMap(x => this.jobService.queryFirestoreForInValues('siteVisitContactCustomerDocIds',x.map(y => y.customer.DocId()),true )));

  const explictJobs = deduplicatedSearchResultsToShare.pipe(
    map(x => x.filter(y => y.job)),
  );

  const allJobs= combineLatest([jobsAsBillingCustomers,jobsAsContactCustomers,explictJobs]).pipe(
    map(([bill,contact,explictJobs]) => {
      const jobs = explictJobs.map(x => x.job);
      const jobDocIds = jobs.map(b => b.DocId());

      bill.forEach(job => {
        if (!jobDocIds.includes(job.DocId())) {
          jobs.push(job);
          jobDocIds.push(job.DocId());
        }
      });

      contact.forEach(job => {
        if (!jobDocIds.includes(job.DocId())) {
          jobs.push(job);
        }
      });
      return jobs;
    }),
    switchMap(j => this.jobService.loadMultiple$(j.map(j => j.DocId()))),
    map(jobs => {
      jobs.sort((a, b) => {
        return a.startDate < b.startDate ? 1 : -1;
      });
      return jobs;
    }),
    );

    return combineLatest([searchResultsDeDuplicated,allJobs]).pipe(
      map(([x,jobs]) => {
        const retVal : {res: searchResults, job: Job}[] = x.filter(z => z.resultSource !== searchSource.InternalServiceAddress &&
          z.resultSource !== searchSource.InternalServiceProviderJobNumber).map(y => {
            if (y.job) {
              return {res: y, job: y.job};
            } else {
            return {res: y, job: null};
            }
        });
        // if there are both custoemr results and job results, we add nulled value to add divider.
        if ((retVal.length > 0 && jobs.length > 0) || (retVal.length === 0 && jobs.length === 0) && !requireJobPresentInResult) {
          retVal.push({res:null, job:null});
        }
        const jobDocIdsAdded: string[] = [];
        x.forEach(searchResult => {
          console.warn(searchResult);
          jobs.filter(job => (searchResult.job && job.DocId() === searchResult.job.DocId()) ||
          job.siteVisitContactCustomerDocIds.includes(searchResult.customer.DocId()) || job.billingCustomerDocIds.includes(searchResult.customer.DocId())).forEach(job => {
            if (!jobDocIdsAdded.includes(job.DocId())) {
              jobDocIdsAdded.push(job.DocId());
            retVal.push({res: searchResult, job: job});
            }
          });
        });
        if (requireJobPresentInResult) {
          return retVal.filter(x => x.job !== null)
        }
        else {
          return retVal;
        }
      }),
      )
  }
}
