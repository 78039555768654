import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, merge, Observable, of,  ReplaySubject,  Subject, zip } from 'rxjs';
import { filter, map, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DataLinkService, DataRetrievedFromDataLink, DataUsedByDataLinkService } from '../../data-link-populator/data-link.service';
import { FormFirestoreService } from '../../../../../../common/src/data/dao-services/form-firestore.service';
import { FetchUpdatedWorkflowService, WORKFLOW_STAGE } from './utilities/fetch-updated-workflow.service';
import { FormFirestore } from '../../../../../../common/src/data/dao/form-firestore';
import { FormModelFirestore } from '../../../../../../common/src/data/dao/form-model-firestore';
import { FormFirestoreSummaryService } from '../../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { LineItem } from '../../../../../../common/src/data/dao/line-item';
import { LoggingService } from '../../../../../../common/src/data/logging/logging.service';


export enum Audience {
  All = 1,
  Internal = 2,
  Customer = 3
}

@Injectable({
  providedIn: 'root'
})
export class FormlyUtilityService {

  designMode: boolean = false;
  formlyFieldRequiringInitialObservableEmission: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(new Set<string>());
  skippedFirstPopulatedEmission: boolean = false;
  _workFlowStage: WORKFLOW_STAGE = WORKFLOW_STAGE.DEPLOYED;

  get workFlowStage() : WORKFLOW_STAGE { return this._workFlowStage; }
  set workFlowStage(value: WORKFLOW_STAGE) {
    this._workFlowStage = value;
    this.fetchUpdatedWorkflowService.workFlowStage = value;
  }

  disabledForms: boolean = false;
  expandLineItems: boolean = false;
  customerFacing: boolean = false;
  completedLineItemOperation$ : ReplaySubject<LineItem> = new ReplaySubject<LineItem>(1);

  disableFormInput$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  sidebarDebounceActive$ = new BehaviorSubject(false);
  debounceDuringCheckboxValueChanges$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  // Design Concerns
  addFormFirestoreToFormDesigner$: Subject<{firestoreSummaryDocId: string, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string}> =
    new Subject<{firestoreSummaryDocId: string, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string}>();
  sectionKeyAddedToFormDesigner$: Subject<{sectionKey: string, firestoreDocId: string, source: string}> = new Subject<{sectionKey: string, firestoreDocId: string, source: string}>();
  removeSectionFromDesignView$ : Subject<{formFirestoreSummaryDocId: string, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string}> =
    new Subject<{formFirestoreSummaryDocId: string, originSourceGuid: string, parentContainerGuid: string, parentDropZoneId: string}>();

  //Concerns in updating tech view based on design view updates.
  patchFormFirestoreToTechViewFromDesign$: Subject<FormFirestore> = new Subject<FormFirestore>();
  saveFormModelFirestoreUpdates: boolean = true;

  // Tech View Concerns.
  spliceWorkflowSelectedFromBranchingControl$: Subject<{spliceKey: string, parentId: string | null, formlyConfig: FormlyFieldConfig}> =
    new Subject<{spliceKey: string, parentId: string | null, formlyConfig: FormlyFieldConfig}>();
  removeSectionFromWorkflow$: Subject<{sectionKey: string, parentId: string | null, branchKey: string | null, originatedFromSection: boolean}> = new Subject<{sectionKey: string, branchKey: string | null,
    parentId: string | null, originatedFromSection: boolean}>();
  sectionRemovedFromTechView$: Subject<{removedKey: string, branchingKey: string, parentId: string | null, originatedFromSection: boolean, workflowDocId: string}> =
    new Subject<{removedKey: string, branchingKey: string, parentId: string | null, originatedFromSection: boolean, workflowDocId: string}>();

    removeSectionFromBranch$ : Subject<{sectionDocId: string, parentId: string, branchKey: string}> = new Subject<{sectionDocId: string, parentId: string, branchKey: string}>();

  saveFormModelExplicit$: Subject<any> = new Subject<any>();
  activeSaveInPipe$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private parentIdBranchKeySectionDocIdToLeafKey : Map<string,Map<string,Map<string,string>>> = new Map<string,Map<string,Map<string,string>>>()

  addLeafKeyToBranchKeyWithParent$: Subject<{branchKey: string, leafKey: string, parentId: string | null, docId: string}> = new Subject<{branchKey: string, leafKey: string,
    parentId: string | null, docId: string}>();

  _activeFormModelFirestore: FormModelFirestore = undefined;

  get activeFormModelFirestore(): FormModelFirestore { return this._activeFormModelFirestore; }
  set activeFormModelFirestore(value: FormModelFirestore) {
    this._activeFormModelFirestore = value;
  }

  private uuid: string;
  get uuidAssociatedWithFormInstance(): string { return this.uuid; }

  sectionKeysUpdated: string[] = [];
  activeAudience: Audience = Audience.Internal;
  triggerReload$ : Subject<null> = new Subject<null>();

  constructor(private formFirestoreService: FormFirestoreService, private formFirestoreSummaryService : FormFirestoreSummaryService,
    private fetchUpdatedWorkflowService: FetchUpdatedWorkflowService, private loggingService: LoggingService) {

      this.resetSectionMappings();
      this.setupAddLeafKeyToBranchKeyObservable();
      this.setupRemoveSectionFromTechViewObservable();

      this.activeSaveInPipe$.next(false);
      this.debounceDuringCheckboxValueChanges$.next(false);

      this.fetchUpdatedWorkflowService.updatedFormModelFirestore$.pipe(
        tap(x => console.log("BEARS")),
      ).
      subscribe(this.saveFormModelExplicit$);
   }

  resetBranchesAndLeafs() : void {
    this.resetSectionMappings();
    this.activeFormModelFirestore = undefined;
  }

  loggers() : void {
    console.log(this.parentIdBranchKeySectionDocIdToLeafKey);
    console.log(this.sectionKeysUpdated);
  }

  resetSectionMappings() : void {
    this.parentIdBranchKeySectionDocIdToLeafKey.clear();
    this.parentIdBranchKeySectionDocIdToLeafKey.set("top_level", new Map<string,Map<string,string>>());
  }

  setupAddLeafKeyToBranchKeyObservable() {
    this.addLeafKeyToBranchKeyWithParent$.pipe(
      tap(x => {
        if (!this.parentIdBranchKeySectionDocIdToLeafKey.has(x.parentId)) {
          this.parentIdBranchKeySectionDocIdToLeafKey.set(x.parentId, new Map<string,Map<string,string>>());
        }
        if (!this.parentIdBranchKeySectionDocIdToLeafKey.get(x.parentId).has(x.branchKey)) {
          this.parentIdBranchKeySectionDocIdToLeafKey.get(x.parentId).set(x.branchKey, new Map<string,string>());
        }
        this.parentIdBranchKeySectionDocIdToLeafKey.get(x.parentId).get(x.branchKey).set(x.docId, x.leafKey);
      }),
      takeUntil(this.formFirestoreService.destroyingComponent$)
    ).subscribe();
  }

  setupRemoveSectionFromTechViewObservable() {
    this.removeSectionFromWorkflow$.pipe(
      tap(x => console.log(x,` string`)),
      tap(removedSectionKey => {
        if (removedSectionKey.branchKey) {
          this.sectionRemovedFromTechView$.next({removedKey: removedSectionKey.sectionKey, branchingKey: removedSectionKey.branchKey,
            parentId: removedSectionKey.parentId, originatedFromSection: removedSectionKey.originatedFromSection, workflowDocId: removedSectionKey.sectionKey.split("-0")[0]});
        }
      }),
      takeUntil(this.formFirestoreService.destroyingComponent$)
    ).subscribe();
  }

  leafKeyFromDocAndBranchKey(docId: string, branch: string, parentId: string) : string {
    return this.parentIdBranchKeySectionDocIdToLeafKey.get(parentId)?.get(branch)?.get(docId);
  }

  generateNewFormInstanceUuid() {
    this.uuid = Math.random().toString(36).substring(0, 10);
  }

  sortAdditionsToCheckboxControl(toAdd: string[], order: string[]) : string[] {
    return toAdd.sort((a,b) => order.indexOf(b) - order.indexOf(a));
  }

  getSection(contents: FormlyFieldConfig[] | FormlyFieldConfig, designView: boolean = false, title?: string, formFirestoreSummaryDocId?: string,
    formFirestoreDocId?: string, sourceKey?: string) : FormlyFieldConfig {
    if (contents['type'] !== undefined &&  contents['type'] === "formlySection") {
      (contents as FormlyFieldConfig).props.title = title;
      (contents as FormlyFieldConfig).props.sourceKey= sourceKey;
      (contents as FormlyFieldConfig).key= `${formFirestoreDocId}-${sourceKey}-section`;
      return contents as FormlyFieldConfig;
    } else {
      const retVal =  {
        type: 'formlySection',
        defaultValue: [{}],
        key: `${formFirestoreDocId}-${sourceKey}-section`,
        // key: `${Math.random().toString(36).substring(0, 9)}-section`,
        fieldGroup: designView ? [{props: {formFirestoreSummaryDocId:formFirestoreSummaryDocId}, fieldGroup:
          [{props: {formFirestoreSummaryDocId:formFirestoreSummaryDocId}, fieldGroup: [...(contents as FormlyFieldConfig[])]}]}]
          : [{props: {}, fieldGroup: [...(contents as FormlyFieldConfig[])]}],
        fieldArray: designView ?
        {fieldGroup: [{props: {formFirestoreSummaryDocId:formFirestoreSummaryDocId}, fieldGroup: [...(contents as FormlyFieldConfig[])]}]}
        : {fieldGroup: [...(contents as FormlyFieldConfig[])]},
        props: {
          className:"",
          itemName: "default item name",
          minimumNumberInstances: 1,
          maximumNumberInstances: 1,
          title: title,
          generatedFromBranch: true,
          sourceKey: sourceKey,
        formFirestoreDocId: formFirestoreDocId,
        formFirestoreSummaryDocId:formFirestoreSummaryDocId,
        },
        wrappers: [],
      };
      return retVal;
    }
    }

  addWorkflowFromBranchingUpdateIfNeededReturnSectionKey(formFirestoreDocId: string, sourceKey: string, parentId: string, workFlow: FormFirestore
    , formModelFirestoreDocId: string) : Observable<{sectionKey: string, newFormFirestoreDocId: string, oldFirestoreDocId: string}> {

      const checkForUpdateNeeded = this.fetchUpdatedWorkflowService.formFirestoreDocIdNeedsCheckedForUpdate(formFirestoreDocId, this.activeFormModelFirestore.DocId()) ||
      this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(this.activeFormModelFirestore.DocId()) === undefined ||
      this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(this.activeFormModelFirestore.DocId()).get(formFirestoreDocId) === undefined;

    let preUpdate = JSON.parse(workFlow.form);
    this.stripKeysAllTheWayDown(preUpdate);

    const retrieveUpdatedVersion = of(formFirestoreDocId).pipe(
      filter(() => checkForUpdateNeeded),
      // the fetchUpdatedWorkflowService handles refreshing the workflow when it's summary points to a different workflow for specified work flow stage.
      switchMap(() => this.fetchUpdatedWorkflowService.retrieveRefreshedFormFirestore(workFlow,formModelFirestoreDocId).pipe(take(1))),
      filter(w => w.form !== undefined),
      map(x => {
        return {val: x, previousForm: x.form};
      }),
      switchMap(w => this.updateSectionsIfNeeded(w.val, this.workFlowStage, formModelFirestoreDocId)),
      map(x => {
        const updated = JSON.parse(x);
        this.stripKeysAllTheWayDown(updated);
        return {updated, raw: x};
      }),
      share(),
    );

    const updated = retrieveUpdatedVersion.pipe(
      filter(x => JSON.stringify(x.updated) !== JSON.stringify(preUpdate)),
      tap(() => console.warn("UPDATED!@!!!!!!!!!!!!!!!")),
      tap(x => console.log(JSON.stringify(x.updated))),
      tap(() => console.log(JSON.stringify(preUpdate))),
      tap(x => this.loggingService.addLog("Updating the workflow", "formlyUtilityService", ({updated: JSON.stringify(x.updated),
        pre: JSON.stringify(preUpdate)}))),
      map(x => {
      const formFirestoreToCommit = new FormFirestore(workFlow);
      formFirestoreToCommit.form = x.raw;
      return formFirestoreToCommit;
      }),
      switchMap(x => this.formFirestoreService.retrieveDocId(x).pipe(
        map(() => x)
      )),
      tap(x => console.log(x,` string`)),
      switchMap(x => this.formFirestoreService.create$(x).pipe(
        tap(x => this.fetchUpdatedWorkflowService.patchInUpdatedDocId(x.DocId(), x.formSummaryDocId, this.activeFormModelFirestore.DocId())),
        take(1)
      )),
      map(x => {
        return {parsed: JSON.parse(x.form) as FormlyFieldConfig[], newFirestoreDocId: x.DocId()};
      }),
    ) as Observable<{parsed: FormlyFieldConfig[], newFirestoreDocId: string}>;

    const noUpdate = retrieveUpdatedVersion.pipe(
      filter(x => JSON.stringify(x.updated) === JSON.stringify(preUpdate)),
      tap(x => console.log("NO UPDATE!")),
      map(() => {
        return {parsed: JSON.parse(workFlow.form)  as FormlyFieldConfig[], newFirestoreDocId: null};
      })
    );

    const noUpdateCheckNeeded = of(null).pipe(
      filter(() => !checkForUpdateNeeded),
      tap(x => console.log("NO UPDATE CHECK NEEDED!")),
      map(() => {
        return {parsed: this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(this.activeFormModelFirestore.DocId()).get(formFirestoreDocId)  as FormlyFieldConfig[], newFirestoreDocId: null};
      })
    );

    //CHECK OUT 2023-06-07
    return merge(updated, noUpdate, noUpdateCheckNeeded).pipe(

      map(x => {
        const activeFormFirestoreDocId = x.newFirestoreDocId !== null ? x.newFirestoreDocId : formFirestoreDocId;
        console.log(activeFormFirestoreDocId);
        const section = this.getSection(x.parsed,false, workFlow.formSummary.title, workFlow.formSummary.DocId(), activeFormFirestoreDocId, sourceKey);
        section.props.generatedFromBranch = true;
        this.spliceWorkflowSelectedFromBranchingControl$.next({spliceKey: sourceKey, parentId, formlyConfig: section});
          this.addLeafKeyToBranchKeyWithParent$.next({branchKey: sourceKey, leafKey: section.key as string, parentId,
            docId: x.newFirestoreDocId !== null ? x.newFirestoreDocId : formFirestoreDocId});
        return {sectionKey: section.key as string, newFormFirestoreDocId: x.newFirestoreDocId, oldFirestoreDocId: formFirestoreDocId};
      }),
      take(1),
    );
  }

  resetLineItemsWaitingForFirstEmission() {
    this.formlyFieldRequiringInitialObservableEmission.value.clear();
    this.formlyFieldRequiringInitialObservableEmission.next(this.formlyFieldRequiringInitialObservableEmission.value);
    this.skippedFirstPopulatedEmission = false;
  }

  stripKeysAllTheWayDown(f: FormlyFieldConfig | FormlyFieldConfig[]) {
    if (f instanceof Array) {
      f.forEach(p => this.stripKeysAllTheWayDown(p));
    } else {
      if (f.type !== "formlyPageBreak" && f.type !== "formlyHeightSpacer")
      {
        if (f.props?.heightPixels) {
          f.props.heightPixels = undefined;
        }
      }
      f.key = undefined;
      f["_keyPath"] = undefined;
      f.id=undefined;
      f.props.imageHeightPixels = undefined;
      f.props.guid = undefined;
      f.props.indexInParentContainer = undefined;
      if (f.fieldGroup) {
        f.fieldGroup.forEach(g => this.stripKeysAllTheWayDown(g));
      }
      if (f.fieldArray) {
        (f.fieldArray as FormlyFieldConfig).fieldGroup.forEach(g => this.stripKeysAllTheWayDown(g));
      }
    }
  }

  getAudienceFilter(audience : Audience) : any {
    return audience === Audience.Customer ? (f: FormlyFieldConfig) => f.props.audience === undefined ||
    f.props.audience === Audience.Customer || f.props.audience === Audience.All :
    (f: FormlyFieldConfig) => f.props.audience === undefined || f.props.audience === Audience.Internal || f.props.audience === Audience.All;
  }

  setActiveViewAndDisabledTemplateOptionsAllTheWayDown(f: FormlyFieldConfig, activeView: string, disabled: boolean, audience: Audience, formControl: any | undefined = undefined,
      formModelFirestoreDocId: string) {

    const audienceFilter = this.getAudienceFilter(audience);

    if (f.props && f.props['focus'] !== undefined) {
      delete f.props['focus'];
    }
    // fix workflows that were built with no longer supported options, so they will render.
    if (f.props.appearance === "none") {
      f.props.appearance = "fill";
      f.props['cls'] = 'mat-form-field-appearance-none';
    }
    if (f.type === "formlyHeightSpacer") {
      if (f.wrappers.findIndex(x => x === 'form-field') !== -1) {
        f.wrappers.splice(f.wrappers.findIndex(x => x === 'form-field'), 1);
      }
    }
    f.props.activeView = activeView;
    f.props.disabled = disabled;
    if (f.fieldGroup) {
      const parentKeyMod = f.key ? (f.key as string).replace('0.', '') : undefined;
      f.fieldGroup = f.fieldGroup.filter(f => audienceFilter(f));
      f.fieldGroup.forEach(g => this.setActiveViewAndDisabledTemplateOptionsAllTheWayDown(g,activeView,disabled, audience,formControl === undefined ||
      parentKeyMod === undefined || (formControl as UntypedFormGroup).controls[0] === undefined
        ? undefined
        : (formControl as UntypedFormGroup).controls[0].get(parentKeyMod), formModelFirestoreDocId));
    }
    if (f.fieldArray) {
      (f.fieldArray as FormlyFieldConfig).fieldGroup = (f.fieldArray as FormlyFieldConfig).fieldGroup.filter(f => audienceFilter(f));
        (f.fieldArray as FormlyFieldConfig).fieldGroup.forEach(g => this.setActiveViewAndDisabledTemplateOptionsAllTheWayDown(g,activeView,disabled, audience,formControl === undefined ?
          undefined : (formControl as UntypedFormGroup).controls[0], formModelFirestoreDocId));
    }
  }

  recursivelyUpdateFormlySection(formFirestoreDocId: string, formlyFieldConfig: FormlyFieldConfig, workflowStage: WORKFLOW_STAGE, formModelFirestoreDocId: string,
      preUpdateFormFirestoreDocId: string | null = null) : Observable<{formlyFieldConfig:FormlyFieldConfig, containedFormFirestoreSummaryDocIds: string[]}> {

      let formFirestoreDocIdToCache = formFirestoreDocId;
      const refreshedWorkflow = this.fetchUpdatedWorkflowService.retrieveRefreshedFormFirestoreDocId(formFirestoreDocId, formModelFirestoreDocId).pipe(
      take(1),
      share()
    );

    const updatedSummary = refreshedWorkflow.pipe(
      filter(x => x !== formFirestoreDocId || (preUpdateFormFirestoreDocId !== null && x!== preUpdateFormFirestoreDocId)),
      tap(x => formFirestoreDocIdToCache = x),
      switchMap(x => this.formFirestoreService.load$(x)),
      map(updatedWorkflow => JSON.parse(updatedWorkflow.form) as FormlyFieldConfig),
      map(updatedWorkflow => {
        updatedWorkflow.props.itemName=formlyFieldConfig.props.itemName;
        updatedWorkflow.props.minimumNumberInstances=formlyFieldConfig.props.minimumNumberInstances;
        updatedWorkflow.props.maximumNumberInstances=formlyFieldConfig.props.maximumNumberInstances;
        updatedWorkflow.props.repeating=formlyFieldConfig.props.repeating;
        return updatedWorkflow;
      })
    );

    const sameSummary = refreshedWorkflow.pipe(
      filter(x => x === formFirestoreDocId && (preUpdateFormFirestoreDocId === null || x === preUpdateFormFirestoreDocId)),
      map(x => formlyFieldConfig),
    );

    return merge(updatedSummary,sameSummary).pipe(
      switchMap(x => {
        const subObs$ : Observable<{grouping: {formlyFieldConfig:FormlyFieldConfig, containedFormFirestoreSummaryDocIds: string[]}[],
          key: string}>[] = [];
        if (x.fieldGroup && x.fieldGroup.length > 0) {
          subObs$.push(zip(...x.fieldGroup.map(g => this.updateSectionsIfNeededAllTheWayDown(g,workflowStage, formModelFirestoreDocId))).pipe(
            map(g => {
              return {grouping: g, key: "fieldGroup"};
            }),
          ));
        }
        if (x.fieldArray && (x.fieldArray as FormlyFieldConfig).fieldGroup && (x.fieldArray as FormlyFieldConfig).fieldGroup.length > 0) {
          subObs$.push(zip(...(x.fieldArray as FormlyFieldConfig).fieldGroup.map(g => this.updateSectionsIfNeededAllTheWayDown(g,workflowStage, formModelFirestoreDocId))).pipe(
            map(g => {
              return {grouping: g, key: "fieldArray"};
            }),
          ));
        }
        if(subObs$.length === 0) {
          subObs$.push(of(null));
        }

        return zip(...subObs$).pipe(
          map(g => {
            if (g[0] !== null) {
              if (g[0].key === "fieldArray") {
                (x.fieldArray as FormlyFieldConfig).fieldGroup = g[0].grouping.map(y => y.formlyFieldConfig);
              } else {
                x[g[0].key] = g[0].grouping.map(y => y.formlyFieldConfig);
              }
            }
            return {formlyFieldConfig:x, containedFormFirestoreSummaryDocIds: g.map(y => y.grouping)
                                                                              .reduce((acc, val) => acc.concat(val), [])
                                                                              .map(y => y.containedFormFirestoreSummaryDocIds)
                                                                              .reduce((acc, val) => acc.concat(val), [])};
          }),
          tap(x => {
            const formFirestoreModelMapping = this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(formModelFirestoreDocId);
            if (formFirestoreModelMapping === undefined) {
              this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.set(formModelFirestoreDocId, new Map<string, FormlyFieldConfig>());
            }
            this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(formModelFirestoreDocId).set(formFirestoreDocIdToCache, x.formlyFieldConfig);
          }),
          take(1),
          );
        }),
        );
  }

  updateSectionsIfNeededAllTheWayDown(formlyFieldConfig: FormlyFieldConfig, workflowStage: WORKFLOW_STAGE, formModelFirestoreDocId: string) :
    Observable<{formlyFieldConfig:FormlyFieldConfig, containedFormFirestoreSummaryDocIds: string[]}> {

    const containedFormFirestoreSummaryDocIds: string[] = [];
    // Branching containers choice controls are populated with formFirestoreDocIds.  When form is first loaded we need to ensure these point to the current version referenced by
    // the formsummary.

    // Note this will only update the workflows referenced by the formly branch options, it doesn't recurse into each of these workflows to determine if they need updated ( this is done
    // when workflow is selected by branching container)
    if (formlyFieldConfig.type === "formlyBranchingContainer" ) {
      const branchingFormlyFieldConfig = (formlyFieldConfig.fieldArray as FormlyFieldConfig).fieldGroup[0];
      const options = (branchingFormlyFieldConfig.props ? branchingFormlyFieldConfig. props.options : branchingFormlyFieldConfig.templateOptions.options) as any[];

      //formlyBranches just need to ensure that referenced formSummarys and deployed formFirestore documents have been retrieve from database, and that the
      //deployed formFirestore documents are up to date.
      containedFormFirestoreSummaryDocIds.push(...options.map(o => o.value));
      const optionstToCheck = options.filter(o => this.fetchUpdatedWorkflowService.formFirestoreSummaryDocIdNeedsCheckedForUpdate(o.value, formModelFirestoreDocId));
      const toCheck : Observable<any>[] = [of(null)];
      optionstToCheck.forEach(o => {
        toCheck.push(this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(o.value,formModelFirestoreDocId).pipe(
          switchMap(formFirestoreDocId => this.formFirestoreService.load$(formFirestoreDocId).pipe(take(1))),
          switchMap(formFirestore => this.recursivelyUpdateFormlySection(formFirestore.DocId(), JSON.parse(formFirestore.form), workflowStage, formModelFirestoreDocId))
        ));
      });
      return zip(...toCheck).pipe(
        map(x => {
          return {formlyFieldConfig, containedFormFirestoreSummaryDocIds: containedFormFirestoreSummaryDocIds.concat(x.filter(q=>q!==null).flatMap(y => y.containedFormFirestoreSummaryDocIds))};
        }),
      );
    }

    if (formlyFieldConfig.type === "formlySection") {

      const activeFormFirestoreDocIdFromSummary = this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(formlyFieldConfig.props.formFirestoreSummaryDocId, formModelFirestoreDocId);

      const noUpdateNeeded = activeFormFirestoreDocIdFromSummary.pipe(
        filter(x => !this.fetchUpdatedWorkflowService.formFirestoreSummaryDocIdNeedsCheckedForUpdate(formlyFieldConfig.props.formFirestoreSummaryDocId, formModelFirestoreDocId) &&
        this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(formModelFirestoreDocId) !== undefined &&
        this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(formModelFirestoreDocId).get(x) !== undefined),
        map(x => {
          return {formlyFieldConfig: this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(formModelFirestoreDocId).get(x),
            containedFormFirestoreSummaryDocIds: containedFormFirestoreSummaryDocIds.concat(formlyFieldConfig.props.formFirestoreSummaryDocId)};
          })
      );

      const updateNeeded = activeFormFirestoreDocIdFromSummary.pipe(
        filter(x => this.fetchUpdatedWorkflowService.formFirestoreSummaryDocIdNeedsCheckedForUpdate(formlyFieldConfig.props.formFirestoreSummaryDocId, formModelFirestoreDocId) ||
        this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(formModelFirestoreDocId) === undefined ||
        this.fetchUpdatedWorkflowService.formModelFirestoreToInstatiatedFormsMap.get(formModelFirestoreDocId).get(x) === undefined),
        switchMap(x => this.formFirestoreSummaryService.load$(formlyFieldConfig.props.formFirestoreSummaryDocId)),
        map(y => workflowStage === WORKFLOW_STAGE.DEPLOYED ?
            y.currentDeployedFirestoreDocId : y.currentDesignFirestoreDocId),
            switchMap(formFirestoreDocId =>
              this.recursivelyUpdateFormlySection(formFirestoreDocId, formlyFieldConfig, workflowStage, formModelFirestoreDocId, formlyFieldConfig.props.formFirestoreDocId)),
        map(y => {
          return {formlyFieldConfig: y.formlyFieldConfig, containedFormFirestoreSummaryDocIds:  containedFormFirestoreSummaryDocIds.concat(y.containedFormFirestoreSummaryDocIds).concat(formlyFieldConfig.props.formFirestoreSummaryDocId)};
        })
        );

        return merge(noUpdateNeeded, updateNeeded).pipe(
          take(1)
        );

        //  Currently only sections and branches reference workflows.  Workflows referenced by branches are checked when they are first loaded.
        //  If config is another type, we do not need to update it.
    } else {
      return of({formlyFieldConfig, containedFormFirestoreSummaryDocIds: containedFormFirestoreSummaryDocIds});
    }
}

  private retrieveActiveFormFirestoreToUseForSection(formlyFieldConfig: FormlyFieldConfig<import("@ngx-formly/core").FormlyFieldProps & { [additionalProperties: string]: any; }>,
    formModelFirestoreDocId: string) : Observable<string> {
    return this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(formlyFieldConfig.props.formFirestoreSummaryDocId, formModelFirestoreDocId);
  }

  returnLatestFormFirestore(formFirestore: FormFirestore, workflowStage: WORKFLOW_STAGE) : Observable<FormFirestore> {
    // if the current version referenced by the form summary is different then passed in formFirestore, return the latest version
    // of form firestore.
    const docIdToEnsureCurrent = workflowStage == WORKFLOW_STAGE.DEPLOYED ?  formFirestore.formSummary.currentDeployedFirestoreDocId : formFirestore.formSummary.currentDesignFirestoreDocId;
    if (docIdToEnsureCurrent !== formFirestore.DocId() ) {
      return this.formFirestoreService.load$(docIdToEnsureCurrent).pipe(
        filter(x=>x!==null),
      );
    } else {
      return of(formFirestore);
    }
  }

  updateSectionsIfNeeded(formFirestore: FormFirestore, workflowStage: WORKFLOW_STAGE, formModelFirestoreDocId: string) : Observable<string> {
    const parsedForm = JSON.parse(formFirestore.form);
    let retVal: Observable<any>;
    if (parsedForm.type !== undefined) {
      retVal = this.updateSectionsIfNeededAllTheWayDown(parsedForm, workflowStage, formModelFirestoreDocId);
    } else {
      retVal = zip((parsedForm as FormlyFieldConfig[]).map(f => this.updateSectionsIfNeededAllTheWayDown(f,workflowStage, formModelFirestoreDocId)));
    }
    retVal = retVal.pipe(
        map(x => {
          if (x instanceof Array) {
            return JSON.stringify(x.map(y => y.formlyFieldConfig));
          } else {
            return JSON.stringify(x.formlyFieldConfig);
          }
        }),
        tap(x => {
          if (x !== formFirestore.form) {
            formFirestore.form = x;
          }
        })
      );

      return retVal.pipe(
        take(1)
      );
  }

  controlContainsImages(type: string) {
    return type === "formlyImage" || type === "formlySignaturePad" || type === "formlyPhotoAdder";
  }

  imageKey(formlyFieldKey: string, controlType: string, model: any) : string | null {
    if (controlType === "formlyImage") {
      return formlyFieldKey;
    } else {
        // check for presence at top, otherwise we will iterate through the entire model
        if (model[0][formlyFieldKey.replace('0.','')] !== undefined) {
          return `${model[0][formlyFieldKey.replace('0.','')]}-${formlyFieldKey}`;
        } else {
          return this.findKeyInModelRecursively(model, formlyFieldKey);
      }
    }
  }

  findKeyInModelRecursively(model: any, formlyFieldKey: string) : string | null {
    if (model[0] !== undefined) {
      for (let [key, val] of Object.entries(model[0])) {
        if (key === formlyFieldKey.replace('0.','')) {
          return `${val}-${formlyFieldKey}`;
        } else if (val === 0) {
          const foundVal = this.findKeyInModelRecursively(val, formlyFieldKey);
          if (foundVal !== null) {
            return foundVal;
          }
        }
      };
    } else {
      if (model[formlyFieldKey.replace('0.','')] !== undefined) {
        return `${model[formlyFieldKey.replace('0.','')]}-${formlyFieldKey}`;
      } else {
        return null;
      }
    }
  }

  returnKeysForPhotosAndSignaturesFromModel(model: string) : string[] {
    const retVal: string[] = [];
    const regExp: RegExp = new RegExp("\"[^\"]+?(signatureComponent|photoAdderComponent).+?(\").+?(\")", "g");
    for (let match of model.matchAll(regExp)) {
      const partz = match[0].split(':');
      retVal.push(`${partz[1].replace('"','').replace('\"',"")}-0.${partz[0].replace('"','').replace('\"',"")}`);
    }
    return retVal;
  }

  returnKeysForAllImageControls(f: FormlyFieldConfig[], model: any = {}) : string [] {
    const retVal = [];
    f.forEach(config => this.returnKeysForImageControls(config,retVal, model));
    return retVal;
  }

  returnKeysForImageControls(f: FormlyFieldConfig, retVal: string[], model: any) : string[] {
    if (this.controlContainsImages(f.type as string)) {
      const val = this.imageKey(f.key as string, f.type as string, model);
      if (val !== null && val !== undefined) {
        retVal.push(val);
      }
    }
    if (f.fieldGroup) {
      f.fieldGroup.forEach(g => this.returnKeysForImageControls(g,retVal,model));
    }
    if (f.fieldArray) {
      (f.fieldArray as FormlyFieldConfig).fieldGroup.forEach(g => this.returnKeysForImageControls(g,retVal, model));
    }
    return retVal;
  }

  patchInDataLinksIfNeededAllTheWayDown(f: FormlyFieldConfig, sourceData: DataUsedByDataLinkService) {
    if (f.key !== undefined) {
    if (f.props.dataLinksToPopulate !== undefined && f.props.dataLinksToPopulate.length > 0) {
      const populatedAsObjects = (f.props.dataLinksToPopulate as Array<object>).map(x => new DataRetrievedFromDataLink(x));
      const defaultValue =  DataLinkService.replaceDataLinkPlaceholders(f.props.value,sourceData );
      if (f.formControl !== undefined) {
        f.formControl.patchValue(defaultValue);
      } else {
        f.props.manualPatchValue = defaultValue;
        f.defaultValue = defaultValue;
      }
    }
    if (f.props.lineItemControlType) {
    f.props.dataLinkSourceData = sourceData;
      this.formlyFieldRequiringInitialObservableEmission.value.add(f.key.toString());
      this.formlyFieldRequiringInitialObservableEmission.next(this.formlyFieldRequiringInitialObservableEmission.value);
    }
    if (f.type === "formlyBranchingContainer") {
      this.formlyFieldRequiringInitialObservableEmission.value.add(f.key.toString());
      this.formlyFieldRequiringInitialObservableEmission.next(this.formlyFieldRequiringInitialObservableEmission.value);
    }

    }
    if (f.fieldGroup) {
      f.fieldGroup.forEach(g => this.patchInDataLinksIfNeededAllTheWayDown(g,sourceData));
    }
    if (f.fieldArray) {
      (f.fieldArray as FormlyFieldConfig).fieldGroup.forEach(g => this.patchInDataLinksIfNeededAllTheWayDown(g,sourceData));
    }
  }
}
