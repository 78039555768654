import { Injectable } from '@angular/core';

export const ComponentTypes = [
  "SchedulerView","SchedulerViewHeader","DayWeekView","WorkflowDesigner","BirdDog","Connectivity"
] as const;

export type ComponentType = typeof ComponentTypes[number];

@Injectable({
  providedIn: 'root'
})
export class LocalSettingsService {

  app: "MOBILE" | "WEB" = "WEB";
  appVersion: string | null = null;
  releaseTimeStamp: string | null = null;
  extraLogInfo: any = null;
  environment: any = null;
  constructorGuid: string = "";
  loggedInEmployeeName: string = "";
  loggedInEmployeeDocId: string = "";
  deviceId: string = "";
  constructorInitilized : Date | null = null;

  getLocalStorageName(componentType: ComponentType, settingName: string) {
    return `${componentType}-${settingName}`;
  }

  saveToLocalStorage(componentType: ComponentType, settingName: string, setting: any) {
    try {
      const seralizedSetting = JSON.stringify(setting);
      localStorage.setItem(this.getLocalStorageName(componentType,settingName), seralizedSetting);
    } catch (err) {
      console.error(err);
    }
  }

  loadFromLocalStorage(componentType: ComponentType, settingName: string, defaultValue?: any) : any {
    try {
        const serializedValue = localStorage.getItem(this.getLocalStorageName(componentType,settingName));
        if (serializedValue === null || serializedValue === undefined || serializedValue === 'undefined') {
            return defaultValue;
        }
        return JSON.parse(serializedValue);
    } catch (err) {
        console.error(err);
        return undefined;
    }
}

  constructor() { }
}
