import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addDays, addHours, addMinutes, isWeekend, startOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { SchedulerSettingsService } from '../../../../../common/src/data/dao-services/scheduler-settings.service';
import { EmployeeAvailability } from '../../../../../common/src/data/dao/employee-availability';
import { SchedulerSettings } from '../../../../../common/src/data/dao/scheduler-settings';


interface siteVisitsSuggestionInformation {
  settingName: string;
  userName: string;
  description: string;
  icon: string;
}

@Component({
  selector: 'app-scheduler-settings',
  templateUrl: './scheduler-settings.component.html',
  styleUrls: ['./scheduler-settings.component.scss']
})
export class SchedulerSettingsComponent implements OnInit, OnDestroy {

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  standardFieldsToPatch = ["workWeek","minimumSiteVisitLength","defaultArrivalWindowLength","numberFillsDaySiteVisits",
  "numberFirstAvailiableSiteVisits","numberLowCommuteSiteVisits","defaultStartTimeVirginSiteVisits"];

  numberSiteVisitsToSuggestFields : siteVisitsSuggestionInformation[] = [
    {
      settingName: "numberFillsDaySiteVisits",
      userName: "Dense Work Days",
      description: "This is the number of site visits that will be suggested on basis of ensuring resources are scheduled as fully as possible.",
      icon: "work",
    },
    {
      settingName: "numberFirstAvailiableSiteVisits",
      userName: "First Available",
      description: "This is the number of site visits suggested to ensure quicker arrival to site.",
      icon: "work",
    },
    {
      settingName: "numberLowCommuteSiteVisits",
      userName: "Low Total Commute Time",
      description: "This is the number of site visits suggested to a low total commute time.",
      icon: "work",
    },
  ];


  schedulerSetting: SchedulerSettings;
  form: UntypedFormGroup;
  fifteenMinutes = addMinutes(startOfDay(new Date()), 15);
  eightHours= addHours(startOfDay(new Date()), 8);

  constructor(private fb: UntypedFormBuilder, private snackBar: MatSnackBar, private schedulerSettingsService: SchedulerSettingsService) { }

  ngOnInit(): void {
    this.form = this.initilizeForm();

    this.schedulerSettingsService.loadAll$().pipe(
      filter(x => x.length > 0),
      map(x => x[0] as SchedulerSettings),
      tap(x => this.schedulerSetting = this.schedulerSettingsService.getCloneOfCachedValue(x.DocId())),
      tap(x => {
        this.patchSchedulerSettings(this.schedulerSetting)
      }),
      take(1)
    ).subscribe();
  }

  patchSchedulerSettings(s: SchedulerSettings) {
    this.standardFieldsToPatch.forEach(field => {
      this.form.get(field).patchValue(s[field]);
    });
  }

  patchFormGroupToSchedulerSettings() {
    this.standardFieldsToPatch.forEach(field =>  this.schedulerSetting[field] = this.form.get(field).value);
  }

  initilizeForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      minimumSiteVisitLength: [],
      defaultArrivalWindowLength: [],
      workWeek: [],
      numberFillsDaySiteVisits: [, [Validators.min(0), Validators.max(10)]],
      numberFirstAvailiableSiteVisits: [, [Validators.min(0), Validators.max(10)]],
      numberLowCommuteSiteVisits: [, [Validators.min(0), Validators.max(10)]],
      defaultStartTimeVirginSiteVisits: [],
    });

    return retVal;
}

setCheckDay(day: EmployeeAvailability) {
  day.activeWorkDay = !day.activeWorkDay
}

workWeekToRender() : EmployeeAvailability[] {
  if ((this.form.get("workWeek").value as Array<EmployeeAvailability>).length === 0) {
    this.form.patchValue({workWeek: this.defaultWorkWeek((this.eightHours),(addHours(startOfDay(new Date()), 17)))});
  }
  return this.form.get("workWeek").value;
}

defaultWorkWeek(startTime: Date, endTime: Date) : EmployeeAvailability[] {

  let activeDate = new Date(2021,2,7);
  const retVal : EmployeeAvailability[] = [];
  for (let i=0; i<7; i++) {
    retVal.push(new EmployeeAvailability({date: activeDate, activeWorkDay: !isWeekend(activeDate),
      workStartTime: startTime, workEndTime: endTime}));
    activeDate = addDays(activeDate,1);
  }
  return retVal;
}

Cancel() {
  this.form.reset();
  const patchy = this.schedulerSettingsService.getCloneOfCachedValue(this.schedulerSetting.DocId());
  this.patchSchedulerSettings(patchy);
}

scheduleValidates() : boolean {
  let valid = true;
(this.form.get("workWeek").value as EmployeeAvailability[]).forEach(a => valid = valid && a.startEndTimesValidate);
return valid;
}

Save() {
  if (this.form.valid && this.scheduleValidates()) {
    this.snackBar.open("Saving Updates",undefined);
    this.patchFormGroupToSchedulerSettings();
    this.schedulerSettingsService.update$(this.schedulerSetting).pipe(
      take(1)
      ).subscribe(x => {
        this.snackBar.dismiss();
      });
  }
}

}
