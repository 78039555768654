import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySectionComponent } from './formly-section.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [FormlySectionComponent],
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormlyModule.forChild({
      types: [
      {name: 'formlySection', component: FormlySectionComponent}
      ]
    }),
  ]
})
export class FormlySectionModule { }
