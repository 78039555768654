import { Injectable } from '@angular/core';
import { startOfDay } from 'date-fns';
import { ResourceAvailibility } from './resource-availibility';

@Injectable({
  providedIn: 'root'
})
export class MinimizeSiteVisitMutationService {

  static resourceAvailabilityMinimizingSiteVisitMutations : ResourceAvailibility[] = undefined;

  constructor() {}

  set resourceAvailabilityMinimizingSiteVisitMutations(val: ResourceAvailibility[]) {
    MinimizeSiteVisitMutationService.resourceAvailabilityMinimizingSiteVisitMutations = val;
  }


  private findMatchingResourceAvailability(start: Date, actualResourceDocId: string) : ResourceAvailibility {
    try {
    const matchingAvail = MinimizeSiteVisitMutationService.resourceAvailabilityMinimizingSiteVisitMutations.filter(x => x.employeeDocId === actualResourceDocId && x.actualStartTime.getTime() <= start.getTime() &&
      x.actualEndTime.getTime() > start.getTime())[0];
    if (matchingAvail !== undefined) {
      return matchingAvail;
    } else {
      const allResourceAvailibilityForGivenResourceDay = MinimizeSiteVisitMutationService.resourceAvailabilityMinimizingSiteVisitMutations.filter(x => x.employeeDocId === actualResourceDocId &&
          startOfDay(start).getTime() === startOfDay(x.actualStartTime).getTime());

        if (allResourceAvailibilityForGivenResourceDay.length === 0) {
          return undefined;
        }
        // If all resource availibility for given actualResouceDocId is after the passed in start date, then return the first one
      if (allResourceAvailibilityForGivenResourceDay[0].actualStartTime.getTime() > start.getTime()) {
        return allResourceAvailibilityForGivenResourceDay[0];
      } else {
      // If all resource availibility for given actualResouceDocId is before the passed in start date, then return the last one
        if (allResourceAvailibilityForGivenResourceDay[allResourceAvailibilityForGivenResourceDay.length - 1].actualStartTime.getTime() < start.getTime()) {
          return allResourceAvailibilityForGivenResourceDay[allResourceAvailibilityForGivenResourceDay.length - 1];
        }
        else {
          return undefined;
        }
      }
    }
    } catch (e) {
      return undefined;
    }
  }

  public getCommuteDelta(start: Date, actualResourceDocId: string) : number {
    return this.findMatchingResourceAvailability(start, actualResourceDocId)?.commuteMinutesDelta;
  }

  public getSiteVisitNumberDragging(start: Date, actualResourceDocId: string) : number {
    return this.findMatchingResourceAvailability(start, actualResourceDocId)?.siteVisitNumber;
  }
}
