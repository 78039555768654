import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeFieldComponent } from './time-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';


@NgModule({
  declarations: [TimeFieldComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
  ],
  exports: [TimeFieldComponent],
})
export class TimeFieldModule { }
