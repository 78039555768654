<div [formGroup]="pricingMethodologySettingsForm" class="employee-grid" style="padding: 0px;">
  <div class="label-style">Labor Billed At:</div>
  <mat-form-field appearance="outline" class="medium-field">
    <mat-select placeholder="Pricing Methodology" formControlName="pricingMethodologyType">
      <mat-option *ngFor="let p of pricingMethodologyTypes" [value]="p.val">{{p.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Material Markup Rate -->
  <div class="label-style">Material Markup %</div>
  <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field appearance="outline" class="medium-field">
      <input formControlName="materialMarkupRate" matInput placeholder ="10" type="number"
      [name]="ElementNameForId('115361')" autofill='off' autocomplete='new-password' data-lpignore='true'>
    </mat-form-field>
  </div>
  <!-- Labor Billed At Rate-->
  <div class="label-style">Labor Billed At:</div>
  <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field appearance="outline" class="medium-field">
      <input formControlName="defaultHourlyBillingRate" matInput placeholder ="10" type="number"
      [name]="ElementNameForId('955983')" autofill='off' autocomplete='new-password' data-lpignore='true'>
    </mat-form-field>
  </div>
</div>
