import { Injectable } from '@angular/core'
;
import { AuthenticationService } from '../../util/authentication.service'
import { CustomerCommunicationScheduling, InitialCommunicationTimingType } from '../dao/customer-communication-scheduling';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { addDays, addHours, addMinutes, getHours, getMinutes, startOfDay, subHours } from 'date-fns';
import { CustomerCommunicationCategory, CustomerCommunicationTemplate } from '../dao/customer-communication-template';
import { SiteVisit } from '../dao/site-visit';
import { CustomerCommunicationTemplateService } from './customer-communication-template.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerCommunicationSchedulingService extends DatabaseStoreService<CustomerCommunicationScheduling> {

  constructor( fs: CustomerCommunicationSchedulingFirestoreService, authenticationService: AuthenticationService, store: CustomerCommunicationSchedulingStoreService,
    private customerCommunicationTemplateService: CustomerCommunicationTemplateService) {
super(fs, store, authenticationService,
  new Map([["customerCommunicationTemplate", {associatedDocumentId: "customerCommunicationTemplateDocId",compositionStoreService: customerCommunicationTemplateService}]]));
  }


  InitialTriggeringEventDate(template: CustomerCommunicationTemplate, schedule: CustomerCommunicationScheduling, siteVisit?: SiteVisit) : Date {
    switch (schedule.initialCommunicationTimingType) {
      case InitialCommunicationTimingType.PRECEEDHOURS: {
        if (template.communicationCatagory === CustomerCommunicationCategory.APPOINTMENT) {
          return siteVisit.arrivalWindowStartDate;
        } else {
          throw Error("InitialTriggeringEventDate: PRECEEDHOURS not implemented for non-appointment");
        }
      }
      default:
        return new Date();
    }
  }

  InitialTriggeringEventDateScheduleSecondCommunication(initialContactTemplateSchedule: CustomerCommunicationScheduling,
    activeTemplateSchedule: CustomerCommunicationScheduling, siteVisit?: SiteVisit) : Date {
      const triggeringEventDate = this.getInitialCustomerCommunicationDate(initialContactTemplateSchedule, new Date());
      const dayToSendFollowUp  = addDays(startOfDay(triggeringEventDate),activeTemplateSchedule.delayDaysToSendFollowUpMessage);
      return  addMinutes(dayToSendFollowUp, getHours(activeTemplateSchedule.timeOfDayToSendMessage) * 60 +
        getMinutes(activeTemplateSchedule.timeOfDayToSendMessage))
}

  InitialTriggeringEventDateSeperateFollowUpCommunications(initialContactTemplateSchedule: CustomerCommunicationScheduling,
      activeTemplateSchedule: CustomerCommunicationScheduling) : Date {
        const triggeringEventDate = this.getInitialCustomerCommunicationDate(initialContactTemplateSchedule, new Date());
        return this.getInitialCustomerCommunicationDate(activeTemplateSchedule, triggeringEventDate);
  }

  getInitialCustomerCommunicationDate(c: CustomerCommunicationScheduling, triggeringEventDate: Date) : Date {
    switch (c.initialCommunicationTimingType) {
      case InitialCommunicationTimingType.IMEDIATELY:
        return triggeringEventDate;
        break;
      case InitialCommunicationTimingType.DELAYHOURS:
        return addHours(triggeringEventDate,getHours(c.delayHoursFromTriggeringToInitiallySending ));
        break;
      case InitialCommunicationTimingType.DELAYDAYS:
        // Send at first specified time occurring x days after triggering event
        const earliest = addDays(startOfDay(triggeringEventDate), c.delayDaysToSendInitialMessage);
        const atSpecifiedTime = addHours(startOfDay(earliest), getHours(c.timeOfDayToSendMessage));
        const retVal = atSpecifiedTime.getTime() > earliest.getTime() ? atSpecifiedTime : addDays(atSpecifiedTime,1);
        // if specified time is in the past, send it tomorrow.
        if (retVal.getTime() < new Date().getTime()) {
          return addDays(retVal,1);
        } else {
          return retVal;
        }
        break;
      case InitialCommunicationTimingType.PRECEEDHOURS:
        return subHours(triggeringEventDate, getHours(c.delayHoursFromTriggeringToInitiallySending));
        break;
      default:
        throw new Error("Specified comunication timing type is not supported");
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerCommunicationSchedulingStoreService extends StateChangeStoreService<CustomerCommunicationScheduling> {
  protected store = "CustomerCommunicationScheduling-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, CustomerCommunicationScheduling>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class CustomerCommunicationSchedulingFirestoreService extends FirestoreBackend<CustomerCommunicationScheduling> {

 protected basePath = "CustomerCommunicationScheduling";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): CustomerCommunicationScheduling {
return new CustomerCommunicationScheduling(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new CustomerCommunicationScheduling(), authService);
 }
}
