// db.ts
import Dexie, { Table } from 'dexie';

export interface AddressRoutingLocal {
  originAddressDocId: string;
  destinationAddressDocId: string;
  distanceMeters: number;
  timeEstimateSeconds: number;
  addressRoutingSource : string;
  reversedFromRequestedRoute: boolean;
  dateRetrieved: Date;
  docId: string;
}

export interface AddressWithCommuteUpdatedDate {
  addressDocId: string;
  commuteUpdatedDate: Date;
}

export interface ResourceDayWithCommuteUpdatedDate {
  resourceDayEmployeeDocIdKey: string;
  employeeDocId: string;
  resourceDay: Date;
  docId: string;
  commuteUpdatedDate: Date;
  numberEntries: number;
}

export interface DexieResourceDayAddressRouting {
  resourceDayEmployeeDocIdKey: string;
  employeeDocId: string;
  resourceDay: Date;
  docId: string;
  addressRoutingLocal: AddressRoutingLocal;
}

export class AppDB extends Dexie {
    addressRoutingLocal!: Table<AddressRoutingLocal, string>;
    addressCommuteUpdated!: Table<AddressWithCommuteUpdatedDate,string>;
    resourceDayCommuteUpdated!: Table<ResourceDayWithCommuteUpdatedDate,string>;
    resourceDayAddressRoutes!: Table<DexieResourceDayAddressRouting,string>;

    constructor() {
      super('ngdexieliveQuery');
      this.version(9).stores({
        addressRoutingLocal: 'docId, dateRetrieved, originAddressDocId, destinationAddressDocId',
        addressCommuteUpdated: 'addressDocId, commuteUpdatedDate',
        resourceDayCommuteUpdated: 'resourceDayEmployeeDocIdKey, resourceDay, docId',
        resourceDayAddressRoutes: 'docId, resourceDayEmployeeDocIdKey,  resourceDay',
      });
    }
}

export const db = new AppDB();
