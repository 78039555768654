import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { VerticalSchedulerViewWindow } from '../day-week-view/day-week-view.component';
import { ChecklistDatabase } from 'web-app/src/app/multiselect-nested-tree/multiselect-nested-tree.component';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { format, subDays } from 'date-fns';
import { LocalSettingsService } from 'web-app/src/app/settings/local-settings.service';
import { Job } from '../../../../../../common/src/data/dao/job';
import { Employee } from '../../../../../../common/src/data/dao/employee';
import { EmployeeService } from '../../../../../../common/src/data/dao-services/employee.service';
import { randomElementName } from '../../../../../../common/src/util/util';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() onDeckJob: {job: Job, hoursWorkingOnScheduling: number, prospectiveSiteVisitDurationHours: number};
  @Input() viewWindowMovementEnabled: boolean;
  @Input() numberWorkDaysDisplayed: number;
  @Input() viewWindowStartDate: Date;
  @Input() viewWindowEndDate: Date;
  @Input() numberUnassigned: number;


  @Output() employeesRemovedFromTreeDisplay$ = new Subject<Employee[]>();
  @Output() employeesAddedToTreeDisplay$ = new Subject<Employee[]>();
  @Output() updatedViewWindow$ = new Subject<VerticalSchedulerViewWindow>();
  @Output() updateNumberWorkdaysToDisplay$ = new Subject<any>();
  @Output() retreatViewWindow$ = new Subject<null>();
  @Output() advanceViewWindow$ = new Subject<null>();
  @Output() viewWindowSetToToday$ = new Subject<null>();
  @Output() viewWindowUpdatedViaCalendar$ = new Subject<Date>();
  @Output() completedSchedulingFromOnDeck$ = new Subject<null>();
  @Output() schedulerLater$ = new Subject<null>();
  @Output() filterSearch$ = new EventEmitter();
  @Output() allowedCommuteSlider$ = new Subject<number>();
  @Output() prospectiveDurationHourUpdate$ = new Subject<number>();
  @Output() toggleSideNav$ = new Subject<null>();

  destroyingComponent$ = new Subject();

  form : FormGroup;

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  employeeIdsNotDisplaying: string[] = [];

  employeeRoles: ChecklistDatabase<Employee>;

  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  constructor(private employeeService: EmployeeService, private localSettingsService: LocalSettingsService, private fb: FormBuilder) {
    this.employeeIdsNotDisplaying = localSettingsService.loadFromLocalStorage("SchedulerViewHeader", "employeeIdsNotDisplaying", []);
    this.form = this.fb.group({search: ['']});

    this.form.get('search').valueChanges.pipe(
      tap(x => this.filterSearch$.emit(x)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  ngOnInit() {
    this.employeeRoles = new ChecklistDatabase<Employee>("Employee Role",
    this.employeeService.loadAll$().pipe(
      map(z => z.filter(x=>x.active && x.scheduleFieldCallsFor))),
    this.employeeService.loadAll$().pipe(
      map(z => z.filter(x=>x.active && x.scheduleFieldCallsFor && !this.employeeIdsNotDisplaying.includes(x.docId)))),
    (x=>x.employeeRole.name),(x => x.name),(x=>x.name)
    );

    this.employeesAddedToTreeDisplay$.pipe(
      tap(x => {
        x.filter(x=>x!==undefined).forEach(emp => {
          if (this.employeeIdsNotDisplaying.indexOf(emp.docId) !== -1) {
            this.employeeIdsNotDisplaying.splice(this.employeeIdsNotDisplaying.indexOf(emp.docId), 1);
          }
        });
        this.localSettingsService.saveToLocalStorage("SchedulerViewHeader", "employeeIdsNotDisplaying", this.employeeIdsNotDisplaying);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.employeesRemovedFromTreeDisplay$.pipe(
      tap(x => {
        x.filter(x=>x!==undefined).forEach(emp => {
          if (!this.employeeIdsNotDisplaying.includes(emp.docId)) {
            this.employeeIdsNotDisplaying.push(emp.docId);
          }
        });
        this.localSettingsService.saveToLocalStorage("SchedulerViewHeader", "employeeIdsNotDisplaying", this.employeeIdsNotDisplaying);
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  get ActiveViewWindow() {
    if (this.viewWindowStartDate === undefined || this.viewWindowEndDate === undefined ) {
      return "Loading Dates";
    } else {
      const endDateToDisplay = subDays(this.viewWindowEndDate,1);
      return `${format(this.viewWindowStartDate, 'MMM d')} - ${format(endDateToDisplay, 'MMM d, yyyy')}`;
    }
  }

  setDatepicker(val) {
    this.viewWindowUpdatedViaCalendar$.next(val.value);
  }

  CheckListDatabase() : ChecklistDatabase<Employee> {
    return this.employeeRoles;
  }

}

