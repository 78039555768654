import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {  Subject } from 'rxjs';
import { UntypedFormGroup} from '@angular/forms';
import { CustomTagComponentInputs, CustomTagComponentOutputs } from 'web-app/src/app/custom-tags/custom-tags.component';
import { CustomTagsService } from 'web-app/src/app/custom-tags.service';
import { GenericServiceProviderSetting } from '../../../../../common/src/data/dao/generic-service-provider-setting';


@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})

export class CustomerDetailsComponent implements OnInit, OnDestroy {

  @Input() customerDetailsForm: UntypedFormGroup;

  customTagComponentInputs : CustomTagComponentInputs;
  customTagComponentOutputs : CustomTagComponentOutputs;
  destroyingComponent$ = new Subject();
  customerRoles = ["All","Billing Contact","Site Visit Contact"];

  customerTags: GenericServiceProviderSetting[];

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  constructor(private tagService: CustomTagsService) { }

    RemoveCustomerClick() {
      this.customerDetailsForm.patchValue({removeCustomerIndex: this.customerDetailsForm.get("arrayIndex").value});
    }

  ngOnInit(): void {
    this.customerTags = this.customerDetailsForm.get("customerTags").value;
    this.customTagComponentInputs = this.tagService.buildCustomTagsInput(this.customerTags,false,this.destroyingComponent$,"customer_tags", "Customer Tags");
    this.customTagComponentOutputs = this.tagService.buildCustomTagsOutput(this.customerTags, this.destroyingComponent$, "customer_tags");
  }

  public displayAddressSelected(value) {
    console.log(value);
    return  (value !== undefined && value !== null && value !== "") ? value.formattedAddress() : '';
  }

}
