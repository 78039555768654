<!-- GREG'S UPDATED HTML....  -->

<mat-card appearance="outlined" class="on-deck-width">
  <mat-card-content  style="padding: 0px !important;">
  <div class="on-deck-container">

    <div class="cust-name" role ="listitem"  style="font-size: 14px;">{{siteVisitsActivelyScheduling?.job.customer.customerName}}</div>

    <div class="cust-address" role ="listitem"  style="font-size: 14px;">{{siteVisitsActivelyScheduling?.job.serviceAddress.formattedAddress()}}</div>

    <!-- COMMUTE SLIDER -->
    <div class="site-visit-commute slider-div-layout">
      <mat-icon matTooltipClass="tooltip-format" matTooltip='Sets maximum allowable commute time. Green means location on schedule is below maximum commute time.

      Yellow is above maximum commute but will not cause scheduling conflict. Red is above maximum commute time and will cause scheduling conflict.'
      matTooltipPosition="left"
      class="slider-icon"
      matTooltipShowDelay="300">
        directions_car
      </mat-icon>
      <mat-slider style="width: 100%; bottom: 2px;"
      disabled="{{disableSlider}}"
      thumbLabel [displayWith]="formatCommuteSlider(schedulingService)"
      discrete
      #ngCommuteSlider><input matSliderThumb [(ngModel)]="sliderValue" (change)="EmitCommuteSliderValue({source: ngCommuteSliderThumb, parent: ngCommuteSlider, value: ngCommuteSliderThumb.value})"
      #ngCommuteSliderThumb="matSliderThumb" (input)="EmitCommuteSliderValue({source: ngCommuteSliderThumb, parent: ngCommuteSlider, value: ngCommuteSliderThumb.value})" />
      </mat-slider>
    </div>
    <!-- END COMMUTE SLIDER -->


    <!-- DURATION SLIDER -->
    <div class="site-visit-duration slider-div-layout">
      <mat-icon matTooltipClass="tooltip-format" matTooltip='Sets individual site visit duration'
        matTooltipPosition="left"
        class="slider-icon"
        style="margin-top: 5px;"
        matTooltipShowDelay="300">
          handyman
      </mat-icon>
      <mat-slider
       style="width: 100%; top: 1px;"
       disabled="{{disableSlider}}"
       thumbLabel [displayWith]="formatSiteVisitDurationSlider"
       discrete
       max=40 min=1 step=1 #ngSiteVisitDurationSlider>
        <input matSliderThumb [(ngModel)]="fifteenMinuteValueFromHoursSchedulingValue"
          (change)="EmitSiteVisitLengthValue({source: ngSiteVisitDurationSliderThumb, parent: ngSiteVisitDurationSlider, value: ngSiteVisitDurationSliderThumb.value})"
          #ngSiteVisitDurationSliderThumb="matSliderThumb" (input)="EmitSiteVisitLengthValue({source: ngSiteVisitDurationSliderThumb,
            parent: ngSiteVisitDurationSlider, value: ngSiteVisitDurationSliderThumb.value})"
        />
      </mat-slider>
    </div>
    <!-- END DURATION SLIDER -->

    <div class="job-scheduled">Working on scheduling:  {{workingOnSchedulingDisplay}}</div>

    <div class="job-duration">Total Job Hours:  {{totalJobHoursDisplay}}</div>




    <div><!--Room for something else in on deck --></div>
       <!-- <div class="on-deck-button-layout clear-job">
         <button mat-flat-button class="button-green"
         matTooltipClass="tooltip-format"
         matTooltip="Deletes Remaining Unscheduled Time"
         matTooltipPosition="after"
         style="height: 35px; width: 90%; top: 2px;"
         (click)="completedSchedulingFromOnDeck$.next(null)"
         matTooltipShowDelay="1000">
         Complete
        </button>
       </div> -->
    <!-- <button fxLayout="row" fxLayoutAlign="center" mat-flat-button class="button-gray schedule-later" style="height: 35px; width: 90%; top: 2px;" (click)="schedulerLater$.next(null)">Schedule Later</button> -->
       <div class="on-deck-button-layout cancel-job" style="grid-column: 3/4; grid-row: 2/3;">
         <button mat-flat-button class="button-gray tester"
         matTooltipClass="tooltip-format"
         matTooltip='Puts Remaining Time Back in Unscheduled Work'
         matTooltipPosition="after"
         style="height: 35px; width: 90%; top: 2px;"
         (click)="schedulerLater$.next(null)"
         matTooltipShowDelay="1000">
         Cancel
        </button>
       </div>
        <!-- <button mat-flat-button class="button-red cancel-job">Cancel</button> -->
  </div>
  </mat-card-content>
  </mat-card>
