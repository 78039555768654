import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class ResourceDay extends RetrieveFirestorePropertiesBase<ResourceDay>  {

  employeeDocId: string;
  resourceDay: Date;
  numberEntries: number = 0;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return ResourceDay._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return ResourceDay._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return ResourceDay._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return ResourceDay._firestoreCompositionMemberNames;}

public constructor(init?: Partial<ResourceDay>) {
super();
Object.assign(this, init);
}
}
