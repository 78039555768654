import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytesResumable, getDownloadURL } from '@angular/fire/storage';
import {  ReplaySubject } from 'rxjs';

export interface fileUpload {
  fileName: string,
  downloadUrl: string
}

export interface fileProgress {
  fileName: string,
  progress: number
}

@Injectable({
  providedIn: 'root'
})
export class AngularFirestorageService {

  downloadURL$: ReplaySubject<fileUpload> = new ReplaySubject<fileUpload>(1);
  progress$: ReplaySubject<fileProgress> = new ReplaySubject<fileProgress>(1);

  constructor(private afStorage: Storage) {

  }

  uploadFilePopulateDownloadUrl(contents : File, name: string ) : void {

    const fileRef = ref(this.afStorage,name);
    const uploadTask = uploadBytesResumable(fileRef, contents);
    uploadTask.on('state_changed',
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    if (!Number.isNaN(progress)) {
      this.progress$.next({fileName: name, progress: progress});
    }
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
    });
    uploadTask.then((snapshot) => {
      console.log(snapshot);
      return getDownloadURL(snapshot.ref)
    })
    .then((url) => {
      console.log(url);
      this.downloadURL$.next({fileName: name, downloadUrl: (url as string)});
    })
    .catch((error) => {
      console.log(error);
    });
  }

  generateUniqueName() : string {
    return (Math.random().toString(36) + '00000000000000000').slice(2, 14);
  }

}
