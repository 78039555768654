import { Injectable } from '@angular/core'
;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../util/authentication.service'
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { PricingMethodologySettings } from '../dao/pricing-methodology-settings';

@Injectable({
  providedIn: 'root'
})
export class PricingMethodologySettingsService extends DatabaseStoreService<PricingMethodologySettings> {

pricingMethodologyFieldsToPatch = ["materialMarkupRate","defaultHourlyBillingRate","pricingMethodologyType","jobTypeDocId"];

initilizePricingMethodologyForm() : FormGroup {
  const retVal = this.fb.group({
    materialMarkupRate: [50,[Validators.min(0)]],
    defaultHourlyBillingRate: [40,[Validators.min(0)]],
    pricingMethodologyType: [],
    jobTypeDocId: [],
    disableMarkupRates: [],
  });
  return retVal;
}

  populateInitial() {
    const a = new PricingMethodologySettings();
    this.create$(a).subscribe();
  }

  constructor( fs: PricingMethodologySettingsFirestoreService, authenticationService: AuthenticationService, store: PricingMethodologySettingsStoreService, private fb: FormBuilder) {
super(fs, store, authenticationService);
  }

}

@Injectable({
  providedIn: 'root'
})
export class PricingMethodologySettingsStoreService extends StateChangeStoreService<PricingMethodologySettings> {
  protected store = "PricingMethodologySettings-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, PricingMethodologySettings>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class PricingMethodologySettingsFirestoreService extends FirestoreBackend<PricingMethodologySettings> {

 protected basePath = "PricingMethodologySettings";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): PricingMethodologySettings {
return new PricingMethodologySettings(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new PricingMethodologySettings(), authService);
 }
}
