import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { EmployeePermission } from "./employee-permission";

export class EmployeeRole extends RetrieveFirestorePropertiesBase<EmployeeRole>  {

  public static CustomRoleDocId = "nPRr6JUuloxbtoQFlJ9q";

  name: string;
  active : boolean = true;
  employeePermissionDocIds: string[] = [];
  employeePermissions: EmployeePermission[] = [];
  scheduleFieldCallsFor: boolean = true;

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["employeePermissions"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["employeePermissionDocIds"]);
  static _firestoreCompositionMemberNames = ["employeePermissions"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["employeePermissions"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return EmployeeRole._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return EmployeeRole._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return EmployeeRole._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return EmployeeRole._firestoreCompositionMemberNames;}

  public constructor(init?: Partial<EmployeeRole>) {
    super();
    Object.assign(this, init);
  }

}
