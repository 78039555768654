import { Injectable } from "@angular/core";
import { Ability, AbilityBuilder } from "@casl/ability";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { AppAbility } from "../../../common/src/util/AppAbility";
import { EmployeePermission, EmployeePermissionDateRange } from "../../../common/src/data/dao/employee-permission";

@Injectable({
  providedIn: 'root'
})

export class EmployeePermissionBuilder {

constructor(private abilityService: Ability) {

}

CreateAppAbilities(allowed: AppAbility[], deined: AppAbility[]) {
  // const abilityBuilder = new AbilityBuilder(AppAbility);
  // abilityBuilder.can('create', )
}

CreateSimplePermission(allowedPermissions: {action: string, subject: string}[], deniedPermissions?: {action: string, subject: string}[] ) : string {
  const abilityBuilder = new AbilityBuilder(Ability);
  allowedPermissions.forEach(p => abilityBuilder.can(p.action,p.subject));
  deniedPermissions.forEach(p => abilityBuilder.cannot(p.action,p.subject));
  return JSON.stringify(abilityBuilder.rules);
}

private buildDateRangeTypePermission(permission: EmployeePermission, builder: AbilityBuilder<Ability>) : void {
  const dateRangePermission = permission as EmployeePermissionDateRange;
      const earliest = subDays(startOfDay(new Date()), dateRangePermission.numberDaysBack);
      const latest = addDays(endOfDay(new Date()), dateRangePermission.numberDaysForward);
      const {can} = builder;
      switch (dateRangePermission.subject) {
        case "SiteVisit":
           can(`${dateRangePermission.action}`,`${dateRangePermission.subject}`, {
            '_startDate': { $lte: latest, $gte: earliest}});
          break;
        default:
          throw new Error("Must define given subject for date range type permission.")
      }
}

private addAbilitiesFromPermission(permission: EmployeePermission, builder: AbilityBuilder<Ability>): void {
  const {can,rules,cannot} = builder;
  if (permission.abilityString || permission.permissionType === "dateRange") {
      switch (permission.permissionType) {
      case "static":
        try {
          const a = new Ability(JSON.parse(permission.abilityString));
        rules.push(...a.rules);
        } catch (err) {
          console.log(err);
        }

        break;
      case "dateRange":
        this.buildDateRangeTypePermission(permission,builder);
        break;
      default:
        throw new Error("Undefined employee permission type.");
    }
  }
}

public addAbilitiesFromPermissions(permissions: EmployeePermission[]) : void {
  const abilityBuilder = new AbilityBuilder(Ability);
  permissions.forEach(p => this.addAbilitiesFromPermission(p,abilityBuilder));
  this.abilityService.update(abilityBuilder.rules);
}

}
