import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mat-dialog-single-field-modal',
  templateUrl: './mat-dialog-single-field-modal.component.html',
  styleUrls: ['./mat-dialog-single-field-modal.component.scss']
})

export class MatDialogSingleFieldModalComponent implements OnInit {

  content: string;
  textArea: string;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<MatDialogSingleFieldModalComponent>) {
    console.log(data);
    this.content = data.content;
    this.textArea = data.textArea;
  }

  ngOnInit(): void {
  }

  okClose() {
    console.log(this.textArea);
    this.dialogRef.close(this.textArea);
  }

  cancelClose() {
    this.dialogRef.close();
  }

}
