import { Injectable } from '@angular/core';
import {combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, tap, take } from 'rxjs/operators';
import { CompanySettingsService } from '../../../../common/src/data/dao-services/company-settings.service';
import { SchedulerSettingsService } from '../../../../common/src/data/dao-services/scheduler-settings.service';
import { CompanySettings } from '../../../../common/src/data/dao/company-settings';
import { AuthenticationService } from '../../../../common/src/util/authentication.service';
import { BryntumSettingsService } from './bryntum-settings.service';
import { LocalSettingsService } from './local-settings.service';

export const Settings = [
// scheduler settings
'minimumSiteVisitLength' , 'defaultArrivalWindowLength', 'businessStartHour', 'businessEndHour',
  'hoursBeforeBusinessStartTimeToDisplay', 'hoursAfterBusinessEndTimeToDisplay', 'workWeek', "defaultStartTimeVirginSiteVisits",
  "numberFillsDaySiteVisits","numberFirstAvailiableSiteVisits","numberLowCommuteSiteVisits",

// company settings
"referralCode" ,"website" ,"companyEmailAddress" ,"companyPhoneNumber" ,"companyName" ,
"dispatchAddress" ,"mailingAddress" , "industry","defaultFormFirestoreSummaryDocId","defaultFormFirestoreSummaryEstimateDocId",
"defaultFormFirestoreSummaryInvoiceDocId","triggerInvoiceEstimateCommunicationOnWorkflowCompletion", "currentJobCounter", "currentJobFormatting",
"companyBccEmailAddress",
// Pricing Methodology Settings
//"materialMarkupRate","defaultHourlyBillingRate","pricingMethodology",
// mobile site settings
"displayMobileSite",

// Settings which are currently fixed
"minutesFidelityArrivalWindow", "minutesFidelitySchedulingArrivalTimes","minutesToShiftToMoveDropToValidLocation","minimumSiteVisitLengthMinutes","minutesFidelitySchedulingLengthSiteVisit",
"minutesFidelityCommuteTimes","customLineItemPrototypeDocId",
"defaultWorkCatagoryDocId", "demoSiteVisitDocId", "demoJobDocId", "demoCustomerDocId", "demoInvoiceDocId", "demoEstimateDocId", "daysToCache", "autoFillDefaultJobLocation",
] as const;

export type Setting = typeof Settings[number];

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settingsLoaded$ : ReplaySubject<boolean> = new ReplaySubject(1);
  companySettings: CompanySettings = undefined;

  constructor(private companySettingsService: CompanySettingsService, private schedulerSettingsService: SchedulerSettingsService,
    private authenticationService: AuthenticationService, private bryntumSettingsService: BryntumSettingsService, private localSettingsService: LocalSettingsService) {

    console.log("SEETTTTIN?");
    this.populateFixedSettings();

    //iterate through Settings type
      this.authenticationService.isLoggedIn$.pipe(
        filter(x => x===true && this.authenticationService.bucketId!==undefined),
        tap(() => this.loadSettingsOnceUserBucketSet()),
        take(1),
      ).subscribe();
  }

  private configuration: { [key in Setting]: any  } = {
    minimumSiteVisitLength: undefined,
    defaultArrivalWindowLength: Date,
    referralCode: undefined,
    website: undefined,
    companyEmailAddress: undefined,
    companyPhoneNumber: undefined,
    companyName: undefined,
    dispatchAddress: undefined,
    mailingAddress: undefined,
    industry: undefined,
    minutesFidelityArrivalWindow: undefined,
    minutesFidelitySchedulingArrivalTimes: undefined,
    minutesToShiftToMoveDropToValidLocation: undefined,
    businessStartHour: undefined,
    businessEndHour: undefined,
    hoursBeforeBusinessStartTimeToDisplay: undefined,
    hoursAfterBusinessEndTimeToDisplay: undefined,
    minimumSiteVisitLengthMinutes: undefined,
    minutesFidelitySchedulingLengthSiteVisit: undefined,
    minutesFidelityCommuteTimes: undefined,
    numberFillsDaySiteVisits: undefined,
    numberFirstAvailiableSiteVisits: undefined,
    numberLowCommuteSiteVisits: undefined,
    customLineItemPrototypeDocId: undefined,
    defaultWorkCatagoryDocId: undefined,
    demoSiteVisitDocId: undefined,
    demoJobDocId: undefined,
    demoCustomerDocId: undefined,
    defaultFormFirestoreSummaryDocId: undefined,
    defaultFormFirestoreSummaryEstimateDocId: undefined,
    defaultFormFirestoreSummaryInvoiceDocId: undefined,
    triggerInvoiceEstimateCommunicationOnWorkflowCompletion: undefined,
    workWeek: undefined,
    currentJobCounter: undefined,
    currentJobFormatting: undefined,
    daysToCache: undefined,
    displayMobileSite: undefined,
    companyBccEmailAddress: undefined,
    defaultStartTimeVirginSiteVisits: undefined,
    demoInvoiceDocId: undefined,
    demoEstimateDocId: undefined,
    autoFillDefaultJobLocation: undefined,
  };


  get displayContext() { return this.configuration.displayMobileSite ? 'mobile' : 'desktop'; }

  populateFixedSettings() {
    // this.configuration.minutesFidelitySchedulingArrivalTimes = 5;
    // this.configuration.minutesFidelityCommuteTimes = 5;
    this.configuration.minutesFidelitySchedulingArrivalTimes = 5;
    this.configuration.minutesFidelityCommuteTimes = 1;

    this.configuration.minutesFidelityArrivalWindow = 30;
    this.configuration.minutesToShiftToMoveDropToValidLocation = 22;
    this.configuration.hoursBeforeBusinessStartTimeToDisplay = 2;
    this.configuration.hoursAfterBusinessEndTimeToDisplay = 3;
    this.configuration.minimumSiteVisitLengthMinutes = 15;
    this.configuration.minutesFidelitySchedulingLengthSiteVisit = 15;

    this.configuration.customLineItemPrototypeDocId = 'woTVpyHlaaOhiboGWv5x';
    this.configuration.defaultWorkCatagoryDocId = 'JQUdbMAbc4pIA8PILCAC';
    this.configuration.demoSiteVisitDocId = 'kHtHWjvFp7V8cSNEG7yt';
    this.configuration.demoJobDocId = 'NY3Xl6LZifj6kJ28Sgyf';
    this.configuration.demoCustomerDocId = 'XXYs8ijRgOxugTATlBcx';
    this.configuration.demoInvoiceDocId = 'qoS3SqVNb4z1FD8KCRYv';
    this.configuration.demoEstimateDocId = 'Hga0TYPizqqOSwOLR5jq';
    this.configuration.daysToCache=2;
    this.configuration.autoFillDefaultJobLocation = true;
    this.configuration.displayMobileSite = this.localSettingsService.app === "MOBILE";
  }


  getValue(key: Setting): any {
    return this.configuration[key];
  }

  getConfiguration() : any {
    return this.configuration;
  }

  incrementJobCounter(batch : string | null) : Observable<string> {
    return this.companySettingsService.incrementJobCounter(batch,this.companySettings );
  }

  loadSettingsOnceUserBucketSet() {
    const s = this.schedulerSettingsService.loadAll$(false).pipe(
      // tap(x => console.log(x,` string`)),
      filter(x => x.length > 0),
      tap(settings => {
        this.configuration.defaultArrivalWindowLength = settings[0].defaultArrivalWindowLength;
        this.configuration.minimumSiteVisitLength = settings[0].minimumSiteVisitLength;
        this.configuration.numberFillsDaySiteVisits = settings[0].numberFillsDaySiteVisits;
        this.configuration.numberFirstAvailiableSiteVisits = settings[0].numberFirstAvailiableSiteVisits;
        this.configuration.numberLowCommuteSiteVisits = settings[0].numberLowCommuteSiteVisits;
        this.configuration.defaultStartTimeVirginSiteVisits = settings[0].defaultStartTimeVirginSiteVisits;
        this.configuration.workWeek = settings[0].workWeek;
        if (settings[0].workWeek.length > 0)  {
          this.configuration.businessStartHour = settings[0].workWeek.sort((a, b) => a.workStartTime.getTime() - b.workStartTime.getTime())[0].workStartTime;
          this.configuration.businessEndHour = settings[0].workWeek.sort((a, b) => b.workEndTime.getTime() - a.workEndTime.getTime())[0].workEndTime;
        }
      }),
      );

    const c = this.companySettingsService.loadAll$(false).pipe(
      // tap(x => console.log(x,` string`)),
      filter(x => x.length > 0),
      tap(s => this.companySettings = s[0]),
      tap(settings => {
        this.configuration.referralCode = settings[0].referralCode;
      this.configuration.website = settings[0].website;
        this.configuration.companyEmailAddress = settings[0].companyEmailAddress;
        this.configuration.companyPhoneNumber = settings[0].companyPhoneNumber;
        this.configuration.companyName = settings[0].companyName;
        this.configuration.dispatchAddress = settings[0].dispatchAddress;
        this.configuration.mailingAddress = settings[0].mailingAddress;
        this.configuration.industry = settings[0].industry;
        this.configuration.defaultFormFirestoreSummaryDocId = settings[0].defaultFormFirestoreSummaryDocId;
        this.configuration.defaultFormFirestoreSummaryEstimateDocId = settings[0].defaultFormFirestoreSummaryEstimateDocId;
        this.configuration.defaultFormFirestoreSummaryInvoiceDocId = settings[0].defaultFormFirestoreSummaryInvoiceDocId;
        this.configuration.triggerInvoiceEstimateCommunicationOnWorkflowCompletion = settings[0].triggerEstimateAndInvoiceCommunicationsOnWorkflowCompletion;
        this.configuration.currentJobCounter = settings[0].currentJobCounter;
        this.configuration.currentJobFormatting = settings[0].jobNumberFormatting;
        this.configuration.companyBccEmailAddress = settings[0].companyBccEmailAddress;
      }),
      );

      const loadSettings = combineLatest([s,c]).pipe(
        tap(() => localStorage.setItem('settings', JSON.stringify(this.configuration))),
        tap(() => this.bryntumSettingsService.updatedSettings$.next(true)),
        tap(() => this.authenticationService.settingsLoaded$.next(true)),
        tap(() => this.settingsLoaded$.next(true)),
      );

      loadSettings.subscribe();
  }

}
