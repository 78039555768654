import { Injectable } from '@angular/core'
;
import { AuthenticationService } from '../../util/authentication.service'
import { CustomerCommunicationCategory, CustomerCommunicationTemplate } from '../dao/customer-communication-template';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from '../dao-services/firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerCommunicationTemplateService extends DatabaseStoreService<CustomerCommunicationTemplate> {

  populateInitial() {
    const a = new CustomerCommunicationTemplate();
    a.body = "Write up default body";
    a.title= "Appointment Confirmed";
    a.subject = "Appointment Confirmed";
    a.description = "Message sent to user to confirm their appointment time.";
    a.communicationCatagory = CustomerCommunicationCategory.APPOINTMENT;

    const e = new CustomerCommunicationTemplate();
    e.body = "Write up default body";
    e.title= "Appointment Reminder";
    e.subject = "Appointment Reminder";
    e.description = "Message sent to user to remind them of their appointment time.";
    e.communicationCatagory = CustomerCommunicationCategory.APPOINTMENT;

    const b = new CustomerCommunicationTemplate();
    b.body = "Write up default body";
    b.title= "Work Performed";
    b.subject = "Work Performed";
    b.description = "Send customer copy of the work that was performed on job.";
    b.communicationCatagory = CustomerCommunicationCategory.WORK_PERFORMED;

    const c = new CustomerCommunicationTemplate();
    c.body = "Write up default body";
    c.title= "Estimate";
    c.subject = "Estimate";
    c.description = "Send customer copy of the estimate for the job.";
    c.communicationCatagory = CustomerCommunicationCategory.ESTIMATE;

    const f = new CustomerCommunicationTemplate();
    f.body = "Write up default body";
    f.title= "Estimate Follow Up";
    f.subject = "Estimate Follow Up";
    f.description = "Send customer follow up on provided estimate";
    f.communicationCatagory = CustomerCommunicationCategory.ESTIMATE;

    const d = new CustomerCommunicationTemplate();
    d.body = "Write up default body";
    d.title= "Invoice Due";
    d.subject = "Invoice Due";
    d.description = "Send customer reminder that their invoice is due.";
    d.communicationCatagory = CustomerCommunicationCategory.PAYMENT;

    this.createMultiple$([a,b,c,d,e,f]).subscribe();



  }

  constructor( fs: CustomerCommunicationTemplateFirestoreService, authenticationService: AuthenticationService, store: CustomerCommunicationTemplateStoreService) {
    super(fs, store, authenticationService);
  }

  retrieveWorkPerformedTemplate() : Observable<CustomerCommunicationTemplate> {
    return this.loadAll$(true).pipe(
      filter(x => x.length > 0),
      map(b => b.filter(q => q.enabled && q.communicationCatagory === CustomerCommunicationCategory.WORK_PERFORMED)[0]),
      take(1)
    );
  }

}
@Injectable({
  providedIn: 'root'
})
export class CustomerCommunicationTemplateStoreService extends StateChangeStoreService<CustomerCommunicationTemplate> {
  protected store = "CustomerCommunicationTemplate-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, CustomerCommunicationTemplate>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class CustomerCommunicationTemplateFirestoreService extends FirestoreBackend<CustomerCommunicationTemplate> {

 protected basePath = "CustomerCommunicationTemplate";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): CustomerCommunicationTemplate {
return new CustomerCommunicationTemplate(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new CustomerCommunicationTemplate(), authService);
 }
}
