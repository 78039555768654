<div class="header-label header-border">Estimates</div>

<div class="line-item-container">
  <table mat-table [dataSource]="sortedEstimates | async"
  multiTemplateDataRows class="mat-elevation-z8" style="background-color: rgba(0,0,0,0); width: 100%;">

  <!-- Estimate created on date-->
<ng-container matColumnDef="dateCreated">
  <th mat-header-cell *matHeaderCellDef>Created On</th>
  <td mat-cell *matCellDef="let estimate">{{estimate.createdAt | date:'short'}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Estimate updated on date-->
<ng-container matColumnDef="dateUpdated">
  <th mat-header-cell *matHeaderCellDef>Last Updated</th>
  <td mat-cell *matCellDef="let estimate">{{estimate.lastUpdatedAt | date:'short'}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Employee Generated-->
<ng-container matColumnDef="employee">
  <th mat-header-cell *matHeaderCellDef>Employee</th>
  <td mat-cell *matCellDef="let estimate">{{estimate.employee?.name}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Active -->
<ng-container matColumnDef="active">
  <th mat-header-cell *matHeaderCellDef>Active</th>
  <td mat-cell *matCellDef="let estimate">{{estimate.active}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Amount -->
<ng-container matColumnDef="totalEstimateAmount">
  <th mat-header-cell *matHeaderCellDef>Amount</th>
  <td mat-cell *matCellDef="let estimate">
      {{estimate.totalCost | currency}}
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<!-- Open Estimate Button -->
<ng-container matColumnDef="openEstimate">
  <th mat-header-cell *matHeaderCellDef>Open</th>
  <td mat-cell *matCellDef="let estimate">
    <div style="display: flex; justify-content: space-between;">
      <button mat-button class="button-green" (click)=openEstimate(estimate)>Open</button>
      <button mat-button class="button-blue" (click)=copyEstimate(estimate)>Copy</button>
      <button mat-button class="button-yellow"
        (click)="sendEstimateToCustomer(estimate)">Send To Customer</button>
    </div>
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
    <div class="add-line-item-button">
      <button mat-button class="button-blue" type="button" (click)=openEstimate(null)>New Estimate</button>
      <!-- <button mat-button class="button-blue" type="button" (click)=presentLineItemCreationModal(null)>Add Line Item IONICS!</button> -->
      </div></td>
</ng-container>

<!-- When last estimate communication sent out. -->
<ng-container matColumnDef="lastCommunication">
  <th mat-header-cell *matHeaderCellDef>Last Communication</th>
  <td mat-cell *matCellDef="let estimate">
    {{estimateSentDateString(estimate)}}
  </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef></td>
</ng-container>

<ng-container matColumnDef="customer">
  <th mat-header-cell *matHeaderCellDef>Customer</th>
  <td mat-cell *matCellDef="let estimate">
    <a routerLink="/app-customer-page/{{estimate.customer?.DocId()}}">
      {{estimate.customer?.customerName}}
    </a>
    <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
  </td>
</ng-container>

<!-- Service Address -->
<ng-container matColumnDef="serviceAddress">
  <th mat-header-cell *matHeaderCellDef>Address</th>
  <td mat-cell *matCellDef="let estimate">{{estimate.serviceAddress?.formattedAddress()}} </td>
  <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
  </td>
</ng-container>

<!-- Link to originating job if present. -->
<ng-container matColumnDef="jobDocId">
  <th mat-header-cell *matHeaderCellDef>Job #</th>
  <td mat-cell *matCellDef="let estimate">
    <ng-container *ngIf="estimate.job && estimate.job.serviceProviderJobId">
      <a routerLink="/job-page/{{estimate.job.jobDocId}}">
        {{estimate.job.serviceProviderJobId}}
      </a>
    </ng-container>
    <td style="background-color: #e8e8e8;" mat-footer-cell *matFooterCellDef>
  </td>
</ng-container>

<!-- Add line item -->
<ng-container matColumnDef="addEstimate">
  <td mat-footer-cell *matFooterCellDef colspan="4">
    <!-- Invoices to display do not allow line items to be added ( but discounts can be ) -->
    <!-- <div class="add-line-item-button">
    <button mat-button class="button-blue" type="button" (click)=openEstimate(null)>New Estimate</button> -->
    <!-- <button mat-button class="button-blue" type="button" (click)=presentLineItemCreationModal(null)>Add Line Item IONICS!</button> -->
    <!-- </div> -->
  </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
<tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
</tr>
<tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
<tr mat-footer-row *matFooterRowDef="footerSecondRowColumns" class="example-second-footer-row"></tr>
  </table>
</div>
