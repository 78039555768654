import { Injectable } from '@angular/core'
import { FirestoreDiffService } from './firestore-diff.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { SkillSet } from '../dao/skill-set';
import { AuthenticationService } from '../../util/authentication.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { EmployeeRoleService } from './employee-role.service';

@Injectable({
  providedIn: 'root'
})
export class SkillSetService extends DatabaseStoreService<SkillSet> {

  constructor( fs: SkillSetFirestoreService, store: SkillSetStoreService, authenticationService: AuthenticationService,
      private employeeRoleService: EmployeeRoleService) {
    super(fs, store, authenticationService, new Map([
      ["applicibleEmployeeRoles", {associatedDocumentId: "applicableEmployeeRoleDocIds", compositionStoreService: employeeRoleService}]]));
  }
}

@Injectable({
  providedIn: 'root'
})
export class SkillSetStoreService extends StateChangeStoreService<SkillSet> {
  protected store = "SkillSet-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, SkillSet>(), true, firestoreDiffService);
  }
}

@Injectable({
providedIn: 'root'
})
export class SkillSetFirestoreService extends FirestoreBackend<SkillSet> {

 protected basePath = "SkillSet";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): SkillSet {
return new SkillSet(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new SkillSet(), authService);
 }
}
