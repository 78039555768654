import { Injectable } from '@angular/core';
import {db} from '../db';
import { Iphoto } from '../../../../common/src/data/dao/iphoto';
import { from, of } from 'rxjs';
import { tap,catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {

  constructor() { }

  getPhoto(docId: string) {
    return from(db.image.where("docId").equals(docId).first()).pipe(
      map(x => {
        if (x) {
          return new Iphoto(x);
        } else {
          return null;
        }
      }),
      catchError(err => {
      return of(null);
      })
    );
  }

  registerPhotoAccessed(docId: string) {
    return from(db.image.update(docId, {lastAccessed: new Date()}));
  }

  addPhoto(photo: Iphoto) {
    return from(db.image.put(photo));
  }
}
