import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'formly-wrapper-change-detect',
  template: `
<div style="display:none">{{changeDetect|async}}</div>
<ng-container #fieldComponent></ng-container>
`,
changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormlyChangeDetectComponent extends FieldWrapper {

  get changeDetect() {
    if (!(this.to.changeDetect instanceof Subject)) {
      this.to.changeDetect = new Subject();
    }
    return this.to.changeDetect;
  }
}
