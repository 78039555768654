<form class="container1" [formGroup]="form">
  <div class="left-side"></div>
  <div class="middle">
    <div class="employee-grid" style="margin-bottom: 30px;" >
      <div class="header-label">Company Settings</div>
      <!-- Name -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Company Name</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input [name]="ElementNameForId('323462')" autofill='off' autocomplete='new-password' data-lpignore='true'
           formControlName="companyName" matInput placeholder="Company Name">
          <mat-error>Company name must be provided.</mat-error>
        </mat-form-field>
      </div>
      <!-- Phone Number -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Phone Number</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input mask="(000) 000-0000" [name]="ElementNameForId('757733')" formControlName="companyPhoneNumber" matInput placeHolder="Phone Number"
          autofill='off' autocomplete='new-password' data-lpignore='true'>
          <mat-error>Phone number must be provided.</mat-error>
        </mat-form-field>
      </div>
      <!-- Email Address -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Customer Facing Email</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input matInput type="email" formControlName="companyEmailAddress" placeholder="Email Address"
          [name]="ElementNameForId('513384')" autofill='off' autocomplete='new-password' data-lpignore='true'>
          <mat-error>Email address must be provided.</mat-error>
        </mat-form-field>
      </div>
      <!-- BCC E-mail address -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">BCC Email</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input matInput type="email" formControlName="companyBccEmailAddress" placeholder="BCC Email Address"
          [name]="ElementNameForId('58484')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </mat-form-field>
      </div>
      <!-- Website -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Website</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input matInput type="url" formControlName="website" placeholder="Company Website"
          [name]="ElementNameForId('214063')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </mat-form-field>
      </div>
      <!-- Referral Code -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Referral Code</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input readonly="true" matInput formControlName="referralCode" placeholder="Referral Code">
        </mat-form-field>
      </div>
      <!-- Industry -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Industry</div>
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <mat-select formControlName="industry" placeholder="Choose Industry" [compareWith]="compareWithDocIds">
            <mat-option *ngFor="let industry of industryService.loadAll$() | async" [value]="industry">{{industry.name}}</mat-option>
          </mat-select>
          <mat-error>Industry must be selected</mat-error>
      </mat-form-field>
      </div>
      <!-- Job Increment Counter -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Job Number</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input matInput formControlName="currentJobCounter" placeholder="Job Counter" mask="0*">
          <mat-error *ngIf="form.controls['currentJobCounter'].errors?.min">Job counter can not be set below {{currentJobCounter}}</mat-error>
          <mat-error *ngIf="form.controls['currentJobCounter'].errors?.required">Job counter is required.</mat-error>
        </mat-form-field>
      </div>
      <!-- Job Number Formatting-->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Job Number Formatting</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input matInput formControlName="jobNumberFormatting" placeholder="Job Formatting">
          <mat-error *ngIf="form.controls['jobNumberFormatting'].errors?.invalidJobFormatting">Job Number Formatting must include &lt;&lt;counter&gt;&gt;</mat-error>
        </mat-form-field>
      </div>
      <!-- Job Number Formatting-->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Job Number Output</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input matInput readOnly="true" formControlName="nextJobNumberDisplay">
        </mat-form-field>
      </div>
      <app-settings-table style="display: contents;"
      [detailTemplate]="location"
      [settingTableTitle]="'Locations'"
      [dataSource]="offices$"
      [columns]="locationColumns"
      (rowSelected$)="selectLocation($event)"
      (saveRow$)="saveLocation($event)"
      (addRow$)="addLocation()"
      ></app-settings-table>

      <ng-template #location>
        <form [formGroup]="companyLocationForm" style="display: contents;">
          <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Name</div>
          <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline" class="medium-field">
              <input matInput placeholder="" formControlName="name">
            </mat-form-field>
          </div>
          <!-- Mailing Address -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Mailing Address</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" style="margin-top: 5px;" class="medium-field">
          <input matInput placeholder="Mailing Address" formControlName="mailingAddressDisplay" readonly=true>
          <mat-error>Mailing Address must be provided.</mat-error>
        </mat-form-field>
        <button type="button" mat-button style="margin-left: 30px !important;" class="button-light-blue" (click)="UpdateAddress('Mailing Address')">Change Mailing Address</button>
      </div>
      <!--Dispatch Address-->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Dispatch Address</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" style="margin-top: 5px;" class="medium-field">
          <input matInput placeholder="Starting Address" formControlName="dispatchAddressDisplay" readonly=true>
          <mat-error>Default Dispatch Address must be provided.</mat-error>
        </mat-form-field>
        <button type="button" mat-button style="margin-left: 30px !important;" class="button-light-blue" (click)="UpdateAddress('Dispatch Address')">Change Dispatch Address</button>
      </div>
      <!-- Default -->
      <div style="width: 100%; grid-column: 2/3;">
        <mat-checkbox formControlName="default">default</mat-checkbox>
      </div>
        </form>
      </ng-template>


      <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
        <div class="button-position">
          <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
        </div>


        <div class="button-position">
          <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
        </div>

        </div>
    </div>
  </div>
</form>
