import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service';
import { ResourceDay as ResourceDay } from '../dao/resource-day';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceDayService extends DatabaseStoreService<ResourceDay> {

  constructor( fs: resourceDayFirestoreService, store: resourceDayStoreService, authenticationService: AuthenticationService) {
super(fs, store, authenticationService);
  }

  public atomicallyIncrementNumberEntries(resourceDay: ResourceDay, numberToAdd: number, batch : string | null) {
    this.fs.atomicallyIncrementField(resourceDay, 'numberEntries', numberToAdd, batch);
  }
}

@Injectable({
  providedIn: 'root'
})
export class resourceDayStoreService extends StateChangeStoreService<ResourceDay> {
  protected store = "resourceDay-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, ResourceDay>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class resourceDayFirestoreService extends FirestoreBackend<ResourceDay> {

 protected basePath = "resourceDay";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): ResourceDay {
return new ResourceDay(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new ResourceDay(), authService);
 }
}
