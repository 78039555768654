import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class FormCatagory extends RetrieveFirestorePropertiesBase<FormCatagory>  {

name: string;
active: boolean = true;
parentDocId: string = "";

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return FormCatagory._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return FormCatagory._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return FormCatagory._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return FormCatagory._firestoreCompositionMemberNames;}

public constructor(init?: Partial<FormCatagory>) {
super();
Object.assign(this, init);
}
}
