
<div class="modify-employees-schedule-grid">
<mat-dialog-content>
  <div class="header-label">Delete Line Item</div>
  <div class="label-style">
    {{content}}
  </div>
  <div style="display: flex; justify-content: center; padding: 20px;">
    <mat-form-field appearance="outline" style="width: 430px;" rows="5">
    <textarea matInput [(ngModel)]="textArea" cdkTextareaAutosize></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: contents;">
  <div class="footer-button" class="footer-cust" style="grid-column: 1/-1; padding-top: 20px;">
    <div>
      <button mat-button class="button-gray" mat-dialog-close (click)="cancelClose()">Cancel</button>
    </div>
  <div>
    <button mat-button class="button-blue" style="grid-column: 2/3;" mat-dialog-close (click)="okClose()">Save</button>
    </div>
  </div>
</mat-dialog-actions>
</div>




