import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineItemCreationModalComponent } from './line-item-creation-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TimeFieldModule } from '../../../time-field/public_api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { AddEditPrototypeModalModule } from '../add-edit-prototype-modal';
import { MultiselectNestedTreeModule } from '../../../multiselect-nested-tree/public_api';


@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatInputModule,
    MatIconModule,
    TimeFieldModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    MatDialogModule,
    AddEditPrototypeModalModule,
    MultiselectNestedTreeModule
  ],
  declarations: [LineItemCreationModalComponent],
})
export class LineItemCreationModalModule { }

/*
providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useValue: MAT_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY,
    }
  ]
  */
