import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { PricingMethodologySettings } from '../../../../../common/src/data/dao/pricing-methodology-settings';
import { PricingMethodologySettingsService } from '../../../../../common/src/data/dao-services/pricing-methodology-settings.service';
import { TaxRateSettings } from '../../../../../common/src/data/dao/tax-rate-settings';
import { TaxRateSettingsService } from '../../../../../common/src/data/dao-services/tax-rate-settings.service';
import { randomElementName } from '../../../../../common/src/util/util';
import { where } from 'firebase/firestore';

@Component({
  selector: 'app-pricebook-settings',
  templateUrl: './pricebook-settings.component.html',
  styleUrls: ['./pricebook-settings.component.scss']
})
export class PricebookSettingsComponent implements OnInit, OnDestroy {


  get taxRateForm() { return this.form.get("taxRateFormGroup") as UntypedFormGroup;}
  get pricingMethodologyForm() { return this.form.get("pricingMethodologyFormGroup") as UntypedFormGroup;}

  form: UntypedFormGroup;


  randomElementName : string = randomElementName();
  destroyingComponent$ = new Subject();
  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  taxRateFieldsToPatch = ["name","percent","applyToMaterials","applyToLabor","default"];
  taxRateSetting: TaxRateSettings;
  pricingMethodologySetting: PricingMethodologySettings;


  constructor(public taxRateSettingsService: TaxRateSettingsService, private pricingMethodologyService:
    PricingMethodologySettingsService, private fb: UntypedFormBuilder, private snackBar: MatSnackBar) {
  }

  initilizeTaxRateForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      name: [],
      percent: [0],
      default: [true],
      applyToMaterials: [false],
      applyToLabor: [false]
    });
    return retVal;
  }

  initilizeForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      selectedTaxRate: [],
      taxRateFormGroup: this.initilizeTaxRateForm(),
      pricingMethodologyFormGroup: this.pricingMethodologyService.initilizePricingMethodologyForm()
    });

    retVal.get("selectedTaxRate").valueChanges.pipe(
      filter(x => x !== null),
      tap(x => {
        this.taxRateSetting  = this.taxRateSettingsService.getCloneOfCachedValue((x as TaxRateSettings).DocId());
        this.patchTaxRateSettings(this.taxRateSetting),
      takeUntil(this.destroyingComponent$)
      })).subscribe();

    return retVal;
  }

  addNewTaxRate() {
    const t = new TaxRateSettings();
      this.patchTaxRateSettings(t);
  }

  patchTaxRateSettings(settings: TaxRateSettings) {

    this.taxRateFieldsToPatch.forEach(field => {
      this.taxRateForm.get(field).patchValue(settings[field]);
    });
  }

  patchPricingMethodologySettings(settings: PricingMethodologySettings) {
    this.pricingMethodologyService.pricingMethodologyFieldsToPatch.forEach(field => {
      this.pricingMethodologyForm.get(field).patchValue(settings[field]);
    });
  }

  patchFormGroupToPricingMethodologySettings() {
    this.pricingMethodologyService.pricingMethodologyFieldsToPatch.forEach(field =>  this.pricingMethodologySetting[field] = this.pricingMethodologyForm.get(field).value);
  }

  patchFormGroupToTaxRateSettings() {
    this.taxRateFieldsToPatch.forEach(field =>  this.taxRateSetting[field] = this.taxRateForm.get(field).value);
  }



  Cancel() {
    this.form.reset();
    if (this.taxRateSetting !== undefined) {
      const patchTax = this.taxRateSettingsService.getCloneOfCachedValue(this.taxRateSetting.DocId());
    this.patchTaxRateSettings(patchTax);
    }
    const patchPricingMethod = this.pricingMethodologyService.getCloneOfCachedValue(this.pricingMethodologySetting.DocId());
    this.patchPricingMethodologySettings(patchPricingMethod);
  }

  Save() {
    if (this.form.valid) {
      this.snackBar.open("Saving Updates",undefined);
      this.patchFormGroupToPricingMethodologySettings();
      const backEndUpdates : Observable<any>[] = [this.pricingMethodologyService.update$(this.pricingMethodologySetting)];
      if (this.taxRateSetting !== undefined) {
        this.patchFormGroupToTaxRateSettings();
        backEndUpdates.push(this.taxRateSettingsService.update$(this.taxRateSetting));
      }
      combineLatest(backEndUpdates).pipe(
        take(1)
      ).subscribe(() => this.snackBar.dismiss());
    }
  }


  ngOnInit(): void {
    this.form = this.initilizeForm();
    this.pricingMethodologyService.queryFirestoreDeep$([where('default','==',true)]).pipe(
      tap(x => this.pricingMethodologySetting = x[0]),
      tap(x => this.patchPricingMethodologySettings(this.pricingMethodologySetting)),
      take(1)
    ).subscribe();
  }

}
