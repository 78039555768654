import { Injectable } from '@angular/core'
;
import { AuthenticationService } from '../../util/authentication.service'
import { Commute } from '../dao/commute';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})

export class CommuteService extends DatabaseStoreService<Commute> {

  constructor( fs: CommuteFirestoreService, authenticationService: AuthenticationService, store: CommuteStoreService) {
      super(fs, store, authenticationService);
    }

}

@Injectable({
  providedIn: 'root'
})
export class CommuteStoreService extends StateChangeStoreService<Commute> {
  protected store = "Commute-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, Commute>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class CommuteFirestoreService extends FirestoreBackend<Commute> {

 protected basePath = "Commute";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Commute {
return new Commute(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new Commute(), authService);
 }
}
