import { Injectable } from '@angular/core';
import { addHours, addMinutes, startOfDay, subHours, subMinutes } from 'date-fns';
import { SiteVisit } from '../../../../common/src/data/dao/site-visit';

@Injectable({
  providedIn: 'root'
})
export class ArrivalWindowDressingService {

  constructor() { }


  private halfOfArrivalWindow(arrivalWindowLength: Date) : Date {
    var deltaMilliseconds = arrivalWindowLength.getTime() - startOfDay(arrivalWindowLength).getTime();
    deltaMilliseconds = deltaMilliseconds / 2;
    return new Date(startOfDay(arrivalWindowLength).getTime() + deltaMilliseconds);
  }

  public regenerateArrivalWindow(start: Date, siteVisit: SiteVisit, employeeWorkStartTime: Date, employeeWorkEndTime: Date, settings: any) :
    {arrivalWindowStartDate: Date, arrivalWindowEndDate: Date} {


    const minutesAtArrivalWindowFidelity = Math.round(start.getMinutes() / settings["minutesFidelityArrivalWindow"]) *
    settings["minutesFidelityArrivalWindow"];
    start = addMinutes(subMinutes(start,start.getMinutes()), minutesAtArrivalWindowFidelity);
    let arrivalWindowStartDate: Date;
    let arrivalWindowEndDate: Date;

    if (start.getMinutes() + start.getHours() * 60 < employeeWorkStartTime.getHours() * 60 + employeeWorkStartTime.getMinutes()) {
      arrivalWindowStartDate = new Date(start.getTime());
    } else {
    arrivalWindowStartDate = subHours(start, 2).getHours() * 60 + start.getMinutes() < employeeWorkStartTime.getHours() * 60 + employeeWorkStartTime.getMinutes() ?
      addMinutes(subMinutes(start, start.getHours() * 60 + start.getMinutes()),
      employeeWorkStartTime.getHours() * 60 + employeeWorkStartTime.getMinutes()) :
      subMinutes(subHours(start, this.halfOfArrivalWindow(settings["defaultArrivalWindowLength"]).getHours()),this.halfOfArrivalWindow(settings["defaultArrivalWindowLength"]).getMinutes());
    }
    arrivalWindowEndDate = addMinutes(addHours(arrivalWindowStartDate, settings["defaultArrivalWindowLength"].getHours()),
      settings["defaultArrivalWindowLength"].getMinutes());

    if (arrivalWindowEndDate.getHours() * 60 + arrivalWindowEndDate.getMinutes() >   employeeWorkEndTime.getHours() * 60 + employeeWorkEndTime.getMinutes()) {
      const minutesEarlier = arrivalWindowEndDate.getHours() * 60 + arrivalWindowEndDate.getMinutes() - (employeeWorkEndTime.getHours() * 60 + employeeWorkEndTime.getMinutes());
      arrivalWindowEndDate = subMinutes(arrivalWindowEndDate, minutesEarlier);
      arrivalWindowStartDate = subMinutes(arrivalWindowStartDate, minutesEarlier);
    }
    if (start.getMinutes() + start.getHours() * 60 > employeeWorkEndTime.getHours() * 60 + employeeWorkEndTime.getMinutes()) {
      arrivalWindowStartDate = subMinutes(subHours(start, this.halfOfArrivalWindow(settings["defaultArrivalWindowLength"]).getHours()),this.halfOfArrivalWindow(settings["defaultArrivalWindowLength"]).getMinutes());
      arrivalWindowEndDate = new Date(start.getTime());
    }
    return ({arrivalWindowStartDate, arrivalWindowEndDate});

  }
}
