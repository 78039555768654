<div style="display:none">{{unsavedChanges$|async}}</div>
<mat-menu #fileMenu="matMenu" >
  <ng-container *ngIf="activeWorkflowType; else explicitSaveBlock">
    <button mat-menu-item (click)="saveWorkflow(null)">
      <mat-icon style="color: green;">east</mat-icon>
      <span>Save</span>
    </button>
  </ng-container>
  <ng-template #explicitSaveBlock>
    <button mat-menu-item [matMenuTriggerFor]="explictSaveSpecifiedTypeMenu">
      <mat-icon style="color: green;">east</mat-icon>
      <span>Save</span>
    </button>
  </ng-template>

  <button mat-menu-item (click)="loadWorkflow()" [disabled]="loadedFromBranch">
    <mat-icon style="color: green;">east</mat-icon>
    <span>Load</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="newWorkflowSubMenu" [disabled]="loadedFromBranch">
    <mat-icon style="color: green;">east</mat-icon>
    <span>New</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="removeWorkflow()" [disabled]="isMain || loadedFromBranch">
    <mat-icon style="color: blueviolet;">west</mat-icon>
    <span>Remove</span>
  </button>
    <button mat-menu-item (click)="copyWorkflow()" [disabled]="!activeFormFirestore">
      <mat-icon style="color: #ce1b1b;">delete_sweep</mat-icon>
      <span>Make Copy</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="deployWorkflow()" [disabled]="!deployToWorkflowActive">
      <mat-icon style="color: #ce1b1b;">delete_sweep</mat-icon>
      <span>Deploy Workflow</span>
    </button>
    <button mat-menu-item (click)="revertToDeployedWorkflow()" [disabled]="!revertDeloyedActive">
      <mat-icon style="color: #ce1b1b;">delete_sweep</mat-icon>
      <span>Reset to Deployed Version</span>
    </button>
    <button mat-menu-item (click)="toggleWorkflowActivationStatus()" [disabled]="!activeFormFirestore || loadedFromBranch">
      <mat-icon *ngIf="activeFormFirestore && activeFormFirestore.formSummary.active" style="color: #ce1b1b;">check_box</mat-icon>
      <mat-icon *ngIf="!activeFormFirestore || !activeFormFirestore.formSummary.active" style="color: #ce1b1b;">check_box_outline_blank</mat-icon>
      <span>Active</span>
    </button>
</mat-menu>

<mat-menu #explictSaveSpecifiedTypeMenu="matMenu">
  <button mat-menu-item (click)="saveWorkflow('topLevel')" [disabled]="!isMain">Top Level Workflow </button>
  <button mat-menu-item (click)="saveWorkflow('section')">Section </button>
</mat-menu>

<mat-menu #newWorkflowSubMenu="matMenu">
  <button mat-menu-item (click)="newWorkflow('topLevel')" [disabled]="!isMain">Top Level Workflow </button>
  <button mat-menu-item (click)="newWorkflow('section')">Section </button>
</mat-menu>

<div class="design-view-button-container" style="width: 788px;">  <!--width 788 makes gives the header firm width. Which makes the text box not push outside the normal witdth of design view a text box has a width of 80% of 788px-->
  <div class="button-container">
      <button mat-button [matMenuTriggerFor]="fileMenu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="design-view-style" [ngClass]="CheckUnsavedChanges()" style="width: 80%; text-align: center;">{{getTitle}} (Design View) </div>
  <mat-icon
    *ngIf="activeFormFirestore && activeFormFirestore.formSummary.currentDesignFirestoreDocId !== activeFormFirestore.formSummary.currentDeployedFirestoreDocId"
    matTooltip= "Changes present in design which have not yet been deployed"
    color="warn">
      file_upload_off
  </mat-icon>
</div>
