import { addMinutes, differenceInMinutes, getHours, getMinutes} from "date-fns";
import { SiteVisit } from "../../../../common/src/data/dao/site-visit";

export enum RESOURCE_AVAILIBILITY_CALCULATION_METHOD {
  DEFAULT = "DEFAULT",
  MINIMZE_SITE_VISIT_MUTATIONS = "MINIMZE_SITE_VISIT_MUTATIONS",
}

export class ResourceAvailibility {
  numberPreceedingSiteVisitsMutated: number;
  minutesMutatedAtStartOfSegmentPreceedingSiteVisits: number;
  numberPostceedingSiteVisitsMutated: number;
  minutesMutatedAtStartOfSegmentPostceedingSiteVisits: number;
  guid: string;

  get numberSiteVisitsMutated(): number { return this.numberPostceedingSiteVisitsMutated + this.numberPreceedingSiteVisitsMutated; }

  employeeDocId: string;
  actualDate: Date;
  startDate: Date;
  endDate: Date;
  available = true;
  resourceId: string;
  timeRangeColor: string;
  commuteMinutesDelta: number;
  commuteMinutesBeforeSiteVisit: number;
  commuteMinutesAfterSiteVisit: number;
  siteVisitNumber: number;
  scheduledToWorkDuringSegment: boolean = true;
  cls: string = "";
  name: string = "";
  defaultEmployeeAvailability = true;
  preceedingSiteVisits: SiteVisit[] = [];
  postceedingSiteVisits: SiteVisit[] = [];

  static resourcesSegmentsSameMutations(a: ResourceAvailibility, b: ResourceAvailibility) {
    return a.minutesMutatedAtStartOfSegmentPostceedingSiteVisits === b.minutesMutatedAtStartOfSegmentPostceedingSiteVisits &&
    a.minutesMutatedAtStartOfSegmentPreceedingSiteVisits === b.minutesMutatedAtStartOfSegmentPreceedingSiteVisits &&
    a.numberPostceedingSiteVisitsMutated === b.numberPostceedingSiteVisitsMutated &&
    a.numberPreceedingSiteVisitsMutated === b.numberPreceedingSiteVisitsMutated;
  }

  get actualStartTime() : Date {
    return addMinutes(this.actualDate, getHours(this.startDate) * 60 + getMinutes(this.startDate));
  }

  get actualEndTime() : Date {
    return addMinutes(this.actualDate, getHours(this.endDate) * 60 + getMinutes(this.endDate));
  }

  public constructor(init?: Partial<ResourceAvailibility>) {
    Object.assign(this, init);
    if (this.timeRangeColor === undefined) {
      this.timeRangeColor = this.available ? "green" : "red";
      this.cls = this.scheduledToWorkDuringSegment ? "" : "scheduledOff";
    }
    if (this.timeRangeColor === "white") {
      this.cls = this.scheduledToWorkDuringSegment ? "scheduledWork" : "scheduledOff";
    }
    if (!this.defaultEmployeeAvailability) {
      if (this.cls === "") {
        this.cls = "non-standard-schedule";
      } else {
        this.cls += " non-standard-schedule";
      }
      // console.log(this);
    }
  }

  numberMinutesMutated(siteVisitStartTime: Date): number {
    const mutationMinutesPreceedingSiteVisits = this.minutesMutatedAtStartOfSegmentPreceedingSiteVisits -
    differenceInMinutes(siteVisitStartTime, this.startDate) * this.numberPreceedingSiteVisitsMutated;
    const mutationMinutesPostceedingSiteVisits = this.minutesMutatedAtStartOfSegmentPostceedingSiteVisits +
     differenceInMinutes(siteVisitStartTime, this.startDate) * this.numberPostceedingSiteVisitsMutated;
    return mutationMinutesPreceedingSiteVisits + mutationMinutesPostceedingSiteVisits;
  }

  updateStartTime(newStart: Date) {
    this.minutesMutatedAtStartOfSegmentPreceedingSiteVisits  = this.minutesMutatedAtStartOfSegmentPreceedingSiteVisits -
    differenceInMinutes(newStart, this.startDate) * this.numberPreceedingSiteVisitsMutated;
    this.minutesMutatedAtStartOfSegmentPostceedingSiteVisits = this.minutesMutatedAtStartOfSegmentPostceedingSiteVisits +
     differenceInMinutes(newStart, this.startDate) * this.numberPostceedingSiteVisitsMutated;
  }

}
