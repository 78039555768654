import { Injectable } from '@angular/core';
import { addDays, compareAsc, endOfDay, startOfDay, subDays } from 'date-fns';
import { BehaviorSubject, combineLatest, from, merge, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { switchMap, take, tap, distinctUntilChanged, filter, exhaustMap, map, mergeMap, catchError, concatMap, share, delay, debounceTime, debounce } from 'rxjs/operators';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { EmployeeService } from './employee.service';
import { JobService } from './job.service';
import {AddressRoutingLocal, db, DexieResourceDayAddressRouting} from '../../../../web-app/src/app/db';
import { AddressService } from './address.service';
import { AddressRoutingService } from './address-routing.service';
import { Address } from '../dao/address';
import { ResourceDayService } from './resource-day.service';
import { ResourceDayAddressRoutingService } from './resource-day-address-routing.service';
import { ResourceDay } from '../dao/resource-day';
import { ResourceDayAddressRouting } from '../dao/resource-day-address-routing';
import { where } from 'firebase/firestore';
import { AuthenticationService } from '../../util/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DexieCacheService {

  jobDocIdsCached: Set<string> = new Set();
  addressDocIdsMonitoredWithLastUpdate: Map<string,Date> = new Map();
  newAddressesNeedingMonitored$ : BehaviorSubject<string[]> = new BehaviorSubject([]);
  monitoredAddresses$: ReplaySubject<string[]> = new ReplaySubject(1);
  resourceDayAddressesCached$: ReplaySubject<boolean> = new ReplaySubject(1);

  resourceDayMonitoredWithLastUpdate: Map<string,Date> = new Map();

  constructor(private jobService: JobService, private settingsService: SettingsService, private employeeService: EmployeeService,
    private addressService: AddressService, private addressRoutingService: AddressRoutingService, private resourceDayService: ResourceDayService,
    private resourceDayAddressRoutingService: ResourceDayAddressRoutingService, private authenticationService: AuthenticationService)  {
      console.log("hit dexie constructor");
      this.settingsService.settingsLoaded$.pipe(
        tap(() => {
          if (this.settingsService.displayContext === 'desktop') {
          this.observeAddressesWeMustCache();
          this.observeResourceDaysWeMustCache();
        } else {
          this.observeResourceDaysWeMustCache();
        }
        }),
        take(1),
      ).subscribe();
  }

  getDexieResouceDayPrimaryKeyFromResourceDay(resourceDay: ResourceDay) {
    return (resourceDay.resourceDay.getTime()/1000/60/60/24).toString() + resourceDay.employeeDocId;
  }

  getDexieResourceDayKeyFromResourceDayAddressRouting(val: ResourceDayAddressRouting) {
    return (val.resourceDay.getTime()/1000/60/60/24).toString() + val.employeeDocId;
  }

  getDexieResourceDayAddressRouting(val: ResourceDayAddressRouting) : DexieResourceDayAddressRouting {

    const { employeeDocId,resourceDay,  ...addressRoutingLocalProper} = val;
    return {
      docId: val.docId,
      resourceDayEmployeeDocIdKey: this.getDexieResourceDayKeyFromResourceDayAddressRouting(val),
      employeeDocId: val.employeeDocId,
      resourceDay: val.resourceDay,
      addressRoutingLocal: addressRoutingLocalProper
    };
  }

  observeResourceDaysWeMustCache() {

    let currentCounter = 0;
    let totalCounter = 0;

    let retrievingResourceDayInformationFromDexie = false;

    const resourceDaysNeedingUpdate = this.resourceDayService.queryFirestoreShallow$([where('resourceDay', '>=', startOfDay(new Date()))]).pipe(
      debounce(() => of(retrievingResourceDayInformationFromDexie).pipe(filter(x => !x))),
      tap(() => retrievingResourceDayInformationFromDexie = true),
      // We are only interested in updating resourceDay entries we have not yet cached, or which have been updated since we last cached them.
      map(x => x.filter(y => this.resourceDayMonitoredWithLastUpdate.get(this.getDexieResouceDayPrimaryKeyFromResourceDay(y)) === undefined ||
      this.resourceDayMonitoredWithLastUpdate.get(this.getDexieResouceDayPrimaryKeyFromResourceDay(y)).getTime() < y.lastUpdatedAt.getTime())),
      exhaustMap(toCheck => from(!db.resourceDayCommuteUpdated ? of([]) : db.resourceDayCommuteUpdated.where("resourceDayEmployeeDocIdKey").anyOf(
        toCheck.map(y => this.getDexieResouceDayPrimaryKeyFromResourceDay(y))).toArray()).pipe(
        tap(present => present.forEach(y => this.resourceDayMonitoredWithLastUpdate.set(y.resourceDayEmployeeDocIdKey, y.commuteUpdatedDate))),
        map(() => toCheck.filter(y => this.resourceDayMonitoredWithLastUpdate.get(this.getDexieResouceDayPrimaryKeyFromResourceDay(y)) === undefined ||
        this.resourceDayMonitoredWithLastUpdate.get(this.getDexieResouceDayPrimaryKeyFromResourceDay(y)).getTime() < y.lastUpdatedAt.getTime())),
      )),
      tap(() => retrievingResourceDayInformationFromDexie = false),
      share()
      );

      const retrieveRoutes = resourceDaysNeedingUpdate.pipe(
      filter(x => x.length > 0),
      tap(x => {
        totalCounter = x.length;
        currentCounter = 0;
      }),
      exhaustMap(x => from(x)),
      concatMap(res => this.resourceDayAddressRoutingService.queryFirestoreShallow$([where("employeeDocId","==",res.employeeDocId),where("resourceDay","==",res.resourceDay)]).pipe(
        tap(x => {
          if (x.length !== res.numberEntries) {
            console.error(`Resource Day ${res.resourceDay} for ${res.employeeDocId} has ${x.length} entries, but expected ${res.numberEntries}`);
          }
        }),
        filter(x => x.length === res.numberEntries),
        map(val => {
            return {val: val, source: res as ResourceDay}
          }),
          exhaustMap(x => {
            return from(db.transaction("rw", [db.resourceDayCommuteUpdated, db.resourceDayAddressRoutes], () => {
              db.resourceDayAddressRoutes.bulkPut(x.val.map(x => this.getDexieResourceDayAddressRouting(x)));
              db.resourceDayCommuteUpdated.put({resourceDayEmployeeDocIdKey: this.getDexieResouceDayPrimaryKeyFromResourceDay(x.source), employeeDocId: x.source.employeeDocId,
                resourceDay: x.source.resourceDay, docId: x.source.docId, commuteUpdatedDate: x.source.lastUpdatedAt, numberEntries: x.source.numberEntries});
              return x;
           }).then((added) => {
            currentCounter++;
            console.error(`${currentCounter} of ${totalCounter} resource days done.  ${added.val.length} for ${res.resourceDay}   ${res.employeeDocId}`);
            return added;
           }).catch((error) => {
            console.error(error);
            return null;
          })).pipe(
            // if given resource day is in group we are actively monitoring, we need to ensure any new commute possibilities are added.
            tap(y => {
              if (this.addressRoutingService.cachedResouceDays.some(x => x.getTime() === y.source.resourceDay.getTime()))
              {
                console.log(`adding to actively cached addresses ${y}`);
                this.addressRoutingService.cacheDexieEntries(y.val.map(x => this.getDexieResourceDayAddressRouting(x)).map(q => q.addressRoutingLocal));
                this.addressRoutingService.cacheResourceDayEntries(y.val.map(x => this.getDexieResourceDayAddressRouting(x)));
                this.addressRoutingService.updatedResourceDate$.next(y.source.resourceDay);

              }
            }),
          )
        }),
        take(1),
        )),
      tap(() => this.resourceDayAddressesCached$.next(true)),
      tap(() => console.log("done with resource days")),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      })
      );

      if (this.authenticationService.mobileSite) {
        merge(retrieveRoutes, resourceDaysNeedingUpdate.pipe(filter(x => x.length === 0)))
          .pipe(
          tap(x => console.log("MOBILE ROUTES DONE")),
          take(1),
        ).subscribe();
      } else {
        retrieveRoutes.subscribe();
      }
  }

  observeAddressesWeMustCache()  {

    let currentCounter = 0;
    let totalCounter = 0;
    let monitoredAddresses : string[] = [];

    const addressesToObserveStream = this.observeAddressesDocIdsToMonitor().pipe(
      tap(x => monitoredAddresses = x),
      filter(x => x.length > 0),
      distinctUntilChanged((prev,curr) => prev.length === curr.length && prev.every((v,i) => v === curr[i])),
      // load the addresses.
      switchMap(x => this.addressService.queryFirestoreForInValues("docId",[...x]).pipe(
        // debounceTime(200),
      )),
      // Need to pull check dexie on initial population, or if newer commute matrix data present.
      map(x => x.filter(y => y.commuteMatrixGenerationTime && (
        this.addressDocIdsMonitoredWithLastUpdate.get(y.docId) === undefined ||
        this.addressDocIdsMonitoredWithLastUpdate.get(y.docId).getTime() < y.commuteMatrixGenerationTime.getTime()))),
      //fresh data from firestore if addresses is not present in dexie, or if last updated in dexie older then last updated from firestore
      exhaustMap(toCheck => from(!db.addressCommuteUpdated ? of([]) : db.addressCommuteUpdated.where("addressDocId").anyOf(toCheck.map(y => y.docId)).toArray()).pipe(
        tap(present => present.forEach(y => this.addressDocIdsMonitoredWithLastUpdate.set(y.addressDocId, y.commuteUpdatedDate))),
        map(() => toCheck.filter(y => this.addressDocIdsMonitoredWithLastUpdate.get(y.docId) === undefined ||
        this.addressDocIdsMonitoredWithLastUpdate.get(y.docId).getTime() < y.commuteMatrixGenerationTime.getTime()
        && y.commuteMatrixGenerationTime.getTime() > subDays(new Date(),5).getTime())),
      )),
      share()
      );

      const oneOrMoreAddressesNeedsUpdated = addressesToObserveStream.pipe(
      filter(x => x.length > 0),
      tap(x => {
        totalCounter = x.length;
        currentCounter = 0;
      }),
      exhaustMap(x => from(x)),
      concatMap(address => this.addressRoutingService.queryFirestoreShallow$([where("originAddressDocId","==",address.docId)]).pipe(
        distinctUntilChanged((prev,curr) => prev.length === curr.length),
        map(val => {
            return {val: val, source: address as Address}
          }),
          exhaustMap(x => {
            return from(db.transaction("rw", [db.addressRoutingLocal, db.addressCommuteUpdated], () => {
              db.addressRoutingLocal.bulkPut(x.val.map(x => x as AddressRoutingLocal));
              console.log(`${x.source.docId}   ${x.source.commuteMatrixGenerationTime.getTime()}`);
              db.addressCommuteUpdated.put({addressDocId: x.source.docId,
                commuteUpdatedDate: x.source.commuteMatrixGenerationTime});
              return x.val.length;
           }).then((added) => {
            currentCounter++;
            console.log(`${currentCounter} of ${totalCounter} done.  ${added} for address ${x.source.docId}`);
           }).catch((error) => {
            console.error(error);
          }))
        }),
        take(1),
        )),
      tap(x => {
        if (currentCounter === totalCounter) {
        this.monitoredAddresses$.next(monitoredAddresses);
        }
      }),
    );

    const noUpdatesNeeded = addressesToObserveStream.pipe(
      filter(x => x.length === 0),
      tap(x => this.monitoredAddresses$.next(monitoredAddresses)));

    merge(oneOrMoreAddressesNeedsUpdated,noUpdatesNeeded).subscribe();
  }

  observeAddressesDocIdsToMonitor() : Observable<string[]> {
    // tech dispatch origin / destination addresses.
    const employeeDispatchAddresses = this.employeeService.loadAll$().pipe(
      map(emps => {
        const addresses : string[] = [];
        for (const emp of emps) {
          if (emp.dispatchOrginAddressDocId && !addresses.includes(emp.dispatchOrginAddressDocId)) {
            addresses.push(emp.dispatchOrginAddressDocId);
          }
          if (emp.dispatchDestinationAddressDocId && !addresses.includes(emp.dispatchDestinationAddressDocId)) {
            addresses.push(emp.dispatchDestinationAddressDocId);
          }
        }
        return addresses;
      }),
      distinctUntilChanged((prev,curr) => prev.length === curr.length && prev.every((v,i) => v === curr[i])),
    );

    // business dispatch address
    const businessDispatchAddress = this.settingsService.settingsLoaded$.pipe(
      map(x => [this.settingsService.companySettings.dispatchAddressDocId]),
      distinctUntilChanged(),
    );
    // addresses from unscheduled jobs and scheduled jobs that occur in future in next two months.
    const addressesForMonitoredJobs = this.jobService.retrieveJobIdsToMonitorForScheduler(startOfDay(new Date()), endOfDay(addDays(new Date(), 60)), true).pipe(
      map(x=> x.filter(jobDocId => !this.jobDocIdsCached.has(jobDocId))),
      filter(x => x.length > 0),
      switchMap(x => this.jobService.queryFirestoreForInValues('jobDocId', x, false)),
      map(x => x.map(job => job.serviceAddressDocId)),
      map(x => x.filter(addressDocId => !this.addressDocIdsMonitoredWithLastUpdate.has(addressDocId))),
      filter(x => x.length > 0),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      })
    );

    return combineLatest([employeeDispatchAddresses, businessDispatchAddress, addressesForMonitoredJobs, this.newAddressesNeedingMonitored$]).pipe(
      map(x => x.flatMap(y => y)),
      map(x => new Set(x)),
      map(x => Array.from(x).sort()),
      distinctUntilChanged((prev,curr) => prev.length === curr.length && prev.every((v,i) => v === curr[i])),
    );
  }
}
