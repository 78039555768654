<div [formGroup]="form">
  <div style="display: flex; align-items: center;">
    <mat-form-field appearance="outline" style="width: 330px;">
      <textarea style="height: 25px;" [name]="ElementNameForId(12123)" autofill="off" cdkTextareaAutosize autocomplete="new-password" matInput placeholder="Search Job, Customer, Address Details" [matAutocomplete]="nameAuto"
      data-lpignore="true" (keydown)="excludeEnter($event)" (keyup)="searchKeyUp($event)" #inputBox formControlName="serviceAddressSearch"></textarea>
      <!-- <mat-error>Select address</mat-error> -->
      <mat-autocomplete class="double-search-bar-height" panelWidth=600px #nameAuto="matAutocomplete" (optionSelected)="optionSelected($event)">
        <!-- What is displayed for mat-option varies depending on whether search result is an existing customer.  -->
        <!-- Start of Customer Search Header -->
        <div class="customer-search-header" *ngIf="(customerResultCount$ | async).valueOf() > 0; else jobHeaderBlock">
          <div class="search-header" >Customer Results ({{customerResultCount$ | async}} Results)</div>
        </div>

        <ng-template #jobHeaderBlock>
          <div class="customer-search-header" *ngIf="(jobResultCount$ | async).valueOf() > 0; else noResultsHeaderBlock">
            <div class="search-header" >Job Results ({{jobResultCount$ | async}} Results)</div>
          </div>
        </ng-template>

        <ng-template #noResultsHeaderBlock>
          <div class="customer-search-header">
            <div class="search-header" >No Results Found</div>
          </div>
        </ng-template>

        <!-- End of Customer Search Header -->
        <mat-option *ngFor ="let place of searchResultAndJob$ | async" trackBy:searchResultAndJob [value]="place">
          <div class="padding-correction" *ngIf="place.job === null && place.res !== null; else elseBlock">
            <i style="color: #206f93; margin-right: 6px;" class="material-icons">person_pin</i>{{getStringRepresentationOfSearchResults(place)}}
          </div>
          <ng-template #elseBlock>
            <!--If there are both customer and job results, add divider between them.-->
            <!-- Start of Jobs Search Header -->
            <ng-container *ngIf="(jobResultCount$ | async).valueOf() > 0;">
              <div *ngIf="place.res !== null else jobHeaderBlock">
                <div class="padding-correction">
                  <i class="material-icons" style="color: #209334; margin-right: 6px;">handyman</i>{{getStringRepresentationOfSearchResults(place)}}
                </div>
              </div>
          </ng-container>
          </ng-template>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div style="display: flex; justify-content: center; margin-top: 10px;">
      <div *ngIf="activeSearch" fxLayout="row" fxLayoutAlign=" center"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div *ngIf="displayNoResultsFound" fxLayout="row" fxLayoutAlign=" center" style="font-size: 16px; color: red; margin-left: 5px;">No Results Found<i class="material-icons">error</i></div>
    </div>
  </div>
</div>
