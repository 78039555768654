<div *ngIf="schedulerSetting" class="container1">
  <div class="left-side"></div>
  <div [formGroup]="form" class="employee-grid">
    <div class="header-label">Business Operating Hours</div>
    <!-- Active hours per day of the week. -->
    <div *ngFor = "let day of workWeekToRender()" style="display: contents;">
      <mat-checkbox class="label-style" [checked]="day.activeWorkDay" (change)="setCheckDay(day)">{{day.date | date:'EEEE'}}</mat-checkbox>
      <div class="time-field-specificity" fxLayout="row" fxLayoutAlign=" center">
        <app-time-field
          [value]= "day.workStartTime"
          [fieldLabel]= "'Start'"
          [readOnly]="!day.activeWorkDay"
          [granularity]="15"
        ></app-time-field>

        <div class="to-style">To</div>
        <app-time-field
          [value]= "day.workEndTime"
          [fieldLabel]= "'End'"
          [readOnly]="!day.activeWorkDay"
          [granularity]="15"
        ></app-time-field>
        <div *ngIf="!day.startEndTimesValidate" style="color: #f44336;" class="alert alert-danger">
          Start of work day must be before the end of the work day.
      </div>
      </div>
    </div>
      <div class="header-label">Scheduling Settings</div>
      
<!-- Minimum site visit length -->
<div class="label-style" fxLayout="row" fxLayoutAlign="end center">Minimum Site Visit Length</div>
<div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
  <app-time-field
    [value]= "form.get('minimumSiteVisitLength').value"
    [readOnly]="false"
    [granularity]="15"
    [minDate]= "fifteenMinutes"
    [maxDate]= "eightHours"
  ></app-time-field>
</div>

  <!-- Default arrival window length -->
  <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Default Arrival Window Length</div>
  <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
    <app-time-field
      [value]= "form.get('defaultArrivalWindowLength').value"
      [readOnly]="false"
      [granularity]="60"
      [maxDate]= "eightHours"
    ></app-time-field>
  </div>

  <!-- SCheduling Preferences: Dense Work Days, First Available and Low Total commute Time -->
  <div *ngFor="let setting of numberSiteVisitsToSuggestFields" style="display: contents;">
    <div class="label-style">{{setting.userName}}</div>
    <div style="display: flex; align-items: center;">
      <mat-form-field appearance="outline" class="short-field">
        <input matInput formControlName="{{setting.settingName}}">
        <mat-error>Number of site visits must be between 0 and 10.</mat-error>
      </mat-form-field>
      <mat-icon matPrefix matTooltip="{{setting.description}}" class="my-icon" style="color: #797979; padding-left: 20px; margin-bottom: 2px;">info</mat-icon>
      </div>
  </div>

  <!-- Default Suggested Time for Emptye Days -->
  <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Default Suggested Time For Empty Days</div>
  <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
    <app-time-field
      [value]= "form.get('defaultStartTimeVirginSiteVisits').value"
      [readOnly]="false"
      [granularity]="30"
    ></app-time-field>
  </div>




<div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
  <div class="button-position">
    <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
  </div>
  <div class="button-position">
    <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
  </div>

  </div>
