<div class="header-label header-border">Site Visits</div>
    <div>
          <table mat-table [dataSource]="dataSource | async" class="mat-elevation-z8">


            <!-- Visit Number Col-->
            <ng-container matColumnDef="visitNumber">
              <th mat-header-cell *matHeaderCellDef style="width: 65px;"> Visit </th> <!--The title of this is only called "Visit" in order to save horizontal space-->
              <td mat-cell *matCellDef="let s"> {{s.visitNumber}} </td>
            </ng-container>

            <!-- Visit Date Col -->
            <ng-container matColumnDef="visitDate">
              <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let s">
                  <ng-container *ngIf="s.siteVisit.actualStartDate else scheduledStartDateBlock">
                    {{s.siteVisit.actualStartDate | date:'medium'}}
                  </ng-container>
                  <ng-template #scheduledStartDateBlock>
                    {{s.siteVisit.startDate | date:'medium'}}
                  </ng-template>
                </td>
            </ng-container>

            <!-- Time Window Col -->
            <ng-container matColumnDef="timeWindow">
              <th mat-header-cell *matHeaderCellDef> Time Window </th>
              <td mat-cell *matCellDef="let s"> {{formatArrivalWindowString(s.siteVisit)}}</td>
            </ng-container>

            <!-- Actual Arrival Col -->
            <ng-container matColumnDef="actualArrivalTime">
              <th mat-header-cell *matHeaderCellDef>Tech Arrived At</th>
              <td mat-cell *matCellDef="let s"> {{formatActualArrivalTime(s.siteVisit)}} </td>
            </ng-container>

            <!-- Expected Duration Col -->
            <ng-container matColumnDef="expectedDuration">
              <th mat-header-cell *matHeaderCellDef> Estimated Duration </th>
              <td mat-cell *matCellDef="let s"> {{formatExpectedDuration(s.siteVisit)}} </td>
            </ng-container>

            <!-- Actual Duration Col -->
            <ng-container matColumnDef="actualDuration">
              <th mat-header-cell *matHeaderCellDef> Actual Duration </th>
              <td mat-cell *matCellDef="let s"> {{formatActualDuration(s.siteVisit)}} </td>
            </ng-container>

            <!-- Site Visit Arrival Status Col -->
            <ng-container matColumnDef="siteVisitArrivalStatus">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let s"> {{s.siteVisit.siteVisitStatus}} </td>
            </ng-container>

            <!-- Employee Col -->
            <ng-container matColumnDef="employee">
              <th mat-header-cell *matHeaderCellDef> Employee </th>
              <td mat-cell *matCellDef="let s"> {{s.employee.name}} </td>
            </ng-container>

            <ng-container matColumnDef="paperwork">
              <th mat-header-cell *matHeaderCellDef> Completed Work </th>
              <td mat-cell *matCellDef="let element"><span style="color: blue;"><a href="https://servicepal.blob.core.windows.net/files/cb1eb280-c9d6-43dc-a7c7-3e52160620b2.pdf">
                Paperwork sent</a></span> {{element.paperwork}} </td>
            </ng-container>

            <ng-container matColumnDef="siteVisitNotes">
              <th mat-header-cell *matHeaderCellDef>Notes</th>
              <td mat-cell *matCellDef="let s"> {{s.siteVisit.notes}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
          </table>

    </div>

