import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addHours, endOfDay, startOfDay } from 'date-fns';
import { combineLatest,  of,  Subject } from 'rxjs';
import { delay, take, takeUntil, tap } from 'rxjs/operators';
import { CustomerCommunicationScheduling } from '../../../../../../common/src/data/dao/customer-communication-scheduling';
import { CustomerCommunicationSchedulingService } from '../../../../../../common/src/data/dao-services/customer-communication-scheduling.service';
import { CustomerCommunicationTemplate, CustomerCommunicationType } from '../../../../../../common/src/data/dao/customer-communication-template';
import { CustomerCommunicationTemplateService } from '../../../../../../common/src/data/dao-services/customer-communication-template.service';
import { DataLinkPopulatorComponent } from 'web-app/src/app/form-builder/data-link-populator/data-link-populator.component';
import { DataLink, DataLinkService } from 'web-app/src/app/form-builder/data-link-populator/data-link.service';
import { TimeService } from 'web-app/src/app/time-field/time.service';
import { randomElementName } from '../../../../../../common/src/util/util';

@Component({
  selector: 'app-communication-preference-modal',
  templateUrl: './communication-preference-modal.component.html',
  styleUrls: ['./communication-preference-modal.component.scss']
})
export class CommunicationPreferenceModalComponent implements OnInit, OnDestroy {

  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  @ViewChild('body') bodyRef: ElementRef;
  @ViewChild('subject') subjectRef: ElementRef;

  form: UntypedFormGroup;
  customerCommuncationTemplate: CustomerCommunicationTemplate;
  customerCommunicationScheduling: CustomerCommunicationScheduling;
  dataLinkDialogRef: MatDialogRef<DataLinkPopulatorComponent>;
  timeListForBefore: string [];

  zeroHoursAsDate = startOfDay(new Date());

  get schedulingForm() {
    return this.form.get("schedulingForm") as UntypedFormGroup;
  }

  get generalSettingsForm() {
    return this.form.get("generalSettingsForm") as UntypedFormGroup;
  }

  get timeOfDayToSendCommunication() {
    return this.schedulingForm.get("timeOfDayToSendMessage").value;
  }

  get delayHoursFromTriggeringToInitiallySending() {
    return this.schedulingForm.get("delayHoursFromTriggeringToInitiallySending").value;
  }

  get preceedHoursFromTriggeringToInitiallySending() {
      return this.schedulingForm.get("delayHoursFromTriggeringBeforeInitiallySending").value;
  }

  get CustomerCommunicationTypes() {
    return [CustomerCommunicationType.EMAIL, CustomerCommunicationType.SMS, CustomerCommunicationType.BOTH];
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public customerCommuncationTemplateService: CustomerCommunicationTemplateService,
  private customerCommunicationSchedulingService: CustomerCommunicationSchedulingService, private dialog: MatDialog,
  private fb: UntypedFormBuilder, private snackBar: MatSnackBar, private timeService: TimeService) {
      if (data) {
        this.customerCommuncationTemplate = data.customerCommuncationTemplate;
        this.customerCommunicationScheduling = data.customerCommunicationScheduling;
      }
    }

  ngOnInit(): void {
    this.form = this.initilizeForm();
    if (this.customerCommuncationTemplate) {
      this.patchCustomerCommunicationSettings(this.customerCommuncationTemplate);
      if (!this.customerCommuncationTemplate.allowFollowUpMessaging) {
        (this.generalSettingsForm as UntypedFormGroup).controls["sendFollowUpMessaging"].disable();
      }
    }
    if (this.customerCommunicationScheduling) {
      this.patchCustomerCommunicationScheduling(this.customerCommunicationScheduling);
    }
    this.timeListForBefore = this.timeService.GetTimeValueList(30).slice();
    this.timeListForBefore.splice(0,1);
    this.timeListForBefore = this.timeListForBefore.concat("24:00");
  }

  patchInHoursToPreceedSendCommunicaiton(hoursDelay: Date) {
    this.schedulingForm.patchValue({delayHoursFromTriggeringBeforeInitiallySending: hoursDelay});
  }

  patchInHoursDelayToSendCommunicaiton(hoursDelay: Date) {
    if (hoursDelay === startOfDay(hoursDelay)) {
      hoursDelay = endOfDay(hoursDelay);
    }
    this.schedulingForm.patchValue({delayHoursFromTriggeringToInitiallySending: hoursDelay});
  }

  patchInTimeOfDayToSendCommunication(timeOfDayToSendCommunication: Date) {
    this.schedulingForm.patchValue({timeOfDayToSendCommunication: timeOfDayToSendCommunication});
  }

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  initilizeCustomerCommunicationSettingForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      subject: [],
      title: [],
      description: [],
      body: [],
      liveView: [],
      subjectLiveView: [],
      customerCommuncationType: [],
      includeJobWorkflowAttachment: [],
      inlineEstimate: [],
      inlineInvoice: [],
      sendFollowUpMessaging: [false],
    });

    retVal.controls["liveView"].disable();
    retVal.controls["subjectLiveView"].disable();

    retVal.get("body").valueChanges.pipe(
      tap(x => retVal.patchValue({liveView: DataLinkService.replaceDataLinkPlaceholders(x)})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    retVal.get("subject").valueChanges.pipe(
      tap(x => retVal.patchValue({subjectLiveView: DataLinkService.replaceDataLinkPlaceholders(x)})),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    return retVal;
  }

  schedulingFieldsToPatch: string[] = ["initialCommunicationTimingType", "delayHoursFromTriggeringToInitiallySending",
   "timeOfDayToSendMessage", "delayDaysToSendInitialMessage", "delayDaysToSendFollowUpMessage",
   "numberOfAdditionalMessagesToSend", "delayHoursFromTriggeringBeforeInitiallySending"];

  initilizeCustomerCommunicationSchedulingForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      initialCommunicationTimingType: [],
      delayHoursFromTriggeringToInitiallySending: [startOfDay(new Date())],
      timeOfDayToSendMessage: [addHours(startOfDay(new Date(), ),12)],
      delayDaysToSendInitialMessage: [, [Validators.min(0)]],
      delayDaysToSendFollowUpMessage: [],
      numberOfAdditionalMessagesToSend: [, [Validators.min(0), Validators.max(this.customerCommuncationTemplate.maximumAllowedFollowUpMessages)]],
      delayHoursFromTriggeringBeforeInitiallySending: [],
    });

    return retVal;
  }

  initilizeForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      schedulingForm: this.initilizeCustomerCommunicationSchedulingForm(),
      generalSettingsForm: this.initilizeCustomerCommunicationSettingForm(),
    });

    return retVal;
  }

  patchCustomerCommunicationScheduling(p: CustomerCommunicationScheduling) {
    this.schedulingFieldsToPatch.forEach(x => {
      this.schedulingForm.patchValue({[x]: p[x]});
    });
  }

  patchCustomerCommunicationSettings(p: CustomerCommunicationTemplate) {
    this.generalSettingsForm.patchValue({includeJobWorkflowAttachment: p.includeJobWorkflowAttachment, inlineEstimate: p.inlineEstimate,
      inlineInvoice: p.inlineInvoice, subject: p.subject, body: p.body, customerCommuncationType: p.customerCommuncationType,
      title: p.title, description: p.description, sendFollowUpMessaging: p.sendFollowUpMessaging});
  }

  patchCustomerCommunicationSettingsFromForm() {
    this.customerCommuncationTemplate.subject = this.generalSettingsForm.get("subject").value;
    this.customerCommuncationTemplate.body = this.generalSettingsForm.get("body").value;
    this.customerCommuncationTemplate.customerCommuncationType = this.generalSettingsForm.get("customerCommuncationType").value;
    this.customerCommuncationTemplate.includeJobWorkflowAttachment = this.generalSettingsForm.get("includeJobWorkflowAttachment").value;
    this.customerCommuncationTemplate.inlineEstimate = this.generalSettingsForm.get("inlineEstimate").value;
    this.customerCommuncationTemplate.inlineInvoice = this.generalSettingsForm.get("inlineInvoice").value;
    if (!(this.generalSettingsForm as UntypedFormGroup).controls["sendFollowUpMessaging"].disabled) {
      this.customerCommuncationTemplate.sendFollowUpMessaging = this.generalSettingsForm.get("sendFollowUpMessaging").value;
    }
  }

  patchCustomerCommunicationSchedulingFromForm() {
    this.schedulingFieldsToPatch.forEach(x => {
      this.customerCommunicationScheduling[x] = this.schedulingForm.get(x).value;
    });
  }

  Cancel() {
    this.dialog.closeAll();
  }

  Save() {
    if (this.form.valid) {
      this.snackBar.open("Saving Updates",undefined);
      this.patchCustomerCommunicationSettingsFromForm();
      this.patchCustomerCommunicationSchedulingFromForm();
      combineLatest([of(null).pipe(delay(300)),this.customerCommuncationTemplateService.update$(this.customerCommuncationTemplate),this.customerCommunicationSchedulingService.update$(this.customerCommunicationScheduling)]).pipe(
        take(1)).subscribe(x => {
          this.snackBar.dismiss();
          this.dialog.closeAll();
        });
    }
  }

  loadDataLinkModal(field: string) {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      formCatagory: "all"
    });

    this.dataLinkDialogRef = this.dialog.open(DataLinkPopulatorComponent, editorConfig);

    // Event dialog closure:
    this.dataLinkDialogRef.afterClosed().pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.eventDialogClosure({dataLinks: x, field: field}));

  }

  eventDialogClosure(result: {dataLinks: DataLink[], field: string}) {
    if (result.dataLinks !== undefined) {
      const insertString = DataLinkService.mapDataLinksToInsertString(result.dataLinks);
      if (result.field === "body") {
        const loc = this.bodyRef.nativeElement.selectionStart;
        const prev = this.generalSettingsForm.get("body").value as string;
        this.generalSettingsForm.patchValue({body: prev.substr(0,loc) + insertString + prev.substr(loc) });
      } else if (result.field === "subject") {
        const loc = this.subjectRef.nativeElement.selectionStart;
        const prev = this.generalSettingsForm.get("subject").value as string;
        this.generalSettingsForm.patchValue({subject: prev.substr(0,loc) + insertString + prev.substr(loc) });
      }
    }
  }

}
