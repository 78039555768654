<form class="container1" [formGroup]="roleForm">
  <div class="left-side"></div>
  <div class="middle">
    <div class="employee-grid" style="margin-bottom: 30px;">

  <!-- Start of Roles Section -->
      <div class="header-label" style="height: 80px; border-top: 2px solid gainsboro;">Roles</div>
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Employee Role</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <mat-select formControlName="employeeRole" placeholder="Choose Role" [compareWith]="compareWithDocIds">
            <mat-option *ngFor="let role of rolesWhichCanBeUpdated | async" [value]="role">{{role.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Role Name</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input matInput formControlName="name" placeholder="Role Name"
          [name]="ElementNameForId('054457')" autofill='off' autocomplete='new-password' data-lpignore='true'>
        </mat-form-field>
      </div>
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Role Options</div>
      <div style="display: flex;">
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-checkbox formControlName="scheduleFieldCallsFor">Schedule for field calls</mat-checkbox>
        </div>
        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <mat-checkbox formControlName="active">Active</mat-checkbox>
        </div>
      </div>

      <div class="label-style" style="margin-top: 4px;" fxLayout="row" fxLayoutAlign="end start">Privileges</div>
      <app-mulliselect-nested-tree
          (itemsAddedToSelection$)="permissionAdded$.next($event)"
          (itemsRemovedFromSelection$)="permissionRemoved$.next($event)"
          [checkListDatabase]="CheckListDatabase()"
        ></app-mulliselect-nested-tree>
        <!-- End of Roles Section -->

      <!-- Start of Skills Section -->
      <app-settings-table style="display: contents;"
        [detailTemplate]="skills"
        [settingTableTitle]="'Skills'"
        [dataSource]="skillSet$"
        [columns]="skillColumns"
        (rowSelected$)="selectSkill($event)"
        (saveRow$)="saveSkill($event)"
        (addRow$)="addSkill()"
      ></app-settings-table>


      <ng-template #skills>
        <form [formGroup]="employeeSkillsForm" style="display: contents;">
          <!-- name -->
          <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Name</div>
          <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline" class="medium-field">
              <input matInput placeholder="" formControlName="name">
              <mat-error>Name is required for Skill</mat-error>
            </mat-form-field>
          </div>
          <!-- description -->
          <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Details</div>
          <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline" class="medium-field">
              <textarea matInput placeholder="" formControlName="description">
              </textarea>
            </mat-form-field>
          </div>
          <div class="label-style" style="margin-top: 4px;" fxLayout="row" fxLayoutAlign="end start">Assign Skills to Roles</div>
          <app-mulliselect-nested-tree
          [checkListDatabase]="RoleCheckListDatabase()"
          (itemsAddedToSelection$)="skillAddedToRole($event)"
          (itemsRemovedFromSelection$)="skillRemovedFromRole($event)"
          ></app-mulliselect-nested-tree>
        </form>
      </ng-template>

    <!-- End of Skills Section -->

 <!-- Cancel / Submit buttons. -->
 <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%;">
  <div class="button-position">
    <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
  </div>
    <div class="button-position">
      <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
    </div>
  </div>
</div>
</div>
<div class="right-side"></div>
</form>
