import { Injectable } from '@angular/core';
;
import { AuthenticationService } from '../../util/authentication.service';
import { FormFirestoreSummary } from '../dao/form-firestore-summary';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FormCatagoryService } from './form-catagory.service';

@Injectable({
  providedIn: 'root'
})
export class FormFirestoreSummaryService extends DatabaseStoreService<FormFirestoreSummary> {

  constructor( fs: FormFirestoreSummaryFirestoreService, authenticationService: AuthenticationService, store: FormFirestoreSummaryStoreService,
    private formCatagoryService: FormCatagoryService) {
    super(fs, store, authenticationService,
      new Map([["formCatagory", {associatedDocumentId: "formCatagoryDocId",compositionStoreService: formCatagoryService}]]));
    }
  }

@Injectable({
  providedIn: 'root'
})
export class FormFirestoreSummaryStoreService extends StateChangeStoreService<FormFirestoreSummary> {
  protected store = "form-firestore_summary";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, FormFirestoreSummary>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class FormFirestoreSummaryFirestoreService extends FirestoreBackend<FormFirestoreSummary> {

  protected basePath = "form_firestore_summary";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): FormFirestoreSummary {
    return new FormFirestoreSummary(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new FormFirestoreSummary(), authService);
  }
}
