import { ChangeDetectionStrategy, Component, ComponentFactoryResolver,  Input,  OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainerComponent } from '../component-models/control-container.component';
import { FormBuilderDirective } from '../form-builder.directive';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormElementComponent implements OnInit {

  static emitComponentClicked: boolean = true;

  @Input() componentToInstantiate: ControlContainerComponent;
  instantiatedComponent: ControlContainerComponent;

  // This event brings the component into focus in the sidebar.
  @Output() componentClicked$: Subject<ControlContainerComponent> = new Subject<ControlContainerComponent>();

  @ViewChild(FormBuilderDirective, {static: true}) formBuilderControlHost: FormBuilderDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  OnClick() {
    this.componentToInstantiate.unfilteredComponentClicked$.next(this.componentToInstantiate);
  }

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {

    const viewContainerRef = this.formBuilderControlHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentToInstantiate.component);
    const componentRef = viewContainerRef.createComponent<ControlContainerComponent>(componentFactory);
    Object.assign(componentRef.instance,this.componentToInstantiate);
    this.instantiatedComponent = componentRef.instance;
      this.componentToInstantiate.filteredComponentClicked$.subscribe(this.componentClicked$);
      if (FormElementComponent.emitComponentClicked) {
        this.componentToInstantiate.unfilteredComponentClicked$.next(this.componentToInstantiate);
      }
    }
  }
