<div style="display:none">{{fields[0].props.changeDetect|async}}</div>
<div #rootContainer [formGroup]="componentForm" style="padding: 4px;" class="control-attributes active-control">
<!-- Icon as placeholder when no file picked -->
<mat-icon>cloud_upload</mat-icon>
<!-- file input, accepts images only. Detect when file has been picked/changed with Angular's native (change) event listener -->
<input type="file" accept="image/*" (change)="updateSource($event)">
  <div style = "height:auto;">
    <div #canvasContainer ngResizable class="resizable-widget"
    [rzMaxWidth] = "componentForm.get('imageMaxWidthPixels').value"
    [rzAspectRatio]="lockAspectRatio"
    [rzHandles]="rzHandles"
    (rzStart)="startResize($event)"
    (rzStop)="stopResize($event)"
    [hidden]="hideResize"
    [style.height.px]="componentForm.get('imageHeightPixels').value"
    [style.width.px]="componentForm.get('imageWidthPixels').value"
    >
      <canvas #imageCanvas [hidden]="resizing"></canvas>
      <div class="rectangle" [hidden]="!resizing"></div>
    </div>
  </div>
</div>
