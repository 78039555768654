import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RatingControlDefaultService {

  constructor(protected fb: UntypedFormBuilder) {}

  Defaults() : {label: string, value: UntypedFormArray}[] {
    return [
      {label: "One Five Scale", value: this.createOneFiveScaleFormArray() },
      {label: "Satisfied / Unsatisfied", value: this.createSatistiedUnsatisfiedFormarray()}
    ];
  }

  createOneFiveScaleFormArray() : UntypedFormArray {
    return this.fb.array([
      this.fb.group({
        label: this.fb.control('1'),
        value: "1"
      }),
      this.fb.group({
        label: this.fb.control('2'),
        value: "2"
      }),
      this.fb.group({
        label: this.fb.control('3'),
        value: "3"
      }),
      this.fb.group({
        label: this.fb.control('4'),
        value: "4"
      }),
      this.fb.group({
        label: this.fb.control('5'),
        value: "5"
      }),
    ]);
    }

  createSatistiedUnsatisfiedFormarray() : UntypedFormArray {
    return this.fb.array([
      this.fb.group({
        label: this.fb.control('Very Unsatisfied'),
        value: "1"
      }),
      this.fb.group({
        label: this.fb.control('Unsatisfied'),
        value: "2"
      }),
      this.fb.group({
        label: this.fb.control('Neutral'),
        value: "3"
      }),
      this.fb.group({
        label: this.fb.control('Satisfied'),
        value: "4"
      }),
      this.fb.group({
        label: this.fb.control('Very Satisfied'),
        value: "5"
      }),
    ]);
  }
}
