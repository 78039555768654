import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Address } from "./address";

export const addressRoutingSource = {
    GOOGLE: 'google',
    SERVICEPROVIDER: 'service_provider'
  };

export class AddressRouting extends RetrieveFirestorePropertiesBase<AddressRouting>  {

    originAddressDocId: string;
    destinationAddressDocId: string;
    distanceMeters: number;
    timeEstimateSeconds: number;
    addressRoutingSource = addressRoutingSource.GOOGLE;
    reversedFromRequestedRoute: boolean;
    dateRetrieved: Date = new Date();
  
    orginAddress: Address;
    destinationAddress: Address;
  
    get timeEstimateMinutes(): string { return `${Math.round(this.timeEstimateSeconds / 60)} min`; }
    get distanceEstimateMiles(): string { return `${Math.round(this.distanceMeters / 1609.34 * 10) / 10} miles`; }
  
    static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames
      .concat("orginAddress", "destinationAddress");
  
      retrieveFirestoreIgnoredMemberNames() : string[] { return AddressRouting._firestoreIgnoredMemberNames};
  
    public constructor(init?: Partial<AddressRouting>) {
      super();
      Object.assign(this, init);
    }
  }