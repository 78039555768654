import { Injectable } from '@angular/core';
import { FirestoreDiffService } from './firestore-diff.service';
import { EmployeeService } from './employee.service';
import { EmployeeGeofenceService } from './employee-geofence.service';
import { EmployeeGeofenceLog } from '../dao/employee-geofence-log';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { AuthenticationService } from '../../util/authentication.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { where } from 'firebase/firestore';
import { take, tap } from 'rxjs';
import { endOfDay } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGeofenceLogService extends DatabaseStoreService<EmployeeGeofenceLog> {

  constructor( fs: EmployeeGeofenceLogFirestoreService, store: EmployeeGeofenceLogStoreService, authenticationService: AuthenticationService, private employeeService: EmployeeService,
      private employeeGeofenceService: EmployeeGeofenceService) {
      super(fs, store, authenticationService, new Map([
        ["employee", {associatedDocumentId: "employeeDocId", compositionStoreService: employeeService}],
        ["employeeGeofence", {associatedDocumentId: "geofenceDocId", compositionStoreService: employeeGeofenceService}]
        ]));
      }

      outputToConsole(employeeId: string, dateInQuestion: Date) {
        this.queryFirestoreDeep$([where("employeeDocId", "==", employeeId),where("enteredAt", ">=", dateInQuestion),where("enteredAt", "<=", endOfDay(dateInQuestion))]).pipe(
          tap(x => {
            const output = [];
            x.forEach(y => {
              output.push({enteredAt: y.enteredAt, exitedAt: y.exitedAt, geofenceName: y.employeeGeofence.address.formattedAddress(), geoFenceId: y.employeeGeofence.DocId()});
            });
            console.table(output);
          }),
          tap(x => console.log(x,` string`)),
          take(1)
        ).subscribe();
      }

  }

@Injectable({
  providedIn: 'root'
})
export class EmployeeGeofenceLogStoreService extends StateChangeStoreService<EmployeeGeofenceLog> {
  protected store = "EmployeeGeofenceLog-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, EmployeeGeofenceLog>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class EmployeeGeofenceLogFirestoreService extends FirestoreBackend<EmployeeGeofenceLog> {

 protected basePath = "EmployeeGeofenceLog";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): EmployeeGeofenceLog {
return new EmployeeGeofenceLog(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new EmployeeGeofenceLog(), authService);
 }
}
