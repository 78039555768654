import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-schedule-modification-req-modal',
  templateUrl: './schedule-modification-req-modal.component.html',
  styleUrls: ['./schedule-modification-req-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ScheduleModificationReqModalComponent implements OnInit {

  latestAllowedStartTime: Date;
  earliestAllowedEndTime: Date;
  warningRationale: string;

  get warningReason() : string {
    if (this.warningRationale === "Outside Employee Schedule") {
      return "Employee schedule must be modified to add site visit.";
    }
    if (this.warningRationale === "Red Zone") {
      return "Positioning site visit here will result in impossible schedule.";
    }
    if (this.warningRationale === "Past Date") {
      return "You are about to position this site visit in the past.";
    }
  }

  get warningExplanation() : string {
    if (this.warningRationale === "Outside Employee Schedule") {
      return this.scheduleBoundsMessage;
    }
    if (this.warningRationale === "Red Zone") {
      return "If site visit is added here, other site visits will require arrival window modifications, so they can be rescheduled to prevent service technician from needing to be in two places at the same time.";
    }
  }

  get scheduleBoundsMessage() : string {
    return `In order to add site visit to schedule during this time you must ${this.earlyModificationMessage} ${this.lateModificationMessage}.`;
  }

  get earlyModificationMessage() : string {
    return this.latestAllowedStartTime === undefined ? "" : `modify the start time to be no later then ${this.latestAllowedStartTime.toLocaleTimeString()}`;
  }

  get lateModificationMessage() : string {
    if (this.earlyModificationMessage === "") {
      return this.earliestAllowedEndTime === undefined ? "" : `modify the end time to be no earlier then ${this.earliestAllowedEndTime.toLocaleTimeString()}`;
    } else {
      return this.earliestAllowedEndTime === undefined ? "" : `and modify the end time to be no earlier then ${this.earliestAllowedEndTime.toLocaleTimeString()}`;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ScheduleModificationReqModalComponent>) {
    this.latestAllowedStartTime = data.latestAllowedStartTime;
    this.earliestAllowedEndTime = data.earliestAllowedEndTime;
    this.warningRationale = data.warningRationale;
  }

  ngOnInit(): void {
  }

  skipWarningClose() {
    this.dialogRef.close("DISABLE_WARNING");
  }

  cancelClose() {
    this.dialogRef.close("CANCEL");
  }

  continueClose() {
    this.dialogRef.close("PROCEED");
  }

}
