import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ChecklistDatabase } from 'web-app/src/app/multiselect-nested-tree/multiselect-nested-tree.component';
import { WorkflowSelectionNodeType, WorkflowSelectionTreeService, WorkflowSelectionTreeStructure } from '../../storage/workflow-selection-tree.service';

@Component({
  selector: 'app-workflow-selection-modal',
  templateUrl: './workflow-selection-modal.component.html',
  styleUrls: ['./workflow-selection-modal.component.scss']
})
export class WorkflowSelectionModalComponent implements OnInit {

  workflowSelectionAdded$ = new Subject<WorkflowSelectionTreeStructure[]>();
  workflowSelectionRemoved$ = new Subject<WorkflowSelectionTreeStructure[]>();
  checkListDatabase : ChecklistDatabase<WorkflowSelectionTreeStructure> = undefined;
  workflowSelections: WorkflowSelectionTreeStructure[] = [];

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private workflowSelectionTreeService: WorkflowSelectionTreeService, private dialogRef: MatDialogRef<WorkflowSelectionModalComponent>) {
    if (data) {
      this.workflowSelectionTreeService.setSelectedNodes(data.activeDocIds);
      this.workflowSelections = this.workflowSelectionTreeService.selectedNodes.value;
    }
  }

  CheckListDatabase() : ChecklistDatabase<WorkflowSelectionTreeStructure> {
    if (this.checkListDatabase === undefined) {
      this.checkListDatabase = new ChecklistDatabase<WorkflowSelectionTreeStructure>(this.workflowSelectionTreeService.rootNode.pipe(filter(node => node!== undefined)),
      this.workflowSelectionTreeService.allNodes,this.workflowSelectionTreeService.selectedNodes, (x=>x.parentCatagoryKey === null || x.parentCatagoryKey === "" ? undefined : x.parentCatagoryKey),
      (x => x.name),(x=>x.key), (x=> x=== undefined ? "" : x.description), true);
    }
    return this.checkListDatabase;
  }

  save() {
    this.dialogRef.close(this.workflowSelections.filter(x => x.type === WorkflowSelectionNodeType.WORKFLOW).map(z => z.formCatagory));
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    this.workflowSelectionAdded$.pipe(
      tap(selections => selections.forEach(sel => this.workflowSelections.push(sel))),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.workflowSelectionRemoved$.pipe(
      tap(selections => selections.forEach(sel => this.workflowSelections.splice(this.workflowSelections.indexOf(sel),1))),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

}
