import { Address } from "./address";
import { CommuteCharacterization } from "./commute";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { EmployeeAvailability } from "./employee-availability";
import { EmployeePermission } from "./employee-permission";
import { EmployeeRole } from "./employee-role";
import { CompanyLocation } from "./company-location";
import { SkillSet } from "./skill-set";



export class Employee extends RetrieveFirestorePropertiesBase<Employee>  {
  name: string;

  phoneNumber: string = "";
  employeeRoleDocId: string;
  employeeRole: EmployeeRole = null;
  employeeSkillDocIds: string[] = [];
  employeeSkills: SkillSet[] = [];
  employeeAvailability: EmployeeAvailability[] = [];
  employeeAvailabilityDocIds: string[] = [];
  employeePermissions: EmployeePermission[] = [];
  employeePermissionDocIds: string[] = [];

  email: string = "";
  guid: string = "";
  dispatchOrginAddressDocId: string | null = null;
  dispatchOrginAddress: Address;
  dispatchOrginAddressCommuteDispensation: CommuteCharacterization = CommuteCharacterization.WORKDAY;
  dispatchDestinationAddressDocId: string | null = null;
  dispatchDestinationAddress: Address;
  dispatchDestinationAddressCommuteDispensation: CommuteCharacterization = CommuteCharacterization.WORKDAY;
  active = true;
  scheduleFieldCallsFor: boolean = true;
  employeeLocation: CompanyLocation;
  employeeLocationDocId: string;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["dispatchOrginAddress","dispatchDestinationAddress","employeeAvailability",
"employeePermissions","employeeRole", "employeeLocation", "employeeSkills"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["dispatchOrginAddressDocId",
"dispatchDestinationAddressDocId","employeeAvailabilityDocIds","employeePermissionDocIds","employeeRoleDocIds", "employeeLocationDocId", "employeeSkillDocIds"]);
static _firestoreCompositionMemberNames = ["employeeAvailability","dispatchOrginAddress","dispatchDestinationAddress","employeePermissions","employeeRole", "employeeLocation", "employeeSkills" ];
static _firestoreCompositionalDiffMemberNames: string[] = ["dispatchOrginAddress","dispatchDestinationAddress","employeeAvailability","employeePermissions","employeeRole", "employeeLocation", "employeeSkills"];

retrieveFirestoreIgnoredMemberNames() : string[] { return Employee._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Employee._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Employee._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Employee._firestoreCompositionMemberNames;}

  public constructor(init?: Partial<Employee>) {
    super();
    Object.assign(this, init);
  }
}
