<div [ngClass]="lineItemControlTypeClass" class="break-avoid-line-item-tables">
  <div class="header-label header-border">{{to.label}}</div>
      <app-line-item-display [ngClass]="lineItemControlTypeClass" *ngIf="(lineItems$ | async)"
      [expandAllLineItems]="formlyUtilityService.expandLineItems"
      [intendedAudience] = "2"
      [disabled]="formControl.disabled"
      [lineItems]="lineItems$"
      [discounts]="discounts$"
      [invoicePayments]="invoicePayments$"
      [explicitRerenderRows$]="explicitRenderRows$"
      [lineItemControlType]="to.lineItemControlType"
      [lineItemControlContext]="1"
      [siteVisitViewingContextDocId] = "activeSiteVisitViewingContextDocId"
      [completedLineItemOperation$]= "formlyUtilityService.completedLineItemOperation$"
      (addLineItem$)="addLineItem$.next($event)"
      (editLineItem$)="editLineItem$.next($event)"
      (removeLineItem$)="removeLineItem$.next($event)"
      (addDiscount$)="addDiscount$.next($event)"
      (removeDiscount$)="removeDiscount$.next($event)"
      (voidInvoicePayment$)="voidInvoicePayment$.next($event)"
      (jobAttentionRequiredToAdd$)="jobAttentionRequiredToAdd$.next($event)"
      ></app-line-item-display>
</div>
