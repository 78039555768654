import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Invoice } from '../../../../../common/src/data/dao/invoice';

@Component({
  selector: 'app-invoice-display-modal',
  templateUrl: './invoice-display-modal.component.html',
  styleUrls: ['./invoice-display-modal.component.scss']
})
export class InvoiceDisplayModalComponent implements OnInit {

  invoices$: BehaviorSubject<Invoice[]> = new BehaviorSubject<Invoice[]>([]);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.invoices$ = data.invoices$;
    }
  }

  ngOnInit(): void {
  }

}
