

<div class="expand" style="border-bottom: 1px solid rgb(219, 221, 224);">


  <div fxFlex="1 1 100%">

<!--Start of Navigation-->
    <button mat-button style="font-size: 26px;" [matMenuTriggerFor]="menu"><span>Service </span><span style="color: #5BC0DE;">Vanguard</span></button>
    <mat-menu #menu="matMenu" class="nav-width-dd" style="width: 600px;">

      <!--Dashboard Page Link-->

      <!-- <button mat-menu-item class="menu-button-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="card-size" style="background-color: #6C757D;">
            <mat-icon class="icon-nav">dashboard</mat-icon>
          </div>
          <span style="text-align: center;">Dashboard</span>
        </div>
      </button> -->

<div style="display: flex;">
      <!--Reports Page Link-->
      <a routerLink="/reporting" routerLinkActive="active">
       <button mat-menu-item class="menu-button-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="card-size" style="background-color: #337AB7;">
            <mat-icon class="icon-nav">table_chart</mat-icon>
          </div>
          <span class="page-names" style="text-align: center;">Reports</span>
        </div>
      </button>
      </a>


      <!--Price Book Page Link-->

      <!-- <a routerLink="/price-book" routerLinkActive="active">
        <button mat-menu-item class="menu-button-container">
          <div fxLayout="column" fxLayoutAlign="center center">
            <div class="card-size" style="background-color: #28A745;">
              <mat-icon class="icon-nav">menu_book</mat-icon>
            </div>
            <span style="text-align: center;">Price Book</span>
          </div>
        </button>
        </a> -->

      <!--Schedule Page Link-->

      <a routerLink="/app-scheduler-view" routerLinkActive="active">
      <button mat-menu-item class="menu-button-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="card-size" style="background-color: #28A745;">
            <mat-icon class="icon-nav">schedule</mat-icon>
          </div>
          <span class="page-names" style="text-align: center;">Schedule</span>
        </div>
      </button>
      </a>


      <!--Settings Page Link-->

      <a routerLink="/app-settings" routerLinkActive="active">
      <button mat-menu-item class="menu-button-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="card-size" style="background-color: #17A2B8;">
            <mat-icon class="icon-nav">settings</mat-icon>
          </div>
          <span class="page-names" style="text-align: center;">Settings</span>
        </div>
      </button>
      </a>


      <!--Add Job Page Link -->

      <a routerLink="/add-job-board" routerLinkActive="active">
      <button mat-menu-item class="menu-button-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="card-size" style="background-color: #FFC107;">
            <mat-icon class="icon-nav">exposure_plus_1</mat-icon>
          </div>
          <span class="page-names" style="text-align: center;">Add Job</span>
        </div>
      </button>
      </a>


      <!--Form Builder-->

      <a routerLink="/app-form-builder" routerLinkActive="active">
      <button mat-menu-item class="menu-button-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="card-size" style="background-color: #00FFF2;">
            <mat-icon class="icon-nav">file_copy</mat-icon>
          </div>
          <span class="page-names" style="text-align: center;">Form Creator</span>
        </div>
      </button>
      </a>

      <!--Employee Page-->

      <a routerLink="/app-employee" routerLinkActive="active">
      <button mat-menu-item class="menu-button-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="card-size" style="background-color: #FF7900;">
            <mat-icon class="icon-nav">construction</mat-icon>
          </div>
          <span class="page-names" style="text-align: center;">Employees</span>
        </div>
      </button>
      </a>
      <!-- <h4>Service Vanguard Home Page (tm)</h4> -->
</div>
<router-outlet></router-outlet>

    </mat-menu>

  </div>
  <!--End of Navigation-->

<!--Start of Global Search-->
  <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center">
    <app-sitewide-search-bar></app-sitewide-search-bar>
    <a routerLink="/add-job-board" routerLinkActive="active">
    <button mat-button class="button-green">
      Add Job
    </button>
    </a>
  </div>
  <!-- <div style="margin-left: 10px;">
    <button mat-button class="button-green">
      Add Job
    </button>
  </div> -->
<!--End of Global Search-->

<!--Start of section 3-->

<div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="end">

<div class="login-name-style">{{currentEmployeeName}}</div>

  <button mat-icon-button [matMenuTriggerFor]="menu2" aria-label="Example icon-button with a menu" class="kkjjj">
    <mat-icon style="width: 30px;" class="settings-widget">person</mat-icon>
  </button>
  <mat-menu #menu2="matMenu">
    <button type="button" (click)=authService.SignOut() mat-menu-item>
      <mat-icon class="right-icons">power_off</mat-icon>
      <span>Sign Out</span>
    </button>
  </mat-menu>


</div>
<!--End of section 3-->

</div>


