<div [formGroup]="customerDetailsForm" style="display:inline block;">
        <div class="section-content">
          <mat-radio-button [value]="customerDetailsForm.get('customer').value.DocId()" class="primary-radio-positioning label-style-cust">Primary Customer</mat-radio-button>
          <div class="test-layout">
  <!--CUSTOMER-->
        <div fxLayout="row" style="justify-content: space-between;">
          <button  mat-icon-button (click)="RemoveCustomerClick()">
          <mat-icon class="delete-customer-button">cancel</mat-icon>
        </button>
          <label class="label-style-cust">
        Customer
      </label>
      </div>
        <mat-form-field appearance="outline" class="long-field">
          <input [name]="customerDetailsForm.get('noAutoFill').value" autofill="off" autocomplete="new-password" type="text" placeholder="Customer Details" formControlName="customerName" matInput>
          <mat-error>Customer must be selected for Job to schedule</mat-error>
        </mat-form-field>
      <!-- END CUSTOMER -->
      <!-- PHONE -->
        <label class="label-style-cust">Phone</label>
        <div fxLayout="row">
          <mat-form-field appearance="outline" style="width: 180px;">
        <input [name]="customerDetailsForm.get('noAutoFill').value" autofill="off" autocomplete="new-password" matInput placeholder="Phone Number" formControlName="customerPrimaryPhoneNumber" mask="(000) 000-0000">
      <mat-error>Phone number invalid</mat-error>
        </mat-form-field>

        </div>
      <!-- END PHONE -->
      <!--EMAIL-->
        <label class="label-style-cust">E-mail</label>
        <mat-form-field appearance="outline" style="width: 330px;">
          <input type="email" [name]="customerDetailsForm.get('noAutoFill').value" autofill="off" autocomplete="new-password" matInput placeholder="Contact E-mail" formControlName="customerEmail"
          autocomplete="new-password" autofill="off">
            <mat-error>Email address invalid</mat-error>
        </mat-form-field>
      <!--START OF CUSTOMER TAGS-->
      <label class="label-style-cust">Customer Balance</label>
        <app-customer-balance [customer] = "customerDetailsForm.get('customer').value" ></app-customer-balance>
        <label class="label-style-cust">Customer Tags</label>
        <app-custom-tags  class="form-align field-stretch"
        [assignedTags] = "customTagComponentInputs.assignedTags"
        [existingTags] = "customTagComponentInputs.existingTags | async"
        [existingTagsUpdated] = "customTagComponentInputs.existingTagsUpdated"
        [disableInput] = "customTagComponentInputs.disableInput"
        (tagSelectedForRemoval) = "customTagComponentOutputs.tagSelectedForRemoval.next($event)"
        (tagSelectedForAddition) = "customTagComponentOutputs.tagSelectedForAddition.next($event)"
        (newlyCreatedTagForAdditon) = "customTagComponentOutputs.newlyCreatedTagForAdditon.next($event)">
      </app-custom-tags>
  <!--END OF CUSTOMER TAGS-->

  <label class="label-style-cust">Customer Role</label>
  <div class="medium-field">
  <mat-form-field appearance="outline" class="form-align">
    <mat-select formControlName="customerRole" placeholder="Customer Role" >
    <mat-option *ngFor="let role of customerRoles" [value]="role">
      {{role}}
    </mat-option>
  </mat-select>
</mat-form-field>
</div>
</div>
 </div>
</div>
