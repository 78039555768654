
<form class="container1" [formGroup]="form">
  <div class="left-side"></div>
  <div class="middle">
    <div class="employee-grid" style="margin-bottom: 30px;" >
      <div class="header-label">Pricebook Settings</div>

    <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Tax Rates</div>
    <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field appearance="outline" class="medium-field">
          <mat-select formControlName="selectedTaxRate" placeholder="Tax Rate">
        <mat-option *ngFor="let t of taxRateSettingsService.loadAll$() | async" [value]="t">{{t.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-button style="margin-left: 30px !important;" class="button-green" (click)="addNewTaxRate()">Add New Tax Rate</button>
      </div>
      <!-- <div>
        <button type="button" mat-button style="margin-left: 30px !important;" class="button-green" (click)="addNewTaxRate()">Add New Tax Rate</button>
      </div> -->
      <div [formGroup]="taxRateForm" style="display: contents;">
      <!-- Tax Name -->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Tax Name</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="medium-field">
          <input [name]="ElementNameForId('1')" autofill='off' autocomplete='new-password' data-lpignore='true' matInput placeholder="Tax Name" formControlName="name">
        </mat-form-field>
      </div>
      <!-- Tax Rate-->
      <div class="label-style" fxLayout="row" fxLayoutAlign="end center">Tax Rate %</div>
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="short-field">
          <input [name]="ElementNameForId('578959')" autofill='off' autocomplete='new-password' data-lpignore='true' formControlName="percent"
          matInput placeholder="Tax Rate Percentage">
        </mat-form-field>
      </div>
      <!-- Tax rate application checkboxes -->
      <mat-checkbox style="margin-left: 30px;" class="label-style" formControlName="default" >Default Rate</mat-checkbox>
      <div style="display: flex;">
      <mat-checkbox style="margin-left: 30px;" class="label-style" formControlName="applyToMaterials" >Apply to Materials and Parts</mat-checkbox>
      <mat-checkbox style="margin-left: 30px;" class="label-style" formControlName="applyToLabor" >Apply to labor</mat-checkbox>
      </div>
    </div>

<div class="header-label" style="height: 80px; border-top: gainsboro 2px solid; margin-top: 40px;">Default Pricing Methodology</div>    
    <app-pricing-methodology-settings
    [pricingMethodologySettingsForm]= "pricingMethodologyForm"
    ></app-pricing-methodology-settings>
 <!-- Cancel / Submit buttons. -->
    <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
      <div class="button-position">
        <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
      </div>
      
      
      <div class="button-position">
        <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
      </div>
      
      </div>

    </div>


  <!-- <div>
    <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
  </div>
  <div style="display: flex; justify-content: flex-end;">
    <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
  </div> -->
 

</div>
<div class="right-side"></div>
</form>
