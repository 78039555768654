import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { FormCatagoryTreeService } from 'web-app/src/app/form-builder/storage/form-catagory-tree.service';
import { ChecklistDatabase, ItemCatagoryFlatNode, ItemCatagoryNode } from 'web-app/src/app/multiselect-nested-tree/multiselect-nested-tree.component';
import { randomElementName } from '../../../../../../common/src/util/util';

@Component({
  selector: 'app-workflow-settings-tree',
  templateUrl: './workflow-settings-tree.component.html',
  styleUrls: ['./workflow-settings-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowSettingsTreeComponent<T> implements OnInit {

  @Output() nodeClicked$ = new EventEmitter<T[] | ItemCatagoryFlatNode<T>>();
  @Input() checkListDatabase: ChecklistDatabase<T>;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemCatagoryFlatNode<T>, ItemCatagoryNode<T>>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemCatagoryNode<T>, ItemCatagoryFlatNode<T>>();

  treeControl: FlatTreeControl<ItemCatagoryFlatNode<T>>;

  treeFlattener: MatTreeFlattener<ItemCatagoryNode<T>, ItemCatagoryFlatNode<T>>;

  dataSource: MatTreeFlatDataSource<ItemCatagoryNode<T>, ItemCatagoryFlatNode<T>>;

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
    }

    randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  ngOnInit(): void {

    this.checkListDatabase.dataChange.pipe(
      takeUntil(this.destroyingComponent$)
    ).subscribe(data => {
      this.dataSource.data = data;
    });
  }

  constructor(private formCatagoryTreeService: FormCatagoryTreeService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ItemCatagoryFlatNode<T>>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  getLevel = (node: ItemCatagoryFlatNode<T>) => {
    return node.level;
  }

  isExpandable = (node: ItemCatagoryFlatNode<T>) => node.expandable;

  getChildren = (node: ItemCatagoryNode<T>): ItemCatagoryNode<T>[] => node.children;

  hasChild = (_: number, _nodeData: ItemCatagoryFlatNode<T>) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemCatagoryNode<T>, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
        ? existingNode
        : new ItemCatagoryFlatNode<T>();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.key = node.key;
    flatNode.name = node.name;
    flatNode.description = node.description;
    flatNode.expandable = !!(node.children.length > 0);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  getStyleClasses(node: any) {
      if (this.checkListDatabase.selectedNodes.value.findIndex(x => x.key === node.key) !== -1) {
      return { 'selected': true };
    } else {
      return { 'selected': false };
    }
  }

  toggleNode(node: any) {
    this.nodeClicked$.next(node);
    if (this.checkListDatabase.selectedNodes.value.map(x => x.item).indexOf(node.item) !== -1) {
      this.formCatagoryTreeService.updateSelectedNodes([]);
    } else {
      this.formCatagoryTreeService.updateSelectedNodes([node.item?.formCatagory]);
    }
  }


}
