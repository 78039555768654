import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {  differenceInMinutes, format } from 'date-fns';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap,  tap } from 'rxjs/operators';
import { AssignmentService } from '../../../../../common/src/data/dao-services/assignment.service';
import { Employee } from '../../../../../common/src/data/dao/employee';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import { SiteVisit } from '../../../../../common/src/data/dao/site-visit';
import { SiteVisitService } from '../../../../../common/src/data/dao-services/site-visit.service';

export interface siteVisitSummaryInformation {
  siteVisit: SiteVisit;
  visitNumber: number;
  employee: Employee;
}

@Component({
  selector: 'multiple-site-visit-summary',
  templateUrl: './multiple-site-visit-summary.component.html',
  styleUrls: ['./multiple-site-visit-summary.component.scss']
})

export class MultipleSiteVisitSummaryComponent implements OnInit, OnDestroy {

  @Input() siteVisits: Observable<SiteVisit[]>;
  dataSource: Observable<siteVisitSummaryInformation[]>;

  // displayedColumns: string[] = ['visitNumber', 'visitDate', 'timeWindow', 'actualArrivalTime', 'expectedDuration', 'siteVisitArrivalStatus',
  // 'estimates','paperwork',
  // 'siteVisitNotes'];
  displayedColumns: string[] = ['visitNumber', 'visitDate', 'timeWindow', 'expectedDuration', 'actualArrivalTime', 'actualDuration', 'siteVisitArrivalStatus', "employee",
  'siteVisitNotes'];

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor(private siteVisitService: SiteVisitService, private assignmentService: AssignmentService, private employeeService: EmployeeService) {
  }


  public formatArrivalWindowString(siteVisit: SiteVisit): string {
    if (siteVisit.arrivalWindowEndDate !== undefined && siteVisit.arrivalWindowStartDate !== undefined) {
      return `${format(siteVisit.arrivalWindowStartDate, 'h:mm')}-${format(siteVisit.arrivalWindowEndDate, 'h:mm')}`;
    } else {
      return "";
    }
  }

  public formatActualDuration(siteVisit: SiteVisit) : string {
    if (siteVisit.actualStartDate && siteVisit.actualEndDate) {
      const diffMinutes = differenceInMinutes(siteVisit.actualEndDate, siteVisit.actualStartDate);
      return this.formatHours(diffMinutes/60);
    } else {
      return "";
    }
  }

  formatHours(hours: number): string {
    return `${Math.floor(hours)}H, ${Math.round(((hours-Math.floor(hours))*60))}M`;
  }

  public formatExpectedDuration(siteVisit: SiteVisit) : string {
    return this.formatHours(siteVisit.expectedDurationHours);
  }

  public formatActualArrivalTime(siteVisit: SiteVisit): string {
    if (siteVisit.actualStartDate !== undefined) {
      return format(siteVisit.actualStartDate, 'h:mm');
    } else {
      return "";
    }
  }


  ngOnInit(): void {

    this.siteVisits.pipe(
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      })
    ).subscribe();

    const assignments = this.siteVisits.pipe(
      switchMap(siteVisits => this.assignmentService.queryFirestoreForInValues('siteVisitDocId', siteVisits.map(sv => sv.DocId()))),
      switchMap(assignments => this.employeeService.loadMultiple$(assignments.map(a => a.employeeDocId)).pipe(map(() => assignments))));

    this.dataSource = this.siteVisits.pipe(
      switchMap(siteVisits => combineLatest([this.siteVisitService.loadMultiple$(siteVisits.map(x => x.DocId())),assignments])),
        map( ([siteVisits, assignments]) => {
          const orderedSiteVisits = siteVisits.sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf());
          let i=1;
          const retVal: siteVisitSummaryInformation[] = [];
          orderedSiteVisits.forEach(s => {
            if (assignments.find(a => a.siteVisitDocId === s.DocId()) !== undefined) {
              retVal.push({
                siteVisit: s,
                visitNumber: i,
                employee: this.employeeService.get(assignments.find(a => a.siteVisitDocId === s.DocId()).employeeDocId),
              });
            }
            i++;
          });
          return retVal;
        }),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        })
        );
  }

}
