import { Injectable } from "@angular/core";
import { Observable, of, throwError, zip } from "rxjs";
import { catchError, delay, filter, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { AuthenticationService } from "../../../../common/src/util/authentication.service";
import { CustomerCommunicationSchedulingService } from "../../../../common/src/data/dao-services/customer-communication-scheduling.service";
import { CustomerCommunicationTemplateService } from "../../../../common/src/data/dao-services/customer-communication-template.service";
import { CustomerService } from "../../../../common/src/data/dao-services/customer.service";
import { EmployeePermissionService } from "../../../../common/src/data/dao-services/employee-permission.service";
import { EmployeeRoleService } from "../../../../common/src/data/dao-services/employee-role.service";
import { EmployeeService } from "../../../../common/src/data/dao-services/employee.service";
import { FormCatagoryService } from "../../../../common/src/data/dao-services/form-catagory.service";
import { IndustryService } from "../../../../common/src/data/dao-services/industry.service";
import { JobService } from "../../../../common/src/data/dao-services/job.service";
import { SiteVisitService } from "../../../../common/src/data/dao-services/site-visit.service";
import { PricingMethodologySettingsService } from "../../../../common/src/data/dao-services/pricing-methodology-settings.service";
import { CompanySettings } from "../../../../common/src/data/dao/company-settings";
import { CompanySettingsService } from "../../../../common/src/data/dao-services/company-settings.service";
import { SchedulerSettingsService } from "../../../../common/src/data/dao-services/scheduler-settings.service";
import { SettingsService } from "../settings/settings.service";
import { LineItemService } from "../../../../common/src/data/dao-services/line-item.service";
import { SchedulerSettings } from "../../../../common/src/data/dao/scheduler-settings";
import { Job } from "../../../../common/src/data/dao/job";
import { FormModelFirestoreService } from "../../../../common/src/data/dao-services/form-model-firestore.service";
import { endOfDay, startOfDay } from "date-fns";
import { where } from "firebase/firestore";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  // karl account as source.
  sourcePath = `/service-providers/r2rWZNMrjZ2oHtfMyYSQ/`;

  constructor(
    private auth: AuthenticationService,
    private lineItemService: LineItemService,
    private settingsService: SettingsService,
    private formCatagoryService: FormCatagoryService,
    private siteVisitService: SiteVisitService,
    private jobService: JobService,
    private customerService: CustomerService,
    private schedulerSettingsService: SchedulerSettingsService,
    private companySettings: CompanySettingsService,
    private employeeService: EmployeeService,
    private customerCommuncationTemplateService: CustomerCommunicationTemplateService,
    private customerCommunicationSchedulingService: CustomerCommunicationSchedulingService,
    private pricingMethodologyService: PricingMethodologySettingsService,
    private employeeRoleService: EmployeeRoleService,
    private employeePermissionService: EmployeePermissionService,
    private industryService: IndustryService,
    private formModelFirestoreService: FormModelFirestoreService
  ) {}

  updateToLatestFormFirestore() : void {
    // Retrieve all site visits that occur today or later.
    // retrieve the assoicated jobs.
    // If the formModelFirestore has not been instantiated, check if the formFirestore is "W59vevi02EdGUittvYf8" or not.  If it isn't; update it.
  this.siteVisitService.queryFirestoreShallow$([where("_startDate", ">=", endOfDay(new Date()))]).pipe(
    switchMap(x => this.jobService.loadMultiple$(x.map(y => y.jobDocId))),
    map(x=> x.filter(y => y.formModelFirestore.instantiatedLatestForm === false && (y.formModelFirestore.model === undefined || y.formModelFirestore.model === "") &&
    y.formModelFirestore.formFirestoreDocId !== "W59vevi02EdGUittvYf8")),
    map(q => q.map(x => x.formModelFirestore)),
    tap(x => console.log(x,` string`)),
    // map(x => {
    //   const obs= [];
    //   x.forEach(y => {
    //     y.formFirestoreDocId = "W59vevi02EdGUittvYf8";
    //     obs.push(this.formModelFirestoreService.mergeUpdate(y, ["formFirestoreDocId"]))
    //   });
    //   return obs;
    // }
    // ),
    // switchMap(x => zip(...x))
  ).subscribe(x => console.log(x));
  }

  fixIt(): Observable<any> {
    this.auth._firebasePathRead = this.sourcePath;

    //rBVHLhchiDY6eSJufRtt ( active )
    //Is58R23r6EIaJ44vEL7t (previous)
    return this.formModelFirestoreService.load$("rBVHLhchiDY6eSJufRtt").pipe(
      map(x => {
        x.uncommitedDocId= true;
        return x;
      }),
      tap(x => console.log(x,` string`)),
      switchMap(x => this.formModelFirestoreService.create$(x))
    );

    // return this.customerCommuncationTemplateService.loadMultiple$(["ep81vhfoDyTbwKnfiRuq","l9G2wkS8qZnHhb01xXKY"]).pipe(
    //   filter((x) => x.length > 0),
    //   map((x) => {
    //     x.forEach((y) => (y.uncommitedDocId = true));
    //     return x;
    //   }),
    //   tap((x) => console.log(x, ` string`)),
    //   switchMap((x) =>
    //     this.customerCommuncationTemplateService.createMultiple$(x)
    //   )
    // );

    // return this.customerService
    //   .load$(this.settingsService.getValue("demoCustomerDocId"))
    //   .pipe(
    //     map((x) => {
    //       x.uncommitedDocId = true;
    //       return x;
    //     }),
    //     switchMap((x) => this.customerService.create$(x)),
    //     take(1),
    //     tap(x => console.log(x,` string`)),
    //   );

    // return of(null);

  }


  populateDefaultCustomerCommuncationSettings(): Observable<any> {
    this.auth._firebasePathRead = this.sourcePath;

    const temps = this.customerCommuncationTemplateService.loadAll$().pipe(
      filter((x) => x.length > 0),
      map((x) => {
        x.forEach((y) => (y.uncommitedDocId = true));
        return x;
      }),
      tap((x) => console.log(x, ` string`)),
      switchMap((x) =>
        this.customerCommuncationTemplateService.createMultiple$(x)
      )
    );

    const scheds = this.customerCommunicationSchedulingService.loadAll$().pipe(
      filter((x) => x.length > 0),
      map((x) => {
        x.forEach((y) => (y.uncommitedDocId = true));
        return x;
      }),
      tap((x) => console.log(x, ` string`)),
      switchMap((x) =>
        this.customerCommunicationSchedulingService.createMultiple$(x)
      )
    );

    return zip(temps, scheds);
  }

  populateIndustries(): Observable<any> {
    return this.industryService
      .loadAll$()
      .pipe(
        filter((x) => x.length > 0),
        map((x) => {
          x.forEach((y) => (y.uncommitedDocId = true));
          return x;
        }),
        switchMap((x) => this.industryService.createMultiple$(x)),
      );
  }

  populateEmployeePermissionsAndRoles(): Observable<any> {
    this.auth._firebasePathRead = this.sourcePath;

    return this.employeePermissionService
      .loadAll$()
      .pipe(
        filter((x) => x.length > 0),
        take(1),
        map((x) => {
          x.forEach((y) => (y.uncommitedDocId = true));
          return x;
        }),
        tap((x) => console.log(x, ` string`)),
        switchMap((x) => this.employeePermissionService.createMultiple$(x)),
        switchMap(() => this.employeeRoleService.loadAll$()),
        filter(x => x.length > 0),
        take(1),
        map(x => {
          x.forEach(y => y.uncommitedDocId = true);
          return x;
        }),
        tap(x => console.log(x,` string`)),
        switchMap(x => this.employeeRoleService.createMultiple$(x)),
        tap(x => console.warn("Added permissions and roles")),
        tap((x) => console.log(x, ` string`)),
        take(1)
      );
  }

  populateDefaultPricingMethodology(): Observable<any> {
    this.auth._firebasePathRead = this.sourcePath;
    return this.pricingMethodologyService
      .queryFirestoreDeep$([where("default", "==", true)])
      .pipe(
        filter((x) => x.length > 0),
        map((x) => {
          x.forEach((y) => (y.uncommitedDocId = true));
          return x;
        }),
        switchMap((x) => this.pricingMethodologyService.createMultiple$(x)),
      );
  }

  addGregAndSethEmployees(): Observable<any> {
    const boys = ["vb6TG6T7h0sS8qC5G2fQ", "wPoBl4iFQAOyavAFbS6O"];
    const subs: Observable<any>[] = [];
    boys.forEach((boy) => {
      subs.push(this.employeeService
        .load$(boy)
        .pipe(
          map((b) => {
            b.employeeSkills = [];
            b.employeeAvailability = [];
            b.employeePermissions = [];
            b.active = true;
            b.scheduleFieldCallsFor = false;
            b.uncommitedDocId = true;
            return b;
          }),
          switchMap((b) => this.employeeService.create$(b)),
        ));
    });
    return zip(...subs);
  }

  createMinimalInitialConditions(): void {
    this.auth._firebasePathRead = this.sourcePath;

    this.schedulerSettingsService.create$(new SchedulerSettings())
    .pipe(
    tap(() => console.warn("Scheduler settings created")),
    mergeMap(() => this.companySettings.create$(new CompanySettings())),
    tap(() => console.warn("Company settings created")),
    mergeMap(() => this.lineItemService.load$(this.settingsService.getValue("customLineItemPrototypeDocId"))
    .pipe(
        map((x) => {
          x.uncommitedDocId = true;
          return x;
        }),
        tap(x => console.log(x,` string`)),
        switchMap((x) => this.lineItemService.create$(x)),
        take(1)
      )
    ),
    tap(() => console.warn("Default line item created")),
    delay(10000),
    mergeMap(() => this.formCatagoryService
      .load$(this.settingsService.getValue("defaultWorkCatagoryDocId"))
      .pipe(
        map((x) => {
          x.uncommitedDocId = true;
          return x;
        }),
        switchMap((x) => this.formCatagoryService.create$(x)),
        take(1)
      )
    ),
    tap(() => console.warn("Default work catagory created")),
    delay(10000),
    mergeMap(() => this.customerService
      .load$(this.settingsService.getValue("demoCustomerDocId"))
      .pipe(
        map((x) => {
          x.uncommitedDocId = true;
          return x;
        }),
        switchMap((x) => this.customerService.create$(x)),
        take(1)
      )
    ),
    tap(() => console.warn("Default customer created")),
    delay(10000),
    mergeMap(() => this.siteVisitService
      .load$(this.settingsService.getValue("demoSiteVisitDocId"))
      .pipe(
        map((x) => {
          x.uncommitedDocId = true;
          return x;
        }),
        switchMap((x) => this.siteVisitService.create$(x)),
        take(1)
      )),
    tap(() => console.warn("Default site visit created")),
    delay(10000),
    mergeMap(() => this.populateEmployeePermissionsAndRoles()),
    tap(() => console.warn("Permissions and roles created")),
    delay(10000),
    mergeMap(() => this.populateIndustries()),
    tap(() => console.warn("Industries created")),
    delay(10000),
    mergeMap(() => this.populateDefaultPricingMethodology()),
    tap(() => console.warn("Default pricing methodology created")),
    delay(10000),
    // mergeMap(() => this.addGregAndSethEmployees()),
    // tap(() => console.warn("Greg and Seth employees created")),
    mergeMap(() => this.populateDefaultCustomerCommuncationSettings()),
    tap(() => console.warn("Customer communication scheduling created")),
    delay(10000),
    mergeMap(() => this.populateDefaultJob()),
      tap((x) => console.error("Completed minimal condition creation")),
      delay(10000),
      catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
      take(1),
      ).subscribe();

  }

  public popJob() : void {
    this.auth._firebasePathRead = this.sourcePath;
    this.populateDefaultJob().pipe(
      take(1),
    ).subscribe();
  }

  private populateDefaultJob(): Observable<Job> {
    return this.jobService
      .load$(this.settingsService.getValue("demoJobDocId"))
      .pipe(
        tap(x => console.log(x, ` string`)),
        map((x) => {
          x.uncommitedDocId = true;
          const sv = x.siteVistDocIds;
          x.siteVisits = [];
          return { job: x, sv: sv };
        }),
        switchMap((x) => this.jobService.create$(x.job).pipe(
          map(() => {
            x.job.siteVistDocIds = x.sv;
            return x.job;
          })
        )),
        switchMap(x => this.jobService.mergeUpdate(x, ["siteVisitDocIds"])),
        tap((x) => console.error("created default job"))
      );
  }
}
