<div class="container-cus">
  <div class="left-side"></div>
  <div class="section-cus">
    <div [formGroup]="form" class="content-customer">
      <div class="header-label" style="grid-column: 1/3; width: 100%;">Customer</div>


  <!--Start of Customer Search-->

    <div style="grid-column: 1/3;"></div> <!--This row just acts as spacing-->
    <ng-container *ngIf="searchExistingCustomers" >
      <!-- <h4>Customer Search</h4> -->

        <div class="label-style-cust">Customer Search</div>
        <div class="form-align" style="width:380px; display: flex; flex-direction: column;">

          <div style="display: flex;">
        <mat-form-field appearance="outline" style="width: 280px;">
          <input autofocus [name]="form.get('noAutoFill').value" matInput placeholder="Search Name, Email, Phone #, Company" autofill="off" autocomplete="new-password" [matAutocomplete]="nameAuto"
          data-lpignore="true" (keyup)="customerSearchKeyUp($event)" #inputBox formControlName="customerSearch">
            <mat-autocomplete panelWidth=600px #nameAuto="matAutocomplete" (optionSelected)="customerSearchOptionSelected($event)">
              <mat-option *ngFor ="let customer of customersFound$ | async" [value]="customer">
              <!-- Think about whether we want to have icon to represent phone as search origin..... -->
              <i class="material-icons">person_pin</i>{{customer.customerNameCompanyDisplay}} - {{customer.primaryPhoneNumber}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        <div *ngIf="activeSearchCustomer" fxLayout="row" fxLayoutAlign=" center"><mat-spinner [diameter]="25"></mat-spinner></div>
      </div>
          <div *ngIf="displayNoResultsFoundCustomer" fxLayout="row" fxLayoutAlign=" center" style="font-size: 16px; color: red; margin-left: 5px;">No Results Found<i class="material-icons">error</i></div>

      </div>
  </ng-container>
<!--End of Customer Search-->

<div class="label-style-cust">Customer Name</div>
<div style="width:280px;">
    <div class="form-align">
        <mat-form-field appearance="outline" style="width:280px;">
          <input placeholder="Customer Name" matInput [name]="form.get('noAutoFill').value" formControlName="customerName" autocomplete="new-password" autofill="off">
          <mat-error>Customer name is required</mat-error>
        </mat-form-field>
      </div>
    </div>

<!--End of Row 1 Customer Name-->
<!--Start of Row 2 Company-->
<div class="label-style-cust">Company</div>
<div style="width:280px;">
    <div class="form-align">
        <mat-form-field appearance="outline" style="width:280px;">
          <input placeholder="Company Name" matInput [name]="form.get('noAutoFill').value" formControlName="company" autocomplete="new-password" autofill="off">
        </mat-form-field>
      </div>
    </div>
<!--End of Row 1 Customer Name-->
<!--Start of Row 2 Mobile Phone-->
<div class="label-style-cust" style="align-self: center;">Primary Phone</div>
<div class="phone-alignment">
<div>
    <div class="form-align">
        <mat-form-field appearance="outline" style="width:180px;">
          <input (focusout)="onPhoneNumberFocusOut()" placeholder="Phone Number" type="text" matInput mask="(000) 000-0000" [name]="form.get('noAutoFill').value" formControlName="phoneNumber" autocomplete="new-password" autofill="off">
          <mat-error>Phone number is required.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div>
      <div class="form-align">
          <mat-form-field appearance="outline" style="width:75px;">
            <input placeholder="Ext." type="text" [name]="form.get('noAutoFill').value" matInput formControlName="primaryPhoneNumberExtension" autocomplete="new-password" autofill="off">
          </mat-form-field>
        </div>
      </div>
    </div>
<!--End of Row 2 Mobile Phone-->

<!--Start of Row 2 Mobile Phone-->
<div class="label-style-cust" style="align-self: center;">Secondary Phone</div>
<div class="phone-alignment">
<div>
    <div class="form-align">
        <mat-form-field appearance="outline" style="width:180px;">
          <input placeholder="Phone Number" type="text" matInput mask="(000) 000-0000" matInput [name]="form.get('noAutoFill').value" formControlName="secondaryPhoneNumber" autocomplete="new-password" autofill="off">
        </mat-form-field>
      </div>
    </div>
    <div>
      <div class="form-align">
          <mat-form-field appearance="outline" style="width:75px;">
            <input placeholder="Ext." type="text" [name]="form.get('noAutoFill').value" matInput formControlName="secondaryPhoneNumberExtension" autocomplete="new-password" autofill="off">
          </mat-form-field>
        </div>
      </div>
</div>
<!--End of Row 2 Mobile Phone-->


<!-- Other phone removed as per discussion w/ Greg on 2020-1-9 -->
<!--End of Row 3 Other Phone -->
<!--Start of Row 4 Email-->
<div class="label-style-cust">E-mail</div>
<div>
    <div class="form-align">
        <mat-form-field appearance="outline" style="width: 280px">
          <input (focusout)="onEmailFocusOut()" placeholder="Email" matInput [name]="form.get('noAutoFill').value" formControlName="primaryEmail" autocomplete="new-password" autofill="off">
          <mat-error>Not a valid email</mat-error>
        </mat-form-field>
    </div>
</div>
<!--End of Row 4 Email-->

<!-- Start Row 5 Billing Address -->
<div class="label-style-cust">Billing Address</div>
<div style="width: 100%;" class="form-align">
  <div style="display: flex;">
<mat-form-field appearance="outline" style="width: 300px;">
  <textarea cdkTextareaAutosize type="text" matInput placeholder="Service Address" autocomplete="new-password" autofill="off" [name]="form.get('noAutoFill').value" data-lpignore="true"
  [matAutocomplete]="billingAutoComplete" formControlName="billingAddressDisplay" (keyup)="billingAddressSearchKeyUp($event)" ></textarea>
    <mat-autocomplete panelWidth=600px #billingAutoComplete="matAutocomplete" (optionSelected)="billingAddressOptionSelected($event)">
      <mat-option *ngFor ="let place of addressesFound$ | async;" [value]="place" >
          <i class="material-icons">place</i> {{displayAddressSelected(place)}}
      </mat-option>
    </mat-autocomplete>
  <mat-error>Billing address is required.</mat-error>
</mat-form-field>
<mat-form-field appearance="outline" style="width:60px; margin-left: 10px;">
    <input type = "text" matInput placeholder="Unit / Suite" autocomplete="new-password" autofill="off" formControlName="billingAddressUnit" [name]="form.get('noAutoFill').value">
  </mat-form-field>
  <div *ngIf="activeSearch" fxLayout="row" fxLayoutAlign=" center"><mat-spinner [diameter]="25"></mat-spinner></div>
  </div>
<div *ngIf="displayNoResultsFound" fxLayout="row" fxLayoutAlign=" center" style="font-size: 16px; color: red; margin-left: 5px;">No Results Found<i class="material-icons">error</i></div>
</div>



<!--End of Row 5 Billing Address-->
<!--Start of Row 6 Referral-->
    <div class="label-style-cust">Referral</div>

  <div>
      <div class="form-align">
          <mat-form-field appearance="outline">
              <mat-select placeholder="Choose One" formControlName="customerReferral" [compareWith]="compareReferralCampaigns">
                <mat-option *ngFor="let referral of activeReferralCampaigns | async" [value]="referral">
                  {{referral.name}}
                </mat-option>
              </mat-select>
              </mat-form-field>
            </div>
          </div>

<!--End of Row 6 Referral-->
<!--Start of Row 7 Tags-->
  <div class="label-style-cust" style="padding-top: 20px;">Tags</div>
  <app-custom-tags class="form-align"
    [assignedTags] = "customTagComponentInputs.assignedTags"
    [existingTags] = "customTagComponentInputs.existingTags | async"
    [existingTagsUpdated] = "customTagComponentInputs.existingTagsUpdated"
    [disableInput] = "customTagComponentInputs.disableInput"
    (tagSelectedForRemoval) = "CustomTagComponentOutputs.tagSelectedForRemoval.next($event)"
    (tagSelectedForAddition) = "CustomTagComponentOutputs.tagSelectedForAddition.next($event)"
    (newlyCreatedTagForAdditon) = "CustomTagComponentOutputs.newlyCreatedTagForAdditon.next($event)"
  ></app-custom-tags>

<!--End of Row 7 Tags-->
<!--Start of Row 8 Customer Notes-->

  <div class="label-style-cust">Customer Notes</div>
  <div fxLayout="row wrap" fxLayoutAlign=" start" class="form-align">
      <mat-form-field appearance="outline" style="width:330px;">
          <textarea [cdkTextareaAutosize] matInput placeholder="Notes" [name]="form.get('noAutoFill').value" formControlName="customerNotes" autocomplete="new-password" autofill="off"></textarea>
        </mat-form-field>
      </div>

<!--End of Row 8 Customer Notes-->

<!--End of Grid Fields-->
<!--Start of Row 9 Cancel/Save-->

<div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
  <button mat-button class="button-gray" (click)="cancel()">Cancel</button>
  <button mat-button class="button-blue" (click) = "submit()" type="submit">Save</button>
  </div>
</div>
</div>
  <div class="right-side"></div>
</div>
