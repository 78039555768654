<div class="header-label header-border-top" style="display: flex; justify-content: space-between;">
  <div>{{settingTableTitle}}</div>
<div style="margin-right: 20px;"><button mat-button class="button-green" (click)="AddNew()">Add New {{trimmedTitle}}</button></div>
</div>
<div style="display: contents;">
      <table mat-table [dataSource]="dataSource | async" class="mat-elevation-z8" style="grid-column: 1/3;">

        <!-- Default Column View -->
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnNames">
          <th mat-header-cell class="w-75" *matHeaderCellDef> {{columnsToDisplayHumanReadable.get(column)}} </th>
          <td class="line-item-border-top w-75" mat-cell *matCellDef="let setting;">
            <ng-container [ngSwitch]="getType(column)">
              <div *ngSwitchCase="'string'" >{{setting[column]}}</div>
              <div *ngSwitchCase="'date'">{{setting[column] | date:'medium'}}</div>
              <div *ngSwitchCase="'boolean'">{{setting[column]}}</div>
              <div *ngSwitchDefault></div>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headerRow"></tr>
        <tr mat-row *matRowDef="let setting; columns: headerRow;" class="example-element-row" (click)="selectRow(setting)"></tr>
        </table>
        <h5 style="grid-column: 1/3;">Details:</h5>
        <ng-container [ngTemplateOutlet]="detailTemplate"></ng-container>
    </div>
    <!-- <div><button mat-button class="button-green" (click)="AddNew()">Add New Location</button></div> -->
    <div style="margin-left: 10px; margin-top: 40px;"><button mat-button class="button-red" (click)="Delete()">Delete {{trimmedTitle}}</button></div>
    <div style="display: flex; justify-content: end; margin-right: 10px; margin-top: 40px"><button mat-button class="button-blue" (click)="Save()">Save {{trimmedTitle}}</button></div>
