import { Component, OnInit } from '@angular/core';
import { DexieCacheService } from '../../../../common/src/data/dao-services/dexie-cache.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(private dexieCacheService: DexieCacheService) { }

  ngOnInit(): void {
  }

  get versionInfo() {
    return environment.appVersion
  }

  get versionTime() {
    return environment.releaseTimeStamp;
  }

}
