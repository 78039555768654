import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LocalSettingsService } from 'web-app/src/app/settings/local-settings.service';
import { randomElementName } from '../../../../../../common/src/util/util';

export enum VerticalSchedulerViewWindow {
  Week = "WEEK",
  Day = "DAY",
  WorkDay = "WORKDAY"
}

@Component({
  selector: 'app-day-week-view',
  templateUrl: './day-week-view.component.html',
  styleUrls: ['./day-week-view.component.scss']
})

export class DayWeekViewComponent implements OnInit {

  @Input() viewWindowMovementEnabled: boolean;
  @Output() updatedViewWindow = new EventEmitter<VerticalSchedulerViewWindow>();
  @Output() updateNumberWorkdaysToDisplay = new EventEmitter();
  @Input() numberWorkDaysDisplayed: number;

  randomElementName : string = randomElementName();
  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  viewWindow: string = "WorkDay";

  get ViewModelDisabled() {
    return !this.viewWindowMovementEnabled; }

  emitSchedulerViewWindow(val: string)
  {
    this.localSettingsService.saveToLocalStorage("DayWeekView","viewWindow",val);
    switch (val) {
      case "Day":
        this.updatedViewWindow.emit(VerticalSchedulerViewWindow.Day);
        break;
      case "Week":
      this.updatedViewWindow.emit(VerticalSchedulerViewWindow.Week);
      break;
      case "WorkDay":
        this.updatedViewWindow.emit(VerticalSchedulerViewWindow.WorkDay);
        break;
    }
  }

  updateNumberOfWorkDaysToDisplay(event: any) {
    this.updateNumberWorkdaysToDisplay.emit((event.target as HTMLInputElement).valueAsNumber);
  }

  constructor(private localSettingsService: LocalSettingsService) {
    this.viewWindow = localSettingsService.loadFromLocalStorage("DayWeekView", "viewWindow", "WorkDay");
  }

  ngOnInit() {
    this.updatedViewWindow.emit(this.viewWindow.toUpperCase() as VerticalSchedulerViewWindow);
  }

}
