import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { defaultFirestoreDiffMapper, FirestoreDiff } from "./firestore-diff";
import { GenericServiceProviderSetting } from "./generic-service-provider-setting";
import { LineItem } from "./line-item";
import { PricingMethodologySettings } from "./pricing-methodology-settings";

export class JobType extends RetrieveFirestorePropertiesBase<JobType> {

  jobTypeDocId: string;
  jobPriorityDocId : string;
  jobPriority: GenericServiceProviderSetting;
  lineItemsDocIds: string[] = [];
  lineItems: LineItem[] = [];
  name: string = "Name";
  active: boolean = true;
  pricingMethodology: PricingMethodologySettings;
  pricingMethodologySettingDocId: string;

  color = "red";

  DocId(): string {
    return this.jobTypeDocId;
  }

  SetDocId(docId: string): void {
    this.jobTypeDocId  = docId;
  }

  createFirestoreDiff(previous: JobType, current: JobType,
    memberName: string, diffType: string, containedInArray: boolean): FirestoreDiff[] {
    return defaultFirestoreDiffMapper(previous, current, memberName, diffType, containedInArray);
  }

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([ "lineItems", "jobPriority","pricingMethodology"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
    .concat(["jobTypeDocId", "lineItemsDocIds","pricingMethodologySettingDocId"]);
  static _firestoreCompositionMemberNames: string[] = ["lineItems",  "jobPriority", "pricingMethodology"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["lineItems", "jobPriority", "pricingMethodology"];

  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return JobType._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return JobType._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return JobType._firestoreCompositionMemberNames;}
  retrieveFirestoreIgnoredMemberNames() : string[] { return JobType._firestoreIgnoredMemberNames};

  public constructor(init?: Partial<JobType>) {
    super();
    Object.assign(this, init);
  }

}
