/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
// export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {

//    const map = new Map<K, Array<V>>();
export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export function randomElementName () { return (Math.random().toString(36) + '00000000000000000').slice(2, 14); }

export class LiteDomClassList {

  list: Set<string>;

  get value(): string {
    let retVal = "";
    this.list.forEach(element => {
      if (retVal === "") {
        retVal = element;
      } else {
        retVal = retVal.concat(" ", element);
      }
    });
    return retVal;
}

  constructor(existing: string[]) {
    this.list = new Set(existing);
  }

  add(val: string) {
    this.list.add(val);
  }

  remove(val: string) {
    this.list.delete(val);
  }

}
