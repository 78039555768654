import { ChangeDetectorRef, AfterViewInit, Component,  OnInit, ChangeDetectionStrategy, OnDestroy, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyFormBuilder, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, forkJoin,  merge,  Observable,  of,  ReplaySubject,  Subject, throwError,  timer,  zip } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, skip, mergeMap, share, switchMap, take, takeUntil, tap, debounceTime, debounce, delayWhen, finalize, startWith, pairwise } from 'rxjs/operators';

import { AuthenticationService } from '../../util/authentication.service';
import { EmployeeService } from '../../data/dao-services/employee.service';
import { FormlyLineItemService } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-line-item/formly-line-item.service';
import { FormlyUtilityService } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-utility.service';
import { FetchUpdatedWorkflowService, WORKFLOW_STAGE } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/utilities/fetch-updated-workflow.service';
import { DataUsedByDataLinkService } from '../../../../web-app/src/app/form-builder/data-link-populator/data-link.service';
import { FormFirestoreService } from '../../data/dao-services/form-firestore.service';
import { FormModelFirestore } from '../../data/dao/form-model-firestore';
import { FormModelFirestoreService } from '../../data/dao-services/form-model-firestore.service';
import { JobService } from '../../data/dao-services/job.service';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { LoggingService } from '../../data/logging/logging.service';
import { FormFirestore } from '../../data/dao/form-firestore';
import { Audience } from '../../../../web-app/src/app/line-item/line-item-display/line-item-display.component';

/**
 * Responsible for rendering a workflow.
 */
@Component({
  selector: 'app-form-firestore-workflow',
  templateUrl: './form-firestore-workflow.component.html',
  styleUrls: ['./form-firestore-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFirestoreWorkflowComponent implements OnInit, AfterViewInit, OnDestroy {


  get formModelFirestore() : FormModelFirestore {
    return this.formlyUtilityService.activeFormModelFirestore;
  }

  set formModelFirestore(val: FormModelFirestore) {
    this.formlyUtilityService.activeFormModelFirestore = val;
  }


  form!: FormGroup;
  model = {};
  formlyFields = new BehaviorSubject<FormlyFieldConfig[]>([]);
  formlyOptions: FormlyFormOptions = {};

  destroyingComponent$ = new Subject();
  hardFormlyReset$ = new Subject();

  savedFormHistorySnapshot: boolean = false;
  activeFormModelFirestoreDocId: string | null;
  loadedInitialFormModelFirestore$ = new BehaviorSubject<boolean>(false);
  jobSuccessfullyMarkedAsCompleted$ = new BehaviorSubject<boolean>(true);
  workflowStage!: WORKFLOW_STAGE;
  jobInitiallyLoaded$ = new ReplaySubject<boolean>(1);
  // this is used as optimization so that we only update the model if possible, rather then re-render all formly-form components
  previousBranchKeysToSelectedFirestoreDocIds : any;

  private jobDocId: string;
  private customerDocId: string | null;
  private activeSiteVisitDocId: string | null;
  private saveOnUpdate = true;

  get audience(): Audience {
    return this.formlyUtilityService.activeAudience;
  }

  set audience(val: Audience) {
    this.formlyUtilityService.activeAudience = val;
  }



  private skipExternalEmission : boolean = false;
  private loggingService: LoggingService = inject(LoggingService);


  constructor(private route: ActivatedRoute, private fb: FormBuilder, private formModelFirestoreService: FormModelFirestoreService,
    public formlyUtilityService: FormlyUtilityService, public employeeService: EmployeeService, private jobService: JobService,
    private formlyLineItemService: FormlyLineItemService,  private authService: AuthenticationService, private formFirestoreService: FormFirestoreService,
    private fetchUpdatedWorkflowService: FetchUpdatedWorkflowService, private builder: FormlyFormBuilder, private settingsService: SettingsService, private ref: ChangeDetectorRef ) {
      this.form = this.fb.group({});
      this.workflowStage = this.formlyUtilityService.workFlowStage;

     }


    ngOnDestroy(): void {
      console.log("DESTRYOING THE FORM FIRESTORE WORKFLOW COMPONENT");
      this.formlyUtilityService.resetBranchesAndLeafs();
      this.destroyingComponent$.next(null);
      this.destroyingComponent$.complete();
      }


    patchInTechViewUpdates() {


      const patchDesignToTechView = this.formlyUtilityService.patchFormFirestoreToTechViewFromDesign$.pipe(
        tap(() => this.saveOnUpdate = false),
        filter(x => x !== null && x.form !== undefined),
        delayWhen(() => merge(this.loadedInitialFormModelFirestore$.pipe(filter(x => x===true)), of(this.loadedInitialFormModelFirestore$.value).pipe(filter(x => x === true)))),
        tap(() => {
          this.formlyUtilityService.resetSectionMappings();
        this.form = new FormGroup({});
        this.formlyFields.next([]);
        this.model = {columns: [{}],};
        }),
        startWith(null),
        pairwise(),
        map(([prev, curr]) => {
          return {val: curr, previousForm: prev?.form};
        }),
        tap(() => this.fetchUpdatedWorkflowService.formModelFirestoreMapping = new Map<string, FormModelFirestore>()),
        tap(() => this.formModelFirestore.resetMappings()),
        switchMap(x => this.formlyUtilityService.updateSectionsIfNeeded(x.val, this.workflowStage, this.formModelFirestore.DocId()).pipe(
          map(() => x),
          take(1),
        )),
        share()
        ) as Observable<{val: FormFirestore, previousForm: string}>;

        //updated form
        const updatedForm = patchDesignToTechView.pipe(
          filter(x => x.previousForm !== x.val.form),
          tap(x => console.log("UPDATeD FORM")),
          tap(x => {
            this.formModelFirestore.formFirestore = x.val;
          }),
          tap(x => {
            this.formModelFirestore.skipSave = true;
          })
        );

        //didn't update form
        const didntUpdateForm = patchDesignToTechView.pipe(
          filter(x => x.previousForm === x.val.form),
          tap(x => console.log("NOOOOOOOOO  UPDATeD FORM"))
        )

        merge(updatedForm, didntUpdateForm).pipe(
        tap(() => this.updateFormlyForm(this.formModelFirestore, false, false)),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
    }

    /**
     * Patches in routing params.  These should be populated to the workflow service, nothing more.
     */
    patchInRoutingParams() {
      if (this.route.snapshot.paramMap.get('siteVisitDocId')) {
        this.activeSiteVisitDocId = this.route.snapshot.paramMap.get('siteVisitDocId');
      }

      if (this.route.snapshot.paramMap.get('jobDocId')) {
        this.jobDocId = this.route.snapshot.paramMap.get('jobDocId')!;
      }

      if (this.route.snapshot.paramMap.get('customerDocId')) {
        this.customerDocId = this.route.snapshot.paramMap.get('customerDocId');
      }

      if (this.route.snapshot.paramMap.get('expandLineItemControls')) {
        this.formlyUtilityService.expandLineItems = true;
      }

      if (this.route.snapshot.paramMap.get('cf')) {
        this.formlyUtilityService.customerFacing = true;
      }


      if (this.route.snapshot.paramMap.get('invoiceDocId')) {
        if (this.route.snapshot.paramMap.get('invoiceDocId') !== 'null') {
          this.formlyLineItemService.explicitInvoiceDocId = this.route.snapshot.paramMap.get('invoiceDocId');
        }
      }
      if (this.route.snapshot.paramMap.get('estimateDocId')) {
        if (this.route.snapshot.paramMap.get('estimateDocId') !== 'null') {
          this.formlyLineItemService.explicitEstimateDocId = this.route.snapshot.paramMap.get('estimateDocId');
        }
    }
  }

    ngAfterViewInit(): void {
     this.patchInTechViewUpdates();
  }

    ngOnInit(): void {

      this.formlyUtilityService.generateNewFormInstanceUuid();
      this.patchInRoutingParams();
      this.formlyUtilityService.resetLineItemsWaitingForFirstEmission();

      this.formlyUtilityService.triggerReload$.pipe(
        takeUntil(this.destroyingComponent$)
      ).subscribe(() => {
        this.updateFormlyForm(this.formModelFirestore, false, true)
      });



    this.formlyUtilityService.spliceWorkflowSelectedFromBranchingControl$.pipe(
      delayWhen(() =>this.jobInitiallyLoaded$.pipe(filter(x => x === true))),
      tap((x) => this.spliceWorkflowSelectedFromBranchingControl(x)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    const sectionFromBranchPopulated = this.formlyUtilityService.removeSectionFromBranch$.pipe(
      map(x => {
        const associatedSectionKey = this.formlyUtilityService.leafKeyFromDocAndBranchKey(x.sectionDocId, x.branchKey, x.parentId);
        return {sectionKey: associatedSectionKey, parentId: x.parentId};
      })
    );

    merge(this.formlyUtilityService.removeSectionFromWorkflow$,sectionFromBranchPopulated ).pipe(
      tap(x => console.log(x,` string`)),
      tap((x) => this.removeSectionFromWorkflow(x.sectionKey, x.parentId)),
      takeUntil(this.destroyingComponent$)
    ).subscribe();


    const jobLoad = this.jobService.load$(this.jobDocId).pipe(
      filter(x => x !== null),
      tap(() => this.jobInitiallyLoaded$.next(true))
      );

      const loadFormModelFirestoreRaw$ = new ReplaySubject<FormModelFirestore>(1);

      // Disable / enable form input as needed.
      this.formlyUtilityService.disableFormInput$.pipe(
        distinctUntilChanged(),
        tap(x => x ? this.form.disable() : this.form.enable()),
        takeUntil(this.destroyingComponent$)
      ).subscribe();

      // On routing to component load form model firestore parameter.
      const loadedFromRoute : ReplaySubject<FormModelFirestore> = new ReplaySubject<FormModelFirestore>(1);
      this.route.paramMap.pipe(
        delayWhen(() => this.settingsService.settingsLoaded$),
        map(params => params.get("formModelFirestoreDocId")! ),
        switchMap(docId => this.formModelFirestoreService.load$(docId)),
        filter(x=>x!==null && x.formFirestoreDocId !== undefined),
        // take(1)
      ).subscribe(loadedFromRoute);

      let instantiatedThisTime = false;

      // If workflow is being instantiated for the first time, we ensure that we are using the latest deployed version and update if needed.
      const instantiateWorkflowUpdateFormFirestoreIfNeededSource = loadedFromRoute.pipe(
        filter(x => x.instantiatedLatestForm === null && (x.model === undefined || x.model === "") && x.formFirestore!==undefined),
        tap(() => instantiatedThisTime = true),
        mergeMap(formModelFirestore => this.formlyUtilityService.returnLatestFormFirestore(formModelFirestore.formFirestore, this.workflowStage).pipe(map(formFirestore => {
          return {
            formModelFirestore: formModelFirestore,
            formFirestore: formFirestore,
            formFirestoreFormPreUpdate: formModelFirestore.formFirestore.form,
            // QUEST formFirestoreDocIdPreUpdate: formModelFirestore.formFirestore.DocId()
          };
        }))),
        mergeMap(obj => this.formlyUtilityService.updateSectionsIfNeeded(obj.formFirestore, this.workflowStage, obj.formModelFirestore.DocId()).pipe(
          map(() => obj),
          switchMap(obj => this.formModelFirestoreService.retrieveFirestoreBatchString().pipe(
            map(wb => {
              return {val: obj, wb: wb}
            })
          )),
        )),
        share()
        );

        const updateNeeded = instantiateWorkflowUpdateFormFirestoreIfNeededSource.pipe(
          filter(x =>  x.val.formFirestore.form  !== x.val.formFirestoreFormPreUpdate),
          switchMap(x => this.formFirestoreService.retrieveDocId(x.val.formFirestore).pipe(
            map(() => {
              console.log("update needed");
              return x;
            })
          )),
          tap(x => {
            this.loggingService.addLog(`Attempting to update formFirestore : ${x.val.formFirestore.DocId()}`,"FormFirestoreWorkflowComponent.ts" ,
          {prev: JSON.stringify(x.val.formFirestore.form), curr: x.val.formFirestoreFormPreUpdate});
          console.log(x);
          }),
          map(obj => {
            obj.val.formModelFirestore.formFirestoreDocId = obj.val.formFirestore.docId;
            return obj;
          }),
          switchMap(obj => this.formFirestoreService.create$(obj.val.formFirestore, obj.wb).pipe(map(() => obj),
          )));

        const noUpdateNeeded = instantiateWorkflowUpdateFormFirestoreIfNeededSource.pipe(
          filter(x => x.val.formFirestore.form  === x.val.formFirestoreFormPreUpdate),
          tap(() => {
            console.log("NO UPDATE NEEDED FORM FIRESTORE! ");
          }),
        );

        const loadMergedUpdateNeededOrNot =merge(updateNeeded, noUpdateNeeded).pipe(
        take(1),
        map(obj => {
          const updatedFormFirestoreModel = new FormModelFirestore({docId: obj.val.formModelFirestore.docId,
            formFirestore: obj.val.formFirestore,
            instantiatedLatestForm: true,
            model: ""});
          return {val: updatedFormFirestoreModel, wb: obj.wb, formModelFirestore: obj.val.formModelFirestore};
        }),
        mergeMap(obj => this.formModelFirestoreService.mergeUpdate(obj.val, ["formFirestoreDocId", "instantiatedLatestForm", "model"], obj.wb, true).pipe(
          map(() => obj)
        )),
        switchMap(x => this.formModelFirestoreService.commitExternallyManagedFirestoreBatch(x.wb).pipe(
          map(() => x.formModelFirestore),
        )),
        take(1),
        switchMap(x => this.formModelFirestoreService.load$(x.DocId())),
        filter(x => x.formFirestore !== undefined),
        );

        const designUpdate = loadMergedUpdateNeededOrNot.pipe(
          filter(() => this.workflowStage === WORKFLOW_STAGE.DESIGN),
          take(1)
        );

        const deployUpdate = loadMergedUpdateNeededOrNot.pipe(
          filter(() => this.workflowStage === WORKFLOW_STAGE.DEPLOYED),
        );

        merge(designUpdate, deployUpdate).pipe(
          tap(x => loadFormModelFirestoreRaw$.next(x)),
          takeUntil(this.destroyingComponent$),
      ).subscribe();


    //If workflow has been instantiated we next raw form model firestore observable w/o checking to ensure it is most current.
    const loadFirst = loadedFromRoute.pipe(
      filter(x => !instantiatedThisTime &&  (x.instantiatedLatestForm === true || (x.model !== undefined && x.model !== ""))),
      switchMap(x => this.formModelFirestoreService.load$(x.DocId())),
      tap(x => loadFormModelFirestoreRaw$.next(x))
    );

    merge(loadFirst, this.destroyingComponent$).pipe(
      take(1)
    ).subscribe();

    //If workflow has been instantiated we next raw form model firestore observable w/o checking to ensure it is most current.
    const routeLoad = loadedFromRoute.pipe(
      filter(x => !instantiatedThisTime &&  (x.instantiatedLatestForm === true || (x.model !== undefined && x.model !== ""))),
      switchMap(x => this.formModelFirestoreService.load$(x.DocId())),
      debounce(() => this.loadedInitialFormModelFirestore$),
      skip(1),
      share()
    );

    routeLoad.pipe(
       // if update is initilized by instance in question, do not populate as it may no longer be the most up to date.
       filter( x=> x.lastUpdatedByGuid !== this.authService.guid),
      filter(x => x.model !== ""),
      tap(() => this.skipExternalEmission = true),
      tap(x => this.model = JSON.parse(x.model)),
      takeUntil(this.destroyingComponent$),
    ).subscribe();



    // We do not want to save the first emission.
    const loadFormModelFirestoreModelPopulated = loadFormModelFirestoreRaw$.pipe(
      take(1),
      tap(f => this.formlyUtilityService.skippedFirstPopulatedEmission = true)
      );

      // If change to form model was triggered by a different user, we want to skip saving the next change to the form.
      loadFormModelFirestoreRaw$.pipe(
        skip(this.formlyUtilityService.workFlowStage === WORKFLOW_STAGE.DEPLOYED ? 1 : 0),
        filter( x=> x.lastUpdatedByGuid !== this.authService.guid),
        tap(() => this.skipExternalEmission = true),
        tap(f => {
          this.formModelFirestore = f;
        this.updateFormlyForm(f,false);
        }),
        tap(() => this.ref.markForCheck()),
        takeUntil(this.destroyingComponent$)
        ).subscribe();

    // DEB0 ( j/k just remove extra logging when passing testing)

    const loadFormModelFirestore =
    this.settingsService.settingsLoaded$.pipe(
      switchMap(() => loadFormModelFirestoreModelPopulated),
      filter(f => f.formFirestore && f.formFirestore.form !== undefined),
      debounce(() => this.jobInitiallyLoaded$),
      tap(x => console.log(x.formFirestore.DocId(), x.formFirestoreDocId)),
      tap(f => {
        this.formModelFirestore = f;
        this.updateFormlyForm(f,false);
        if (this.formModelFirestore.previousLastUpdatedAt === null){
          this.formModelFirestore.previousLastUpdatedAt = new Date(this.formModelFirestore.lastUpdatedAt);
          this.formModelFirestore.employeeLoggedInWheLastUpdated = this.formModelFirestore.lastUpdatedByEmployeeDocId;
        }
        this.loadedInitialFormModelFirestore$.next(true);
      }),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      })
    );


    const initialLoad = combineLatest([jobLoad, loadFormModelFirestore]).pipe(
      tap(() => this.formlyUtilityService.skippedFirstPopulatedEmission = true),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      }));

      initialLoad.pipe(
        take(1)
      ).subscribe();

    this.initilizeSavingUpdatesToFirestore(this.form);

  }

  initilizeSavingUpdatesToFirestore(formToObserve: FormGroup) {

    const destroyOrHardReset = merge(this.destroyingComponent$,this.hardFormlyReset$).pipe(
      delayWhen(() => this.formlyUtilityService.activeSaveInPipe$.pipe(filter(x => x === false))),
      tap(x => console.error("DELAY OR HARD RESET CALLED    DELAY OR HARD RESET CALLED    DELAY OR HARD RESET CALLED    DELAY OR HARD RESET CALLED")),
      share()
    );

    formToObserve.valueChanges.pipe(
      filter(() => this.skipExternalEmission === true),
      switchMap(() => timer(500).pipe(take(1))),
      tap(() => this.skipExternalEmission = false),
      takeUntil(destroyOrHardReset)
    ).subscribe();

    const updateFromFormToObserve = formToObserve.valueChanges.pipe(
      filter(() => this.saveOnUpdate === true && this.formlyUtilityService.workFlowStage !== WORKFLOW_STAGE.DESIGN),
      filter(() => this.skipExternalEmission === false),
      debounceTime(100),
      debounce(() => this.formlyUtilityService.formlyFieldRequiringInitialObservableEmission.pipe(
        startWith(this.formlyUtilityService.formlyFieldRequiringInitialObservableEmission.value),
        filter(x => x.size === 0))),
      debounce(() => this.formlyUtilityService.debounceDuringCheckboxValueChanges$.pipe(filter(x => x === false))),
      filter(() => !this.formlyUtilityService.disabledForms),
      filter(()  => this.formModelFirestore !== undefined),
      // We use model here b/c otherwise hidden controls aren't included (i.e. _id field)
      map(() => {
         const retVal = JSON.stringify(this.model);
         return retVal;
      }),
      filter(x => x !== "{}"),
      distinctUntilChanged(),
      tap(() => this.formlyUtilityService.activeSaveInPipe$.next(true)),
      tap(x => this.formModelFirestore.model = x),
      tap(x => console.warn("COMMONS UPDATIN TO FIRESTORE!!!!")),
      share()
      ) as Observable<string>;

      const saveFormExplicitMapToCurrentFormModel = this.formlyUtilityService.saveFormModelExplicit$.pipe(
        filter(() => this.formlyUtilityService.saveFormModelFirestoreUpdates && this.formModelFirestore?.model!==undefined),
        filter(() => this.saveOnUpdate === true && this.formlyUtilityService.workFlowStage !== WORKFLOW_STAGE.DESIGN),
        tap(() => this.formlyUtilityService.activeSaveInPipe$.next(true)),
        debounce(() => this.formlyUtilityService.debounceDuringCheckboxValueChanges$.pipe(filter(x => x === false))),
        map(() => this.formModelFirestore.model),
        takeUntil(destroyOrHardReset),
        tap(x => console.warn("CALLING SAVE FORM EXPLICIT")),
        share()
        );

      const addToPreviousForms = merge(updateFromFormToObserve,saveFormExplicitMapToCurrentFormModel).pipe(
        filter(() => !this.savedFormHistorySnapshot && this.formlyUtilityService.skippedFirstPopulatedEmission),
          delay(1),
          tap(() => this.savedFormHistorySnapshot = true),
          switchMap(x => this.generateCopyOfForm().pipe(
            map( copyOfForm => {
              return {current: x, copy: copyOfForm};
            }),
          )),
          switchMap(x => this.formModelFirestoreService.retrieveFirestoreBatchString().pipe(
            map(wb => {
              return {...x, wb: wb};
            })
          )),
          mergeMap(x => this.formModelFirestoreService.create$(x.copy, x.wb).pipe(
            map(y => x),
            take(1),
          )),
          tap(x => {
            this.formModelFirestore.previousFormModelFirestoreVersionDocIds.push(x.copy.docId);
              this.formModelFirestore.title = "";
              this.formModelFirestore.previousLastUpdatedAt = new Date();
              this.formModelFirestore.employeeLoggedInWheLastUpdated = this.authService.activelyLoggedInEmployeeDocId;
          }),
          map(x => {
            return {val: x.current, wb: x.wb};
          }),
          );


      const alreadyAdded = merge(updateFromFormToObserve,saveFormExplicitMapToCurrentFormModel).pipe(
        filter(() => this.savedFormHistorySnapshot && this.formModelFirestore.model !== undefined),
        switchMap(x => this.formModelFirestoreService.retrieveFirestoreBatchString().pipe(
          map(wb => {
              return {val: x, wb: wb}
            })
        )
      ));

        merge(addToPreviousForms,alreadyAdded).pipe(
        tap(() => console.warn("ACTIVE SAVE IN PIPE.")),
        debounceTime(1000),
        map(x => {
          return {val: this.formModelFirestore, wb: x.wb};
        }),
        mergeMap(f => this.formModelFirestoreService.update$(f.val, f.wb).pipe(
          map(() => f.wb),
          take(1)
        )),
        switchMap(wb => this.formModelFirestoreService.commitExternallyManagedFirestoreBatch(wb)),
        delay(1),
        tap(() => this.formlyUtilityService.activeSaveInPipe$.next(false)),
        tap(x => console.warn("Saved form model firstore to database.")),
        takeUntil(destroyOrHardReset.pipe(tap(x => console.log("WE DESTROYED THE OUTERS!")))),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
        finalize(() => console.log("FINALIZE OUTER DESTRUCTIONS"))
    ).subscribe();
  }

  removeSectionFromWorkflow(key: string , parentId: string | null) {
    const updated = this.iterateThroughFormlySearchingForKey(this.formlyFields.value, {spliceKey: key, formlyConfig: null, parentId},"Remove");
    if (updated) {
      this.formlyFields.next(this.formlyFields.value);
    }
  }

  spliceWorkflowSelectedFromBranchingControl(val: {spliceKey: string, parentId: null | string, formlyConfig: FormlyFieldConfig} ) {

    this.formlyUtilityService.setActiveViewAndDisabledTemplateOptionsAllTheWayDown(
      val.formlyConfig,
      "techView",
      this.formlyUtilityService.disabledForms,
      this.audience, undefined, this.formModelFirestore.DocId()
    );
    this.formlyUtilityService.patchInDataLinksIfNeededAllTheWayDown(
      val.formlyConfig,
      new DataUsedByDataLinkService({
        customerDocId: this.customerDocId,
        firstSiteVisitDocId: this.activeSiteVisitDocId,
        activeSiteVisitDocId : this.activeSiteVisitDocId,
        jobId: this.jobDocId,
        invoiceDocId: this.formlyLineItemService.explicitInvoiceDocId,
      })
    );
    const updated = this.iterateThroughFormlySearchingForKey(this.formlyFields.value, val);
    if (updated) {
      this.formlyFields.next(this.formlyFields.value);
    }
  }

  iterateThroughFormlySearchingForKey(formly: FormlyFieldConfig[] | FormlyFieldConfig, val: {spliceKey: string, parentId: string | null,
    formlyConfig: FormlyFieldConfig | null}, removeOrSplice = "Splice") : boolean{

    let retVal = false;
    if (val.parentId === "top_level") {
      const elementIndex = (formly as FormlyFieldConfig[]).findIndex(x => x.key === val.spliceKey);
      if (elementIndex > -1) {
        if (removeOrSplice === "Splice") {
          (formly as FormlyFieldConfig[]).splice(elementIndex+1, 0,val.formlyConfig!);
          console.error(`SPLICED IN:  ${val.formlyConfig!.key} @ ${val.spliceKey}`);
          this.model={...this.model};
        } else if (removeOrSplice === "Remove") {
          (formly as FormlyFieldConfig[]).splice(elementIndex, 1);
          console.error(`REMOVED! ${val.spliceKey}`);
        }
        return true;
      } else {
        return false;
      }
    }

    if (Array.isArray(formly)) {

      for (let formlyConfig of formly) {
        if (formlyConfig.fieldGroup) {
          const sectionIndex = formlyConfig.fieldGroup.findIndex(z => z.key === "_id" && z.model._id === val.parentId);
          if (sectionIndex > -1) {
            // Find the index of the specified branch.
            const branchingKey = val.spliceKey.split("0.").length > 1 ? "0.".concat(val.spliceKey.split("0.")[1]) : val.spliceKey;
            if (removeOrSplice === "Splice") {
              const spliceIndex = formlyConfig.fieldGroup.findIndex(z => z.key === branchingKey) + 1;
                formlyConfig.fieldGroup.splice(spliceIndex, 0,val.formlyConfig!);
              console.error(`SPLICED IN:  ${val.formlyConfig!.key} @ ${val.spliceKey} @${val.parentId}`);
              this.model={...this.model};
            } else if (removeOrSplice === "Remove") {
              const spliceIndex = formlyConfig.fieldGroup.findIndex(z => z.key === val.spliceKey);
              if (spliceIndex !== -1) {
                formlyConfig.fieldGroup.splice(spliceIndex, 1);
                console.error(`REMOVED! ${val.spliceKey}`);
              }
            }
            return true;
          } else {
            retVal = this.iterateThroughFormlySearchingForKey(formlyConfig.fieldGroup, val, removeOrSplice);
            if (retVal) {
              return retVal;
            }
          }
        }
      }
    } else {
      return false;
    }
  }

  updateFormlyForm(f: FormModelFirestore, disabled: boolean, explictlyRebuild: boolean = false) {
    this.activeFormModelFirestoreDocId = f.DocId();
    if (f.model) {
      this.model = JSON.parse(f.model);
    }
    let parsedForm = JSON.parse(f.formFirestore.form);
    if (!Array.isArray(parsedForm)) {
      parsedForm = (parsedForm as any).fieldArray.fieldGroup;
    }
    parsedForm = parsedForm.filter(x => this.formlyUtilityService.getAudienceFilter(this.audience)(x));
    (parsedForm as FormlyFieldConfig[]).forEach(f => this.formlyUtilityService.patchInDataLinksIfNeededAllTheWayDown(f,
      new DataUsedByDataLinkService(
        {customerDocId: this.customerDocId,
          jobId: this.jobDocId,
          activeSiteVisitDocId : this.activeSiteVisitDocId!,
          invoiceDocId: this.formlyLineItemService.explicitInvoiceDocId})));
    (parsedForm as FormlyFieldConfig[]).forEach(f => this.formlyUtilityService.setActiveViewAndDisabledTemplateOptionsAllTheWayDown(f,"techView",disabled, this.audience,
      this.formlyUtilityService.designMode ? (this.form as AbstractControl<any>) : undefined, this.activeFormModelFirestoreDocId));

    if (explictlyRebuild) {
      console.warn("EXPLICTLY REBUILDING FORM");
      this.hardFormlyReset$.next(true);
      this.formlyUtilityService.resetSectionMappings();
      this.form = new FormGroup({});
      this.formlyFields.next([]);
      this.model = {columns: [{}],};
      this.builder.buildForm(this.form,parsedForm,this.model,this.formlyOptions );
      // this.initilizeSavingUpdatesToFirestore(this.form);
      console.log(this.form);
    }
    // If there is no tick here, the form will not be populated.
    of(null).pipe(
      delay(1),
      tap(x => console.log(parsedForm)),
      tap(() => this.formlyFields.next(parsedForm)),
      take(1),
    ).subscribe();
  }

  generateCopyOfForm(formToGenerateCopyOf :FormModelFirestore = this.formModelFirestore) : Observable<FormModelFirestore> {
    const copyOfForm = new FormModelFirestore({...formToGenerateCopyOf});
    copyOfForm.employeeLoggedInWheLastUpdated = formToGenerateCopyOf.lastUpdatedByEmployeeDocId;
    copyOfForm.previousLastUpdatedAt = formToGenerateCopyOf.lastUpdatedAt;
    copyOfForm.previousForms=[];
    return this.formModelFirestoreService.retrieveDocId(copyOfForm).pipe(
      map(() => copyOfForm)
    );
  }
  }
