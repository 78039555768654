const resourceHeaderRenderer = ({ resourceRecord }) => `
<div class="my-custom-template ${resourceRecord.displayAttributes}" resourceId=${resourceRecord.id} style="cursor: pointer;">
  <table class="schedule-header" style="width:100%; font-size: 14px;" resourceId=${resourceRecord.id}>
  <tr resourceId=${resourceRecord.id} style="border-bottom: 1px solid #d2d2d2; border-top: 1px solid #d2d2d2;">
    <td style="padding: 4px;" class="tooltip" resourceId=${resourceRecord.id} title="Click Name to View Only That Person's Schedule">${resourceRecord.name} </td>
  </tr>
  <tr resourceId=${resourceRecord.id} style="border-bottom: 1px solid #d2d2d2; border-top: 1px solid #d2d2d2;">
    <td class="tooltip" style="padding: 4px;" resourceId=${resourceRecord.id} title="Schedule Date">${resourceRecord.displayDate}</td>
  </tr>
  <tr resourceId=${resourceRecord.id} style="border-bottom: 1px solid #d2d2d2; border-top: 1px solid #d2d2d2;">
    <td style="padding: 4px;" class="tooltip" resourceId=${resourceRecord.id} title="Total Commute Time">${resourceRecord.commuteBookedOnDateDisplay} </td>
  </tr>
  </table>
  <div title="Total Work and Commute Time">
  <svg class="expand" width="150" height="20" resourceId=${resourceRecord.id} title="Total Work and Commute Time">
  <rect title="Total Work and Commute Time" class="expand" x="0%" height="25" y="0" resourceId=${resourceRecord.id}
    style="fill:${resourceRecord.colorAssociatedWithDateFullness};stroke-width:0;stroke:rgb(0,0,0);"/>
  <text x="50%" y="70%" style="text-anchor:middle" fill="white" resourceId=${resourceRecord.id} title="Total Work and Commute Time"> ${resourceRecord.timeBookedOnDateDisplay}</text>
  </svg>
  </div>
          </div>
        `;

export default resourceHeaderRenderer;


/*
const resourceHeaderRenderer = ({ resourceRecord }) => `
<div ${resourceRecord.displayAttributes}" resourceId=${resourceRecord.id}>
  <div class="schedule-grid" style="width:100%" resourceId=${resourceRecord.id}>
  <div style="width:100%" fxLayout="row" fxLayoutAlign="start" resourceId=${resourceRecord.id}>
    <div style="width:100%" resourceId=${resourceRecord.id}>${resourceRecord.name}</div>
  </div>
  <div style="width:100%" resourceId=${resourceRecord.id}>
    <div style="width:100%" resourceId=${resourceRecord.id}>${resourceRecord.displayDate}</div>
  </div>
  <div style="width:100%" resourceId=${resourceRecord.id}>
    <div style="width:100%" resourceId=${resourceRecord.id}>${resourceRecord.furthestOutStop}</div>
  </div>
  </div>
  <svg style="width:100%" class="expand" width="150" height="20" resourceId=${resourceRecord.id}>
  <rect class="expand" x="0%" height="25" y="0" resourceId=${resourceRecord.id}
    style="fill:${resourceRecord.colorAssociatedWithDateFullness};stroke-width:0;stroke:rgb(0,0,0);"/>
  <text x="50%" y="70%" style="text-anchor:middle" fill="white" resourceId=${resourceRecord.id}> ${resourceRecord.timeBookedOnDateDisplay}</text>
  </svg>
          </div>
        `;
 <tr resourceId=${resourceRecord.id}>
    <td resourceId=${resourceRecord.id}>${resourceRecord.furthestOutStop}</td>
  </tr>


export default resourceHeaderRenderer;
*/
