import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PROTOTYPE_INTENTION_ON_EDIT } from '../line-item-creation.service';

@Component({
  selector: 'app-add-edit-prototype-modal',
  templateUrl: './add-edit-prototype-modal.component.html',
  styleUrls: ['./add-edit-prototype-modal.component.scss']
})
export class AddEditPrototypeModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AddEditPrototypeModalComponent>) { }

  ngOnInit(): void {
  }

  createNewClose() {
    this.dialogRef.close({ prototypeIntentionOnEdit: PROTOTYPE_INTENTION_ON_EDIT.CREATE});
  }

  editClose() {
    this.dialogRef.close({ prototypeIntentionOnEdit: PROTOTYPE_INTENTION_ON_EDIT.UPDATE});
  }

}
