import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sv-data-display-selection',
  templateUrl: './sv-data-display-selection.component.html',
  styleUrls: ['./sv-data-display-selection.component.scss']
})
export class SvDataDisplaySelectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
