import { RetrieveFirestoreProperties } from "@database-backend/retrieve-firestore-properties";

export const defaultFirestoreDiffMapper = function(previous: RetrieveFirestoreProperties | null,
  current: RetrieveFirestoreProperties | null, memberName: string, diffType: string, containedInArray: boolean) : FirestoreDiff[] {
    // update
    if (memberName === undefined) {
      console.log(current);
      console.log(diffType);
    }

    const activeDocId = current !== null ? current.DocId() : previous.DocId();
    const retVal: FirestoreDiff = new FirestoreDiff({fieldName: memberName, dateCreated: new Date(), changeType: diffType,
                                                   activeDocId: activeDocId, associatedDocId: activeDocId});

    // update
    if (current !== null && previous !== null) {
      retVal.newValue = current[memberName];
      retVal.oldValue = previous[memberName];
      retVal.userMessage = `${covertCamelCaseToSentanceCase(memberName)} changed to ${current[memberName]}`;
    } else if (current !== null) {
    // add
      retVal.newValue = current.DocId();
      retVal.oldValue = "";
      // Below fails when we add a string to an array ( to see this, load up a workflow w/ branched sub workflows, and click on one of them and examine diff)
      retVal.userMessage = containedInArray ?  `${current.DocId()} added to ${covertCamelCaseToSentanceCase(memberName)}` :
      `${covertCamelCaseToSentanceCase(memberName)} set to ${current[memberName]}`;
    } else {
      // delete
      retVal.newValue = "";
      retVal.oldValue = previous.DocId();
      retVal.userMessage = containedInArray ?  `${previous.DocId()} removed from ${covertCamelCaseToSentanceCase(memberName)}` :
      `${covertCamelCaseToSentanceCase(memberName)} set to ${current[memberName]}`;
    }
    return [retVal];
};

export function covertCamelCaseToSentanceCase(camel: string) {
  const result = camel.replace( /([A-Z])/g, " $1" );
  return result.charAt(0).toUpperCase() + result.slice(1);
}


export class FirestoreDiff implements RetrieveFirestoreProperties {

  public constructor(init?: Partial<FirestoreDiff>) {
  Object.assign(this, init);
  this.temporaryGuid = Math.random().toString(36).substring(0,14);
  }
  retrieveFirestoreDenormalizedMemberNames(): string[] {
    return [];
  }

  lazyLoaded: boolean = false;
  lastUpdatedAt: Date;
  lastUpdatedByGuid: string;
  appVersionUpdate: string;
  appVersionCreate: string;
  lastUpdatedByEmployeeDocId: string = "";

  updateRequired: boolean = false;
  updatePrincipalRequired: boolean = false;
  updatedThisCycle: boolean = false;
  createdObject: boolean = false;
  createdAt: Date = new Date();

  activeDocId: string;
  associatedDocId: string;
  previousDocId: string;

  changeType: string;
  dateCreated: Date;
  employeeInitiatingChangeDocId: string | null;
  fieldName: string;
  bucketId: string;

  firestoreDiffDocId: string;
  userMessage: string;
  uncommitedDocId: boolean = false;
  emisssionGuid: string = "";
  immutable: boolean = true;

  private _newValue: string | null;
  get newValue(): string | null {
    return this._newValue;
  }
  set newValue(value: string | null) {
    this._newValue = value === undefined ? null : value;
  }

  private _oldValue: string | null;
  get oldValue(): string | null {
    return this._oldValue;
  }
  set oldValue(value: string | null) {
    this._oldValue = value === undefined ? null : value;
  }

  static _firestoreIgnoredMemberNames = ["firestoreIgnoreDiffTrackingMembers", "firestoreCompositionMemberNames",
  "firestoreCompositionalDiffMemberNames", "generateFirestoreDeltas", "firestoreIgnoredMemberNames", "temporaryGuid", "uncommitedDocId",
  "freshObservableRequred", "updateRequired", "lazyLoaded", "updatedThisCycle"];

  static _firestoreIgnoreDiffTrackingMembers = FirestoreDiff._firestoreIgnoredMemberNames;
  static _firestoreCompositionMemberNames = [];
  static _firestoreCompositionalDiffMemberNames: string[] = [];

  retrieveFirestoreIgnoredMemberNames() : string[] { return FirestoreDiff._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return FirestoreDiff._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return FirestoreDiff._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return FirestoreDiff._firestoreCompositionMemberNames;}

  temporaryGuid: string;

  SetDocId(docId: string): void {
    this.firestoreDiffDocId = docId;
  }

  DocId(): string {
    return this.firestoreDiffDocId;
  }

  createFirestoreDiff(previous: RetrieveFirestoreProperties | null, current: RetrieveFirestoreProperties | null,
    memberName: string, diffType: string, containedInArray: boolean): FirestoreDiff[] {
    return defaultFirestoreDiffMapper(previous, current, memberName, diffType, containedInArray);
  }

}
