import { Component,  OnDestroy,  OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { CommunicationPreferenceModalComponent } from './communication-preference-modal/communication-preference-modal.component';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { GenericServiceProviderSetting } from '../../../../../common/src/data/dao/generic-service-provider-setting';
import { CompanySettings } from '../../../../../common/src/data/dao/company-settings';
import { randomElementName } from '../../../../../common/src/util/util';
import { CustomerCommunicationCategory, CustomerCommunicationTemplate } from '../../../../../common/src/data/dao/customer-communication-template';
import { CustomerCommunicationScheduling } from '../../../../../common/src/data/dao/customer-communication-scheduling';
import { CustomerCommunicationTemplateService } from '../../../../../common/src/data/dao-services/customer-communication-template.service';
import { CustomerCommunicationSchedulingService } from '../../../../../common/src/data/dao-services/customer-communication-scheduling.service';
import { GenericServiceProviderSettingService } from '../../../../../common/src/data/dao-services/generic-service-provider-setting.service';
import { CompanySettingsService } from '../../../../../common/src/data/dao-services/company-settings.service';

@Component({
  selector: 'app-communication-preference-settings',
  templateUrl: './communication-preference-settings.component.html',
  styleUrls: ['./communication-preference-settings.component.scss']
})
export class CommunicationPreferenceSettingsComponent implements OnInit, OnDestroy {

  destroyingComponent$ = new Subject();
  customerTypeForm: UntypedFormGroup;
  allCustomerTypes: BehaviorSubject<GenericServiceProviderSetting[]>;
  activeCustomerTypes$: Observable<GenericServiceProviderSetting[]>;
  inactiveCustomerTypes$: Observable<GenericServiceProviderSetting[]>;
  customerTypesToDisplay$: Observable<GenericServiceProviderSetting[]>;
  triggerEstimateAndInvoiceWithWorkflowSend: boolean = true;
  companySettings: CompanySettings;

  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }


  dataLinkDialogRef: MatDialogRef<CommunicationPreferenceModalComponent>;
  customerTemplatesGroupedByCatagory : ReplaySubject<{ key:CustomerCommunicationCategory, values: CustomerCommunicationTemplate[] }[]> =
    new ReplaySubject<{ key:CustomerCommunicationCategory, values: CustomerCommunicationTemplate[] }[]>(1);
  customerCommunicationSchedules: CustomerCommunicationScheduling[] = [];

  get customerCommunicationTemplatesGroupedByType(): Observable<{ key:CustomerCommunicationCategory, values: CustomerCommunicationTemplate[] }[]> {

    return this.customerCommuncationTemplateService.loadAll$().pipe(
      map(customerCommunicationTemplates => (customerCommunicationTemplates as CustomerCommunicationTemplate[]).map(x=>x)),
      map(customerCommunicationTemplates => {
        const retVal : { key:CustomerCommunicationCategory, values: CustomerCommunicationTemplate[] }[] = [];
        customerCommunicationTemplates.reduce((accumulator, currentValue) => {
          if (!accumulator[currentValue.communicationCatagory]) {
            accumulator[currentValue.communicationCatagory] = [];
            retVal.push({key: currentValue.communicationCatagory, values: accumulator[currentValue.communicationCatagory]});
          }
          accumulator[currentValue.communicationCatagory].push(currentValue);
          return accumulator;
        }, []);
        return retVal;
      }),
    );
  }

  constructor(public customerCommuncationTemplateService: CustomerCommunicationTemplateService,
    private customerCommunicationSchedulingService: CustomerCommunicationSchedulingService,
    private dialog: MatDialog, private fb: UntypedFormBuilder, private genericServiceProviderSettingService: GenericServiceProviderSettingService,
    private companySettingsService: CompanySettingsService, private settingsService: SettingsService) {
      this.customerCommunicationSchedulingService.loadAll$().pipe(take(1)).subscribe();

      this.customerTypeForm = this.buildCustomerForm();
  }

  Cancel() : void {
    this.customerTypeForm.reset();
  }

  Save() : void {
    if (this.customerTypeForm.valid) {
      const customerTypeToUpdate = this.customerTypeForm.value.customerType;
      customerTypeToUpdate.name = this.customerTypeForm.value.customerTypeName;
      customerTypeToUpdate.active = this.customerTypeForm.value.customerTypeActive;
      this.genericServiceProviderSettingService.update$(customerTypeToUpdate).pipe(take(1)).subscribe();
      this.customerTypeForm.reset();
    } else {
      this.customerTypeForm.markAllAsTouched();
    }
  }

  buildCustomerForm() : UntypedFormGroup {
    const retVal = this.fb.group({
      customerType:[],
      customerTypeName:["",Validators.required],
      customerTypeActive: [],
      showInactiveCustomerTypes: [false],
    });

    retVal.controls["customerType"].valueChanges.pipe(
      filter(x => x != null),
      tap(x => retVal.patchValue({customerTypeActive: x.active, customerTypeName: x.name})),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    retVal.controls["showInactiveCustomerTypes"].valueChanges.pipe(
      tap(x =>
        this.customerTypesToDisplay$ = x ? this.inactiveCustomerTypes$ : this.activeCustomerTypes$),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    return retVal;
  }


  addNewCustomerType() : void {
      this.customerTypeForm.reset();
      const j = new GenericServiceProviderSetting({name: '', active: true, createdOn: new Date(),parentCollectionName: "customer_types"});
        this.customerTypeForm.patchValue({customerType: j});
    }

  ngOnInit(): void {

    this.companySettingsService.loadAll$().pipe(
      filter(x=>x.length > 0),
      map(x => x[0]),
      tap(x => this.companySettings = x),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.allCustomerTypes = this.genericServiceProviderSettingService.allCustomerTypes;

    this.activeCustomerTypes$ = this.allCustomerTypes.pipe(
      map(x => x.filter(y => y.active)),
    );

    this.inactiveCustomerTypes$ = this.allCustomerTypes.pipe(
      map(x => x.filter(y => !y.active)),
    );

    this.customerTypesToDisplay$ = this.activeCustomerTypes$;

    this.customerCommunicationTemplatesGroupedByType.pipe(
      takeUntil(this.destroyingComponent$))
      .subscribe(x=>this.customerTemplatesGroupedByCatagory.next(x));

     this.customerCommunicationSchedulingService.loadAll$().pipe(
       takeUntil(this.destroyingComponent$)
       ).subscribe(x=>this.customerCommunicationSchedules = x);
  }

  toggleEnabled(customerCommuncationTemplate: CustomerCommunicationTemplate) {
    this.customerCommuncationTemplateService.update$(customerCommuncationTemplate).pipe(take(2)).subscribe();
  }

  customerCommunicationCategoryStringRepresentation(customerCommunicationCategory: CustomerCommunicationCategory) : string {
    switch (customerCommunicationCategory) {
      case CustomerCommunicationCategory.PAYMENT:
        return 'Payment';
      case CustomerCommunicationCategory.APPOINTMENT:
        return 'Appointment';
      case CustomerCommunicationCategory.ESTIMATE:
        return 'Estimate';
      case CustomerCommunicationCategory.WORK_PERFORMED:
        return 'Work Performed';
      default:
        return 'Other';
    }
  }

  toggletriggerEstimateAndInvoiceWithWorkflow() {
    this.companySettingsService.update$(this.companySettings).pipe(
      take(1)
    ).subscribe();
  }

  editTemplate(customerCommuncationTemplate: CustomerCommunicationTemplate) {
    const editorConfig = new MatDialogConfig();

    let sched = this.customerCommunicationSchedules.find(x=>x.customerCommunicationTemplateDocId === customerCommuncationTemplate.DocId());
    if (!sched) {
      sched = new CustomerCommunicationScheduling({customerCommunicationTemplateDocId: customerCommuncationTemplate.DocId()});
    }
    Object.assign(editorConfig, {
      data: {customerCommuncationTemplate: customerCommuncationTemplate, customerCommunicationScheduling:  sched}
    });

    this.dataLinkDialogRef = this.dialog.open(CommunicationPreferenceModalComponent, editorConfig);
  }
}
