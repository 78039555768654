<ion-app>
  <ion-header translucent>
    <ion-toolbar>
      <ion-searchbar [debounce]="200" (ionChange)="searchItemChangeEvent($event)" (ionFocus)="searchBarFocus($event)" (ionBlur)="searchBarBlur($event)"></ion-searchbar>
    </ion-toolbar>
    <ion-toolbar *ngIf="displaySearchResults" class="autoCompleteList">
      <!-- <ion-content class="autoCompleteList"> -->
      <cdk-virtual-scroll-viewport itemSize="47" minBufferPx="900" maxBufferPx="1350">
          <ion-list>
              <ion-item *cdkVirtualFor="let lineItem of filteredLineItems()">
                <ion-label *ngIf="lineItem.DocId() === undefined else standardBlock" style="color:red;" (click)="selectItem(lineItem)">
                  {{lineItem.title}}
                </ion-label>
                <!-- styling for the standard prototypical line item goes here. -->
                <ng-template #standardBlock>
                  <ion-label (click)="selectItem(lineItem)">
                    {{lineItem.title}}
                  </ion-label>
                </ng-template>
              </ion-item>
            </ion-list>
        </cdk-virtual-scroll-viewport>
      <!-- </ion-content> -->
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <div [formGroup]="form">
      <ion-item>
        <ion-label>Title:</ion-label>
        <ion-input formControlName="title" auto-grow="true"></ion-input>
      </ion-item>
      <div *ngIf="form.controls['title'].errors && (form.controls['title'].dirty || form.controls['title'].touched)">
        <div *ngIf="form.controls['title'].errors['lineItemNameDistinct']; else generalTitleErrorBlock">
          <p  class="danger" padding>Line item titles must be distinct</p>
        </div>
        <ng-template #generalTitleErrorBlock>
          <p  class="danger" padding>Title is required and must be at least 3 characters.</p>
        </ng-template>
      </div>
      <ion-item>
        <ion-label>Description:</ion-label>
        <ion-textarea formControlName="description" auto-grow="true"></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-label>Estimated Days (12h)</ion-label>
        <ion-input formControlName="daysLineItemDuration" auto-grow="true" inputmode="numeric"
        type="number" min="0" step="1" oninput="validity.valid||(value='');"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Estimated Hours and Minutes:</ion-label>
        <ion-datetime presentation="time" hourValues="0,1,2,3,4,5,6,7,8,9,10,11" minuteValues="0,5,10,15,20,25,30,35,40,45,50,55" locale="es-ES" formControlName="singleQuantityDurationAsTime"></ion-datetime>
      </ion-item>
      <p *ngIf="form.controls['singleQuantityDurationAsTime'].errors && (form.controls['singleQuantityDurationAsTime'].dirty || form.controls['singleQuantityDurationAsTime'].touched)" class="danger" padding>Estimated duration to complete line item must be set and must be >= 0</p>
      <ion-item>
        <ion-label>Duration:</ion-label>
        <ion-input readonly=true formControlName="expectedDurationOneLineItemString"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Unit Price: </ion-label>
        <ion-input formControlName="box_price_formatted" placeholder="$0.00" inputmode="decimal" (change)='onChangePrice($event.target)' (keyup)="onPriceUp($event.target)"></ion-input>
      </ion-item>
      <p *ngIf="form.controls['price'].errors && (form.controls['price'].dirty || form.controls['price'].touched)" class="danger" padding>Price is required must be >= 0</p>
      <ion-item>
        <ion-label>Quantity: </ion-label>
        <ion-input formControlName="quantity" inputmode="number"></ion-input>
      </ion-item>
      <p *ngIf="form.controls['quantity'].errors && (form.controls['quantity'].dirty || form.controls['quantity'].touched)" class="danger" padding>Quantity must be >= 1</p>
      <ion-item>
        <ion-label>Total Duration:</ion-label>
        <ion-input readonly=true formControlName="totalExpectedDurationString"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Total Price: </ion-label>
        <ion-input formControlName="totalPrice" placeholder="$0.00" readonly=true></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Internal Notes:</ion-label>
        <ion-textarea formControlName="internalNotes" auto-grow="true"></ion-textarea>
      </ion-item>
  </div>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <ion-buttons slot="end">
        <button style="border-radius: 4px; min-width: 80px;" mat-button class="button-blue" type="submit" (click)="save()">Save</button>
      </ion-buttons>
      <ion-buttons slot="start">
        <button style="border-radius: 4px; min-width: 80px;" mat-button class="button-gray" (click)="exit()">Cancel</button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</ion-app>

<!-- <ion-button class="button-blue" type="submit" (click)="save()">Save</ion-button>
      </ion-buttons>
      <ion-buttons slot="start">
        <ion-button class="button-gray" (click)="exit()">Cancel</ion-button> -->

