import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export enum DiscountType {
  PERCENTAGE = "Percentage",
  FIXED = "Fixed",
}

export enum DiscountCatagory {
  MEMBERSHIP = "Membership",
  REFUND = "Refund",
  PROMOTION = "Promotion",
  OTHER = "Other",
}

export class Discount extends RetrieveFirestorePropertiesBase<Discount>  {

static DiscountCatagories = [DiscountCatagory.MEMBERSHIP, DiscountCatagory.REFUND, DiscountCatagory.PROMOTION, DiscountCatagory.OTHER];
static DiscountTypes = [DiscountType.PERCENTAGE, DiscountType.FIXED];

discountType: DiscountType;
discountCharacterization: DiscountCatagory;
discountPercentage: number | null = null;
discountFixedAmount: number | null = null;
title: string = "";
description: string = "";
internalNotes: string = "";

addToActiveJob: boolean = false;
calculatedDiscountAmount: number = 0;
addedFromEstimate: boolean = false;

setCalculatedDiscountAmount(totalBeforeDiscount: number) {
  if (this.discountType === DiscountType.PERCENTAGE) {
    if (totalBeforeDiscount === 0) {
      this.calculatedDiscountAmount = 0;
    } else {
      this.calculatedDiscountAmount = Math.round(-totalBeforeDiscount * (this.discountPercentage / 100) * 100) / 100;
    }
  } else if (this.discountType === DiscountType.FIXED) {
    this.calculatedDiscountAmount = -this.discountFixedAmount;
  }
}

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["calculatedDiscountAmount","addToActiveJob"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["calculatedDiscountAmount","addToActiveJob"]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return Discount._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Discount._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Discount._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Discount._firestoreCompositionMemberNames;}

public constructor(init?: Partial<Discount>) {
super();
Object.assign(this, init);
}
}
