import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { JobAttachmentsComponent } from './job-attachments.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatSnackBarModule,
    DatePipe
  ],

  declarations: [JobAttachmentsComponent],
})

export class JobAttachmentsModalModule { }
