import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DiscountService } from '../../../../../common/src/data/dao-services/discount.service';
import { Discount, DiscountType } from '../../../../../common/src/data/dao/discount';
import { startWith,filter,tap, takeUntil } from 'rxjs/operators';
import { map, merge, Observable, of, Subject, take } from 'rxjs';

@Component({
  selector: 'app-discount-creation-ionic',
  templateUrl: './discount-creation-ionic.component.html',
  styleUrls: ['./discount-creation-ionic.component.scss']
})
export class DiscountCreationIonicComponent implements OnInit {

  form: UntypedFormGroup;
  @Input() passedInDiscount: Discount;
  discountGeneratedFromInitialFormValue: Discount;

  discountCatagoryValidationCheck: boolean = false;
  discountCatagoryEntryAlertDismissed: boolean = false;

  discountTypeValidationCheck: boolean = false;
  discountTypeEntryAlertDismissed: boolean = false;

  constructor(public modalController: ModalController, private discountService: DiscountService, private fb: UntypedFormBuilder) { }

  get discountCatagories() { return Discount.DiscountCatagories;}
  get discountTypes() { return Discount.DiscountTypes; }


  destroyingComponent$ = new Subject();
  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  ngOnInit(): void {

    this.form = this.buildForm();
    if (this.passedInDiscount) {
      this.populateFormFromPassedInDiscount(this.passedInDiscount);
    }

    const percentDisc = this.form.get("discountType").valueChanges.pipe(
      startWith(this.form.get("discountType").value),
      filter(x=> x === DiscountType.PERCENTAGE),
      tap(() => {
        this.form.get("discountPercentageDisplay").enable();
        this.form.get("discountFixedAmount").disable();
      }));

    const fixedDisc = this.form.get("discountType").valueChanges.pipe(
      startWith(this.form.get("discountType").value),
      filter(x=> x === DiscountType.FIXED),
      tap(() => {
        this.form.get("discountPercentageDisplay").disable();
        this.form.get("discountFixedAmount").enable();
      }));

    merge(percentDisc,fixedDisc).pipe(takeUntil(this.destroyingComponent$)).subscribe();
  }

  dismissDiscountCatagory() {
    this.discountCatagoryEntryAlertDismissed = true;
    this.discountCatagoryValidationCheck = true;
  }

  blurDiscountCatagory() {
    if (this.discountCatagoryEntryAlertDismissed) {
      this.discountCatagoryValidationCheck = true;
    }
  }

  focusDiscountCatagory() {
    if (!this.discountCatagoryEntryAlertDismissed === true) {
      this.discountCatagoryValidationCheck=false;
    }
  }


  dismissDiscountType() {
    this.discountTypeEntryAlertDismissed = true;
    this.discountTypeValidationCheck = true;
  }

  blurDiscountType() {
    if (this.discountTypeEntryAlertDismissed) {
      this.discountTypeValidationCheck = true;
    }
  }

  focusDiscountType() {
    if (!this.discountTypeEntryAlertDismissed === true) {
      this.discountTypeValidationCheck=false;
    }
  }

  buildForm() : UntypedFormGroup {
    return this.fb.group({
      discountType: ["",[Validators.required]],
      discountCatagory: ["",[Validators.required]],
      discountPercentage: [],
      discountPercentageDisplay: [{value: '', disabled: true}],
      discountFixedAmount: [{value: '', disabled: true}],
      title: ["",[Validators.required,Validators.minLength(3)]],
      description: [],
      internalNotes: [],
    },{validators: this.discountAmountFilledOut});
  }

  discountAmountFilledOut: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const discountType = control.get("discountType").value;
    if (discountType === DiscountType.PERCENTAGE) {
      const discountPercentage = `${control.get("discountPercentageDisplay").value}`;
      return (discountPercentage === undefined || discountPercentage === null || discountPercentage.trim()  === "" || discountPercentage.match(/[0-9]/g) === null ) ? { "discountPercentage": true } : null;
    }
    if (discountType === DiscountType.FIXED) {
      const discountFixedAmount = control.get("discountFixedAmount").value;
      return (discountFixedAmount === undefined || discountFixedAmount === null || discountFixedAmount === " ") ? { "discountFixedAmount": true } : null;
    }
    return null;
  };

  populateFormFromPassedInDiscount(d: Discount){
    this.form.patchValue({ discountType: d.discountType, discountCatagory: d.discountCharacterization,
      title: d.title, description: d.description, internalNotes: d.internalNotes,
      discountPercentage: d.discountPercentage, discountPercentageDisplay : `${d.discountPercentage}`, discountFixedAmount: d.discountFixedAmount});
    this.getDiscountFromForm().pipe(
      tap(d => this.discountGeneratedFromInitialFormValue = d),
      take(1)
    ).subscribe();
  }

  getDiscountFromForm() : Observable<Discount> {
    const discount = new Discount();
    discount.discountType = this.form.get("discountType").value;
    discount.discountCharacterization = this.form.get("discountCatagory").value;
    let discountParsing = ((this.form.get("discountPercentageDisplay").value as string).replace(/[^0-9.]/g, ''));
    if (discountParsing.length > 2) {
      discountParsing = discountParsing.replace(".","");
      discountParsing = discountParsing.substring(0,2).concat(".").concat(discountParsing.substring(2));
    }
    discount.discountPercentage = parseFloat(discountParsing);
    discount.discountFixedAmount = this.form.get("discountFixedAmount").value;
    discount.title = this.form.get("title").value;
    discount.description = this.form.get("description").value;
    discount.internalNotes = this.form.get("internalNotes").value;
    if (JSON.stringify(this.discountGeneratedFromInitialFormValue) === JSON.stringify(discount)) {
      return of(this.passedInDiscount);
    } else {
      return this.discountService.retrieveDocId(discount).pipe(
        map(() => discount)
      );
    }
  }

  save(): void {
    if (this.form.valid) {
      this.getDiscountFromForm().pipe(
        tap(d => {
          this.modalController.dismiss({
            'dismissed': false,
            'discount' : d});
        }),
        take(1)
      ).subscribe();
    } else {
      this.form.markAllAsTouched();
    }
}

exit() : void {
  this.modalController.dismiss({
    'dismissed': true
  });
}

}
