import { Injectable, Optional } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from '../../util/authentication.service';
import { LocalSettingsService } from '../../../../web-app/src/app/settings/local-settings.service';
import { LoggingService } from './logging.service';
import { LogEntry } from './logEntry';

@Injectable({
  providedIn: 'root'
})
export class LoggingBackendService {

  platform: Platform;

  constructor(private logger: NGXLogger, platform: Platform, private authenticationService: AuthenticationService, private localSettingsService: LocalSettingsService,
    private loggingService: LoggingService) {
    this.platform = platform;
  }

  public startLogging() {
    this.loggingService.logEntryToAdd.subscribe(l => this.addLog(l));
  }


  private addLog(l: LogEntry) {

    l.version = this.localSettingsService.appVersion;
    l.app = this.localSettingsService.app;
    const emp = this.authenticationService.activelyLoggedInEmployee;
    if (emp) {
      l.userDocId = emp.DocId();
      l.userName = emp.name;
    }

    // Get the current config
    var config = this.logger.getConfigSnapshot();
    // Update to log config ( where calls are batched and queued)
    if (config.serverCallsTimer !== 3000) {
      config.serverCallsTimer = 3000;
      config.serverCallsBatchSize = 10;
      // config.serverLoggingUrl= 'http://localhost:5001/service-vanguard/us-central1/ml-logMobile';
      config.serverLoggingUrl= 'https://us-central1-service-vanguard.cloudfunctions.net/ml-logMobile';
      // Setting the config
      this.logger.updateConfig(config);
    }


    this.submitLog(l);
  }

  private submitLog(l: LogEntry, errorCount: number = 0) {
    try {
      this.logger.log(l.message, l, l.logType, l.origin !== null ? l.origin : 'Logging Service');
    }
    catch (e) {
      if (errorCount < 5) {
        setTimeout(() => {
          this.submitLog(l, errorCount + 1);
        }, errorCount * 1000);
      } else {
        throw e;
      }
    }
  }
}
