<!-- <div style="display:none">{{to.changeDetect|async}}</div> -->
<h3 class="donotprint">{{to.label}}</h3>
<!-- Control functions differently in print view compared to technician view. -->
<div *ngIf="to.activeView==='printView'">
  <div class="image-container">
    <div *ngFor="let image of imagePlaceholders | async; index as i" >
      <img src={{placeholderImageLocation}} height= "340">
      <!-- When printing out placeholder images ( print view proper )
      <div [style.height.px]="340"></div> -->
    </div>
  </div>
</div>

<div *ngIf="to.activeView === 'techView'">
  <h5 class="donotprint">Min # {{to.minimumPhotoCount}}</h5>
  <h5 class="donotprint">Max # {{to.maximumPhotoCount}}</h5>


<!-- <div class="image-container"> -->
  <div *ngIf="(pictureCount | async) > 0; then picturesTakenBlock else picturePlaceholderBlock"></div>
  <ng-template #picturesTakenBlock style="break-inside: avoid;">
      <div *ngFor="let p of resizedIphotos | async" class="image-layout" trackBy:photoTrack>
      <div class="image-position" style="break-inside: avoid;">
      <img [src]= "this.bypassSantizationResourceUrl(p.photo.localWebviewPath)" height= {{p.height}} (click)= presentModal()>
      </div>
    </div>
  </ng-template>
  <ng-template #picturePlaceholderBlock>
    <img src={{placeholderImageLocation}} (click)= presentModal() height= "340" style="width: 340px;" class="remove-camera-image" >
  </ng-template>
<!-- </div> -->
</div>
