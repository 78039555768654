  <form [formGroup]="form" class="container1">
  <!--START OF JOB TYPE-->
  <div class="left-side"></div>
  <div class="middle">
    <div class="employee-grid" style="margin-bottom: 30px;">
    <div class="header-label">Job Types</div>
    <div class="label-style">Job Type</div>
    <div style="display: flex;">
    <div style="width: 330px;">
      <mat-form-field appearance="outline" class="form-align" style="width: 330px;">
            <mat-select formControlName="jobType" placeholder="Job Type" [compareWith]="compareJobTypes" >
            <mat-option *ngFor="let jobType of jobTypesToDisplay$ | async" [value]="jobType" >
              {{jobType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <button type="button" mat-button style="margin-left: 30px !important;" class="button-green" (click)="addNewJobType()">Add New Job Type</button>
        </div>
        <div style="grid-column: 2/3; display: flex;">
        <div [formGroup]="formDisplayInactives">
           <mat-checkbox formControlName="showAllJobTypes">
             Show inactive Job Types
            </mat-checkbox>
        </div>
        <div style="width: 100%; margin-left: 20px;">
          <mat-checkbox formControlName="active">Active</mat-checkbox>
        </div>
    </div>
<!--START OF JOB NAME-->
<div class="label-style">Job Type Name</div>
<div style="width: 100%;">
  <mat-form-field appearance="outline" class="medium-field">
    <input formControlName="name" matInput placeholder ="Name"
    [name]="ElementNameForId('195159')" autofill='off' autocomplete='new-password' data-lpignore='true'>
  </mat-form-field>
</div>
<!--END OF JOB NAME-->
  <!--START OF JOB PRIORITY-->
    <div class="label-style">Job Priority</div>
    <div style="display: flex;">
    <div class="medium-field">
      <mat-form-field appearance="outline" class="form-align">
            <mat-select formControlName="jobPriority" placeholder="Job Priority" [compareWith]="compareCommonServiceProviderSettings" >
            <mat-option *ngFor="let jobPriority of jobPrioritiesToDisplay$ | async" [value]="jobPriority" >
              {{jobPriority.name}}
            </mat-option>
          </mat-select>
          <mat-error>Job Priority must be selected or created.</mat-error>
        </mat-form-field>
        </div>
        <button type="button" mat-button style="margin-left: 30px !important;" class="button-green" (click)="addNewJobPriority()">Add New Job Priority</button>
        </div>
        <div style="grid-column: 2/3; display: flex;">
          <div [formGroup]="formDisplayInactives">
          <mat-checkbox formControlName="showAllJobPriorities">
            Show inactive Job Priorities
          </mat-checkbox>
          </div>
          <div style="width: 100%; margin-left: 20px;">
          <mat-checkbox formControlName="jobPriorityActive">Active</mat-checkbox>

  </div>
    </div>
    <!--START OF JOB PRIORITY NAME-->
<div class="label-style">Job Priority Name</div>
<div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
  <mat-form-field appearance="outline" class="medium-field">
    <input formControlName="jobPriorityName" matInput placeholder ="Name"
    [name]="ElementNameForId('362687')" autofill='off' autocomplete='new-password' data-lpignore='true'>
  </mat-form-field>
</div>
<!--END OF JOB PRIORITY  =NAME-->
  <!--END OF JOB PRIORITY-->

  <!-- START OF JOB TYPE ASSOCIATED COLOR-->
  <div class="label-style">Job Color</div>
  <div class="medium-field">
    <mat-form-field appearance="outline" class="form-align">
          <mat-select formControlName="color" placeholder="Choose Color">
          <mat-option *ngFor="let color of jobTypeService.jobTypeColors" [value]="color" >
            {{color}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
  <!-- END OF JOB TYPE ASSOCIATED COLOR-->
  <!--START OF PRICING METHODOLOGY ASSOCIATED WITH JOB TYPE-->
  <app-pricing-methodology-settings
  [pricingMethodologySettingsForm]="formPricingMethodology"
  ></app-pricing-methodology-settings>
  <!--END OF PRICING METHODOLOGY ASSOCIATED WITH JOB TYPE-->

  <!--START OF LINE ITEM SECTION-->
  <div class="header-label" style="height: 80px; border-top: 2px solid gainsboro;">Default Line Items</div>
  <div class="section" style="border: none; grid-column: 1/3;">
    <div class="section">
        <div class="section-content">
          <div class="line-items" style="padding: 30px 0 30px 0;">
            <app-line-item-display
            [disabled]="jobType === undefined"
            [lineItems]="lineItems$"
            [lineItemCreationMode] = 1
            (addLineItem$)="addLineItem$.next($event)"
            (editLineItem$)="editLineItem$.next($event)"
            (removeLineItem$)="removeLineItem$.next($event)"
            >
          </app-line-item-display>
          </div>
        <div>
      </div>
    </div>
  </div>
</div>
  <!--END OF LINE ITEM SECTION-->

 <!-- Cancel / Submit buttons. -->
<div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
  <div class="button-position">
    <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
  </div>


  <div class="button-position">
    <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
  </div>

  </div>
</div>

  <!--END OF JOB TYPE-->
  </div>
  <div class="right-side"></div>
</form>


