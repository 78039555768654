import { Injectable } from '@angular/core'
;
import { AuthenticationService } from '../../util/authentication.service'
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { EmployeeAvailabilityService } from './employee-availability.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { SchedulerSettings } from '../dao/scheduler-settings';

@Injectable({
  providedIn: 'root'
})
export class SchedulerSettingsService extends DatabaseStoreService<SchedulerSettings> {

  populateInitial() {
    const a = new SchedulerSettings();
    this.create$(a).subscribe();
  }

  constructor( fs: SchedulerSettingsFirestoreService, authenticationService: AuthenticationService, store: SchedulerSettingsStoreService, private employeeAvailibilityService: EmployeeAvailabilityService) {
super(fs, store, authenticationService, new Map([
  ["workWeek", {associatedDocumentId: "employeeAvailabilityDocIds", compositionStoreService: employeeAvailibilityService}]]));
  }
}

@Injectable({
  providedIn: 'root'
})
export class SchedulerSettingsStoreService extends StateChangeStoreService<SchedulerSettings> {
  protected store = "SchedulerSettings-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, SchedulerSettings>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class SchedulerSettingsFirestoreService extends FirestoreBackend<SchedulerSettings> {

 protected basePath = "SchedulerSettings";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): SchedulerSettings {
return new SchedulerSettings(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new SchedulerSettings(), authService);
 }
}
