import { Injectable} from '@angular/core';
import {  catchError, delay, filter, map, mergeMap, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddressRoutingService } from '../../../common/src/data/dao-services/address-routing.service';
import {  delayWhen, from,  merge,  Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { SettingsService } from './settings/settings.service';
import { AuthenticationService } from '../../../common/src/util/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Address } from '../../../common/src/data/dao/address';
import { AddressRouting } from '../../../common/src/data/dao/address-routing';
import { AddressService } from '../../../common/src/data/dao-services/address.service';
import { EmployeeService } from '../../../common/src/data/dao-services/employee.service';
import { DexieCacheService } from '../../../common/src/data/dao-services/dexie-cache.service';
import { where } from 'firebase/firestore';
import { startOfDay, subDays } from 'date-fns';
import { AddressRoutingLocal, db } from './db';
import { CompanyLocation } from '../../../common/src/data/dao/company-location';
import { CompanyLocationService } from '../../../common/src/data/dao-services/company-location.service';

@Injectable({
  providedIn: 'root'
})
export class PhysicalAddressRoutingService {

  generatedCommuteMatrixResultsToShop$ = new Subject<string>();
  addressPopulatedFromGeocoderResults$ = new Subject<Address>();
  addressNeedsPopulatedToSchedule$ = new Subject<Address>();
  addressDocIdsToAlwaysConsider: string[] = [];
  addressesToAlwaysConsider: Address[] = [];
  addressesToAlwaysConsiderLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  officeSchedulingFromAddress: Address;
  officeLocations: CompanyLocation[] = [];


  orginAddressIdToAssociatedCommutes = new Map<string, Map<string, AddressRouting>>();
  generateCommuteMatrixForAddressWithRetryCount$ = new Subject<{address: Address, retryCount: number}>();
  addressesOriginationDocIdsRetrievedOrRetrieving: Set<string> = new Set();
  addressesOriginationDocIdsRetrievedForScheduler: Set<string> = new Set();
  addressesSentToCommuteMatrixGeneration: Set<string> = new Set();

  constructor(public addressRoutingService: AddressRoutingService,
    private addressService: AddressService, private employeeService: EmployeeService,
    private settingsService: SettingsService, private auth: AuthenticationService, private http: HttpClient,
    private dexieCacheService: DexieCacheService, private companyLocationService: CompanyLocationService) {


      // This should be refactored at some point, but currently the commute matrix is retrieved from this service, but additions to
      // commute matrix are made in the address routing service.
      this.addressRoutingService.addCommuteMatrix = this.addToCommuteMatrix.bind(this);
      this.settingsService.settingsLoaded$.pipe(
        switchMap(() => this.companyLocationService.loadAll$()),
        filter(x => x.length > 0 && x.find(x => x.active && x.default) !== undefined),
        tap(x => {
          this.officeLocations = x;
          this.officeSchedulingFromAddress = x.find(x => x.active && x.default).dispatchAddress;
        }),
        switchMap(() => this.employeeService.loadAll$()),
        filter(x=>x.length > 0),
        tap(x => {
          if (this.settingsService.getValue("dispatchAddress") !== undefined) {
          this.addressDocIdsToAlwaysConsider = [...new Set([(this.settingsService.getValue("dispatchAddress").DocId() as string)].concat(
            this.officeLocations.filter(x=>x.active).map(x => x.dispatchAddressDocId)).concat(
            x.filter(z=>z.scheduleFieldCallsFor && z.active).map(x => x.dispatchDestinationAddressDocId).concat(
            x.filter(z=>z.scheduleFieldCallsFor && z.active).map(x => x.dispatchOrginAddressDocId))))].filter(x=>x!==undefined);
          } else {
            this.addressDocIdsToAlwaysConsider = [...new Set(this.officeLocations.filter(x=>x.active).map(x => x.dispatchAddressDocId).concat(
              x.filter(z=>z.scheduleFieldCallsFor && z.active).map(x => x.dispatchDestinationAddressDocId).concat(
              x.filter(z=>z.scheduleFieldCallsFor && z.active).map(x => x.dispatchOrginAddressDocId))))].filter(x=>x!==undefined);
          }
        }),
      switchMap(() => this.addressService.loadMultiple$(this.addressDocIdsToAlwaysConsider)),
        filter(x=>x.length > 0),
        tap(x=> this.addressesToAlwaysConsider = x),
        tap(() => this.addressesToAlwaysConsiderLoaded$.next(true)),
        // tap(x => console.log(x,` string`)),
      ).subscribe();

    this.addressPopulatedFromGeocoderResults$.pipe(
      tap(x => console.log("HEREHERHERH")),
      switchMap(x => this.addressService.update$(x).pipe(
        take(1),
        tap(x => this.generateCommuteMatrixForAddressWithRetryCount$.next({address: x, retryCount: 0 })),
      )),
      takeUntil(this.addressService.destroyingComponent$),
      ).subscribe();

      const addressesWithCommuteMatrixGenerationNeeds = this.addressNeedsPopulatedToSchedule$.pipe(
        filter(x => !this.addressesSentToCommuteMatrixGeneration.has(x.DocId())),
        map(x => {
          return {address: x, retryCount: 0 };
        }),
        tap(x => this.addressesSentToCommuteMatrixGeneration.add(x.address.DocId())),
      );

    const sendToMicroservice = merge(this.generateCommuteMatrixForAddressWithRetryCount$,addressesWithCommuteMatrixGenerationNeeds).pipe(
        mergeMap(x => this.addressRoutingService.removeAddressFromDexie(x.address).pipe(
          tap(num => console.warn(`${num} addresses removed from dexie`)),
          map( () => x)),
        ),
        mergeMap(request => this.generateCommuteMatrixServerSide(request.address).pipe(
          map(response => {
            return {req: request, resp: response};
          }),
          tap(x => console.log("commuteGen" ,x)),
          tap(x => console.log("commuteGen" ,x.resp["result"])),
        )),
        catchError(err => {
        console.log('Error caught in commuteGen observable.', err);
        return throwError(err);
        }),
        share(),
        );

    // Sucessfully enqueued to commute matrix generation microservice.
    sendToMicroservice.pipe(
        filter(x => x.resp["result"] === "Success" || x.resp["result"] == "GENERATION IN PROGRESS"),
        tap(x => console.warn(x.resp)),
        tap(x => this.dexieCacheService.newAddressesNeedingMonitored$.next([x.req.address.docId])),
        takeUntil(this.addressService.destroyingComponent$),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
    ).subscribe();

    const failedToAdd = sendToMicroservice.pipe(
      filter(x => x.resp["result"] !== "Success" && x.resp["result"] !== "GENERATION IN PROGRESS"),
      tap(x => console.warn(x.resp)),
      map(x => {
        x.req.retryCount = x.req.retryCount + 1;
        return x.req;
      }),
      catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        })
      );

    //retry up to 5 times.
    failedToAdd.pipe(
      filter(x => x.retryCount < 5),
      delay(1000),
      tap(x => this.generateCommuteMatrixForAddressWithRetryCount$.next(x)),
      catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        })
    ).subscribe();



    this.addressPopulatedFromGeocoderResults$.pipe(
      switchMap(orgin => this.addressRoutingService.queryFirestoreDeep$([where("originAddressDocId", "==", orgin.DocId())])),
      tap(x => {
        x.forEach(q => {
        if (!this.orginAddressIdToAssociatedCommutes.get(q.originAddressDocId)?.get(q.destinationAddressDocId) ) {
        this.addToCommuteMatrix(q);
        } else {
          if (this.officeSchedulingFromAddress && q.destinationAddressDocId ===  this.officeSchedulingFromAddress.DocId() ) {
            this.generatedCommuteMatrixResultsToShop$.next(q.originAddressDocId);
          }
        }
      });
      }),
    ).subscribe();
  }

  updateAddressServerSide(oldAddressDocId: string, newAddressDocId: string) : Observable<Object>{
    const url = "https://cm-updateaddress-buwvrbjm3q-uc.a.run.app"
    // const url = "http://localhost:5001/service-vanguard/us-central1/cm-updateaddress"
    return from(this.auth.afAuth.currentUser).pipe(
      switchMap(currentUser =>
        from(currentUser.getIdToken()).pipe(
          map(tkn => ({token: tkn, user: currentUser }))
        )),
      map(userData => {
        return { query: {
          initiatingUuid: userData.user.uid,
          bucketId: this.auth.bucketId,
          addressDocId: newAddressDocId,
          oldAddressDocId: oldAddressDocId,
          updateRecordsAfter: subDays(startOfDay(new Date()),4)
        },
        headers: new HttpHeaders()
          .set('Authorization', userData.token)
          .set('Content-Type', 'application/json')
        };
      }),
      tap(x => console.warn(`POSTING ADDRESS UPDATE`)),
      switchMap(x => this.http.post(`${url}?initiatingUuid=${x.query.initiatingUuid}&bucketId=${x.query.bucketId}` +
        `&addressDocId=${x.query.addressDocId}&oldAddressDocId=${x.query.oldAddressDocId}&updateRecordsAfter=${x.query.updateRecordsAfter}`,
      {headers: x.headers})),
    )
  }

  generateCommuteMatrixServerSide(orginAddress: Address) : Observable<Object>{
    const url = "https://us-central1-service-vanguard.cloudfunctions.net/cm-newcommatrixgen";
    return from(this.auth.afAuth.currentUser).pipe(
      switchMap(currentUser =>
        from(currentUser.getIdToken()).pipe(
          map(tkn => ({token: tkn, user: currentUser }))
        )),
      map(userData => {
        return { query: {
          initiatingUuid: userData.user.uid,
          bucketId: this.auth.bucketId,
          addressDocId: orginAddress.docId
        },
        headers: new HttpHeaders()
          .set('Authorization', userData.token)
          .set('Content-Type', 'application/json')
        };
      }),
      tap(x => console.warn(`POSTING COMMUTE GEN REQUEST`,orginAddress)),
      switchMap(x => this.http.post(`${url}?initiatingUuid=${x.query.initiatingUuid}&bucketId=${x.query.bucketId}&addressDocId=${x.query.addressDocId}`,
      {headers: x.headers})),
    )
  }

  private addIndividualEntryToCommuteMatrix(toAdd: AddressRouting) : void {

    if (this.orginAddressIdToAssociatedCommutes.get(toAdd.originAddressDocId) === undefined) {
      this.orginAddressIdToAssociatedCommutes.set(toAdd.originAddressDocId, new Map());
      // Add entry to represent commute between site visit and itself.
      this.orginAddressIdToAssociatedCommutes.get(toAdd.originAddressDocId).set(toAdd.originAddressDocId, new AddressRouting({
        originAddressDocId: toAdd.originAddressDocId, destinationAddressDocId: toAdd.originAddressDocId,
        distanceMeters: 0, timeEstimateSeconds: 0,
        reversedFromRequestedRoute: false, orginAddress: toAdd.orginAddress,  destinationAddress: toAdd.orginAddress}));
    }
    this.orginAddressIdToAssociatedCommutes.get(toAdd.originAddressDocId).set(toAdd.destinationAddressDocId, toAdd);
    if (this.officeSchedulingFromAddress !== undefined && toAdd.destinationAddressDocId ===  this.officeSchedulingFromAddress.DocId()) {
      this.generatedCommuteMatrixResultsToShop$.next(toAdd.originAddressDocId);
    }
  }

  addToCommuteMatrix(toAdd: AddressRouting ) : void {
    if (toAdd === undefined) {
      return;
    }
    this.addressesOriginationDocIdsRetrievedOrRetrieving.add(toAdd.originAddressDocId);
    this.addIndividualEntryToCommuteMatrix(toAdd);
    const derivedDirection = new AddressRouting({
      destinationAddressDocId: toAdd.originAddressDocId, originAddressDocId: toAdd.destinationAddressDocId,
      distanceMeters: toAdd.distanceMeters, timeEstimateSeconds: toAdd.timeEstimateSeconds,
      reversedFromRequestedRoute: true, orginAddress: toAdd.destinationAddress, destinationAddress: toAdd.orginAddress
    });
    this.addIndividualEntryToCommuteMatrix(derivedDirection);
  }

  retrieveOnDemandFromDixie(address: Address, silent: boolean = false, skipPendingEmissions: boolean = false) : Observable<AddressRoutingLocal[]> {

    if (!this.addressRoutingService.cacheInProgress.includes(address.docId)) {
      this.addressRoutingService.cacheInProgress.push(address.docId);
    } else {
      if (skipPendingEmissions) {
        return of([]);
      }
    }
    if (!silent) {
      console.log(`Retrieving from dexie for address: ${address.formattedAddress()}   DocId: ${address.DocId()}`);
    }

    if (this.addressRoutingService.catchedOriginAddressDocIds.includes(address.docId)) {
      const retVal = [];
      this.orginAddressIdToAssociatedCommutes.get(address.docId).forEach(x => retVal.push(x as AddressRoutingLocal));
      return of(retVal);
    } else {
      return from(Promise.all([db.addressRoutingLocal.where("originAddressDocId").equals(address.docId).toArray(),
        db.addressRoutingLocal.where("destinationAddressDocId").equals(address.docId).toArray()])).pipe(
          delayWhen(() => this.settingsService.settingsLoaded$),
          map(x => x[0].concat(x[1])),
          tap(x => this.addressRoutingService.cacheDexieEntries(x)),
          tap(x => this.addressRoutingService.catchedOriginAddressDocIds.push(address.docId)),
          tap(x => {
            if (!silent) {
              console.log(`Retrieved ${x.length} rows from dexie for address: ${address.formattedAddress()}`);
          }}),
          catchError(err => {
          console.log('Error caught in observable.', err);
          return throwError(err);
          })
          );
    }
}

}
