import { AfterViewInit, Component, ElementRef, Inject,  OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  startOfDay, subHours } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';
import { BehaviorSubject, combineLatest, delayWhen, of, Subject } from 'rxjs';
import {  filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { LineItemService } from '../../../../../../common/src/data/dao-services/line-item.service';
import { LineItem } from '../../../../../../common/src/data/dao/line-item';
import { AuthenticationService } from '../../../../../../common/src/util/authentication.service';
import { randomElementName } from '../../../../../../common/src/util/util';
import { AddEditPrototypeModalComponent } from '../add-edit-prototype-modal/add-edit-prototype-modal.component';
import { LineItemCreationService, LINE_ITEM_CREATION_MODE, EXIT_STATUS, PROTOTYPE_INTENTION_ON_EDIT } from '../line-item-creation.service';
import { where } from 'firebase/firestore';
import { SkillSetService } from '../../../../../../common/src/data/dao-services/skill-set.service';
import { ChecklistDatabase } from '../../../multiselect-nested-tree/multiselect-nested-tree.component';
import { SkillSet } from '../../../../../../common/src/data/dao/skill-set';

@Component({
  selector: 'app-line-item-creation-modal',
  templateUrl: './line-item-creation-modal.component.html',
  styleUrls: ['./line-item-creation-modal.component.scss']
})
export class LineItemCreationModalComponent implements OnInit, OnDestroy, AfterViewInit {

  lineItemPassedIntoControl: boolean = false;
  lineItem: LineItem;
  lineItemCreationMode: LINE_ITEM_CREATION_MODE;
  prototypeIntentionOnEdit : PROTOTYPE_INTENTION_ON_EDIT = PROTOTYPE_INTENTION_ON_EDIT.UNKNOWN;

  topLevelLineItems: BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
  filteredTopLevelLineItems : Subject<LineItem[]> = new Subject<LineItem[]>();
  timeToCompleteSingleLineItem$: Subject<Date> = new Subject<Date>();

  form: UntypedFormGroup;
  lineItemAutoCompleteControl: UntypedFormControl = new UntypedFormControl();

  timeForSingleQuantityAsDate : Date;

  destroyingComponent$ = new Subject();
  box_price = 0;
  lineItemInitiallyPopulated = false;
  addEditPrototypeDialogRef: MatDialogRef<AddEditPrototypeModalComponent>;

  zeroHoursAsDate = startOfDay(new Date());
  maximumTimeOneLineItem= addMinutes(startOfDay(new Date()),11*60+55);

  @ViewChild("titleInput") titleInputReference: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  _skillsChecklistDatabase: ChecklistDatabase<SkillSet> = undefined;
  selectedLineItemSkills: Subject<SkillSet[]> = new Subject<SkillSet[]>();

  LineItemSkillCheckListDatabase() : ChecklistDatabase<SkillSet> {
    if (this._skillsChecklistDatabase === undefined) {
      this._skillsChecklistDatabase =   new ChecklistDatabase<SkillSet>("Line Item Skills",
      this.skillSetService.loadAll$().pipe(
        map(z => z.filter(x=>x.active))
        ),
        this.selectedLineItemSkills,
      (x=>undefined),(x => x.name),(x=>x.name)
      );
    }
    return this._skillsChecklistDatabase;
  }

  skillAddedToLineItem(skills: SkillSet[]) {
    console.log(this.form);
      this.form.get("requiredSkills").value.push(...skills);
    }

  skillRemovedFromLineItem(skills: SkillSet[]) {
      skills.forEach(skill => {
        this.form.get("requiredSkills").value.splice(this.form.get("requiredSkills").value.findIndex(x => x.docId === skill.docId),1);
      })
    }

    setupAssignedLineItemSkills() : void {
      this._skillsChecklistDatabase.addMatchingNodes(this.form.get("requiredSkills").value);
    }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private lineItemCreationService: LineItemCreationService,
    private lineItemService: LineItemService, private dialog: MatDialog,
    private dialogRef: MatDialogRef<LineItemCreationModalComponent>, private authenticationService: AuthenticationService, private skillSetService: SkillSetService ) {

      const newLineItem = new LineItem({title: "Add New"});
      combineLatest([of(newLineItem),this.lineItemService.queryFirestoreDeep$([where("lineItemPrototypeDocId", "==", null)])]).pipe(
        map(([a,b]) => {
          const arrayCopy = [...b];
          arrayCopy.unshift(a);
          return arrayCopy;
        }),
        takeUntil(this.destroyingComponent$)
      ).subscribe(this.topLevelLineItems);

      if (data) {
        this.lineItemCreationMode = data.lineItemCreationMode;
        this.lineItem = data.lineItem;
        this.lineItemInitiallyPopulated = data.lineItem;
        if (data.lineItem) {
          this.lineItemAutoCompleteControl.patchValue(data.lineItem);
          this.lineItemPassedIntoControl = true;
        }
        this.form = this.lineItemCreationService.buildLineItemFormGroup(this.destroyingComponent$);

        this.form.patchValue({singleQuantityDurationAsTime :this.lineItemCreationService.getTimeFromHourDuration(0)});

        this.form.get('singleQuantityDurationAsTime').valueChanges.pipe(
          startWith(this.form.get('singleQuantityDurationAsTime').value),
          map(x => new Date(x)),
          tap(x => this.timeForSingleQuantityAsDate = x),
          takeUntil(this.destroyingComponent$)
        ).subscribe();

        this.timeToCompleteSingleLineItem$.pipe(
          map(x => {
            let hours = 0;
            hours = x.getHours();
            x = subHours(x,hours);
            hours = Math.round(x.getMinutes()*100/60)/100 + hours;
            return hours;
          }),
          tap(hours => this.form.patchValue({singleQuantityDurationAsTime:
            this.lineItemCreationService.getTimeFromHourDuration(hours) })),
          takeUntil(this.destroyingComponent$)
        ).subscribe();

        if (this.lineItemCreationMode === LINE_ITEM_CREATION_MODE.PROTOTYPE && this.prototypeIntentionOnEdit === PROTOTYPE_INTENTION_ON_EDIT.CREATE) {
          this.form.controls["title"].setAsyncValidators(this.lineItemService.lineItemNameDistinctValidator());
        }
        if (this.lineItem) {
          this.patchLineItemToFormGroup(this.lineItem);
        }
      }

      this.lineItemAutoCompleteControl.valueChanges.pipe(
        filter(x => typeof(x) === "object"),
        tap(x => this.lineItem = x),
        tap(x => this.lineItemCreationService.patchLineItemToFormGroup(x, this.form)),
        takeUntil(this.destroyingComponent$)
      ).subscribe();

      this.lineItemAutoCompleteControl.valueChanges.pipe(
        filter(x => x == ""),
        tap(() => this.form.reset()),
        takeUntil(this.destroyingComponent$)
      ).subscribe();

    }
  ngAfterViewInit(): void {
    // When line item is passed in, set focus to title ( first element after the autocomplete search)
    if (this.lineItem !== null) {
      this.setupAssignedLineItemSkills();
      setTimeout(() => {
        this.titleInputReference.nativeElement.focus()
      }, 0);
    }
  }

  randomElementName : string = randomElementName();
  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  public displayLineItemName(value: LineItem) {
    return value !== null ? value.title : "";
  }

  patchLineItemToFormGroup(lineItem: LineItem) : void {
      this.lineItemCreationService.patchLineItemToFormGroup(lineItem, this.form);
      this.box_price = lineItem.pricePerItem;
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
    }

  ngOnInit(): void {
     // tag search emission returns filtered results of search term.
     combineLatest([this.lineItemAutoCompleteControl.valueChanges.pipe(startWith('')),this.topLevelLineItems]).pipe(
      map(([value,topLevelLineItems]) => value),
      map((tagSearch: string | null | LineItem) => (tagSearch instanceof LineItem) ? [tagSearch] : tagSearch  ? this._filter(tagSearch) : this._filter('')),
      tap(x => this.filteredTopLevelLineItems.next(x)),
      takeUntil(this.destroyingComponent$)).subscribe();

  }

  private _filter(value: string): LineItem[] {

    const filterValue = value.toLowerCase();
    return filterValue !== '' ? this.topLevelLineItems.value.filter(lineItem => lineItem.title.toLowerCase().indexOf(filterValue) === 0 ||
    lineItem.title.toLowerCase().indexOf(` ${filterValue}`) !== -1 || !lineItem.lineItemDocId) : this.topLevelLineItems.value;
  }


  updatePrototypeIntentionOnEdit(result: any) {
    if (result !== undefined) {
      this.prototypeIntentionOnEdit = result.prototypeIntentionOnEdit;
      if (this.prototypeIntentionOnEdit === PROTOTYPE_INTENTION_ON_EDIT.CREATE) {
        this.form.get("title").setAsyncValidators(this.lineItemService.lineItemNameDistinctValidator());
      } else {
        this.form.get("title").clearAsyncValidators();
      }
      this.form.get("title").updateValueAndValidity();
      this.Save();
    } else {
      this.form.get("title").clearAsyncValidators();
    }
  }

  Save(): void {
    if (this.form.valid) {
      if (this.lineItem === null) {
        this.lineItem = this.topLevelLineItems.value[0];
      }
      const l = new LineItem(this.lineItem);
      this.lineItemCreationService.patchFormGroupToLineItem(this.form, l);
      if (!this.lineItemService.updatePrincipleObjectRequired(l)) {
        this.lineItemCreationService.newLineItemFromFormAndPrototypeLineItem(this.form,this.lineItem).pipe(
          tap(retVal => this.dialogRef.close({lineItem: retVal, exitStatus: EXIT_STATUS.SELECT})),
          take(1)
        ).subscribe();
      } else {
        if (l.lineItemDocId === undefined) {
          this.prototypeIntentionOnEdit = PROTOTYPE_INTENTION_ON_EDIT.CREATE;
        }
        // If we are working w/ prototypes, and we don't know whether user intends to create new, or edit existing, we need to find out w/ modal.
        if (this.lineItemCreationMode === LINE_ITEM_CREATION_MODE.PROTOTYPE && this.prototypeIntentionOnEdit === PROTOTYPE_INTENTION_ON_EDIT.UNKNOWN) {
          this.addEditPrototypeDialogRef = this.dialog.open(AddEditPrototypeModalComponent);
          this.addEditPrototypeDialogRef.afterClosed().pipe(
          take(1)).subscribe(x => this.updatePrototypeIntentionOnEdit(x));
        } else {
            this.lineItemCreationService.newLineItemFromFormAndPrototypeLineItem(this.form,this.lineItem).pipe(
              tap(retVal => {
                retVal.originatingEmployeeDocId = this.authenticationService.activelyLoggedInEmployeeDocId;
                // If this.lineItem is not a prototype, and it doesn't possess an originating line item already then we need to
                // set the returned line item's originating line item to it's doc id.
                if (retVal.originatingLineItemDocId === null && this.lineItem.lineItemPrototypeDocId) {
                  retVal.originatingLineItemDocId = this.lineItem.DocId();
                }
                let exitStatus: EXIT_STATUS;
                if (this.lineItemCreationMode === LINE_ITEM_CREATION_MODE.INSTANTIATE || this.prototypeIntentionOnEdit === PROTOTYPE_INTENTION_ON_EDIT.CREATE) {
                  exitStatus = EXIT_STATUS.CREATE;
                  if (this.lineItemCreationMode === LINE_ITEM_CREATION_MODE.PROTOTYPE) {
                    retVal.lineItemPrototypeDocId = null;
                  }
                } else {
                  if (this.lineItemCreationMode === LINE_ITEM_CREATION_MODE.PROTOTYPE && this.prototypeIntentionOnEdit === PROTOTYPE_INTENTION_ON_EDIT.UPDATE) {
                    exitStatus = EXIT_STATUS.UPDATE;
                  }
                }
                this.dialogRef.close({lineItem: retVal, exitStatus, oldLineItem: this.data.lineItem  ? this.data.lineItem : this.lineItem});
              }),
            take(1)
          ).subscribe();
        }
      }
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  Exit() : void {
    this.dialogRef.close();
  }

}
