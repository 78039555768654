
<div class="container1">
  <div class="left-side"></div>
  <div class="middle">
    <div [formGroup]="customerTypeForm" class="customer-type-grid">
      <div class="header-label" style="grid-column: 1/3;">Customer Types</div>
      <div class="label-style">Customer Type</div>
      <div style="display: flex;">
      <div style="width: 330px;">
        <mat-form-field appearance="outline" class="form-align" style="width: 330px;">
              <mat-select formControlName="customerType" placeholder="Customer Type" >
              <mat-option *ngFor="let jobType of customerTypesToDisplay$ | async" [value]="jobType" >
                {{jobType.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <button type="button" mat-button style="margin-left: 30px !important;" class="button-green" (click)="addNewCustomerType()">Add New Customer Type</button>
          </div>
          <div style="width: 100%; grid-column: 2/3;">
            <mat-checkbox formControlName="showInactiveCustomerTypes">
              Show inactive Customer Types
             </mat-checkbox>
          </div>
          <div class="label-style">Customer Type Name</div>
          <div style="width: 100%;">
            <mat-form-field appearance="outline" class="medium-field">
              <input formControlName="customerTypeName" matInput placeholder ="Name"
              [name]="ElementNameForId('53159')" autofill='off' autocomplete='new-password' data-lpignore='true'>
              <mat-error>Customer type must be named to save.</mat-error>
            </mat-form-field>
          </div>
          <div style="width: 100%; grid-column: 2/3;">
            <mat-checkbox formControlName="customerTypeActive">Active</mat-checkbox>
          </div>
  
      <!-- Cancel / Submit buttons. -->
  <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/-1; width: 100%; padding-top: 20px;">
    <div class="button-position">
      <button type="button" mat-button class="button-gray" (click)="Cancel()">Cancel Changes</button>
    </div>
  
  
    <div class="button-position">
      <button type="submit" (click)="Save()" mat-button class="button-blue">Save Changes</button>
    </div>
  
    </div>
  </div>
  
  <div class="cust-communication-grid">
<div class="header-label" style="grid-column: 1/5;">Customer Communication Preferences</div>
<mat-checkbox *ngIf="companySettings!==undefined" style="grid-column: 2/4; margin-left: 20px;" class="label-style label-style-left-aligned"
  [(ngModel)]="companySettings.triggerEstimateAndInvoiceCommunicationsOnWorkflowCompletion" (ngModelChange)="toggletriggerEstimateAndInvoiceWithWorkflow()"><span class="label-style label-style-left-aligned">Send Estimate and Invoice When Work Complete Clicked</span></mat-checkbox>
<div *ngFor="let templateType of customerTemplatesGroupedByCatagory | async" style="display: contents;">
  <h2 style="grid-column: 1/5; padding-left: 20px;">{{customerCommunicationCategoryStringRepresentation(templateType.key)}}</h2>
  <div *ngFor="let template of templateType.values; let i=index" style="display: contents;">
          <mat-checkbox style="grid-column: 2/4; margin-left: 20px;" class="label-style label-style-left-aligned" [(ngModel)]="template.enabled" (ngModelChange)="toggleEnabled(template)"><span class="label-style label-style-left-aligned">{{template.description}}</span></mat-checkbox>
          <div class="button-align">
            <button mat-button class="button-green" style="margin-right: 20px;" (click)="editTemplate(template)">Edit</button>
          </div>
      <!-- </div>
    </div> -->
  </div>
</div>
</div>
</div>
<div class="right-side"></div>
</div>
