import { Injectable } from '@angular/core';
import { FirestoreDiff } from '../dao/firestore-diff';
;
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { AuthenticationService } from '../../util/authentication.service';

@Injectable({
  providedIn: 'root'
})

export class FirestoreDiffService extends FirestoreBackend<FirestoreDiff> {

  protected basePath = "diffs";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): FirestoreDiff {
    return new FirestoreDiff(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new FirestoreDiff(), authService);
   }
}
