import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DataLink, DataLinkNode, DataLinkService } from './data-link.service';


@Component({
  selector: 'app-data-link-populator',
  template: `
  <div class="data-selection-container">
  <h5 style="border-right: gainsboro 2px solid;">Selectable Data</h5>
  <h5>Selected Data</h5>
  </div>
  <div class="data-selection-container" style="margin-bottom: 12px;">
  <div style="border-right: gainsboro 2px solid;">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="leafClicked(node)" matTreeNodePadding matTreeNodePaddingIndent="100">
  <button mat-icon-button disabled></button>
  <li class="mat-tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
      <!--<button mat-icon-button disabled></button>-->
      {{node.name}}
    </li>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
  <li>
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </div>
      <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
</div>
<div *ngIf="selectedDataLink" class="findme">
    <app-drag-drop-list-builder style="display: contents;"
    [form]="initilizeDragDropList()"
    (formArrayElementGainedFocus)="dataLinkFormArrayElementGainedFocus.next($event)">
    </app-drag-drop-list-builder>
    <div class="display-data-position component-section">
      <div class="data-display-style">Data Display</div>
      <mat-form-field appearance="outline" style="width: 90%;">
        <textarea matInput>{{formattedDataFields()}}</textarea>
      </mat-form-field>
    </div>
</div>
</div>
<!--end of data choice, start of data display -->

<!--end of data display -->
  <div class="footer-buttons">
    <div>
      <button mat-button class="button-gray button-min-width" (click)="cancel()">Cancel</button>
    </div>
    <div class="save-button">
      <button mat-button class="button-blue button-min-width" (click)="save()">Save</button>
    </div>
  </div>
  `,
  styleUrls: ['./data-link-populator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLinkPopulatorComponent implements OnInit, OnDestroy {

  private selectedComponents: UntypedFormGroup = undefined;
  formCatagory: string = "all";
  treeControl = new NestedTreeControl<DataLinkNode> (node => node.children);
  dataSource : ArrayDataSource<DataLinkNode>;
  selectedDataLink: DataLink;
  dataLinkFormArrayElementGainedFocus: Subject<number> = new Subject<number>();
  focusedDataLinkFormArrayIndex: number = 0;

  hasChild = (_: number, node: DataLinkNode) => !!node.children && node.children.length > 0;

  destroyingComponent$ = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DataLinkPopulatorComponent>,
  private dataLinkService: DataLinkService, private fb: UntypedFormBuilder) {

    if (data) {
      this.formCatagory = data.formCatagory;
    }
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
    }

  ngOnInit(): void {
    this.dataSource = new ArrayDataSource(this.dataLinkService.rootTreeData);

    this.dataLinkFormArrayElementGainedFocus.pipe(
      tap(x => this.focusedDataLinkFormArrayIndex = x),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

  }

  save() {
    const selected = this.selectedComponents.get("draggableComponents") as UntypedFormArray;
    this.dialogRef.close(selected.value.map(x => x.dataLink));
  }

  cancel() {
      this.dialogRef.close();
  }

  initilizeDragDropList() : UntypedFormGroup{
    if (this.selectedComponents === undefined) {
      this.selectedComponents =
      this.fb.group({
        title: ["Data Arrangement"],
        allowAddFromDragDrop: [true],
      draggableComponents: this.fb.array([
        this.fb.group({
          label: this.selectedDataLink.placeHolderOrLinkedData(),
          id: this.selectedDataLink.id,
          value: 1,
          dataLink: this.selectedDataLink,
          focused: true
        })
      ])
    })
    }
    return this.selectedComponents;
    }

  leafClicked(node: DataLinkNode) {
    this.selectedDataLink = this.dataLinkService.retrieveDataLinkAssociatedWithNode(node);
    if (this.selectedComponents !== undefined)
    {
      const arr = this.selectedComponents.get("draggableComponents") as UntypedFormArray;
      const valueToReplace = arr.at(this.focusedDataLinkFormArrayIndex).value;
      arr.setControl(this.focusedDataLinkFormArrayIndex,
        this.fb.group({
          label: this.selectedDataLink.placeHolderOrLinkedData(),
          id: this.selectedDataLink.id,
          value: valueToReplace.value,
          dataLink: this.selectedDataLink,
          focused: valueToReplace.focused,
        })
      )
    }
  }

  formattedDataFields() {
    const arr = this.selectedComponents.get("draggableComponents") as UntypedFormArray;
    return this.dataLinkService.formattedDataFields((arr.value as Array<any>).filter(z => z.dataLink !== undefined).map(x=>x.dataLink));
  }


}
