import { Ability, AbilityClass } from '@casl/ability';
import { Estimate } from '../data/dao/estimate';
import { FormModelFirestore } from '../data/dao/form-model-firestore';
import { Job } from '../data/dao/job';
import { SiteVisit } from '../data/dao/site-visit';

type Actions = 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Payment' | 'Prices' | 'SiteVisit' | SiteVisit | 'Estimate' | Estimate | 'WorkOrder' | 'Invoice' | 'DefaultEmployeeSchedule' | 'EmployeeSchedule' |
'Job' | Job  | 'SiteVisit' | SiteVisit | 'Report' | FormModelFirestore | 'FormModelFirestore' | 'AllEmployeeSchedule';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
