import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject,  Observable, Subject, debounceTime, take } from 'rxjs';
import {  tap, map, startWith, takeUntil, distinctUntilChanged  } from 'rxjs/operators';

export interface Irate {
  id: string;
  name: string;
  rating: number;
}

@Component({
  selector: 'app-formly-rating',
  templateUrl: './formly-rating.component.html',
  styleUrls: ['./formly-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRatingComponent extends FieldType implements AfterViewInit, OnDestroy, OnInit {


  guid = ','.concat(Math.random().toString(36).substring(0, 7));
  internalChangeDetect = new Subject<any>();
  rawData: Observable<any> = new Observable<any>();
  preparedData$: Observable<any> = new Observable<any>();
  destroyingComponent$ = new Subject();
  columnIds = new BehaviorSubject<string[]>([]);
  private _columns: BehaviorSubject<any[]> = undefined;

  get data() {
    return this.preparedData$;
 }

 get changeDetect() {
   if (this.to.changeDetect !== undefined) {
     return this.to.changeDetect;
   } else {
     return this.internalChangeDetect;
   }
 }

 get columns() {
   if (this._columns === undefined) {
       if (!(this.to.columns instanceof BehaviorSubject)) {
         this._columns =  new BehaviorSubject<any[]>(this.to.columns);
       } else {
         this._columns = this.to.columns;
       }
       return this._columns;
     } else {
       return this._columns;
     }
 }

  constructor(private ref: ChangeDetectorRef) {
    super();
   }

   ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }


   getInitialValue(formControlVal : any[]) : any {

    const retVal = [];
    var i = 0;
    let updated: boolean = false;
    formControlVal.forEach(x => {
      if (!(x['id'] as string).includes('-GR')) {
        x['id'] = `${i},${this.key as string}${this.guid}-GR`;
        i++;
        updated=true;
      }
      retVal.push(x);
    });
    if (updated) {
      this.formControl.patchValue(retVal);
    }
    return retVal;
  }

  ngOnInit(): void {
    this.field.hooks = {
      afterViewInit: (field: FormlyFieldConfig) => {

          this.rawData = (this.formControl.value instanceof BehaviorSubject) ? this.formControl.value :
          this.formControl.valueChanges.pipe(
            startWith(this.getInitialValue(this.formControl.value)),
            );

            this.preparedData$ = this.rawData.pipe(
              map(p => {
                if (p instanceof BehaviorSubject) {
                  return p.value;
                } else {
                  return p;
                }
              }),
              map(present => {
                if ((present as Array<any>).length > 0 && !(present as Array<any>)[0].id.includes(this.key as string)) {
                  (present as Array<any>).forEach(p => {
                    p.id = (p.id as string).split(',')[0].concat(`,${this.key as string}`).concat(`${this.guid}`);
                  });
                }
                return present;
              }),
              );
        }
      };
  }

  trackDataRow(index: number, item: Irate) {
    return item.id;
  }

  columnLabel(i: number) {
    return this.columns.value[i] === undefined ? "" : this.columns.value[i].label;
  }

  ngAfterViewInit(): void {

    // Required b/c we have to.columns, to.data, but | async is called b/f afterViewInit, which we must wait for to
    // properly initilize columnIds.....

    (this.columns as Observable<any[]>).pipe(
      debounceTime(25),
      map(x => x.map(z => z["value"])),
      takeUntil(this.destroyingComponent$)
    ).subscribe(this.columnIds);


    this.ref.markForCheck();

  }

  radioChange(event: any, row: Irate) {
    // In design mode, this is an observable, so we don't want to try to patch this in.
    if (!(this.formControl.value instanceof Observable) && !this.formControl.disabled) {
      const curVal = this.formControl.value as Array<Irate>;
      curVal[curVal.findIndex(x => x.id === row.id)].rating = event.value;
      this.formControl.patchValue(curVal);
    }
  }

}
