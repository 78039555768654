import { ChangeDetectionStrategy, Component} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-page-break',
  styleUrls: ['./formly-page-break.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="donotprint page-break-styling">
  ----------------------------------- Page Break -----------------------------------
  </div>
  <div class="page-break-phantom"></div>
  <div class="break"></div>
  <div class="break"></div>
    `,
})

export class FormlyPageBreakComponent extends FieldType {

}
