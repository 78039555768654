import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { addressRoutingSource } from "./address-routing";

export class ResourceDayAddressRouting extends RetrieveFirestorePropertiesBase<ResourceDayAddressRouting>  {

employeeDocId: string;
resourceDay: Date;
originAddressDocId: string;
destinationAddressDocId: string;
distanceMeters: number = 0;
timeEstimateSeconds: number;
addressRoutingSource = addressRoutingSource.GOOGLE;
reversedFromRequestedRoute: boolean;
dateRetrieved: Date = new Date();

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return ResourceDayAddressRouting._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return ResourceDayAddressRouting._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return ResourceDayAddressRouting._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return ResourceDayAddressRouting._firestoreCompositionMemberNames;}

public constructor(init?: Partial<ResourceDayAddressRouting>) {
super();
Object.assign(this, init);
}
}
