import { CdkDragEnd, CdkDragStart } from "@angular/cdk/drag-drop";
import { Directive, HostBinding, Type, inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { BehaviorSubject, interval, merge, Observable, race, Subject, switchMap, takeWhile } from "rxjs";
import { debounceTime, startWith, takeUntil, tap } from "rxjs/operators";
import { ColumnSplitterComponent } from "../column-splitter/column-splitter.component";
import { SectionComponent } from "../section/section.component";
import { Audience, FormlyUtilityService } from "./formly-controls/formly-utility.service";

export enum LabelLocation {
  Left = 1,
  Right = 2,
  Above = 3
}

export enum underlineControlOptions {
  None = "NONE",
  Black = "BLACK",
  Grey = "GREY"
}

export enum FormlyView {
  Print = "printView",
  Technician = "techView",
}

export class CommonControlContainerFormGroup {
  audience: Audience;
  percentTotalWidth: number;
  icon: string;
  iconColor: string;
  controlComponentFormGroup: UntypedFormGroup;
  controlName: string;
  focused: boolean;
  guid: string;
  triggerUpdate: object;
  liveFormlyFieldConfig: FormlyFieldConfig;
  numberComponents: number;
  resizing: boolean;
  dragging: boolean;
  readonly: boolean;
  readOnlyDisabled: boolean;
  composerComponent: boolean;
  ControlRequiresFullWidth: boolean;
  emitClick: boolean;
  activeView: FormlyView;
  indexInParentContainer: number;
}

@Directive()
export abstract class ControlContainerComponent   {

  changeDetect$ = new Subject<any>();
  fields: FormlyFieldConfig[];
  perInstance = Math.random().toString(36).substring(0, 9);
  viewsPerInstance: string[] = [];
  viewPerInstance: string;

  get serializeToDatabase() : boolean { return this.form.get("serializeToDatabase").value; }


  @HostBinding('class.isFocused') get t() {
    return this.form.get("focused").value;
  };

  static initilizedDebouncingOnDrag: boolean = false;
  static componentBeingDragged: Subject<null> = new Subject<null>();
  static componentDragEnd: Subject<null> = new Subject<null>();
  static formlyUtilityService: FormlyUtilityService;

  emitClick: boolean = true;

  public unfilteredComponentClicked$ : Subject<ControlContainerComponent> = new Subject<ControlContainerComponent>();
  public filteredComponentClicked$ : Subject<ControlContainerComponent> = new Subject<ControlContainerComponent>();

  get guid() { return this.perInstance;}

  get NumberComponents() { return this.form.get("numberComponents").value; }
  set NumberComponents(value: number) { this.form.patchValue({numberComponents: value}); }

  _parentContainer: ColumnSplitterComponent | SectionComponent = null;

  get parentContainer() {return this._parentContainer;}

  set parentContainer(value: ColumnSplitterComponent | SectionComponent) {

    // This must be maintained so we can properly handle destruction that is per instance, not per
    // Instance of view (which calls ngDestory regarless of whether there are other references,
    // which causes click streams and resizeobservers to be released before their time.)
    this.viewPerInstance = Math.random().toString(36).substring(0, 9);
    this.viewsPerInstance.push(this.viewPerInstance);
    const changeSection$ = new Subject<any>();

    // If previous parent is set, and is a Section, we must remove component from it.
    if (this._parentContainer && this._parentContainer.form.get("controlName").value === "Section") {
      changeSection$.next(null);
      const asSection = this._parentContainer as SectionComponent;
    }

    this._parentContainer = value;
    this.updateParentContainer$.next(null);
    let activeClickStream$ : Observable<ControlContainerComponent>;
    if (["Section","Columns"].some(x => x === this.form.get("controlName").value)) {
      activeClickStream$ = this.filteredComponentClicked$;
    } else {
      activeClickStream$ = this.unfilteredComponentClicked$;
    }

    activeClickStream$.pipe(
        tap(x => this._parentContainer.unfilteredComponentClicked$.next(x)),
        takeUntil(merge(this.destroyingComponent$,this.updateParentContainer$))).subscribe();

    this.form.get("triggerUpdate").valueChanges.subscribe(x =>  {
        this._parentContainer.form.controls["triggerUpdate"].patchValue({triggerUpdate: {}})
      });

      // Sections suscbribe to sub-component resize observables.
      if (this.parentContainer.form.get("controlName").value === "Section") {
        const asSection = this.parentContainer as SectionComponent;
        // When parent section is resized, must trigger change detection in children components to have proper top assigned (so layout of page breaks
        // is correct).  When parent section triggers change detection, must trigger in children b/c they may need re-rendered b/c component add / removed.
        asSection.changeDetect$.pipe(
          debounceTime(5),
          takeUntil(merge(changeSection$,this.destroyingComponent$))
          ).subscribe(() => this.changeDetect$.next(null));
      }
}

static _activeView: FormlyView = FormlyView.Print;
get activeView() { return ControlContainerComponent._activeView;}
set activeView (value: FormlyView) {
  ControlContainerComponent._activeView = value;
  this.form.patchValue({activeView: value});
}

static _disableDesignComponentInteraction: boolean = false;
get disableDesignComponentInteraction() { return ControlContainerComponent._disableDesignComponentInteraction;}
set disableDesignComponentInteraction (value: boolean) {
  ControlContainerComponent._disableDesignComponentInteraction = value;
}


  public form: UntypedFormGroup;
  options$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  // subjectsToCopyToControlContainer : {key: string, value: Subject<any>}[] = [{key: "options$", value: this.options$}];
  destroyingComponent$ = new Subject();
  updateParentContainer$ = new Subject();


  constructor(public component: Type<any>, protected fb: UntypedFormBuilder)
  {
    if (ControlContainerComponent.initilizedDebouncingOnDrag === false) {
        ControlContainerComponent.initilizedDebouncingOnDrag = true;
        ControlContainerComponent.initilizeDebounceOnDrag();
      }
  }

  static initilizeDebounceOnDrag() {
    ControlContainerComponent.formlyUtilityService = inject(FormlyUtilityService);
    ControlContainerComponent.componentBeingDragged.pipe(
      tap(() => this.formlyUtilityService.sidebarDebounceActive$.next(true))
    ).subscribe();

    ControlContainerComponent.componentDragEnd.pipe(
      switchMap(() => race(
        interval(2000).pipe(
          tap(() => this.formlyUtilityService.sidebarDebounceActive$.next(false))
        ),
        ControlContainerComponent.componentBeingDragged)
      )
    ).subscribe();
  }

  abstract initilizeFormGroup() : UntypedFormGroup;
  abstract patchControlComponentsToFormlyFields() : void;
  abstract patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig | FormlyFieldConfig[]) : void;
  abstract toFormlyFieldConfigJsonOnly() : FormlyFieldConfig | FormlyFieldConfig[];
  abstract toFormlyFieldConfig() : FormlyFieldConfig | FormlyFieldConfig[];

  get untypedForm () { return this.form as UntypedFormGroup; }

  controlContainerCommonDestruction() : void {
    this.viewsPerInstance.splice(this.viewsPerInstance.findIndex(x => x === this.viewPerInstance),1);
    if (this.viewsPerInstance.length === 0) {
      this.destroyingComponent$.next(null);
      this.destroyingComponent$.complete();
    }
  }

  dragStarted(event: CdkDragStart) {
    this.form.patchValue({dragging: true});
    ControlContainerComponent.componentBeingDragged.next(null);
  }

  dragEnded(event: CdkDragEnd) {
    this.form.patchValue({dragging: false});
    ControlContainerComponent.componentDragEnd.next(null);
  }

  get associtedDropZoneIds () : string[] { return [];}

  get componentForm() {
    return this.form.get("controlComponentFormGroup").value as UntypedFormGroup;}

    createDefaultControlContainerFormGroup(controlName: string) : UntypedFormGroup{
    return this.fb.group({

      // specific to composed control.
      controlComponentFormGroup: [],

      // applies to all form builder controls.
      audience: Audience.All,
      readonly: false,
      readOnlyDisabled: false,
      displayWhen: undefined,
      required: false,
      focused: false,
      guid: this.perInstance,
      triggerUpdate: {},
      liveFormlyFieldConfig: {},
      emitClick: true,
      activeView: ControlContainerComponent._activeView,
      serializeToDatabase: true,

      // layout
      percentTotalWidth: 100,
      composerComponent: false,
      ControlRequiresFullWidth: false,

      // form building specific
      resizing: false,
      dragging: false,
      controlName: controlName,
      icon: [],
      numberComponents: 0,
      iconColor: [], //styles individual colors to each button's individual icon
      indexInParentContainer: 0,


    }) ;
  }

  commonFormControlsToPatch = ["readonly", "required", "activeView", "audience"];


  patchValueIntoTemplateOptionsField(value: any, key: string) : void {
  const fieldsToPatch = Array.isArray(this.fields) ? this.fields : [this.fields];
    fieldsToPatch.forEach(field => {
      field.props[key] = value;
   });
  }

  patchFieldsThatAreCommonToAllControls(field: FormlyFieldConfig, obs$ : Observable<any>[]) {

    this.commonFormControlsToPatch.forEach( patch => {
      obs$.push(
        this.form.get(patch).valueChanges.pipe(
          startWith(this.form.get(patch).value),
          tap(x => this.patchValueIntoTemplateOptionsField(x,patch))));
    });
  }

  patchCommonFieldsToForm (formlyConfig : FormlyFieldConfig | FormlyFieldConfig[]) {
    if (Array.isArray(formlyConfig)) {
      formlyConfig.forEach(config => {
        this.patchCommonFieldsToForm(config);
      });
    } else {
      this.commonFormControlsToPatch.forEach( patch => {
        this.form.controls[patch].patchValue((formlyConfig as FormlyFieldConfig).props[patch]);
      });
    }
  }

  patchControlComponentsToFormlyFieldsCommon(fields?: FormlyFieldConfig[]) : Observable<any>[] {
    const obs$ : Observable<any>[] = [];

    // If fields is provided, patch in activeView
    if (this.fields) {
      if (Array.isArray(this.fields)) {
        this.fields.forEach(field => {
          this.patchFieldsThatAreCommonToAllControls(field,obs$);
        })
      } else {
        this.patchFieldsThatAreCommonToAllControls(this.fields,obs$);
      }
    }

    obs$.push(this.form.get("focused").valueChanges.pipe(
      startWith(this.form.get("focused").value)
      ));

    return obs$;
  }

}



