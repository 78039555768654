import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import { FormlyHorizontalWhitespaceComponent } from './formly-horizontal-whitespace.component';


@NgModule({
  declarations: [FormlyHorizontalWhitespaceComponent],
  imports: [
    CommonModule,
    FormlyMaterialModule,
    FormlyModule.forChild({
      types: [
        { name: 'formlyHeightSpacer', component: FormlyHorizontalWhitespaceComponent},
      ]}),
  ]
})
export class FormlyHorizontalWhitespaceModule { }
