import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoPickerComponent } from './photo-picker.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [PhotoPickerComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [PhotoPickerComponent]
})
export class PhotoPickerModule { }
