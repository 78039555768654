<div class="header-style">Customer Details</div>
<div [formGroup]="customerDetailsForm" style="width: 950px;" autocomplete="off" autofill="off">
  <!--SERVICE ADDRESS-->
  <div class="section-content">
  <div style="margin-left: 40px"> <!--Greg moved the green add customer button from outside the [formGroup]="customerDetailsForm" div to inside it for ease of styling. I did not think it would matter and everything seems to be working correctly after moving it-->
    <button mat-button class="button-green" (click)="AddCustomerClick()">Add Customer</button>
  </div>
  <label class="label-style">Service Address</label>
  <div>
  <mat-form-field appearance="outline" style="width: 330px;">
    <input matInput placeholder="Service Address" formControlName="serviceAddressToDisplay">
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 75px; margin-left: 20px;">
    <input matInput placeholder="Unit" formControlName="serviceAddressUnit">
  </mat-form-field>
  </div>
  <mat-form-field class="error-position" appearance="outline">
    <textarea matInput formControlName="customerPopulated" hidden [errorStateMatcher]="errorMatcher"></textarea>
    <mat-error *ngIf="customerDetailsForm.get('customers').hasError('requirePrimaryCustomer')">Job requires a primary customer</mat-error>
    <mat-error style="z-index: 100000;" *ngIf="customerDetailsForm.get('customers').hasError('requiredContactsUnpopulated')" >Job must have at least one billing and one site visit contact.</mat-error>
    <mat-error *ngIf="customerDetailsForm.get('customers').hasError('customerNotPopulated')">Job requires customer to schedule.</mat-error>
    <mat-error *ngIf="customerDetailsForm.get('serviceAddressToValidateOn').hasError('required')">Service Address must be populated to schedule job.</mat-error>
  </mat-form-field>
  </div>
  <div>
    <ul formArrayName="customers">
      <li *ngFor="let customer of customers.controls; let i = index;" [formGroupName]="i">
        <mat-radio-group formControlName="primaryCustomer" name="primary">
        <app-customer-details [customerDetailsForm]="asCustomerFormGroup(customer)"></app-customer-details>
      </mat-radio-group>
      </li>
    </ul>
  </div>
</div>
