import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class ReferralCampaign extends RetrieveFirestorePropertiesBase<ReferralCampaign> {

    name: string;
    active: boolean;
    createdAt: Date = new Date();
    phoneNumber: string;
    cost: number;
    endedOn: Date;
    documentId: string;
  
    DocId(): string {
      return this.documentId;
    }
  
    SetDocId(docId: string): void {
      this.documentId  = docId;
    }
  
    public constructor(init?: Partial<ReferralCampaign>) {
      super();
      Object.assign(this, init);
    }
  }