import { Injectable } from '@angular/core';
;
import { FirestoreBackend  } from '../database-backend/retrieve-from-firestore';
import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { AuthenticationService } from '../../util/authentication.service';
import { share, takeUntil, tap, take, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ReferralCampaign} from '../dao/referral-campaign';
import { where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})

export class ReferralCampaignService extends DatabaseStoreService<ReferralCampaign> {

  allActive = new BehaviorSubject<ReferralCampaign[]>([]);

  constructor( fs: ReferralCampaignFirestoreService, authenticationService: AuthenticationService, store: ReferralCampaignStoreService) {
    super(fs, store, authenticationService);

    const retrieveAllActive = this.queryFirestoreShallow$([where('active', '==', true)]).pipe(
      tap(j => this.allActive.next(j))
    );

    if (this.authenticationService.mobileSite) {
      retrieveAllActive.pipe(
        take(1)
      ).subscribe();
    } else {
      retrieveAllActive.subscribe();
    }
  }

  prePopulate() {
    this.loadAll$().pipe(
      map(x => {
        x.forEach(y => y.uncommitedDocId = true);
        return x;
      }),
      switchMap(j => this.createMultiple$(j)),
      tap(x => {
      console.log("Populated generic service settings");
      }),
      take(1)
    ).subscribe();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReferralCampaignStoreService extends StateChangeStoreService<ReferralCampaign> {
  protected store = "referral-campaign-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, ReferralCampaign>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class ReferralCampaignFirestoreService extends FirestoreBackend<ReferralCampaign> {

  protected basePath = "referral_campaigns";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): ReferralCampaign {
    return new ReferralCampaign(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new ReferralCampaign(), authService);
  }
}


