import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { takeUntil, tap } from "rxjs/operators";
import { ControlContainerComponent, FormlyView } from "./control-container.component";

export abstract class ControlContainsControlsComponent extends ControlContainerComponent {

  get associtedDropZoneIds () : string [] {
    let retVal : string[] = [];
    // add drop zones for column in column control.
    (this.componentForm.get("columnFormGroups") as UntypedFormArray).value.forEach(c => retVal.push(c.id));
    // add any additional drop zones that are contained within the columns.
    (this.componentForm.get("columnFormGroups") as UntypedFormArray)
      // each column
      .value.forEach(c => {
        (c.cdkDropListData as ControlContainerComponent[])
      // each control contained in column.
      .forEach(control => retVal= retVal.concat(control.associtedDropZoneIds))})

    return retVal;
  }


  retrieveFormlyFieldConfigForContainedElements(column: UntypedFormGroup, jsonOnly: boolean) : FormlyFieldConfig[]
  {
    return (column.get("cdkDropListData").value as ControlContainerComponent[]).reduce((retVal: FormlyFieldConfig[], o) => {
      if (jsonOnly) {
          retVal = retVal.concat(o.toFormlyFieldConfigJsonOnly());
      } else {
          retVal = retVal.concat(o.toFormlyFieldConfig());
      }
      return retVal;
    }, []);
  }

  patchValuesToContainedComponentsAsNeeded() {
    const toPatch: string[] = ["dragging"];
    toPatch.forEach(patch => this.patchValueToContainedComponent(patch));
  }

  patchValueToContainedComponent(value: string) {
      this.form.get(value).valueChanges.pipe(
        tap(x => {
          (this.componentForm.get("columnFormGroups") as UntypedFormArray)
      // each column
      .value.forEach(c => {
        (c.cdkDropListData as ControlContainerComponent[])
        // each control contained in column.
        .forEach(control => {
          control.untypedForm.patchValue({ value: x});
        })
      });
        }),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
  }

  set activeView (value: FormlyView) {
    super.activeView = value;
    (this.componentForm.get("columnFormGroups") as UntypedFormArray)
      // each column
      .value.forEach(c => {
        (c.cdkDropListData as ControlContainerComponent[])
        // each control contained in column.
        .forEach(control => {
          control.activeView = value;
        })
      });
  }
}
