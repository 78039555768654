import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service';
import { FormCatagory } from '../dao/form-catagory';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';

@Injectable({
  providedIn: 'root'
})
export class FormCatagoryService extends DatabaseStoreService<FormCatagory> {

  constructor( fs: FormCatagoryFirestoreService, store: FormCatagoryStoreService, auth: AuthenticationService) {
  super(fs, store,auth);
  }

}

@Injectable({
  providedIn: 'root'
})
export class FormCatagoryStoreService extends StateChangeStoreService<FormCatagory> {
  protected store = "FormCatagory-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, FormCatagory>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class FormCatagoryFirestoreService extends FirestoreBackend<FormCatagory> {

 protected basePath = "FormCatagory";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): FormCatagory {
return new FormCatagory(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new FormCatagory(), authService);
 }
}
