<app-navigation></app-navigation>
<div class="container1">
  <div class="left-side"></div>
  <div class="middle">
      <div>
        <div class="main" fxLayout="column" style="background-color: white;">
          <app-jobs-page-header
            [jobNumber]="(activeJob | async)?.serviceProviderJobId">
          </app-jobs-page-header>
          <app-customer-job-section
          [customers]="customers"
          [job]="activeJob"
          (addedCustomer)="addedCustomer.next($event)"
          ></app-customer-job-section>


<!-- Section display for short Screens (Tabs) -->
<mat-tab-group animationDuration="0ms" class="short-screen" [selectedIndex]="1" style="margin-top: 20px;">

  <!-- front filler -->
  <mat-tab disabled="true" style="width: 20px;">
    <ng-template mat-tab-label style="width: 20px;">
    </ng-template>
  </mat-tab>
  <!-- end front filler -->

  <!-- Jobs Section Tab -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon style="color: #209334;" class="example-tab-icon">work</mat-icon>
      Assigned Work
    </ng-template>
     <app-line-item-display
     *ngIf="lineItems$ | async"
     [lineItems]="lineItems$"
     [intendedAudience]="2"
     (addLineItem$)="addLineItem$.next($event)"
     (editLineItem$)="editLineItem$.next($event)"
    (removeLineItem$)="removeLineItem$.next($event)"
     >
   </app-line-item-display>
  </mat-tab>
<!--End Jobs Section Tab -->

<!-- Site Visits Section Tab -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon style="color: #209334;" class="example-tab-icon">local_shipping</mat-icon>
      Site Visits
    </ng-template>
    <multiple-site-visit-summary
    *ngIf="(activeJob | async)?.siteVisits"
    [siteVisits]="siteVisit$"
    ></multiple-site-visit-summary>
  </mat-tab>
  <!-- End Site Visits Section Tab -->

  <!-- Estimates Section Tab -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon style="color: #209334;" class="example-tab-icon">text_snippet</mat-icon>
      Estimates
    </ng-template>
        <app-multiple-estimate-summary
        [estimates]= "estimates"
        [customer]="primaryCustomer$"
        [job]="(activeJob|async)"
        [serviceAddress]="(activeJob|async)?.serviceAddress"
    ></app-multiple-estimate-summary>
  </mat-tab>
<!-- End Estimates Section Tab-->

  <!-- Invoices Section Tab -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon style="color: #209334;" class="example-tab-icon">attach_money</mat-icon>
      Invoices
    </ng-template>
        <app-invoice-display
        [invoices] = "invoices"
        [addToJournalEntry] = true
        ></app-invoice-display>
  </mat-tab>
<!-- End Invoices Section Tab-->

  <!-- back filler -->
  <mat-tab disabled="true" style="width: 100%;">
    <ng-template mat-tab-label style="width: 100%;">
    </ng-template>
  </mat-tab>
  <!-- end back filler -->

</mat-tab-group>
<!-- End Section display for Small Screens -->


</div>
</div>
<div class="right-side"></div>
</div>


