import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomTagComponentInputs, CustomTagComponentOutputs } from './custom-tags/custom-tags.component';
import { GenericServiceProviderSetting } from '../../../common/src/data/dao/generic-service-provider-setting';
import { GenericServiceProviderSettingService } from '../../../common/src/data/dao-services/generic-service-provider-setting.service';
import { where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class CustomTagsService {

  parentToTags = new Map<string,ReplaySubject<GenericServiceProviderSetting[]>>()

  constructor(private genericServiceProviderSettingService: GenericServiceProviderSettingService) { }

  buildCustomTagsInput(arraySelectedTags: GenericServiceProviderSetting[], initiallyDisabled: boolean, unsuscribeObs: Observable<any>, parentCollectionName: string, title: string) : CustomTagComponentInputs{
    const retVal: CustomTagComponentInputs = new CustomTagComponentInputs();
    if (!this.parentToTags.has(parentCollectionName)) {
      const parentCollectionObs$ =  new ReplaySubject<GenericServiceProviderSetting[]>(1);
      this.parentToTags.set(parentCollectionName, parentCollectionObs$);
      this.genericServiceProviderSettingService.queryFirestoreShallow$([where('parentCollectionName', '==', parentCollectionName),
        where('active','==',true)]).pipe(
        map(tags => tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))),
        takeUntil(unsuscribeObs),
        finalize(() => {
          this.parentToTags.delete(parentCollectionName);
        }),
        ).subscribe(parentCollectionObs$);
    }
    const existingTags = this.parentToTags.get(parentCollectionName)
    const existingTagsUpdated = existingTags.pipe(map(() => true));

    retVal.title = title;
    retVal.assignedTags = arraySelectedTags;
    retVal.existingTags = existingTags;
    retVal.existingTagsUpdated = existingTagsUpdated;
    retVal.disableInput = new BehaviorSubject<boolean>(initiallyDisabled);
    return retVal;
  }

  buildCustomTagsOutput(arraySelectedTags: GenericServiceProviderSetting[], unsuscribeObs: Subject<any>, parentCollectionName: string,
    allowUserToCreateNewTags: boolean = false) : CustomTagComponentOutputs{
    const retVal: CustomTagComponentOutputs = new CustomTagComponentOutputs();

    const existingTagAdded = new EventEmitter<GenericServiceProviderSetting>();
    const existingTagRemoved = new EventEmitter<GenericServiceProviderSetting>();
    const newTagCreatedForAddition = new EventEmitter<string | GenericServiceProviderSetting>();

    existingTagAdded.pipe(
      tap(tag => {
        if (!arraySelectedTags.find(t => t.docId === tag.docId)) {
        arraySelectedTags.push(tag)
        }
      }),
      takeUntil(unsuscribeObs)).subscribe();

      existingTagRemoved.pipe(
     tap(tag => {
      if (arraySelectedTags.findIndex(x =>  x.docId === tag.docId) > -1) {
      arraySelectedTags.splice(arraySelectedTags.findIndex(x =>  x.docId === tag.docId),1)
      }}),
     takeUntil(unsuscribeObs)).subscribe();

     const fullyFormedNewTag = newTagCreatedForAddition.pipe(
       filter(x => (x as GenericServiceProviderSetting).parentCollectionName !== undefined),
       tap(x => console.log(x,` string`)),
       map(x => x as GenericServiceProviderSetting)
     );

     const namedOnlyTag = newTagCreatedForAddition.pipe(
      filter(x => (x as GenericServiceProviderSetting).parentCollectionName === undefined),
      map(x => x as string),
      map( tagName => new GenericServiceProviderSetting({name: tagName, active: true, createdOn: new Date(), parentCollectionName, colorAssocatedWithSetting: 'red'}))
     );

     if (allowUserToCreateNewTags) {
        merge(fullyFormedNewTag,namedOnlyTag).pipe(
          map(tag => {
            tag.active=true;
            return tag;
          }),
        switchMap(tag => this.genericServiceProviderSettingService.create$(tag).pipe(
          tap(x => console.log(x)),
          tap(tag => arraySelectedTags.push(this.genericServiceProviderSettingService.get(tag.docId)),
        ))),
        takeUntil(unsuscribeObs)).subscribe();
     }

      retVal.newlyCreatedTagForAdditon = newTagCreatedForAddition;
      retVal.tagSelectedForAddition = existingTagAdded;
      retVal.tagSelectedForRemoval = existingTagRemoved;
      return retVal;
  }
}
