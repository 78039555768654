import { Component,  OnDestroy, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { SitewideSearchResult } from './sitewide-search.service';
import {debounceTime, tap,  filter, distinctUntilChanged, map, takeUntil, share} from 'rxjs/operators';
import {Observable,Subject, merge,  BehaviorSubject} from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { randomElementName } from '../../../../common/src/util/util';
import { Job } from '../../../../common/src/data/dao/job';
import { Router } from '@angular/router';
import {GlobalSearchService} from '../../../../common/src/data/services/global-search.service';
import {CustomerSearchService} from '../../../../common/src/data/services/customer-search.service';
import { searchResults } from '../../../../common/src/data/services/search.service';

@Component({
  selector: 'app-sitewide-search-bar',
  templateUrl: './sitewide-search-bar.component.html',
  styleUrls: ['./sitewide-search-bar.component.scss']
})

export class SitewideSearchBarComponent implements OnInit, OnDestroy {

  destroyingComponent$ = new Subject();

  sitewideSearchForm: UntypedFormGroup;
  selectedSearchResult: SitewideSearchResult;
  isLoading = false;
  skipAddressSearchEmissionOnSelect: boolean = false;

  searchResultAndJob$: Observable< {res: searchResults,job: Job}[]>;
  activeSearch: boolean;
  displayNoResultsFound: boolean = false;

  customerResultCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  jobResultCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  searchRaw$ = new Subject<string>();
  form: UntypedFormGroup;


  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private customerSearchService: CustomerSearchService)
  {
    this.form = this.fb.group({
      serviceAddressSearch: [''],
    });
  }

  searchResultAndJob(index: number, item: {res: searchResults,job: Job}) {
    return item.job ? item.job.DocId() : item.res.customer.DocId();
  }

  ngOnInit() {

    const debounceSearch = this.searchRaw$.pipe(
      filter(() => !this.skipAddressSearchEmissionOnSelect),
      distinctUntilChanged(),
      tap(() => this.displayNoResultsFound = false),
      debounceTime(300));

    const searchToEmit = debounceSearch.pipe(
      filter(x => x.length > 2),
      tap(x => this.activeSearch = true),
      tap(x => this.globalSearchService.addSearch(this.customerSearchService,"sitewideSearchBar",x)),
    );

    const resetAddressSearch = debounceSearch.pipe(
      filter( x => x.length <= 2),
      map(() => ([])));

    const searchToSkip = this.searchRaw$.pipe(
      filter(() => this.skipAddressSearchEmissionOnSelect),
      tap(() => this.skipAddressSearchEmissionOnSelect = false));


        this.searchResultAndJob$ =
      merge(resetAddressSearch, this.globalSearchService.observeResults(this.customerSearchService,"sitewideSearchBar", false).pipe(
        tap(() => this.activeSearch = false),
        map(x => x.filter(y => y.res !== null)),
        )).pipe(
          share()
        )

        this.searchResultAndJob$.pipe(
          map(x => x.filter(x => x.job === null && x.res!==null).length),
        ).subscribe(x => this.customerResultCount$.next(x));

        this.searchResultAndJob$.pipe(
          map(x => x.filter(x => x.job !== null && x.res!==null).length)).subscribe(x => this.jobResultCount$.next(x));

      merge(searchToEmit,searchToSkip).pipe(
      takeUntil(this.destroyingComponent$)).subscribe();
  }

  searchKeyUp(event: KeyboardEvent) {
    this.searchRaw$.next((event.target as HTMLTextAreaElement).value)
  }

  getStringRepresentationOfSearchResults(a: {res: searchResults,job: Job}) {
    if (a.job === null && a.res !== null) {
      return `${a.res.customer.customerName} - ${a.res.customer.primaryPhoneNumber}`;
    } else {
      if (a.job === null && a.res === null) {
      return "";
      } else {
        return `Job # ${a.job.serviceProviderJobId} - ${a.res.customer.customerName} ${a.job.serviceAddress.formattedAddress()}`;
      }
    }
  }

  excludeEnter(event:any){
    if(event.keyCode == 13) {
      event.preventDefault();
    }
  }

  optionSelected(e: MatAutocompleteSelectedEvent) {
    this.skipAddressSearchEmissionOnSelect = true;
    const searchValue : {res: searchResults,job: Job} = e.option.value;
    this.form.patchValue({ serviceAddressSearch : ""});
    if (searchValue.job) {
      this.router.navigate(['/job-page', {jobDocId: searchValue.job.DocId()}]);
    } else if (searchValue.res) {
      this.router.navigate(['/app-customer-page', {customerDocId: searchValue.res.customer.DocId()}]);
    }
  }

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

}
