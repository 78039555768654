import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { randomElementName } from '../../../../../../../common/src/util/util';

@Component({
  selector: 'app-drag-drop-list-builder',
  templateUrl: './drag-drop-list-builder.component.html',
  styleUrls: ['./drag-drop-list-builder.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropListBuilderComponent implements OnInit {
  index: number = 10;

  @Input() form: UntypedFormGroup;
  @Output() formArrayElementGainedFocus: Subject<number> = new Subject<number>();
  @Output() formArrayElementLostFocus: Subject<number> = new Subject<number>();

  randomElementName : string = randomElementName();

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  get disableDelteElementButton(): boolean {
    return this.draggableComponents.length < 2;
  }

  get draggableComponents(): UntypedFormArray {
    return this.form.get('draggableComponents') as UntypedFormArray;
  }

  draggableComponentAsUntypedFormGroup(c: AbstractControl<any,any>): UntypedFormGroup {
    return c as UntypedFormGroup;
  }

  get jsonForm(): string {
    return JSON.stringify(this.form.value);
  }

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    (this.form.get('draggableComponents') as UntypedFormArray).value.forEach(val => {
      this.index = Number(val.value) >  this.index ? Number(val.value) : this.index;
    });
  }

  onTalkDrop(e: any) {
    const event = e as CdkDragDrop<string[]>;
    // moved within same container.
    if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        moveItemInArray(this.draggableComponents.value,event.previousIndex, event.currentIndex)
        this.draggableComponents.at(0).patchValue(this.draggableComponents.at(0).value);
    }
  }

  focused(f: UntypedFormGroup): boolean {
       return f.get("focused") === null ? false : f.get("focused").value;
  }

  focusGained(index: number) {
    //add focused class to element @ index and remove from all others.
    (this.draggableComponents as UntypedFormArray).controls.forEach(c =>
      c.patchValue({focused: false}))
    this.draggableComponents.at(index).patchValue({focused: true});
    //emit event to any observers.
    this.formArrayElementGainedFocus.next(index);
  }

  deleteElement(index: number) {
    this.draggableComponents.removeAt(index);
  }

  addElement(index: number) {
    this.index++;
    const newVal = this.fb.group({
      label: this.fb.control('New value'),
      value: `${this.index}`,
      focused: false
    });
    this.draggableComponents.insert(index+1,newVal );
    this.focusGained(index+1);
  }

}
