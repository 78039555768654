import { Customer } from "./customer";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Employee } from "./employee";
import { InvoicePayment } from "./invoice-payment";

export enum JOURNAL_ENTRY_TYPE {
  CREDIT = "Credit",
  DEBIT = "Debit"
}

export enum JOURNAL_CREDIT_TYPE {
  CASH = "Cash",
  CHECK = "Check",
  CREDIT_CARD = "Credit Card",
  OTHER = "Other",
  CREDIT = "Credit"
}

export enum JOURNAL_DEBIT_TYPE {
  INVOICE = "Invoice",
  OTHER = "Other",
}


export class JournalEntry extends RetrieveFirestorePropertiesBase<JournalEntry>  {


memo: string = "";
billingCustomerDocId: string;
billingCustomer : Customer;
amount: number;
journalEntryType: JOURNAL_ENTRY_TYPE;
journalEntryCreditType: JOURNAL_CREDIT_TYPE;
journalEntryDebitType: JOURNAL_DEBIT_TYPE;
checkNumber: string;
employee: Employee;
employeeDocId: string;

invoicePaymentDocIds: string[];
invoicePayments: InvoicePayment[];

void: boolean = false;
employeeVoiding: Employee;
employeeVoidingDocId: string;
voidedOn: Date;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["billingCustomer","employee","invoicePayments","employeeVoiding"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["billingCustomerDocId","employeeDocId",
"invoicePaymentDocIds","employeeVoidingDocId"]);
static _firestoreCompositionMemberNames = ["billingCustomer","employee","invoicePayments","employeeVoiding"];
static _firestoreCompositionalDiffMemberNames: string[] = ["billingCustomer","employee","invoicePayments","employeeVoiding"];

retrieveFirestoreIgnoredMemberNames() : string[] { return JournalEntry._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return JournalEntry._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return JournalEntry._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return JournalEntry._firestoreCompositionMemberNames;}

public constructor(init?: Partial<JournalEntry>) {
super();
Object.assign(this, init);
}
}
