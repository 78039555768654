import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyChangeDetectComponent } from './formly-wrapper-change-detect';
import { FormlyModule } from '@ngx-formly/core';


@NgModule({
  declarations: [FormlyChangeDetectComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      wrappers: [
        { name: 'change-detect', component: FormlyChangeDetectComponent},
      ]
    }),
  ]
})
export class FormlyWrapperChangeDetectModule { }
