import { Injectable } from '@angular/core';
;
import { delayWhen, filter, switchMap, take, tap } from 'rxjs/operators';
import { AddressService } from './address.service';
import { AuthenticationService } from '../../util/authentication.service';
import { CustomerService } from './customer.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { Discount } from '../dao/discount';
import { DiscountService } from './discount.service';
import { EmployeeService } from './employee.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FormFirestore } from '../dao/form-firestore';
import { FormFirestoreSummaryService } from './form-firestore-summary.service';
import { FormFirestoreService } from './form-firestore.service';
import { FormModelFirestoreService } from './form-model-firestore.service';
import { JobService } from './job.service';
import { LineItem } from '../dao/line-item';
import { LineItemService } from './line-item.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { Estimate } from '../dao/estimate';
import { of, Observable, combineLatest, map } from 'rxjs';
import { FormlyUtilityService } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/formly-utility.service';
import { WORKFLOW_STAGE } from '../../../../web-app/src/app/form-builder/component-models/formly-controls/utilities/fetch-updated-workflow.service';

@Injectable({
  providedIn: 'root'
})

export class EstimateService extends DatabaseStoreService<Estimate> {

  estimateFormFirestore: FormFirestore;

  constructor( fs: EstimateFirestoreService, authenticationService: AuthenticationService, store: EstimateStoreService, private lineItemService: LineItemService,
    private customerService: CustomerService, private jobService: JobService, private employeeService: EmployeeService,
    private addressService:AddressService, private discountService: DiscountService, private formModelFirestoreService: FormModelFirestoreService,
    private settingsService: SettingsService, private formFirestoreSummaryService: FormFirestoreSummaryService,
    private formFirestoreService: FormFirestoreService, private formlyUtilityService: FormlyUtilityService ) {
    super(fs, store, authenticationService,new Map([
      ["lineItems", {associatedDocumentId: "lineItemDocIds", compositionStoreService: lineItemService}],
      ["customer", {associatedDocumentId: "customerDocId", compositionStoreService:
        customerService}],
      ["employee", {associatedDocumentId: "employeeDocId", compositionStoreService: employeeService}],
      ["serviceAddress", {associatedDocumentId: "serviceAddressDocId", compositionStoreService: addressService}],
      ["job", {associatedDocumentId: "jobDocId", compositionStoreService: jobService}],
      ["formModelFirestore", {associatedDocumentId: "formModelFirestoreDocId", compositionStoreService: formModelFirestoreService}],
      ["discounts", {associatedDocumentId: "discountDocIds",compositionStoreService: discountService}]]));


        of(null).pipe(
        delayWhen(() => this.settingsService.settingsLoaded$),
        switchMap(() => this.formFirestoreSummaryService.load$(this.settingsService.getValue('defaultFormFirestoreSummaryEstimateDocId'))),
          filter(x => x != null),
          switchMap(summary => this.formFirestoreService.load$(this.formlyUtilityService.workFlowStage === WORKFLOW_STAGE.DEPLOYED ?
            summary.currentDeployedFirestoreDocId : summary.currentDesignFirestoreDocId)),
          tap(formFirestore => this.estimateFormFirestore = formFirestore),
        take(1),
      ).subscribe();

  }


  createCopy(source: Estimate) : Observable<Estimate> {
    const retVal = new Estimate(source);
    const docIdsToPopulate$ : Observable<void>[] = [];
    retVal.createdAt = new Date();
    retVal.lineItems = [];
    retVal.discounts=  [];
    retVal.formModelFirestoreDocId = null;
    retVal.formModelFirestore = null;
    retVal.mostRecentlySentCommunicationDocId = null;
    retVal.mostRecentlySentCommunicationDate = null;
    docIdsToPopulate$.push(this.retrieveDocId(retVal));
    source.lineItems.forEach(lineItem => {
      const l = new LineItem(lineItem);
      if (l.originatingLineItemDocId === null) {
        l.originatingLineItemDocId = lineItem.lineItemDocId;
      }
      l.originatingEstimateDocId = retVal.DocId();
      docIdsToPopulate$.push(this.lineItemService.retrieveDocId(l));
      retVal.lineItems.push(l);
    });
    source.discounts.forEach(discount => {
      const d = new Discount(discount);
      d.setCalculatedDiscountAmount(0);
      docIdsToPopulate$.push(this.discountService.retrieveDocId(d));
      retVal.discounts.push(d);
    });
    retVal.employee = this.employeeService.get(this.authenticationService.activelyLoggedInEmployeeDocId);
    return combineLatest(docIdsToPopulate$).pipe(
      map(() => retVal)
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class EstimateStoreService extends StateChangeStoreService<Estimate> {
  protected store = "estimate-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, Estimate>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class EstimateFirestoreService extends FirestoreBackend<Estimate> {

  protected basePath = "estimate";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Estimate {
    return new Estimate(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new Estimate(), authService);
  }
}
