import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { combineLatest } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { ControlContainsLiveviewComponent } from '../control-contains-liveview.component';
import { LineItemControlType } from './line-item-enums';


@Component({
  selector: 'app-line-items',
  templateUrl: './line-items.component.html',
  styleUrls: ['./line-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemsComponent extends ControlContainsLiveviewComponent implements OnInit, AfterViewInit {

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Line Items");
    retVal.patchValue({
      icon: "dehaze",
      iconColor: "blue",
      controlComponentFormGroup: this.createLineItemsFormGroup(),
    });
    return retVal;
  }

  createLineItemsFormGroup() {
    const retVal =  this.fb.group({
      label:  "",
      lineItemControlType: LineItemControlType.EstimateCreator
    });

    retVal.get("lineItemControlType").valueChanges.pipe(
      startWith(retVal.get("lineItemControlType").value),
      tap(t => {
        switch (t) {
          case LineItemControlType.EstimateCreator:
            retVal.patchValue({label: "Estimate Creator"});
            break;
          case LineItemControlType.LineItemsToComplete:
            retVal.patchValue({label: "Line Items To Complete"});
            break;
          case LineItemControlType.InvoiceDisplay:
            retVal.patchValue({label: "Invoice Display"});
            break;
          case LineItemControlType.InvoiceEditor:
            retVal.patchValue({label: "Invoice Editor"});
            break;
            default:
            retVal.patchValue({label: ""});
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    return retVal;
  }

  patchControlComponentsToFormlyFields(): void {
    const obs$ = this.patchControlComponentsToFormlyFieldsCommon();

    combineLatest([...obs$]).pipe(
      tap(() => {
        this.fields[0].props.changeDetect.next();
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();
  }
  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {
    super.patchCommonFieldsToForm(formlyConfig);
    this.componentForm.patchValue({lineItemControlType: formlyConfig.props["lineItemControlType"]});
    this.componentForm.patchValue({label: formlyConfig.props["label"]});
  }

  toFormlyFieldConfig(): FormlyFieldConfig | FormlyFieldConfig[] {
    const retVal = super.convertFormlyFieldConfigToLiveViewVersion(this.fields[0]);
    retVal.props.disabled=true;
    return retVal;
  }

  initilizeFormlyFields() {
    this.fields =
      [
    this.formlyLiveViewFields({
      type: "formlyLineItems",
      label: "Line Items",
      hidden: false,
      props: {
        appearance: "outline",
        activeView: "printView",
        lineItemControlType: LineItemControlType.EstimateCreator,
      },
      className: "",
    })
    ];
    this.fields[0].wrappers = ["change-detect"];
  }

  constructor(protected fb: UntypedFormBuilder) {
    super(LineItemsComponent,fb);
    this.form = this.initilizeFormGroup();
    this.initilizeFormlyFields();
  }
  ngAfterViewInit(): void {
    this.patchControlComponentsToFormlyFields();
  }

  ngOnInit(): void {
  }

}
