import { Injectable } from '@angular/core';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { AuthenticationService } from '../../util/authentication.service';
import { Customer } from '../dao/customer';
import { CustomerService } from './customer.service';
import { AddressService } from './address.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {AddressCustomerMapping} from '../dao/address-customer-mapping';

@Injectable({
  providedIn: 'root'
})
export class AddressCustomerMappingService extends DatabaseStoreService<AddressCustomerMapping> {

  constructor( fs: AddressCustomerMappingFirestoreService, authenticationService: AuthenticationService, store: AddressCustomerMappingStoreService,
    private customerService: CustomerService, private addressService: AddressService) {
    super(fs, store, authenticationService, new Map([
      ["serviceAddress", {associatedDocumentId: "serviceAddressDocId",
                            compositionStoreService: addressService}],
      ["primaryCustomer", {associatedDocumentId: "primaryCustomerDocId", compositionStoreService:
      customerService}],
      ["billingCustomers", {associatedDocumentId: "billingCustomerDocIds", compositionStoreService:
      customerService}],
      ["siteVisitContactCustomers", {associatedDocumentId: "siteVisitContactCustomerDocIds", compositionStoreService:
      customerService}],
    ]));
  }

  loadAddressMatches(addressDocIds: string[]): Observable<Map<string, Customer[]>> {
    return (this.fs as AddressCustomerMappingFirestoreService).loadAddressMatches(addressDocIds).pipe(
      map(addressCustMappings => {
          const customerDocIdToAddressDocId = new Map<string, string>();
          addressCustMappings.forEach( cust => {
            cust.billingCustomerDocIds.forEach(doc => customerDocIdToAddressDocId.set(doc,cust.serviceAddressDocId));
            cust.siteVisitContactCustomerDocIds.forEach(doc => customerDocIdToAddressDocId.set(doc,cust.serviceAddressDocId));
          });
          return customerDocIdToAddressDocId;
        }),
        switchMap(customerDocIdToAddressDocId => this.customerService.queryFirestoreForInValues("customerDocId", customerDocIdToAddressDocId.size > 0 ? [...customerDocIdToAddressDocId.keys()] : []).pipe(
          map(customers => {
            const addressDocToCustomers = new Map<string, Customer[]>();
            customers.forEach(customer => {
              const addressDocId = customerDocIdToAddressDocId.get(customer.DocId());
              if (!addressDocToCustomers.has(addressDocId)) {
                addressDocToCustomers.set(addressDocId, []);
              }
                addressDocToCustomers.get(addressDocId).push(customer);
            })
              return addressDocToCustomers;
            }),
          catchError(err => {
            console.log('Error writing to firestore.', err);
            return throwError(err);
        }),
        ),

    ),
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AddressCustomerMappingStoreService extends StateChangeStoreService<AddressCustomerMapping> {
  protected store = "address-customer-matching-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, AddressCustomerMapping>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class AddressCustomerMappingFirestoreService extends FirestoreBackend<AddressCustomerMapping> {

  protected basePath = "address-customer-matching";

  loadAddressMatches(addressDocIds: string[]): Observable<AddressCustomerMapping[]> {
    return this. queryFirestoreForInValues("serviceAddressDocId", addressDocIds);
  }

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): AddressCustomerMapping {
    return new AddressCustomerMapping(obj);
  }

  constructor(protected authService: AuthenticationService) {
    super(new AddressCustomerMapping(), authService);
  }

}
