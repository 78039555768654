import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject, pipe, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomTagsService } from '../custom-tags.service';
import { CustomTagComponentInputs, CustomTagComponentOutputs } from '../custom-tags/custom-tags.component';
import { Customer } from '../../../../common/src/data/dao/customer';
import { GenericServiceProviderSetting } from '../../../../common/src/data/dao/generic-service-provider-setting';
import { ReferralCampaignService } from '../../../../common/src/data/dao-services/referral-campaign.service';
import { ReferralCampaign } from '../../../../common/src/data/dao/referral-campaign';
import { Address } from '../../../../common/src/data/dao/address';
import { CustomerService } from '../../../../common/src/data/dao-services/customer.service';
import { CustomerSearchService } from '../../../../common/src/data/services/customer-search.service';
import { searchSource } from '../../../../common/src/data/services/search.service';


@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit, OnDestroy {

  activeReferralCampaigns: Observable<ReferralCampaign[]>;

  searchCustomer$ : Subject<string> = new Subject<string>();
  activeSearchCustomer: boolean = false;
  displayNoResultsFoundCustomer: boolean;
  customersFound$: Subject<Customer[]> = new Subject<Customer[]>();
  activeSearchTermCustomer = "";

  customerTags: GenericServiceProviderSetting[] = [];

  destroyCompoenent$: Subject<any> = new Subject();
  customTagComponentInputs : CustomTagComponentInputs;
  CustomTagComponentOutputs : CustomTagComponentOutputs;
  skipCustomerSearchEmissionOnSelect: boolean = false;

  searchAddress$ = new EventEmitter();
  addressesFound$: Subject<Address[]> = new Subject<Address[]>();
  //We must maintain active search term to prevent later emissions from previously submitted searches from being processed.
  activeSearchTerm = "";
  activeSearch: boolean = false;
  displayNoResultsFound: boolean;

  emailsCheckedForDuplication = new Set<string>();
  phoneNumbersCheckedForDuplication = new Set<string>();
  activeDuplicationSearchTerm = "";

  searchExistingCustomers: boolean = true;

  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AddCustomerComponent>,
  private dialog: MatDialog, private customerService: CustomerService, private fb: UntypedFormBuilder, private referralCampaignService: ReferralCampaignService,
    private customerSearchService: CustomerSearchService, private tagService: CustomTagsService) {

      this.form = this.createForm();

      if (data) {
        if (data.address && data.address !== "") {
          this.form.patchValue({ billingAddressDisplay : data.address.formattedAddress(), billingAddress: data.address});
        }
        if (data.searchExistingCustomers !== undefined) {
          this.searchExistingCustomers = data.searchExistingCustomers;
        }
        if (data.customer) {
          of(data.customer).pipe(
            take(1),
            this.loadCustomer(),
          ).subscribe();
        }
      }

    this.activeReferralCampaigns = this.referralCampaignService.allActive;
  }

  ngOnDestroy(): void {
    this.destroyCompoenent$.next(null);
    this.destroyCompoenent$.complete();
  }

  customerSearchKeyUp(event: KeyboardEvent) {
    this.searchCustomer$.next((event.target as HTMLInputElement).value);
  }

  billingAddressSearchKeyUp(event: KeyboardEvent) {
    this.searchAddress$.emit((event.target as HTMLInputElement).value);
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.valid) {
      let customer = this.form.value["selectedCustomer"];
      const address: Address = this.form.value["billingAddress"];
      if (customer === "") {
        customer = new Customer();
      }
      address.unit = this.form.value["billingAddressUnit"];
      customer.customerName = this.form.value["customerName"];
      customer.primaryPhoneNumber= this.form.value["phoneNumber"],
      customer.primaryPhoneNumberExtension = this.form.value["primaryPhoneNumberExtension"],
      customer.alternativePhoneNumber = this.form.value["secondaryPhoneNumber"],
      customer.alternativePhoneNumberExtension = this.form.value["secondaryPhoneNumberExtension"],
      customer.address = address;
      customer.customerReferralCampaign = this.form.value["customerReferral"];
      customer.customerNotes = this.form.value["customerNotes"];
      customer.company = this.form.value["company"];
      customer.customerEmail = this.form.value["primaryEmail"];
      customer.customerTags = this.customerTags;
      this.dialogRef.close(customer);
    } else {
      this.form.markAllAsTouched();
      //Billing address display stores both SP search for address, and the selected result.  Therefore we patch in blank value to invalidate
      // if SP actions didn't result in a valid address being selected.
      if (this.form.controls["billingAddress"].invalid) {
        this.form.controls["billingAddressDisplay"].patchValue("");
      }
    }
  }

  createForm() : UntypedFormGroup {
    const randomElementName = (Math.random().toString(36) + '00000000000000000').slice(2, 14);

    return this.fb.group ({
      noAutoFill: [{value: randomElementName, disabled: true}],
      customerName: ["", [Validators.required]],
      company: [""],
      phoneNumber: ["", [Validators.required]],
      primaryPhoneNumberExtension: [""],
      secondaryPhoneNumber: [""],
      secondaryPhoneNumberExtension: [""],
      primaryEmail: ["", [Validators.email]],
      billingAddressDisplay:["", [Validators.required,]],
      billingAddress :["", [Validators.required]],
      billingAddressUnit:["",],
      customerReferral: [],
      customerNotes: [""],
      customerSearch: [""],
      selectedCustomer: [new Customer()],
    });
  }

  formattedCustomerSearch(c: Customer) : string {
    return `${c.customerName} - ${c.primaryPhoneNumber}`.trim();
  }

  loadCustomer = () => pipe (
    map(x => x as Customer),
    tap(selectedCustomer => {
      this.customerTags.splice(0);
      if (selectedCustomer.customerTags !== undefined) {
        selectedCustomer.customerTags.forEach(tag => this.customerTags.push(tag));
      }
      this.emailsCheckedForDuplication.add(selectedCustomer.customerEmail);
      this.phoneNumbersCheckedForDuplication.add(selectedCustomer.primaryPhoneNumber);
      this.form.patchValue({ customerName : selectedCustomer.customerName, phoneNumber: selectedCustomer.primaryPhoneNumber, primaryEmail : selectedCustomer.customerEmail,
        billingAddressDisplay: selectedCustomer.address.formattedAddress(), billingAddress: selectedCustomer.address, billingAddressUnit: selectedCustomer.address.unit,
        customerReferral: selectedCustomer.customerReferralCampaign, customerNotes: selectedCustomer.customerNotes, selectedCustomer, company: selectedCustomer.company});
      })
  );

  customerSearchOptionSelected(e: MatAutocompleteSelectedEvent) {
    //Patched in early b/c otherwise defaults to Object[Obj] until observable emits.  Go Duck Typing!
    this.form.patchValue({customerSearch : this.formattedCustomerSearch(e.option.value)})
    this.skipCustomerSearchEmissionOnSelect = true;
    this.customerService.load$((e.option.value as Customer).customerDocId).pipe(
      take(1),
      this.loadCustomer(),
    ).subscribe();
  }

  billingAddressOptionSelected(e: MatAutocompleteSelectedEvent) {
    this.form.patchValue({ billingAddressDisplay : e.option.value.formattedAddress(), billingAddress: e.option.value, billingAddressUnit: ""});
  }

  onEmailFocusOut() {
    //check for duplication first time valid email entered.
    const email = this.form.value["primaryEmail"];
    if (this.form.controls["primaryEmail"].status === "VALID" && !this.emailsCheckedForDuplication.has(email)) {
      this.customerSearchService.addSearch({search: email, componentSource: "customerAddDuplicationCheck", searchSources: [searchSource.InternalCustomerEmail]});
      this.emailsCheckedForDuplication.add(email);
      this.activeDuplicationSearchTerm = email;
    }
  }

  onPhoneNumberFocusOut() {
    const phoneNumber = this.form.value["phoneNumber"];
    if (this.form.controls["phoneNumber"].status === "VALID" && !this.phoneNumbersCheckedForDuplication.has(phoneNumber)) {
      this.customerSearchService.addSearch({search: phoneNumber, componentSource: "customerAddDuplicationCheck", searchSources: [searchSource.InternalCustomerPhone]});
      this.phoneNumbersCheckedForDuplication.add(phoneNumber);
      this.activeDuplicationSearchTerm = phoneNumber;
    }
  }

  ngOnInit(): void {
    this.customTagComponentInputs = this.tagService.buildCustomTagsInput(this.customerTags, false, this.destroyCompoenent$, 'customer_tags', 'Customer Tags')
    this.CustomTagComponentOutputs = this.tagService.buildCustomTagsOutput(this.customerTags, this.destroyCompoenent$, 'customer_tags');

    this.customerSearchService.searchResults$.pipe(
      filter(x => x.searchInput.componentSource === "customerAddDuplicationCheck" && x.searchInput.search === this.activeDuplicationSearchTerm),
      filter(x => x.results.length > 0),
      tap(x => window.alert(`Duplicate customer detected . ${x.searchInput.search} Customer details are loading, modify at will!`)),
      switchMap(x => this.customerService.load$(x.results[0].customer.customerDocId).pipe(
      take(1),
      this.loadCustomer())),
      takeUntil(this.destroyCompoenent$)
      ).subscribe();

    this.searchCustomer$.pipe(
      filter(() => !this.skipCustomerSearchEmissionOnSelect),
      distinctUntilChanged(),
      tap(() => this.displayNoResultsFoundCustomer = false),
      debounceTime(300),
      filter(x => x.length > 2),
      tap(x => {
        if (this.form.controls["selectedCustomer"].value === "" || this.formattedCustomerSearch(this.form.controls["selectedCustomer"].value) !== x) {
        this.activeSearchCustomer=true;
        this.activeSearchTermCustomer = x;
        this.customerSearchService.addSearch({search: x, componentSource: "addCustomerComponentSearchName",
          searchSources: [searchSource.InternalCustomerName, searchSource.InternalCustomerEmail, searchSource.InternalCustomerPhone, searchSource.InternalCustomerCompany] });
        }
      }),
      takeUntil(this.destroyCompoenent$)
      ).subscribe();

      this.customerSearchService.searchResults$.pipe(
        filter(x => x.searchInput.componentSource === "addCustomerComponentSearchName" && x.searchInput.search === this.activeSearchTermCustomer),
        tap(x => {
          this.activeSearchCustomer=false;
          if (x.results.length === 0) {
            this.displayNoResultsFoundCustomer = true;
          } else {
            this.displayNoResultsFoundCustomer = false;
          }
        }),
        // Since looking to add address to existing customer, remove results where customer is populated, and map to just address.
        map(x => x.results.map(z => z.customer)),
        tap(q => {
          console.log(q);
          // If there are no results, more then 1 result, or a result that doesn't match already selected value, patch in
          // blank value for billing address.
          if (q.length !== 1 ||  this.formattedCustomerSearch(q[0]) !== this.formattedCustomerSearch(this.form.controls["selectedCustomer"].value)) {
            this.form.patchValue({ selectedCustomer: ""});
          }
        }),
        takeUntil(this.destroyCompoenent$)
      ).subscribe(this.customersFound$);

      this.searchCustomer$.pipe(
        filter(() => this.skipCustomerSearchEmissionOnSelect),
        tap(() => this.skipCustomerSearchEmissionOnSelect = false),
        takeUntil(this.destroyCompoenent$)
      ).subscribe();

      this.searchCustomer$.pipe(
        filter(() => !this.skipCustomerSearchEmissionOnSelect),
        filter( x => x.length <= 2),
        tap(() =>  {
          this.activeSearchTermCustomer = "";
          this.activeSearchCustomer=false;
        }),
        map(() => ([])),
        takeUntil(this.destroyCompoenent$)
      ).subscribe(this.customersFound$);


    this.searchAddress$.pipe(
      distinctUntilChanged(),
      tap(() => this.displayNoResultsFound = false),
      debounceTime(300),
      filter(x => x.length > 2),
      tap(x => {
        if (this.form.controls["billingAddress"].value === "" || this.form.controls["billingAddress"].value.formattedAddress() !== x) {
        this.activeSearch=true;
        this.activeSearchTerm = x;
        // this.customerSearchService.addSearch({search: x, componentSource: "addCustomerComponentSearch", searchSources: [searchSource.InternalServiceAddress, searchSource.GooglePlaceAutoComplete]});
        this.customerSearchService.addSearch({search: x, componentSource: "addCustomerComponentSearch", searchSources: [searchSource.GooglePlaceAutoComplete]});
        }
      }),
      takeUntil(this.destroyCompoenent$)
      ).subscribe();

      this.searchAddress$.pipe(
        filter( x => x.length <= 2),
        tap(() =>  {
          this.activeSearchTerm = "";
          this.activeSearch=false;
        }),
        map(() => ([])),
        takeUntil(this.destroyCompoenent$)
      ).subscribe(this.addressesFound$);


      this.customerSearchService.searchResults$.pipe(
        filter(x => x.searchInput.componentSource === "addCustomerComponentSearch" && x.searchInput.search === this.activeSearchTerm),
        tap(x => {
          console.log(x);
          this.activeSearch=false;
          if (x.results.length === 0) {
            this.displayNoResultsFound = true;
          }
        }),
        // Since looking to add address to existing customer, remove results where customer is populated, and map to just address.
        map(x => x.results.filter(result => result.address !== null).map(z => z.address)),
        tap(q => {
          // If there are no results, more then 1 result, or a result that doesn't match already selected value, patch in
          // blank value for billing address.
          if (q.length !== 1 || this.form.controls["billingAddress"].value === "" || q[0].formattedAddress() !== this.form.controls["billingAddress"].value.formattedAddress()) {
            this.form.patchValue({ billingAddress: ""});
          }
        }),
        takeUntil(this.destroyCompoenent$),
        tap(z => console.log(z))
      ).subscribe(this.addressesFound$);
  }

  public displayAddressSelected(value) {
    return  (value !== undefined && value !== null && value !== "") ? value.formattedAddress() : '';
  }

  compareReferralCampaigns(o1: any, o2: any): boolean {
    return o1!== undefined && o2 !== undefined && o1 !== null && o2 !== null &&  o1.documentId === o2.documentId;
  }

}
