import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../util/authentication.service';
import {Iphoto} from '../dao/iphoto';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';


@Injectable({
    providedIn: 'root'
  })
export class IphotoService extends DatabaseStoreService<Iphoto> {

    constructor(fs: IphotoFirestoreService, authenticationService: AuthenticationService, store: IphotoStoreService)
    {
        super(fs, store, authenticationService);
    }
}


@Injectable({
    providedIn: 'root'
  })
  export class IphotoStoreService extends StateChangeStoreService<Iphoto> {
    protected store = "Iphoto-store";

    constructor(firestoreDiffService: FirestoreDiffService) {
      super(new Map<string, Iphoto>(), true, firestoreDiffService);
    }
  }

  @Injectable({
    providedIn: 'root'
    })
  class IphotoFirestoreService extends FirestoreBackend<Iphoto> {

    protected basePath = "tech_photos";

    public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Iphoto {
      return new Iphoto(obj);
    }

    constructor(protected authService: AuthenticationService) {
      super(new Iphoto(), authService);
    }
  }
