import { Component, Input, Output, OnDestroy, ChangeDetectionStrategy, OnInit } from '@angular/core';
import {  Subject } from 'rxjs';
import { MatSliderChange } from '@angular/material/slider';
import { VerticalSchedulerService } from 'web-app/src/app/vertical.scheduler.service';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { Job } from '../../../../../../common/src/data/dao/job';

@Component({
  selector: 'app-on-deck',
  templateUrl: './on-deck.component.html',
  styleUrls: ['./on-deck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnDeckComponent implements OnDestroy, OnInit {

  @Input() siteVisitsActivelyScheduling: {job: Job, hoursWorkingOnScheduling: number, prospectiveSiteVisitDurationHours: number};
  @Output() allowedCommuteSlider$ = new Subject<number>();
  @Output() prospectiveDurationHourUpdate$ = new Subject<number>();
  @Output() completedSchedulingFromOnDeck$ = new Subject<null>();
  @Output() schedulerLater$ = new Subject<null>();

  destroyingComponent$ = new Subject();

  schedulingService: VerticalSchedulerService;
  sliderValue: number;
  siteLengthValue: number;
  commuteSliderString: string;
  minutesFidelitySchedulingLengthSiteVisit: number;

  fifteenMinuteValueFromHoursSchedulingValue: number = 0;


  get workingOnSchedulingDisplay(): string { return this.formatHours(this.siteVisitsActivelyScheduling.hoursWorkingOnScheduling) }

  get totalJobHoursDisplay() : string { return this.formatHours(this.siteVisitsActivelyScheduling.job.durationExpectedHours); }

  formatHours(hours: number): string {
    return `${Math.floor(hours)}H, ${Math.round(((hours-Math.floor(hours))*60))}M`;
  }

  constructor(private _schedulingService: VerticalSchedulerService, private settingsService: SettingsService) {
    this.schedulingService = _schedulingService;
    this.minutesFidelitySchedulingLengthSiteVisit= this.settingsService.getValue("minutesFidelitySchedulingLengthSiteVisit");
  }
  ngOnInit(): void {
    this.fifteenMinuteValueFromHoursSchedulingValue = (this.siteVisitsActivelyScheduling.prospectiveSiteVisitDurationHours * 60) / this.minutesFidelitySchedulingLengthSiteVisit;
  }

  hoursSchedulingFromFifteenMinuteValue(val: number): number {
    let totalMinutes =  val * this.minutesFidelitySchedulingLengthSiteVisit;
    const hours = Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes - hours * 60;
    return hours + totalMinutes / 60;
}

  EmitSiteVisitLengthValue(value: MatSliderChange) {
      this.prospectiveDurationHourUpdate$.next(this.hoursSchedulingFromFifteenMinuteValue(value.value));
  }

  EmitCommuteSliderValue(value: MatSliderChange) {
    this.setPercentOfSiteVisitsToDisplay(value.value);
  }

  setPercentOfSiteVisitsToDisplay(num: number) {
    if (this.siteVisitsActivelyScheduling !== null) {
      this.allowedCommuteSlider$.next(num);
    }
  }

  formatSiteVisitDurationSlider = (value: number) => {
      let minutes = value * this.minutesFidelitySchedulingLengthSiteVisit;
      const hours = Math.floor(minutes / 60);
      minutes = Math.round(minutes - hours * 60);
      const minString = minutes < 10 ? `0${minutes}` : minutes.toString();
      return `${hours}:${minString}`;
    }

  formatCommuteSlider = (v: VerticalSchedulerService) => {
    return (value: number) => {
      if (value !== v.commuteSliderValue || v.commuteSliderUpdate) {
        v.commuteSliderUpdate = false;
        v.commuteSliderValue = value;
        let retVal = "";
        const schedulingService = v;
        const minutesAllowed = schedulingService.getCommuteDeltaMaxAssociatedWithSliderPercent(value);
        if (this.siteVisitsActivelyScheduling !== null) {
          this.allowedCommuteSlider$.next(value);
        }
        if (minutesAllowed === 1440) {
          retVal = "all";
        } else {
          const hours = minutesAllowed > 0 ? Math.floor(minutesAllowed / 60) : Math.ceil(minutesAllowed / 60);
          const hoursString = minutesAllowed >= 0 ? hours.toString() : `-${hours}`;
          const minutes = minutesAllowed - hours * 60;
          const minString = minutes < 0 ? minutes > -10 ? `0${-minutes}` : -minutes.toString() :
            minutes < 10 ? `0${minutes}` : minutes.toString();
          retVal = `${hoursString}:${minString}`;
        }
        v.commuteSliderStringRepresentation = retVal;
        return retVal;
    } else {
        return v.commuteSliderStringRepresentation;
    }
  }
}

  get  disableSlider() {
    return this.siteVisitsActivelyScheduling === null;
  }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }
}
