import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { format, startOfDay, toDate } from 'date-fns';
import { interval, Observable, zip } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { CustomerCommunicationService } from '../../../../../common/src/data/dao-services/customer-communication.service';
import { CustomerCommuncationStatus, CustomerCommunication } from '../../../../../common/src/data/dao/customer-communication';
import { CustomerCommunicationType } from '../../../../../common/src/data/dao/customer-communication-template';
import { CustomerCommunicationsDeliveryDetailsModalComponent } from '../customer-communications-delivery-details-modal/customer-communications-delivery-details-modal.component';

@Component({
  selector: 'app-customer-communication-display-modal',
  templateUrl: './customer-communication-display-modal.component.html',
  styleUrls: ['./customer-communication-display-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerCommunicationDisplayModalComponent implements OnInit {

  activeCustomerCommunication: CustomerCommunication;
  dataSource: Observable<CustomerCommunication[]>;
  // displayedColumns: string[] = ['primaryCustomerContact', 'customerName', 'templateName', 'CommunicationStatus', 'sentDate','deliveryDetails','docId'];
  displayedColumns: string[] = ['primaryCustomerContact', 'customerName', 'templateName', 'CommunicationStatus', 'sentDate','deliveryDetails'];

  customerCommunicationsDeliveryDetailsModalRef: MatDialogRef<CustomerCommunicationsDeliveryDetailsModalComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CustomerCommunicationDisplayModalComponent>,
  private snackBar: MatSnackBar, private customerCommunicationService: CustomerCommunicationService, private dialog: MatDialog)  {
    this.dataSource = data.dataSource;

    this.dataSource.pipe(
      tap(x => console.log(x,` string`)),
    ).subscribe();
  }

  customerCommunicationContactUtilized (c: CustomerCommunication) : string {
    if (c.customerCommunicationType === CustomerCommunicationType.SMS) {
      return c.primaryCustomer?.primaryPhoneNumber;
    } else {
      return c.primaryCustomer?.customerEmail;
    }
  }

  setActiveCommunication(c: CustomerCommunication) {
    this.activeCustomerCommunication = c;
  }

  get commuinicationDestinationString(): string {
    return !this.activeCustomerCommunication ? '' : this.activeCustomerCommunication.to.join('; ');
  }

  getDeliveryDate (c: CustomerCommunication) : string {
    return this.getDateFormatForCommunication(c.actualDeliveryDate);
  }

  getCreationDate(c: CustomerCommunication) : string {
    return this.getDateFormatForCommunication(c?.createdAt);
  }

  getDateFormatForCommunication(c: Date) : string {
    if (!c) {
      return "";
    } else {
      if (startOfDay(c).getTime() === startOfDay(new Date()).getTime()) {
        return format(c,'h:mm a');
      } else {
        return format(c,'LLL d');
      }
    }
  }

  get actualDeliveryDate(): string {
    return !this.activeCustomerCommunication ? "" : this.activeCustomerCommunication.actualDeliveryDate === null ? "" :
    this.formatDeliveryDate(this.activeCustomerCommunication.actualDeliveryDate);
  }

  get scheduledDeliveryDate() : string {
    return !this.activeCustomerCommunication ? "" : this.activeCustomerCommunication.scheduledDeliveryDate === null ? "" :
     this.formatDeliveryDate(this.activeCustomerCommunication.scheduledDeliveryDate);
  }

  get creationDate() : string {
    return !this.activeCustomerCommunication ? "" : this.activeCustomerCommunication.createdAt === null ? "" :
     this.formatDeliveryDate(this.activeCustomerCommunication.createdAt);
  }

  formatDeliveryDate(d: Date) : string {
    return format(d,'EEE, LLL d, yyyy, h:mm a');
  }

  messagePending(c?: CustomerCommunication) {
    return c && c.customerCommunicationStatus === CustomerCommuncationStatus.SCHEDULED;
  }

  resendDisabled(c?: CustomerCommunication) {
    if (!c) {
      return true;
    } else {
      return this.messagePending(c);
    }
  }

  cancelMessageDisabled(c?: CustomerCommunication) {
    if (!c) {
      return true;
    } else {
      return !this.messagePending(c);
    }
  }

  deliveryDetailsModal(c: CustomerCommunication) {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         : c
      });

    this.customerCommunicationsDeliveryDetailsModalRef = this.dialog.open(CustomerCommunicationsDeliveryDetailsModalComponent, editorConfig);
  }

  resendMessage() {
    this.snackBar.open("Saving Updates",undefined);
    const copy = new CustomerCommunication(this.activeCustomerCommunication);
    copy.numberOfAdditionalMessagesToSend = 0;
    copy.customerCommunicationStatus = CustomerCommuncationStatus.SCHEDULED;
    copy.scheduledDeliveryDate = new Date();
    copy.actualDeliveryDate = null;
    zip(interval(350),this.customerCommunicationService.create$(copy)).pipe(
      tap(() => this.snackBar.dismiss()),
      take(1)
    ).subscribe();
    }

  cancelMessage() {
    this.snackBar.open("Cancelling Message Delivery",undefined);
    this.activeCustomerCommunication.stateChangeNotes = `Cancelled through Customer Communication Display Modal on ${new Date().toLocaleString()}`;
    this.activeCustomerCommunication.customerCommunicationStatus = CustomerCommuncationStatus.CANCELED;
    console.log(this.activeCustomerCommunication);
    zip(interval(700),this.customerCommunicationService.update$(this.activeCustomerCommunication)).pipe(
      tap(x => this.activeCustomerCommunication = x[1] as CustomerCommunication),
      tap(() => this.snackBar.dismiss()),
      take(1)
    ).subscribe();
  }

  exit(){
    this.dialogRef.close();
  }

  ngOnInit(): void {}

}
