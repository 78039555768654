<!--START OF CUSTOMER TAGS-->
<div class="container1">
  <div class="left-side"></div>
  <div>
    <div class="employee-grid">
<label class="header-label" style="margin-bottom: 0px;">Customer Tags</label>
<div class="label-style" fxLayout="row" fxLayoutAlign="end start" style="margin-top: 30px;">Create Customer Tag</div>
<div style="margin-bottom: 80px; margin-left: 30px;">
<app-custom-tags  class="form-align field-stretch"
[assignedTags] = "customerTagComponentInputs.assignedTags"
[existingTags] = "customerTagComponentInputs.existingTags | async"
[existingTagsUpdated] = "customerTagComponentInputs.existingTagsUpdated"
[disableInput] = "customerTagComponentInputs.disableInput"
[createTags] = true
(tagSelectedForRemoval) = "customerTagComponentOutputs.tagSelectedForRemoval.next($event)"
(tagSelectedForAddition) = "customerTagComponentOutputs.tagSelectedForAddition.next($event)"
(newlyCreatedTagForAdditon) = "customerTagComponentOutputs.newlyCreatedTagForAdditon.next($event)">
</app-custom-tags>
<mat-chip-listbox>
  <mat-chip-option [style.background]="tag.colorAssocatedWithSetting" style="color: white;" *ngFor="let tag of customerTagComponentInputs.existingTags | async"
  [selectable]="true"
  [removable]="true"
  (removed)="deactivateTag(tag)"
  >
  {{tag.name}}
  <mat-icon matChipRemove>cancel</mat-icon>
  <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{tag: tag}">
    <mat-icon>color_lens</mat-icon>
  </button>
  </mat-chip-option>
  </mat-chip-listbox>
  </div>
<!--END OF CUSTOMER TAGS-->

<!--START OF JOB TAGS-->
<label class="header-label" style="border-top: gainsboro 2px solid; margin-bottom: 0px;">Job Tags</label>
<div class="label-style" fxLayout="row" fxLayoutAlign="end start" style="margin-top: 30px;">Create Job Tag</div>
<div style="margin-bottom: 80px; margin-left: 30px;">
<app-custom-tags  class="form-align field-stretch"
[assignedTags] = "jobTagComponentInputs.assignedTags"
[existingTags] = "jobTagComponentInputs.existingTags | async"
[existingTagsUpdated] = "jobTagComponentInputs.existingTagsUpdated"
[disableInput] = "jobTagComponentInputs.disableInput"
[createTags] = true
(tagSelectedForRemoval) = "jobTagComponentOutputs.tagSelectedForRemoval.next($event)"
(tagSelectedForAddition) = "jobTagComponentOutputs.tagSelectedForAddition.next($event)"
(newlyCreatedTagForAdditon) = "jobTagComponentOutputs.newlyCreatedTagForAdditon.next($event)">
</app-custom-tags>

<mat-chip-listbox>
  <mat-chip-option [style.background]="tag.colorAssocatedWithSetting" style="color: white;" *ngFor="let tag of jobTagComponentInputs.existingTags | async"
  [selectable]="true"
  [removable]="true"
  (removed)="deactivateTag(tag)"
  >
  {{tag.name}}
  <mat-icon matChipRemove>cancel</mat-icon>
  <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{tag: tag}">
    <mat-icon>color_lens</mat-icon>
  </button>
  </mat-chip-option>
  </mat-chip-listbox>
  </div>

  <mat-menu matMenuContent #menu="matMenu">
    <ng-template let-tag="tag" matMenuContent>
    <button mat-menu-item (click)="changeColor(tag,'#cc2525e6')" style="background-color: #cc2525e6;">
      <mat-icon>dialpad</mat-icon>
      <span>Red</span>
    </button>
    <button mat-menu-item (click)="changeColor(tag,'#0060ffb5')" style="background-color: #0060ffb5;">
      <mat-icon>voicemail</mat-icon>
      <span>Blue</span>
    </button>
    <button mat-menu-item (click)="changeColor(tag,'#469419')" style="background-color: #469419;">
      <mat-icon>notifications_off</mat-icon>
      <span>Green</span>
    </button>
  </ng-template>
  </mat-menu>
  </div>
  <div class="right-side"></div>
  </div>
<!--END OF JOB TAGS-->
