import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyColumnSplitterComponent } from './formly-column-splitter.component';
import { FormlyModule } from '@ngx-formly/core';
import {AngularSplitModule} from 'angular-split';

@NgModule({
  declarations: [FormlyColumnSplitterComponent],
  imports: [
    CommonModule,
    AngularSplitModule,

    FormlyModule.forChild({
      types: [
        {name: 'formlySplitter', component: FormlyColumnSplitterComponent},
      ]}),
  ]
})
export class FormlyColumnSplitterModule { }
