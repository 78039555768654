import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {Device} from '@capacitor/device';
import {CameraResultType, Camera, CameraSource, Photo, ImageOptions} from '@capacitor/camera';
import {Filesystem, Directory} from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import {  BehaviorSubject, combineLatest, firstValueFrom, from, merge, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, concatMap, delay, distinctUntilChanged, filter, map, mergeMap, share, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AngularFirestorageService } from '../../../../web-app/src/app/angular-firestorage.service';
import { format, subDays, subMinutes } from 'date-fns';
import {Iphoto} from '../dao/iphoto';
import { IphotoService } from '../dao-services/Iphoto.service';
import {ImageCacheService} from '../../../../service-vanguard-mobile/src/app/cache/image-cache.service';
import { LoggingService } from '../logging/logging.service';
import { where } from 'firebase/firestore';

export function base64DataURLtoFile(dataurl, filename) {
  console.log(filename);
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  private platform: Platform;
  //string in Map is control that photo is registered to ( i.e. it is a photo associated with the 2nd of 5
  //formly photo picker controls.) + the instance of form being filled out.

  private deviceId: string;
  private photosActivelyUploading: Iphoto[] = [];

  private photosLoading: Map<string, ReplaySubject<Iphoto>> = new Map<string, ReplaySubject<Iphoto>>();
  private photoDocIdsWaitingFor = new Set<string>();
  private photosLoadedFromDevice: Subject<Iphoto> = new Subject<Iphoto>();

  private photosLoadedForControl: Map<string, ReplaySubject<Iphoto[]>> = new Map<string, ReplaySubject<Iphoto[]>>();

  ceaseMonitoringPhotoKey: Subject<string> = new Subject<string>();
  useDixie: boolean = true;
  photoLoadErrorMessage$: Subject<string | null> = new Subject<string | null>();

  constructor(platform: Platform, private iPhotoService: IphotoService, private afStorage: AngularFirestorageService,
    private imageCacheService: ImageCacheService, private loggingService: LoggingService) {
    this.platform = platform;
    console.log("FIXING TO GET DEVICE ID");
    from(Device.getId()).pipe(
      tap(x => console.log(`DEVICE ID:  ${x}`)),
      tap(x => this.deviceId = x.identifier),
      take(1)
    ).subscribe();
  }

  maintainPhotoCaches() {
    this.uploadImagesToFirestoreStorage();
      if (this.platform.is('desktop')) {
        this.removeImagesFromDesktopStorage();
      } else {
        this.removeOldImagesFromStorage();
      }
  }

  addToPhotosSendingToFirestore(photo: Iphoto) {
    this.iPhotoService.create$(photo).pipe(
      take(1),
      switchMap(photo => from(this.loadSavedPhoto(photo, false))),
      tap(p => this.photosLoadedFromDevice.next(p)),
      tap(x => console.log("TWAALLLYO")),
      tap(x => console.log(format(new Date(), 'mm:ss.SSS'))),
      take(1)
    ).subscribe();
  }

  async uploadPhotoToCloudStorage(photo: Iphoto){

    if (!this.photosActivelyUploading.map(p=>p.DocId()).includes(photo.DocId())) {
      this.photosActivelyUploading.push(photo);

        let dataUrl: string = undefined;

        // running on the web
        if (!this.platform.is('hybrid')) {
          const readFile = await Filesystem.readFile({
          path: photo.filepath,
          directory: Directory.Data});
          dataUrl =  `${photo.encodingString}${readFile.data}`;
        } else {
          if (photo.img !== undefined) {
          dataUrl = `${photo.encodingString}${photo.img}`;
          }
        }

        if (dataUrl !== undefined) {
          const fileName = photo.filepath.match("[0-9]+\.(jpeg|png|jpg)")[0];
          this.afStorage.uploadFilePopulateDownloadUrl(base64DataURLtoFile(dataUrl, fileName),fileName);

          this.afStorage.downloadURL$.pipe(
            filter(x => x.fileName === fileName),
            map(downloadUrl => {
              photo.storageUrl = downloadUrl.downloadUrl;
              console.warn(photo);
              console.warn(format(new Date(), 'mm:ss.SSS'));
              return photo;
            }),
            mergeMap(photo => this.iPhotoService.update$(photo)),
            take(1)
          ).subscribe();
      }
    } else {
      console.warn("Photo already uploading");
    }
  }

  // Local storage is limited in the browser on desktop.  This may well prove true on devices as well, we will see.
  removeImagesFromDesktopStorage() {
    this.removeOldImagesFromStorage();

    const imagesToDelete = this.iPhotoService.queryFirestoreShallow$(
      [where('deviceId', '==', this.deviceId ),
      where('storedLocally', '==', true ),
      where('createdAt', '<', subMinutes(new Date(),1) )]).pipe(
      map(i => i.filter(photo => photo.storageUrl!==null)),
      share()
    );

    const someImages = imagesToDelete.pipe(
      filter(i => i.length > 0),
      mergeMap(p => this.deletePhotoFromStorage(p.pop(),false)),
    );

    const noImages = imagesToDelete.pipe(
      filter(i => i.length === 0),
    );

    merge(someImages, noImages).pipe(
      take(1)
      ).subscribe();
  }

  removeOldImagesFromStorage() {

    this.iPhotoService.queryFirestoreShallow$([where('deviceId', '==', this.deviceId ), where('storedLocally', '==', true ),where('createdAt', '<', subDays(new Date(),15))] ).pipe(
      switchMap(x => {
        return of(x).pipe(
        tap(photos => {
          if (photos.length> 0) {
            this.deletePhotoFromStorage(photos.pop(),false)
          }
        })
      )
      }),
      take(1)
      ).subscribe();
  }

  uploadImagesToFirestoreStorage() {

    console.log(`Device id when attempting to upload: ${this.deviceId}`)
    const queryFn = [where('storageUrl', '==', null ),where('deviceId', '==', this.deviceId ),where('active','==',true)];

    this.iPhotoService.queryFirestoreShallow$(queryFn).pipe(
      delay(2000),
      tap(photos => {
        const photosProcessing = this.photosActivelyUploading.map(p => p.filepath);
        photos.forEach(  async photo => {
          if (!photosProcessing.some(q=>q===photo.filepath)) {
            console.warn("Adding photo: " + photo.filepath + " to photosActivelyUploading" + new Date().toLocaleTimeString() );
            if (photo.img === undefined) {
              photo = await this.loadSavedPhoto(photo, false);
            }
            await this.uploadPhotoToCloudStorage(photo)
              .catch(err => {
                this.loggingService.addLog(`Error uploading image to cloud storage.  ${err.message}`, "photo.service.ts",{key: photo.DocId(), error: err.message});
                const asString = err.message as string;
                if (asString === ("File does not exist.")) {
                  photo.storedLocally = false;
                  photo.active = false;
                  this.iPhotoService.update$(photo).pipe(take(1)).subscribe(p => console.log(`Set photo to inactve, as it doesn't exist on deice, or off the device.`,p));
                } else {
                  throw err;
                }
              });
              console.warn("Added photo: " + photo.filepath);
            }})
          })).subscribe();
  }

  public photosAssignedControls(keys: string[]) : Observable<Iphoto[]> {
    const useDexie = this.useDixie;
    if (keys.length === 0) {
      return of([]);
    } else {
        return this.iPhotoService.queryFirestoreForInValues('controlKeyAssociatedWith', keys).pipe(
          map(photos => {
            const obs$: Observable<any>[] = [of(null)];
            photos.forEach(photo => {
                obs$.push(from(this.loadSavedPhoto(photo, useDexie)));
            });
            return obs$;
          }),
          switchMap(o => combineLatest([...o])),
          map(p => p.filter(x=>x!==null)),
        )
    }
  }

  public photosAssignedControl(key: string): Observable<Iphoto[]> {
    const useDexie = this.useDixie;
    if (key === "") {
      return of([]);
    } else {
        if (this.photosLoadedForControl.get(key) !== undefined) {
          return this.photosLoadedForControl.get(key).asObservable();
        } else {
          this.photosLoadedForControl.set(key, new ReplaySubject<Iphoto[]>(1));
          const activeQueryFn = [where('controlKeyAssociatedWith', '==', key ),where('active','==',true)];
          const deletedQueryFn = [where('controlKeyAssociatedWith', '==', key ),where('active','==',false)];
          const localPhotoStream = this.photosLoadedFromDevice.pipe(
          startWith(null),
          filter(x => x === null || x.controlKeyAssociatedWith === key),
          );
          combineLatest([localPhotoStream,this.iPhotoService.queryFirestoreShallow$(activeQueryFn), this.iPhotoService.queryFirestoreShallow$(deletedQueryFn)]).pipe(
          map(x => {
            const deltedDocIds = [];
            if (x[2] !== null) {
              x[2].forEach(p => {
                deltedDocIds.push(p.DocId());
              });
            }
            if (x[0] !== null) {
              const retVal = [x[0]].filter(p => !deltedDocIds.includes(p.DocId()));
              x[1].forEach(p => {
                if (!(x[0].DocId() === p.DocId())) {
                  retVal.push(p);
                }
              });
              return retVal;
            } else {
              return x[1];
            }
          }),
          map(q => q.filter(p => p.active)),
        distinctUntilChanged((prev,curr) => {
          const prevSet = new Set(prev.map(p=> p.DocId()) );
          const curSet = new Set(curr.map(p=> p.DocId()) );
          const currentValidWebUrl = new Set(curr.map(p=> p.storageUrl) );
          const prevValidWebUrl = new Set(prev.map(p=> p.storageUrl) );
            if (prevSet.size !== curSet.size) {
              return false;
            }
            for (var p of prevSet) {
              if (!curSet.has(p)) {
                return false;
              }
            }
            if (currentValidWebUrl.size !== prevValidWebUrl.size) {
              return false;
            }
            for (var p of currentValidWebUrl) {
              if (!prevValidWebUrl.has(p)) {
                return false;
              }
            }
            return true;
          }),
          map(photos => {
            const obs$: Observable<any>[] = [of(null)];
            photos.forEach(photo => {
                obs$.push(from(this.loadSavedPhoto(photo, useDexie)));
            });
            return obs$;
          }),
          switchMap(o => combineLatest([...o])),
          map(p => p.filter(x=>x!==null)),
          map(p => p.sort((a,b) => b.createdAt > a.createdAt ? 1 : -1)),
          takeUntil(this.ceaseMonitoringPhotoKey.pipe(
            filter(x => x === key),
            tap(x => this.photosLoadedForControl.delete(key)),
          ))
          ).subscribe(this.photosLoadedForControl.get(key));
          return this.photosLoadedForControl.get(key).asObservable();
        }
      }
  }

  private async loadSavedPhoto(photoPassedToMethod: Iphoto, useDexie: boolean) : Promise<Iphoto> {
    if (! this.photoDocIdsWaitingFor.has(photoPassedToMethod.DocId()) &&
      this.photosLoading.get(photoPassedToMethod.DocId()) !== undefined &&
      (photoPassedToMethod.img !== undefined || photoPassedToMethod.storageUrl !== null)) {
        this.loggingService.addLog("Photo Load One");
      return this.photosLoading.get(photoPassedToMethod.DocId()).pipe(take(1)).toPromise();
    }
    if (this.photosLoading.get(photoPassedToMethod.DocId()) === undefined || this.photoDocIdsWaitingFor.has(photoPassedToMethod.DocId()) ) {
      const loadedPhoto$ = new ReplaySubject<Iphoto>(1);
      this.photosLoading.set(photoPassedToMethod.DocId(), loadedPhoto$);
      // If photo is stored locally on device.
    if (this.deviceId === photoPassedToMethod.deviceId && photoPassedToMethod.storedLocally) {

        const readLocally =
        from(Preferences.get({key: photoPassedToMethod.filepath})).pipe(
          map(photoFromDevice => {
            photoPassedToMethod.img = JSON.parse(photoFromDevice.value);
            return photoPassedToMethod;
          }),
          share()
        );

        const readLocallyFromMobileDevice = readLocally.pipe(
          filter(() => this.platform.is('hybrid')),
          map(photo => {
            photo.localWebviewPath = `${photo.encodingString}${photo.img}`;
            return photo;
          }),
          );

        const readLocallyFromWebBrowser = readLocally.pipe(
        filter(() => !this.platform.is('hybrid')),
        switchMap(p => from(Filesystem.readFile({
          path: photoPassedToMethod.filepath,
          directory: Directory.Data
        })).pipe(
        map(readFile => {
          p.localWebviewPath = `${p.encodingString}${readFile.data}`;
          return p;
        }),
        ))
      );

        merge(readLocallyFromMobileDevice, readLocallyFromWebBrowser).pipe(
          tap(() => console.log("DRAKY BAKEY", format(new Date(), 'mm:ss.SSS'))),
          tap(() => this.loggingService.addLog("Photo Load TWO")),
          catchError(err => {
            if (err.message === "File does not exist.") {
              this.photosLoading.delete(photoPassedToMethod.DocId());
              photoPassedToMethod.storedLocally = false;
              return this.loadSavedPhoto(photoPassedToMethod, useDexie);
            } else
            {
              this.loggingService.addLog("Error loading image. loadSavedPhoto()" + " " + err.message, "photo.service.ts",{key: photoPassedToMethod.DocId(), error: err.message});
              return throwError(err);
            }
          }),
          take(1)
          ).subscribe(loadedPhoto$);
      } else {
      if (useDexie) {
        const getDexieValue = this.imageCacheService.getPhoto(photoPassedToMethod.docId).pipe(
          share()
        );

        const dexieValuePresent = getDexieValue.pipe(
          filter(x => x !== null && x !== undefined),
          map(x => {
            x.localWebviewPath = x.img;
            return x;
          }),
          switchMap(x => this.imageCacheService.registerPhotoAccessed(x.docId).pipe(
            map(q => x)
          ))
        );

        const dexieValueNotPresent = getDexieValue.pipe(
          filter(x => x == null),
          share()
        );

        const dexieNotPresentIsWeb = dexieValueNotPresent.pipe(
          filter(() => photoPassedToMethod && photoPassedToMethod.storageUrl !== undefined && photoPassedToMethod.storageUrl !== null),
          map(x => photoPassedToMethod),
          tap(x => console.warn("FROM WEB")),
          concatMap((photo) => from(fetch(photoPassedToMethod.storageUrl)).pipe(
            map(res => {
              return {res, photo};
            })
          )),
          concatMap(x => from(x.res.blob().then(blob => this.convertBlobToBase64(blob))).pipe(
            map(blob => {
              x.photo.img = blob as string;
              x.photo.localWebviewPath = photoPassedToMethod.img;
              x.photo['lastAccessed'] = new Date();
              return x.photo;
            }))),
            concatMap(photo => this.imageCacheService.addPhoto(photo).pipe(
            map(() => photo)
          )),
          catchError(err => {
          this.loggingService.addLog("Error loading image from web.", "photo.service.ts",{key: photoPassedToMethod.DocId(), source: "web"});
          return of(null)
          })
        );

        const dexieNotPresentWebNotPresent = dexieValueNotPresent.pipe(
          filter(() => photoPassedToMethod.storageUrl === null),
          tap(x => {
            if (!this.photoDocIdsWaitingFor.has(photoPassedToMethod.DocId())) {
              this.photoDocIdsWaitingFor.add(photoPassedToMethod.DocId());
            }
          }),
          map(() => photoPassedToMethod)
        );

        const retrievedPhoto = merge(dexieNotPresentIsWeb,dexieValuePresent).pipe(
          tap(x => {
            if (this.photoDocIdsWaitingFor.has(photoPassedToMethod.DocId())) {
              this.photoDocIdsWaitingFor.delete(photoPassedToMethod.DocId());
            }
          })
        );

        merge(dexieNotPresentWebNotPresent,retrievedPhoto  ).pipe(
          tap(photo => {
            this.photosLoading.get(photoPassedToMethod.DocId()).next(photo);
          }),
          take(1)
          ).subscribe();

          this.loggingService.addLog("Photo Load THREE");
      } else {
        this.loggingService.addLog("Photo Load FOUR");
        photoPassedToMethod.localWebviewPath=photoPassedToMethod.storageUrl;
        of (photoPassedToMethod).pipe(take(1)).subscribe(loadedPhoto$);
      }
    }
    }
    return firstValueFrom(this.photosLoading.get(photoPassedToMethod.DocId()).pipe(
      tap(() => console.log(photoPassedToMethod.DocId(), format(new Date(), 'mm:ss.SSS')))));
}

  public async addNewToGallery(key: string, source: CameraSource) {
    console.log(key);

    const options: ImageOptions = {
      resultType: CameraResultType.Uri, // file-based data; provides best performance
      source: source, // automatically take a new photo with the camera
      quality: 60, // highest quality (0 to 100)
      width: 750,
      height: 750,
    };

    if (source === CameraSource.Camera) {
      options.allowEditing= true;
      options.saveToGallery= true;
    }

    // Take a photo
    try {
      const capturedPhoto = await Camera.getPhoto(options);
      // const capturedPhoto = await this.boxedGetPhoto(options);
      console.log(format(new Date(), 'mm:ss.SSS'));
      const savedImageFile = await this.savePicture(capturedPhoto,key);
      if (savedImageFile !== null) {
        console.log(format(new Date(), 'mm:ss.SSS'));
        this.addNewPhotoToGallery(savedImageFile, key);
        console.log(format(new Date(), 'mm:ss.SSS'));
      } else {
        throw new Error("User attempted to save null image.");
      }
    } catch (error) {
      // this isn't an error per se, so we don't throw it.
      if (error.message === "User cancelled photos app") {
        this.loggingService.addLog("User cancelled photos app", "photo.service.ts",{key: key, source: source});
        console.log("User cancelled photos app");
      } else {
        if (error.message === "Error loading image") {
          this.photoLoadErrorMessage$.next("Error loading image.  If you are loading image from iCloud, please try again when in an area with better internet availibility.");
          this.loggingService.addLog("User attempted barley with bad internet.", "photo.service.ts",{key: key, source: source});
        } else {
          throw error;
        }
      }
    }

  }

  public async addNewPhotoToGallery(photo: Iphoto, key: string) {

    try {
        // Cache all photo data for future retrieval
      await Preferences.set({
        key: photo.filepath,
        value: JSON.stringify(photo.img)
      });

      //Add photo details to cloud firestore.
      this.addToPhotosSendingToFirestore(photo);
    } catch (error) {
      if (error.name === "QuotaExceededError") {
        console.log("QuotaExceededError");
        localStorage.clear();
        this.addNewPhotoToGallery(photo, key);
      } else {
        throw error;
      }
    }

  }

  public async savebase64EncodedImage(base64Data: string, controlKeyAssociatedWith: string, encodingString: string, webPath? : string ) {
    // Write the file to the data directory
    let extension = "";
    if (encodingString.includes("png")) {
      extension = ".png"
    } else {
      extension = ".jpeg"
    }
    const fileName = new Date().getTime() + extension;
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data
    });

    if (this.platform.is('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return new Iphoto({
        filepath: savedFile.uri,
        localWebviewPath: Capacitor.convertFileSrc(savedFile.uri),
        storageUrl: null,
        controlKeyAssociatedWith: controlKeyAssociatedWith,
        img: base64Data,
        deviceId: this.deviceId,
        encodingString: encodingString,
      });
    }
    else {
      if (!webPath) {
        const asBlob = await (await fetch(`${encodingString}${base64Data}`)).blob();
        webPath = URL.createObjectURL(asBlob);
      }

      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return new Iphoto({
        filepath: fileName,
        localWebviewPath: webPath ?? Capacitor.convertFileSrc(savedFile.uri),
        storageUrl: null,
        controlKeyAssociatedWith: controlKeyAssociatedWith,
        img: base64Data,
        deviceId: this.deviceId,
        encodingString: encodingString,
      });
    }
  }

  // Save picture to file on device
  private async savePicture(cameraPhoto: Photo, controlKeyAssociatedWith: string) {
    // Convert photo to base64 format, required by Filesystem API to save
    const base64Data = await this.readAsBase64(cameraPhoto);
    if (base64Data === undefined || base64Data === null) {
      window.alert("Error retriving photo. Please try again.");
      return null;
    } else {
      try {
        return this.savebase64EncodedImage((base64Data as string), controlKeyAssociatedWith, "data:image/jpeg;base64,",cameraPhoto.webPath);
      }
      catch (e) {
        if (e.name !== 'QuotaExceededError') {
          throw e;
        } else {
          localStorage.clear();
          return this.savePicture(cameraPhoto, controlKeyAssociatedWith);
        }
      }
    }
}

  // Read camera photo into base64 format based on the platform the app is running on
  private async readAsBase64(cameraPhoto: Photo) {
    // "hybrid" will detect Cordova or Capacitor
    if (this.platform.is('hybrid')) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: cameraPhoto.path
      });

      return file.data;
    }
    else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(cameraPhoto.webPath!);
      const blob = await response.blob();
      return await this.convertBlobToBase64(blob) as string;
    }
  }

  // Delete picture by removing it from reference data and the filesystem
  public deletePicture(photo: Iphoto, position: number, key: string) : Observable<Iphoto> {
    return this.deletePhotoFromStorage(photo,true);
  }

  private deletePhotoFromStorage(photo: Iphoto, photoNoLongerActive: boolean) : Observable<Iphoto> {

    return of (Preferences.remove({key: photo.filepath})).pipe(
      map(() => photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1)),
      switchMap(filename => from(Filesystem.deleteFile({
        path: filename,
        directory: Directory.Data
      })).pipe(
        catchError(e => {
          if (e.message="File does not exist.") {
            console.log(filename + " does not exist");
            return of(null).pipe(
              map(() => {
                photo.storedLocally=false;
                if (photoNoLongerActive){
                  photo.active = false;
                }
                return photo;
              }),
              tap(x => console.log(x,` string`)),
              switchMap(x => this.iPhotoService.update$(photo)),
            );
          } else {
            console.log(e.message);
            return of(null);
          }
        }))),
      map(() => {
        photo.storedLocally=false;
        if (photoNoLongerActive){
          photo.active = false;
        }
        return photo;
      }),
      switchMap(x => this.iPhotoService.update$(photo)),
      tap(x => console.log(x,`DELETED PHOOOOTOOO!`)),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return throwError(err);
      }),
      take(1)
    );
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}
