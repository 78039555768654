<mat-dialog-content>
  <div class="site-visit-cont modal-dimensions" [formGroup]="siteVisitDetailsFormGroup">
    <div class="site-visit-label">{{siteVisitDetailsFormGroup.get('siteVisitSummaryInformation').value}}</div>
        <div class="label-style">
          Arrival Window
        </div>
        <div class="arrival">
        <app-time-field
        [value]= "siteVisitDetailsFormGroup.get('siteVisitArrivalWindowStartDate').value"
        [fieldLabel]= "'Start'"
        [readOnly]="false"
        [granularity]="fidelitySiteVisitArrivalWindows"
        (valueChange) = "updateArrivalWindowStart$.next($event)"
        [maxDate]="getTimeWindowDateRespectingFidelity(siteVisitDetailsFormGroup.get('siteVisitStartDate').value,'arrive')"
        ></app-time-field>
        <div class="to-styling">To</div>
        <app-time-field
        [value]= "siteVisitDetailsFormGroup.get('siteVisitArrivalWindowEndDate').value"
        [fieldLabel]= "'End'"
        [readOnly]="false"
        [granularity]="fidelitySiteVisitArrivalWindows"
        (valueChange) = "updateArrivalWindowEnd$.next($event)"
        [minDate]="getTimeWindowDateRespectingFidelity(siteVisitDetailsFormGroup.get('siteVisitStartDate').value,'depart')"
        ></app-time-field>
        </div>
      <div class="label-style" style="grid-column: 1/2">
        Estimated Visit
      </div>
      <div class="arrival">

      <app-time-field
        [value]= "getTimeWindowFiveMinuteFidelity(siteVisitDetailsFormGroup.get('siteVisitStartDate').value)"
      [fieldLabel]= "'Estimated Start'"
      [readOnly]="true"
      [granularity]="5"
        ></app-time-field>
        <div class="to-styling">To</div>
        <app-time-field
        [value]= "getTimeWindowFiveMinuteFidelity(siteVisitDetailsFormGroup.get('siteVisitEndDate').value)"
        [fieldLabel]= "'Estimated End'"
        [readOnly]="true"
        [granularity]="5"

        ></app-time-field>
      </div>

      <div class="label-style">Technician</div>

      <div class="tech-sv-status">{{siteVisitDetailsFormGroup.get('resource').value}}</div>
      <div class="label-style">Site Visit Status</div>
      <div class="tech-sv-status">{{siteVisitDetailsFormGroup.get('siteVisitDispatchStatus').value}}</div>

    <div class="label-style" style="grid-column: 1/2; padding-bottom: 10px;">Commute to site</div>
      <div>
        <app-time-field
        [value]= "siteVisitDetailsFormGroup.get('siteVisitCommuteTimeFront').value"
        [fieldLabel]= "'Commute Time'"
        [readOnly]="false"
        [granularity]="fidelityCommuteTime"
        (valueChange) = "updateCommuteTime$.next($event)"
        [minDate]="zeroHoursAsDate"
        [maxDate]="maxCommuteAsDate"
        ></app-time-field>
      </div>
      <!-- <mat-slide-toggle style="grid-column: 3/5;">Automatic Commute</mat-slide-toggle> -->
      <div class="label-style" style="grid-column: 1/2;">Site Visit Notes</div>
    <div fxLayout="row wrap" fxLayoutAlign=" start" class="sv-notes">
      <mat-form-field appearance="outline" style="width:390px;">
          <textarea [cdkTextareaAutosize] matInput placeholder="Site Visit Notes" formControlName="siteVisitNotes"></textarea>
        </mat-form-field>
    </div>
      <div></div>
      <div></div>
    <div class="site-visit2">
      <div class="hr-style"><hr></div><br>
      <div class="job-header">Job: {{siteVisitDetailsFormGroup.get('jobTypeName').value}}</div>
    </div>
    <div class="site-contact">
      <div>
        <mat-icon class="icon-style">person</mat-icon>
        <span style="padding-left: 5px;">
          <a routerLink="/app-customer-page/{{customer.DocId()}}">
            {{siteVisitDetailsFormGroup.get('customerName').value}}
          </a>
        </span>
        <br><mat-icon class="icon-style">local_phone</mat-icon><span style="color: black; padding-left: 5px;">{{formattedCustomerPhoneNumber}}</span></div>
    </div>

    <div class="service-address">
      <div class="address-container">
        <mat-icon class="icon-style">home</mat-icon>
        <div style="padding: 10px 0 0 5px; margin-right: 25px;" (click)="ViewPropertyHistoryClick()"
        matBadge="{{jobCountAtAddress$|async}}"
        matBadgeOverlap="false"
        matBadgePosition="after">
            {{siteVisitDetailsFormGroup.get('serviceAddress').value}}
        </div>
      </div>
    </div>

    <div class="label-style" style="grid-column: 1/2;">Job Notes</div>
    <div fxLayout="row wrap" fxLayoutAlign=" start" class="sv-notes">
      <mat-form-field appearance="outline" style="width:390px;">
          <textarea [cdkTextareaAutosize] matInput placeholder="Job Notes" formControlName="jobNotes"></textarea>
        </mat-form-field>
    </div>
    <label class="label-style">Job Tags</label>
    <app-custom-tags  style="grid-column: 2/5;"
    [assignedTags]= "jobTags"
    [disableInput] = "disableInput"
    ></app-custom-tags>
    <div class="line-items">
      <app-line-item-display
        style="width: 100%;"
        [intendedAudience]="2"
        [lineItems]="lineItems"
        [siteVisitViewingContextDocId] = "siteVisitDocId"
        (addLineItem$)="addLineItem$.next($event)"
        (editLineItem$)="editLineItem$.next($event)"
        (removeLineItem$)="removeLineItem$.next($event)"
    ></app-line-item-display>
    </div>

  </div>
</mat-dialog-content>
  <mat-dialog-actions class="site-visit-cont spacing">
    <div class="button-position">
      <button class="button-white button-width" mat-button mat-dialog-close>Exit</button>
    </div>
    <div class="button-position">
      <button class="button-red button-width" mat-button [matMenuTriggerFor]="removeMenu">Remove</button>
      <mat-menu #removeMenu="matMenu" yPosition="above">
        <button mat-menu-item style="color:red;" (click)="rescheduleClose()"><b>Reschedule</b></button>
        <button mat-menu-item style="color:red;" (click)="deleteClose()"><b>Delete</b></button>
      </mat-menu>
    </div>
    <div class="button-position">
      <button class="button-green button-width" mat-button (click)="modifyClose()">Job Details</button>
    </div>
    <div class="button-position">
    <button class="button-blue button-width" mat-button [matMenuTriggerFor]="addsvMenu">Add Site Visit</button>
    <mat-menu #addsvMenu="matMenu" yPosition="above">
      <button mat-menu-item style="color: #428BCA;" (click)="addSiteVisit()"><b>New Site Visit</b></button>
      <button mat-menu-item style="color: #428BCA;" disabled={{noOutstandingTimeOnJob}} (click)="siteVisitFromUnassigned()"><b>Unassigned</b></button>
    </mat-menu>
    </div>
