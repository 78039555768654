import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, merge, Observable, of, Subject, take } from 'rxjs';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { randomElementName } from '../../../../../common/src/util/util';
import { Discount, DiscountType } from '../../../../../common/src/data/dao/discount';
import { DiscountService } from '../../../../../common/src/data/dao-services/discount.service';

@Component({
  selector: 'app-discount-creation-modal',
  templateUrl: './discount-creation-modal.component.html',
  styleUrls: ['./discount-creation-modal.component.scss']
})
export class DiscountCreationModalComponent implements OnInit {


  randomElementName : string = randomElementName();
  matcher = new ControlNamePresentInErrorsMatcher();

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  ElementNameForId(id: any) {
  return this.randomElementName.concat(id);
  }

  form: UntypedFormGroup;
  passedInDiscount: Discount;
  discountGeneratedFromInitialFormValue: Discount;

  get discountCatagories() { return Discount.DiscountCatagories;}
  get discountTypes() { return Discount.DiscountTypes; }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<DiscountCreationModalComponent>,
  private discountService: DiscountService) {
    if (data && data.discount) {
      this.passedInDiscount = data.discount;
    }
  }

  buildForm() : UntypedFormGroup {
    return this.fb.group({
      discountType: ["",[Validators.required]],
      discountCatagory: ["",[Validators.required]],
      discountPercentage: [],
      discountPercentageDisplay: [{value: '', disabled: true}],
      discountFixedAmount: [{value: '', disabled: true}],
      title: ["",[Validators.required,Validators.minLength(3)]],
      description: [],
      internalNotes: [],
    },{validators: this.discountAmountFilledOut});
  }

  populateFormFromPassedInDiscount(d: Discount){
    this.form.patchValue({ discountType: d.discountType, discountCatagory: d.discountCharacterization,
      title: d.title, description: d.description, internalNotes: d.internalNotes,
      discountPercentage: d.discountPercentage, discountPercentageDisplay : `${d.discountPercentage}`, discountFixedAmount: d.discountFixedAmount});
     this.getDiscountFromForm().pipe(
      tap(discount => this.discountGeneratedFromInitialFormValue = discount),
      take(1)
     ).subscribe();
  }

  discountAmountFilledOut: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const discountType = control.get("discountType").value;
    if (discountType === DiscountType.PERCENTAGE) {
      const discountPercentage = `${control.get("discountPercentageDisplay").value}`;
      return (discountPercentage === undefined || discountPercentage === null || discountPercentage.trim()  === "" || discountPercentage.match(/[0-9]/g) === null ) ? { "discountPercentage": true } : null;
    }
    if (discountType === DiscountType.FIXED) {
      const discountFixedAmount = control.get("discountFixedAmount").value;
      return (discountFixedAmount === undefined || discountFixedAmount === null || discountFixedAmount === " ") ? { "discountFixedAmount": true } : null;
    }
    return null;
  };

  getDiscountFromForm() : Observable<Discount> {
    const discount = new Discount();
    discount.discountType = this.form.get("discountType").value;
    discount.discountCharacterization = this.form.get("discountCatagory").value;
    let discountParsing = ((this.form.get("discountPercentageDisplay").value as string).replace(/[^0-9.]/g, ''));
    if (discountParsing.length > 2) {
      discountParsing = discountParsing.replace(".","");
      discountParsing = discountParsing.substring(0,2).concat(".").concat(discountParsing.substring(2));
    }
    discount.discountPercentage = parseFloat(discountParsing);
    discount.discountFixedAmount = this.form.get("discountFixedAmount").value;
    discount.title = this.form.get("title").value;
    discount.description = this.form.get("description").value;
    discount.internalNotes = this.form.get("internalNotes").value;
    if (JSON.stringify(this.discountGeneratedFromInitialFormValue) === JSON.stringify(discount)) {
      return of(this.passedInDiscount);
    } else {
      return this.discountService.retrieveDocId(discount).pipe(
      map(() => discount)
      );
    }
  }


  ngOnInit(): void {
    this.form = this.buildForm();
    if (this.passedInDiscount) {
      this.populateFormFromPassedInDiscount(this.passedInDiscount);
    }

    const percentDisc = this.form.get("discountType").valueChanges.pipe(
      startWith(this.form.get("discountType").value),
      filter(x=> x === DiscountType.PERCENTAGE),
      tap(() => {
        this.form.get("discountPercentageDisplay").enable();
        this.form.get("discountFixedAmount").disable();
      }));

    const fixedDisc = this.form.get("discountType").valueChanges.pipe(
      startWith(this.form.get("discountType").value),
      filter(x=> x === DiscountType.FIXED),
      tap(() => {
        this.form.get("discountPercentageDisplay").disable();
        this.form.get("discountFixedAmount").enable();
      }));

    merge(percentDisc,fixedDisc).pipe(takeUntil(this.destroyingComponent$)).subscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.valid) {
      this.getDiscountFromForm().pipe(
        tap(d => this.dialogRef.close({discount: d})),
        take(1)
      ).subscribe();
    } else {
      this.form.markAllAsTouched();
    }
  }

}

export class ControlNamePresentInErrorsMatcher implements ErrorStateMatcher {

  controlsParentsErrorsContainsControlsName(control: UntypedFormControl, errors: ValidationErrors) : boolean {
    for (let key in errors) {
      if (control.parent.get(key) === control) {
        return true;
      }
    }
    return false;
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = control && control.invalid;
    const invalidParent = control && control.parent && control.parent.invalid && this.controlsParentsErrorsContainsControlsName(control, control.parent.errors);
    return (invalidCtrl || invalidParent) && (control.dirty || control.touched);
  }
}
