import { Address } from '@dao/address';
import { FirestoreDiff } from '@dao/firestore-diff';
import { GenericServiceProviderSetting } from '@dao/generic-service-provider-setting';
import { ReferralCampaign } from '@dao/referral-campaign';
import { RetrieveFirestorePropertiesBase } from '@database-backend/retrieve-firestore-properties';

export class Customer extends RetrieveFirestorePropertiesBase<Customer>  {


  // Principal address ( billing )
  addressDocId: string;
  address: Address;

  customerReferralCampaignDocId: string;
  customerReferralCampaign: ReferralCampaign;

  firestoreDiffDocIds: string[] = [];
  firestoreDiffs: FirestoreDiff[] = [];

  accountStatusDocId: string;
  accountStatus: GenericServiceProviderSetting;
  customerTypeDocId: string;
  customerType: GenericServiceProviderSetting;
  customerTagDocIds: string[] = [];
  customerTags: GenericServiceProviderSetting[] = [];

  primaryPhoneNumber: string = "";
  primaryPhoneNumberExtension: string = "";
  alternativePhoneNumber: string = "";
  alternativePhoneNumberExtension: string = "";
  company: string = "";
  customerName: string = "";
  customerEmail: string = "";
  customerNotes: string = "";
  customerOutstandingBalance = 0;
  customerOverdueBalance = undefined;
  totalAmountInvoiced = undefined;
  customerDocId: string;

  importedCustomerKey: string = "";

  get customerNameCompanyDisplay(): string {
    if (this.company !== undefined && this.company !== null && this.company !== "" ) {
      return this.company + " - " + this.customerName;
    } else {
      return this.customerName;
    }
  }

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(
    ["address", "customerReferralCampaign", "accountStatus", "customerType",
    "customerTags", "firestoreDiffs","customerOutstandingBalance","customerOverdueBalance","totalAmountInvoiced"]);

  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
    .concat(["addressDocId", "customerReferralCampaignDocId","accountStatusDocId", "customerTypeDocId", "customerTagDocIds", "firestoreDiffs", "firestoreDiffDocIds"]);
  static _firestoreCompositionMemberNames = ["address", "firestoreDiffs", "accountStatus", "customerType", "customerTags", "customerReferralCampaign"];

  static _firestoreCompositionalDiffMemberNames: string[] = ["address", "customerReferralCampaign", "accountStatus", "customerType", "customerTags"];

  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Customer._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return Customer._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return Customer._firestoreCompositionMemberNames;}
  retrieveFirestoreIgnoredMemberNames() : string[] { return Customer._firestoreIgnoredMemberNames};
  retrieveFirestoreDenormalizedMemberNames(): string[] { return Customer._firestoreCompositionMemberNames;}


  DocId(): string {
    return this.customerDocId;
  }

  SetDocId(docId: string): void {
    this.customerDocId = docId;
  }

  public constructor(init?: Partial<Customer>) {
    super();
    Object.assign(this, init);
    if (this.customerTagDocIds === null) {
      this.customerTagDocIds = [];
    }
  }

}
