import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { FormlyFieldTextArea } from '@ngx-formly/material/textarea';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FieldType,FormlyFieldProps } from '@ngx-formly/material/form-field';

interface TextAreaProps extends FormlyFieldProps {
  autosize?: boolean;
  autosizeMinRows?: number;
  autosizeMaxRows?: number;
}

@Component({
  selector: 'app-formly-textbox-autosize-triggerable',
  styleUrls: ['./formly-textbox-autosize-triggerable.component.scss'],
  template: `
    <textarea
      matInput
      [id]="id"
      [readonly]="props.readonly"
      [required]="required"
      [formControl]="typedFormControl"
      [errorStateMatcher]="errorStateMatcher"
      [cols]="props.cols"
      [rows]="props.rows"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [tabindex]="props.tabindex"
      [cdkTextareaAutosize]="props.autosize"
      [cdkAutosizeMinRows]="props.autosizeMinRows"
      [cdkAutosizeMaxRows]="props.autosizeMaxRows"
      [class.cdk-textarea-autosize]="props.autosize"
      #cfcAutosize="cdkTextareaAutosize"
    >
    </textarea>
  `,
  providers: [
    // fix for https://github.com/ngx-formly/ngx-formly/issues/1688
    // rely on formControl value instead of elementRef which return empty value in Firefox.
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: FormlyFieldTextArea },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FormlyTextboxAutosizeTriggerableComponent extends FieldType<FieldTypeConfig<TextAreaProps>> implements AfterViewInit, OnDestroy
{

  destroyingComponent$ = new Subject();
  afterViewInitCalled$ = new Subject();

  get typedFormControl() { return this.formControl as FormControl<any>; }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
  }

  @ViewChild('cfcAutosize') contentFCAutosize: CdkTextareaAutosize;

  ngAfterViewInit(): void {
    if (this.props['changeDetect'] !== undefined) {
      this.props['changeDetect'].pipe(
        // This debounce shouldn't really need to be here.  But as components are moved around the form design view, an
        // increasing number of emissions on change detect occur.  Should be fixed at some point, to replicate add
        // a single text box, and then move it between two columns.  This is a workaround for now.
        debounceTime(25),
        tap(() => this.contentFCAutosize.resizeToFitContent(true)),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
    } else {
      of(true).pipe(
        delay(50),
        tap(() => this.contentFCAutosize.resizeToFitContent(true))).subscribe();
    }
  }

  override defaultOptions = {
    props: {
      cols: 1,
      rows: 1,
    },
  };
}
