import { FirestoreDiff } from './firestore-diff';
import { RetrieveFirestorePropertiesBase } from '../database-backend/retrieve-firestore-properties';

export class GenericServiceProviderSetting extends RetrieveFirestorePropertiesBase<GenericServiceProviderSetting> {
  static friendlyCollectionNames: Map<string, string> = new Map([["customer_account_status", "Customer Account Status"],
                                                                 ["customer_tags", "Customer Tags"], ["customer_types", "Customer Type"],
                                                                 ["job_tags", "Job Tags"], ["job_types", "Job Type"],
                                                                 ["job_priorities", "Job Priority"]]);

  name: string;
  description: string;
  active: boolean;
  createdOn: Date;
  createdByEmployeeId: string;
  createdByEmployeeName: string;
  lastUpdatedOn: Date;
  lastUpdatedByEmployeeId: string = "";
  lastUpdatedByEmployeeName: string;
  parentCollectionName: string;
  colorAssocatedWithSetting: string;

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat("parentCollectionFriendlyName");
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat("parentCollectionFriendlyName");

  public constructor(init?: Partial<GenericServiceProviderSetting>) {
    super();
    Object.assign(this, init);
  }

  retrieveFirestoreIgnoredMemberNames() : string[] { return GenericServiceProviderSetting._firestoreIgnoredMemberNames };
  retrievefirestoreIgnoreDiffTrackingMembers(): string[] { return GenericServiceProviderSetting._firestoreIgnoreDiffTrackingMembers; }

  createFirestoreDiff(previous: GenericServiceProviderSetting | null, current: GenericServiceProviderSetting | null,
    memberName: string, diffType: string , containedInArray: boolean): FirestoreDiff[] | null{
      console.log(this);
      console.log(this.parentCollectionName);
      const aDiff = new FirestoreDiff({newValue: current?.name, oldValue: previous?.name, fieldName: this.parentCollectionName,
                                       dateCreated: new Date(), changeType: diffType, activeDocId:  current !== null ?
                                       current.DocId() : previous.DocId(), associatedDocId: current?.DocId()});
      if (previous === null) {
        if (containedInArray) {
          aDiff.userMessage = `${GenericServiceProviderSetting.friendlyCollectionNames.get
            (this.parentCollectionName)} added ${current.name}`;
        } else {
        aDiff.userMessage = `${GenericServiceProviderSetting.friendlyCollectionNames.get
          (this.parentCollectionName)} set to ${current.name}`;
        }
      } else if (current === null) {
        aDiff.userMessage = `${GenericServiceProviderSetting.friendlyCollectionNames.get
          (this.parentCollectionName)} removed ${previous.name}`;
      } else {
        aDiff.userMessage = `${GenericServiceProviderSetting.friendlyCollectionNames.get
          (this.parentCollectionName)} changed to ${current.name}`;
      }
      return [aDiff];
  }
}
