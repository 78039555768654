import { Observable } from "rxjs";
import { ControlContainerComponent } from "./component-models/control-container.component";

export interface ContainsControlComponentsInterface {
  mutateComponentWhenDroppedInContainer(c: ControlContainerComponent) : ControlContainerComponent;
}

export function instanceOfControlReportsOnViewInitilizationInterface(object: any): object is ControlReportsOnViewInitilizationInterface {
  return 'afterViewInitilized$' in object;
}

export interface ControlReportsOnViewInitilizationInterface {
  afterViewInitilized$ : Observable<boolean>;
}
