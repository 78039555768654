import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySignaturePadComponent } from './formly-signature-pad.component';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [FormlySignaturePadComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {name: 'formlySignaturePad', component: FormlySignaturePadComponent }
      ]}),
    HttpClientModule,
    MatDialogModule,
  ]
})
export class FormlySignaturePadModule { }
