import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrls: ['./date-range-selection.component.scss']
})
export class DateRangeSelectionComponent implements OnInit {

  @Input() viewWindowMovementEnabled: boolean = false;
  @Output() retreatViewWindow$ = new Subject<null>();
  @Output() advanceViewWindow$ = new Subject<null>();
  @Output() viewWindowSetToToday$ = new Subject<null>();

  constructor() { }

  get ViewModelDisabled() {
    return !this.viewWindowMovementEnabled; }
  ngOnInit() {
  }

}
