
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <!-- Leaf Node. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="50" [ngClass]="classesFromItem(node.item)">
        <button mat-icon-button disabled hidden></button>
        <mat-checkbox class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(node)"
          (change)="todoLeafItemSelectionToggle(node)">{{node.name}}
        </mat-checkbox>
        <div *ngIf="node.item?.displayType">
          <div *ngIf="node.item.displayType === 'days'" [formGroup]="node.item.form">
            <h5>Number days to display</h5>
            <mat-select formControlName="dateSelectionType">
              <mat-option value=null>Any</mat-option>
              <mat-option value=0>Day Of</mat-option>
              <mat-option value='Custom'>Custom</mat-option>
            </mat-select>
            <div *ngIf="node.item.form.get('dateSelectionType').value === 'Custom'">
                <mat-form-field appearance="outline" style="width:70px; margin-left: 10px;">
                  <input type = "number" matInput placeholder="Any"  formControlName="numberDays" min="1"
                  [name]="ElementNameForId('436513')" autofill='off' autocomplete='new-password' data-lpignore='true'
                  ></mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="node.description">
          <mat-icon matTooltip="{{node.description}}" matTooltipPosition="right">info</mat-icon>
        </div>
      </mat-tree-node>
      <!-- When node has 1..n children. -->
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="30" [ngClass]="classesFromItem(node.item)">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.filename" style="border: none">
          <mat-icon class="mat-icon-rtl-mirror" style="font-size: 20px;">
            {{treeControl.isExpanded(node) ? 'expand_more' : treeControl.isExpandable(node) ? 'chevron_right' : null}}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="todoItemSelectionToggle(node)">{{node.name}}
        </mat-checkbox>
        <div *ngIf="node.description">
          <mat-icon matTooltip="{{node.description}}" matTooltipPosition="right">info</mat-icon>
        </div>
      </mat-tree-node>
    </mat-tree>
