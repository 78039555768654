<div class="container-cus">
  <!-- <div class="left-side"></div> -->
  <div class="middle-side">
    <div [formGroup]="form" class="content-customer">
      <div class="header-label" style="grid-column: 1/5; width: 100%;">Add Journal Entry</div>

      <!--just a spacer for the area below the title-->
      <div style="grid-column: 1/5; width: 100%;"></div>

      <!-- Start Billing Customer Name -->
      <div class="label-style-cust">Billing Customer</div>
      <div style="width:280px; display: flex;">
        <div class="form-align">
            <mat-form-field appearance="outline" style="width:280px;">
              <input placeholder="Customer Name" matInput formControlName="billingCustomerName">
            </mat-form-field>
          </div>
          <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='This is the customer that this payment will be billed to. 
          
          When the payment entries button is launched from the Jobs Page it will always apply to the customer for that job.'
    >info</mat-icon>
        </div>
      <!-- End Billing Customer Name -->

      <!--Start of Journal Entry Type-->
      <!-- <div class="label-style-cust">Journal Entry Type</div>
      <div class="form-align">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Choose One" formControlName="journalEntryType">
            <mat-option *ngFor="let entryType of journalEntryTypes" [value]="entryType">
              {{entryType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <!--End of Journal Entry Type-->

      <!--Start of Journal Entry Credit Types-->
        <div class="label-style-cust">Payment Type</div>
        <div class="form-align" style="display: flex;">
          <mat-form-field appearance="outline" style="width:280px;">
            <mat-select placeholder="Choose One" formControlName="journalEntryCreditType">
              <mat-option *ngFor="let entryType of journalEntryCreditTypes" [value]="entryType">
                {{entryType}}
              </mat-option>
            </mat-select>
            <mat-error>Payment Type Required.</mat-error>
          </mat-form-field>
          <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='The mode of payment that your customer is paying with'
    >info</mat-icon>
        </div>
      <!--End of Journal Entry Credit Types-->

      <!--Start of Journal Entry Debit Types-->
      <!-- <ng-container *ngIf ="form.get('journalEntryType').value === 'Debit'">
        <div class="label-style-cust">Debit Type</div>
        <div class="form-align">
          <mat-form-field appearance="outline">
            <mat-select placeholder="Choose One" formControlName="journalEntryDebitType">
              <mat-option *ngFor="let entryType of journalEntryDebitTypes" [value]="entryType">
                {{entryType}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container> -->
      <!--End of Journal Entry Debit Type-->

      <!-- Begin amount-->
      <div class="label-style-cust">Amount</div>
      <div style="width:280px; display: flex;">
        <div class="form-align journal-amount">
          <mat-form-field class="journal-amount" appearance="outline" style="width:280px;">
          <input currencyMask [options]="{align: 'left'}" placeholder="Amount" matInput formControlName="amount"
          inputmode="numeric" [name]="ElementNameForId('5753134')" autofill='off' autocomplete='new-password' data-lpignore='true'>
          <mat-error *ngIf="form.controls['amount'].errors?.min">You can not apply negative amounts to invoice.</mat-error>
          <mat-error *ngIf="form.controls['amount'].errors?.required">You must enter payment amount recevied.</mat-error>
          </mat-form-field>
        </div>
        <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='This is the dollar amount of the payment being made by the customer. 
        
        This number must equal the total "Amount to Apply" number in the bottom left of the payment table'
    >info</mat-icon>
      </div>
      <!-- End Amount-->


      <!-- Start of Check Number -->
      <div class="label-style-cust">Check Number</div>
      <div style="width:280px; display: flex;">
        <div class="form-align">
            <mat-form-field appearance="outline" style="width:280px;">
              <input placeholder="Check Number" matInput formControlName="checkNumber"
              [name]="ElementNameForId('796949')" autofill='off' autocomplete='new-password' data-lpignore='true'>
            </mat-form-field>
          </div>
          <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='If payment is made with a check this field allows for the check number to be recorded. This field will only be enabled if the "Check" option is selected from the "Payment Type" drop down'
    >info</mat-icon>
        </div>
      <!-- End Check Number -->

      <!-- Start of Memo -->
      <div class="label-style-cust" style="grid-column: 3/4; grid-row: 3/4;">Memo</div>
      <div style="width:280px; grid-column: 4/5; grid-row: 3/4; display: flex;">
        <div class="form-align">
            <mat-form-field appearance="outline" style="width:280px;">
              <textarea placeholder="Memo" matInput formControlName="memo" rows="9"></textarea>
            </mat-form-field>
          </div>
          <mat-icon class="mat-icon-radio-position disappearing-tooltips" matTooltipClass="tooltip-format" matTooltipPosition="right" matTooltip='Any additional notes about this payment can be written here'
    >info</mat-icon>
        </div>
      <!-- End Memo -->

      <!-- Invoices to reconcile payment against. -->
      <!-- <app-invoice-display
        [invoices] = "invoices"
        [allocateToJournalEntry] = true
        (updateAmountAllocated$) = "updateAmountAllocated.next(null)"
        ></app-invoice-display>

        <div *ngIf="form.errors?.['paymentsAssignedUn'] && (form.touched || form.dirty)" class="cross-validation-error-message alert alert-danger">
          Amount applied to invoices does not equal amount of the payment.
      </div>

      <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
        <button mat-button class="button-gray" (click)="cancel()">Cancel</button>
        <button mat-button class="button-blue" (click) = "submit()" type="submit">Save</button>
      </div> -->
      <!-- <div style="grid-row: 7/8; grid-column: 1/5; background-color: gray;"></div> -->
      <div style="grid-column: 1/5; grid-row: 7/8; padding-top: 40px;">
        <app-invoice-display
              [invoices] = "invoices"
              [allocateToJournalEntry] = true
              (updateAmountAllocated$) = "updateAmountAllocated.next(null)"
              ></app-invoice-display>
      
              <div *ngIf="form.errors?.['paymentsAssignedUn'] && (form.touched || form.dirty)" style="color: #f44336;" class="cross-validation-error-message alert alert-danger">
                Amount applied to invoices does not equal amount of the payment.
            </div>
      
            <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
              <button mat-button class="button-gray" (click)="cancel()">Cancel</button>
              <button mat-button class="button-blue" (click) = "submit()" type="submit">Save</button>
            </div>
            </div>
    </div>
  </div>
  <!-- <div class="right-side"></div> -->
  <!-- <div style="grid-column: 1/4;">
  <app-invoice-display
        [invoices] = "invoices"
        [allocateToJournalEntry] = true
        (updateAmountAllocated$) = "updateAmountAllocated.next(null)"
        ></app-invoice-display>

        <div *ngIf="form.errors?.['paymentsAssignedUn'] && (form.touched || form.dirty)" class="cross-validation-error-message alert alert-danger">
          Amount applied to invoices does not equal amount of the payment.
      </div>

      <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
        <button mat-button class="button-gray" (click)="cancel()">Cancel</button>
        <button mat-button class="button-blue" (click) = "submit()" type="submit">Save</button>
      </div>
      </div> -->
</div>
