import { FirestoreDiff } from '@dao/firestore-diff';
import {RetrieveFirestorePropertiesBase } from '@database-backend/retrieve-firestore-properties';

export class Address extends RetrieveFirestorePropertiesBase<Address> {
  lineOne: string;
  lineTwo: string;
  lineThree: string;
  city: string;
  county: string;
  district: string;
  stateOrRegion: string;
  postalCode: string;
  countryCode: string;
  notes: string;
  unit = "";
  _formattedAddress: string = null;
  postalCodePlusFour: string;
  geoCoordinates: {Latitude: number, Longitude: number};
  googlePlaceId: string;
  generatedCommuteMatrix: boolean = true;
  commuteMatrixGenerationTime: Date;

  public formattedAddress() {
    if (this._formattedAddress === null) {
      return this.lineOne + ', ' + this.city + ', ' + this.stateOrRegion + ', USA';
    } else {
      return this._formattedAddress;
    }
  }

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames;
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers
    .concat(["dateScheduledToVisit", "lineOne", "lineTwo", "lineThree", "city", "county", "district", "stateOrRegion", "postalCode",
    "countryCode", "notes", "_formattedAddress", "googlePlaceId", "dateScheduledToVisit"]);
  static _firestoreCompositionMemberNames: string[] = [];
  static _firestoreCompositionalDiffMemberNames: string[] = [];

  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Address._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return Address._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return Address._firestoreCompositionMemberNames;}
  retrieveFirestoreIgnoredMemberNames() : string[] { return Address._firestoreIgnoredMemberNames};

  public constructor(init?: Partial<Address>) {
    super();
    Object.assign(this, init);
  }

  createFirestoreDiff(previous: Address, current: Address, memberName: string, diffType: string): FirestoreDiff[] | null {
    return [new FirestoreDiff({newValue: current.formattedAddress(), oldValue: previous.formattedAddress(), fieldName: "address",
                               dateCreated: new Date(), changeType: diffType, activeDocId: current.DocId(),
                               associatedDocId: current.DocId(), userMessage: `Address changed to ${current.formattedAddress()}  member: ${memberName}`})];
  }
}
