<div [formGroup]="form">
  <div class="section-content">
  <div style="width: 580px" fxLayout="row" style="grid-column: 3/5;"><!-- Start of Form field. Also this line controls size of form field, removing this div will make field expand entire width of container-->
    <div autocomplete="off">
      <div style="display: flex; align-items: center;">
        <mat-form-field appearance="outline" style="width: 330px;">
          <textarea style="height: 25px;" [name]="randomElementName" autofill="off" cdkTextareaAutosize autocomplete="new-password" matInput placeholder="Search Address" [matAutocomplete]="nameAuto"
          data-lpignore="true" (keyup)="searchAddressSearchKeyUp($event)" #inputBox formControlName="serviceAddressSearch"></textarea>
          <!-- <mat-error>Select address</mat-error> -->
            <mat-autocomplete panelWidth=600px #nameAuto="matAutocomplete" (optionSelected)="optionSelected($event)">
              <!-- What is displayed for mat-option varies depending on whether search result is an existing customer.  -->
              <mat-option *ngFor ="let place of addressCustomersFound$ | async" [value]="place">
              <div *ngIf="place.customer; else elseBlock"> <i class="material-icons">person_pin</i>{{place.customer.customerNameCompanyDisplay}} - {{place.address.formattedAddress()}}</div>
              <ng-template #elseBlock>
                <!--If there are results from both google and internal, we add seperator between them.-->
                <div *ngIf="place.address == null else googleResultBlock"><mat-divider></mat-divider></div>
              </ng-template>
              <ng-template #googleResultBlock>
                <i class="material-icons">place</i> {{place.address.formattedAddress()}}
              </ng-template>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div>
        <mat-form-field appearance="outline" style="width: 75px; margin-left: 20px;">
          <input type = "text" matInput placeholder="Unit" autocomplete="new-password" autofill="off" formControlName="unit" [name]="randomElementName">
        </mat-form-field>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 10px;">
      <div *ngIf="activeSearch" fxLayout="row" fxLayoutAlign=" center"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div *ngIf="displayNoResultsFound" fxLayout="row" fxLayoutAlign=" center" style="font-size: 16px; color: red; margin-left: 5px;">No Results Found<i class="material-icons">error</i></div>
    </div>

      <!--Distance and time to shop if displaying.  -->
      <div *ngIf="addressToShopRouting != null" class="distance-to-job">
        <b>Distance:</b> {{addressToShopRouting.distanceEstimateMiles}}  -  {{addressToShopRouting.timeEstimateMinutes}}
      </div>

      <!--If previous job count is displayed.-->
      <div *ngIf="previousJobCountAtAddress != null" style="grid-column: 1/2; grid-row: 4/5;">
        <button
          (click) = "ViewPropertyHistoryClick()"
          disabled={{disablePropertyHistory}}
          mat-button class="button-light-blue" style="margin-left: 20px;"
          matBadge={{previousJobCountAtAddress}} matBadgePosition="after"
          matBadgeColor="primary">Property History</button>
    </div>
    </div> <!--end of address form field-->
  </div>
</div>
