import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from '../../../../../common/src/data/dao/customer';

@Component({
  selector: 'app-customer-details-modal',
  template: `
    <!-- <div class="section" [formGroup]="form"> -->
    <div class="section">
      <app-multiple-customer-container
        [customerDetailsForm] = "form"
      ></app-multiple-customer-container>
      <div fxLayout="row" class="footer-cust" fxLayoutAlign="space-between" style="margin-right: 10px; grid-column: 1/3; width: 100%; padding-top: 20px;">
              <button mat-button class="button-gray" (click)="cancel()">Cancel</button>
              <button mat-button class="button-blue" (click)="submit()" type="submit">Save</button>
            </div>
    </div>
  `,
  styleUrls: ['./customer-details-modal.component.scss']
})
export class CustomerDetailsModalComponent implements OnInit, AfterViewInit {

  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CustomerDetailsModalComponent>,
  private dialog: MatDialog) {
    console.log("here");
    this.form = data.customerDetailsForm;
    console.log("there");
   }
  ngAfterViewInit(): void {

  }

   cancel() {
    this.dialogRef.close();
  }

  mapFormToCustomerInformation() : {customer: Customer, role: string, primary: boolean}[] {
    const retVal : {customer: Customer, role: string, primary: boolean}[] = [];
    const customersArray = (this.form.get("customers") as UntypedFormArray).controls;
    customersArray.forEach(customerFormGroup => {
      const customer = (customerFormGroup.get("customer").value as Customer);
      customer.customerTags = customerFormGroup.get("customerTags").value;
      customer.customerName = customerFormGroup.get("customerName").value;
      customer.primaryPhoneNumber = customerFormGroup.get("customerPrimaryPhoneNumber").value;
      customer.customerEmail = customerFormGroup.get("customerEmail").value;
      const role = customerFormGroup.get("customerRole").value;
      const primary = customer.DocId() === this.form.get("primaryCustomerDocId").value;
      retVal.push({customer: customer, role:role, primary: primary});
    });
    return retVal;
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.mapFormToCustomerInformation());
    } else {
      this.form.markAllAsTouched();
    }
  }

  ngOnInit(): void {
  }

}
