import { Injectable } from '@angular/core';
import {AddressService} from './address.service';
import {ReferralCampaignService} from './referral-campaign.service';
;
import { FirestoreBackend} from '../database-backend/retrieve-from-firestore';
import { Customer } from '../dao/customer';
import {StateChangeStoreService} from '../database-backend/state-change-store.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { AuthenticationService } from '../../util/authentication.service';
import { GenericServiceProviderSettingService } from './generic-service-provider-setting.service';


@Injectable({
  providedIn: 'root'
  })
  class CustomerFirestoreService extends FirestoreBackend<Customer> {

  protected basePath = "customers";
  protected RetrieveInstantiatedFirestoreObjectFromJson(obj: Partial<Customer>): Customer {
    return new Customer(obj);
  }

  constructor(private firestoreDiffService: FirestoreDiffService, authService: AuthenticationService) {
      super(new Customer(), authService);
      this.compositionServices.set("firestoreDiffs", {associatedDocumentId: "firestoreDiffDocIds", firestoreBackEnd: firestoreDiffService});
     }

}


@Injectable({
  providedIn: 'root'
})
export class CustomerStoreService extends StateChangeStoreService<Customer> {
  protected store = "customer-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, Customer>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends DatabaseStoreService<Customer> {

constructor(fs: CustomerFirestoreService, authenticationService: AuthenticationService, store: CustomerStoreService,
  private genericServiceProviderSettingService: GenericServiceProviderSettingService,
  private referralCampaignService: ReferralCampaignService, private addressService: AddressService,
  private fireStoreDiffStoreService: FirestoreDiffService ) {
  super(fs, store, authenticationService,
    new Map([
      ["address", {associatedDocumentId: "addressDocId", compositionStoreService: addressService}],
      ["customerReferralCampaign", {associatedDocumentId: "customerReferralCampaignDocId",
                                    compositionStoreService: referralCampaignService}],
      ["accountStatus", {associatedDocumentId: "accountStatusDocId", compositionStoreService:
          genericServiceProviderSettingService}],
      ["customerType", {associatedDocumentId: "customerTypeDocId", compositionStoreService:
          genericServiceProviderSettingService}],
      ["customerTags", {associatedDocumentId: "customerTagDocIds",
                        compositionStoreService: genericServiceProviderSettingService}],
]));
    // Map<string, {associatedDocumentId: string, compositionStoreService:
    //   DatabaseStoreService<RetrieveFirestoreProperties>}>
}

}
