<div class=cust-job-section fxLayout="row">
  <!-- Begin Customer Details Section -->
  <div class="tester">
      <div class="customer-section">
          <div class="section-header" style="margin-left: 0px;">
            <div style="width: 100%;">
              <div class="align-header">
            <div>Customer Details</div>
            <button mat-button class="button-blue" (click)="EditCustomerInfoClick()">Edit Customer(s)</button>
            </div>
            <hr>
            </div>
          </div>
            <div *ngFor="let c of customers | async">
              <app-customer-display-card
                [customerWithRoleAndPrimary] = c
              ></app-customer-display-card>
          </div>
      </div>
  </div>
  <!-- End Customer Details Section -->

<div class="tester2">
  <div class="job-section" >
    <div class="section-header">
      <div style="width: 100%;">
        <div class="align-job-header" style="height: 40px;">
      <div>Job Details</div>
      <button mat-button class="button-blue" (click)="DisplayCustomerCommunicationModal()">View Job Communications</button>
      </div>
      <hr>
      </div>
    </div>
      <div *ngIf="(job | async) !== null">
        <app-job-details
      [form] = "jobDetailsFormGroup"
      [activeJob] = "job | async"
      [jobTypes] = "jobTypes$ | async"
      [jobPriorities] = "jobPriorities$ | async"
        ></app-job-details>

  <div class="service-address-content">
    <!-- Balance Box -->
    <label class="label-style">Balance Due</label>
<app-customer-balance
  [job]="((job))"
></app-customer-balance>
<!-- End Balance Box -->
    <label class="label-style" style="grid-column: 1/2;">Workflow</label>
    <div style="grid-column: 2/6;">
      <button mat-button class="button-outline-blue" type="button" disabled="true" (click)=loadWorkflow()>Job Workflow</button>
      </div>
    <label class="label-style">Service Address</label>
    <mat-form-field appearance="outline" style="width: 330px;">
      <input matInput placeholder="Service Address" value={{(job|async).serviceAddress.formattedAddress()}} disabled=true>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 75px; margin-left: 20px;">
      <input matInput placeholder="Unit" value={{(job|async).serviceAddress.unit}} disabled=true>
    </mat-form-field>
    <div class="change-address-button">
      <button mat-button class="button-light-blue" (click)="ChangeAddress()">Change Address</button>
    </div>
    <div class="property-history-button">
      <button
          disabled={{disablePropertyHistory}}
          mat-button class="button-light-blue"
          matBadge={{jobCountAtAddress$|async}} matBadgePosition="after"
          matBadgeColor="primary" (click)="ViewPropertyHistoryClick()">Property History</button>
    </div>
    <div class="property-history-button">
      <button
          mat-button class="button-light-blue"
          (click)="launchCustomerPdfView()">Customer Pdf</button>
    </div>

    <div>
      <button
          mat-button class="button-light-blue"
          matBadge={{externalAttachementCount}} matBadgePosition="after"
          matBadgeColor="primary" (click)="ViewExternalAttachmentsClick()">External Attachments</button>
    </div>
    <div style="grid-column: 4/5; grid-row: 1/2;">
    <button
      mat-button class="button-outline-blue"
      disabled="{{(job|async).needsAssignedStateWhenCanceled !== null}}"
      (click)="cancelJob()"
      >Cancel Job</button>
    </div>

    <div style="grid-column: 5/6; grid-row: 1/2;">
      <button
      mat-button class="button-green"
      disabled="{{(job|async).needsAssignedStateWhenCanceled === null}}"
      (click)="reactivateJob()"
      >Reopen Job</button>
      </div>
  </div>

      <!-- <div style="grid-column: 1/2; grid-row: 4/5;">
        <button
            disabled={{disablePropertyHistory}}
            mat-button class="button-light-blue" style="margin-left: 20px;"
            matBadge={{jobCountAtAddress$|async}} matBadgePosition="after"
            matBadgeColor="primary" (click)="ViewPropertyHistoryClick()">Property History</button>
      </div> -->

  </div>
</div>
</div>
