import { BehaviorSubject, Observable, of, from } from 'rxjs';
import { RetrieveFirestoreProperties } from './retrieve-firestore-properties';
import { GenerateFirestoreDeltas } from '../../../../web-app/src/app/database backend/generate-firestore-deltas';
import { FirestoreDiffService } from '../dao-services/firestore-diff.service';
import { FirestoreDiff } from '../dao/firestore-diff';

// Adapted from:  https://www.toptal.com/angular/state-management-in-angular-using-firebase
export abstract class StateChangeStoreService<T extends RetrieveFirestoreProperties> {

  protected bs: BehaviorSubject<Map<string, T>>;
  state$: Observable<Map<string, T>>;
  state: Map<string, T>;
  logChanges: boolean;
  private g: GenerateFirestoreDeltas = new GenerateFirestoreDeltas();

  protected abstract store: string;

  public get storeName() { return this.store; }

  constructor(initialValue: Map<string, T>, logChanges: boolean, private fs: FirestoreDiffService) {

    // this.bs = new BehaviorSubject<Map<string, T>>(initialValue);
    // this.state$ = this.bs.asObservable();

    // this.state = initialValue;
    // this.state$.subscribe(s => {
    //     this.state = s;
    // });
    this.logChanges = false;
    // this.logChanges = logChanges;
  }

  private logDeltasToFirestore(deltas: FirestoreDiff[], batch?: string): Observable<FirestoreDiff[]> {
    return this.fs.createMultipleAtomically(deltas, batch);
    }

  protected log(newValue: Partial<T> | null, newState: T | null, previousValue: T | null, operation: string, event: string,
    batch: string): Observable<void> {

      return of(void(0));
      // if (!environment.production && event !== "From Firestore") {
      //   const deltas = this.g.calculateDeltas(previousValue, newState);
      //   if (deltas !== null && deltas.length > 0) {
      //     console.log(deltas);
      //     return this.logDeltasToFirestore(deltas, batch).pipe(
      //       tap( x => {
      //       console.groupCollapsed(`[${this.store} store] [${operation}}] [event: ${event}]`);
      //       console.log("change", newValue);
      //       console.log("prev", previousValue);
      //       console.log("next", newState);
      //       console.groupEnd();
      //       console.groupCollapsed("firestore Diffs");
      //       deltas.forEach(element => {
      //         console.log(element);
      //       });
      //       console.groupEnd();
      //       }),
      //       take(1),
      //       map(() => void(0)));
      //   } else {
      //     return of(void(0));
      //   }
      // } else {
      //   return of(void(0));
      // }
    }

  set(newValue: T, event: string = "Not specified", batch: string): Observable<void> {
    return of(void(0));
      // if (newValue === null) {
      //   return of(void(0));
      // }
      // const registeredDodId: T | null = this.state.has(newValue.DocId()) ? this.state.get(newValue.DocId()) : null;
      // const previousValue: T = registeredDodId == null ? null : registeredDodId;

      // if (this.logChanges) {
      //   return this.log(newValue, newValue, previousValue, "set", event, batch).pipe(
      //     tap( () => {
      //     this.state.set(newValue.DocId(), cloneDeep(newValue));
      //     this.bs.next(this.state);
      //   }
      //   ));
      // } else {
      //   this.state.set(newValue.DocId(), cloneDeep(newValue));
      //   this.bs.next(this.state);
      //   return of(void(0));
      // }
  }

  delete(oldValue: T,  event: string = "Not specified", batch: string): Observable<void> {
    // const registeredDodId: T | null = this.state.has(oldValue.DocId()) ? this.state.get(oldValue.DocId()) : null;
    // this.state.delete(oldValue.DocId());
    // return this.log(null, null, oldValue, "delete", event, batch).pipe(
    //   tap(() => this.bs.next(this.state))
    // );
    return of(void(0));
  }

}
