<div style="margin-top: 10px; font-size: 20px;">Unscheduled Work </div>
<div *ngIf="(draggingSidebar | async) == false" else blank>
  <mat-card appearance="outlined" style="padding: 5px 16px 5px 16px;" *ngFor="let job of jobsNeedingAssignment" trackBy:trackJob (click)="clickUnassignedItem(job)">
    <mat-card-content>
      <div *ngIf="!job.serviceAddress.generatedCommuteMatrix">
        <mat-spinner [diameter]="15"></mat-spinner>
      </div>
      <div class="unscheduled-card">
      <mat-list-item class="njed">{{job.customer.customerName}}</mat-list-item>
      <mat-list-item class="njed">{{job.jobType.name}}</mat-list-item>
      <mat-list-item class="njed" style="grid-column: 3/4;">Existing Visits: {{job.siteVisits.length}}</mat-list-item>
      <mat-list-item class="njed">Duration: {{formatHours(job.remainingToScheduleHours)}}</mat-list-item>
      <mat-list-item class="list-item-specificity" style="grid-column: 1/2;">
        <button mat-button class="button-outline-blue" routerLink="/job-page/{{job.jobDocId}}">
          Job Details
        </button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button class="button-outline-red" disabled="{{!additionalSiteVisitBooked(job)}}" (click)="removeFromScheduler($event,job)">Delete Site Visit</button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button class="button-outline-red" disabled="{{additionalSiteVisitBooked(job)}}" (click)="cancelJob($event,job)">Cancel Job</button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button class="button-outline-blue" (click)="addNote($event,job)">Add Scheduling Note</button>
      </mat-list-item>

      <mat-list-item *ngIf="getAttentionRequired(job).length > 0" style="grid-column: 1/5;">
        <app-job-attention-required-table
          [alerts]="getAttentionRequired(job)"
          ></app-job-attention-required-table>
      </mat-list-item>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<ng-template #blank>
</ng-template>
