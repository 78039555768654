import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of, Subject, throwError} from 'rxjs';
import { Address } from '../dao/address';
import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import {AddressRoutingLocal, db, DexieResourceDayAddressRouting} from '../../../../web-app/src/app/db';
import { catchError, debounce, delayWhen, map, tap} from 'rxjs/operators';
import {AddressRouting} from '../dao/address-routing';
import { LocalSettingsService } from '../../../../web-app/src/app/settings/local-settings.service';
import { Commute } from '../dao/commute';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { addDays, compareAsc, startOfDay } from 'date-fns';
import { ResourceDayAddressRouting } from '../dao/resource-day-address-routing';
import { ResourceDay } from '../dao/resource-day';

@Injectable({
  providedIn: 'root'
})
export class AddressRoutingService extends DatabaseStoreService<AddressRouting> {

  missingMatches: Address[] = [];
  cacheInProgress: string[] = [];
  catchedOriginAddressDocIds: string[] = [];
  cachedResouceDays: Date[] = [];
  addCommuteMatrix: (toAdd: AddressRouting) => void;
  updatedResourceDate$: Subject<Date> = new Subject<Date>();

  cachedResourceDayAddressRouting: ResourceDayAddressRouting[] = [];

  constructor( fs: AddressRoutingFirestoreService, authenticationService: AuthenticationService, store: AddressRoutingStoreService,
    private localSettingsService: LocalSettingsService, private settingsService: SettingsService) {
    super(fs, store, authenticationService);
  }

  removeAddressFromDexie(address: Address): Observable<number> {

    return from(db.transaction("rw", [db.addressRoutingLocal, db.addressCommuteUpdated], () => {
      let deletedRows = 0;
      db.addressCommuteUpdated.where("addressDocId").equals(address.docId).delete();
      db.addressRoutingLocal.where("originAddressDocId").equals(address.docId).delete().then(
        (numDeleted) => {
          deletedRows = numDeleted;
        }
      );
      return deletedRows;
   }).then((added) => {
      console.log(`Deleted ${added} rows from dexie for address: ${address.formattedAddress()}`);
      return added;
    }).catch((error) => {
    console.error(error);
  })).pipe(
    map(x => x as number),
  )
  }

  cacheDexieEntries(dexieEntries: AddressRoutingLocal[]) {
    dexieEntries.forEach(d => this.addCommuteMatrix(d as AddressRouting));
  }

  cacheResourceDayEntries(addressDayRouting: DexieResourceDayAddressRouting[]) {
    addressDayRouting.filter(x => this.cachedResourceDayAddressRouting.findIndex(y => y.docId === x.docId) === -1).forEach(x => {
      this.cachedResourceDayAddressRouting.push(this.getResouceDayAddressRouting(x));
    });
  }

  getResouceDayAddressRouting(val: DexieResourceDayAddressRouting) : ResourceDayAddressRouting {
    const retVal = new ResourceDayAddressRouting();
    retVal.docId = val.docId;
    retVal.resourceDay = val.resourceDay;
    retVal.employeeDocId = val.employeeDocId;
    retVal.originAddressDocId = val.addressRoutingLocal.originAddressDocId;
    retVal.destinationAddressDocId = val.addressRoutingLocal.destinationAddressDocId;
    retVal.distanceMeters = val.addressRoutingLocal.distanceMeters;
    retVal.timeEstimateSeconds = val.addressRoutingLocal.timeEstimateSeconds;
    retVal.addressRoutingSource = val.addressRoutingLocal.addressRoutingSource;
    retVal.reversedFromRequestedRoute = val.addressRoutingLocal.reversedFromRequestedRoute;
    retVal.dateRetrieved = val.addressRoutingLocal.dateRetrieved;
    return retVal;
  }

  cacheProspectiveCommutes(commutes: Commute[]) {
    const adds : AddressRoutingLocal[] = [];
    commutes.filter(x=> x!==undefined).forEach(c => {
      const add = {originAddressDocId: c.orginAddressDocId, destinationAddressDocId: c.destinationAddressDocId,
        timeEstimateSeconds: c.commuteTimeMinutes * 60};
        adds.push(add as AddressRoutingLocal);
    });
    this.cacheDexieEntries(adds);
  }

  populateAddressRoutingFromDexieForResourceDay(day: Date) : Observable<number>{
    const dayStart = startOfDay(day);
    if (this.cachedResouceDays.some(x => compareAsc(x, dayStart) === 0)) {
      return of(0);
    }
    return from(db.resourceDayAddressRoutes.where("resourceDay").equals(day).toArray()).pipe(
      tap(x => this.cacheResourceDayEntries(x)),
      tap(x => this.cacheDexieEntries(x.map(q => q.addressRoutingLocal))),
      tap(x => this.cachedResouceDays.push(dayStart)),
      map(x => x.length),
      catchError(err => {
      console.log('Error caught in observable.', err);
      return this.populateAddressRoutingFromDexieForResourceDay(day);
      })
    );
  }

  populateAddressRoutingFromDexieForResourceDays(startDate: Date, endDate: Date) : Observable<number>
  {
    const obsToRetrieve: Observable<number>[] = [];
    let currentDate = new Date(startDate.getTime());
    do {
      obsToRetrieve.push(this.populateAddressRoutingFromDexieForResourceDay(currentDate));
      currentDate = addDays(currentDate, 1);
    } while (compareAsc(currentDate, endDate) <= 0);
    return combineLatest([...obsToRetrieve]).pipe(
      map(x => x.reduce((a,b) => a + b, 0))
    );
  }

  retrieveResourceDayFromDixie(resouceDay: Date, employeeDocId: string) : Observable<ResourceDay[]> {
    return from(db.resourceDayCommuteUpdated.where("resourceDay").equals(resouceDay).and(x => x.employeeDocId === employeeDocId).toArray()).pipe(
      map(x => {
        const retVal = [];
        x.forEach(y => {
        const entry = new ResourceDay();
        entry.docId = y.docId;
        entry.employeeDocId = y.employeeDocId;
        entry.resourceDay = y.resourceDay;
        entry.numberEntries = y.numberEntries;
        retVal.push(entry);
        });
        return retVal;
      })
    );
  }


}


@Injectable({
  providedIn: 'root'
})
export class AddressRoutingStoreService extends StateChangeStoreService<AddressRouting> {
  protected store = "address-routing-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, AddressRouting>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class AddressRoutingFirestoreService extends FirestoreBackend<AddressRouting> {

  protected basePath = "address_routing";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): AddressRouting {
    return new AddressRouting(obj);
  }

  constructor(authService: AuthenticationService) {
    super(new AddressRouting(), authService);
  }
}
