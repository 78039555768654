export enum LineItemControlType {
  Unknown = 0,
  EstimateCreator = 1,
  LineItemsToComplete = 2,
  InvoiceDisplay=3,
  InvoiceEditor=4,
}

export enum LineItemControlContext {
  Unknown = 0,
  TechnicianInField = 1,
  Website=2,
}
