import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { format, startOfDay } from 'date-fns';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import {  map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomerCommunicationManagementService } from 'web-app/src/app/customer-communication/customer-communication-management.service';
import { CustomerCommunicationCategory } from '../../../../../common/src/data/dao/customer-communication-template';
import { Discount } from '../../../../../common/src/data/dao/discount';
import { FormModelFirestore } from '../../../../../common/src/data/dao/form-model-firestore';
import { FormModelFirestoreService } from '../../../../../common/src/data/dao-services/form-model-firestore.service';
import { LineItem } from '../../../../../common/src/data/dao/line-item';
import { LineItemDisplayModalComponent } from 'web-app/src/app/line-item/line-item-display-modal/line-item-display-modal.component';
import { Audience, LineItemRemovalWithReason } from 'web-app/src/app/line-item/line-item-display/line-item-display.component';
import { AddJournalEntryComponent } from '../add-journal-entry/add-journal-entry.component';
import { Invoice } from '../../../../../common/src/data/dao/invoice';
import { InvoiceService } from '../../../../../common/src/data/dao-services/invoice.service';
import { DiscountService } from '../../../../../common/src/data/dao-services/discount.service';
import { LineItemControlType } from '../../form-builder/component-models/line-items/line-item-enums';
import { InvoicePayment } from '../../../../../common/src/data/dao/invoice-payment';
import { InvoicePaymentService } from '../../../../../common/src/data/dao-services/invoice-payment.service';
import { where } from 'firebase/firestore';
import { FirestoreBackend } from '../../../../../common/src/data/database-backend/retrieve-from-firestore';

@Component({
  selector: 'app-invoice-display',
  templateUrl: './invoice-display.component.html',
  styleUrls: ['./invoice-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InvoiceDisplayComponent implements OnInit, OnDestroy {

  @Input() invoices: BehaviorSubject<Invoice[]>;
  @Input() addToJournalEntry: boolean = false;
  @Input() allocateToJournalEntry: boolean = false;

  @Output() updateAmountAllocated$: BehaviorSubject<any> = new BehaviorSubject(null);
  totalAmountPaid$ : Observable<number>;
  totalAmount$: Observable<number>;
  totalOutstandingAmount$: Observable<number>;
  totalAllocated$: Observable<number>;

  addJournalEntryDialogRef: MatDialogRef<AddJournalEntryComponent>;
  lineItemDisplayModalDialogRef: MatDialogRef<LineItemDisplayModalComponent>;

  destroyingComponent$ = new Subject();

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  columnsToDisplay: string[] = ["customerName","amountPaidToDate","totalAmountDue","outstandingDue","dueDate",
  "dateCreated","invoiceDetails"]

  constructor(private dialog: MatDialog, private invoiceService: InvoiceService, private formModelFirestoreService: FormModelFirestoreService,
    private customerCommunicationManagementService: CustomerCommunicationManagementService, private discountService: DiscountService,
    private invoicePaymentService: InvoicePaymentService) {
   }

  ngOnInit(): void {

    if (this.addToJournalEntry) {
      this.columnsToDisplay.push("lastCommunication");
      this.columnsToDisplay.push("sendInvoice");
      this.columnsToDisplay.push("addToJournalEntry");
    }

    if (this.allocateToJournalEntry) {
      this.columnsToDisplay.push("allocateToJournalEntry");
    }

    this.totalAmountPaid$ = this.invoices.pipe(
      map(x=> x.reduce((acc, cur)=> acc + cur.amountPaid, 0))
    );

    this.totalAmount$ = this.invoices.pipe(
      map(x=> x.reduce((acc, cur)=> acc + cur.totalAmount, 0))
    );

    this.totalOutstandingAmount$ = this.invoices.pipe(
      map(x=> x.reduce((acc, cur)=> acc + cur.outstandingDue, 0))
    );

    this.totalAllocated$ = combineLatest([this.invoices, this.updateAmountAllocated$]).pipe(
      map(([invoices])=> invoices),
      map(x=> (x as Invoice[]).reduce((acc, cur)=> acc + cur.AmountAllocatedToJournalEntry, 0)),
      );
      this.totalAllocated$.subscribe();
  }

  disableWhenNoInvoiceSelected() {
    return (this.invoices.value.find(x => x.addToJournalEntry) === undefined);
  }

  openInvoice(invoice: Invoice) {

    console.log(invoice);

    const editorConfig = new MatDialogConfig();

    const discounts$ : BehaviorSubject<Discount[]> = new BehaviorSubject([]);
    const invoicePayments$ : BehaviorSubject<InvoicePayment[]> = new BehaviorSubject([]);
    const lineItems$ : BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);
    const addLineItem$: Subject<LineItem> = new Subject<LineItem>();
    const removeLineItem$: Subject<LineItemRemovalWithReason> = new Subject<LineItemRemovalWithReason>();
    const editLineItem$: Subject<{old: LineItem, new: LineItem}[]> = new Subject<{old: LineItem, new: LineItem}[]>();
    const closeInvoiceModal$ : Subject<any> = new Subject<any>();

    // load line items and discounts from invoice.
    this.invoiceService.load$(invoice.DocId()).pipe(
      tap(i => invoice = i),
      tap(x => lineItems$.next(x.lineItems)),
      tap(x => discounts$.next(x.discounts)),
      takeUntil(closeInvoiceModal$)
    ).subscribe();

    // load invoice payments
    this.invoicePaymentService.queryFirestoreDeep$([where('invoiceDocId', '==', invoice.DocId())]).pipe(
      switchMap(x => this.invoicePaymentService.loadMultiple$(x.map(y => y.DocId()))),
      map(x => x.filter(y => y.void === false)),
      tap(x => invoicePayments$.next(x)),
      takeUntil(closeInvoiceModal$)
    ).subscribe();

    const editInvoice = editLineItem$.pipe(
      map(q => q[0]),
      map(x => {
        const indexOfLineItem = invoice.lineItems.findIndex(z => z.DocId() === x.old.DocId());
        invoice.lineItems.splice(indexOfLineItem,1,x.new);
        return invoice;
      }));

    const removeLineItemsFromInvoice = removeLineItem$.pipe(
      map(x => {
        const indexOfLineItem = invoice.lineItems.indexOf(x.lineItem);
        invoice.lineItems.splice(indexOfLineItem,1);
        return invoice;
      }));

    const addLineItemToInvoice = addLineItem$.pipe(
      map(x => {
        invoice.lineItems.push(x);
        return invoice;
      }));

      const editDiscounts$ = new Subject<{old: Discount, new: Discount}>().pipe(
        map(x => {
          const indexOfDiscount = invoice.discounts.findIndex(z => z.DocId() === x.old.DocId());
          invoice.discounts.splice(indexOfDiscount,1,x.new);
          return invoice;
        }),
      );

      const removeDiscounts$ = new Subject<Discount>().pipe(
        map(x => {
          const indexOfDiscount = invoice.discounts.indexOf(x);
          const toRemove = invoice.discounts.splice(indexOfDiscount,1);
          toRemove[0].addedFromEstimate = false;
          return {invoice, disc: toRemove[0]}
          }),
          switchMap(x => this.discountService.update$(x.disc).pipe(
            map(() => x.invoice),
            take(1)))
      );

      const addDiscounts$ = new Subject<Discount>().pipe(
        map(x => {
          invoice.discounts.push(x);
          return invoice;
        }
      ));


      merge(editInvoice,removeLineItemsFromInvoice,addLineItemToInvoice,editDiscounts$,removeDiscounts$,addDiscounts$).pipe(
        mergeMap(invoice => this.invoiceService.update$(invoice).pipe(
          take(1)
        )),
        takeUntil(closeInvoiceModal$)
      ).subscribe();


    Object.assign(editorConfig, {
      disableClose : false,
      width        : '500px',
      data         :
      {
        title: "Invoice",
        lineItems$ : lineItems$,
        discounts$ : discounts$,
        invoicePayments$ : invoicePayments$,
        lineItemControlType: LineItemControlType.InvoiceEditor,
        addLineItem$ : addLineItem$,
        removeLineItem$ : removeLineItem$,
        editLineItem$ : editLineItem$,
        addDiscount$ : addDiscounts$,
        removeDiscount$ : removeDiscounts$,
        editDiscount$ : editDiscounts$,
        intendedAudience: Audience.Internal,
      }
      });

    this.lineItemDisplayModalDialogRef = this.dialog.open(LineItemDisplayModalComponent, editorConfig);

    this.lineItemDisplayModalDialogRef.afterClosed().pipe(
      tap( () => closeInvoiceModal$.next(null)),
      take(1)
    ).subscribe();
  }

  invoiceSentDateString(invoice: Invoice) {
    return this.getDeliveryDate(invoice.mostRecentlySentCommunicationDate);
  }

  sendInvoice(invoice: Invoice) {
    console.log("clicked");
    const docIdsToPopulate$ : Observable<void>[] = [of(void(0))];
    if (invoice.formModelFirestore === null) {
      invoice.formModelFirestore = new FormModelFirestore({formFirestore: this.invoiceService.invoiceFormFirestore ,
        formFirestoreDocId: this.invoiceService.invoiceFormFirestore.DocId() });
        docIdsToPopulate$.push(this.formModelFirestoreService.retrieveDocId(invoice.formModelFirestore));
      invoice.formModelFirestoreDocId = invoice.formModelFirestore.DocId();
    }

    let b: string = null;
    FirestoreBackend.retrieveFirestoreWriteBatchIdentifier().pipe(
      tap(batch => b = batch),
      switchMap(() => combineLatest(docIdsToPopulate$)),
      switchMap(() => this.customerCommunicationManagementService.cancelCustomerCommunicationsForInvoice(invoice.DocId(),"InvoiceDisplayComponent.sendInvoice", b)),
      map(() => [invoice]),
      take(1),
      switchMap(invoices => this.invoiceService.popluateJobsToInvoices(invoices)),
      map(invoices => invoices[0]),
      switchMap(() => this.customerCommunicationManagementService.createInvoiceCustomerCommuinications(invoice.job, CustomerCommunicationCategory.PAYMENT,
        invoice.job.siteVisits[0], invoice, "invoice display component", b)),
          map(x => {
            invoice.mostRecentlySentCommunicationDocId = x[0].DocId();
            return invoice;
          }),
          take(1),
        switchMap(invoice => this.invoiceService.update$(invoice, b)),
        switchMap(() => this.invoiceService.commitExternallyManagedFirestoreBatch(b)),
        take(1)
        ).subscribe();
  }

  getDeliveryDate (deliveryDate: Date) : string {
    if (deliveryDate === null) {
      return "";
    } else {
      if (startOfDay(deliveryDate).getTime() === startOfDay(new Date()).getTime()) {
        return format(deliveryDate,'h:mm a');
      } else {
        return format(deliveryDate,'LLL d');
      }
    }
  }

  applyPayment() : void {

    const editorConfig = new MatDialogConfig();
    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      data         :
      {
        invoices:  new BehaviorSubject<Invoice[]>(this.invoices.value.filter(x=> x.addToJournalEntry))
      }
      });

      this.addJournalEntryDialogRef = this.dialog.open(AddJournalEntryComponent, editorConfig);
  }

}
