import { Customer } from "../../../../common/src/data/dao/customer";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Discount, DiscountType } from "./discount";
import { Employee } from "../../../../common/src/data/dao/employee";
import { FormModelFirestore } from "./form-model-firestore";
import { Job } from "./job";
import { LineItem } from "./line-item";

export enum INVOICE_PAYMENT_STATUS {
  OUTSTANDING = "Outstanding",
  PARTIAL_PAYMENT = "Partial Payment",
  PAID_IN_FULL = "Paid in Full",
}

export class Invoice extends RetrieveFirestorePropertiesBase<Invoice>  {

dueDate: Date = new Date();
memo: string;

billingCustomer: Customer;
billingCustomerDocId: string;

employee: Employee;
employeeDocId: string;

lineItems: LineItem[] = [];
lineItemDocIds: string[] = [];
amountPaid: number = 0;

discounts: Discount[] = [];
discountDocIds: string[] = [];

jobDocId: string;
job: Job | null = null;

formModelFirestoreDocId: string;
formModelFirestore: FormModelFirestore = null;

mostRecentlySentCommunicationDocId: string = null;
mostRecentlySentCommunicationDate: Date = null;

_totalAmount: number;
_outstandingDue: number;

// Members used temporally that are not part of firestore document.
addToJournalEntry: boolean = false;
private amountToApplyAgainstJournalEntry: number = undefined;

get totalAmount() {
  let totalBeforeDiscountApplied =   this.lineItems.map(t => t.pricePerItem * t.quantity).reduce((acc, value) => acc + value, 0);
      const fixedDiscounts = this.discounts.filter(y => y.discountType === DiscountType.FIXED);
      const percentDiscounts = this.discounts.filter(y => y.discountType === DiscountType.PERCENTAGE);
      fixedDiscounts.forEach(d => d.setCalculatedDiscountAmount(totalBeforeDiscountApplied));
        totalBeforeDiscountApplied = totalBeforeDiscountApplied + fixedDiscounts.map(d => d.calculatedDiscountAmount).reduce((acc, value) => acc + value, 0);
        percentDiscounts.forEach(d => d.setCalculatedDiscountAmount(0));
        if (percentDiscounts.length > 0) {
          percentDiscounts[0].setCalculatedDiscountAmount(totalBeforeDiscountApplied);
          totalBeforeDiscountApplied = totalBeforeDiscountApplied + percentDiscounts[0].calculatedDiscountAmount;
        }
        return totalBeforeDiscountApplied;
}

get outstandingDue() {
  return Math.round((this.totalAmount - this.amountPaid) * 100) / 100;
}

get pastDue() { return this.dueDate < new Date() ? this.outstandingDue : 0; }

get AmountAllocatedToJournalEntry() {
  if (this.amountToApplyAgainstJournalEntry === undefined) {
    this.amountToApplyAgainstJournalEntry = this.outstandingDue;
  }
  return this.amountToApplyAgainstJournalEntry;
}

set AmountAllocatedToJournalEntry(value: number) {
  this.amountToApplyAgainstJournalEntry = value;
}


static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["billingCustomer","employee","lineItems","job",
"addToJournalEntry", "amountToApplyAgainstJournalEntry", "discounts","formModelFirestore"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["billingCustomerDocId",
"employeeDocId","lineItemDocIds","discountDocIds","formModelFirestoreDocId", "job"]);
static _firestoreCompositionMemberNames = ["billingCustomer","employee","lineItems","discounts","formModelFirestore"];
static _firestoreCompositionalDiffMemberNames: string[] = ["billingCustomer","employee","lineItems","discounts","formModelFirestore"];
static _firestoreDenormalizedMemberNames = ["billingCustomer","employee","lineItems","discounts","formModelFirestore"];

retrieveFirestoreIgnoredMemberNames() : string[] { return Invoice._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Invoice._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Invoice._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Invoice._firestoreCompositionMemberNames;}
retrieveFirestoreDenormalizedMemberNames(): string[] { return Invoice._firestoreDenormalizedMemberNames;}

public constructor(init?: Partial<Invoice>) {
super();
Object.assign(this, init);
}
}
