import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Employee } from "./employee";
import { EmployeeGeofence } from "./employee-geofence";

export class EmployeeGeofenceLog extends RetrieveFirestorePropertiesBase<EmployeeGeofenceLog>  {

  employeeDocId: string = "";
  geofenceDocId: string = "";
  enteredAt: Date | null = null;
  exitedAt: Date | null = null;

  employee: Employee;
  employeeGeofence: EmployeeGeofence;

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["employee","employeeGeofence"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["employee","employeeGeofence"]);
  static _firestoreCompositionMemberNames = ["employee","employeeGeofence"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["employee","employeeGeofence"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return EmployeeGeofenceLog._firestoreIgnoredMemberNames;}
  retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return EmployeeGeofenceLog._firestoreIgnoreDiffTrackingMembers; }
  retrievefirestoreCompositionalDiffMemberNames() : string[] {return EmployeeGeofenceLog._firestoreCompositionalDiffMemberNames;}
  retrievefirestoreCompositionMemberNames() : string[] { return EmployeeGeofenceLog._firestoreCompositionMemberNames;}

  public constructor(init?: Partial<EmployeeGeofenceLog>) {
    super();
    Object.assign(this, init);
  }
}
