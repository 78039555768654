<label>Active Company</label>
<mat-radio-group>
  <mat-radio-button (click)="updateCollection(collection)" class="example-radio-button" *ngFor="let collection of collections" value="{{collection}}">
    {{collection.name}}
  </mat-radio-button>
</mat-radio-group>
<div>Active collection is: {{activeCollection.name}}</div>
<label>Populate active company w/ default data.</label>
<!-- <button (click)="adminService.createMinimalInitialConditions()">Do not click me Greg.</button> -->
<!-- <button (click)="tests()">Tests</button> -->
<button (click)="popJob()">Pop Job</button>
