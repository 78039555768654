import {  AfterViewInit, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject, combineLatest, delay, delayWhen, Observable, of, ReplaySubject, Subject, take, zip} from 'rxjs';
import { tap, filter, map, takeUntil } from 'rxjs/operators';
import { randomElementName } from '../../../../common/src/util/util';
import { FirestoreBackend } from '../../../../common/src/data/database-backend/retrieve-from-firestore';

/**
 * Node for to-do item
 */
export class ItemCatagoryNode<T> {
  children: ItemCatagoryNode<T>[] = [];
  item: T | null;
  key: string;
  name: string;
  selected: boolean = false;
  description: string = undefined;
}

/** Flat to-do item node with expandable and level information */
export class ItemCatagoryFlatNode<T> {
  item: T | null;
  level: number;
  expandable: boolean;
  key: string;
  name: string;
  selected: boolean;
  description: string = undefined;
}

export class ChecklistDatabase<T> {

  dataChange = new BehaviorSubject<ItemCatagoryNode<T>[]>([]);
  selectedNodes = new BehaviorSubject<ItemCatagoryNode<T>[]>([]);
  rootNodeItem: string | T;
  allNodesEmitted$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(private rootNodeItemObs: string | Observable<T>, allNodes: Observable<T[]>,  selectedNodes: Observable<T[]>,
    grouping: (i: T)=> string, nameGenerator: (i:T) => string, public keyGenerator: (i:T) => string, tooltipGenerator:(i:T) => string = (i:T) => null,
    private selectParentWhenAllChildrenSelected: boolean = true ) {

    if (typeof(rootNodeItemObs) === 'string') {
        allNodes.pipe(
        tap(() => this.rootNodeItem = rootNodeItemObs),
        tap(a => this.initialize(a,grouping,nameGenerator,keyGenerator, tooltipGenerator)),
        takeUntil(FirestoreBackend.destroyingComponent$),
        ).subscribe();
    } else {
      combineLatest([rootNodeItemObs,allNodes]).pipe(
        tap(([rootNodeItem,a]) => this.rootNodeItem = rootNodeItem),
        tap(([rootNodeItem,a]) => this.initialize(a,grouping,nameGenerator,keyGenerator, tooltipGenerator)),
        takeUntil(FirestoreBackend.destroyingComponent$),
        ).subscribe();
    }

    if (selectedNodes) {
      selectedNodes.pipe(
        tap(a => this.reflectSelected(a,keyGenerator)),
        takeUntil(FirestoreBackend.destroyingComponent$),
        ).subscribe();
    }

      this.dataChange.pipe(
      ).subscribe();

  }

  get data(): ItemCatagoryNode<T>[] { return this.dataChange.value; }
  get selectedData() : ItemCatagoryNode<T>[] { return this.selectedNodes.value; }


  findNodeInternal(source: T , matching: {sourceMatchGen: (i:T)=> string, existingMatchGen:(i:T)=>string}, nodeSearching?: ItemCatagoryNode<T> ) : ItemCatagoryNode<T> {
    if (nodeSearching === undefined) {
      nodeSearching = this.data[0];
    }
    if (nodeSearching.item !== null &&  matching.existingMatchGen(nodeSearching.item) ===  matching.sourceMatchGen(source)) {
      return nodeSearching;
    } else {
      if (nodeSearching.children.length > 0) {
        for (var child of nodeSearching.children)
        {
          const retVal = this.findNodeInternal(source,matching,child );
          if (retVal !== undefined) {
            return retVal;
          }
        }
        return undefined;
      }
    }
  }

  addMatchingNodes(selectedEquivilants:T[]) : void {
    const selected :ItemCatagoryNode<T>[] = [];
    // We must delay selecting the matching nodes until we have loaded the initial list of nodes.
    of(null).pipe(
      delayWhen(() => this.allNodesEmitted$.pipe(filter(x => x === true))),
      tap(() => {
        selectedEquivilants.forEach(s => {
          const active = this.findNodeInternal(s,{sourceMatchGen: this.keyGenerator, existingMatchGen: this.keyGenerator} )
          if (active !== undefined) {
            selected.push(active);
          }
        });
        this.selectedNodes.next(selected);
      }),
      take(1),
    ).subscribe();
  }

  findNode(source:T, matching?: {sourceMatchGen: (i:T)=> string, existingMatchGen:(i:T)=>string} ) : ItemCatagoryNode<T> {
        if (matching === undefined) {
          matching = {sourceMatchGen: this.keyGenerator, existingMatchGen: this.keyGenerator};
        }
        return this.findNodeInternal(source,matching);
  }


 nodesSelected(nodes: ItemCatagoryNode<T>[], selectedKeys: string[]) {
  nodes.forEach(n => {
    if (selectedKeys.some(s => s === n.key)) {
      n.selected=true;
    } else {
      n.selected= false;
    }
    if (n.children.length > 0) {
      this.nodesSelected(n.children,selectedKeys);
    }
    if (n.children.length > 0) {
        n.selected = n.selected || (this.selectParentWhenAllChildrenSelected && this.areAllChildNodesSelected(n));
        }
  });
 }

 reflectSelected(selectedNodes: T[], keyGenerator: (i:T) => string ) {
    const selectedKeys = selectedNodes.map(x => keyGenerator(x));
    const d = this.dataChange.value;
    this.nodesSelected(d,selectedKeys);
    this.selectedNodes.next(this.retrieveSelectedNodes(d));

 }

  initialize(data: T[], grouping: (i: T)=> string,  nameGenerator: (i:T) => string, keyGenerator: (i:T) => string, tooltipGenerator:(i:T) => string = (i:T) => null ) {

    const itemNodes = this.buildFileTreeRecursively(data, grouping, keyGenerator, nameGenerator,undefined, tooltipGenerator);
    this.dataChange.next(itemNodes);
    this.allNodesEmitted$.next(true);
  }

  retrieveSelectedNodes(node: ItemCatagoryNode<T>[]) : ItemCatagoryNode<T>[]{
    let retVal: ItemCatagoryNode<T>[] = [];
    node.forEach(n => {
      if (n.selected) {
        retVal = retVal.concat(n);
      }
      if (n.children.length > 0) {
        retVal = retVal.concat(this.retrieveSelectedNodes(n.children));
      }
    });
    return retVal;
  }

  areAllChildNodesSelected(parentNode: ItemCatagoryNode<T>) : boolean {
    if (parentNode.children.length === parentNode.children.filter(c=>c.selected).length) {
      return true;
    } else {
      return false;
    }
  }



  buildFileTreeRecursively(items: T[], grouping: (i: T)=> string, keyGenerator: (i:T) => string, nameGenerator: (i:T) => string,
    rootNode?: ItemCatagoryNode<T>, tooltipGenerator:(i:T) => string = (i:T) => null ): ItemCatagoryNode<T>[] {
    const retVal: ItemCatagoryNode<T>[] = [];
    let trueRootNode: boolean = false;
    if (rootNode === undefined) {
      rootNode = new ItemCatagoryNode<T>();
      rootNode.name = typeof(this.rootNodeItem) === "string" ? this.rootNodeItem : nameGenerator(this.rootNodeItem);
      rootNode.item = typeof(this.rootNodeItem) === "string" ? null : this.rootNodeItem;
      rootNode.key =  typeof(this.rootNodeItem) === "string" ? undefined : keyGenerator(this.rootNodeItem);
      rootNode.description = typeof(this.rootNodeItem) === "string" ? null : tooltipGenerator(this.rootNodeItem);
      trueRootNode = true;
    }

      if (items.filter(x => grouping(x) === rootNode.key).length === 0)
      {
        const distinctGroupings = new Set(items.map(x => grouping(x)).filter(z => z!==undefined));
        distinctGroupings.forEach(d => {
          const childNode = new ItemCatagoryNode<T>();
          childNode.name = d;
          childNode.item = undefined;
          childNode.key = d;
          childNode.description = tooltipGenerator(items.find(x => nameGenerator(x) === d));
          childNode.children = this.buildFileTreeRecursively(items, grouping, keyGenerator, nameGenerator, childNode,tooltipGenerator)[0].children;
          rootNode.children.push(childNode);
        })
      } else {
        // if no parentGenerator then node stucture is root -> groupBy() => instances from GroupBy().
      // else it is root -> <<everything with parent of root>> (a,b,c) -> <<everything w/ parent a>>, .....
      const children = items.filter(x => grouping(x) === rootNode.key);
      children.forEach(c => {
        const childNode = new ItemCatagoryNode<T>();
        childNode.name = nameGenerator(c);
        childNode.description = tooltipGenerator(c);
        childNode.item = c;
        childNode.key = keyGenerator(c);
        if (items.filter(z => grouping(z) === childNode.key).length > 0) {
          childNode.children = this.buildFileTreeRecursively(items, grouping, keyGenerator, nameGenerator, childNode, tooltipGenerator)[0].children;
        } else {
          childNode.children = [];
        }
        if (trueRootNode && rootNode.key === childNode.key) {
          rootNode.children.push(...childNode.children);
        } else {
          rootNode.children.push(childNode);
        }
        });
      }

    retVal.push(rootNode);
    return retVal;
  }
}



@Component({
  selector: 'app-mulliselect-nested-tree',
  templateUrl: './multiselect-nested-tree.component.html',
  styleUrls: ['././multiselect-nested-tree.component.scss']
})
export class MultiselectNestedTreeComponent<T> implements AfterViewInit, OnInit, OnDestroy  {

@Output() itemsRemovedFromSelection$ = new EventEmitter<T[]>();
@Output() itemsAddedToSelection$ = new EventEmitter<T[]>();
@Input() checkListDatabase: ChecklistDatabase<T>;

/** Map from flat node to nested node. This helps us finding the nested node to be modified */
flatNodeMap = new Map<ItemCatagoryFlatNode<T>, ItemCatagoryNode<T>>();

/** Map from nested node to flattened node. This helps us to keep the same object for selection */
nestedNodeMap = new Map<ItemCatagoryNode<T>, ItemCatagoryFlatNode<T>>();

/** A selected parent node to be inserted */
selectedParent: ItemCatagoryFlatNode<T> | null = null;

treeControl: FlatTreeControl<ItemCatagoryFlatNode<T>>;

treeFlattener: MatTreeFlattener<ItemCatagoryNode<T>, ItemCatagoryFlatNode<T>>;

dataSource: MatTreeFlatDataSource<ItemCatagoryNode<T>, ItemCatagoryFlatNode<T>>;

/** The selection for checklist */
checklistSelection = new SelectionModel<ItemCatagoryFlatNode<T>>(true, null, true);

destroyingComponent$ = new Subject();

ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

ngAfterViewInit() {
}

classesFromItem(item: any) : any {
  if (item?.classDecoration !== undefined) {
    return item.classDecoration;
  } else {
    return;
  }
}

randomElementName : string = randomElementName();

ElementNameForId(id: any) {
return this.randomElementName.concat(id);
}

ngOnInit(): void {

  this.checkListDatabase.dataChange.pipe(
    takeUntil(this.destroyingComponent$)
  ).subscribe(data => {
    this.dataSource.data = data;
  });

  this.checkListDatabase.selectedNodes.pipe(
    map(nodes =>  nodes.map(n => this.nestedNodeMap.get(n))),
    tap(node => this.checklistSelection.select(...node)),
    tap(node => node.forEach(n => this.checkAllParentsSelection(n))),
    map(nodes => {
      const unselectedNodes : ItemCatagoryFlatNode<T>[] = [];
      this.nestedNodeMap.forEach((x) => {
        if (!nodes.some(a => a.key === x.key) && !x.expandable ) {
          unselectedNodes.push(x)
        }
      });
      return unselectedNodes;
    }),
    tap(node => this.checklistSelection.deselect(...node)),
    takeUntil(this.destroyingComponent$)
    ).subscribe();

  this.checklistSelection.changed.pipe(
    filter(x => x.removed.length > 0),
    map(x => x.removed.filter(anItem => anItem.item !== null)),
    filter(x => x.length > 0),
    map(x => x.map(z => z.item)),
    takeUntil(this.destroyingComponent$)
  ).subscribe(x => this.itemsRemovedFromSelection$.next(x));

  this.checklistSelection.changed.pipe(
    filter(x => x.added.length > 0),
    map(x => x.added.filter(anItem => anItem.item !== null)),
    filter(x => x.length > 0),
    map(x => x.map(z => z.item)),
    takeUntil(this.destroyingComponent$)
  ).subscribe(x => this.itemsAddedToSelection$.next(x));
}


constructor() {
  this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
    this.isExpandable, this.getChildren);
  this.treeControl = new FlatTreeControl<ItemCatagoryFlatNode<T>>(this.getLevel, this.isExpandable);
  this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

}

getLevel = (node: ItemCatagoryFlatNode<T>) => {
  return node.level;
}

isExpandable = (node: ItemCatagoryFlatNode<T>) => node.expandable;

getChildren = (node: ItemCatagoryNode<T>): ItemCatagoryNode<T>[] => node.children;

hasChild = (_: number, _nodeData: ItemCatagoryFlatNode<T>) => _nodeData.expandable;

/**
 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
 */
transformer = (node: ItemCatagoryNode<T>, level: number) => {
  const existingNode = this.nestedNodeMap.get(node);
  const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new ItemCatagoryFlatNode<T>();
  flatNode.item = node.item;
  flatNode.level = level;
  flatNode.key = node.key;
  flatNode.name = node.name;
  flatNode.description = node.description;
  flatNode.expandable = !!(node.children.length > 0);
  this.flatNodeMap.set(flatNode, node);
  this.nestedNodeMap.set(node, flatNode);
  return flatNode;
}

/** Whether all the descendants of the node are selected. */
descendantsAllSelected(node: ItemCatagoryFlatNode<T>): boolean {
  const descendants = this.treeControl.getDescendants(node);
  const descAllSelected = descendants.every(child =>
    this.checklistSelection.isSelected(child)
  );
  return descAllSelected;
}

/** Whether part of the descendants are selected */
descendantsPartiallySelected(node: ItemCatagoryFlatNode<T>): boolean {
  const descendants = this.treeControl.getDescendants(node);
  const result = descendants.some(child => this.checklistSelection.isSelected(child));
  return result && !this.descendantsAllSelected(node);
}

/** Toggle the to-do item selection. Select/deselect all the descendants node */
todoItemSelectionToggle(node: ItemCatagoryFlatNode<T>): void {
  this.checklistSelection.toggle(node);
  const descendants = this.treeControl.getDescendants(node);
  this.checklistSelection.isSelected(node)
    ? this.checklistSelection.select(...descendants)
    : this.checklistSelection.deselect(...descendants);

  // Force update for the parent
  descendants.every(child =>
    this.checklistSelection.isSelected(child)
  );
  this.checkAllParentsSelection(node);
}

/** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
todoLeafItemSelectionToggle(node: ItemCatagoryFlatNode<T>): void {
  this.checklistSelection.toggle(node);
  this.checkAllParentsSelection(node);
}

/* Checks all the parents when a leaf node is selected/unselected */
checkAllParentsSelection(node: ItemCatagoryFlatNode<T>): void {
  let parent: ItemCatagoryFlatNode<T> | null = this.getParentNode(node);
  while (parent) {
    this.checkRootNodeSelection(parent);
    parent = this.getParentNode(parent);
  }
}

/** Check root node checked state and change it accordingly */
checkRootNodeSelection(node: ItemCatagoryFlatNode<T>): void {
  const nodeSelected = this.checklistSelection.isSelected(node);
  const descendants = this.treeControl.getDescendants(node);
  const descAllSelected = descendants.every(child =>
    this.checklistSelection.isSelected(child)
  );
  if (nodeSelected && !descAllSelected) {
    this.checklistSelection.deselect(node);
  } else if (!nodeSelected && descAllSelected) {
    this.checklistSelection.select(node);
  }
}

/* Get the parent node of a node */
getParentNode(node: ItemCatagoryFlatNode<T>): ItemCatagoryFlatNode<T> | null {
  const currentLevel = this.getLevel(node);

  if (currentLevel < 1) {
    return null;
  }

  const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

  for (let i = startIndex; i >= 0; i--) {
    const currentNode = this.treeControl.dataNodes[i];

    if (this.getLevel(currentNode) < currentLevel) {
      return currentNode;
    }
  }
  return null;
}
}
