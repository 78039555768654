import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

interface field {
  name: string;
  type: string;
  mode: string;
}

interface schema {
  fields: field[]
}

export class Report extends RetrieveFirestorePropertiesBase<Report>  {


  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["schemaObj"]);

  populationQuery = "";
  primaryKey = "";
  createdAt: Date = new Date();
  lastRun: Date = new Date(0);
  viewCount = 0;
  bucketId = "";
  schema : string | null = null;
  primaryDateField = "";
  name: string = "";

  schemaObj: any;


  public constructor(init?: Partial<Report>) {
    super();
    Object.assign(this, init);
  }

  retrieveFirestoreIgnoredMemberNames() : string[] { return Report._firestoreIgnoredMemberNames;}
}
