import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { AddressService } from '../../../../../common/src/data/dao-services/address.service';
import { AuthenticationService } from '../../../../../common/src/util/authentication.service';
import { CustomerService } from '../../../../../common/src/data/dao-services/customer.service';
import { EmployeeService } from '../../../../../common/src/data/dao-services/employee.service';
import {CurrencyPipe} from '@angular/common';
import { SiteVisit } from '../../../../../common/src/data/dao/site-visit';
import { Job } from '../../../../../common/src/data/dao/job';
import { Customer } from '../../../../../common/src/data/dao/customer';
import { Invoice } from '../../../../../common/src/data/dao/invoice';
import { Employee } from '../../../../../common/src/data/dao/employee';
import { SettingsService } from '../../settings/settings.service';
import {tap} from 'rxjs/operators';
import { JobService } from '../../../../../common/src/data/dao-services/job.service';
import { SiteVisitService } from '../../../../../common/src/data/dao-services/site-visit.service';
import { InvoiceService } from '../../../../../common/src/data/dao-services/invoice.service';

/**
 * Data Link Node
 * Each node has a name and an optional list of children.
 */
 export interface DataLinkNode {
  name: string;
  children?: DataLinkNode[];
  id: string;
}

export class DataRetrievedFromDataLink {
  dataLinkId: string;
  retrievedValue: string = undefined;

  public constructor(init?: Partial<DataRetrievedFromDataLink>) {
  Object.assign(this, init);
  }

  retrieveData(sourceData: DataUsedByDataLinkService, dataLinkService: DataLinkService) : string {
    if (this.retrievedValue !== undefined) {
      return this.retrievedValue;
    } else {
      const retVal = DataLinkService.dataLinks.find(x => x.id === this.dataLinkId).populateData(sourceData);
      if (retVal !== undefined) {
        this.retrievedValue = retVal;
      }
      return retVal === undefined ? "" : retVal;
    }
  }

  static formattedDataFields(arr: DataRetrievedFromDataLink[], sourceData: DataUsedByDataLinkService, dataLinkService: DataLinkService) : string {
    let retVal = "";
    arr.forEach(d => {
      if (retVal !== "") {
        retVal += "\n"
      }
      const toAdd = d.retrieveData(sourceData, dataLinkService);
      retVal += toAdd === undefined ? "UNKNOWN" : toAdd;
    })
    return retVal;
  }
}

export class DataUsedByDataLinkService {
  jobId: string = undefined;
  firstSiteVisitDocId: string | null = undefined;
  customerDocId: string | null = undefined;
  activeSiteVisitDocId: string = undefined;
  invoiceDocId: string | null = undefined;
  activeSiteVisitEmployeeDocId: string = undefined;

  job: Job = undefined;
  firstSiteVisit: SiteVisit = undefined;
  customer: Customer = undefined;
  activeSiteVisit: SiteVisit = undefined;
  invoice: Invoice = undefined;
  activeSiteVisitEmployee: Employee = undefined;

  public constructor(init?: Partial<DataUsedByDataLinkService>) {
  Object.assign(this, init);
  }

}

export class DataLink {
  ///**Optional string is the document id used to pull data. */
  populateData: (obj: DataUsedByDataLinkService) => string;
  id: string;
  placeholder: string;
  description: string;

  placeHolderOrLinkedData(obj?: DataUsedByDataLinkService) : string {
    if (this.placeholder === "" || obj !== undefined) {
      if (obj === undefined) {
        obj = new DataUsedByDataLinkService();
      }
      return this.populateData(obj) === undefined ? this.placeholder : this.populateData(obj);
    } else {
      return this.placeholder;
    }
  }

  public constructor(init?: Partial<DataLink>) {
  Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataLinkService {

  rootTreeData: DataLinkNode[];
  static dataLinks: DataLink[] = [];

  static mapDataLinksToInsertString(arr: DataLink[]) : string {
    return arr.map(x => `<<${x.id}>>`).join("\n");
  }

  constructor(private addressService: AddressService, private employeeService: EmployeeService, private authenticationService: AuthenticationService,
    private jobService: JobService, private siteVisitService: SiteVisitService,
    private customerService: CustomerService, private settingsService: SettingsService,
    private currencyPipe: CurrencyPipe, private invoiceService: InvoiceService) {
      this.settingsService.settingsLoaded$.pipe(
        tap(() => {
          this.rootTreeData = [
            {
            name: 'General',
            children: this.retrieveGeneralChildren(),
            id: "general"
            },
            {
              name: 'Business Information',
              children: this.retrieveBusinessInfo(),
              id: "business"
            },
            {
              name: 'Customer Information',
              children: this.retrieveCustomerInfo(),
              id: "customer"
            },
            {
              name: 'Payment Information',
              children: this.retrievePaymentChildren(),
              id: "payment"
            }
          ];

            DataLinkService.dataLinks = this.retrieveGeneralDataLinks();
            DataLinkService.dataLinks = DataLinkService.dataLinks.concat(this.retrieveCustomerInfoDataLinks()).concat(this.retrieveBusinessInfoDataLinks()).concat(this.retrievePaymentDataLinks());
        })
      ).subscribe();

  }

  static replaceDataLinkPlaceholders(str: string, obj?: DataUsedByDataLinkService) : string {
    return str.replace(/<<(\w+)>>/g, (match, p1) => {
      const dataLink = DataLinkService.dataLinks.find(x => x.id === p1);
      if (dataLink === undefined) {
        return match;
      } else {
        return dataLink.placeHolderOrLinkedData(obj);
      }
    });
  }

  retrievePaymentChildren() : DataLinkNode[] {
    return [
      {name: "Total Remaining Balance For Job", id: "totalRemainingBalanceForJob",},
      {name: "Total Original Balance For Job", id: "totalOriginalBalanceForJob"},
      {name: "Total Paid To Date For Job", id: "totalPaidToDate"},
    ];
  }

  retrievePaymentDataLinks() : DataLink[] {
    return [
      new DataLink( {id: "totalRemainingBalanceForJob",
        placeholder: this.currencyPipe.transform(0,'USD'),
        description: "Total remaining customer balance for job",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.invoice === undefined) {
            if (data.invoiceDocId === undefined) {
              return "$0.00";
            }
            data.invoice = this.invoiceService.get(data.invoiceDocId);
          }
          return this.currencyPipe.transform(data.invoice.outstandingDue,'USD')
        }
      }),
      new DataLink(
      {
        id: "totalOriginalBalanceForJob",
        placeholder: this.currencyPipe.transform(0,'USD'),
        description: "Total original balance for job",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.invoice === undefined) {
            if (data.invoiceDocId === undefined) {
              return "$0.00";
            }
            data.invoice = this.invoiceService.get(data.invoiceDocId);
          }
          return this.currencyPipe.transform(data.invoice.totalAmount,'USD')
        }
      }),
      new DataLink( {
        id: "totalPaidToDate",
        placeholder: this.currencyPipe.transform(0,'USD'),
        description: "Total paid to date",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.invoice === undefined) {
            if (data.invoiceDocId === undefined) {
              return "$0.00";
            }
            data.invoice = this.invoiceService.get(data.invoiceDocId);
          }
          return this.currencyPipe.transform(data.invoice.amountPaid,'USD');
        }
      })
    ];
  }

  retrieveGeneralChildren() : DataLinkNode[] {
    return [
      {name: "Date (Workflow Begun)", id: "date",},
      {name: "Date Time (Workflow Begun)", id: "dateTime"},
      {name: "Time (Workflow Begun)", id: "time"},
      {name: "Arrival Window", id: "arrivalWindow"},
      {name: "Arrival Date", id: "arrivalDate"},
      {name: "Job #", id: "jobNumber"},
    ];
  }

  retrieveGeneralDataLinks(): DataLink[] {
    return [
      new DataLink( {id: "jobNumber",
        placeholder: "#1234",
        description: "Unique identifier for job.",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.job === undefined) {
            if (data.jobId === undefined) {
              return undefined;
            }
            data.job = this.jobService.get(data.jobId);
          }
          return data.job.serviceProviderJobId}
        }
      ),
      new DataLink(
      {
        id: "date",
        placeholder: format(new Date(), 'E d'),
        description: "Date when workflow was first opened by tech on site.",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.firstSiteVisit === undefined) {
            if (data.firstSiteVisitDocId === undefined) {
              return undefined;
            }
            data.firstSiteVisit = this.siteVisitService.get(data.firstSiteVisitDocId);
          }
          return data.firstSiteVisit.actualStartDate === undefined ? "n/a" :
            format(this.siteVisitService.get(data.firstSiteVisitDocId).actualStartDate, 'E d')
        }
      }),
      new DataLink( {
        id: "dateTime",
        placeholder: format(new Date(), 'PP h:mm aaa'),
        description: "Date / Time when workflow was first opened by tech on site.",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.firstSiteVisit === undefined) {
            if (data.firstSiteVisitDocId === undefined) {
              return "n/a";
            }
            data.firstSiteVisit = this.siteVisitService.get(data.firstSiteVisitDocId);
          }
          return data.firstSiteVisit.actualStartDate === undefined ? "n/a" : format(data.firstSiteVisit.actualStartDate, 'PP h:mm aaa')
        }
      }),
      new DataLink(
      {
        id: "time",
        placeholder: format(new Date(), 'h:mm:ss aaa'),
        description: "Time when workflow was first opened by tech on site.",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.firstSiteVisit === undefined) {
            if (data.firstSiteVisitDocId === undefined) {
              return "n/a";
            }
            data.firstSiteVisit = this.siteVisitService.get(data.firstSiteVisitDocId);
          }
          return data.firstSiteVisit.actualStartDate === undefined ? "n/a" : format(data.firstSiteVisit.actualStartDate, 'h:mm:ss aaa');
        }
      }),
      new DataLink(
        {
          id: "arrivalWindow",
          placeholder: `${format(new Date(2000,1,null,8), 'h:mm aaa')} - ${format(new Date(2000,1,null,12), 'h:mm aaa')}`,
          description: "Arrival Window for Site Visit",
          populateData: (data: DataUsedByDataLinkService) => {
            if (data.activeSiteVisit === undefined) {
              if (data.activeSiteVisitDocId === undefined) {
                return "n/a";
              }
              data.activeSiteVisit = this.siteVisitService.get(data.activeSiteVisitDocId);
            }
            return data.activeSiteVisit.arrivalWindowStartDate === undefined || data.activeSiteVisit.arrivalWindowStartDate ===  null ? undefined :
              `${format(data.activeSiteVisit.arrivalWindowStartDate, 'h:mm aaa')} - ${format(data.activeSiteVisit.arrivalWindowEndDate, 'h:mm aaa')}`
          }
        }),
        new DataLink(
          {
            id: "arrivalDate",
            placeholder: format(new Date(), 'EEEE, LLLL do'),
            description: "Date of Site Visit",
            populateData: (data: DataUsedByDataLinkService) => {
              if (data.activeSiteVisit === undefined) {
                if (data.activeSiteVisitDocId === undefined) {
                  return "n/a";
                }
                data.activeSiteVisit = this.siteVisitService.get(data.activeSiteVisitDocId);
              }
              return (data.activeSiteVisit?.arrivalWindowStartDate) !== undefined ? `${format(data.activeSiteVisit.arrivalWindowStartDate, 'EEEE, LLLL do')}` : undefined;
            }
          }),
    ]
  }

  retrieveBusinessInfo(): DataLinkNode[] {
    return [
      {name: "Business Address", id: "businessAddress"},
      {name: "Business Name", id: "businessName"},
      {name: "Service Tech", id: "serviceTechName"},
      {name: "Business Email", id: "businessEmail"},
      {name: "Business Phone", id: "businessPhone"},
    ];
  }

  retrieveBusinessInfoDataLinks() : DataLink[] {
    return [
      new DataLink( {
        id: "businessEmail",
        placeholder: "customerservice@serviceprovider.com",
        description: "Business Email",
        populateData: () => this.settingsService.getValue("companyEmailAddress")
      }),
      new DataLink( {
        id: "businessPhone",
        placeholder: "410-555-PIPE",
        description: "Business Phone",
        populateData: () => this.settingsService.getValue("companyPhoneNumber")
      }),
      new DataLink( {
        id: "businessAddress",
        placeholder: "123 Business Way\nColumbus OH 43612",
        description: "Business Address",
        populateData: () => this.settingsService.getValue("mailingAddress").formattedAddress()
      }),
      new DataLink( {
        id: "businessName",
        placeholder: "Greg's Chimney Service",
        description: "Business Name",
        populateData: () => this.settingsService.getValue("companyName")
      }),
      new DataLink( {
        id: "serviceTechName",
        placeholder: "Freddy Field Tech",
        description: "User who is scheduled to work on site visit.",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.activeSiteVisitEmployee === undefined) {
            if (data.activeSiteVisitEmployeeDocId === undefined) {
              return "n/a";
            }
            data.activeSiteVisitEmployee = this.employeeService.get(data.activeSiteVisitEmployeeDocId);
          }
          return data.activeSiteVisitEmployee.name;
        }
      }),
    ]
  }

  retrieveCustomerInfo() : DataLinkNode[] {
    return [
      {name: "Customer Name", id: "customerName"},
      {name: "Customer Address", id: "customerAddress"},
      {name: "Customer Email", id: "customerEmail"},
      {name: "Customer Phone", id: "customerPhone"},
      {name: "Customer Company", id: "customerCompany"},
    ];
  }

  retrieveCustomerInfoDataLinks() : DataLink[] {
    return [
      new DataLink( {
        id: "customerPhone",
        placeholder: "555-555-555",
        description: "Customer Phone",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.customer === undefined) {
            if (data.customerDocId === undefined) {
              return undefined;
            }
            data.customer = this.customerService.get(data.customerDocId);
          }
          return data.customer ? data.customer.primaryPhoneNumber : undefined;
        }
      }),
      new DataLink( {
        id: "customerEmail",
        placeholder: "sample@email.com",
        description: "Customer Email",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.customer === undefined) {
            if (data.customerDocId === undefined) {
              return undefined;
            }
            data.customer = this.customerService.get(data.customerDocId);
          }
          return data.customer ? data.customer.customerEmail : undefined;
        }
      }),
      new DataLink( {
        id: "customerName",
        placeholder: "John Doe",
        description: "Customer Name",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.customer === undefined || data.customer === null) {
            if (data.customerDocId === undefined) {
              return undefined;
            }
            data.customer = this.customerService.get(data.customerDocId);
          }
          return data.customer ? data.customer.customerName : undefined;
        }
      }),
      new DataLink( {
        id: "customerAddress",
        placeholder: "Apartment 1\n123 Main Street\nColumbus OH 43612",
        description: "Address of site where where will be done.",
        populateData: (data: DataUsedByDataLinkService) => {
          const retVal = undefined;
          if (data.job === undefined && data.jobId !== undefined) {
            data.job = this.jobService.get(data.jobId);
          }
          if (data.job !== undefined) {
            return data.job.serviceAddress ? data.job.serviceAddress.formattedAddress() : "Undefined service address for job";
          }
          else {
            if (data.customer === undefined) {
              if (data.customerDocId === undefined) {
                return undefined;
              }
              data.customer = this.customerService.get(data.customerDocId);
            }
            return data.customer.address.formattedAddress();
          }
        }
      }),
      new DataLink( {
        id: "customerCompany",
        placeholder: "Acme Paper",
        description: "Company assocaited with customer",
        populateData: (data: DataUsedByDataLinkService) => {
          if (data.customer === undefined) {
            if (data.customerDocId === undefined) {
              return undefined;
            }
            data.customer = this.customerService.get(data.customerDocId);
          }
          return data.customer ? data.customer.company : undefined;
        }
      }),
    ]
  }

  retrieveDataLinkAssociatedWithNode(node: DataLinkNode) : DataLink
  {
    return DataLinkService.dataLinks.find(x => x.id === node.id);
  }

  formattedDataFields(arr: DataLink[]) {
    let retVal = "";
    arr.forEach(d => {
      if (retVal !== "") {
        retVal += "\n"
      }
      retVal += d.placeHolderOrLinkedData();
    })
    return retVal;
  }
}
