import { Injectable } from '@angular/core';
import { AddressService} from './address.service';
import { Job } from '../dao/job';
import { Observable,   combineLatest, zip,  of, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, switchMap, tap, distinctUntilChanged,  takeUntil,  share, filter, debounceTime, take, catchError, debounce, finalize  } from 'rxjs/operators';
import { CustomerService } from './customer.service';
import { LineItemService } from './line-item.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { JobTypeService } from './job-type.service';
import { SiteVisitService } from './site-visit.service';
import { SiteVisit } from '../dao/site-visit';
import { GenericServiceProviderSettingService} from './generic-service-provider-setting.service';
import { AuthenticationService } from '../../util/authentication.service';
import { FormModelFirestoreService } from './form-model-firestore.service';
import { JobAttentionRequiredService } from './job-attention-required.service';
import { JobDurationDeltaService } from './job-duration-delta.service';
import {JobEventService} from './job-event.service';
import { AttachmentService } from './attachment.service';
import { Employee } from '../dao/employee';
import { SettingsService } from '../../../../web-app/src/app/settings/settings.service';
import { CommuteService } from './commute.service';
import { DiscountService } from './discount.service';
import { JobAttentionRequired } from '../dao/job-attention-required';
import { getHours, getMinutes } from 'date-fns';
import { JobDurationDelta, JobDurationDeltaModificationType } from '../dao/job-duration-delta';
import { where } from 'firebase/firestore';
import { AssignmentService } from './assignment.service';
import { CustomerCommunicationManagementService } from '../../../../web-app/src/app/customer-communication/customer-communication-management.service';
import { CompanyLocationService } from './company-location.service';

export interface arrivalWindow {
  startTime: Date;
  endTime: Date;
}

@Injectable({
  providedIn: 'root'
})

  export class JobService extends DatabaseStoreService<Job> {


    constructor( fs: JobFirestoreService, authenticationService: AuthenticationService, store: JobStoreService, private addressService: AddressService,
      private customerService: CustomerService, private jobTypeService: JobTypeService, private lineItemService: LineItemService,
      private firestoreDiffService: FirestoreDiffService, private siteVisitService: SiteVisitService,
      private jobDurationDeltaService: JobDurationDeltaService,
      private genericServiceProviderSettingService: GenericServiceProviderSettingService, private formModelFirestoreService: FormModelFirestoreService,
      private jobAttentionRequiredService: JobAttentionRequiredService,
      private jobEventService: JobEventService, private attachmentService: AttachmentService, private settingsService: SettingsService,
      private commuteService: CommuteService, private discountService: DiscountService, private assignmentService: AssignmentService,
      private customerCommunicationCreationService: CustomerCommunicationManagementService, private companyLocationService: CompanyLocationService) {
      super(fs, store, authenticationService,  new Map([
        ["lineItems", {associatedDocumentId: "lineItemDocIds", compositionStoreService: lineItemService}],
        ["abandonedLineItems", {associatedDocumentId: "abandonedLineItemDocIds", compositionStoreService: lineItemService}],
        ["serviceAddress", {associatedDocumentId: "serviceAddressDocId",
                            compositionStoreService: addressService}],
        ["customer", {associatedDocumentId: "customerDocId", compositionStoreService:
        customerService}],
        ["jobType", {associatedDocumentId: "jobTypeDocId", compositionStoreService:
        jobTypeService}],
        ["siteVisits", {associatedDocumentId: "siteVistDocIds", compositionStoreService:
        siteVisitService}],
        ["jobDurationDeltas", {associatedDocumentId: "jobDurationDeltaIds", compositionStoreService:
        jobDurationDeltaService}],
        ["jobPriority", {associatedDocumentId: "jobPriorityDocId", compositionStoreService:
        genericServiceProviderSettingService}],
        ["jobTags", {associatedDocumentId: "jobTagDocIds", compositionStoreService:
        genericServiceProviderSettingService}],
        ["billingCustomers", {associatedDocumentId: "billingCustomerDocIds", compositionStoreService:
        customerService}],
        ["siteVisitContactCustomers", {associatedDocumentId: "siteVisitContactCustomerDocIds", compositionStoreService:
        customerService}],
        ["formModelFirestore", {associatedDocumentId: "formModelFirestoreDocId", compositionStoreService: formModelFirestoreService}],
        ["jobAttentionRequired", {associatedDocumentId: "jobAttentionRequiredDocIds", compositionStoreService: jobAttentionRequiredService}],
        ["explicitJobEvents", {associatedDocumentId: "explicitJobEventDocIds", compositionStoreService: jobEventService}],
        ["externalAttachments", {associatedDocumentId: "externalAttachmentDocIds", compositionStoreService: attachmentService}],
        ["discounts", {associatedDocumentId: "discountDocIds", compositionStoreService: discountService}],
        ["locationAssignedWork", {associatedDocumentId: "locationAssignedWorkDocId", compositionStoreService: companyLocationService}],
  ]));
    }

    retrieveServiceProviderSpecificJobIdentifier(batch : string) : Observable<string> {
      const incrementCounter = new Number(this.settingsService.getValue('currentJobCounter')).valueOf() + 1;
      const jobFormatting = this.settingsService.getValue('currentJobFormatting');
      return this.settingsService.incrementJobCounter(batch).pipe(
        map(() => Job.generateServiceProviderSpecificJobIdentifier(jobFormatting, incrementCounter))
      );
    }

    override update$(obj: Job, batch?: string | null): Observable<Job> {
    try {
      const responsibleForCommit = this.fs.writeBatchNeeded(batch);
      let batchResolved = of(batch);
      if (responsibleForCommit) {
        batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
      }
      let b: string;
      batchResolved = batchResolved.pipe(
        tap(batch => b = batch)
      );

      // If we haven't yet set the service provider specified id, do that now.
      console.log(obj.serviceProviderSpecifiedJobId);
      if (obj.serviceProviderSpecifiedJobId === null) {
        batchResolved = batchResolved.pipe(
          switchMap(batch => this.retrieveServiceProviderSpecificJobIdentifier(batch).pipe(
            tap(x => obj.serviceProviderSpecifiedJobId = x),
            map(() => batch)
          ))
        );
      }

      const retrieveDocIds$ : Observable<void>[] = [of(void(0))];
      const siteVisitUpdates : SiteVisit[] = [];
      obj.siteVisits.forEach(s => {
        if (s.DocId() === undefined || s.DocId() === null) {
          retrieveDocIds$.push(this.siteVisitService.retrieveDocId(s));
        }
        siteVisitUpdates.push(s);
      });
      const retVal = siteVisitUpdates.length > 0 ?
        batchResolved.pipe(
        switchMap(() => combineLatest(retrieveDocIds$)),
          switchMap(() => zip(...siteVisitUpdates.map(s => {
          const prevVal = this.siteVisitService.getPreviousPureValueIfCached(s.docId);
          return this.customerCommunicationCreationService.createCustomerContactsFromSiteVisit(s, prevVal, obj, "site visit update",null, b);
        }))),
        take(1),
        switchMap(() => super.update$(obj, b))
      ) : batchResolved.pipe(switchMap(() => super.update$(obj, b)));

      if (responsibleForCommit) {
        return retVal.pipe(
          switchMap(x => this.fs.commitFirestoreWriteBatch(b).pipe(
            map(() => x)
            )),
            take(1));
      } else {
        return retVal;
      }
    } catch (err) {
      console.error(err.message);
      throw err;
    }
  }

    override create$(obj: Job, batch?: string): Observable<Job> {

      const responsibleForCommit = this.fs.writeBatchNeeded(batch);
      let batchResolved = of(batch);
      if (responsibleForCommit) {
        batchResolved = FirestoreBackend.retrieveFirestoreWriteBatchIdentifier();
      }

      let b: string;
      batchResolved = batchResolved.pipe(
        tap(batch => b = batch)
      );

      // If we haven't yet set the service provider specified id, do that now.
      if (obj.serviceProviderSpecifiedJobId === null) {
        batchResolved = batchResolved.pipe(
          switchMap(batch => this.retrieveServiceProviderSpecificJobIdentifier(batch).pipe(
            tap(x => obj.serviceProviderSpecifiedJobId = x),
            map(() => batch)
          )));
      }

      const siteVisitUpdates = [];
      const retrieveDocIds$ : Observable<void>[] = [of(void(0))];
      obj.siteVisits.forEach(s => {
        if (s.DocId() === undefined || s.DocId() === null) {
          retrieveDocIds$.push(this.siteVisitService.retrieveDocId(s));
        }
        siteVisitUpdates.push(s);
      });
      const retVal = siteVisitUpdates.length > 0 ? batchResolved.pipe(
        switchMap(() => combineLatest(retrieveDocIds$)),
        switchMap(() => zip(...siteVisitUpdates.map(s => {
        const prevVal = this.siteVisitService.getPreviousPureValueIfCached(s.docId);
        return this.customerCommunicationCreationService.createCustomerContactsFromSiteVisit(s, prevVal, obj, "site visit update", null, b);
        }))),
        take(1),
        switchMap(() => super.create$(obj, b))
        ) : batchResolved.pipe(switchMap(() => super.create$(obj, b)));

      if (responsibleForCommit) {
        return retVal.pipe(
          switchMap(x => this.fs.commitFirestoreWriteBatch(b).pipe(
            map(() => x)
            )),
            take(1));
      } else {
        return retVal;
      }
    }

    private retrieveJobsPopulatedForScheduler(jobDocIds: string[]) : Observable<Job[]> {

      if (jobDocIds.length === 0) {
        return of([]);
      } else {
        return this.loadMultiple$(jobDocIds);
      }
    }

    public addJobAttentionRequired(attention: JobAttentionRequired, modificationType: JobDurationDeltaModificationType,
      job: Job) : Observable<Job> {
      job.jobAttentionRequired.push(attention);
      const hours = getHours(attention.additionalTimeNeedingScheduled) + getMinutes(attention.additionalTimeNeedingScheduled) / 60;
      if (hours > 0) {
        const timeDelta = new JobDurationDelta({employeeAssigningDocId: this.authenticationService.activelyLoggedInEmployeeDocId, deltaHours: hours,
          modificationType, dateModificationOccurred: new Date(), originatingLineItemDocId: attention.originatingLineItemDocId, siteVisitDocId: attention.siteVisitDocId });
        job.jobDurationDeltas.push(timeDelta);
      }
      return this.update$(job);
    }

    public retrieveJobIdsToMonitorForScheduler(minDate: Date, maxDate: Date, includeNeedsAssigned: boolean) : Observable<string[]> {
      const sourceObs = includeNeedsAssigned ? combineLatest([this.retrieveJobIdsNeedingAssignment(),
        this.retrieveJobIdsInDateRangeForEmployees(minDate, maxDate)]) :
        combineLatest([of([]), this.retrieveJobIdsInDateRangeForEmployees(minDate, maxDate)]);
      return sourceObs.pipe(
      map(([a, b]) => new Set(a.concat(b))),
      // distinctUntilChanged((a: Set<string>, b: Set<string>) => {
      // return a.size === b.size && [...a].every(value => b.has(value));
      // }
      // ),
      map(x => Array.from(x)));
    }

  public retrieveJobsToMonitorForScheduler(minDate: Date,maxDate: Date,includeNeedsAssigned: boolean,debounceOn: ReplaySubject<boolean>,
    explicitJobDocIds: string[], activelyModifyingAssignments$ : BehaviorSubject<boolean>): Observable<Job[]> {
    let sourceObs: Observable<string[]>;
    if (includeNeedsAssigned) {
      sourceObs = combineLatest([of(explicitJobDocIds),this.retrieveJobIdsNeedingAssignment()]).pipe(
        filter(() => activelyModifyingAssignments$.value === false),
        map(([a, b]) => [...new Set(a.concat(b))]));
    } else {
      sourceObs = of(explicitJobDocIds);
    }

    const fresh = sourceObs.pipe(
      // When current count is decremented, assignment is removed which triggers fresh, or
      // job is removed from those needing assignment which triggers retrieveJobIdsNeedingAssignment
      distinctUntilChanged((prev, curr) => {
        return curr.length > prev.length;
      }),
      tap((x) => {
        console.log(
          `Fresh from jobs to monitor. ${x.length} jobs  Explicit jobs: ${explicitJobDocIds.length}  MinDate: ${minDate}  MaxDate: ${maxDate} IncludeNeedsAssigned: ${includeNeedsAssigned}`
        );
      }),
      switchMap((x) => this.retrieveJobsPopulatedForScheduler(x).pipe(
        takeUntil(activelyModifyingAssignments$.pipe(filter(x => x === true)))
      )),
      map(x => x.filter(y => !y.importedJob))
    );

    if (explicitJobDocIds.length > 0) {
      return fresh.pipe(
        debounce(() => debounceOn ? debounceOn.pipe(filter((x) => x === true)) : of(true))
        // debounceTime(50)
      );
    } else {
      return fresh.pipe(
        // this debounce time is present b/c currently we are re-calculating all entries each time (navievely); so it helps w/ reducing jankey UI
        debounce(() => debounceOn ? debounceOn.pipe(filter(x => x === true)) : of(true)),
      );
    }
  }

    public searchDateRange(minDate: Date, maxDate: Date ): Observable<Job[]> {
      return this.retrieveJobIdsInDateRangeForEmployees(minDate, maxDate).pipe(
        switchMap(x => this.loadMultiple$(x))
      );
    }


    public addSiteVisitToJob(siteVisit: SiteVisit, job: Job): Job {

      console.log("ADD SITE VISIT TO JOB CALLED");
      const retVal = new Job({...job});
      // Update start date as needed.
      if (job.startDate === null || siteVisit.startDate < job.startDate) {
        job.startDate = siteVisit.startDate;
      } else if (siteVisit.startDate > job.startDate) {
        const earliestSiteVisitStartDate = job.siteVisits.filter(x => x.docId !== siteVisit.docId)?.map(q => q.startDate).sort
        ((a,b) => a.getUTCMinutes() - b.getUTCMinutes());
        if (earliestSiteVisitStartDate === undefined || earliestSiteVisitStartDate[0] > siteVisit.startDate) {
          job.startDate = siteVisit.startDate;
        }
      }
      // Update end date as needed.
      if (job.endDate === null || siteVisit.endDate > job.endDate) {
        job.endDate = siteVisit.endDate;
      } else if (siteVisit.endDate < job.endDate) {
        const latestSiteVisitEndDate = job.siteVisits.filter(x => x.docId !== siteVisit.docId)?.map(q => q.endDate).sort
        ((a,b) => b.getUTCMinutes() - a.getUTCMinutes());
        if (latestSiteVisitEndDate === undefined || latestSiteVisitEndDate[0] < siteVisit.endDate) {
          job.endDate = siteVisit.endDate;
        }
      }
      retVal.siteVisits = [];
      job.siteVisits.forEach(x => retVal.siteVisits.push(x));
      retVal.siteVisits.push(siteVisit);
      return retVal;
    }

    public applySiteVisitModificationsToJobReturnTrueIfArrivalWindowChanged(siteVisit: SiteVisit, job: Job):
      {orignalWindow: arrivalWindow, newWindow: arrivalWindow} {
      const existingSiteVisit = job.siteVisits.find(x => x.docId === siteVisit.docId);
      const retVal = existingSiteVisit.arrivalWindowStartDate.getTime() !== siteVisit.arrivalWindowStartDate.getTime() ||
        existingSiteVisit.arrivalWindowEndDate.getTime() !== siteVisit.arrivalWindowEndDate.getTime() ? {
          orignalWindow: {startTime: existingSiteVisit.arrivalWindowStartDate, endTime: existingSiteVisit.arrivalWindowEndDate},
          newWindow: {startTime: siteVisit.arrivalWindowStartDate, endTime: siteVisit.arrivalWindowEndDate}
        } : null;
      job.siteVisits.splice(job.siteVisits.findIndex(x => x.docId === siteVisit.docId), 1);
      job.siteVisits.push(siteVisit);
      this.updateJobStartEndDateFromSiteVisits(job);
      return retVal;
    }

    private updateJobStartEndDateFromSiteVisits(job: Job): void
    {
      job.startDate = job.siteVisits.sort((a,b) => a.startDate.getUTCMinutes() - b.startDate.getUTCMinutes())[0].startDate;
      job.endDate = job.siteVisits.sort((a,b) => a.startDate.getUTCMinutes() - b.startDate.getUTCMinutes())[0].endDate;
    }

    public removeSiteVisitFromJob(siteVisit: SiteVisit, job: Job, timeNeedsRescheduled: boolean): Job {
      job.siteVisits.splice(job.siteVisits.findIndex(x => x.docId === siteVisit.docId), 1);
      if (job.siteVisits.length > 0) {
        this.updateJobStartEndDateFromSiteVisits(job);
      } else {
        job.startDate = null;
        job.endDate = null;
      }
      if (timeNeedsRescheduled) {
        job.needsAssigned = true;
      }
      return job;
    }

  public retrieveJobIdsNeedingAssignment(): Observable<string[]> {
    return this.queryFirestoreShallow$([where('needsAssigned', '==', true)]).pipe(
      map(x => x.map(z => z.jobDocId)),
      distinctUntilChanged((a, b) => { return a.length === b.length && a.every((v, i) => b.includes(v)); }),
      takeUntil(FirestoreBackend.destroyingComponent$)
    );
  }

    public retrieveJobIdsInDateRangeForEmployees(minDate: Date, maxDate: Date, employees?: Employee[] ): Observable<string[]> {

        const queryFn = ([where('_startDate', '>=', minDate ),(where('_startDate', '<=', maxDate ))]);

        const siteVisitsInRange = this.siteVisitService.queryFirestoreShallow$(queryFn).pipe(
          share()
        );
            if (employees===undefined) {
              return siteVisitsInRange.pipe(
                map(x=> [...new Set<string>(x.map(y=>y.jobDocId))]));
            } else {
              // If employees is passed in, we need to use assignment service to only return jobs that are assigned
              // to an empolyee in the list.
              return siteVisitsInRange.pipe(
                map(x=> {
                  return {val: x, distinct: [...new Set<string>(x.map(y=>y.docId))]};
                }),
                distinctUntilChanged((a,b) => a.distinct.length === b.distinct.length && a.distinct.every((v,i) => v === b.distinct[i])),
                switchMap(s => this.assignmentService.queryFirestoreForInValues("siteVisitDocId", s.distinct).pipe(
                  map(assignments => assignments.filter(a => employees.map(e => e.DocId()).includes(a.employeeDocId) && a.active) ),
                  map(assignments => {
                    const siteVisitDocIds = assignments.map(a => a.siteVisitDocId);
                    return s.val.filter(sv => siteVisitDocIds.includes(sv.docId)).map(sv => sv.jobDocId);
                  })
                )));
            }
      }

  }

@Injectable({
  providedIn: 'root'
})
export class JobStoreService extends StateChangeStoreService<Job> {
  protected store = "job-store";
  constructor(firestoreDiffService: FirestoreDiffService) {
    super(new Map<string, Job>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class JobFirestoreService extends FirestoreBackend<Job> {

  protected basePath = "jobs";

  public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): Job {
    if (obj !== null && obj !== undefined && obj["durationHours"]
      !== null && obj["durationHours"] !== undefined) {
    delete obj["durationHours"];
      }
    return new Job(obj);
  }

  constructor(protected authService: AuthenticationService, private firestoreDiffService: FirestoreDiffService) {
      super(new Job(), authService);
      this.compositionServices.set("firestoreDiffs", {associatedDocumentId: "firestoreDiffDocIds", firestoreBackEnd: firestoreDiffService});
    }

  }


