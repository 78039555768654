import { Injectable } from '@angular/core'

import { AuthenticationService } from '../../util/authentication.service'
import { CustomerService } from './customer.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { FirestoreDiffService } from './firestore-diff.service';
import {  FirestoreBackend } from '../database-backend/retrieve-from-firestore';
import { AttachmentService } from './attachment.service';
import { CustomerCommunication as CustomerCommunication } from '../../../../common/src/data/dao/customer-communication';
import { CustomerCommunicationSchedulingService } from './customer-communication-scheduling.service';
import { CustomerCommunicationTemplateService } from './customer-communication-template.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerCommunicationService extends DatabaseStoreService<CustomerCommunication> {

  constructor( fs: CustomerCommunicationFirestoreService, store: CustomerCommunicationStoreService, authenticationService: AuthenticationService,
    private customerCommuncationTemplateService: CustomerCommunicationTemplateService, private customerService: CustomerService,
    private customerCommunicationSchedulingService: CustomerCommunicationSchedulingService,
    private attachmentService: AttachmentService) {
    super(fs, store,authenticationService,
      new Map([["customerCommunicationTemplate", {associatedDocumentId: "customerCommunicationTemplateDocId",compositionStoreService: customerCommuncationTemplateService}],
      ["attachments", {associatedDocumentId: "attachmentDocIds",compositionStoreService: attachmentService}],
      ["primaryCustomer", {associatedDocumentId: "customerDocId",compositionStoreService: customerService}],
                  ["customerCommunicationScheduling", {associatedDocumentId: "customerCommunicationSchedulingDocId",compositionStoreService: customerCommunicationSchedulingService}]])
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerCommunicationStoreService extends StateChangeStoreService<CustomerCommunication> {
  protected store = "customerCommunication-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, CustomerCommunication>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
  class CustomerCommunicationFirestoreService extends FirestoreBackend<CustomerCommunication> {

 protected basePath = "customerCommunication";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): CustomerCommunication {
return new CustomerCommunication(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new CustomerCommunication(), authService);
 }
}
