import { Injectable } from '@angular/core';
import {DatePipe} from '@angular/common';
import { addMinutes, endOfDay, getHours, getMinutes, startOfDay } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
    timeValueList = new Map<number, Date[]>();
    startOfDay = new Date(2000, 1, 1);
    endOfDay = endOfDay(new Date(2000, 1, 1));

    GetTimeValueList(granularity: number, minDate: Date = null, maxDate: Date = null): string[] {
      let retVal: Date[] = [];
      if (minDate === null) {
        minDate = new Date(this.startOfDay.getTime());
      } else {
        minDate = addMinutes(this.startOfDay, getHours(minDate) * 60 + getMinutes(minDate));
        retVal.push(minDate);
      }
      if (maxDate === null) {
        maxDate = new Date(this.endOfDay.getTime());
      } else {
        maxDate = addMinutes(this.startOfDay, getHours(maxDate) * 60 + getMinutes(maxDate));
        retVal.push(maxDate);
      }

      // console.log(granularity, this.timeValueList.get(granularity));
      retVal = retVal.concat(this.timeValueList.get(granularity)
      .filter(x => x.getTime() >= minDate.getTime() && x.getTime() <= maxDate.getTime()));
      retVal.sort((a,b) => a.getTime() - b.getTime());
      const asString = retVal.map(aDate => this.dateFormatPipe.transform(aDate, 'HH:mm'));
      const distinct = new Set(asString);
      return [...distinct];
    }

    private generatedTimeValueList(granularityMinutes: number): Date[] {
    const retVal: Date[] = [];
    const aDate = new Date(2000, 1, 1);
    const millisecondsFromEpocToADate = aDate.getTime();
    let i = 1;
    do {
        retVal.push(new Date(aDate));
        aDate.setTime(millisecondsFromEpocToADate + granularityMinutes * i * 60 * 1000);
        i++;
    } while (aDate.getDate() === 1);
    return retVal;
    }

    constructor(private dateFormatPipe: DatePipe) {
      this.timeValueList.set(15, this.generatedTimeValueList(15));
      this.timeValueList.set(5, this.generatedTimeValueList(5));
      this.timeValueList.set(30, this.generatedTimeValueList(30));
      this.timeValueList.set(60, this.generatedTimeValueList(60));
  }
}
