import { Type } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyConfig, FormlyField, FormlyFieldConfig, FormlyFormOptions, FormlyTemplateOptions } from '@ngx-formly/core';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import {  catchError, startWith, tap } from 'rxjs/operators';
import { ControlContainerComponent, underlineControlOptions } from './control-container.component';


export abstract class ControlContainsLiveviewComponent extends ControlContainerComponent {


  // Controls which contain live views need Formly specific members/
   formlyForm = new UntypedFormGroup({});
   model: any = {};
   options: FormlyFormOptions = {
   };

   formlyLiveViewFields = (config: {type: string, label: string, hidden: boolean, props: FormlyTemplateOptions, className: string, modelOptions?: any }) => {

    return {
      key: `${this.perInstance}-${config.type}`,
      type: config.type,
      props: {
        label: config.label,
        hideLabel: true,
        disabled: true,
        hidden: config.hidden,
        changeDetect: this.changeDetect$,
        indexInParentContainer: 0,
        ...config.props
      },
      defaultValue: {},
      modelOptions: config.modelOptions,
      wrappers: ["manual-label","change-detect", "form-field"],
      className: config.className,
      hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
        return field.props.hidden;
      },
    }
  }

  controlContainerFormControlsToPatch = ["indexInParentContainer"];

   toFormlyFieldConfigJsonOnly() : FormlyFieldConfig {
     const retVal = this.toFormlyFieldConfig() as FormlyFieldConfig;
     //Json only representation strips out formControl, and changeDetection subject from props.
     const {formControl, ...newObj} = retVal;
     const {changeDetect,...temps} = retVal.props;
     newObj.props = temps;
     newObj.wrappers = newObj.wrappers.filter(x => x !== "change-detect");
    return newObj;
   }

   patchControlComponentsToFormlyFieldsCommon() : Observable<any>[] {
    const obs$ = super.patchControlComponentsToFormlyFieldsCommon();

    this.controlContainerFormControlsToPatch.forEach( patch => {
      obs$.push(
        this.form.get(patch).valueChanges.pipe(
          startWith(this.form.get(patch).value),
          tap(x => this.patchValueIntoTemplateOptionsField(x,patch))));
    });

  const propsToSkipPatching = ["heightPixels","columns","data","ratingPrepopulatedOption"];

    // All component form control are patched in to the formly fields.
    Object.keys(this.componentForm.controls).forEach(key =>
      {
        if (!propsToSkipPatching.includes(key)) {
          obs$.push(
          this.componentForm.get(key).valueChanges.pipe(
            startWith(this.componentForm.get(key).value),
            tap(x => this.patchValueIntoTemplateOptionsField(x,key))));
        }
      }
    );
    return obs$;
   }

   patchValueIntoTemplateOptionsField(value: any, key: string) : void {
    const fieldsToPatch = Array.isArray(this.fields) ? this.fields : [this.fields];
    fieldsToPatch.forEach(field => {
      field.props[key] = value;
   });
    if (this['liveFormlyFieldConfig'] !== undefined) {
      this['liveFormlyFieldConfig'].props[key]=value;
    }
  }

  patchCommonFieldsToForm(formlyConfig : FormlyFieldConfig | FormlyFieldConfig[]) {
    super.patchCommonFieldsToForm(formlyConfig);
    if (Array.isArray(formlyConfig)) {
      formlyConfig.forEach(config => {
        this.patchCommonFieldsToForm(config);
      });
    } else {
      this.controlContainerFormControlsToPatch.forEach( patch => {
        this.form.controls[patch].patchValue((formlyConfig as FormlyFieldConfig).props[patch]);
      });
    }
  }

   patchLabelRelatedClassesToComponentForm(formlyConfig: FormlyFieldConfig) {
    if (formlyConfig.props["labelClass"] !== undefined) {
      this.componentForm.patchValue({labelLocation: formlyConfig.props["labelClass"].includes("label-left") ? 1 : 0 });
      this.componentForm.patchValue({labelBold: formlyConfig.props["labelClass"].includes("bold-label") ? true : false })
      this.componentForm.patchValue({underline: formlyConfig.props["labelClass"].includes("underline-control-black") ? underlineControlOptions.Black :
      formlyConfig.props["labelClass"].includes("underline-control-gray") ? underlineControlOptions.Grey : underlineControlOptions.None });
    }
   }

   patchValueToFields(fieldName: string, fields: FormlyFieldConfig[], value: any) {
     fields.forEach(field => {
       if (field !== undefined) {
        field.props[fieldName] = value;
       }
     });
   }

   patchLabelRelatedFromComponentFormToTemplateOptions(fields: FormlyFieldConfig[]) : Observable<any> {
    return combineLatest([
      this.componentForm.get("labelLocation").valueChanges.pipe(startWith(this.componentForm.get("labelLocation").value)),
      this.componentForm.get("labelBold").valueChanges.pipe(startWith(this.componentForm.get("labelBold").value)),
      this.componentForm.get("underline").valueChanges.pipe(startWith(this.componentForm.get("underline").value))
    ]).pipe(
    tap(combo => {
      const x = combo[1] === true ? "bold-label" : "";
      this.patchValueToFields("labelClass", fields, x);
      }),
    tap(combo => {
      const x = combo[0]  === 1 ? "label-left" :  "label-top";
      this.patchValueToFields("labelClass", fields, this.fields[0].props["labelClass"].concat(` ${x}`));
    }),
    tap(combo => {
      let x = "";
      if (combo[2] === underlineControlOptions.Black) {
        x = "underline-control-black";
      }
      if (combo[2] === underlineControlOptions.Grey) {
        x = "underline-control-gray";
      }
      if (combo[2] === underlineControlOptions.None) {
        x = "";
      }
      this.patchValueToFields("labelClass", fields, this.fields[0].props["labelClass"].concat(` ${x}`));
    }),
    catchError(err => {
    console.log('Error caught in observable.', err);
    return throwError(err);
    }));
   }

   convertFormlyFieldConfigToLiveViewVersion(internal: FormlyFieldConfig): FormlyFieldConfig {

    const retVal: FormlyFieldConfig = {};
    retVal.key = internal.key;
    retVal.type = internal.type;
    retVal.props = {...internal.props};
    retVal.wrappers = internal.wrappers;
    retVal.className = internal.className;
    retVal.props.disabled=false;
    retVal.modelOptions = internal.modelOptions;
    return retVal;
  }

  constructor(public component: Type<any>, protected fb: UntypedFormBuilder) {
    super(component,fb);
  }

}
