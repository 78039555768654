import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, filter, map, merge, share, switchMap, take, tap, zip } from 'rxjs';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { WorkflowType } from '../form-designer/form-designer.component';
import { FormlyUtilityService } from '../component-models/formly-controls/formly-utility.service';
import { FetchUpdatedWorkflowService, WORKFLOW_STAGE } from '../component-models/formly-controls/utilities/fetch-updated-workflow.service';
import { FormFirestoreSummaryService } from '../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ControlContainerComponent } from '../component-models/control-container.component';
import { SectionComponent } from '../section/section.component';
import { UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeploymentDialogResults, WorkflowDeploymentDialogComponentDialog } from './workflow-deployment-dialog/workflow-deployment-dialog.component';

@Component({
  selector: 'app-workflow-header',
  templateUrl: './workflow-header.component.html',
  styleUrls: ['./workflow-header.component.scss']
})
export class WorkflowHeaderComponent implements OnInit {

  constructor(private formlyUtilityService: FormlyUtilityService, private formFirestoreSummaryService: FormFirestoreSummaryService,
    private fetchUpdatedWorkflowService: FetchUpdatedWorkflowService, public dialog: MatDialog) { }

  @Input() activeFormFirestore: FormFirestore;
  @Input() isMain: boolean;
  @Input() loadedFromBranch: boolean;
  @Input() referencedComponents: ControlContainerComponent[];

  @Input() unsavedChanges$: BehaviorSubject<boolean>;

  @Output() saveWorkflowClicked = new EventEmitter<WorkflowType | null>();
  @Output() loadWorkflowClicked = new EventEmitter();
  @Output() copyWorkflowClicked = new EventEmitter();
  @Output() removeWorkflowClicked = new EventEmitter();
  @Output() newWorkflowClicked = new EventEmitter<WorkflowType>();
  @Output() workflowActivationStatusToggled = new EventEmitter();
  @Output() revertToDeployedWorkflowClicked = new EventEmitter();
  @Output() deployWorkflowClicked = new EventEmitter();

  @Input() activeWorkflowType: WorkflowType = null;

  removeWorkflow() {
    this.removeWorkflowClicked.emit(null);
  }

  saveWorkflow(workflowType: string) {
    if (workflowType === null) {
      this.saveWorkflowClicked.emit(null);
    } else {
      this.saveWorkflowClicked.emit(workflowType as WorkflowType);
    }
  }

  newWorkflow(workflowType: string) {
    this.activeWorkflowType = workflowType as WorkflowType;
    this.newWorkflowClicked.emit(workflowType as WorkflowType);
  }

  loadWorkflow() {
    this.loadWorkflowClicked.emit(null);
  }

  copyWorkflow() {
    this.copyWorkflowClicked.emit(null);
  }

  toggleWorkflowActivationStatus() {
    this.workflowActivationStatusToggled.emit(null);
  }

  revertToDeployedWorkflow() {
    this.revertToDeployedWorkflowClicked.emit(null);
  }

  unsavedSubComponentsPresent(referencedComponents: ControlContainerComponent[]) : boolean {
    let retVal = false;
    const sectionComponents = referencedComponents.filter(x => x.component.name === "SectionComponent").map(x => x as SectionComponent);
    for (let section of sectionComponents) {
      const sectionUnsaved = this.checkSectionForUnsavedComponents(section);
      if (sectionUnsaved) {
        retVal = true;
        break;
      }
    }
    return retVal;
  }

  checkSectionForUnsavedComponents(section: SectionComponent) : boolean {
    if (section.activeFormFirestore === undefined) {
      return true;
    } else {
      return this.unsavedSubComponentsPresent((section.componentForm.get("columnFormGroups") as UntypedFormArray).value[0].cdkDropListData as ControlContainerComponent[])
    }
  }

  deployWorkflow() {
    if (this.unsavedChanges$.value) {
      window.alert("You must first save your changes (or abandon them via refresh or resetting to live version) before deploying workflow.");
      return;
    }
    else {
      // Check if any components referenced in form are unsaved entirely.
      const unsavedSubComponentsPresent = this.unsavedSubComponentsPresent(this.referencedComponents);
      if (unsavedSubComponentsPresent) {
        window.alert("Workflow currently contains unsaved sub-workflows.  This must be resolved before deploying new version, please remove or save unsaved workflows.");
        return;
      }
      const parsedForm = JSON.parse(this.activeFormFirestore.form);
      this.formlyUtilityService.activeFormModelFirestore.resetMappings();
      this.fetchUpdatedWorkflowService.resetMappings();
      let retVal: Observable<any>;
      if (parsedForm.type !== undefined) {
        retVal = this.formlyUtilityService.updateSectionsIfNeededAllTheWayDown(parsedForm,WORKFLOW_STAGE.DESIGN,this.formlyUtilityService.activeFormModelFirestore.DocId()).pipe(
          map(x => x.containedFormFirestoreSummaryDocIds.filter(y => y !== this.activeFormFirestore.formSummaryDocId)),
        );
      } else {
        retVal = zip((parsedForm as FormlyFieldConfig[]).map(f => this.formlyUtilityService.updateSectionsIfNeededAllTheWayDown(f,WORKFLOW_STAGE.DESIGN,this.formlyUtilityService.activeFormModelFirestore.DocId()))).pipe(
          map(x => x.flatMap(y => y.containedFormFirestoreSummaryDocIds).filter(y => y !== this.activeFormFirestore.formSummaryDocId))
        );
      }


      retVal = retVal.pipe(
        take(1),
        switchMap(x => this.formFirestoreSummaryService.loadMultiple$(x)),
        map(x => x.filter(y => y.currentDeployedFirestoreDocId !== y.currentDesignFirestoreDocId)),
        share()
      );
      // Check that all sub workflows are deployed else modal with "deploy all / cancel"
      const allGood = retVal.pipe(
        filter(x => x.length === 0),
        tap(() => this.deployWorkflowClicked.emit(false)),
      );

      const decisionNeeded = retVal.pipe(
        filter(x => x.length > 0),
        tap(x => {
          const dialogRef = this.dialog.open(WorkflowDeploymentDialogComponentDialog, {
            data: {workflow: this.activeFormFirestore.formSummary, outOfSyncWorkflows: x}
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === DeploymentDialogResults.DEPLOY_ALL) {
              x.forEach(y => y.currentDeployedFirestoreDocId = y.currentDesignFirestoreDocId);
              zip(x.map(y => this.formFirestoreSummaryService.update$(y))).pipe(
                tap(() => this.deployWorkflowClicked.emit(true)),
                take(1)
              ).subscribe();
            } else if (result === DeploymentDialogResults.DEPLOY_ONLY) {
              this.deployWorkflowClicked.emit(false);
            }
          });
        }),
      );

      // Check that all sub workflows deployed === design, else modal with "deploy all / ok"

      merge(allGood,decisionNeeded).pipe(
        take(1)
      ).subscribe();
    }
  }

  CheckUnsavedChanges() {
    return { 'unsaved': this.unsavedChanges$.value };
  }

  get deployToWorkflowActive() {
    return this.activeFormFirestore && this.activeFormFirestore.formSummary &&
    this.activeFormFirestore.formSummary.currentDesignFirestoreDocId !== this.activeFormFirestore.formSummary.currentDeployedFirestoreDocId &&
    this.activeFormFirestore.formSummary.currentDesignFirestoreDocId !== undefined;
  }

  get revertDeloyedActive() { return this.activeFormFirestore && this.activeFormFirestore.formSummary &&
    this.activeFormFirestore.formSummary.currentDesignFirestoreDocId !== this.activeFormFirestore.formSummary.currentDeployedFirestoreDocId &&
    this.activeFormFirestore.formSummary.currentDeployedFirestoreDocId !== undefined; }

  get getTitle() { return !this.activeFormFirestore  ? "Unsaved" : this.activeFormFirestore.formSummary.title; }

  ngOnInit(): void {
  }

}
