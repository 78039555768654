import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {  AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, debounceTime, interval, merge, Observable, of, Subject, zip } from 'rxjs';
import { delay,distinctUntilChanged, filter, map, pairwise, startWith,  switchMap,  take, takeUntil, tap } from 'rxjs/operators';
import { LocalSettingsService } from '../../settings/local-settings.service';
import { LoadFormComponent } from '../storage/load-form/load-form.component';
import { SaveFormComponent } from '../storage/save-form/save-form.component';
import { ColumnSplitterComponent } from '../column-splitter/column-splitter.component';
import { ComponentFromFormlyFactoryService } from '../component-from-formly-factory.service';
import {  ControlContainerComponent } from '../component-models/control-container.component';
import { ControlContainsControlsComponent } from '../component-models/control-contains-controls.component';
import { Audience, FormlyUtilityService } from '../component-models/formly-controls/formly-utility.service';
import { ContainsControlComponentsInterface, ControlReportsOnViewInitilizationInterface } from '../containsComponents';
import { FormElementComponent } from '../form-element/form-element.component';
import { FormFirestoreService } from '../../../../../common/src/data/dao-services/form-firestore.service';
import { FormFirestore } from '../../../../../common/src/data/dao/form-firestore';
import { FormFirestoreSummary } from '../../../../../common/src/data/dao/form-firestore-summary';
import { FormCatagoryService } from '../../../../../common/src/data/dao-services/form-catagory.service';
import { FormFirestoreSummaryService } from '../../../../../common/src/data/dao-services/form-firestore-summary.service';
import { FormlyComponentUtilityService } from '../component-models/formly-controls/formly-component-utility.service';
import { WorkflowType } from '../form-designer/form-designer.component';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})

export class SectionComponent extends ControlContainsControlsComponent implements OnInit, ContainsControlComponentsInterface, ControlReportsOnViewInitilizationInterface, AfterViewInit, OnDestroy {

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  menuX : number = 0;
  menuY: number = 0;
  saveFormDialogRef: MatDialogRef<SaveFormComponent>;
  loadFormDialogRef: MatDialogRef<LoadFormComponent>;
  dropZoneSettingsInitilized: boolean = false;

  activeFormFirestore: FormFirestore;
  unsavedChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);
  formlyViewInitializing$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  automaticallySaveForm: boolean;
  reset$: Subject<any> = new Subject();
  dropZoneIdAssigned : string = undefined;
  sectionWorkflowType : WorkflowType = WorkflowType.Section;
  extraFunctionEndViewInit: () => void = undefined;

  updatedSectionProperties$: Subject<any> = new Subject<any>();
  activelyManuallySaving : boolean = false;

  get afterViewInitilized$() { return this.formlyViewInitializing$.asObservable() }

  constructor(fb: UntypedFormBuilder, private componentFromFormlyFactory: ComponentFromFormlyFactoryService, private _ngZone: NgZone, private dialog: MatDialog,
    private formlyUtilityService:  FormlyUtilityService, private formCatagoryService: FormCatagoryService, private formFirestoreService: FormFirestoreService,
    private formFirestoreSummaryService: FormFirestoreSummaryService, private localSettingsService: LocalSettingsService,
    private formlyComponentUtilityService: FormlyComponentUtilityService )
  {
    super(SectionComponent,fb);
    this.automaticallySaveForm  = localSettingsService.loadFromLocalStorage("WorkflowDesigner", "autoSaveForms", true);
    this.form = this.initilizeFormGroup();

    this.fields = this.generateFormlyFieldConfigSanSubForms();
    this.setupPropagationOfClickEventsInsideSection();
  }

  setupPropagationOfClickEventsInsideSection() {
    let skipOne: boolean = false;

    // Always pass through when perInstance !== this.perInstance ( child component clicked)
    this.unfilteredComponentClicked$.pipe(
      filter(x => x.perInstance !== this.perInstance),
      tap(() => skipOne = true),
      takeUntil(this.destroyingComponent$),
      ).subscribe(this.filteredComponentClicked$);

      // When not skipping one, propagate.
      this.unfilteredComponentClicked$.pipe(
        filter(x => x.perInstance === this.perInstance && !skipOne),
        takeUntil(this.destroyingComponent$),
      ).subscribe(this.filteredComponentClicked$);

      // When skipping one.
      this.unfilteredComponentClicked$.pipe(
        filter(x => x.perInstance === this.perInstance && skipOne),
        tap(() => skipOne = false),
        takeUntil(this.destroyingComponent$),
      ).subscribe();
  }


  patchControlComponentsToFormlyFields(): void {
    const obs$ = this.patchControlComponentsToFormlyFieldsCommon(this.fields);
    const fieldList = ["itemName","minimumNumberInstances","maximumNumberInstances","repeating","title","formFirestoreDocId","formFirestoreSummaryDocId"];
    fieldList.forEach(f => {
      obs$.push(this.componentForm.get(f).valueChanges.pipe(
        startWith(this.componentForm.get(f).value),
        tap(x => this.fields[0].props[f] = x),
      ));
    });

    obs$.push(this.componentForm.get("activeFormFirestore").valueChanges.pipe(
      startWith(this.componentForm.get("activeFormFirestore").value),
      tap(x => this.activeFormFirestore = x),
    ));

    obs$.push(this.form.get("serializeToDatabase").valueChanges.pipe(
      startWith(this.form.get("serializeToDatabase").value),
      tap(x => this.fields[0].props.serializeToDatabase = x),
    ));

    obs$.push(
      this.form.get("indexInParentContainer").valueChanges.pipe(
        startWith(this.form.get("indexInParentContainer").value),
        tap(x => {
            this.fields[0].props.indexInParentContainer = x;
        })
      )
    );

    this.componentForm.get("maximumNumberInstances").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => this.componentForm.controls["minimumNumberInstances"].setValidators([Validators.min(0),Validators.max(x)])),
      tap(() => this.componentForm.controls["minimumNumberInstances"].updateValueAndValidity()),
      takeUntil(this.destroyingComponent$)).subscribe();

    this.componentForm.get("minimumNumberInstances").valueChanges.pipe(
      distinctUntilChanged(),
      tap(x => this.componentForm.controls["maximumNumberInstances"].setValidators([Validators.min(x), Validators.max(100)])),
      tap(() => this.componentForm.controls["maximumNumberInstances"].updateValueAndValidity()),
      takeUntil(this.destroyingComponent$)).subscribe();

    combineLatest([...obs$]).pipe(
      tap(() => this.fields[0].props.changeDetect.next()),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

}

  get columnFormGroup(): UntypedFormGroup {
    return this.componentForm;
  }

  static fb: UntypedFormBuilder = new UntypedFormBuilder();

  static buildSectionFormGroup() : UntypedFormGroup {
    const retVal = this.fb.group({
      repeating: [false],
      title: [""],
      minimumNumberInstances: [1,[Validators.min(0),Validators.max(100)]],
      maximumNumberInstances: [100,[Validators.min(1), Validators.max(100)]],
      itemName: "Untitled",
      sectionUpdated: [],
      //columnFormGroups here is always 1, and not representative of columns per se.
      columnFormGroups: new UntypedFormArray([]),
      connectedDropLists: [],
      addDropZoneId: "",
      removeDropZoneId: "",
      itemDrop: [],
      itemRemoved: {},
      menuLanchedByContainerId: "",
      explicitRegenerateFormly: {},
      formFirestoreDocId: [],
      formFirestoreSummaryDocId: [],
      activeFormFirestore: [],
    });
    return retVal;
  }

  public get NumberComponents() {
    let subComponentCount = 0;
    ((this.componentForm.get("columnFormGroups") as UntypedFormArray).value as UntypedFormGroup[]).forEach( column => {
      subComponentCount ++;
      const columnControls = (column["cdkDropListData"] as ControlContainerComponent[]);
      columnControls.forEach(control => subComponentCount += control.NumberComponents);
    });
    return subComponentCount;
  }

  public get idForContainingDiv() : string {
    return `div-${((this.componentForm.get("columnFormGroups") as UntypedFormArray).controls[0].value["id"])}`;
  }

  set columnFormGroup(value: UntypedFormGroup) {
    this.form.patchValue({controlComponentFormGroup: value});
    const columnFormGroup = this.buildColumnFormGroup();
    const id = columnFormGroup.get("id").value;


    // Add subscription to connected drop lists to newly built component.
    this.componentForm.controls["connectedDropLists"].valueChanges.pipe(
      startWith(this.componentForm.get("connectedDropLists").value),
      takeUntil(this.componentForm.controls["removeDropZoneId"].valueChanges.pipe(
        filter(x => x === id))),
      map(q => (q as string[])),
      tap(connectedGroups => {
        columnFormGroup.patchValue({cdkDropListConnectedTo: connectedGroups.filter(x => x !== id)})
      }),
      delay(1),
      takeUntil(merge(this.destroyingComponent$,this.reset$))
    ).subscribe();

    (this.componentForm.get("columnFormGroups") as UntypedFormArray).insert(0,columnFormGroup);
  }

  get containedControls() : ControlContainerComponent[] {
    return (this.componentForm.get("columnFormGroups") as UntypedFormArray).value[0].cdkDropListData as ControlContainerComponent[];
  }

  get columnFormGroups(): UntypedFormArray {
    return this.componentForm.get('columnFormGroups') as UntypedFormArray;
  }

  initilizeFormGroup(): UntypedFormGroup {
    const retVal = this.createDefaultControlContainerFormGroup("Section");
    retVal.patchValue({
      icon: "note_add",
      iconColor: "#868686",
      controlComponentFormGroup: SectionComponent.buildSectionFormGroup(),
      composerComponent: true,
      menuLanchedByContainerId: "",
    });
    return retVal;
  }

  removeSection() {
    this.componentForm.patchValue({menuLanchedByContainerId: this.columnFormGroups.value[0].id});
    const idsToRemove = this.associtedDropZoneIds;
    idsToRemove.forEach(i => this.componentForm.patchValue({removeDropZoneId: i}));
    (this.componentForm.get("columnFormGroups") as UntypedFormArray).removeAt(0);
  }

  deleteControlsContainedInSection() {
    const idsToRemove = this.associtedDropZoneIds.filter(x => x !== (this.componentForm.get("columnFormGroups") as UntypedFormArray).value[0].id);
    idsToRemove.forEach(i => this.componentForm.patchValue({removeDropZoneId: i}));
  }

  ngOnDestroy(): void {
    this.controlContainerCommonDestruction();
    ((this.componentForm.get("columnFormGroups") as UntypedFormArray).value as UntypedFormGroup[]).forEach( c => {
      this.columnFormGroup.patchValue({removeDropZoneId:  c["id"]});
    });
  }



  autoSaveForm() {
    const readyToSave = this.checkForSaveValidity();
    if (!readyToSave) {
      // window.alert("You first must save sub sections.");
      return;
    }
    const newFormFireStore = new FormFirestore();
    this.formFirestoreService.retrieveDocId(newFormFireStore).pipe(
      tap(() => {
        let formFirestoreSummaryDocId = this.componentForm.get("formFirestoreSummaryDocId").value;
        this.componentForm.patchValue({formFirestoreDocId: newFormFireStore.DocId(), formFirestoreSummaryDocId: formFirestoreSummaryDocId});
        const savedVersion = JSON.stringify(this.stripSectionRepeatConfiguration(this.toFormlyFieldConfigJsonOnly() as FormlyFieldConfig));
        newFormFireStore.formSummary = this.activeFormFirestore.formSummary;
        newFormFireStore.title = this.activeFormFirestore.title;
        newFormFireStore.form = savedVersion;
      }),
      map(() => {
        newFormFireStore.formSummary.currentDesignFirestoreDocId = newFormFireStore.DocId();
        return newFormFireStore;
      }),
      switchMap(newFormFireStore => this.formFirestoreService.create$(newFormFireStore)),
      tap(() => this.unsavedChanges.next(false)),
      filter(x => x.formSummary !== null),
      tap(x => this.activeFormFirestore = x),
      tap(x => console.log(x,` string`)),
      tap(() => console.log("AUTO SAVING SECTION.")),
      tap(() => this.componentForm.patchValue({sectionUpdated: true})),
      take(1),
      ).subscribe();
  }

  checkForSaveValidity() : boolean {
    // We must iterate through all controls and check if there are any formly-sections which have not yet been saved.
    // If there are, we must prompt the user to save the form before proceeding.
    const toCheck = this.columnFormGroups.controls[0].get('cdkDropListData').value.filter(x => x.serializeToDatabase);
    for (const formlySection of toCheck) {
      if (formlySection.component.name === "ColumnSplitterComponent" && formlySection.activelyManuallySaving) {
        return false;
      }
      const sectionRetVal = this.checkForSectionValidity(formlySection.toFormlyFieldConfig() as FormlyFieldConfig);
      if (!sectionRetVal) {
        return false;
      }
    }
    return true;
  }

  checkForSectionValidity(f : FormlyFieldConfig) : boolean {
    if (f.type === "formlySection" && !f.props.formFirestoreSummaryDocId) {
      return false;
    } else {
      if (f.fieldGroup) {
        for (const sub of f.fieldGroup) {
          if (!this.checkForSectionValidity(sub)) {
            return false;
          }
        }
    }
      return true;
    }
  }


  saveToFirestore(toSave: FormFirestore) {
    const readyToSave = this.checkForSaveValidity();
    if (!readyToSave) {
      window.alert("You first must save sub sections.");
      return;
    }
    this.activelyManuallySaving = true;
    const existingformFirestoreDocId = this.componentForm.get("formFirestoreDocId").value;
    const existingFormFirestoreSummaryDocId = this.componentForm.get("formFirestoreSummaryDocId").value;
    let formFirestoreSummaryDocId$ : Observable<string>;
    if ( this.componentForm.get("formFirestoreSummaryDocId").value === null) {
      formFirestoreSummaryDocId$ = this.formFirestoreService.retrieveFirestoreRawDocId();
    } else {
      formFirestoreSummaryDocId$ = of(this.componentForm.get("formFirestoreSummaryDocId").value);
    }

    const formFirestoreDocId$ = this.formFirestoreService.retrieveFirestoreRawDocId();

    zip(formFirestoreDocId$, formFirestoreSummaryDocId$).pipe(
      tap(([formFirestoreDocId, formFirestoreSummaryDocId]) => {
        this.componentForm.patchValue({formFirestoreDocId: formFirestoreDocId, formFirestoreSummaryDocId: formFirestoreSummaryDocId});
        const savedVersion = JSON.stringify(this.stripSectionRepeatConfiguration(this.toFormlyFieldConfigJsonOnly() as FormlyFieldConfig));
        const editorConfig = new MatDialogConfig();
        Object.assign(editorConfig, {
          data: {
          form: savedVersion,
          formFirestore: toSave,
          formType: "section",
          formFirestoreDocId: formFirestoreDocId,
          formFirestoreSummaryDocId: formFirestoreSummaryDocId,
          }});

        this.saveFormDialogRef = this.dialog.open(SaveFormComponent, editorConfig);

        const savedForm = this.saveFormDialogRef.afterClosed().pipe(
          filter(x => x !== undefined),
          switchMap(x => this.formFirestoreService.load$(x.DocId())),
          filter(x => x.formSummary !== null),
          tap(x => {
            this.activeFormFirestore = x;
            this.activelyManuallySaving = false;
            this.unsavedChanges.next(false);
          }),
          tap(() => this.componentForm.patchValue({sectionUpdated: true})),
          tap(()=> this.fields[0].props.changeDetect.next())
        );

        const exitedModal = this.saveFormDialogRef.afterClosed().pipe(
          filter(x => x === undefined),
          tap(() => this.componentForm.patchValue({formFirestoreDocId: existingformFirestoreDocId,
            formFirestoreSummaryDocId: existingFormFirestoreSummaryDocId})),
          tap(() => this.activelyManuallySaving = false),
        );

        merge(savedForm,exitedModal).pipe(
          take(1),
        ).subscribe();
      }),
      take(1),
    ).subscribe();

  }

  saveSection() {
    this.saveToFirestore(this.activeFormFirestore);
  }

  copyWorkflow() {
    const copy = new FormFirestore(this.activeFormFirestore);
    const summaryCopy = new FormFirestoreSummary(copy.formSummary);
    copy.formSummary = summaryCopy;
    copy.formSummaryDocId = undefined;
    combineLatest([this.formFirestoreService.retrieveDocId(copy),this.formFirestoreSummaryService.retrieveDocId(summaryCopy)]).pipe(
      tap(() => this.saveToFirestore(copy)),
      take(1)
    ).subscribe();
  }

  revertToDeployedWorkflow() {
    const f = this.activeFormFirestore.formSummary;
    if (f.currentDeployedFirestoreDocId === undefined)
    {
      alert("No deployed version to revert to.");
      return;
    }
    f.currentDesignFirestoreDocId = f.currentDeployedFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      switchMap(x => this.formFirestoreService.load$(f.currentDesignFirestoreDocId)),
      filter(x => x.formSummary !== null),
      tap(() => this.formlyViewInitializing$.next(true)),
      tap(x => this.activeFormFirestore = x),
      map(x => JSON.parse(x.form)),
      tap(x => this.patchFormlyFieldConfigFromFormFirestore(x)),
      take(1)
      ).subscribe();
  }

  deployWorkflow(refresh: boolean) {
    const f = this.activeFormFirestore.formSummary;
    f.currentDeployedFirestoreDocId = f.currentDesignFirestoreDocId;
    this.formFirestoreSummaryService.update$(f).pipe(
      tap(() => {
        if (refresh) {
          this.loadForm(this.activeFormFirestore);
        }
      }),
      take(1)
    ).subscribe();
  }

  loadForm(formFirestore: FormFirestore) {
    this.deleteControlsContainedInSection();
    this.unsavedChanges.next(false);
    this.formlyViewInitializing$.next(true);
    this.activeFormFirestore = formFirestore;
    this.patchFormlyFieldConfigFromFormFirestore(JSON.parse(formFirestore.form));
  }


  loadSection() {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      data: {
      formCatagory: null,
      formType: "section",
      explicitWorkflowList: ["section"]
      }
    });

    this.loadFormDialogRef = this.dialog.open(LoadFormComponent, editorConfig);

    // Event dialog closure:
    this.loadFormDialogRef.afterClosed().pipe(
      take(1),
      filter(x => x !== undefined),
      map(x => x as FormFirestore),
      tap(x => this.loadForm(x)),
      ).subscribe();
  }

  newSection(workflowType: WorkflowType) {
    if (workflowType !== WorkflowType.Section) {
      throw new Error("Section component can only create new sections.");
    }
    this.reset$.next(null);
    this.componentForm.patchValue({title: "",itemName: "Untitled",sectionUpdated: null,formFirestoreDocId: null, formFirestoreSummaryDocId: null, activeFormFirestore: null});
    (this.componentForm.get("columnFormGroups") as UntypedFormArray).clear();
    this.columnFormGroup = this.form.get("controlComponentFormGroup").value;
    this.fields[0].props.changeDetect.next();
  }

  forbidSectionComponentsPredicate(item: CdkDrag<ControlContainerComponent>) {
    // return !(item.data.form.get("controlName").value === "Section");
    return true;
  }

  test() {
    console.log(this.activeFormFirestore);
    console.log(this.perInstance);
  }

  patchChangesToDropZonesAndItems(retVal: UntypedFormGroup) {
    const toPatchThrough: string[] = ["addDropZoneId","removeDropZoneId","itemRemoved","itemDrop","sectionUpdated"];

    toPatchThrough.forEach(patch => {
      if (retVal.controls[patch] !== undefined) {
      retVal.controls[patch].valueChanges.pipe(
        tap(val => this.componentForm.controls[patch].patchValue(val)),
        takeUntil(this.destroyingComponent$),
        ).subscribe();
      }
    });

    this.componentForm.get("connectedDropLists").valueChanges.pipe(
      tap(val => retVal.controls["connectedDropLists"].patchValue(val)),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    // If final control is removed from a column it should be deleted.
    retVal.controls["removeDropZoneId"].valueChanges.pipe(
      tap(x => {
        if ( (retVal.get("columnFormGroups").value as UntypedFormArray).length === 1 && this.columnFormGroups.value[0] !== undefined) {
          const spliceIndex = this.columnFormGroups.value[0].cdkDropListData.findIndex(c => (c as ControlContainerComponent).form.get("controlName").value === "Columns" &&
            (c as ColumnSplitterComponent).componentForm.get("menuLanchedByContainerId").value == x)
          // Index is only found when deleting a top level component, but a splice on -1 === a splice on last element, and we only want to remove
          // Component if it was deleted, not if a composed component within it was deleted...
            if (spliceIndex > -1) {
              this.columnFormGroups.value[0].cdkDropListData.splice(spliceIndex,1);
            }
        }
      }),
      takeUntil(this.destroyingComponent$),
    ).subscribe();
  }

  public dropListsForContainedColumns() : string[] {
    return this.componentForm.get("connectedDropLists").value;
  }

     // ******************** Members required for live view of formly form.   ******************* //

     public buildColumnSplitterFormGroup() : UntypedFormGroup {
      const retVal = ColumnSplitterComponent.buildColumnSplitterFormGroup();
      retVal.patchValue({connectedDropLists: [this.dropListsForContainedColumns()]});
      this.patchChangesToDropZonesAndItems(retVal);
      return retVal;
    }

    public buildSectionFormGroup() : UntypedFormGroup {
      const retVal = SectionComponent.buildSectionFormGroup();
      retVal.patchValue({connectedDropLists: [this.dropListsForContainedColumns()]});
      this.patchChangesToDropZonesAndItems(retVal);
      return retVal;
    }

    mutateComponentWhenDroppedInContainer(c: ControlContainerComponent): ControlContainerComponent {
      let retVal: ControlContainerComponent;

      if (c.form.get("composerComponent").value || c.form.get("ControlRequiresFullWidth").value) {
        //When sections are dropped, we must contstruct a new section b/c the existing section patches things to the main form ( as it was instantiated there)
        //But that doesn't work, b/c the responsibility for passing events up and down the chain lies with the parent.
        if (c.form.get("controlName").value === "Section") {
          const droppedSection = c as SectionComponent;
          const sectionComponentToAdd = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog,
            this.formlyUtilityService,this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService, this.formlyComponentUtilityService);

          sectionComponentToAdd.columnFormGroup = this.buildSectionFormGroup();
          if (droppedSection.componentForm.get("activeFormFirestore").value!==null)
          {
            const activeFormFirestoreDocId = droppedSection.componentForm.get("activeFormFirestore").value.DocId();

            sectionComponentToAdd.extraFunctionEndViewInit = () =>
            {
              sectionComponentToAdd.deleteControlsContainedInSection();
              sectionComponentToAdd.unsavedChanges.next(false);
              sectionComponentToAdd.formlyViewInitializing$.next(true);
              const parsedForm = droppedSection.toFormlyFieldConfigJsonOnly() as FormlyFieldConfig;
              sectionComponentToAdd.patchFormlyFieldConfigFromFormFirestore(parsedForm);
              this.formFirestoreService.load$(activeFormFirestoreDocId).pipe(
                tap(x => sectionComponentToAdd.componentForm.patchValue({activeFormFirestore: x, formFirestoreDocId: x.DocId(), formFirestoreSummaryDocId: x.formSummaryDocId})),
                take(1)
              ).subscribe();
            };
          }

          retVal = sectionComponentToAdd;
          //Also must emit to remove drop zone for dropped component, as it has moved OOS.
          this.componentForm.patchValue({removeDropZoneId:
            droppedSection.componentForm.get("columnFormGroups").value[0].id});
        } else {
          retVal = c;
        }
      } else {
        const columnSplitterComponent = new ColumnSplitterComponent(this.fb,this.componentFromFormlyFactory,this._ngZone, this.formlyComponentUtilityService);
        columnSplitterComponent.columnFormGroup = this.buildColumnSplitterFormGroup();
        columnSplitterComponent.addColumnAtIndex(0,true);
        const dropListToPushTo = (((columnSplitterComponent.componentForm.get("columnFormGroups") as UntypedFormArray).at(0) as UntypedFormGroup).get("cdkDropListData").value as Array<ControlContainerComponent>);
        c.parentContainer = columnSplitterComponent;
        dropListToPushTo.push(c);
        retVal = columnSplitterComponent;
      }
      retVal.parentContainer = this;
      return retVal;
    }

  onTalkDrop(event: CdkDragDrop<ControlContainerComponent[]>) {
    FormElementComponent.emitComponentClicked=true;
    if (event.previousContainer === event.container)  {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.container.data);
        this.componentForm.patchValue({explicitRegenerateFormly: true});
      // Moved from a different container.
    } else  {
      const controlWithMutation = this.mutateComponentWhenDroppedInContainer(event.previousContainer.data[event.previousIndex]);
      event.previousContainer.data[event.previousIndex] = controlWithMutation;
      transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
      this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.previousContainer.data);
      this.formlyComponentUtilityService.updateControlContainerComponentsPositionInDropList(event.container.data);
      // When an empty section is dropped, it must emit to the section that it is no longer initializing.
      if (event.previousContainer?.id === "sidebarDragDropZone" && controlWithMutation.component.name  === "SectionComponent") {
          (controlWithMutation as SectionComponent).formlyViewInitializing$.next(false);
        }
      }
    this.componentForm.patchValue({itemDrop: event});
    this.changeDetect$.next(null);
  }

  generateFormlyFieldConfigSanSubForms() : FormlyFieldConfig[] {

    return [{
      type: 'formlySection',
      defaultValue: [{}],
      key: `${this.perInstance}-section`,
      fieldGroup: [],

      props: {
        audience: Audience.All,
        className:"",
        changeDetect: this.changeDetect$,
        destroyComponents$: new Subject<any>(),
        itemName: "default item name",
        minimumNumberInstances: 1,
        maximumNumberInstances: 100,
        guid: this.guid,
        formFirestoreDocId: "",
        formFirestoreSummaryDocId: "",
        activeFormFirestore: [],
      },
      hooks: {
        afterViewInit: (field: FormlyFieldConfig) => {
          setTimeout(() => this.formlyViewInitializing$.next(false), 5000);
        }
      },
      wrappers: ["change-detect"],
    }];
  }


  fixIgnoredValidationErrors(temps: FormlyTemplateOptions) {
    if (temps.minimumNumberInstances === undefined ||  temps.minimumNumberInstances < 0) {
      temps.minimumNumberInstances=0;
     } else if (temps.minimumNumberInstances > temps.maximumNumberInstances) {
      temps.minimumNumberInstances = temps.maximumNumberInstances;
     }

     if (temps.maximumNumberInstances === undefined) {
       if (temps.minimumNumberInstances > 0) {
        temps.maximumNumberInstances = temps.minimumNumberInstances;
       } else {
        temps.maximumNumberInstances = 1;
       }
     }

     if (temps.maximumNumberInstances > 100) {
      temps.maximumNumberInstances = 100;
     } else if (temps.maximumNumberInstances < 1) {
      temps.maximumNumberInstances=1;
     }
  }

  toFormlyFieldConfigJsonOnly(): FormlyFieldConfig | FormlyFieldConfig[] {
    const column = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
    const idField = {
      'key': '_id',
      'type': 'input',
      'props': {},
      'defaultValue': null,
      'hide': true,
      'resetOnHide' : false,
    };
    const fg = this.retrieveFormlyFieldConfigForContainedElements(column.controls[0] as UntypedFormGroup,true).concat(idField);
    // For working representation ( with add / remove of section instances ) we need to use fieldArray.  But
    // for displaying updates properly in design view, we must use fieldGroup.
    const {fieldGroup,...retVal} = this.fields[0];
    retVal.fieldArray = {
      fieldGroup:fg
    };
    retVal.wrappers = [];
    this.fixIgnoredValidationErrors(retVal.props);
    retVal.hooks = undefined;
    const {changeDetect,destroyComponents$,subPageSectionSizes$,
      addSubPageSection$,removeSubPageSection$,activeFormFirestore,guid,...temps} = retVal.props;
     retVal.props = temps;
    return retVal;
  }

  stripSectionRepeatConfiguration(input: FormlyFieldConfig) : FormlyFieldConfig {
    const retVal = input;
    const {itemName,minimumNumberInstances,maximumNumberInstances,repeating,...retValProps} = input.props;
    retVal.props = retValProps;
    return retVal;
  }

  toFormlyFieldConfig(): FormlyFieldConfig  {

    const column = (this.componentForm.get("columnFormGroups") as UntypedFormArray);
    const subForms: FormlyFieldConfig[]=[];
    for (let control of column.controls) {
      const subComponentsFormlyFieldConfigs : FormlyFieldConfig = {};
      subComponentsFormlyFieldConfigs.props = {};
      subComponentsFormlyFieldConfigs.fieldGroup = this.retrieveFormlyFieldConfigForContainedElements(control as UntypedFormGroup,false);
      subForms.push(subComponentsFormlyFieldConfigs);
    }

    const retVal = this.fields[0];
    retVal.fieldGroup = subForms;
    return retVal;
  }

  toggleWorkflowActivationStatus() {
    this.activeFormFirestore.formSummary.active = !this.activeFormFirestore.formSummary.active;
    this.formFirestoreSummaryService.update$(this.activeFormFirestore.formSummary).pipe(
      take(1)
    ).subscribe();
  }

  patchFormlyFieldConfigToSection(formlyFieldConfig: FormlyFieldConfig) {
    const aGrouping: ControlContainerComponent[] = [];
    if (formlyFieldConfig.type === "input") {
      return;
    }
    if (formlyFieldConfig.fieldArray) {
      formlyFieldConfig.fieldGroup = (formlyFieldConfig.fieldArray as FormlyFieldConfig).fieldGroup;
      formlyFieldConfig.fieldArray=undefined;
    }
    const toIterate = formlyFieldConfig.fieldGroup ? formlyFieldConfig.fieldGroup : [formlyFieldConfig];
    toIterate.forEach(field => {
      var c : ControlContainerComponent;
        if (field.type === "formlySplitter") {
          const val = new ColumnSplitterComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.formlyComponentUtilityService);
            val.columnFormGroup = this.buildColumnSplitterFormGroup();
            val.columnFormGroup.patchValue({borderColor: field.props["borderColor"], border: field.props["border"]  });
            c = val;
        } else {
          if (field.type === "formlySection") {
            const val = new SectionComponent(this.fb, this.componentFromFormlyFactory, this._ngZone, this.dialog,
              this.formlyUtilityService,this.formCatagoryService, this.formFirestoreService, this.formFirestoreSummaryService, this.localSettingsService,
              this.formlyComponentUtilityService);
            const subFormGroup = SectionComponent.buildSectionFormGroup();
            subFormGroup.patchValue({connectedDropLists: [this.columnFormGroup.get("connectedDropLists").value]});
            this.patchChangesToDropZonesAndItems(subFormGroup);
            val.columnFormGroup = subFormGroup;
            val.maintainDropZoneSettings();
            c = val;
          } else {
            c = this.componentFromFormlyFactory.generateComponent(field);
          }
        }
        c.patchInFormlyFieldConfig(field);
        c.parentContainer = this;
        aGrouping.push(c);
      });
      (this.columnFormGroups.controls[0] as UntypedFormGroup).patchValue({"cdkDropListData" :aGrouping.reverse()});
      this.formlyViewInitializing$.next(false);
  }

  patchInSectionConfigurationComponents(formlyConfig: FormlyFieldConfig) {
    this.componentForm.patchValue({itemName: formlyConfig.props["itemName"], minimumNumberInstances: formlyConfig.props["minimumNumberInstances"],
    maximumNumberInstances: formlyConfig.props["maximumNumberInstances"], repeating: formlyConfig.props["repeating"],
    title: formlyConfig.props["title"], formFirestoreDocId:  formlyConfig.props["formFirestoreDocId"],
    formFirestoreSummaryDocId: formlyConfig.props["formFirestoreSummaryDocId"], });
  }

  patchFormlyFieldConfigFromFormFirestore(formlyConfig: FormlyFieldConfig) : void {

    this.maintainDropZoneSettings();
    this.patchInSectionConfigurationComponents(formlyConfig);

    // For working representation ( with add / remove of section instances ) we need to use fieldArray.  But
    // for displaying updates properly in design view, we must use fieldGroup.
    if  (formlyConfig.fieldArray!==undefined) {
      formlyConfig.fieldGroup = (formlyConfig.fieldArray as FormlyFieldConfig).fieldGroup.filter(x => x.key !== "_id");
      formlyConfig.fieldArray=undefined;
    }

    //if there are no contained controls in section, we must set view initilizing to false as hook for after view initilized will never fire.
    if (formlyConfig.fieldGroup.length === 0) {
      this.formlyViewInitializing$.next(false);
    }
    formlyConfig.fieldGroup.reverse();
    this.patchFormlyFieldConfigToSection(formlyConfig);
  }

  patchInFormlyFieldConfig(formlyConfig: FormlyFieldConfig): void {

    const designLoad = of(null).pipe(
      filter(x => this.formlyUtilityService.designMode),
      tap(() => console.log(formlyConfig)),
      switchMap(() => this.formFirestoreSummaryService.load$(formlyConfig.props["formFirestoreSummaryDocId"])),
      switchMap(f => this.formFirestoreService.load$(f.currentDesignFirestoreDocId)),
      tap(x => this.unsavedChanges.next(false)),
      map(z => this.formlyUtilityService.getSection(JSON.parse(z.form),true,z.title,z.formSummaryDocId,z.DocId())),
      tap(x => {
        // Configuration of whether section is repeating or not and associated properties are not stored w/ section object proper.
        x.props.itemName=formlyConfig.props.itemName;
        x.props.minimumNumberInstances=formlyConfig.props.minimumNumberInstances;
        x.props.maximumNumberInstances=formlyConfig.props.maximumNumberInstances;
        x.props.repeating=formlyConfig.props.repeating;
        this.patchFormlyFieldConfigFromFormFirestore(x);
      }),
      tap(x => super.patchCommonFieldsToForm(x)),
      map(x => this.componentForm.get("formFirestoreSummaryDocId").value),
      switchMap(formFirestoreSummaryDocId => merge(
        // unpopulated firestoreSummaryDocId
        of(null).pipe(filter(() => formFirestoreSummaryDocId ===null)),
        // populated firestoreDocSummaryDocId
        of(null).pipe(filter(() => formFirestoreSummaryDocId !==null),
        switchMap(() => this.formFirestoreSummaryService.load$(this.componentForm.get("formFirestoreSummaryDocId").value).pipe(
          filter(x => x !== null),
          switchMap(x => this.formFirestoreService.load$(x.currentDesignFirestoreDocId)),
          filter(x => x?.formSummary !== null),
          tap(x => {
            this.activeFormFirestore = x;
            this.componentForm.patchValue({activeFormFirestore: x});
          })))))),
      take(1)
      ).subscribe();
  }

  private buildColumnFormGroup(): UntypedFormGroup {
    if (!this.dropZoneIdAssigned) {
      this.dropZoneIdAssigned = (Math.random().toString(36) + '00000000000000000').slice(2, 14)
    }
  return this.fb.group({
    id: this.dropZoneIdAssigned,
    cdkDropListConnectedTo: [[]],
    cdkDropListData: [[]],
  });
  }

  ngAfterViewInit(): void {
    this.patchControlComponentsToFormlyFields();
    super.patchValuesToContainedComponentsAsNeeded();
    if (this.extraFunctionEndViewInit !== undefined) {
      this.extraFunctionEndViewInit();
      this.extraFunctionEndViewInit = undefined;
    }
  }

  maintainDropZoneSettings(): void {
    if (this.dropZoneSettingsInitilized){
      return;
    }
    const columnFormGroup = (this.componentForm.get("columnFormGroups") as UntypedFormArray).at(0) as UntypedFormGroup;
    const id = columnFormGroup.get("id").value;
    this.componentForm.patchValue({addDropZoneId: id});


    // Add subscription to connected drop lists to newly built component.
    this.componentForm.controls["connectedDropLists"].valueChanges.pipe(
      startWith(this.componentForm.get("connectedDropLists").value),
      takeUntil(this.componentForm.controls["removeDropZoneId"].valueChanges.pipe(
        filter(x => x === id))),
        map(q => (q as string[])),
        tap(connectedGroups => columnFormGroup.patchValue({cdkDropListConnectedTo: connectedGroups.filter(x => x !== id)})),
        delay(1),
        takeUntil(this.destroyingComponent$)
    ).subscribe();

    this.dropZoneSettingsInitilized = true;
  }

  detectUnsavedChanges() {

    const updatedSubComponent = interval(1000).pipe(
      filter(() => this.unsavedChanges.value === false),
      filter(() => this.formlyUtilityService.sidebarDebounceActive$.value === false),
      map(() => {
        const f = this.columnFormGroups.controls[0].get('cdkDropListData').value.filter(x => x.form.get("controlName").value !== "Section"
        && x.serializeToDatabase).map(x => x.toFormlyFieldConfigJsonOnly());
        f.forEach(form => this.formlyUtilityService.stripKeysAllTheWayDown(form));
        return JSON.stringify(f);
      }),
      pairwise(),
      filter(x => x[0] !== x[1]),
      );

      const addedSection = interval(1000).pipe(
        filter(() => this.unsavedChanges.value === false),
        map(() =>  {
          return this.columnFormGroups.controls[0].get('cdkDropListData').value.filter(x => x.form.get("controlName").value === "Section"
            && x.form.get("serializeToDatabase").value === true &&
            x.form.value?.controlComponentFormGroup?.value?.formFirestoreSummaryDocId !== null).length
        }),
        pairwise(),
        filter(x => x[0] !== x[1]),
        tap(x => {
          console.log(x,` SECTION SUB SECTION COUNT CHANGE`);
          }),
        );

      interval(1000).pipe(
        filter(() => this.unsavedChanges.value === false),
        map(() => {
          const repeating = this.componentForm.get("repeating").value === undefined ? false : this.componentForm.get("repeating").value;
          const itemName = this.componentForm.get("itemName").value;
          const minimumNumberInstances = this.componentForm.get("minimumNumberInstances").value;
          const maximumNumberInstances = this.componentForm.get("maximumNumberInstances").value;
          const audience = this.form.get("audience").value;
          return JSON.stringify({repeating: repeating, itemName: itemName, minimumNumberInstances: minimumNumberInstances, maximumNumberInstances: maximumNumberInstances, audience: audience});
        }),
        pairwise(),
        filter(x => x[0] !== x[1]),
        tap(() => this.updatedSectionProperties$.next(true)),
        takeUntil(this.destroyingComponent$)
        ).subscribe();

        merge(updatedSubComponent, addedSection,...(this.columnFormGroups.controls[0] as any).controls.cdkDropListData.value
          .filter(x => (x as any).component.name === "SectionComponent")
        .map(y => (y as any).updatedSectionProperties$.pipe(debounceTime(500)))).pipe(
        tap(() => this.unsavedChanges.next(true)),
        takeUntil(merge(this.destroyingComponent$, this.formlyViewInitializing$.pipe(filter(x => x === true)))),
      ).subscribe();

  }

  ngOnInit(): void {

    this.unsavedChanges.pipe(
      filter(x => x === true),
      filter(() => this.automaticallySaveForm),
      // Initial save must be manual, even in auto save mode as there is no name or catagory assigned.
      filter(() => this.activeFormFirestore !== undefined && this.activeFormFirestore !== null),
      tap(() => this.autoSaveForm()),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    this.formlyViewInitializing$.pipe(
      filter(x => x === false),
      tap(() => this.detectUnsavedChanges()),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    this.componentForm.get("repeating").valueChanges.pipe(
      startWith(this.componentForm.get("repeating").value),
      tap(x => {
        if (x) {
          this.componentForm.get("minimumNumberInstances").enable();
          this.componentForm.get("maximumNumberInstances").enable();
          this.componentForm.get("itemName").enable();
        } else {
          this.componentForm.get("minimumNumberInstances").disable();
          this.componentForm.get("maximumNumberInstances").disable();
          this.componentForm.get("itemName").disable();
        }
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    if (!this.dropZoneSettingsInitilized) {
      this.maintainDropZoneSettings();
    }
  }

}
