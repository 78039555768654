import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component,  Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup} from '@angular/forms';
import { BehaviorSubject, combineLatest,  Observable,  of, ReplaySubject, Subject } from 'rxjs';
import {  delay, map, switchMap, take, tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { LineItem } from '../../../../../../common/src/data/dao/line-item';
import { LineItemCreationService, LINE_ITEM_CREATION_MODE } from '../line-item-creation.service';
import { LineItemService } from '../../../../../../common/src/data/dao-services/line-item.service';
import { where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class LineItemAutocompleteService {

  topLevelLineItems: BehaviorSubject<LineItem[]> = new BehaviorSubject<LineItem[]>([]);

  constructor(private lineItemService: LineItemService) {
    const newLineItem = new LineItem({title: "Add New"});
    combineLatest([of(newLineItem),this.lineItemService.queryFirestoreDeep$([where("lineItemPrototypeDocId", "==", null)])]).pipe(
      map(([a,b]) => {
        const arrayCopy = [...b];
          arrayCopy.unshift(a);
          return arrayCopy;
      }),
    ).subscribe(this.topLevelLineItems);
  }

  formValueAttribute?: any;

  getResults(term: any) {
    return this.topLevelLineItems.pipe(
      map(result => {
        return result.filter(item => {
          return item.title.toLowerCase().startsWith(term.toLowerCase());
        })
        })
      )
  }

  getItemLabel?(item: any) {
    return item.title;
  }

  labelAttribute = 'title';
}

@Component({
  selector: 'app-line-item-creation-ionic',
  templateUrl: './line-item-creation-ionic.component.html',
  styleUrls: ['./line-item-creation-ionic.component.scss']
})
export class LineItemCreationIonicComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() lineItem: LineItem;

  lineItemCreationMode: LINE_ITEM_CREATION_MODE.INSTANTIATE;
  form: UntypedFormGroup;
  box_price = 0;
  destroyCompoenent$: Subject<any> = new Subject();
  lineItemInitiallyPopulated = false;
  searchTerm$: ReplaySubject<string> = new ReplaySubject<string>(1);
  displaySearchResults = false;


  constructor(public lineItemAutocompleteService: LineItemAutocompleteService, private currencyPipe: CurrencyPipe, public modalController: ModalController,
    private lineItemCreationService: LineItemCreationService) { }

  ngAfterViewInit(): void {
      this.searchTerm$.next("");
  }

  ngOnDestroy(): void {
    this.destroyCompoenent$.next(null);
    this.destroyCompoenent$.complete();
  }

  searchBarFocus(event:any) {
    this.displaySearchResults = true;
  }

  searchBarBlur(event:any) {
    of(null).pipe(
      delay(150),
      take(1)
    ).subscribe(() => this.displaySearchResults = false);
  }

  searchItemChangeEvent(event: any) {
    this.searchTerm$.next(event.target.value);
  }

  filteredLineItems() : Observable<LineItem[]> {
    return this.searchTerm$.pipe(
      switchMap(search => this.lineItemAutocompleteService.getResults(search)),
    );
  }

  selectItem(a:LineItem) {
    this.lineItem = a;
    this.patchLineItemToFormGroup(a);
  }

  patchLineItemToFormGroup(lineItem: LineItem) : void {
      this.lineItemCreationService.patchLineItemToFormGroup(lineItem, this.form);
      this.box_price = lineItem.pricePerItem;
  }

  ngOnInit(): void {
    this.form= this.lineItemCreationService.buildLineItemFormGroup(this.destroyCompoenent$);

    if (this.lineItem !== null) {
      this.lineItemInitiallyPopulated=true;
      this.patchLineItemToFormGroup(this.lineItem);
    }
  }

  save(): void {
      if (this.form.valid) {
        if (this.lineItem === null) {
          this.lineItem = new LineItem();
        }
        if (this.lineItem.lineItemPrototypeDocId === null) {
          this.lineItem.lineItemPrototypeDocId = undefined;
        }
        this.lineItemCreationService.newLineItemFromFormAndPrototypeLineItem(this.form, this.lineItem).pipe(
          tap(lineItem => {
            this.modalController.dismiss({
              'dismissed': false,
              'lineItem' : lineItem,
            });
          }),
          take(1)
        ).subscribe();
      } else {
        this.form.markAllAsTouched();
      }
  }

  exit() : void {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

onChangePrice(genericEventTarget:EventTarget) {
    this.processChangePrice(genericEventTarget);
}

onPriceUp(genericEventTarget:EventTarget){
  console.error("ON PRICE UP");
  // this.processChangePrice(genericEventTarget);
}

processChangePrice(genericEventTarget:EventTarget){
  const evt = (genericEventTarget as HTMLInputElement).value;
  if (evt.endsWith(".") && evt.split(".").length === 2) {
    return;
  }
  this.box_price = parseFloat(evt.replace(/[^0-9.]/g, ""));
  if (Number.isNaN(this.box_price)) {
    this.box_price = 0.00;
  }
  this.box_price = Math.round((this.box_price + Number.EPSILON) * 100) / 100

  this.form.patchValue({price: this.box_price, box_price_formatted: this.getCurrency(this.box_price),
    totalPrice :this.getCurrency(this.box_price*this.form.get("quantity").value)});
}

getCurrency(amount: number) {
  return this.currencyPipe.transform(amount, 'USD', "symbol", '1.2-2');
}

}
