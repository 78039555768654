import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditPrototypeModalComponent } from './add-edit-prototype-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [AddEditPrototypeModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ]
})
export class AddEditPrototypeModalModule { }
