import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
selector: 'formly-wrapper-manual-size',
template: `
   <div style="display:none">{{to.changeDetect|async}}</div>
   <div class= "full-height-formly">
     <ng-container #fieldComponent></ng-container>
  </div>
`,
styles : [
  `
    :host ::ng-deep textarea {
      padding: 0px;
    }`,
  `
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    :host ::ng-deep .mat-form-field-infix {
      width: auto;
    }`,
  `
    :host ::ng-deep .full-height-formly * {
      height: 100%;
      resize: none;
    }`
],
changeDetection: ChangeDetectionStrategy.OnPush
})

// changeDetection needs triggered here as well via | async, b/c otherwise
// updating won't reflect.
export class ManualSizeWrapperComponent extends FieldWrapper {
}
