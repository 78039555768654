import {  ChangeDetectionStrategy, Component } from '@angular/core';
import {  FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-column-splitter',
  styleUrls: ['./formly-column-splitter.component.scss'],
  template: `
  <div>
    <div style="display:none">{{to.changeDetect|async}}</div>
  <!-- <div style="width: 750px; height:100%;" class={{to.className}}> -->
    <!-- <h5>{{key}}</h5> -->
    <div  style="height:100%;" class={{to.className}}>
      <as-split [disabled]="true" [gutterSize]="2"> <!-- Without width 99% some controls on the right have their border cut off. This is not the best solution but it works for now-->
        <as-split-area [order]="i" *ngFor="let field of field.fieldGroup; index as i" [size]="field.props.size" style="padding: 3px; box-sizing: border-box;">
            <formly-field class="row" [field]="field"></formly-field>
        </as-split-area>
      </as-split>
    </div>
  </div>
  `,
  // To convert to OnPush need to make field.fieldGroup immutable, wheras at moment we update it willy-nilly.
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormlyColumnSplitterComponent extends FieldType {
}
