import { Injectable } from '@angular/core';
import {EmployeePermission, EmployeePermissionDateRange} from '../../../common/src/data/dao/employee-permission';
import { distinctUntilChanged, map, takeUntil, tap,take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { EmployeePermissionBuilder } from './employee-permission-builder';
import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../../common/src/util/AppAbility';
import { EmployeePermissionService } from '../../../common/src/data/dao-services/employee-permission.service';
import { FirestoreBackend } from '../../../common/src/data/database-backend/retrieve-from-firestore';
import { where } from 'firebase/firestore';


export class EmployeePermissionTreeStructure {
  name: string;
  key: string;
  parentCatagoryKey: string;
  employeePermission: EmployeePermission;
  description: string;
  displayType: string = undefined;
  form: UntypedFormGroup = undefined;

  constructor(init?: Partial<EmployeePermissionTreeStructure>) {
    Object.assign(this, init);
    // Some of structure is derived from employee role object if provided.
    if (this.employeePermission !== undefined) {
      this.name = this.employeePermission.name;
      this.key = this.employeePermission.DocId();
      this.parentCatagoryKey = this.employeePermission.parentDocId;
      this.description = this.employeePermission.description;
      this.displayType = this.employeePermission.displayType;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmployeePermissionTreeService {

  topLevelTreeStructureNodes: EmployeePermissionTreeStructure[];
  allNodes: BehaviorSubject<EmployeePermissionTreeStructure[]> = new BehaviorSubject<EmployeePermissionTreeStructure[]>([]);
  selectedNodes: BehaviorSubject<EmployeePermissionTreeStructure[]> = new BehaviorSubject<EmployeePermissionTreeStructure[]>([]);
  abilityBuilder = new AbilityBuilder(AppAbility);

  numberDaysPermissionForm() : UntypedFormGroup
  {
    return this.fb.group({
      dateSelectionType: ["null"],
      numberDays: [1,[Validators.min(0)]],
    });
  }

  updateSelectedNodes(selectedPermissions: EmployeePermission[]) {
    this.selectedNodes.next(this.allNodes.value.filter(x => selectedPermissions.some(z => z.DocId() === x.key)));
  }

  resetAllForms() {
    this.allNodes.value.forEach(node => {
      if (node.form !== undefined ) {
        node.form.reset();
        this.patchPermissionToForm(node.employeePermission, node.form);
      }
    })
  }

  patchPermissionToForm(e: EmployeePermission, form: UntypedFormGroup) {
    const asEmployeePermissionDateRange = e as EmployeePermissionDateRange;
        form.patchValue({numberDays: asEmployeePermissionDateRange.numberDaysForward});
        if (asEmployeePermissionDateRange.numberDaysForward === 365) {
          form.patchValue({dateSelectionType: "null"});
        } else {
          if (asEmployeePermissionDateRange.numberDaysForward > 0) {
            form.patchValue({dateSelectionType: "Custom"});
          } else {
            form.patchValue({dateSelectionType: "0"});
          }
        }
    }

  constructor(private employeePermissionService: EmployeePermissionService, private fb: UntypedFormBuilder, private employeePermissionBuilder: EmployeePermissionBuilder) {
    this.populateTopLevelNodes();
    this.employeePermissionService.queryFirestoreDeep$([where('active','==',true),
      where('rootPermission','==',true)]).pipe(
      distinctUntilChanged(((x,y) => x.length === y.length)),
      map(x => this.topLevelTreeStructureNodes.concat((x as EmployeePermission[]).map(z => {
        const retVal = new EmployeePermissionTreeStructure({employeePermission: z});
        if (retVal.displayType === "days") {
          retVal.form = this.numberDaysPermissionForm();
          this.patchPermissionToForm(retVal.employeePermission, retVal.form);
        }
        return retVal;
      }))),
      takeUntil(FirestoreBackend.destroyingComponent$),
      ).subscribe(this.allNodes);
  }

  populateScheduleSubNodes() : (EmployeePermission | EmployeePermissionDateRange) [] {
    return [
      new EmployeePermission({name: "View Next Site Visit Only.", description: "", parentDocId: "scheduleSelf"}),
      new EmployeePermission({name: "View schedule", description: "", displayType: "days", parentDocId: "scheduleSelf"}),

      new  EmployeePermissionDateRange({name: "View All schedules", description: "", displayType: "days", parentDocId: "scheduleAll", numberDaysBack : 3, numberDaysForward: 3, action: "read", subject: "SiteVisit"}),
      new EmployeePermission({name: "Modify All Schedules", description: "", parentDocId: "scheduleAll"}),
  ]

  }

    populateEmployeeSubNodes() : EmployeePermission[] {
      return [
      new EmployeePermission({name: "Edit Permissions", description: "", parentDocId: "employee"}),
      new EmployeePermission({name: "Set Regular Schedule", description: "", parentDocId: "employee"}),
      new EmployeePermission({name: "Approve Time Off", description: "", parentDocId: "employee"}),
    ];
    }

    populateAdminSubNodes() : EmployeePermission[] {
      return [
      new EmployeePermission({name: "Update Application Settings", description: "", parentDocId: "admin"}),
      ];
    }

    populatePricingSubNodes() : EmployeePermission[] {
      return [
      new EmployeePermission({name: "View Prices", description: "", parentDocId: "pricepay"}),
      new EmployeePermission({name: "Set Custom Prices", description: "", parentDocId: "pricepay"}),
      new EmployeePermission({name: "Modify Pricebook Prices", description: "", parentDocId: "pricepay"}),
    ];
    }

    populateJobSubNodes() : EmployeePermission[] {
      return [

      new EmployeePermission({name: "Add Work Line Items", description: "", parentDocId: "job"}),
      new EmployeePermission({name: "Add Estimate Line Items", description: "", parentDocId: "job"}),
      ];
    }

    populateCustomerSubNodes() : EmployeePermission[] {
      return [
      new EmployeePermission({name: "View Customer Contact Information", description: "", parentDocId: "customer"}),
    ];
    }

    populateWorkflowSubNodes() : EmployeePermission[] {
      return [
      new EmployeePermission({name: "Modify workflows", description: "", parentDocId: "workflow"}),
    ];
    }

    populateReportSubNodes()  : EmployeePermission[]{
      return [
        new EmployeePermission({name: "View All Reports", description: "", parentDocId: "report"}),
        new EmployeePermission({name: "View Some Reports", description: "", parentDocId: "report"}),
        new EmployeePermission({name: "Edit and Schedule Reports", description: "", parentDocId: "report"}),
    ];
    }


    populateSubNodes() : EmployeePermission[] {
      const retVal: EmployeePermission[] = [];
      retVal.push(...this.populateScheduleSubNodes());
      retVal.push(...this.populateEmployeeSubNodes());
      retVal.push(...this.populatePricingSubNodes());
      retVal.push(...this.populateJobSubNodes());
      retVal.push(...this.populateCustomerSubNodes());
      retVal.push(...this.populateWorkflowSubNodes());
      retVal.push(...this.populateReportSubNodes());
      retVal.push(...this.populateAdminSubNodes());
      return retVal;
    }

  populateTopLevelNodes() {

new EmployeePermissionTreeStructure({name: "", key : "", parentCatagoryKey : undefined, description: "" });

    const schedule = new EmployeePermissionTreeStructure({name: "Schedule", key : "schedule", parentCatagoryKey : undefined, description: "Permissions dealing with ability to view and modify scheduled site visits." });
    const scheduleSelf = new EmployeePermissionTreeStructure({name: "Technician", key : "scheduleSelf", parentCatagoryKey : "schedule", description: "Ability to view and modify own schedule and site visits." });
    const scheduleOthers = new EmployeePermissionTreeStructure({name: "All Technicians", key : "scheduleAll", parentCatagoryKey : "schedule", description: "Ability to view and modify others schedule and site visits." });

    const employee = new EmployeePermissionTreeStructure({name: "Employee", key : "employee", parentCatagoryKey : undefined, description: "Add, and modify employees, employee schedules, and employee permissions." });

    const pricingPayments = new EmployeePermissionTreeStructure({name: "Pricing and Payments", key : "pricepay", parentCatagoryKey : undefined, description: "Viewing and modifying prices, and ability to take payments." });
    const job = new EmployeePermissionTreeStructure({name: "Job", key : "job", parentCatagoryKey : undefined, description: "Ability to modify and add jobs." });
    const customer = new EmployeePermissionTreeStructure({name: "Customer", key : "customer", parentCatagoryKey : undefined, description: "Ability to directly contact, and see details for job's customers." });

    const workflow = new EmployeePermissionTreeStructure({name: "Workflow", key : "workflow", parentCatagoryKey : undefined, description: "Add, modify and deactivate technician workflows." });
    const reports = new EmployeePermissionTreeStructure({name: "Reports", key : "report", parentCatagoryKey : undefined, description: "Create, run, and schedule reporting." });
    const admin = new EmployeePermissionTreeStructure({name: "Administrator", key : "admin", parentCatagoryKey : undefined, description: "Administrative functions" });
    this.topLevelTreeStructureNodes = [schedule, scheduleSelf, scheduleOthers, job, workflow, employee, reports,customer,pricingPayments,admin];
  }
}
