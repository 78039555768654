import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export enum JOB_ATTENTION_REQUIRED {
  FIELD_TECH_RESCHEDULED = "Field Tech Rescheduled",
  WAITING_FOR_PART = "Waiting For Part",
  SCHEDULING_NOTE = "Scheduling Note",
}

export class JobAttentionRequired extends RetrieveFirestorePropertiesBase<JobAttentionRequired>  {

jobAttentionRequiredReason: JOB_ATTENTION_REQUIRED;
jobAttentionRequiredSubReason: string = "";
notes: string = "";
originatingLineItemDocId: string | null = null;
siteVisitDocId: string | null = null;
active : boolean = true;

// temporal
additionalTimeNeedingScheduled: Date = null;

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["additionalTimeNeedingScheduled"]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["additionalTimeNeedingScheduled"]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];


retrieveFirestoreIgnoredMemberNames() : string[] { return JobAttentionRequired._firestoreIgnoredMemberNames;}


retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return JobAttentionRequired._firestoreIgnoreDiffTrackingMembers; }


retrievefirestoreCompositionalDiffMemberNames() : string[] {return JobAttentionRequired._firestoreCompositionalDiffMemberNames;}


retrievefirestoreCompositionMemberNames() : string[] { return JobAttentionRequired._firestoreCompositionMemberNames;}



public constructor(init?: Partial<JobAttentionRequired>) {
super();
Object.assign(this, init);
}
}
