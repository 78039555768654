<mat-expansion-panel [expanded]="true" style="margin-top: 10px;">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="load-form-modal-title header-style" style="margin-bottom: 5px; margin-top: 13px; font-weight: bold;">Reschedule Details</div>
    </mat-panel-title>
  </mat-expansion-panel-header>
<div class="mat-table-container">
  <table mat-table [dataSource]="alerts" style="width: 100%;">

    <!-- Reason Job attention required. -->
    <ng-container matColumnDef="jobAttentionRequiredReason">
      <th mat-header-cell *matHeaderCellDef>Reason</th>
      <td mat-cell *matCellDef="let j">
        {{j.jobAttentionRequiredReason}}
        </td>
    </ng-container>

    <!-- Sub Reason Job Attention Required. -->
    <ng-container matColumnDef="jobAttentionRequiredSubReason">
      <th mat-header-cell *matHeaderCellDef>Details</th>
      <td mat-cell *matCellDef="let j">
        {{j.jobAttentionRequiredSubReason}}
        </td>
    </ng-container>

    <!-- Notes: -->
    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef>Notes</th>
      <td mat-cell *matCellDef="let j">
        {{j.notes}}
        </td>
    </ng-container>

    <!-- Employee Generated-->
<ng-container matColumnDef="employee">
  <th mat-header-cell *matHeaderCellDef>Employee</th>
  <td mat-cell *matCellDef="let j">{{(employeeService.get(j.lastUpdatedByEmployeeDocId))?.name}} </td>
</ng-container>


  <!-- Estimate created on date-->
  <ng-container matColumnDef="dateCreated">
    <th mat-header-cell *matHeaderCellDef>Created On</th>
    <td mat-cell *matCellDef="let j">{{j.createdAt | date:'short'}} </td>
  </ng-container>


    <tr mat-header-row *matHeaderRowDef="cols;"></tr>
    <tr mat-row *matRowDef="let j; columns: cols;"></tr>
  </table>
</div>
</mat-expansion-panel>
