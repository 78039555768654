import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";

export class EmployeeLocation extends RetrieveFirestorePropertiesBase<EmployeeLocation>  {

  employeeDocId = "";
  latitude = 0;
  longitude = 0;
  bucketId = "";
  createdAt: Date = new Date();
  lastUpdatedAt: Date = new Date();
  lastRecordedAt: Date = new Date();

static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat([]);
static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat([]);
static _firestoreCompositionMemberNames = [];
static _firestoreCompositionalDiffMemberNames: string[] = [];

retrieveFirestoreIgnoredMemberNames() : string[] { return EmployeeLocation._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return EmployeeLocation._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return EmployeeLocation._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return EmployeeLocation._firestoreCompositionMemberNames;}

public constructor(init?: Partial<EmployeeLocation>) {
super();
Object.assign(this, init);
}
}
