import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { FormCatagory } from "./form-catagory";

export enum FORM_TYPE {
  LINE_ITEM_COMPLETION = "Line Item Completion",
  LINE_ITEM_GENERATION = "Line Item Generation",
  TOP_LEVEL = "topLevel",
  SECTION = "section",
}

export class FormFirestoreSummary extends RetrieveFirestorePropertiesBase<FormFirestoreSummary>  {

  title: string;
  formType: FORM_TYPE;
  active: boolean = true;

  formCatagory: FormCatagory;
  formCatagoryDocId: string;
  // This is needed because we assign jobs form firestore instances, but also need to be able to update them.  So we maintain a reference here
  // to the most current version, so that new jobs properly utilize it, but jobs created w/ older versions still function properly.
  currentDeployedFirestoreDocId: string;
  currentDesignFirestoreDocId: string;

  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["formCatagory"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["title","formCatagoryDocId"]);
  static _firestoreCompositionMemberNames = ["formCatagory"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["formCatagory"];

  retrieveFirestoreIgnoredMemberNames() : string[] { return FormFirestoreSummary._firestoreIgnoredMemberNames};
  retrievefirestoreIgnoreDiffTrackingMembers() : string[] {return FormFirestoreSummary._firestoreIgnoreDiffTrackingMembers;}
  retrievefirestoreCompositionMemberNames() { return FormFirestoreSummary._firestoreCompositionMemberNames; }
  retrievefirestoreCompositionalDiffMemberNames() { return FormFirestoreSummary._firestoreCompositionalDiffMemberNames; }
  retrieveFirestoreDenormalizedMemberNames(): string[] {return FormFirestoreSummary._firestoreCompositionMemberNames; }

  public constructor(init?: Partial<FormFirestoreSummary>) {
    super();
    Object.assign(this, init);
  }

}
