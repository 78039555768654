import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineItemCreationIonicComponent } from './line-item-creation-ionic.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';



@NgModule({
  declarations: [LineItemCreationIonicComponent],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    ScrollingModule
  ]
})
export class LineItemCreationIonicModule { }
