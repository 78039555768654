<div [formGroup]="form">
  <div class="header-style">Service Address</div>
  <div class="section-content">
    <div style="grid-column: 3/4; grid-row: 2/3;">
      <ng-container *ngIf="addressToShopRouting && addressToShopRouting != null" class="distance-to-job">
        <b>Distance:</b> {{addressToShopRouting.distanceEstimateMiles}}  -  {{addressToShopRouting.timeEstimateMinutes}}
      </ng-container>
    </div>
    <div class="label-style">Address Search</div>
    <!-- Start of address form field -->
    <div fxLayout="row" style="grid-column: 3/5; align-items: center;"><!-- Start of Form field. Also this line controls size of form field, removing this div will make field expand entire width of container-->
      <div autocomplete="off" fxLayout="row" style="align-items: center;">
          <mat-form-field appearance="outline" style="width: 330px; font-size: 16px;">
            <textarea [name]="randomElementName" cdkTextareaAutosize autofill="off" autocomplete="new-password" matInput placeholder="Search Address" [matAutocomplete]="nameAuto"
            data-lpignore="true" (keydown)="excludeEnter($event)" (keyup)="addressSearchKeyUp($event)" #inputBox formControlName="serviceAddressSearch"></textarea>
            <mat-error>Select service address</mat-error>
            <mat-autocomplete class="double-search-bar-height" panelWidth=600px #nameAuto="matAutocomplete" (optionSelected)="optionSelected($event)">
              <div *ngIf="displayCustomerResultsHeader(); else newAddressResultsBlock"  class="customer-search-header">
                <div class="search-header">Customer Results</div>
              </div>
              <ng-template #newAddressResultsBlock>
                <div class="address-search-header">
                  <span class="search-header">New Address Results</span>
                </div>
              </ng-template>
              <!-- What is displayed for mat-option varies depending on whether search result is an existing customer.  -->
              <mat-option class="new-address-results-width" *ngFor ="let place of addressCustomersFound" [value]="place" [disabled]="place.address==null">
              <!-- Think about whether we want to have icon to represent phone as search origin..... -->
              <div class="padding-correction" *ngIf="place.customer; else elseBlock">
                <i style="color: #206f93; margin-right: 6px;" class="material-icons">person_pin</i>{{place.customer.customerNameCompanyDisplay}} - {{place.address.formattedAddress()}}
              </div>
              <ng-template #elseBlock>
                <div class="address-search-header" *ngIf="place.address == null else googleResultBlock">
                  <span class="search-header">New Address Results</span>
                </div>
              </ng-template>
              <ng-template #googleResultBlock>
                <div class="padding-correction">
                  <ng-container *ngIf="place.resultSource==='INTERNALSERVICEADDRESS'" >
                    <i class="material-icons" style="color: #206f93; margin-right: 6px;" class="material-icons">other_houses</i> {{place.address.formattedAddress()}}
                  </ng-container>
                  <ng-container *ngIf="place.resultSource!=='INTERNALSERVICEADDRESS'" >
                    <i class="material-icons" style="color: #b01f1f; margin-right: 6px;" class="material-icons">other_houses</i> {{place.address.formattedAddress()}}
                  </ng-container>
                </div>
              </ng-template>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 75px; margin-left: 20px; align-self: center;">
          <input type = "text" matInput placeholder="Unit" autocomplete="new-password" autofill="off" formControlName="unit" [name]="form.get('noAutoFill').value">
        </mat-form-field>
      </div>
      <div *ngIf="activeSearch" fxLayout="row" fxLayoutAlign=" center"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div *ngIf="displayNoResultsFound" fxLayout="row" fxLayoutAlign=" center" style="font-size: 16px; color: red; margin-left: 5px;">No Results Found<i class="material-icons">error</i></div>

      <div style="grid-column: 1/2; grid-row: 4/5; align-self: center;">
        <button
          (click) = "ViewPropertyHistoryClick()"
          disabled={{disablePropertyHistory}}
          mat-button class="button-light-blue" style="margin-left: 20px;"
          matBadge={{previousJobCountAtAddress}} matBadgePosition="after"
          matBadgeColor="primary">Property History</button>
      </div>
    </div> <!--end of address form field-->
  </div>
</div>
