import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { concat,  merge, Observable, of, Subject, throwError } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap, pairwise, delay, startWith, catchError, share, distinctUntilChanged, concatMap } from 'rxjs/operators';
import { FormFirestoreService } from '../../../../../../../common/src/data/dao-services/form-firestore.service';
import { FormlyUtilityService } from '../formly-utility.service';
import { FetchUpdatedWorkflowService } from '../utilities/fetch-updated-workflow.service';

@Component({
  selector: 'app-formly-branching-container',
  template: `
  <!-- <button (click)="test()">Test</button> -->
  <div style="display:none">{{forceRender|async}}</div>
    <h5>{{to.label}}</h5>
    <div style="margin-bottom: 10px;">
    <formly-field class="row" [field]="chooser"></formly-field>
    </div>
  `,
  styleUrls: ['./formly-branching-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyBranchingContainerComponent extends FieldArrayType implements OnDestroy, OnInit {

  destroyingComponent$ = new Subject();
  firestoreSummaryDocIdPatchedToChooser: string = undefined;

  forceRender: Subject<any> = new Subject();
  checkBoxRemovalInitiatedAtSection$: Subject<{toRemove : string[]}> = new Subject();

  get keyString() : string {
    return this.key as string;
  }

  ngOnDestroy(): void {
  this.destroyingComponent$.next(null);
  this.destroyingComponent$.complete();
  }

  constructor(private formlyUtilityService:  FormlyUtilityService, private formFirestoreService: FormFirestoreService,
    private fetchUpdatedWorkflowService: FetchUpdatedWorkflowService) {
    super();
  }

  // test() {
  //   this.chooser.props.options= (this.chooser.props.options as Array<any>).slice();
  //   console.log(this.chooser);
  //   console.log(this.form);
  //   console.log(this.model);
  //   console.log(this.field.fieldGroup);
  //   console.log(this.formControl.value);
  //   console.log(this.formlyUtilityService.activeFormModelFirestore);
  // }

  get chooser() {
    if (this.field.fieldGroup[0].fieldGroup[0] === undefined) {
      return null;
    } else {
      return this.field.fieldGroup[0].fieldGroup[0];
    }
  }

  get parentId() : string {
    //top level is needed so we match branch key when branch is not within a section otherwise.
    if( this.field.parent) {
      if (this.field.parent.model._id === undefined || this.field.parent.model._id === null) {
        return "top_level";
      } else {
        return this.field.parent.model._id;
      }
    }  else {
      return "top_level";
    }
  }


  initilizeSectionRemovedFromTechView() {

    const sectionRemovedFromSectionsBranch = this.formlyUtilityService.sectionRemovedFromTechView$.pipe(
      filter(x => x.branchingKey === this.keyString && x.parentId === this.field.parent.model._id)
    );

    const sectionRemovedMultiCheckbox = sectionRemovedFromSectionsBranch.pipe(
      filter(() => this.chooser.type === "multicheckbox"),
      map(x => this.fetchUpdatedWorkflowService.getActiveFormSummaryDocIdFromFormFirestoreDocId(x.workflowDocId, this.formlyUtilityService.activeFormModelFirestore.DocId())),
      tap(x => {
          const checkedValues = {...this.chooser.formControl.value};
          checkedValues[x] = false;
          this.chooser.formControl.patchValue(checkedValues);
          // Required to update the checked state for formly multi-checkbox control.
          this.chooser.props.options= (this.chooser.props.options as Array<any>).slice();
      })
      );

    const sectionRemovedRadioDropDown = sectionRemovedFromSectionsBranch.pipe(
      filter(() => this.chooser.type !== "multicheckbox"),
      tap(() => {
        this.formlyUtilityService.removeSectionFromBranch$.next({ sectionDocId: this.firestoreSummaryDocIdPatchedToChooser, branchKey: this.keyString, parentId: this.parentId});
        this.firestoreSummaryDocIdPatchedToChooser = undefined;
        this.chooser.formControl.patchValue(undefined);
        this.chooser.props.value = undefined;
      }),
    );

    merge(sectionRemovedMultiCheckbox, sectionRemovedRadioDropDown).pipe(
      takeUntil(this.destroyingComponent$),
    ).subscribe();
  }

  ngOnInit(): void {

    console.error("NGONIT FOR FORMLY BRANCH");

    this.initilizeSectionRemovedFromTechView();

      const checkBoxValueChangesRaw = merge(of(null),
        this.chooser.formControl.valueChanges.pipe(
          startWith(this.chooser.formControl.value),
          filter(x=> x!==undefined && x!==null),
          // delay is to ensure of (null) hits first on populated values for pairwise to work properly.
          delay(1),
        )
      ).pipe(
        filter(() => this.chooser.type === "multicheckbox"),
        pairwise(),
        // after below transformation, emitted value is docId : true || false || not present (also false)
        map(x => {
          return {old: x[0], new: x[1]};
        }),
        distinctUntilChanged((prev,curr) => {
          return JSON.stringify(prev) === JSON.stringify(curr) || JSON.stringify(prev.new) === JSON.stringify(curr.new);
        }),
        // Build map of values to remove and add.
        map(x => {
          const toAdd = [];
          const toRemove = [];
          for (const docId in x.new) {
            if (x.new[docId] === true && (x.old === null ||  !x.old[docId] )) {
              toAdd.push(docId);
            }
            if(x.new[docId] === false && (x.old && x.old[docId] )) {
              toRemove.push(docId);
            }
          }
          return {toAdd: toAdd, toRemove: toRemove};
        }),
        share()
      );

      const checkBoxAdded = checkBoxValueChangesRaw.pipe(
        map(x => x.toAdd.filter(q => q !== this.firestoreSummaryDocIdPatchedToChooser)),
        filter(x => x.length > 0),
        tap(() => this.firestoreSummaryDocIdPatchedToChooser === ""),
        // We must sort sections to add, or field tech has bad experience on re-loading of workflow.
        map(x => this.formlyUtilityService.sortAdditionsToCheckboxControl(x, (this.chooser.props.options as Array<any>).map(x => x.value))),
        map(x => {
          const AddedSections : Observable<{sectionKey: string, newFormFirestoreDocId: string, oldFirestoreDocId: string}>[] = [];
          x.forEach(docId => {
            AddedSections.push(
              this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(docId, this.formlyUtilityService.activeFormModelFirestore.DocId()).pipe(
                take(1),
                switchMap(x => this.formFirestoreService.load$(x)),
                take(1),
                //Switch below to loading docId (an error) and the branching container will properly load the updated section......
                concatMap(formFirestore => this.formlyUtilityService.addWorkflowFromBranchingUpdateIfNeededReturnSectionKey(formFirestore.DocId(),(this.keyString as string),this.parentId,
                  formFirestore, this.formlyUtilityService.activeFormModelFirestore.DocId()).pipe(
              tap(x => {
              this.formlyUtilityService.addLeafKeyToBranchKeyWithParent$.next({branchKey: this.keyString, leafKey: x.sectionKey,
                docId: x.newFormFirestoreDocId === null ? x.oldFirestoreDocId : x.newFormFirestoreDocId, parentId: this.parentId});
              })))));
        });
        return AddedSections;
      }),
        switchMap(x => concat(...x))
      );

      const checkBoxRemoved = merge(checkBoxValueChangesRaw, this.checkBoxRemovalInitiatedAtSection$).pipe(
        map(x => x.toRemove.filter(b => b!==undefined)),
        filter(x => x.length > 0),
        tap(() => this.firestoreSummaryDocIdPatchedToChooser === ""),
        tap(x => console.log(x,` string`)),
        tap(x => {
          x.forEach(val => {
            if (val !== undefined) {
              this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(val, this.formlyUtilityService.activeFormModelFirestore.DocId()).pipe(
                tap(associatedFormFirestoreDocId =>
                  this.formlyUtilityService.removeSectionFromBranch$.next({ sectionDocId: associatedFormFirestoreDocId, branchKey: this.keyString, parentId: this.parentId})
                ),
                take(1)
              ).subscribe();
            }
          })
        }),
        tap(() => this.forceRender.next(null)),
      );

      const checkBoxValueChanges = merge(checkBoxAdded, checkBoxRemoved);

      const radioDropDownValueChanges = this.chooser.formControl.valueChanges.pipe(
        startWith(this.chooser.formControl.value),
        filter(() => this.chooser.type !== "multicheckbox"),
        distinctUntilChanged(),
        filter(x => x !== undefined),
        tap(() => this.formlyUtilityService.debounceDuringCheckboxValueChanges$.next(true)),
        switchMap(x => this.fetchUpdatedWorkflowService.getActiveFormFirestoreDocIdFromSummary(x, this.formlyUtilityService.activeFormModelFirestore.DocId())),
        switchMap(x =>this.formFirestoreService.load$(x).pipe(take(1))),
      switchMap( u => this.formlyUtilityService.addWorkflowFromBranchingUpdateIfNeededReturnSectionKey(u.DocId(),(this.keyString as string), this.parentId, u, this.formlyUtilityService.activeFormModelFirestore.DocId())),
      tap(() => {
        // For radio buttons and other selection modes which only allow a single value, removal is called whenever there is a value present (
        // and is distinct from existing value)
            if (this.firestoreSummaryDocIdPatchedToChooser !== undefined) {
              console.warn(`removing ${this.firestoreSummaryDocIdPatchedToChooser} from ${this.keyString}`);
              this.formlyUtilityService.removeSectionFromBranch$.next({ sectionDocId: this.firestoreSummaryDocIdPatchedToChooser, branchKey: this.keyString, parentId: this.parentId});
            }
      }),
      tap(() => this.formlyUtilityService.debounceDuringCheckboxValueChanges$.next(false)),
      tap( x => {
        this.formlyUtilityService.addLeafKeyToBranchKeyWithParent$.next({branchKey: this.keyString, leafKey: x.sectionKey,
          docId: x.newFormFirestoreDocId === null ? x.oldFirestoreDocId : x.newFormFirestoreDocId, parentId: this.parentId});
      }),

      tap(x => {
        this.firestoreSummaryDocIdPatchedToChooser = x.newFormFirestoreDocId !== null ? x.newFormFirestoreDocId : x.oldFirestoreDocId;
      }),

    );

      const blankValue = this.chooser.formControl.valueChanges.pipe(
        startWith(this.chooser.formControl.value),
        filter(x => x === undefined));

      const emptyCheckbox = this.chooser.formControl.valueChanges.pipe(
        startWith(this.chooser.formControl.value),
        filter(() => this.chooser.type === "multicheckbox"),
        filter(x=> x!== undefined),
        filter(x => Object.values(x).filter(x => x === true).length === 0)
      );

      merge(checkBoxValueChanges, radioDropDownValueChanges,blankValue,emptyCheckbox).pipe(
        // delay here is needed for updated content to have been added to DOM b/f rendering.
        delay(1),
        tap(x => console.log(`REMOVING OBS$ REQUIREMENT FOR : ${this.keyString}`)),
        tap(() => {
          this.formlyUtilityService.formlyFieldRequiringInitialObservableEmission.value.delete(this.keyString as string);
          this.formlyUtilityService.formlyFieldRequiringInitialObservableEmission.next(this.formlyUtilityService.formlyFieldRequiringInitialObservableEmission.value);
        }),
        catchError(err => {
        console.log('Error caught in observable.', err);
        return throwError(err);
        }),
        takeUntil(this.destroyingComponent$)
      ).subscribe();
  }

}
