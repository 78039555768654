import { Injectable } from '@angular/core'
;
import { skip, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../util/authentication.service';
import { DatabaseStoreService } from '../database-backend/database-store.service';
import { StateChangeStoreService } from '../database-backend/state-change-store.service';
import { EmployeePermissionService } from './employee-permission.service';
import { EmployeeRole } from '../dao/employee-role';
import { FirestoreDiffService } from './firestore-diff.service';
import { FirestoreBackend } from '../database-backend/retrieve-from-firestore';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRoleService extends DatabaseStoreService<EmployeeRole> {

  populateDefaultRoles() : void {
    const roles: EmployeeRole[] = [
      new EmployeeRole({name: "Custom"}),
      new EmployeeRole({name: "Technician", employeePermissions: [this.employeePermissionService.get("9RJdTfodZEaPB4IgG8K5")],  }),
      new EmployeeRole({name: "Office Manager", employeePermissions: [this.employeePermissionService.get("9RJdTfodZEaPB4IgG8K5")],  }),
      new EmployeeRole({name: "Administrator", employeePermissions: [this.employeePermissionService.get("9RJdTfodZEaPB4IgG8K5"), this.employeePermissionService.get("Ox5tsPIj6n9QlPJuOSAJ")],  }),
      new EmployeeRole({name: "Dispatcher"}),
    ];
    this.createMultiple$(roles).subscribe();
  }


  constructor( fs: EmployeeRoleFirestoreService, authenticationService: AuthenticationService, store: EmployeeRoleStoreService, private employeePermissionService: EmployeePermissionService) {
  super(fs, store, authenticationService, new Map([
    ["employeePermissions", {associatedDocumentId: "employeePermissionDocIds", compositionStoreService: employeePermissionService}]]));
    // this.employeePermissionService.loadAll$().pipe(
    //   skip(1),
    //   tap(x => console.log(x,` string`)),
    //   tap(() => this.populateDefaultRoles()),
    //   take(1)
    // ).subscribe();
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmployeeRoleStoreService extends StateChangeStoreService<EmployeeRole> {
  protected store = "EmployeeRole-store";

  constructor(firestoreDiffService: FirestoreDiffService) {
super(new Map<string, EmployeeRole>(), true, firestoreDiffService);
  }
}

@Injectable({
  providedIn: 'root'
  })
class EmployeeRoleFirestoreService extends FirestoreBackend<EmployeeRole> {

 protected basePath = "EmployeeRole";

 public RetrieveInstantiatedFirestoreObjectFromJson(obj: object): EmployeeRole {
return new EmployeeRole(obj);
 }

constructor(protected authService: AuthenticationService) {
super(new EmployeeRole(), authService);
 }
}
