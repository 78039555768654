import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddCustomerComponent } from '../add-customer/add-customer.component';
import { Customer } from '../../../../common/src/data/dao/customer';
import { AddressService } from '../../../../common/src/data/dao-services/address.service';
import { CustomerService } from '../../../../common/src/data/dao-services/customer.service';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return form.touched && form.invalid;
  }
}

@Component({
  selector: 'app-multiple-customer-container',
  templateUrl: './multiple-customer-container.component.html',
  styleUrls: ['./multiple-customer-container.component.scss']
})
export class MultipleCustomerContainerComponent implements OnInit, OnDestroy {

  randomElementName = (Math.random().toString(36) + '00000000000000000').slice(2, 14);
  activeCustomerDocIds : Set<string> = new Set<string>();
  @Input() customerDetailsForm: UntypedFormGroup;
  destroyingComponent$ = new Subject();
  errorMatcher = new CrossFieldErrorMatcher();


  fileNameDialogRef: MatDialogRef<AddCustomerComponent>;

  public AddCustomerClick() {
    const editorConfig = new MatDialogConfig();

    Object.assign(editorConfig, {
      disableClose : false,
      autoFocus    : true,
      width        : '500px',
      data         :
      {
        address: this.customerDetailsForm.value["serviceAddress"]
      }
      });

    this.fileNameDialogRef = this.dialog.open(AddCustomerComponent, editorConfig);

    // Event dialog closure:
    this.fileNameDialogRef.afterClosed().pipe(
      takeUntil(this.destroyingComponent$)
      ).subscribe(x => this.eventDialogClosure(x));
  }

  eventDialogClosure(customer: Customer) {
    if (customer !== undefined) {
      if (this.addressService.get(customer.address.DocId()) !== undefined){
        customer.address = this.addressService.get(customer.address.DocId());
      }
      this.customerService.update$(customer).pipe(
        switchMap(q =>  this.customerService.load$(q.DocId())),
        take(1),
        tap(selectedCustomer => {
          const primaryCustomer = this.activeCustomerDocIds.size === 0 ? true : false;
          if (!this.activeCustomerDocIds.has(selectedCustomer.DocId())) {
            this.activeCustomerDocIds.add(selectedCustomer.DocId());
            const formGroup = this.buildCustomerFormGroup(selectedCustomer, primaryCustomer );
            (this.customerDetailsForm.get("customers") as UntypedFormArray).push(formGroup);
          }
          if (primaryCustomer) {
            this.customerDetailsForm.patchValue({primaryCustomerDocId: selectedCustomer.DocId()});
            this.customerDetailsForm.patchValue({primaryCustomer: selectedCustomer});
          }

          this.customerDetailsForm.patchValue({billingAddressAssociatedWithAddedCustomer: selectedCustomer.address});
        })
      ).subscribe();
    }
  }

  constructor(private dialog: MatDialog, private fb: UntypedFormBuilder, private addressService: AddressService, private customerService: CustomerService) { }

  ngOnDestroy(): void {
    this.destroyingComponent$.next(null);
    this.destroyingComponent$.complete();
}

  ngOnInit(): void {
    this.customerDetailsForm.controls["resetActiveCustomerDocIds"].valueChanges.pipe(
      tap(x => this.activeCustomerDocIds.clear()),
      takeUntil(this.destroyingComponent$),
    ).subscribe();

    this.customerDetailsForm.controls["customersPassedFromAddressSelection"].valueChanges.pipe(
      startWith(this.customerDetailsForm.controls["customersPassedFromAddressSelection"].value),
      filter(x=> x !== undefined),
      tap( customersToPopulate =>
        {
          (this.customerDetailsForm.get("customers") as UntypedFormArray).clear();
          this.activeCustomerDocIds.clear();
          if (customersToPopulate.length > 0) {
          (customersToPopulate as Array<{customer: Customer, role: string, primary: boolean}>).forEach(customer =>
            {
              this.activeCustomerDocIds.add(customer.customer.DocId());
              const formGroup = this.buildCustomerFormGroup(customer.customer, customer.primary);
              formGroup.patchValue({customerRole : customer.role});
              (this.customerDetailsForm.get("customers") as UntypedFormArray).push(formGroup);
            });
        }
        }),
        takeUntil(this.destroyingComponent$)
    ).subscribe();

    (this.customerDetailsForm.get('customers') as UntypedFormArray).setValidators
    (this.buildCrossCustomerValidator());

  }

  get customers(): UntypedFormArray {
    return this.customerDetailsForm.get('customers') as UntypedFormArray;
  }

  asCustomerFormGroup(customer: any) : UntypedFormGroup {
    return (customer as UntypedFormGroup);
  }

  buildCrossCustomerValidator() : ValidatorFn {

    return (group: UntypedFormArray) => {
      // Create an object of errors to return
      const errors = {};
      // Get the list of controls in the array (which are FormGroups)
      const controls = group.controls;
      if (controls.length > 0) {
      let siteVisitContactAssigned = false;
      let billingContactAssigned = false;
      let primaryAssigned = false;
      // Iterate over them
      for (let i = 0; i < controls.length; i++) {
        // Get references to controls to compare them (split code again)
        const role = controls[i].get('customerRole').value;
        if (role === "All") {
          billingContactAssigned = true;
          siteVisitContactAssigned = true;
        } else if (role === "Billing Contact") {
          billingContactAssigned = true;
        } else {
          siteVisitContactAssigned = true;
        }
        const primaryCustomer = controls[i].get('primaryCustomer').value;
        if (primaryCustomer !== null) {
          primaryAssigned=true;
        }
      }

      if (!billingContactAssigned || !siteVisitContactAssigned) {
        errors["requiredContactsUnpopulated"]=true;
      }
      if (!primaryAssigned) {
        errors["requirePrimaryCustomer"]=true;
      }
    } else {
      errors["customerNotPopulated"]=true;
    }
    return errors;
    }
  }

  buildCustomerFormGroup(customer: Customer, primaryCustomer: boolean) : UntypedFormGroup {
    const retVal = this.fb.group ({
      noAutoFill: [{value: this.randomElementName, disabled: true}],
      customerName: [{value:customer.customerName, disabled:false, },Validators.required],
      customerPrimaryPhoneNumber: [{value: customer.primaryPhoneNumber, disabled:false}, Validators.required],
      customerEmail: [{value: customer.customerEmail, disabled:false}, Validators.email],
      customerTags: {value: customer.customerTags, disabled:false},
      primaryCustomer: null,
      customerRole: {value: "All", disabled: false},
      customer,
      arrayIndex: this.activeCustomerDocIds.size - 1,
      removeCustomerIndex: "",
    });

    retVal.patchValue({customerTags: customer.customerTags});

    retVal.controls["primaryCustomer"].valueChanges.pipe(
      filter(x => x !== null),
      tap(docId => {
        this.customerDetailsForm.patchValue({primaryCustomerDocId: docId});
      }),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    retVal.controls["removeCustomerIndex"].valueChanges.pipe(
      tap(index => {
        const customersArray = (this.customerDetailsForm.get("customers") as UntypedFormArray).controls;
        const cust = customersArray[index].get("customer").value as Customer;
        this.activeCustomerDocIds.delete(cust.DocId());
        for (let i = index+1; i <= customersArray.length - 1; i++) {
          customersArray[i].patchValue({arrayIndex: i-1});
        }
        // If customer being removed is primary customer, we must reset the primaryCustomer form control
        // for each customer to properly trigger validation error ( as radio buttons being unchecked due to
        // another radio button selection don't cause value to reset)
        if (this.customerDetailsForm.get("primaryCustomerDocId").value === cust.DocId() ) {
          for (let i = 0; i < customersArray.length ; i++) {
            customersArray[i].get("primaryCustomer").reset();
          }
          this.customerDetailsForm.get("customerPopulated").markAsTouched();
        }
        (this.customerDetailsForm.get("customers") as UntypedFormArray).removeAt(index);
      }),
      take(1),
      takeUntil(this.destroyingComponent$)
    ).subscribe();

    if (primaryCustomer) {
      retVal.patchValue({primaryCustomer: customer.DocId()});
    }

    return retVal;
  }

}
