import { Address } from "./address";
import { Customer } from "./customer";
import { RetrieveFirestorePropertiesBase } from "../database-backend/retrieve-firestore-properties";
import { Discount, DiscountType } from "./discount";
import { Employee } from "./employee";
import { FormModelFirestore } from "./form-model-firestore";
import { Job } from "./job";
import { LineItem } from "./line-item";

export class Estimate extends RetrieveFirestorePropertiesBase<Estimate>  {

  lineItemDocIds: string[] = [];
  lineItems: LineItem[] = [];
  discounts: Discount[] = [];
  discountDocIds: string[] = [];

  //Customer.
  customerDocId: string;
  customer: Customer = null;

  //Site visit at which esitimate was created (can be null, for estimate given w/o site visit).
  job: Job;
  jobDocId: string;

  serviceAddress: Address;
  serviceAddressDocId: string;

  // Employee Generating the Estimate.
  employeeDocId: string;
  employee: Employee;

  formModelFirestoreDocId: string;
  formModelFirestore: FormModelFirestore = null;

  mostRecentlySentCommunicationDocId: string = null;
  mostRecentlySentCommunicationDate: Date = null;

  active: boolean = true;

  get totalCost(): number {
      let totalBeforeDiscountApplied =   this.lineItems.map(t => t.pricePerItem * t.quantity).reduce((acc, value) => acc + value, 0);
      const fixedDiscounts = this.discounts.filter(y => y.discountType === DiscountType.FIXED);
      const percentDiscounts = this.discounts.filter(y => y.discountType === DiscountType.PERCENTAGE);
      fixedDiscounts.forEach(d => d.setCalculatedDiscountAmount(totalBeforeDiscountApplied));
        totalBeforeDiscountApplied = totalBeforeDiscountApplied + fixedDiscounts.map(d => d.calculatedDiscountAmount).reduce((acc, value) => acc + value, 0);
        percentDiscounts.forEach(d => d.setCalculatedDiscountAmount(0));
        if (percentDiscounts.length > 0) {
          percentDiscounts[0].setCalculatedDiscountAmount(totalBeforeDiscountApplied);
          totalBeforeDiscountApplied = totalBeforeDiscountApplied + percentDiscounts[0].calculatedDiscountAmount;
        }
        return totalBeforeDiscountApplied;
  }


  static _firestoreIgnoredMemberNames = RetrieveFirestorePropertiesBase._firestoreIgnoredMemberNames.concat(["lineItems","customer","employee","serviceAddress","job","discounts",
    "formModelFirestore"]);
  static _firestoreIgnoreDiffTrackingMembers = RetrieveFirestorePropertiesBase._firestoreIgnoreDiffTrackingMembers.concat(["lineItemDocIds","customerDocId","jobDocId",
  "employeeDocId","serviceAddressDocId","discountDocIds","formModelFirestoreDocId"]);
  static _firestoreCompositionMemberNames = ["lineItems","customer","employee","serviceAddress","job","discounts","formModelFirestore"];
  static _firestoreCompositionalDiffMemberNames: string[] = ["lineItems","customer","employee","serviceAddress","job","discounts","formModelFirestore"];
  static _firestoreDenormalizedMemberNames: string[] = ["lineItems","customer","employee","serviceAddress","discounts","formModelFirestore"];

retrieveFirestoreIgnoredMemberNames() : string[] { return Estimate._firestoreIgnoredMemberNames;}
retrievefirestoreIgnoreDiffTrackingMembers() : string [] {return Estimate._firestoreIgnoreDiffTrackingMembers; }
retrievefirestoreCompositionalDiffMemberNames() : string[] {return Estimate._firestoreCompositionalDiffMemberNames;}
retrievefirestoreCompositionMemberNames() : string[] { return Estimate._firestoreCompositionMemberNames;}
retrieveFirestoreDenormalizedMemberNames(): string[] { return Estimate._firestoreDenormalizedMemberNames;}

public constructor(init?: Partial<Estimate>) {
  super();
  Object.assign(this, init);
}

}
