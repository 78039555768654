import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { addHours, differenceInHours, differenceInMinutes, getMinutes, roundToNearestMinutes, setMinutes, startOfMinute, subHours } from 'date-fns';
import { SettingsService } from 'web-app/src/app/settings/settings.service';
import { EmployeeAvailabilityService } from '../../../../../common/src/data/dao-services/employee-availability.service';
import { EmployeeAvailability } from '../../../../../common/src/data/dao/employee-availability';
import { LineItem } from '../../../../../common/src/data/dao/line-item';

@Component({
  selector: 'app-modify-employee-schedule-modal',
  templateUrl: './modify-employee-schedule-modal.component.html',
  styleUrls: ['./modify-employee-schedule-modal.component.scss']
})
export class ModifyEmployeeScheduleModalComponent implements OnInit {

  avail: EmployeeAvailability;
  defaultAvailability : EmployeeAvailability;
  minDateToDisplay: Date;
  maxDateToDisplay: Date;
  dateInQuestion: Date;
  employeeScheduleModificationReason: "unknown" | "resolve_schedule";
  maximumStartTime: Date = undefined;
  minimumEndTime: Date = undefined;

  floorToNearestMinutes(date: Date, interval: number) {
    var roundedMinutes = Math.floor(getMinutes(date) / interval) * interval;
    return setMinutes(startOfMinute(date), roundedMinutes);
  }

  ceilToNearestMinutes(date: Date, interval: number) {
    var roundedMinutes = Math.ceil(getMinutes(date) / interval) * interval;
    if (roundedMinutes === 60) {
      return setMinutes(addHours(date, 1),0);
    } else {
      return setMinutes(startOfMinute(date), roundedMinutes);
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ModifyEmployeeScheduleModalComponent>,
    private employeeAvailabilityService: EmployeeAvailabilityService, private settingsService: SettingsService)  {
    if (data) {
      this.dateInQuestion = data.dateInQuestion;
      const availDocId = this.employeeAvailabilityService.getAvailibilityForDate(data.dateInQuestion, data.employeeId).docId;
      this.avail = this.employeeAvailabilityService.getCloneOfCachedValue(availDocId);
      this.defaultAvailability = new EmployeeAvailability({...this.employeeAvailabilityService.getDefaultAvailabilityForDate(data.dateInQuestion, data.employeeId)});
      this.employeeScheduleModificationReason = data.employeeScheduleModificationReason;
      if (data.maximumStartTime) {
        this.maximumStartTime = data.maximumStartTime;
        if (!this.avail.activeWorkDay || this.convertDefaultTimeToTimeOnDate(this.dateInQuestion,this.avail.workStartTime).getTime() > this.maximumStartTime.getTime()) {
          this.avail.workStartTime = this.floorToNearestMinutes(this.maximumStartTime, 15);
        }
      }
      if (data.minimumEndTime) {
        this.minimumEndTime = data.minimumEndTime;
        if (!this.avail.activeWorkDay ||  this.convertDefaultTimeToTimeOnDate(this.dateInQuestion,this.avail.workEndTime).getTime() < this.minimumEndTime.getTime()) {
          this.avail.workEndTime = this.ceilToNearestMinutes(this.minimumEndTime,15);
        }
      }
      if (data.minimumEndTime || data.maximumStartTime) {
        this.avail.workStartTime = this.convertDefaultTimeToTimeOnDate(this.dateInQuestion, this.avail.workStartTime);
        this.avail.workEndTime = this.convertDefaultTimeToTimeOnDate(this.dateInQuestion, this.avail.workEndTime);
      }
      if (this.employeeScheduleModificationReason === "resolve_schedule") {
        this.avail.activeWorkDay = true;
      }
    }
  }

  convertDefaultTimeToTimeOnDate(date: Date, time: Date) : Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes());
  }


  get startTimeErrored() : boolean {
    return this.maximumStartTime && this.avail.workStartTime.getTime() > this.maximumStartTime.getTime();
  }

  get startTimeErrorMessage() : string {
    return `Work day can start no later then ${this.maximumStartTime.toLocaleTimeString()} for schedule to be valid.`;
  }

  get endTimeErrored() : boolean {
    return this.minimumEndTime && this.avail.workEndTime.getTime() < this.minimumEndTime.getTime();
  }

  get endTimeErrorMessage() : string {
    return `WOrk day can end no earlier then ${this.minimumEndTime.toLocaleTimeString()} for schedule to be valid.`;
  }

  get disallowRevertToDefault() : boolean {
    return this.employeeScheduleModificationReason === "resolve_schedule";
  }

  get needValidationBeforeSave() : boolean {
    return this.startTimeErrored || this.endTimeErrored;
  }

  ngOnInit(): void {
    this.minDateToDisplay = subHours(this.settingsService.getValue("businessStartHour"), this.settingsService.getValue("hoursBeforeBusinessStartTimeToDisplay"));
    this.maxDateToDisplay = addHours(this.settingsService.getValue("businessEndHour"), this.settingsService.getValue("hoursAfterBusinessEndTimeToDisplay"));
  }

  saveClose() : void {
    const retVal = this.avail.defaultAvailability ? new EmployeeAvailability({...this.avail}) : this.avail;
    if (retVal.startEndTimesValidate) {
      if (retVal.defaultAvailability) {
        retVal.docId = undefined;
        retVal.createdAt = new Date();
        retVal.date = this.dateInQuestion;
      }
      retVal.defaultAvailability = false;
      this.dialogRef.close({action: "update",avail: retVal});
    }
  }

  deleteClose() : void {
    if (!this.avail.defaultAvailability) {
      this.dialogRef.close({action: "delete", avail: this.avail});
    } else {
      this.dialogRef.close(undefined);
    }

  }

  setCheckDay(day: EmployeeAvailability) {
    day.activeWorkDay = !day.activeWorkDay
  }

  GetHoursScheduled(a : EmployeeAvailability) : string
  {
    const h = differenceInHours(a.workEndTime, a.workStartTime);
    const m = differenceInMinutes(subHours(a.workEndTime,h),a.workStartTime)/60;
    return LineItem.timeDurationStringFromHours(h+m);
  }

}
