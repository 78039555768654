import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { SiteWideSearchBarModule } from '../../sitewide-search-bar/sitewide-search-bar.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    SiteWideSearchBarModule,
    FlexLayoutModule,
    RouterModule,
  ],
  exports: [NavigationComponent],
  declarations: [NavigationComponent],

})

export class NavigationModule { }
