// db.ts
import Dexie, { Table } from 'dexie';
import { Iphoto } from '../../../common/src/data/dao/iphoto';

export class AppDB extends Dexie {
    image!: Table<Iphoto, string>;

    constructor() {
      super('images');
      this.version(2).stores({
        image: 'docId, dateAdded, lastAccessed'
      });
    }
}

export const db = new AppDB();
