import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Subject, share, switchMap } from 'rxjs';
import {  filter, map, tap } from 'rxjs/operators';
import { Address } from '../../../common/src/data/dao/address';
import { PhysicalAddressRoutingService } from './physical-address-routing.service';
import { AddressService } from '../../../common/src/data/dao-services/address.service';

export enum googleGeocodeResult {
  Zero_Results = "ZERO_RESULTS",
  Multiple_Results= "MULTIPLE_RESULTS",
  Success= "SUCCESS"
}

@Injectable({
  providedIn: 'root'
})
export class GoogleGeocodeService {

  private googleRawGeocoderResults$ = new EventEmitter<{googleGeocoderStatus: google.maps.GeocoderStatus, googleGeocoderResult: google.maps.GeocoderResult[], orginAddress: Address}>();
  private googleGeocodeService = new google.maps.Geocoder();

  geocodeSearchResultSummary$ = new Subject<{googleGeocodeResult: googleGeocodeResult , orginAddress: Address}>();


  retrieveGeocoderResult(origin: Address) {
    let request = {};
    if (origin.googlePlaceId === undefined) {
      request = {address: origin.formattedAddress()};
    } else {
      request = {placeId: origin.googlePlaceId};
    }
    this.googleGeocodeService.geocode(request, (data,status) => {
            this._ngZone.run(() => this.googleRawGeocoderResults$.next({ googleGeocoderStatus: status, googleGeocoderResult : data, orginAddress: origin}));
    }
    );
  }

  constructor(private _ngZone: NgZone, private routing: PhysicalAddressRoutingService,
    private addressService: AddressService) {

    this.googleRawGeocoderResults$.pipe(
      filter(results => results.googleGeocoderStatus === "ZERO_RESULTS" || results.googleGeocoderResult[0].partial_match),
      tap(x => this.geocodeSearchResultSummary$.next({googleGeocodeResult: googleGeocodeResult.Zero_Results, orginAddress: x.orginAddress })),
    ).subscribe();

    this.googleRawGeocoderResults$.pipe(
      filter(results => results.googleGeocoderResult.length > 1),
      tap(x => this.geocodeSearchResultSummary$.next({googleGeocodeResult: googleGeocodeResult.Multiple_Results, orginAddress: x.orginAddress })),
    ).subscribe();

    const processedRaw = this.googleRawGeocoderResults$.pipe(
      filter(results => results.googleGeocoderStatus !== "ZERO_RESULTS"),
      filter(results => results.googleGeocoderResult.length === 1),
      filter(results => !results.googleGeocoderResult[0].partial_match),
      tap(x => this.geocodeSearchResultSummary$.next({googleGeocodeResult: googleGeocodeResult.Success, orginAddress: x.orginAddress })),
      map(x => {
        x.orginAddress.geoCoordinates =  {Latitude: x.googleGeocoderResult[0].geometry.location.lat(),
                                          Longitude: x.googleGeocoderResult[0].geometry.location.lng()};
        x.orginAddress._formattedAddress = x.googleGeocoderResult[0].formatted_address;
        x.orginAddress.lineOne = x.orginAddress.formattedAddress();
        let zipNext = false;
        x.googleGeocoderResult[0].address_components.forEach(component => {
          if (zipNext) {
            zipNext = false;
            x.orginAddress.postalCodePlusFour = component.long_name;
          }
          if (component.long_name === x.orginAddress.postalCode) {
            zipNext = true;
          }
        });
        return x.orginAddress;
      }),
      share()
      );

    processedRaw.pipe(
      filter(x => x.DocId() === undefined),
      switchMap(x=> this.addressService.retrieveDocId(x).pipe(
        map(() => x)
      )),
      ).subscribe(x => this.routing.addressPopulatedFromGeocoderResults$.next(x));

    processedRaw.pipe(
      filter(x => x.DocId() !== undefined),
      ).subscribe(x => this.routing.addressPopulatedFromGeocoderResults$.next(x));
  }
}
